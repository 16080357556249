import {GET_RESOURCES,DELETE_RESOURCES,POST_RESOURCES,RESOURCES_BY_ID,
    RESOURCES_DETAILS
} from "../types"
import api from "../../utils/apiClient"
import {showFailedNoti,showSucessNoti} from "../actions/notificationAction"

export const getResources=()=> async dispatch =>{
    await api.resources.get().then(res => {
        if(res.data){
            dispatch({
                type: GET_RESOURCES,
                payload: res.data
            })
        }
    })
    .catch(err =>{
        console.error(err.message)
    })
}

export const postResources=(data)=> async dispatch =>{
    await api.resources.post(data).then(res =>{
        if(res.data){
            dispatch({
                type: POST_RESOURCES,
                payload: res.data
            })
        }
    })
    .catch(err =>{
        console.error(err.message)
    })
}

export const deleteResources=(id)=> async dispatch =>{
    await api.resources.deleteResources(id).then(res => {
        // debugger
        if(res.data){
            dispatch({
                type: DELETE_RESOURCES,
                payload: res.data
            })
        }
    })
    .catch(err =>{
        console.error(err.message)
    })
}

export const resourcesById=(id)=>async dispatch =>{
    await api.resources.resourcesById(id).then(res =>{
        if(res.data){
            dispatch({
                type: RESOURCES_BY_ID,
                payload: res.data
            })
        }
    }) 
    .catch(err=>{
        console.error(err.message)
    })  
}
export const setResourcesDetails = (data) => dispatch => {
    dispatch({
        type: RESOURCES_BY_ID,
        payload: data
    })
}