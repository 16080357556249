import { get,post } from './base/index';

export default {
    getFeaturedList: (headers=true) => {
        return  get(`${window.apiUrl}Program/featured-programs?Page_size=1000&Page_no=1`,{},headers)
     }
   
}


    