import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TopBar from "../../shared/components/TopBar";
import { Container,Row,Col } from "reactstrap";
import { studentEnrolledList } from "../../redux/actions/studentAction";
import StudentTable from "./components/StudentTable";
import HelmetComponent from "shared/components/HelmetComponent"
import { seoData } from "shared/StaticData"
import {exportToCsv} from "shared/helpers/index"
export default function Student() {
  const StudentEnrolled = useSelector(
    (state) => state.student.studentEnrolledList
  );
  const loading = useSelector((state) => state.student.studentLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(studentEnrolledList());
  }, []);



  const convertToCsv = () => {
    let data = []
    let A = [
      "Sl. No.",
      "Student First Name",
      "Student Middle Name",
      "Student Last Name",
      "Parent Name",
      "Gender",
      "Age",
      "DOB",
      "Grade",
      "Program Name",
      "Email",
      "Phone",
      "Emergency Contact",
      "Registration Date",
      "Learning Needs",
      "Medical Condition",
      "School Name",
      "Address",
      "City",
      "Province",
      "Postal Code"
    ]
    data.push(A)
    StudentEnrolled.forEach((d, index) => {
      return data.push([
        index + 1,
        d.fName ,
        d.mName ? d.mName : "",
        d.lName,
        d.parentName,
        d.gender,
        d.age,
        new Date(d.birthDate).toLocaleDateString(),
        d.grade,
        d.programName.join(" - "),
        d.email,
        d.phone,
        d.emergencyContact,
        new Date(d.dateOfRegistration).toLocaleDateString(),
        d.learningNeeds,
        d.medicalCondition,
        d.schoolName,
        d.address,
        d.city,
        d.province,
        d.postalCode
      ])
    });
    exportToCsv(data, "Students List")
  }

  return (
    <>
      <HelmetComponent seoData={seoData.student} />
      <Container>
        <Row>
          <Col>
             <TopBar title="My Students" buttonTitle="Download " buttonClick={convertToCsv}></TopBar>
          </Col>

        </Row>
        <Row>
          <Col> 
             <StudentTable StudentEnrolled={StudentEnrolled} loading={loading} />
          </Col>
        </Row>
       
       
      </Container>



    </>
  );
}
