
// import { Container } from '@material-ui/core'
import React from 'react';
import {
  Card, CardBody, Col, CardText, Container, Row,
} from 'reactstrap';
import { Button } from "@material-ui/core"
import { useDispatch } from 'react-redux';
import { loginModal, registerModal } from '../../../redux/actions/modalAction';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { green, pink } from '@material-ui/core/colors';
import { authRoles } from "config/authroles"
import {setUserRoleCode} from "redux/actions/userAction"

export default function Cards(props) {
  // const classes = useStyles();
  const dispatch = useDispatch()


  return (
    <div >
      <Container>
        <Row class="card">
          <Col md={12} lg={6} xl={4} xs={12} >

            <Card >

              <CardBody className="boxh">

                <div class="upper-container one">
                </div>
                <div className="text-center avatar">
                  {/* <img alt="Image placeholder"className="avatar img-fluid rounded-circle w-50 " src={provider}/> */}
                  <Badge><Avatar className="bg-light"><SchoolRoundedIcon fontSize="large" color="secondary" /></Avatar></Badge>
                </div>

                <CardText>
                  <h5 className="head text-center bold-text mt-2">I am a Parent.</h5>
                  <p className=" text-center ">I need to Register.</p>
                </CardText>
                <div className="text-center mt-4"><Button color="primary" variant="outlined"
                  onClick={() => {
                  
                    dispatch(registerModal(true)).then(() => {
                      dispatch(setUserRoleCode(authRoles.Parents))
                      dispatch(loginModal(false))
                    });

                  }}
                >Register Now</Button></div>
              </CardBody>

            </Card>
          </Col>

          <Col md={12} lg={6} xl={4} xs={12}>
            <Card >
              <CardBody className="boxh">








                

                <div class="upper-container two">
                </div>
                <div className="text-center avatar">
                  <Badge  ><Avatar className="bg-light"><PermIdentityIcon fontSize="large" color="secondary" ></PermIdentityIcon></Avatar></Badge>
                </div>
                <CardText>
                  <h5 className="head text-center bold-text mt-2">I am an Education Provider.</h5>
                  <p className=" text-center ">I serve Children and Families.</p>
                </CardText>

                <div className="text-center mt-4"><Button color="primary" variant="outlined"
                  onClick={() => {

                    dispatch(registerModal(true)).then(() => {
                      dispatch(setUserRoleCode(authRoles.Provider))
                      dispatch(loginModal(false))
                    });

                  }}
                >Register Now</Button></div>

              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={6} xl={4} xs={12}>
            <Card >
              <CardBody className="boxh">

                {/* <div className="card__title text-center">
              
              <h5 className="head bold-text text-center">Provider Name</h5>
            </div> */}
                <div class="upper-container three"></div>
                <div>
                  <div className="text-center avatar">
                    <Badge ><Avatar className="bg-light"><SupervisedUserCircleIcon fontSize="large" color="secondary" ></SupervisedUserCircleIcon></Avatar></Badge>
                  </div>
                </div>
                <CardText>
                  <h5 className="head text-center bold-text mt-2">I am a School Community.</h5>
                  <p className=" text-center ">I need to find Programs.</p>
                </CardText>
                <div className="text-center mt-4"><Button color="primary" variant="outlined"
                  onClick={() => {
                   props.handleSchoolnCommi()
                  }}
                >Register Now</Button></div>
              </CardBody>

            </Card>
          </Col>

        </Row>

      </Container>
    </div>
  )
}




