import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
    cryptoTableReducer,
    newOrderTableReducer,
    sidebarReducer,
    themeReducer,
    customizerReducer,
    todoReducer,
    rtlReducer,
    authReducer,
    modalReducer,
    programReducer,
    experianceReducer,
    studentReducers,
    snackbarReducer,
    tagReducer,
    mediaReducer,
    providerReducer,
    profileReducer,
    enrollmentReducer,
    transactionReducer,
    reviewReducer,
    notificationReducer,
    caledarReducer,
    cartReducer,
    recommendationReducer,
    messageReducer,
    promoReducer,
    favoriteReducer,
    announcementReducer,
    contactUsReducer,
    susbcriptionReducer,
    userReducer,
    resourcesReducer,
    featuredReducer,
    universalReducer,
    linkManagementReducer

} from '../../redux/reducers/index';
const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form",
    theme: themeReducer,
    rtl: rtlReducer,
    sidebar: sidebarReducer,
    cryptoTable: cryptoTableReducer,
    newOrder: newOrderTableReducer,
    customizer: customizerReducer,
    todos: todoReducer,
    user: authReducer,
    modal: modalReducer,
    program: programReducer,
    experiance: experianceReducer,
    student: studentReducers,
    snackbar: snackbarReducer,
    tags: tagReducer,
    media: mediaReducer,
    provider: providerReducer,
    profile: profileReducer,
    enrollment: enrollmentReducer,
    transaction: transactionReducer,
    review: reviewReducer,
    notification: notificationReducer,
    calendar: caledarReducer,
    cart: cartReducer,
    recommend: recommendationReducer,
    message: messageReducer,
    promocode: promoReducer,
    fav: favoriteReducer,
    announcement: announcementReducer,
    contactUs: contactUsReducer,
    subscription: susbcriptionReducer,
    users: userReducer,
    resources: resourcesReducer,  
    featured: featuredReducer,
    universal:universalReducer,
    linkManagement:linkManagementReducer
})
const store = createStore(reducer, applyMiddleware(thunk));

export default store;