import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom"
import { Col, Row } from 'reactstrap';
import PopularTags from "../../shared/components/PopularTags/PopularTags"
import AllProgram from "../AllProgram"
import ProfileCard from "shared/components/CardComponent/ProfileCard"
import Experience from "../Experience"
import { seoData } from "shared/StaticData"
import Banner from "../AboutUs/Component/Banner";

// import SubscribeBox from "containers/Layout/newsetter_box/subscribeBox";
const Landing = () => {
  //const dispatch = useDispatch()
  const { providerList } = useSelector(state => state.provider);
  const {
    mainSelectedTag
  } = useSelector(state => state.tags);

  let filteredProviderList = providerList && providerList.length > 0 && providerList.filter(item => item.organisationName)
  return (
    <div className="mx-30 full_width">
      <Helmet>
        <meta charSet={seoData.landing.char} />
        <title>{seoData.landing.title}</title>
        {seoData.landing.meta.map((m, key) => <meta key={key} name={m.name} content={m.content} />)}
        
      </Helmet>
      <div style={{ "padding-top": "1%" , "padding-bottom": "5%" }}><Banner/></div>
     
      <PopularTags className="mt-15 mb-10" />

      {(mainSelectedTag === "All" || mainSelectedTag === "Programs") &&
        (<>
          <Row className="mt-15 mb-10">
            <Col md={12}>
              <div>
                <h3 className="bold-text uppercase">Programs</h3>

              </div>
            </Col>
          </Row>
          <Row >

            <AllProgram />
          </Row> </>
        )}

      {(mainSelectedTag === "All" || mainSelectedTag === "Provider") && (filteredProviderList && filteredProviderList.length !== 0)
        &&
        (<>
          <Row>
            <Col md={12}>
              <div >
                <h3 className="bold-text">Providers</h3>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <AllProgram />
          </Row> */}

        </>
        )}

      <Row >

        {(mainSelectedTag === "All" || mainSelectedTag === "Provider") && (filteredProviderList && filteredProviderList.length !== 0) && filteredProviderList.map((item, index) =>
        (

          <div className="profile-card-div" key={index + new Date()}>

            <ProfileCard
              item={item}
            />
          </div>
        )
        )}
      </Row>
      {(mainSelectedTag === "All" || mainSelectedTag === "Experience")?<div> <Row>
            <Col md={12}>
              <div >
                <h3 className="bold-text">Experiences</h3>
              </div>
            </Col>
          </Row>
        <Row style={{ "paddingLeft": "12px" }}>
          <Experience type={"general"} />
        </Row></div> : <></>}
      {/* <SubscribeBox></SubscribeBox> */}
    </div>
  );
}

export default withRouter(Landing);
