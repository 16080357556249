import React from "react"
import { Button } from "@material-ui/core"
import {connect, useSelector} from "react-redux"
import {getCartDetails} from "../../../redux/actions/cartAction"
import { addTransactionDetails,startPayment } from "../../../redux/actions/transactionActions"
import {paymentDetailsModal} from "../../../redux/actions/modalAction"
import SimpleBackDrop from "../../../shared/components/SimpleBackDrop/BackDrop"
import {paymentCode} from "config/paymentCode"
const PayLater = ({ getCartDetails, addTransactionDetails,startPayment,paymentDetailsModal }) => {
    const {paymentStart}  = useSelector(state=>state.transaction)
    const hanldePaylater = async () => {
        startPayment(true)
        let data = {
            id: 0,
           paymentSource: paymentCode.PayLater,
           transactionDetails: "",
           applicationFee: 0,
           currency: "",
           transactionId: ""
         }
         await addTransactionDetails(data).then(() => {
             paymentDetailsModal(false)
             getCartDetails().then(() => {
                startPayment(false)
             })
         })
     }
    return (
        <>
            <h4 className="subhead mb-8">Please contact us: <strong>registration@dowhatuluv.ca.</strong><br />
            </h4>
            <h4 className="subhead">Your registration will be pending until we receive your confirmation.</h4>
            <Button color="primary" className="float-right my-10"
                disableElevation variant="contained"
                onClick={hanldePaylater}
            
            >
            Confirm
              </Button>
            {paymentStart && (<SimpleBackDrop open={paymentStart}/>)}
            
        </>
    )
}

export default connect(null,{getCartDetails,addTransactionDetails,startPayment,paymentDetailsModal}) (PayLater )