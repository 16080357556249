
import React from 'react';
import { useSelector, useDispatch } from "react-redux"
import { Col, Row, Container } from 'reactstrap';
import AuthForm from "../../containers/Account/AuthForm"
import { authRoles } from "../../config/authroles"
import { getCalender } from "../../redux/actions/calenderAction"
import SpeedDial from "../SpeedDials"
const background = `${process.env.PUBLIC_URL}/img/landing/footer_bg.png`;

const logo = `${process.env.PUBLIC_URL}/img/landing/LOGO.png`;
const logo2 = `${process.env.PUBLIC_URL}/img/landing/Logomisty-side.png`;

const Footer = () => {
    const user = useSelector(state => state.user);
    return (
        <footer className="dwul__footer">
            <Container>
                <Row>
                    <Col md={12} className="mb-10" >
                        {/* <img src={logo} alt="logo" className="dwul-img mx-10" /> */}
                        <a href="https://mis.solutions" target="_blank"> <img className="ml-10 mis-solution-img mx-10" src={logo2} alt="logo2" />
                        </a>
                    </Col>
                    <Col md={12}>
                        <p className="landing__footer-text">Copyright {(new Date().getFullYear())} © Misty Interactive Studios Inc.
                            Platform operated by Do What U Luv Foundation.
                        </p>
                        <p>version 5.1.3</p>
                    </Col>
                </Row>
                {user.role === authRoles.Parents && (<SpeedDial />)}
            </Container>
        </footer>
    );
}

export default AuthForm(Footer)
