import React from "react";
import { renderField } from "../../../../../../shared/components/form/RenderField";
import { mediaTypes } from "../../../../../../config/mediaTypes";
import ImageUpload from "../../../../../../shared/components/form/ImageUpload";
import { Button } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { validate } from "../../../../../../config/formValidation";
import { Row, Col } from "reactstrap";
import { tagTypes } from "../../../../../../config/tagTypes";
import ActivityTag from "../../../../../../shared/components/form/ActivityTag";
import TextEditor from "shared/components/text-editor/TextEditor";
const ProviderForm = ({
  handleSubmit,
  initialValues,
  providerMedia,
  providerSubmit,
  valid,
  submitting,
  reset,
  isEdit,
}) => {
  return (
    <>
      <form className="login-form" onSubmit={handleSubmit(providerSubmit)}>
        <Row>
          <Col md={10} lg={10} sm={10}>
            <div className="form__form-group">
              <Field
                name="fName"
                component={renderField}
                type="text"
                startIcon="people"
                label="First Name*"
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="mName"
                component={renderField}
                type="text"
                startIcon="people"
                label="Middle Name"
                disabled={isEdit ? true : false}
              />
            </div>
          </Col>
          <Col md={2} lg={2} sm={2}>
            <ImageUpload
              accept="image/*"
              name="logoUrl"
              image={providerMedia ? providerMedia : initialValues.logoUrl}
              mediatype={mediaTypes.ProviderLogo}
              disabled={isEdit ? true : false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <Field
                name="lName"
                component={renderField}
                type="text"
                startIcon="people"
                label="Last Name*"
                disabled={isEdit ? true : false}
              />
            </div>
            {/* <div className="form__form-group">
              <Field
                name="email"
                component={renderField}
                type="text"
                startIcon="email"
                label="Email"
                disabled={true}
              />
            </div> */}
            <div className="form__form-group">
              <Field
                name="email"
                label="Email"
                startIcon="email"
                component={renderField}
                disabled={initialValues.email === "" ? false : true}
                type="text"
              />
            </div>

            <div className="form__form-group">
              <Field
                name="contactNo"
                label="Contact*"
                startIcon="contact_phone"
                component={renderField}
                disabled={isEdit ? true : false}
                type="text"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="organisationName"
                component={renderField}
                type="text"
                startIcon="business"
                label="Organisation Name*"
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="locations"
                component={ActivityTag}
                type="text"
                startIcon="room"
                label="City Tags*"
                tagType={[tagTypes.Location, tagTypes.School]}
                id={true}
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="city"
                label="Location/Address"
                startIcon="location_city"
                component={renderField}
                type="text"
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="ageGroup"
                label="Age Group"
                component={ActivityTag}
                type="text"
                startIcon="people"
                tagType={[tagTypes.AgeGroup]}
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="activity"
                label="Activity Tags"
                component={ActivityTag}
                type="text"
                tagType={[tagTypes.Activity]}
                disabled={isEdit ? true : false}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="website"
                label="Website"
                component={renderField}
                type="url"
                placeholder="Include http:// or https://"
                startIcon=""
                disabled={isEdit ? true : false}
              />
            </div>
            <h6 style={{color:"red"}}>Why do you love what you do?</h6>
            <div className="form__form-group">
              <Field
                name="whyDoULuvWhatUDo"
                label=""
                component={renderField}
                type="text"
                placeholder="Tell us in 2-3 sentences"
                startIcon=""
                disabled={isEdit ? true : false}
              />
            </div>

            <div className="form__form-group">
              <Field
                name="description"
                label="About*"
                startIcon="description"
                component={TextEditor}
                type="text"
                multiline={true}
                rows={5}
                readOnly={isEdit ? true : false}
              />
            </div>
          </Col>
        </Row>
        <div className="my-16 flex flex-end flex-middle">
          <div>
            <Button
              color="primary"
              disabled={isEdit ? true : false}
              variant="contained"
              type="submit"
            >
              Update profile
            </Button>
          </div>
          {/* <div className="pl-10">
            <Button type="button" disabled={isEdit ? true : false} color="secondary" onClick={reset} variant="outlined" disableElevation>
              Cancel
          </Button>
          </div> */}
        </div>
      </form>
    </>
  );
};

export default connect((state) => ({
  errorMsg: state.user.error,
  providerMedia: state.media.providerMedia,
  initialValues: {
    fName: state.profile.providerProfile.fName
      ? state.profile.providerProfile.fName
      : "",
    mName: state.profile.providerProfile.mName
      ? state.profile.providerProfile.mName
      : "",
    lName: state.profile.providerProfile.lName
      ? state.profile.providerProfile.lName
      : "",
    organisationName: state.profile.providerProfile.organisationName
      ? state.profile.providerProfile.organisationName
      : "",
    description: state.profile.providerProfile.description
      ? state.profile.providerProfile.description
      : "",
    website: state.profile.providerProfile.website
      ? state.profile.providerProfile.website
      : "",
    contactNo: state.profile.providerProfile.contactNo
      ? state.profile.providerProfile.contactNo
      : "",
    logoUrl: state.profile.providerProfile.logoUrl
      ? state.profile.providerProfile.logoUrl
      : "",
    ageGroup: state.profile.providerProfile.ageGroup
      ? state.provider.providerProfile.ageGroup
      : "",
    email: state.profile.providerProfile.email
      ? state.profile.providerProfile.email
      : "",
    city: state.profile.providerProfile.city
      ? state.profile.providerProfile.city
      : "",
    locations: state.profile.providerProfile.locations
      ? state.profile.providerProfile.locations
      : "",
    locationName: state.profile.providerProfile.locationName
      ? state.profile.providerProfile.locationName
      : "",
    activity: state.profile.providerProfile.activity
      ? state.profile.providerProfile.activity
      : "",
  },
}))(
  reduxForm({
    form: "Provider-Profile",
    validate,
    enableReinitialize: true,
  })(ProviderForm)
);
