import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ButtonToolbar,
  Table,
  CardBody,
  Card
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Button, Icon, TableRow, TableCell } from "@material-ui/core";
import SearchableTable from "../../../../../../shared/components/SearchableTable";

const columns = [
  {
    id: 0,
    label: "Name",
  },
  {
    id: 1,
    label: '',
  },
  {
    id: 1,
    label: '',
  },
];

const Announcement = ({
  openAnnouncementModal,
  announcementList,
  refreshAnnouncement,
  handleEditAnnouncement,
  handleDeleteAnnouncement,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const clearSearch = () => {
    setSearchValue("");
  };
  const handleAnnouncementSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  let filteredData = announcementList;
  if (searchValue) {
    filteredData = announcementList.filter((search) => {
      if (
        search.announcementName
          .toLowerCase()
          .startsWith(searchValue.toLowerCase())
      ) {
        return search;
      }
    });
  }
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  return (


    <SearchableTable
      title=""
      rows={columns}
      filteredData={filteredData}
      loading={refreshAnnouncement}
      actionTablebtn={true}
      actionBtnIcon="add"
      actionBtnText=""
      actionBtnClick={openAnnouncementModal}
      searchType="text"
      searchLabel="Search"
      searchValue={searchValue}
      clearSearch={clearSearch}
      searchPlaceholder="Search Announcement"
      pagination={true}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      getSearchResult={handleAnnouncementSearch}
    >
      {filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => (
          <TableRow key={index}>
            <TableCell padding="checkbox">{(index + 1) + (page * rowsPerPage)}</TableCell>
            <TableCell className="material-table__cell ">
              {item.announcementName}
            </TableCell>

            <TableCell className="material-table__cell">
              <UncontrolledDropdown className="dashboard__table-more">
                <DropdownToggle>
                  <p>
                    <DotsHorizontalIcon />
                  </p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem onClick={() => handleEditAnnouncement(item.id)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDeleteAnnouncement(item.id)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </TableCell>
          </TableRow>
        ))}
    </SearchableTable>

  );
};

export default withTranslation("common")(Announcement);
