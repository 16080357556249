import React from "react";
import { Helmet } from "react-helmet";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Col, Card, CardBody, Container, Row, ButtonToolbar } from "reactstrap";
import FAQs from "./components/StudentFAQs";
import Provider from "./components/ProviderFAQs";
import Parent from "./components/ParentFAQs";
import Contact from "./components/Contact";
import { seoData } from "shared/StaticData"
import PrivacyPolicies from "./components/PrivacyPolicies"
import TermsAndCondition from "./components/TermsAndCondition"
export default function FAQ() {
  return (
    <Container>
      <Helmet>
        <meta charSet={seoData.faq.char} />
        <title>{seoData.faq.title}</title>
        {seoData.faq.meta.map((m, key) => <meta key={key} name={m.name} content={m.content} />)}
      </Helmet>
      {/* <Row>
        <Col>
          <h3 className="bold-text mb-2">Support</h3>
        </Col>
      </Row>
      <Row>
        <FAQs />
      </Row> */}

      <Row>
        <Col>
          <h3 className="bold-text mb-2">Frequently Asked Questions</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
              <Provider />
              <Parent />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
          <h3 className="bold-text ">Contact Us</h3>
          <h4 className=" text-muted mt-3">
            You have questions and we have the answers. Get in
          <br /> touch with DWUL members by either filling the form <br /> below
          or using one of the provided emails.
        </h4>
          <h4 className="text-muted mt-5 mb-3">
            General information : <b>info@dowhatuluv.ca</b> <br />
          Registrations Info : <b>registration@dowhatuluv.ca</b>
          </h4>

          <div className="mb-3">
            <FacebookIcon style={{ color: "#3b5998" }} />
            <LinkedInIcon style={{ color: "#0e76a8" }} />
            <InstagramIcon style={{ color: "#e4405f" }} />
          </div>


        </Col>

        <Contact />
       
      </Row>
      <Row>
          <Col>
            <h3 className="bold-text mb-2">Terms of Service</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <TermsAndCondition />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="bold-text mb-2">Privacy Policies</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <PrivacyPolicies />
              </CardBody>
            </Card>
          </Col>
        </Row>

    </Container>
  );
}
