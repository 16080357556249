import React, { useState } from 'react'
import { Container } from 'reactstrap';
import { useHistory } from "react-router-dom"
import DetailsCard from './Component/DetailsCard'
import { useSelector, useDispatch } from "react-redux"
import ModalRegisterProgram from "./Component/ModalRegisterProgram"
import { addOder } from "../../redux/actions/cartAction"
import { paymentDetailsModal, programRegiterModal, reviewModal, contactProviderModal } from "../../redux/actions/modalAction"
import { getProgramById } from "../../redux/actions/programActions"
import { getproviderExpById } from "redux/actions/experianceAction"
import { getProviderProfileId } from "redux/actions/profileActions"
import ReviewModal from "./Component/review/ReviewModal";
import { postReview } from "../../redux/actions/reviewAction";
import ContactModal from "./Component/contact/ContactModal";
import { showWarningNoti } from '../../redux/actions/notificationAction';
import { messageItemType } from "../../config/notificationType";
import HelmetComponent from "shared/components/HelmetComponent"
import { sendMessage } from "../../redux/actions/messageActions"
import { uniqBy } from "lodash"
export default function StudentDetalsPage({ location, match }) {
   const history = useHistory()
   const { id } = match.params
   const type = new URLSearchParams(location.search).get("type")
   const modal = useSelector(state => state.modal)
   const rating = useSelector(state => state.review.rating)
   const { programDetails, detailsLoading } = useSelector(state => state.program)
   const { providerExpById } = useSelector(state => state.experiance)
   const { providerProfileId, providerProfileLoading } = useSelector(state => state.profile)
   const [studentState, setStudentState] = useState([]);
   const [expLoading, setExpLoading] = useState(true)
   const dispatch = useDispatch()
   const closeRegisterProgramModal = () => {
      dispatch(programRegiterModal(false))

   }
   const closePaymentModal = () => {
      dispatch(paymentDetailsModal(false))
   }
   const handleCart = (list) => {

      let uniqStudent = uniqBy(list, 'id')
      let student = []


      uniqStudent.map((s, i) => {
         return student.push(s.id)


      })


      let billingProduct = {
         programId: programDetails.id,
         studentId: student
      }
      dispatch(addOder(billingProduct)).then(() => {
         dispatch(programRegiterModal(false)).then(() => {
            history.push('/cart')
         })
      })

   }
   const sendMessages = async (val) => {
      let data = {
         messageType: {
            id: type === 'experience' ? providerExpById.id : programDetails.id,
            itemType: type === 'experience' ? messageItemType.Experience : messageItemType.Program
         },
         subject: val.subject,
         fullMassage: val.fullMassage
      }
      dispatch(sendMessage(data));
      closeContact();
   }
   const handleMoreProgram = (list) => {
      if (list.length === 0) {
         dispatch(showWarningNoti("No student selected."))
      }
      let uniqStudent = uniqBy(list, 'id')
      let student = []

      uniqStudent.map((s, i) => {
         return student.push(s.id)


      })

      let billingProduct = {
         programId: programDetails.id,
         studentId: student
      }
      dispatch(addOder(billingProduct)).then(() => {
         dispatch(programRegiterModal(false))
      })
   }
   React.useEffect(() => {
      if (type === 'program') dispatch(getProgramById(id))
      else if (type === 'experience') {
         setExpLoading(true)
         dispatch(getproviderExpById(id)).then(() => setExpLoading(false))
      }

      else if (type === 'provider') {
         dispatch(getProviderProfileId(id))
      }
   }, [])

   const submitReview = async (values) => {
      let data = {
         id: 0,
         programId: programDetails.id,
         subject: values.subject,
         rating: rating,
         fullMassage: values.fullMassage
      }
      dispatch(postReview(data));
      dispatch(reviewModal(false));
   }
   const closeReview = () => {
      dispatch(reviewModal(false))
   }
   const closeContact = () => {
      dispatch(contactProviderModal(false))
   }
   const getPagename = (type) => {
      switch (type) {
         case "program":
            return programDetails.programName
         case "provider":
            return providerProfileId.organisationName
         case "experience":
            return providerExpById.name
         default:
            return ''
      }
   }
   return (
      <>
         <HelmetComponent pageName={getPagename(type)} />
         <Container>
            <>
               <DetailsCard
                  programDetails={type === "program" ? programDetails : type === "provider" ? providerProfileId : providerExpById}
                  detailsLoading={type === "program" ? detailsLoading : type === "provider" ? providerProfileLoading : expLoading}
                  type={type}
               />
            </>
         </Container>
         {modal.registerProgram && (<ModalRegisterProgram
            title="SELECT STUDENT(S)"
            isOpen={modal.registerProgram}
            closeModal={closeRegisterProgramModal}
            handleCart={handleCart}
            setStudentState={setStudentState}
            handleMoreProgram={handleMoreProgram}
         />)}
         <ReviewModal
            title="Review Program"
            isOpen={modal.reviewModal}
            closeModal={closeReview}
            submitReview={submitReview}
         />
         <ContactModal
            sendMessages={sendMessages}
            title="Provider Information"
            isOpen={modal.contactProviderModal}
            closeModal={closeContact}
         />
      </>
   )
}
