import moment from "moment";

const ligthTheme = {
  backgroundColor: "white",
  color: "#646777",
};

const darkTheme = {
  backgroundColor: "#2e2c34",
  color: "#dddddd",
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case "theme-dark": {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === "defaultItems" ? null : { color },
      };
    }
    case "theme-light": {
      return ligthTheme;
    }
    default:
      return ligthTheme;
  }
}
export default getTooltipStyles;
// date format
export const dateFormat = (date, time = true) => {
  let d = "";
  if (date && time) {
    return (d = moment(date).format("MMM DD, YYYY "));
  } else if (date && !time) {
    return (d = moment(date).utc().format("MMM DD, YYYY"));
  } else return d;
};

export const getTodayday = () => {
  return new Date().toDateString().substr(0, 3);
};
export const monthFormat = (date) => {
  let d = "";
  if (date) {
    return (d = moment(date).utc().format("MMM DD, YYYY "));
  } else {
    return d;
  }
};
export const timeFormat = (time = new Date()) => {
  return moment(time).utc().format("HH:mm");
};
//  diffrence between two days . eg: endDate - startDate
export const getTimeDiff = (date2 = new Date()) => {
  const d1 = new Date();
  const d2 = new Date(date2);
  let difference = moment(d2, "DD/MM/YYYY").diff(
    moment(d1, "DD/MM/YYYY"),
    "days"
  );
  if (difference >= 0) {
    return difference;
  } else {
    return "Expired";
  }
};
//  if want to get diff from current date
export function getTimeDifference(date = new Date()) {
  var utcMoment = moment.utc(new Date());
  var utcDate = utcMoment.format("DD/MM/YYYY HH:mm:ss");

  let difference =
    moment(utcDate, "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;
  console.log("time difference", difference);

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3600)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export const gradeOption = [
  "Kindergarten",
  "Grade 1",
  "Grade 2",
  "Grade 3",
  "Grade 4",
  "Grade 5",
  "Grade 6",
  "Grade 8",
  "Grade 9",
  "Grade 10",
  "Grade 11",
  "Grade 12",
  "others..",
];

// for truncating string
export function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str && str.length <= num) {
    return str;
  } else if (str && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return "";
  }

  // Return str truncated with '...' concatenated to the end of str.
}
const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (data = [], fileName = "dwul") => {
  // let csvContent = "data:text/csv;charset=utf-8,";
  let csvContent = "";

  // console.log("exportToCSVdata ", data);
  data.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });
  // let encodedUri = encodeURI(csvContent);
  downloadFile({
    data: csvContent,
    fileName: fileName,
    fileType: "text/csv",
  });
  // console.log(encodedUri);
  // let link = document.createElement("a");
  // link.setAttribute("href", encodedUri);
  // link.setAttribute("download", `${fileName}.csv`);
  // document.body.appendChild(link); // Required for FF

  // link.click();
};

// -----new csv export ------------

export const exportToCsv2 = (data = [], fileName = "dwulsales") => {
  let csvContent = "data:text/csv;charset=utf-8,";

  data.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });
  let encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

// -----new csv export ------------

export const getLocationData = (location = []) => {
  let data = [];
  location.map((l) => {
    if (data.name) {
      data.push({
        id: l.id,
        locationName: l.name,
      });
    } else {
      data.push({
        id: l.id,
        locationName: l.locationName,
      });
    }
  });
  return data;
};

export const getThisWeekDate = () => {
  let curr = new Date(); // get current date
  let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
  let last = first + 6; // last day is the first day + 6

  let firstday = new Date(curr.setDate(first)).toUTCString();
  let lastday = new Date(curr.setDate(last)).toUTCString();
  return { startDate: firstday, endDate: lastday };
};

export const getThisMonthDate = () => {
  let date = new Date();
  let startDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let endDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDay: startDay, endDay: endDay };
};
export const getThisYear = () => {
  let date = new Date();
  var start = date.getFullYear() + "/1/1/";
  var end = date.getFullYear() + "/12/31/";
  return { start: start, end: end };
};

export function formatInputDate(date = new Date()) {
  const preFixNumber = (number) => {
    if (number < 10) return "0" + number;
    else return number;
  };
  let tempDate = new Date(date);
  let YYYY = tempDate.getFullYear();
  let MM = preFixNumber(tempDate.getMonth() + 1);
  let DD = preFixNumber(tempDate.getDate());
  let Hh = preFixNumber(tempDate.getHours());
  let Mm = preFixNumber(tempDate.getMinutes());
  let newDate = `${YYYY}-${MM}-${DD}T${Hh}:${Mm}`;
  return newDate;
}

export function ArrayToString(data = []) {
  try {
    let d = [];
    if (typeof data === "object") {
      data.length > 0 &&
        data.map((item) =>
          typeof item === "object" ? d.push(item?.name) : d.push(item)
        );
      return d;
    } else {
      let d1 = [data];
      d1.map((item) =>
        typeof item === "object" ? d.push(item.name) : d.push(item)
      );
      return d;
    }
  } catch (e) {}
}
export const getUTCDate = (date = []) => {
  // if()
  return date.map((d) => new Date(d));
};

export const stringToTime = (time) => {
  if (time) {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours;
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    minutes =
      minutes < 10 && (minutes === "00" ? "" : "00") ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
};
