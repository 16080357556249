import tagApi from './api/tagApi';
import experienceApi from './api/experianceApi';
import programApi from './api/ProgramApi';
import profileApi from "./api/profileApi"
import studentApi from "./api/studentApi"
import usersApi from "./api/usersApi"
import providerApi from "./api/providerApi";
import transactionService from "./api/transactionService";
import promoCodeApi from "./api/promoCodeApi";
import reviewApi from "./api/reviewApi";
import calenderApi from "./api/calenderApi"
import cartApi from "./api/cartApi"
import recommendApi from "./api/recommendApi";
import messageApi from "./api/MessageApi";
import announcementApi from "./api/announcementApi";
import favoriteApi from "./api/favoriteApi"
import contactUsApi from "./api/contactUsApi";
import subscriptionApi from "./api/subscriptionApi";
import resourcesApi from "./api/resourcesApi";
import notificationApi from "./api/notificationApi";
import featuredApi from "./api/featuredApi"
import linkManagementApi from './api/linkManagementApi';
const api = {
    tag: tagApi,
    experience: experienceApi,
    program: programApi,
    profile: profileApi,
    student: studentApi,
    users: usersApi,
    provider: providerApi,
    transaction: transactionService,
    promoCode: promoCodeApi,
    review: reviewApi,
    calender: calenderApi,
    cart: cartApi,
    recommend: recommendApi,
    message: messageApi,
    announcement: announcementApi,
    favorite: favoriteApi,
    contactUs: contactUsApi,
    subscription: subscriptionApi,
    resources: resourcesApi,
    noti: notificationApi,
    feature:featuredApi,
    linkManagement:linkManagementApi
};
export default api