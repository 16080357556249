import api from "../../utils/apiClient";
import { reset } from "redux-form"
import {  showSucessNoti, } from "./notificationAction"
import {CONTACT_US,CONTACT_US_LOADING} from "../types";

export const sendQuery=(data)=> async dispatch=>{
    await api.contactUs.sendQuery(data,false).then(res=>{
        if(res.data){
            dispatch({
                type: CONTACT_US,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message))
            dispatch(reset("Contact_form"))
        }
    })
    .catch(err =>{
        // debugger
    })
} 

// export const contactUsLoad=(data)=> async dispatch=>{
//     await api.contactUs.sendQuery(data,false).then(res=>{
//         if(!res.data){
//             dispatch({
//                 type: CONTACT_US_LOADING,
//                 payload: true
//             })
//         }
//     })
//     .catch(err =>{
//         debugger
//     })
// }   