import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Banner from "./Component/Banner";
import { Container, Row, Col } from "reactstrap";
import { totalcount } from "../../redux/actions/profileActions";
import { seoData } from "shared/StaticData"
import { Helmet } from "react-helmet";
import Cards from "./Component/Cards";
import AuthForm from "containers/Account/AuthForm"
import SchoolCommunityModal from "./Component/SchoolCommunityModal"
import ModalRegisterForm from "containers/Account/AuthForm/Components/ModalRegisterForm"
import BannerCarasouel from "containers/Layout/banner/banner_carasouel";

function AboutUs(props) {
  const [schoolNCommuni, setschoolNCommuni] = React.useState(false)
  const [registerModal, setregisterModal] = React.useState(false)
  const Count = useSelector((state) => state.profile.totalCount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(totalcount());
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet={seoData.aboutUs.char} />
        <title>{seoData.aboutUs.title}</title>
        {seoData.aboutUs.meta.map((m, key) => <meta key={key} name={m.name} content={m.content} />)}
      </Helmet>
      <BannerCarasouel></BannerCarasouel>
      


      <div class="half-half text-center shadow p-3 mb-5 bg-white rounded ">
        <Row className="flex flex-center mt-0">
          <Col xs={12} sm={3} md={2} lg={2}><h5> <strong>{Count.totalRegistration + " "}</strong>Registrations</h5></Col>
          <Col xs={12} sm={2} md={2} lg={2}><h5><strong>{Count.totalStudent}</strong> Students </h5></Col>
          <Col xs={12} sm={2} md={2} lg={2}><h5><strong>{Count.totalPrograms}</strong> Programs </h5></Col>
          <Col xs={12} sm={2} md={2} lg={2}><h5><strong>{Count.totalExperience}</strong> Experiences
          </h5></Col>
        </Row>
      </div>

      {/* </Container> */}
      {/* </Row> */}
      <Row>
        <Container>
          <div className="text-center mb-4">
            <p >
              <h3 >
                <strong>
                  Help people discover what they love to learn, explore their
                  talents,
                </strong>
              </h3>
              <h4> and unleash their potential. </h4>{" "}
            </p>
          </div>
        </Container>
      </Row>
      <Container>
        <Cards handleSchoolnCommi={() => setschoolNCommuni(true)} {...props}></Cards>
      </Container>
      <SchoolCommunityModal
        title="School & community"
        isOpen={schoolNCommuni}

        closeModal={() => setschoolNCommuni(false)}
      />
      


    </div>
  );
}

export default withRouter(AboutUs);
