import {
  SEND_MESSAGE,
  GET_MESSAGE,
  Resolve_MESSAGE,
  OPEN_MESSAGE,
  CLOSE_MESSAGE,
  GET_MESSAGE_ADMIN,
  OPEN_MESSAGE_ADMIN,
  CLOSE_MESSAGE_ADMIN,
  PARENT_MESSAGE
} from "../types";

const initialState = {
  getMessage: [],
  sendMessage: {},
  resolveMessage: {},
  loading: true,
  open: false,
  openAdmin: false,
  openMessages: [],
  openMessagesAdmin: [],
  adminMessage: [],
  parentMessages: []

};
export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return {
        ...state,
        sendMessage: action.payload,
      };
    case GET_MESSAGE:
      return {
        ...state,
        getMessage: action.payload,
        loading: false,
      };
    case Resolve_MESSAGE:
      return {
        ...state,
        resolveMessage: action.payload,
      };
    case OPEN_MESSAGE:
      return {
        ...state,
        openMessages: action.payload,
        // open: true,
      };
    case OPEN_MESSAGE_ADMIN:
      return {
        ...state,
        openMessagesAdmin: action.payload,
        // open: true,
      };
    case CLOSE_MESSAGE:
      return {
        ...state,
        open: action.payload,
      };
    case CLOSE_MESSAGE_ADMIN:
      return {
        ...state,
        openAdmin: action.payload,
      };
    case GET_MESSAGE_ADMIN:
        return{
            ...state,
            adminMessage: action.payload,
            loading: false,
        }
        case PARENT_MESSAGE:
        return{
            ...state,
            parentMessages: action.payload,
            loading: false,
        }
    default:
      return state;
  }
};
export default messageReducer;
