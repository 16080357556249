import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import {
  Modal, ModalHeader, ModalBody, Alert, Button
} from 'reactstrap';
import { authRoles } from "../../../../config/authroles"
import {
  Icon,
  Tooltip,
  Button as MaterialBtn
} from '@material-ui/core';
import RegisterForm from '../../../../shared/components/registerForm/RegisterForm';
import AuthGoogleBtn from "containers/Account/AuthBtn/googleAuthBtn"
import AuthFacebookeBtn from "containers/Account/AuthBtn/fbAuthBtn"
import MicrosoftLogin from "containers/Account/AuthBtn/MicrosoftLogin"
import GoogleRecaptcha from "shared/components/auth/GoogleRecaptcha"
const ModalRegisterForm = ({
  title,
  isOpen,
  handleRegister,
  error,
  closeModal,
  selectRole,
  auth,
  roleCode,
  registerPassword,
  registerEmail,
  errorMessage,
  errorMsg,
  setuserDataLocal
}) => (
    <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="md" isOpen={isOpen} centered>
      <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
      <ModalBody className="login-form" >

        <div className="account__social mb-10">
          <Tooltip title="Register as parent">
            <MaterialBtn
              onClick={() => selectRole(authRoles.Parents)}
              size="small"
              color={roleCode === authRoles.Parents ? "primary" : "inherit"}
            >
              <Icon
                style={{ color: roleCode === authRoles.Parents ? "#ef3e37" : "#595959" }}
              >
                face_icon
            </Icon>
            Parent
          </MaterialBtn>
          </Tooltip>

          <Tooltip title="Register as provider.">
            <MaterialBtn
              onClick={() => { selectRole(authRoles.Provider) }}
              size="small"
              color={roleCode === authRoles.Provider ? "primary" : "inherit"}
            >
              <Icon
                style={{ color: roleCode === authRoles.Provider ? "#ef3e37" : "#595959" }}
              >
                people
            </Icon>
            Provider
          </MaterialBtn>
          </Tooltip>


        </div>
        <Alert
          color="danger"
          isOpen={!!errorMessage || !!errorMsg}
        >
          {errorMessage}
          {errorMsg}
        </Alert>
        <div className="account__social my-10">
          <AuthGoogleBtn setuserDataLocal={(user) => setuserDataLocal(user)} roleCode={roleCode} form="modal_register" />
        </div>

        <div className="sociallogins">
          <div className="account__social my-10 facebookbtn">
            <AuthFacebookeBtn setuserDataLocal={(user) => setuserDataLocal(user)} roleCode={roleCode} form="modal_register" />
          </div>
          <div className="account__social my-10 microsoftbtn">
            <MicrosoftLogin setuserDataLocal={(user) => setuserDataLocal(user)} roleCode={roleCode} form="modal_register" />
          </div>
        </div>
        <div className="account__or my-20">
          <p>Or  Using </p>
        </div>
        <div className="mt-10">
          <RegisterForm
            errorMessage={error}
            form="modal_register"
            fieldUser="Email"
            typeFieldUser="email"
            handleRegister={handleRegister}
            registerEmail={registerEmail}
            registerPassword={registerPassword}
          />
        </div>


        <GoogleRecaptcha />
      </ModalBody>
    </Modal>
  );

ModalRegisterForm.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  // onSubmit: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  onGoogleClick: PropTypes.func,
  onFacebookClick: PropTypes.func,
  onMicrosoftClick: PropTypes.func
};

ModalRegisterForm.defaultProps = {
  title: '',
  error: '',
  isOpen: false,
  onSubmit: () => { },
  onGoogleClick: () => { },
  onFacebookClick: () => { },
  onMicrosoftClick: () =>{ },
};

export default connect(state => ({
  errorMessage: state.user.error
}))(ModalRegisterForm);
