import React, { useState } from 'react';
import { connect } from "react-redux"
import {
    Modal, ModalHeader, ModalBody, Table, Badge
} from 'reactstrap';
import {
    ListItem,
    ListItemText,
    ListItemIcon,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup
} from "@material-ui/core"
import { programId } from 'redux/actions/reviewAction';
const CancelRefund = ({
    title,
    isOpen,
    closeModal,
    historyDetail = {},
    handleProgramCancelConfirm,
    getStatusColor
}) => {
    const [items, setItems] = useState([

    ])
    const getNewStudents = (value = []) => {
        return value.map((stu) => ({
            ...stu,
            checked: false
        }))
    }
    React.useEffect(() => {
        console.log(historyDetail)

        let newHistory = historyDetail
            &&
            historyDetail.programOrder.map((po) => {
                let programName = {
                    ...po,
                    checked: false,
                    students: getNewStudents(po.students)
                }
                return programName
            })
        console.log(newHistory)
        setItems(newHistory)
    }, [])
    const handleProgramId = (programIdx) => e => {
        let newChecked = items.map((p, i) => {
            if (i === programIdx) {
                return ({
                    ...p,
                    checked: e.target.checked
                })
            } else {
                return ({
                    ...p
                })
            }
        })
        setItems(newChecked)
    }
    const handleStudentId = (programIdx, studentIndx) => e => {
        let newStudent = items[programIdx].students.map((ns, index) => {
            if (index === studentIndx) {
                return ({
                    ...ns,
                    checked: e.target.checked,
                })
            } else {
                return ({

                    ...ns
                })
            }
        })
        let newChecked = items.map((p, i) => {
            if (i === programIdx) {
                return ({
                    ...p,
                    students: newStudent
                })
            } else {
                return ({
                    ...p
                })
            }
        })

        setItems(newChecked)
    }

    const handleCancel = () => {
        const getStudentId = (l = []) => {
            let newId = []
            l.map(n => {
                if (n.checked) {
                    newId.push(n.studentId)
                }
            })
            return newId
        }
        let getCancelItem = items.map(p => {
            if (p.checked) {
                return ({
                    programId: p.programId,
                    studentId: getStudentId(p.students)
                })
            }
        })
        let data = {
            transactionId: historyDetail.id,
            items: getCancelItem
        }

        handleProgramCancelConfirm(data)
    }
    return (
        <Modal className="theme-light ltr-support login-modal-form" size="lg" isOpen={isOpen} centered>
            <div id="divToPrint">
                <ModalHeader toggle={closeModal}><strong>{title}</strong></ModalHeader>
                <ModalBody className="login-form "   >
                    <div className="text-left"  >

                        <h5 className="my-10" >

                            Order Id: <strong>{historyDetail.orderId}</strong>
                        </h5>
                        <h5 className="my-10">
                            Parent Name: <strong>{historyDetail.parentName}</strong>
                        </h5>
                        <h5 className="my-10">
                            Date of Payment: <strong>{historyDetail.dateOfPayment}</strong>
                        </h5>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Program Name</th>
                                    <th>Students</th>
                                    <th>Amount(CA$)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {items.map((p, i) => {

                                    return (<tr key={i}>
                                        <td>
                                            <Checkbox
                                                checked={p.checked}
                                                onChange={handleProgramId(i)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />

                                        </td>
                                        <td>{p.programName}</td>
                                        <td> <span>
                                            {p.students && p.students.map((s, index) => {
                                                return (
                                                    <ListItem dense key={index} role={undefined} button>
                                                        {p.checked
                                                            &&
                                                            (<ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={s.checked}
                                                                    onChange={handleStudentId(i, index)}
                                                                    disableRipple

                                                                />
                                                            </ListItemIcon>)}

                                                        <ListItemText
                                                            primary={`${index + 1}.${s.strudentname} `} />
                                                    </ListItem>
                                                )
                                            })}
                                        </span></td>
                                        <td>{p.amount ?? 0} x {p.students.length ?? 0}</td>
                                        <td>
                                            <Badge
                                                color={getStatusColor(historyDetail.paymentStatusEnum)}>
                                                {historyDetail.paymentStatusString}
                                            </Badge>
                                        </td>

                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <hr />
                    <div className="flex flex-space-between">
                        <h3 className="text-left">Total : <strong>CA${historyDetail.amount}</strong> </h3>
                        <Button style={{ marginRight: "51px" }} color="primary" onClick={handleCancel} >Proceed</Button>
                    </div>
                </ModalBody>
            </div>

        </Modal>
    );
}




export default connect(state => ({
    errorMessage: state.user.error
}))(CancelRefund);
