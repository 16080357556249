import React from "react";
import { Button } from "@material-ui/core";
import { Card, CardBody, Col } from "reactstrap";
import { Avatar } from "@material-ui/core";
import Badge from '@material-ui/core/Badge';
import { Skeleton } from "@material-ui/lab"
import Divider from '@material-ui/core/Divider';
const Ava = `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`;

const ProfileMain = ({ admin, handleChangePassModal, loading }) => {
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">

          {
            loading ?
              <div className="text-center">
                <Badge>

                  <Skeleton variant="circle" width={150} animation="wave" height={150} />
                </Badge>
              </div> : (
                <div className="mb-1 pt-2 text-center">
                  <Badge>
                    <Avatar
                      className="profile__avatar"
                      src={admin.avatar ? admin.avatar : Ava}
                    ></Avatar>
                  </Badge>
                </div>
              )
          }
          {

            loading ? (
              <div>
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton variant="rect" width="100%" animation="wave" height={10} />
              </div>
            )
              :
              <div className="profile__stat">
                <h4 className="profile__name text-transform-none">
                  {`${admin.fName}+ " " + ${admin.lName}`}
                </h4>

                <p className="profile__contact mt-2" dir="ltr">
                  {admin.email}
                </p>
              </div>
          }
          {/* <Divider /> */}


          <div className="text-center mb-3">
            <Button
              size="small"
              color="primary"
              onClick={handleChangePassModal}
              variant="outlined"
            >
              Change Password
            </Button>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
