import {
    SET_UNIV_LIST_PROGRAM, SET_UNIV_LIST_EXPERIANCE, SET_UNIV_LIST_PROVIDER,SET_UNI_LOADING,
    SET_GLOBAL_SEARCH_TEXT
} from "redux/types"

const initialState = {
    uniProgram: [],
    uniExperiance: [],
    uniProvider: [],
    uniLoading: true
}
const universalReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_UNIV_LIST_PROGRAM:
            return {
                ...state,
                uniProgram: action.payload,
            };
        case SET_UNIV_LIST_EXPERIANCE:
            return {
                ...state,
                uniExperiance: action.payload,
            };
        case SET_UNIV_LIST_PROVIDER:
            return {
                ...state,
                uniProvider: action.payload,
            }
        case SET_UNI_LOADING:
            return {
                ...state,
                uniLoading:action.payload
            }
        case SET_GLOBAL_SEARCH_TEXT:
            return {
                ...state,
                uniSearchText:action.payload
            }
        default:
            return {
                ...state,
                uniLoading:false
            };
    }
};

export default universalReducer;
