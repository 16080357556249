/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
import Snackbar from '@material-ui/core/Snackbar';
import { ImageNotification, FullWideNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import {
  changeThemeToDark, changeThemeToLight,
} from '../../redux/actions/themeActions';
import {
  changeDirectionToRTL, changeDirectionToLTR,
} from '../../redux/actions/rtlActions';
import { changeBorderRadius, toggleBoxShadow, toggleTopNavigation } from '../../redux/actions/customizerActions';
import {
  CustomizerProps, SidebarProps, ThemeProps, RTLProps, UserProps,
} from '../../shared/prop-types/ReducerProps';
import { authRoles } from "../../config/authroles";

let notification = null;
const logo = require("../../shared/img/logo/LOGO.png")

// eslint-disable-next-line no-return-assign
NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
    user: UserProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      providerwarning: false
    }

  }
  componentDidMount() {
    var that = this;
    setTimeout(function () {
      // that.showProviderAlert();
      

    }, 8000);

    // if (user.isAuthenticated) {
    //   this.showMessage()
    // }  
  }
  showProviderAlert() {
    this.setState({ providerwarning: true });
  }
  showMessage = () => {
    let { user } = this.props
    const title = `Welcome Back  ${user.name} `;
    const message = '';
    const notificationInitialProps = {
      content: <ImageNotification
        img={user.avatar}
        title={title}
        message={message}
        theme={this.props.theme}
      />,
      closable: true,
      duration: 5,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: `right-up ${this.props.rtl.direction}-support`,
    };
    notification.notice(notificationInitialProps);
  }

  componentWillUnmount() {
    notification.destroy();
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  changeToRTL = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToRTL());
  };

  changeToLTR = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToLTR());
  };

  toggleTopNavigation = () => {
    const { dispatch } = this.props;
    dispatch(toggleTopNavigation(true));
  };

  changeBorderRadius = () => {
    const { dispatch } = this.props;
    dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    const { dispatch } = this.props;
    dispatch(toggleBoxShadow());
  };

  render() {
    const {
      customizer, sidebar, theme, rtl, user, provider, parent
    } = this.props;
       
    if(this.state.providerwarning && user && user.isAuthenticated && user.role == authRoles.Provider && provider && !provider.profileLoading && !provider.organisationName){
    }
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': !user.isAuthenticated,
    });

    return (
      <div className={layoutClass}>
        {/* <Customizer
          customizer={customizer}
          sidebar={sidebar}
          theme={theme}
          rtl={rtl}
          changeSidebarVisibility={this.changeSidebarVisibility}
          toggleTopNavigation={this.toggleTopNavigation}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeToRTL={this.changeToRTL}
          changeToLTR={this.changeToLTR}
          changeBorderRadius={this.changeBorderRadius}
          toggleBoxShadow={this.toggleBoxShadow}
        /> */}
        {this.state.providerwarning && user && user.isAuthenticated && user.role == authRoles.Provider && provider && !provider.profileLoading && !provider.organisationName}
        <Snackbar
          open={this.state.providerwarning && user && user.isAuthenticated && user.role == authRoles.Provider && provider && !provider.profileLoading && !provider.organisationName}
          message="Please update organisation name"
        />

        <Snackbar
          open={this.state.providerwarning  && user && user.isAuthenticated && user.role == authRoles.Parents && parent && (!parent.address1 || !parent.city)}
          message="Please complete your profile to use Dwul.io. Go to Accounts from the top right icon to update."
        />

        {!user.isAuthenticated
          ? (
            <TopbarWithNavigation
              changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
              logo={logo}
            />
          )
          : (
            <Topbar
              changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
              changeSidebarVisibility={this.changeSidebarVisibility}
              user={user}
              logo={logo}
            />
          )
        }
        {user.isAuthenticated ? (<Sidebar
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />) : (
          <SidebarMobile
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          />)}

      </div>
    );
  }
}

export default withRouter(connect(state => ({
  customizer: state.customizer,
  sidebar: state.sidebar,
  theme: state.theme,
  rtl: state.rtl,
  user: state.user,
  provider: state.provider?.providerProfile,
  parent: state.profile?.parentProfile
}))(Layout));
