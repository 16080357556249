import { get } from './base/index';
import {post} from "./base/index"

export default {
    parentFavorite: () =>{
        return get(`${window.apiUrl}UserProfile/parent-favorites`,{},true)
    },
    // to get fav provider profile
    parentproviderProfile: (id) =>{
        return get(`${window.apiUrl}UserProfile/get-provider-profile?Id=${id}`,{},true)
    },
}