import { config } from '../../translations';
import {
  GET_ALL_STUDENT,
  SELECT_STUDENT,
  GET_STUDENT_ENROLLED_LIST,
  GET_STUDENT_LIST,
  STUDENT_LIST_BY_PROGRAM_ID,
  STUDENT_PROFILE_BY_ID
} from '../types';
const initialState = {
  studentList: [],
  selectedStudent: [],
  studentEnrolledList: [],
  studentAllList: [],
  studentLoading: true,
  studentAllListLoading: true,
  studentListByProgramId: [],
  studentProfile:{}
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT:
      return {
        ...state,
        studentList: action.payload
      }
    case SELECT_STUDENT:
      return {
        ...state,
        selectedStudent: action.payload
      }
    case GET_STUDENT_ENROLLED_LIST:
      return {
        ...state,
        studentEnrolledList: action.payload,
        studentLoading: false
      }
    case GET_STUDENT_LIST:
      return {
        ...state,
        studentAllList: action.payload,
        studentAllListLoading: false,
      }
    case STUDENT_LIST_BY_PROGRAM_ID:
      return {
        ...state,
        studentListByProgramId: action.payload
      }
      case STUDENT_PROFILE_BY_ID:
      return {
        ...state,
        studentProfile: action.payload
      }
    default:
      return state;
  }
};

export default studentReducer;
