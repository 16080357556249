import { ANNOUNCEMENT_LIST, ANNOUNCEMENT_DETAILS, ANNOUNCEMENT_BY_USER } from "../types"
import api from "../../utils/apiClient"
import { showSucessNoti, showWarningNoti, } from "../../redux/actions/notificationAction"
export const getAnnouncement = () => async dispatch => {
    await api.announcement.getAnnouncement().then(res => {
        if (res) {
            dispatch({
                type: ANNOUNCEMENT_LIST,
                payload: res.data
            })
        }
    }).catch(err => console.log(err))
}
export const getAnnouncementDetails = (id) => async dispatch => {
   
    await api.announcement.getAnnouncementDetails(id).then(res => {
        if (res) {
            dispatch(setAnnouncementDetails(res.data))
        }
    }).catch(err => console.error(err))
}
export const setAnnouncementDetails = (data) => dispatch => {
    dispatch({
        type: ANNOUNCEMENT_DETAILS,
        payload: data
    })
}
export const getAnnouncementByUser = () => async dispatch => {
    await api.announcement.getAnnouncementByUser().then(res => {
        if (res) {
            dispatch({
                type: ANNOUNCEMENT_BY_USER,
                payload: res.data
            })
        }
    }).catch(err => console.error(err))
}