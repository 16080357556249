import {
    GET_PROGRAM, GET_PROVIDER_PROGRAM, SEND_PROGRAM, GET_PROVIDER_PROGRAM_BY_ID, SET_PROGRAM_BY_ID,
    GET_ENROLLED_PROGRAM, PARENT_PROGRAM_DATES, PROGRAM_LIST_BY_PROVIDER, PROGRAM_DETAILS_LOADING, ALL_PROGRAM_LOADING, SEARCH_PROVIDER_PROGRAM
} from "../types"
import api from '../../utils/apiClient';
import moment from "moment";
// import { dateFormat,stringToTime } from "../../../shared/helpers"


export const providerProgram = () => async (dispatch) => {
    await api.program.getProviderProgram(true).then(res => {
        if (res.data) {
            dispatch({
                type: GET_PROVIDER_PROGRAM,
                payload: res.data
            })
        }
    }).catch(res => {

    })
}

export const setProgramSearch = (data) => async (dispatch) => {
    dispatch({
        type: SEARCH_PROVIDER_PROGRAM,
        payload: data
    })
}

export const providerProgramForAdd = () => async (dispatch) => {
    await api.program.getProviderProgram(true).then(res => {

        if (res.data) {
            dispatch({
                type: GET_PROGRAM,
                payload: res.data.item1
            })
        }
    }).catch(res => {

    })
}

export const getAllProgram = ( {searchText = "",
    tags = [], activityTags = [],
    ageGroup = "", dayOfWeek = "", locationTags = [], pageNo = 0, pageSize = 0 }) => async (dispatch) => {
        console.log("page number getallprograms ", pageNo)
        console.log("page size getallprograms ", pageSize)
        
        dispatch({
            type: GET_PROGRAM,
            payload: []
        })
        
        dispatch(allProgramLoading(true))
        let data = {
            searchText: searchText,
            tags: tags,

            activityTags: activityTags,
            locationTags: locationTags,
            ageGroup: ageGroup,
            dayOfWeek: dayOfWeek,
            pageNo: pageNo,
            pageSize: pageSize,
        }
        // console.log("data getallprograms ", data)

        api.program.getAllProgram(data, false)
            .then(res => {
                if (res.data) {
                    let pData = res.data

                    dispatch(setAllProgram(pData))
                }
            })
            .catch(err => {
                console.log(err.message)
                dispatch(allProgramLoading(false))
            })
    }
export const setAllProgram = (pData) => async dispatch => {
    dispatch({
        type: GET_PROGRAM,
        payload: pData
    })
}

export const sendProgram = (formData) => async (dispatch) => {
    await api.program.post(formData, true)
        .then(res => {
            if (res.data) {
                dispatch({
                    type: SEND_PROGRAM,
                    payload: res.data
                })
            }
        })
        .catch(err => console.log(err))
}



export const getProviderProgramById = (id) => async (dispatch) => {
    await api.program.getProgramById(id, true).then(res => {
        // debugger
        if (res.data) {
            // debugger
            let regulerDates = res.data.regulerDates ? res.data.regulerDates.map(c => moment(c).format('YYYY-MM-DD')) : null;
            let specialDates = res.data.specialDates ? res.data.specialDates.map(c => moment(c).format('YYYY-MM-DD')) : null;
            let noSession = res.data.noSession ? res.data.noSession.map(c => moment(c).format('YYYY-MM-DD')) : null;
            let regularStartTime = res.data.regularStartTime ? moment(res.data.regularStartTime, "HH:mm") : null
            let regularEndTime = res.data.regularEndTime ? moment(res.data.regularEndTime, "HH:mm") : null
            let specialStartTime = res.data.specialStartTime ? moment(res.data.specialStartTime, "HH:mm") : null
            let specialEndTime = res.data.specialStartTime ? moment(res.data.specialStartTime, "HH:mm") : null
            let obj = { ...res.data, regulerDates, specialDates, noSession, regularStartTime, regularEndTime, specialStartTime, specialEndTime };
            dispatch(setProviderProgramById(obj))


            // debugger
        }
    }).catch(err => {
        console.error(err.message)
    })
}

export const setProviderProgramById = (data) => async (dispatch) => {
    dispatch({
        type: GET_PROVIDER_PROGRAM_BY_ID,
        payload: data
    })
}


//  for getting program details by id on parent 

export const getProgramById = (id) => async (dispatch) => {
    dispatch(detailsLoading(true))
    await api.program
        .getProgramById(id, true)
        .then((res) => {
            if (res.data) {
                dispatch(setProgramById(res.data));

            }
        })
        .catch((err) => {
            console.error(err.message)
        });
};

export const setProgramById =
    (data) => async (dispatch) => {
        dispatch({
            type: SET_PROGRAM_BY_ID,
            payload: data,
        });
    };

export const getEnrolledProgram = () => async dispatch => {
    await api.program.enrolledPrograms(true).then(res => {
        if (res.data) {
            dispatch({
                type: GET_ENROLLED_PROGRAM,
                payload: res.data
            })
        }
    })
        .catch(err => {

        })
}
export const parentProgramDates = () => async dispatch => {
    await api.program.allProgramDates(true).then(res => {
        // debugger
        if (res.data) {
            dispatch({
                type: PARENT_PROGRAM_DATES,
                payload: res.data
            })
        }
    })
}



export const detailsLoading = (status) => async dispatch => {
    dispatch({
        type: PROGRAM_DETAILS_LOADING,
        payload: status
    })
}
export const allProgramLoading = (status) => async dispatch => {
    dispatch({
        type: ALL_PROGRAM_LOADING,
        payload: status
    })
}

export const programListingByProvider = (id) => async dispatch => {
    let list = {
        programs: [],
        experience: []
    }
    dispatch({
        type: PROGRAM_LIST_BY_PROVIDER,
        payload: list
    })
    await api.program.programListing(id, true).then(res => {
        if (res.data) {
            list.programs = res.data.item1
        }
    }).catch((err) => {
        console.error(err.message)
    });
    await api.experience.experienceListing(id, true).then(res => {
        if (res.data) {
            list.experience = res.data.item1
        }
    }).catch((err) => {
        console.error(err.message)
    });
    dispatch({
        type: PROGRAM_LIST_BY_PROVIDER,
        payload: list
    })
}