import React, { useState } from "react";
import Panel from "../../../shared/components/Panel";
import SearchableTable from "../../../shared/components/SearchableTable";
import { Button, Icon, TableRow, TableCell } from "@material-ui/core";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import moment from "moment";
import { getTimeDiff } from "shared/helpers"

const rows = [
  {
    id: 0,
    label: "Provider Name",
  },
  {
    id: 1,
    label: "Plan Name",
  },
  {
    id: 2,
    label: "Date of Purchase",
  },
  {
    id: 3,
    label: "Days Left",
  },
  {
    id: 4,
    label: "Cancellation Date",
  },
  {
    id: 5,
    label: "Date of Renewal",
  },
  {
    id: 6,
    label: "",
  },
];
const PurchaseHistoryTable = ({ list, refreshSubscription }) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const clearSearch = () => {
    setSearchValue("");
  };
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleTablesSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  const tableData = () => {
    let tableData = [];
    if (list) {
      console.log("list of cancel", list)
      list.map((sub) => {
        tableData.push({
          id: sub.id,
          subscriptionName: sub.subscriptionName,
          createdBy: sub.createdBy,
          dateOfPurchase: sub.createdOn,
          dateOfRenewal: sub.endDate,
        });
      });
    }
    return tableData;
  };
  let filteredData = tableData();
  if (searchValue) {
    filteredData = tableData().filter((search) =>
      search.createdBy.toLowerCase().startsWith(searchValue.toLowerCase())
    );
  }
  { console.log("filteredData", filteredData) }
  return (

    <>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget py-28 px-24">
            <h5 className="uppercase bold-text font-size-13 mb-24">
              Purchase History
            </h5>
            <SearchableTable
              title=""
              rows={rows}
              filteredData={filteredData}
              loading={refreshSubscription}
              actionTablebtn={false}
              actionBtnIcon="add"
              actionBtnText=""
              actionBtnClick={false}
              searchType="text"
              searchLabel="Search"
              searchValue={searchValue}
              clearSearch={clearSearch}
              searchPlaceholder="Search"
              pagination={true}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              getSearchResult={handleTablesSearch}
            >
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">{(index + 1) + (page * rowsPerPage)}</TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {item.createdBy}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {item.subscriptionName}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {moment(item.dateOfPurchase).utc().format("MMM DD, YYYY ")}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {/* {moment(item.dateOfRenewal).format("l")} */}
                      {`${getTimeDiff(item.dateOfRenewal)} Days`}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {item.cancelledDate ? moment(item.cancelledDate).utc().format("MMM DD, YYYY ") : "---"}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {moment(item.dateOfRenewal).utc().format("MMM DD, YYYY ")}
                    </TableCell>

                    {/* <TableCell className="material-table__cell">
                      <UncontrolledDropdown className="dashboard__table-more">
                        <DropdownToggle className="m-auto">
                          <p>
                            <DotsHorizontalIcon />
                          </p>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown__menu ">
                          <DropdownItem >
                            Edit
                          </DropdownItem>
                          <DropdownItem>Deactivate</DropdownItem>
                          <DropdownItem >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </TableCell> */}
                  </TableRow>
                ))}
            </SearchableTable>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default PurchaseHistoryTable;
