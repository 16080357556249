
import {
  ALL_EXPERIANCE, GET_PROVIDER_EXPERIENCE, SEND_EXPERIENCE, GET_PROVIDER_EXP_BY_ID, ALL_EXPERIANCE_LOADING
} from '../types';
const initialState = {
  getAllExperiance: [],
  providerExperienceData: [],
  providerExpById: {},
  loading: true,
};

const experianceReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case ALL_EXPERIANCE:
      return {
        ...state,
        getAllExperiance: action.payload,
        loading:false
      }
    case SEND_EXPERIENCE:
      return {
        ...state,
        payload: action.payload
      }
    case GET_PROVIDER_EXPERIENCE:
      return {
        ...state,
        providerExperienceData: action.payload,
        loading: false
      }
    case GET_PROVIDER_EXP_BY_ID:
      return {
        ...state,
        providerExpById: action.payload
      }
    case ALL_EXPERIANCE_LOADING:
      return {
        ...state,
        loading:action.payload
      }
    default:
      return state;
  }
};

export default experianceReducer;
