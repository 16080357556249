import React from 'react';
import Slider from 'react-slick';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import CrosshairsGpsIcon from 'mdi-react/CrosshairsGpsIcon';
import { withTranslation } from 'react-i18next';
import Panel from '../../../shared/components/Panel'
import { Icon } from "@material-ui/core"
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import CounterCard from "../../../shared/components/CounterCard"

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  swipeToSlide: true,
  // vertical: true,
  // verticalSwiping: true,
  arrows: false,
  slidesToScroll: 1,
  responsive: [
    { breakpoint: 498, settings: { slidesToShow: 1 } },
    { breakpoint: 769, settings: { slidesToShow: 1 } },
    { breakpoint: 820, settings: { slidesToShow: 2 } },
    { breakpoint: 900, settings: { slidesToShow: 2 } },
    { breakpoint: 990, settings: { slidesToShow: 2 } },
    { breakpoint: 1050, settings: { slidesToShow: 2 } },
    { breakpoint: 1200, settings: { slidesToShow: 3 } },
    { breakpoint: 1536, settings: { slidesToShow: 1 } },
    { breakpoint: 100000, settings: { slidesToShow: 1 } },
  ],
};

const TagsCounter = ({
  onRefresh,
  refreshTagsCounter
}) => {
  const { categoryTag, locationTag, schoolTag, activityTag, cityTag } = useSelector(state => state.tags)
  const tagLabel = [
    { label: "Categories", icon: "category", count: categoryTag.length, color: "color1" },
    { label: "Cities", icon: "location_on", count: locationTag.length, color: "color2" },
    { label: "Schools", icon: "school", count: schoolTag.length, color: "color3" },
    { label: "Activities", icon: "local_activity", count: activityTag.length, color: "color4" },
    // { label: "City", code: "tagTypes.City", icon: "location_city", count: cityTag.length, color: "color5" },
  ]
  return (
    // <Panel md={6} lg={6} xl={4} sm={12} xs={12} onRefresh={onRefresh} refresh={refreshTagsCounter} title="Tag Stats">
      <Col md={12} xl={4} lg={12} sm={12} xs={12} className="md-m-auto">
    <Card>
      <CardBody className="dashboard__card-widget py-28 px-24">
        <h5 className="mb-16 uppercase bold-text font-size-13">Tags Counter</h5>
      <Slider {...settings} className="m-auto">
        {tagLabel.map((item, index) => (<CounterCard
          count={item.count}
          label={item.label}
          icon={item.icon}
          color={item.color} />))}
      </Slider>
      </CardBody>
      </Card>
      </Col>
    // </Panel>
  )
};

export default withTranslation('common')(TagsCounter);
