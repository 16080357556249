import React from "react";
import {
  closeMessagesAdmin,
} from "../../../../../redux/actions/messageActions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Messages = ({}) => {
  const dispatch = useDispatch();
  const [messageId, setMessageId] = React.useState("");
  const  {openMessagesAdmin} = useSelector((state) => state.message);
  const handleClose = () => {
    dispatch(closeMessagesAdmin(false));
  };
  return (
    <>
      <div className="text-right">
        <Tooltip title="Close">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      { openMessagesAdmin &&
         openMessagesAdmin.length > 0 &&
         openMessagesAdmin.map((data, index) => {
         
          return (
            <Col md={12} xl={12} lg={12} key={index}>
              <Card>
                <CardBody className="dashboard__card-widget">
                  <div>
                    <div className="card__title flex flex-space-between">
                      <h5 className="bold-text">{data.typeName}</h5>
                      <h6> {new Date(data.date).toLocaleString()}</h6>
                    </div>
                    {/* <h5 className="text-muted">{data.programName}</h5> */}

                    <div>
                      <h5 className="bold-text mb-2 mt-2">{data.subject}</h5>
                    </div>
                  </div>

                  {/* <h5 className="bold-text pt-2">{data.parentName}</h5> */}
                  <p className="mb-2">{data.fullMessage}</p>
                  <div className="text-right">
                    <div className="text-left">
                      <p>
                        <span>{data.parentName}</span>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
    </>
  );
};
export default Messages;
