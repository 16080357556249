import React from "react";
import Button from "@material-ui/core/Button";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { renderField } from "../../../shared/components/form/RenderField";
import { validate } from "../../../config/formValidation";
import renderFieldArray from "../../../shared/components/form/renderFieldArray";

function AddSubscription({
  isOpen,
  closeModal,
  handleSubmit,
  isEdit,
  submitting,
  valid,
  addSubs,
  initialValues,

}) {
  return (
    <div>
      <Modal
        toggle={closeModal}
        className="theme-light ltr-support login-modal-form"
        isOpen={isOpen}
        centered
      >
        <ModalHeader toggle={closeModal}>
          <h5>
            <strong>{isEdit ? "Edit Subscription" : "Add Subscription"}</strong>
          </h5>
        </ModalHeader>
        <form onSubmit={handleSubmit(addSubs)}>
          <ModalBody>
            <div>
              <Field
                component={renderField}
                name="subscriptionName"
                label="Subscription Name*"
                placeholder="Subscription Name*"
                type="text"
                multiple={false}
                startIcon="titleIcon"
              />
            </div>
            <div className="form__form-group">
              <FieldArray
                name="descriptions"
                label="Description*"
                placeholder="Description"
                component={renderFieldArray}
                type="text"
                className="input-without-border-radius"
                // startIcon="local_activity"
                disabled={true}
              />
            </div>

            <div className="form__form-group">
              <Field
                name="costPerMonth"
                label="Cost Per Month*"
                placeholder="Cost Per Month*"
                component={renderField}
                type="number"
                className="input-without-border-radius"
                startIcon="attach_money"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="annually"
                label="Anually*"
                placeholder="Anually*"
                component={renderField}
                type="number"
                className="input-without-border-radius"
                startIcon="attach_money"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="commisionPercentage"
                label="Commission Percentage(%)*"
                placeholder="Commission Percentage(%)*"
                component={renderField}
                type="number"
                className="input-without-border-radius"
                startIcon="label"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="cents"
                label="Cents* (ex: 1-100)"
                component={renderField}
                type="number"
                className="input-without-border-radius"
                startIcon="attach_money"
              />
            </div>
          </ModalBody>
          <ModalFooter>

            <Button
              variant="outlined"
              autoFocus
              color="secondary"
              onClick={closeModal}
            >
              Close
            </Button>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              disabled={!valid || submitting}
              color="primary"
              className="mx-10"
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}
const selector = formValueSelector("SubscriptionForm")
export default connect((state) => {

  return ({

    initialValues: {
      id: state.subscription.getSubscriptionById.id
        ? state.subscription.getSubscriptionById.id
        : 0,
      subscriptionName: state.subscription.getSubscriptionById.subscriptionName
        ? state.subscription.getSubscriptionById.subscriptionName
        : "",
      descriptions: state.subscription.getSubscriptionById.descriptions
        ? state.subscription.getSubscriptionById.descriptions
        : [""],
      costPerMonth: state.subscription.getSubscriptionById.costPerMonth
        ? state.subscription.getSubscriptionById.costPerMonth
        : 0,
      annually: state.subscription.getSubscriptionById.annually
        ? state.subscription.getSubscriptionById.annually
        : 0,
      commisionPercentage: state.subscription.getSubscriptionById.commisionPercentage
        ? state.subscription.getSubscriptionById.commisionPercentage
        : 0,
      cents: state.subscription.getSubscriptionById.cents
        ? state.subscription.getSubscriptionById.cents :
        0
    },
  })
})(
  reduxForm({
    form: "SubscriptionForm",
    enableReinitialize: true,
    validate,
  })(AddSubscription)
);
