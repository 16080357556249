import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Panel from '../../../shared/components/Panel'
import Panel from "../../../../shared/components/Panel";
import { Col, Card, CardBody, Table, Container, Row } from "reactstrap";
import { getUserList } from "../../../../redux/actions/userAction";
import SearchableTable from "../../../../shared/components/SearchableTable";
import { TableRow, TableCell, Avatar } from "@material-ui/core";

import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

const rows = [
  {
    id: 1,
    label: "",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Email",
  },
];
const AddUserTable = ({ openSubModal }) => {
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.users);
  const loading = useSelector((state) => state.users.loading);

  useEffect(() => {
    dispatch(getUserList(""));
  }, []);

  const clearSearch = () => {
    setSearchValue("");
  };
  const handleTablesSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  let filteredData = userList.filter((item) => item.id !== 2);
  if (searchValue) {
    filteredData = userList.filter((search) => {
      if (search.name.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      } else if (
        search.email.toLowerCase().startsWith(searchValue.toLowerCase())
      ) {
        return search;
      }
    });
  }
  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <SearchableTable
                title=""
                rows={rows}
                filteredData={filteredData}
                filterAction={true}
                loading={loading}
                actionTablebtn={true}
                actionBtnIcon="add"
                actionBtnText="Add User"
                actionBtnClick={openSubModal}
                searchType="text"
                searchLabel="Search"
                searchValue={searchValue}
                clearSearch={clearSearch}
                searchPlaceholder="Search User"
                pagination={false}
                getSearchResult={handleTablesSearch}
              >
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">{index + 1}</TableCell>
                    <TableCell className="material-table__cell">
                      <Avatar src={item.avatar} alt={item.name} />
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap">
                      {item.name}
                    </TableCell>
                    <TableCell className="material-table__cell">
                      {item.email}
                    </TableCell>
                  </TableRow>
                ))}
              </SearchableTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUserTable;
