import React from 'react';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from "redux-form"
import { TextField, InputAdornment, Icon, IconButton } from "@material-ui/core"
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function AddNewFormTag({
    isOpen,
    closeModal,
    handleTag,
    tagName,
    isEdit,
    startIcon,
    label,
    handleTagInput
     }) {
    return (
        <div>
            <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
                <ModalHeader toggle={closeModal}><h5><strong>{isEdit ? "Edit Tag" : "Add Tag"}</strong></h5></ModalHeader>
                <form
                    className="login-form"
                >
                    <ModalBody>
                        <div className="form__form-group">
                            <TextField
                                className="mb-10"
                                name="tagType"
                                label={label}
                                type="text"
                                disabled
                                value={label}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>{startIcon}</Icon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Select Tag Type"
                            />
                        </div>
                        <div className="form__form-group">
                            <TextField
                            value={tagName}
                                name="name"
                                label="Name"
                                type="text"
                                placeholder="Name"
                                className="mb-10"
                                type="text"
                                variant="outlined"
                                size="small"
                                onChange={handleTagInput}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>label</Icon>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>

                    </ModalBody>
                    <div className="flex flex-end flex-middle ">

                        <div>
                            <Button
                                className="m-0"
                                variant="outlined"
                                type="button"

                                onClick={closeModal}
                                color="secondary">
                                Cancel
                        </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                className="mx-10"
                                autoFocus
                                onClick={handleTag}
                                color="primary">
                                Save
                        </Button>
                        </div>

                    </div>


                </form>

            </Modal>
        </div>
    );
}
export default AddNewFormTag