import React from 'react';
import { resloveMessage, getMessage } from "../../../../redux/actions/messageActions"
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import { closeMessages } from '../../../../redux/actions/messageActions'
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import {Icon} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ResloveModel from './ResloveModal'
import ConfirmationModal from "../../../../shared/components/ConfirmationModal"

const Messages = ({}) => {

  const dispatch = useDispatch()
  const [resolveMessageConfirm, setResolveMessageConfirm] = React.useState(false);
  const [messageId,   setMessageId] = React.useState("");
  const openMessages = useSelector((state) => state.message.openMessages);
  const handleClose = () => {
    dispatch(closeMessages(false))
  };
  const handleApproveMsg = (id) => {
    setMessageId(id)
    setResolveMessageConfirm(true)
    
  }
  const hanldeMsgConfirm = () => {
    dispatch(resloveMessage(messageId)).then(() => {
      setResolveMessageConfirm(false)
      dispatch(getMessage())
    })
  }
  return (
    <>
      <div className="text-right mb-16">
        <Tooltip title="Go Back">
          <Button aria-label="close" size="small" onClick={handleClose} >
            <Icon>arrow_back</Icon> {" Back"}
          </Button>
        </Tooltip>
      </div>
    {openMessages && openMessages.length > 0 && openMessages.map((data,index)=>{
      return(
        <Col md={12} xl={12} lg={12} key={index} className="mb-10 px-0">
       <Card>
         <CardBody  className="dashboard__card-widget">
           <div>
           <div className="card__title flex flex-space-between">
              <h5 className="bold-text">{data.typeName}</h5>
              <h6> {new Date(data.date).toLocaleString()}</h6>
            </div>
             {/* <h5 className="text-muted">{data.programName}</h5> */}
            
             <div>
               <h5 className="bold-text mb-2 mt-2">{data.subject}</h5>
              
             </div>
           </div>
           
           {/* <h5 className="bold-text pt-2">{data.parentName}</h5> */}
           <p className="mb-2">{data.fullMessage}</p>
           <div className="text-right">
             
           <div className="text-left">
            <p><span>{data.parentName}</span></p>
           </div>
             
          {
            data.isResolved === false ? 
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleApproveMsg(data.id)}>
                        Mark as Resolved
                         </Button>
            : <Button color="primary"size="small" variant="outlined" disabled>Already Resolved!</Button>
          }
          
           </div>
           
         </CardBody>
       </Card>
     </Col>
      )
    })}
    {resolveMessageConfirm && (
          <ConfirmationModal
          color="danger"
          title="Resolve Message"
          message="Are you sure you want to resolve this message?"
          colored={false}
          header={true}
          isOpen={resolveMessageConfirm}
          closeModal={handleClose}
          onYesClick={hanldeMsgConfirm}
        />
        )
          
        }
    </>
  )
}
export default Messages;
