import React from 'react';
import { Route } from 'react-router-dom';
import { authRoles } from "../../../../config/authroles"
import PrivateRoute from "../PrivateRoute"
import ProtectedRoute from "../ProtectedRoute"
import LandingRoute from "../LandingRoute"
import Layout from '../../../Layout/index';
import AboutUs from '../../../AboutUs/index'
import Home from "../../../Home"
import Featured from '../../../Featured/index'
import Activities from '../../../Activities/index'
import Community from '../../../Community/index'
import Calendar from '../../../DefaultPage/Calendar';
import FAQ from '../../../DefaultPage/Faq';
import StudentDetalsPage from "../../../StudentDetalsPage"
import UsersList from '../../../UsersList/index'
import Listings from '../../../Listings/index';
import Landing from "../../../Landing"
import Student from "../../../StudentList/index"
import Profile from "../../../Profile/index";
import Cart from "../../../Cart"
import Footer from "../../../Footer"
import AdminSubscription from "../../../AdminSubscription";
import SuperAdminUsers from '../../../SuperAdminUsers/index'
import renderProfile from "../../../Account/Profile/components/index"
import UniversalSearch from "containers/Layout/UniversalSearch/UniversalSearch"
import LinkProgram from 'containers/LinkManagement/Components/LinkProgram';
import Common from 'containers/LinkManagement/Components/Common';
import ProfileInfoLink from '../../../../ProfileInfoLink';

// export const pathName = window.location.origin + '/' + global.lakshay + '/' + '*'
// const abc = window.location.pathname.split('/')
// let lakshay = 'DWULRegistration'
// if (localStorage.getItem('username')) {
//   lakshay = localStorage.getItem('username').replace(" ", "");
// }
// const pathname = window.location.pathname;
// const isDetailPage = pathname.includes('/detail/') ? true : false;
// const isLinkProgranmPage = !isDetailPage;

// const isLinkProviderPage = !isDetailPage;

export default () => (

  <>

    <Layout />
    <div className="container__wrap ">
      <LandingRoute exact path="/" component={Landing} />
      <Route path="/about" component={AboutUs} />
      <Route path="/support" component={FAQ} />
      <ProtectedRoute path="/home" component={Home} />
      <PrivateRoute path="/featured" component={Featured} role={authRoles.Parents} />
      <ProtectedRoute path="/activities" component={Activities} />
      <ProtectedRoute path="/listings" component={Listings} />
      <ProtectedRoute path="/community" component={Community} />
      <ProtectedRoute path="/calender" component={Calendar} />
      {<Route path="/page/detail/:id" component={StudentDetalsPage} />}

      <PrivateRoute path="/users" component={UsersList} role={authRoles.Admin} />
      <PrivateRoute path="/user" component={SuperAdminUsers} role={authRoles.SuperAdmin} />
      {/* <PrivateRoute path="/listings" component={Listings} role={authRoles.Provider}  /> */}
      <ProtectedRoute path="/student" component={Student} />
      <ProtectedRoute path="/profile" component={renderProfile} />
      <Route path="/cart" component={Cart} role={authRoles.Parents} />
      <ProtectedRoute path="/subscription" component={AdminSubscription} />
      <Route path="/search" component={UniversalSearch} />
      <ProtectedRoute path="/link-program" component={LinkProgram} />
      <Route path="/common" component={Common} />

      <LandingRoute path={"/link/*/*"} component={ProfileInfoLink} role={authRoles.Guest} />



    </div>

  </>
);
