/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { useDispatch, useSelector } from "react-redux"
import {
  Card, CardBody, Row, Col, Progress,
} from 'reactstrap';
import Panel from '../../../shared/components/Panel'
import CounterCard from "../../../shared/components/CounterCard"

const RegistrationStats = ({ refreshRegistrationStats, onRefresh }) => {
  const count = useSelector(state => state.profile.totalCount)

  return (
    // <Panel xl={4} lg={6} md={6} sm={12} xs={12} onRefresh={onRefresh} refresh={refreshRegistrationStats} title="Registration Stats" >
    <Col md={12} xl={4} lg={6} sm={12} xs={12}>
    <Card>
      <CardBody className="dashboard__card-widget py-28 px-24" >
      <h5 className="uppercase bold-text font-size-13 mb-16">Registration Stats</h5>
      <div className="m-auto">
      <Row >
        <Col md={6} xl={6} lg={6} sm={6} xs={6}>
        <CounterCard
      count={count.totalRegistration}
      icon="person"
      label="Registrations"
      color="color5"
      size="small"  />
        </Col>
        <Col md={6} xl={6} lg={6} sm={6} xs={6}>
        <CounterCard
      count={count.totalPrograms}
      icon="card_membership_icon "
      label="Programs"
      color="color5"
      size="small" />
        </Col>
      
      </Row>
      <Row>
      <Col md={6} xl={6} lg={6} sm={6} xs={6}>
      <CounterCard
      count={count.totalExperience}
      icon="assistant"
      label="Experience"
      color="color5"
      size="small"
      />
      </Col>
      <Col md={6} xl={6} lg={6} sm={6} xs={6}>
      <CounterCard
      count={count.totalStudent}
      icon="group"
      label="Students"
      color="color5"
      size="small" />
      </Col>
      
      </Row>
      </div>
      </CardBody>
      </Card>
      </Col>
    
    // </Panel>
  )
};


export default withTranslation('common')(RegistrationStats);
