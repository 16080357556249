// import React, { useEffect, useState } from 'react';
// import TopBar from "../../shared/components/TopBar"
// import { withRouter } from "react-router-dom"
// import {
//   Card,
//   Row,
//   Col,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   CardBody
// } from 'reactstrap';
// import classnames from "classnames";
// import { useSelector, useDispatch } from "react-redux"
// import AllListing from "./AdminListing/AllListing"
// import ProfileCard from "shared/components/CardComponent/ProfileCard"
// import { getProviderList } from "redux/actions/profileActions"
// import AllExperiance from "./AdminListing/AllExperiance"

// const Landing = () => {
//   const dispatch = useDispatch()
//   const [activeTab, setActiveTab] = useState('1');
//   const { providerList } = useSelector(state => state.provider);
//   useEffect(() => {
//     dispatch(getProviderList())
//   }, [])
//   let filteredProviderList = providerList.filter(item => item.organisationName);
//   function toggle(tab) {
//     if (activeTab !== tab) {
//       setActiveTab(tab);
//     }
//   }
//   return (

//     <Card className="h-auto">
//       <CardBody className="p-0">
//         <div className="profile__card tabs tabs--bordered-bottom" >
//           {/* <div className="tabs__wrap" > */}
//           <Nav tabs >
//             <NavItem >
//               <NavLink
//                 className={classnames({ active: activeTab === "1" })}
//                 onClick={() => {
//                   toggle("1");
//                 }}
//               >
//                 Programs
//                   </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 className={classnames({ active: activeTab === "2" })}
//                 onClick={() => {
//                   toggle("2");
//                 }}
//               >
//                 Providers
//                   </NavLink>
//             </NavItem>

//             <NavItem>
//               <NavLink
//                 className={classnames({ active: activeTab === "3" })}
//                 onClick={() => {
//                   toggle("3");
//                 }}
//               >
//                 Registrations
//                   </NavLink>
//             </NavItem>

//           </Nav>
//           <TabContent activeTab={activeTab}>
//             <TabPane tabId="1">
//               <Row>
//                 <AllListing />
//               </Row>

//             </TabPane>
//             <TabPane tabId="2">
//               <Row className="ml-4">
//                 <Col md={12}>
//                   <div className="card__title">
//                     <h3 className="bold-text">Providers</h3>
//                   </div>
//                 </Col>
//               </Row>
//               <Row className="ml-4">
//                 {filteredProviderList.length !== 0 && filteredProviderList.map((item, index) => (
//                   <div key={index + new Date()}>
//                     <ProfileCard
//                       item={item}
//                     />
//                   </div>
//                 ))}
//               </Row>
//             </TabPane>

//             <TabPane tabId="3">
//               <Row>

//               </Row>
//             </TabPane>

//           </TabContent>
//         </div>
//       </CardBody>
//       {/* </div> */}
//     </Card>

//     // <div className="mr-10 ml-30" >

//     //   {/* <Row>
//     //       <AllExperiance/>
//     //   </Row> */}

//     //   <Row>
//     //       <AllListing/>
//     //   </Row>
//     //   <Row className="ml-4">
//     //   <Col md={12}>
//     //     <div className="card__title">
//     //       <h3 className="bold-text">Providers</h3>
//     //     </div>
//     //   </Col>
//     // </Row>
//     // <Row className="ml-4">
//     //   {filteredProviderList.length !== 0 && filteredProviderList.map((item, index) => (
//     //     <div key={index + new Date()}>
//     //       <ProfileCard
//     //         item={item}
//     //       />
//     //     </div>
//     //   ))}
//     // </Row>

//     // </div>

//   );
// }

// export default withRouter(Landing);

import React, { useEffect, useState } from 'react';
import TopBar from '../../shared/components/TopBar';
import { withRouter } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
} from 'reactstrap';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Update';
import HistoryInfo from '../../containers/Activities/Components/HistoryInfo';
import {
  Icon,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Divider,
} from '@material-ui/core';
import classnames from 'classnames';
import SearchableTable from '../../shared/components/SearchableTable';
import { useSelector, useDispatch } from 'react-redux';
import AllListing from './AdminListing/AllListing';
import { paymentStatus } from '../../config/paymentCode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoImage from 'shared/img/logo/LOGO.png';
import { exportToCsv } from '../../shared/helpers';
import ProfileCard from 'shared/components/CardComponent/ProfileCard';
import { getProviderList } from 'redux/actions/profileActions';
import AllExperiance from './AdminListing/AllExperiance';
import { getTrHistoryAdmin } from '../../redux/actions/transactionActions';

const rows = [
  {
    id: 1,
    label: 'Order Id',
  },
  {
    id: 2,
    label: 'Parent Name',
  },
  {
    id: 3,
    label: 'Payment Mode',
  },
  {
    id: 4,
    label: 'Date of Payment',
  },
  {
    id: 5,
    label: 'Amount(CA$)',
  },
  {
    id: 6,
    label: 'Promo Code',
  },
  {
    id: 7,
    label: 'Payment Status',
  },
  // {
  //   id: 7,
  //   label: "Refund Status",
  // },
  {
    id: 8,
    label: 'Action',
  },
];
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Landing = ({ loading, updateStatus = false, handleUpdate }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);

  const [historyDetail, sethistoryDetail] = useState({});
  const [historyInfo, sethistoryInfo] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const { providerList } = useSelector((state) => state.provider);

  const history = useSelector((state) => state.transaction.TrHistoryAdmin);

  useEffect(() => {
    (async () => {
      dispatch(getTrHistoryAdmin());
    })();
  }, []);
  let filteredData = ([] = history);
  if (searchValue) {
    filteredData = history.filter((search) => {
      return (
        moment(search.dateOfPayment).format() ||
        moment(search.dateOfProgram).format()
      ).startsWith(searchValue);
    });
  }

  const clearSearch = () => {
    setSearchValue('');
  };
  const handleClose = () => {
    sethistoryInfo(false);
    setAnchorEl(null);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };

  const handleInfo = (d) => {
    sethistoryDetail(d);
    sethistoryInfo(true);
  };

  const handleFavSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setSearchValue(value);
  };

  const handlePdf = (d) => {
    sethistoryDetail(d);
    sethistoryInfo(true);
    setAnchorEl(null);
  };
  const handlePdfDownload = () => {
    const logo = document.createElement('div');
    logo.id = 'logo';
    logo.style.width = '90%';
    logo.classList.add('flex', 'flex-end');
    const logoImg = document.createElement('img');
    logoImg.src = logoImage;
    logoImg.style.width = '80px';
    logo.appendChild(logoImg);

    const button = document.getElementById('buton');
    button.style.display = 'none';
    const input = document.getElementById('divToPrint').innerHTML;

    const div = document.createElement('div');
    div.id = 'print';
    div.style.width = '90%';
    div.style.height = '100%';
    div.style.display = 'block';
    div.innerHTML = input;
    const mainDiv = document.createElement('div');
    const mainPrint = 'mainPrint_' + new Date().toISOString();
    mainDiv.id = mainPrint;
    mainDiv.appendChild(logo);
    mainDiv.appendChild(div);
    document.body.append(mainDiv);

    const print = document.getElementById(mainPrint);
    html2canvas(print, {
      useCORS: true,
      allowTaint: true,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const image = canvas.toDataURL('image/jpeg');
      const doc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
      doc.addImage(image, 'JPEG', 20, 20, canvasWidth, canvasHeight);
      doc.save(`${historyDetail.parentName}.pdf`);
    });
    mainDiv.style.display = 'none';
    handleClose();
  };

  const convertToCsv = () => {
    let data = [];
    // let csvContent = "";
    let A = [
      [
        'Oder Id',
        'Parent Name',
        'Programs',
        'Payment Mode',
        'Payment Status',
        'Date of Payment',
        'Promo Code',
        'Amount($)',
      ],
    ];
    data.push(A);
    filteredData.forEach((d) => {
      return data.push([
        d.id,
        d.parentName,
        d.programOrder.map((item) => item.programName).join(' - '),
        d.paymentMode,
        d.paymentStatusString,
        new Date(d.dateOfPayment).toLocaleDateString(),
        d.promoCode,
        d.amount,
      ]);
    });
    exportToCsv(data, 'payment_history');
    setAnchorEl(null);
  };

  const getStatusColor = (statusType) => {
    switch (statusType) {
      case paymentStatus.Pending:
        return 'warning';
      case paymentStatus.Success:
        return 'success';
      case paymentStatus.Failed:
        return 'danger';
      default:
        return 'info';
    }
  };
  const handleRefundRequestUi = (value) => {
    if (value.cancelRequest && !value.refunded) {
      return <Badge color='warning'>Pending</Badge>;
    } else if (!value.cancelRequest && value.refunded) {
      return <Badge color='Sucess'>Success</Badge>;
    } else {
      return <Badge color='info'>Not requested</Badge>;
    }
  };

  useEffect(() => {
    dispatch(getProviderList());
  }, []);
  let filteredProviderList = providerList.filter(
    (item) => item.organisationName
  );
  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  return (
    <Card className='h-auto'>
      <CardBody className='p-0'>
        <div className='profile__card tabs tabs--bordered-bottom'>
          {/* <div className="tabs__wrap" > */}

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Listings
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Providers
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                Registrations
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
              >
                Experiences
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane
              tabId='1'
              style={{
                backgroundColor: '#fff',
              }}
            >
              <Row>
                <AllListing />
              </Row>
            </TabPane>

            <TabPane tabId='2' style={{
                backgroundColor: '#fff',
              }}>
              <Row className='ml-4'>
                <Col md={12}>
                  <div className='card__title'>
                    <h3 className='bold-text'>Providers</h3>
                  </div>
                </Col>
              </Row>
              <Row className='ml-4'>
                {filteredProviderList.length !== 0 &&
                  filteredProviderList.map((item, index) => (
                    <div key={index + new Date()}>
                      <ProfileCard item={item} />
                    </div>
                  ))}
              </Row>
            </TabPane>

            <TabPane tabId='3' style={{
                backgroundColor: '#fff',
              }}>
              <Row>
                <Card>
                  <CardBody>
                    <SearchableTable
                      title='History'
                      rows={rows}
                      filteredData={filteredData}
                      loading={loading}
                      actionTablebtn={false}
                      searchType='date'
                      searchLabel='Search By Date'
                      searchValue={searchValue}
                      clearSearch={clearSearch}
                      searchPlaceholder='By name'
                      rowsPerPage={rowsPerPage}
                      page={page}
                      getSearchResult={handleFavSearch}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      actionTablebtn={true}
                      actionBtnIcon='publish'
                      actionBtnText='Export'
                      actionBtnClick={convertToCsv}
                    >
                      <>
                        {filteredData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((d, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell className='material-table__cell'>
                                  {i + 1 + page * rowsPerPage}
                                </TableCell>
                                <TableCell className='material-table__cell'>
                                  {d.orderId}
                                </TableCell>
                                <TableCell className='material-table__cell text-nowrap'>
                                  {d.parentName}
                                </TableCell>
                                <TableCell className='material-table__cell material-table__cell-right text-nowrap'>
                                  {d.paymentMode}
                                </TableCell>
                                <TableCell className='material-table__cell material-table__cell-right text-nowrap '>
                                  {moment(d.dateOfPayment).format(
                                    'MMM DD, YYYY'
                                  )}
                                </TableCell>
                                <TableCell className='material-table__ceActivitiesll material-table__cell-right text-nowrap'>
                                  {d.amount}
                                </TableCell>
                                <TableCell className='material-table__cell material-table__cell-center text-nowrap'>
                                  {d.promoCode}
                                </TableCell>
                                <TableCell className='material-table__cell material-table__cell-center text-nowrap'>
                                  <Badge
                                    color={getStatusColor(d.paymentStatusEnum)}
                                  >
                                    {d.paymentStatusString}
                                  </Badge>
                                  Activities{' '}
                                </TableCell>
                                <TableCell
                                  className='material-table__cell material-table__cell-center'
                                  style={{ display: 'flex' }}
                                >
                                  <Button
                                    color='primary'
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    className='mr-10 text-tranform-none '
                                    startIcon={<Icon>info</Icon>}
                                    onClick={() => handleInfo(d)}
                                  >
                                    Details
                                  </Button>
                                  {updateStatus &&
                                    d.paymentStatusEnum ===
                                      paymentStatus.Pending && (
                                      <Button
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                        className='mr-10 text-tranform-none'
                                        onClick={() => handleUpdate(d)}
                                        startIcon={<UpdateIcon />}
                                      >
                                        Update
                                      </Button>
                                    )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    </SearchableTable>
                  </CardBody>
                </Card>

                <HistoryInfo
                  isOpen={historyInfo}
                  title='Payment History'
                  closeModal={handleClose}
                  historyDetail={historyDetail}
                  handlePdfDownload={handlePdfDownload}
                  getStatusColor={getStatusColor}
                />
              </Row>
            </TabPane>
            <TabPane tabId='4' style={{
                backgroundColor: '#fff',
              }}>
              <Row>
                <AllExperiance />
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </CardBody>
      {/* </div> */}
      <div className='mr-10 ml-30'>
        {/* 
        <Row>
          <AllExperiance />
        </Row> */}

        {/* <Row>
          <AllListing />
        </Row> */}
        <Row className='ml-4'>
          <Col md={12}>
            <div className='card__title'>
              <h3 className='bold-text'>Providers</h3>
            </div>
          </Col>
        </Row>
        <Row className='ml-4'>
          {filteredProviderList.length !== 0 &&
            filteredProviderList.map((item, index) => (
              <div key={index + new Date()}>
                <ProfileCard item={item} />
              </div>
            ))}
        </Row>
      </div>
    </Card>
  );
};

export default withRouter(Landing);
