import React from 'react'
import { Card, CardBody, Col } from 'reactstrap';
import Collapse from '../../../../shared/components/Collapse';

export default function PrivacyPolicies() {
    return (

        <>
            <Collapse className="with-shadow" title="Collection ">
                <p>
                    The DWUL Online Platform currently collects and will collect the registration information from
                    parents and students in the future. Such as the parents' and students' first and last names,
                    mailing address, emergency contact, email contact, date of birth of the students, grade level,
                    which school they attend, outlined in all the online registration forms when a parent account
                    registers a student in a program.
          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Access">
                <p>
                    Information collected from parents and students currently and in the future is only accessible to
                    DWUL and the program providers, who deliver the programs to the parents and students who
                    have registered for that program. The registration information may be shared with the student's
                    school when a program is hosted directly on that school's premises. The purpose is to provide
                    accurate registration information to local program providers who deliver the programs and your
                    school.

          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Use">
                <p>
                    The information collected is currently and in the future, only used by the DWUL online platform
                    and the program providers who deliver the programs that parents and students will register
                    using the DWUL platform. DWUL and program providers may send program communications
                    related to their registration and future updates about relevant program opportunities in their
                    school and community. Parents can opt-out from receiving program and community updates at
                    any time, except for when they are participating in a program.

          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Openness">
                <p>
                    All the current and future information collected from parents and students, and program
                    providers are only associated with their registration in a program, detailed in the "collection"
                    policy and all the registration forms. The information can be shared and downloaded by DWUL
                    and program providers to create program attendance sheets, emergency contact lists, and
                    email communications related to that program. Parents can request to delete their information
                    from the DWUL platform and any attendance lists if they withdraw from a registered program.

          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Compliance">
                <p>
                    The DWUL platform currently operates and complies with the Personal Information Protection
                    Act (PIPA) provincial requirement in BC. In the future, when the online platform and registration
                    services of the DWUL platform extend beyond BC and into other provinces and territories in
                    Canada, the cross-provincial information collection will operate and comply with the Personal
                    Information Protection and Electronic Documents Act (PIPEDA).

          </p>
            </Collapse>
        </>
    )
}
