import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function RenderToggle({ input,label }) {
  return (
    
    <>
      
      <FormControlLabel
        control={<Switch onChange={input.onChange} checked={input.value}  />}
        label={label}
    />
    </>
      
    
  );
}
