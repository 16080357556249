
import axios from "axios"
import localStorageService from "./localStorageService";

function login(params) {
    const config = {
        method: "post",
        url: window.apiUrl + "Account/authenticate",
        data: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return axios(config).then(res => {
        if (res.data.data) {
            const { authToken, name, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                email: email,
                contact: contact
            }
            setToken(authToken)

            setRefreshToken(refreshToken)
            setUserLocal(user)
            var username = name.replace(" ", "");
            localStorage.setItem('username', name.replace(" ", ""));
            return res.data
        } else {
            return res.data
        }

    }).catch(err => {
        return err
    })
}




async function microsoftregister(data) {
    const config = {
        method: "post",
        url: window.apiUrl + "Account/Microsoft-register",
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }

    }

    return await axios(config).then(res => {
        if (res.data) {
            // debugger
            const { authToken, name, id, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                id: id,
                email: email,
                contact: contact
            }
            setToken(authToken)

            setRefreshToken(refreshToken)
            setUserLocal(user)
            return res.data
        } else {
            return res.data
        }
    }).catch(err => {
        console.error(err.message)
    })
}



async function registerfacebook(data) {
    const config = {
        method: "post",
        url: window.apiUrl + "Account/Facebook-register",
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }

    }

    return await axios(config).then(res => {
        if (res.data) {
            // debugger
            const { authToken, name, id, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                id: id,
                email: email,
                contact: contact
            }
            setToken(authToken)

            setRefreshToken(refreshToken)
            setUserLocal(user)
            return res.data
        } else {
            return res.data
        }
    }).catch(err => {
        console.error(err.message)
    })
}




async function register(data) {
    let userData = {
        email: data.email,
        password: data.password,
        emailVerificationUrl: `${window.origin}/?code=`,
        roleCode: data.roleCode,
        ...data,
    }
    const config = {
        method: "post",
        url: window.apiUrl + "Account/register",
        data: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return await axios(config).then(res => {
        if (res.data) {
            // debugger
            const { authToken, name, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                email: email,
                contact: contact
            }
            if (authToken) {
                setToken(authToken)
                setRefreshToken(refreshToken)
                setUserLocal(user)
            }
            return res.data
        } else {
            return res.data
        }
    }).catch(err => {
        console.error(err.message)
    })
}

const authUserExternal = async (userData) => {
    const config = {
        method: "post",
        url: window.apiUrl + "Account/external-register",
        data: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return await axios(config).then(res => {
        if (res.data) {
            // debugger
            const { authToken, name, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                email: email,
                contact: contact
            }
            setToken(authToken)

            setRefreshToken(refreshToken)
            setUserLocal(user)
            return res.data
        } else {
            return res.data
        }
    }).catch(err => {
        console.error(err.message)
    })
}

const loginWithToken = async () => {
    let user = {
        user: localStorageService.getItem("authUser"),
        token: localStorage.getItem("authToken"),
        refreshToken: localStorage.getItem("refreshToken")
    };
    return await new Promise((resolve, reject) => {
        resolve(user);
    }).then((data) => {
        setToken(data.token);
        setUserLocal(data.user);
        setRefreshToken(data.refreshToken)
        return data.user;
    });
};




async function setRefreshToken(refreshToken) {
    await localStorage.setItem("refreshToken", refreshToken)
}
async function setUserLocal(user) {
    await localStorageService.setItem("authUser", user)

}
export const setToken = async (authToken) => {
    await localStorage.setItem("authToken", authToken)
}
async function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');
    localStorage.removeItem('refreshToken')
}

// verify email
export const verifyEmail = async (token) => {
    return await axios.post(`${window.apiUrl}Account/verify-email?token=${token}`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res) => {
        if (res.data.data) {
            const { authToken, name, image, role, refreshToken, authTokenExpire, email, contact } = res.data.data
            let user = {
                name: name,
                avatar: image,
                role: role,
                email: email,
                contact: contact
            }
            setToken(authToken)

            setRefreshToken(refreshToken)
            setUserLocal(user)
            var username = name.replace(" ", "");
            localStorage.setItem('username', name.replace(" ", ""));
            console.log("ccccc", res.data.data)
            return res
        } else {
            return res.data
        }
    }).catch(err => {
        return err
    })
}
export const userService = {
    login,
    logout,
    register,
    setRefreshToken,
    setToken,
    registerfacebook,
    setUserLocal,
    authUserExternal,
    microsoftregister,
    verifyEmail,
    loginWithToken

};