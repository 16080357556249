import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import { getparentProfile, changePassword } from '../../redux/actions/profileActions';
import { Skeleton } from "@material-ui/lab";
import ChangePassModal from "../../shared/components/ChangePassword/ChangePassModal"
import { changePasswordModal } from "../../redux/actions/modalAction"
import { getEnrolledProgram } from "../../redux/actions/programActions"
import Interest from "./components/Interest";
//-------
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { renderField } from 'shared/components/form/RenderField';
import { validate } from 'config/formValidation';
import { connect } from "react-redux";
import { tagTypes } from 'config/tagTypes';
import ActivityTag from 'shared/components/form/ActivityTag';


const ParentProfile = ({ isEdit, valid, initialValues, }) => {

  const { parentProfile, parentLoading } = useSelector(state => state.profile)

  const dispatch = useDispatch()
  const modal = useSelector(state => state.modal)
  useEffect(() => {
    (async () => {

      dispatch(getparentProfile(0))
      dispatch(getEnrolledProgram())
    })()
  }, [])
  const closeChangePassModal = () => {
    dispatch(changePasswordModal(false))
  }
  const handleChangePassModal = () => {
    dispatch(changePasswordModal(true))
  }
  const confirmChangePass = async (val) => {
    let data = {
      oldPassword: val.oldPassword,
      newPassword: val.newPassword
    }
    dispatch(changePassword(data))
    dispatch(changePasswordModal(false))

  }
  return (
    <Container>
      <Row className="mt-0">
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Row className="mt-0 mx-0 ">

            <ProfileMain
              loading={parentLoading}
              parentProfile={parentProfile}
              handleChangePassModal={handleChangePassModal}
              closeModal={closeChangePassModal}
            />
          </Row>


          <Row className="mt-0 mx-0 ">
            <div className="form__form-group">
              <Field
                disabled={isEdit ? true : false}
                name="interests"
                label="Interest"
                component={ActivityTag}
                type="text"
                placeholder="Interest"
                className="input-without-border-radius "
                startIcon="local_activity"
                tagType={[tagTypes.Activity]}
              />
            </div>
          </Row>


          <Row className="mt-0 mx-0">
            <Interest
              parentProfile={parentProfile}
            />
          </Row>
        </Col>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          {parentLoading
            ? (
              <div>

                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton variant="rect" width="100%" animation="wave" height={200} />
              </div>
            )
            : <ProfileTabs />
          }
        </Col>


      </Row>

      <ChangePassModal
        title="Change Password"
        isOpen={modal.changePasswordModal}
        closeModal={closeChangePassModal}
        confirmChangePass={confirmChangePass}
        onSubmit={confirmChangePass}
      />
    </Container>
  )
};

//export default ParentProfile;
export default connect((state) => ({
  errorMsg: state.user.error,

  initialValues: {


    interests: state.profile.parentProfile.interestNames
      ? state.profile.parentProfile.interestNames
      : [],

  },
}))(
  reduxForm({
    form: "UpdateParent",
    enableReinitialize: true,
    validate,
  })(ParentProfile)
);

