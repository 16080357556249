import React from "react";
import Dialog from '@material-ui/core/Dialog';
import {Modal, ModalBody, ModalHeader} from "reactstrap"

import RecommendationForm from "./RecommendationForm";



function RecommendationModal({ isOpen, title, submitRecommendation,closeModal }) {
    return (
        <div>
            <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form"  isOpen={isOpen} centered>
                <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
                <ModalBody className="login-form">
                <RecommendationForm 
                submitRecommendationNew={submitRecommendation}
                closeRecommandationModal={closeModal}/>
                </ModalBody>
                
            </Modal>
        </div>
    );
}

export default RecommendationModal;