import {
    LOGIN_MODAL,
    // EMAIL_MODAL,
    FACEBOOOK_MODAL,
    REGISTER_MODAL,
    FORGETPASSWORD_MODAL,
    USER_INFO_MODAL,
    ADD_NEW_STUDENT_MODAL,
    ADD_NEW_STUDENT_MODAL_FORM,
    PROVIDER_INFO_MODAL,
    EXPLORE_MODAL,
    ADD_EXPERIANCE_MODAL,
    VIEW_PROFILE,
    REGISTER_PROGRAM,
    PAYMENT_DETAILS,
    PROMO_CODE,
    MICROSOFT_MODAL,
    ANNOUNCEMENT,
    REVIEW_MODAL,
    CONTACT_INFO_PROVIDER_MODAL,
    ADD_RECOMMANDATION,
    MESSAGE_MODAL,
    CHANGE_PASSWORD_MODAL,
    APPLY_PROMO_MODAL,
    ADD_USER_MODAL,
    RESLOVE_MODAL,
    SUBSCRIPTION_MODAL,
    PAYMENT_SUBS,
    RESOURCES_MODAL,
    ADD_USER_ADMIN_MODAL,
    USE_EXISTING_ADDRESS,
} from "../types"
export const facebookModal = (payload) => async (dispatch) => {
    dispatch({
        type: FACEBOOOK_MODAL,
        payload: payload
    })
}

export const microsoftModal = (payload) => async (dispatch) => {
    dispatch({
        type: MICROSOFT_MODAL,
        payload: payload
    })
}
export const loginModal = (payload) => async (dispatch) => {
    dispatch({
        type: LOGIN_MODAL,
        payload: payload
    })
}
// export const emailModal = (payload) => async (dispatch) => {
//     dispatch({
//         type: EMAIL_MODAL,
//         payload: payload
//     })
// }
export const registerModal = (payload) => async (dispatch) => {
    dispatch({
        type: REGISTER_MODAL,
        payload: payload
    })
}
export const forgetPasswordModal = (payload) => async (dispatch) => {
    dispatch({
        type: FORGETPASSWORD_MODAL,
        payload: payload
    })
}
export const userInfoModal = (payload) => async (dispatch) => {
    dispatch({
        type: USER_INFO_MODAL,
        payload: payload
    })
}
export const addNewStModal = (payload) => async (dispatch) => {
    dispatch({
        type: ADD_NEW_STUDENT_MODAL,
        payload: payload
    })
}
export const addNewStModalForm = (payload) => async (dispatch) => {
    dispatch({
        type: ADD_NEW_STUDENT_MODAL_FORM,
        payload: payload
    })
}
export const providerInfoModal = (payload) => async (dispatch) => {
    dispatch({
        type: PROVIDER_INFO_MODAL,
        payload: payload
    })
}
export const addExperienceModal = (payload) => async (dispatch) => {
    dispatch({
        type: ADD_EXPERIANCE_MODAL,
        payload: payload
    })
}

export const exploreModal = (payload) => async (dispatch) => {
    dispatch({
        type: EXPLORE_MODAL,
        payload: payload
    })
}
export const viewProfileModal = (payload) => async (dispatch) => {
    dispatch({
        type: VIEW_PROFILE,
        payload: payload
    })
}
export const programRegiterModal = (payload) => async (dispatch) => {
    dispatch({
        type: REGISTER_PROGRAM,
        payload: payload
    })
}
export const paymentDetailsModal = (payload) => async (dispatch) => {
    dispatch({
        type: PAYMENT_DETAILS,
        payload: payload
    })
}
export const promoCodeModal = (payload) => async (dispatch) => {
    dispatch({
        type: PROMO_CODE,
        payload: payload
    })
}
export const announcementModal = (payload) => async (dispatch) => {
    dispatch({
        type: ANNOUNCEMENT,
        payload: payload
    })
}

export const reviewModal = (payload) => async (dispatch) => {
    dispatch({
        type: REVIEW_MODAL,
        payload: payload
    })
}
export const contactProviderModal = (payload) => async dispatch => {
    dispatch({
        type: CONTACT_INFO_PROVIDER_MODAL,
        payload: payload
    })
}
export const recommandationModel = (payload) => async dispatch => {
    dispatch({
        type: ADD_RECOMMANDATION,
        payload: payload
    })
}
export const messageModal = (payload) => async dispatch => {
    dispatch({
        type: MESSAGE_MODAL,
        payload: payload
    })
}
export const changePasswordModal = payload => async dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_MODAL,
        payload: payload
    })
}

export const applyPromoCodeModal = payload => async dispatch => {
    dispatch({
        type: APPLY_PROMO_MODAL,
        payload: payload
    })
}
export const addUserModal = payload => async dispatch => {
    dispatch({
        type: ADD_USER_MODAL,
        payload: payload
    })
}
export const resloveModal = payload => async dispatch => {
    dispatch(
        {
            type: RESLOVE_MODAL,
            payload: payload
        }
    )
}
export const modalSubscrition = (open, isEdit) => async (dispatch) => {
    let payload = {
        open: open,
        isEdit: isEdit
    }
    dispatch({
        type: SUBSCRIPTION_MODAL,
        payload: payload
    })
}
export const paymentSubsModal = (payload) => async (dispatch) => {
    dispatch({
        type: PAYMENT_SUBS,
        payload: payload
    })
}
export const resourcesModal = (payload) => async (dispatch) => {
    dispatch({
        type: RESOURCES_MODAL,
        payload: payload
    })
}
export const addUserAdminModal = (payload) => async (dispatch) => {
    dispatch({
        type: ADD_USER_ADMIN_MODAL,
        payload: payload
    })
}
export const useExistingAddress = (payload) => async (dispatch) => {
    dispatch({
        type: USE_EXISTING_ADDRESS,
        payload: payload
    })
}