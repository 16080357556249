import React from "react";
import { useHistory } from "react-router-dom"

import ParentHome from "./components/ParentHome";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { authRoles } from "../../config/authroles";
import ProviderHome from "./components/ProviderHome";
import AdminHome from "../AdminHome/index";
import SuperAdmin from "../SuperAdmin";
import SpeedDials from "../SpeedDials"
import { seoData } from "shared/StaticData"
import HelmetComponet from "shared/components/HelmetComponent"
const Home = () => {
  const auth = useSelector((state) => state.user);
  const history = useHistory()
  const renderUi = () => {
    switch (auth.role) {
      case authRoles.Provider:
        return <ProviderHome />
      case authRoles.Parents:
        return <>
          <ParentHome />
          <SpeedDials />
        </>
      case authRoles.Admin:
        return <AdminHome />
      case authRoles.SuperAdmin:
        return <AdminHome />
      default:
        return null
    }
  }
  return <>
    <HelmetComponet seoData={ seoData.home}/>
    {renderUi()}
  </>
};
export default withRouter(Home);
