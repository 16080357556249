import { config } from '../../translations';
import { GET_USER_LIST, GET_USER_COUNT,GET_ORAGANISATIONS,SET_USER_ROLE_CODE } from '../types';
const initialState = {
    userList: [],
    loading: true,
    usersCount: {},
    getOrganisations: [],
    userRoleCode:4
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
                loading: false
            }
        case GET_USER_COUNT:
            return {
                ...state,
                usersCount: {...state.usersCount, [action.payload.name]: action.payload.count }
            }
        case GET_ORAGANISATIONS:
            return {
                ...state,
                getOrganisations: action.payload
            }
        case SET_USER_ROLE_CODE:
            return {
                ...state,
                userRoleCode: action.payload
            }
        default:
            return state;
    }
};

export default userReducer