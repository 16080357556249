import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Field, reduxForm, formValueSelector } from "redux-form";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { renderField } from "../../../../../../shared/components/form/RenderField";
import Button from "@material-ui/core/Button";
import ProviderAnnounSelect from "../../../../../../shared/components/form/ProviderAnnounSelect";
import { connect} from "react-redux";
import { validate } from "../../../../../../config/formValidation";
import AutoDropDown from "../../../../../../shared/components/form/AutoDropDown";
import {announcementData,announcementType} from "config/announcementType"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

function AddAnnouncement({
  isAnnouncementOpen,
  closeAnnouncementModal,
  handleSubmit,
  handleAnnouncement,
  isEdit,
  submitting,
  valid,
  initialValues,
  forAudience
}) {

  const handleAnnouncementField = () => {
    if (forAudience) {
      switch (forAudience.id) {
        case announcementType.AllStudents:
          return <></>
        case announcementType.ProgramStudents:
          return (<Field
            name="programId"
            label="Select Program"
            type="text"
            disabled={isEdit}
            component={ProviderAnnounSelect}
            id={true}
          />)
        case announcementType.SpecificStudents:
          return (<Field
            name="studentId"
            label="Select Students"
            type="text"
            disabled={isEdit}
            component={ProviderAnnounSelect}
            id={true}
          />)
        default:
          return  <></>
      }
    }
  }
  return (
    <div>
      <Dialog
        onClose={closeAnnouncementModal}
        fullWidth
        open={isAnnouncementOpen}
      >
        <DialogTitle className="pb-0" onClose={closeAnnouncementModal}>
          {isEdit ? "Edit Announcement" : "Create Announcement"}
        </DialogTitle>
        <form
          className="login-form p-16 px-24"
          onSubmit={handleSubmit(handleAnnouncement)}
        >
          <DialogContent className="p-0 pt-4">
            <div className="form__form-group">
              <Field
                name="announcementName"
                label="Announcement Title"
                type="text"
                component={renderField}
                placeholder="Announcement Title"
                startIcon="title"
                required
              />
            </div>
            <div className="form__form-group">
              <Field
                name="description"
                label="Description"
                type="text"
                component={renderField}
                placeholder="Description"
                startIcon="note"
                multiline={true}
                rows={4}
              />
            </div>
            <div className="form__form-group">
              <Field
                name="announcementFor"
                label="Announcement for*"
                component={AutoDropDown}
                type="text"
                disabled={isEdit}
                placeholder="Announcement for"
                startIcon='people'
                multiple={false}
                objectArr={true}
                options={announcementData}
              />
            </div>
            <div className="form__form-group">
              {handleAnnouncementField()}
            </div>
          </DialogContent>
          <DialogActions className="float-right p-0">
            <Button
              className="mx-10"
              variant="contained"
              type="submnit"
              color="primary"
              disabled={!valid || submitting}
            >
              Save
            </Button>
            <Button
              className="m-0"
              variant="outlined"
              color="default"
              onClick={closeAnnouncementModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const selector = formValueSelector("announcementForm");
export default connect((state) => {
  const hasAnnouncement = selector(state, "announcementFor");
  return ({
    forAudience:hasAnnouncement,
    initialValues: {
      id: state.announcement.announcementDetails.id
      ? state.announcement.announcementDetails.id
      : 0,
    announcementName: state.announcement.announcementDetails.announcementName
      ? state.announcement.announcementDetails.announcementName :
      "",
    announcementFor: state.announcement.announcementDetails.announcementFor ?
      {
        id: state.announcement.announcementDetails.announcementFor,
        name: state.announcement.announcementDetails.announcementString
      } : { id: 3, name: "All Students" },
    description: state.announcement.announcementDetails.description
      ? state.announcement.announcementDetails.description
      : "",
    programId: state.announcement.announcementDetails.programDetails ?
      state.announcement.announcementDetails.programDetails
      : [],
    studentId: state.announcement.announcementDetails.studentDetails ?
      state.announcement.announcementDetails.studentDetails
      : [],
    allStudents: state.announcement.announcementDetails.allStudents ?
      state.announcement.announcementDetails.allStudents
      : true
    },
  })
})(
  reduxForm({
    form: "announcementForm",
    validate,
    enableReinitialize: true,
  })(AddAnnouncement)
);
