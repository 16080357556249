import { resourcesModal } from "../actions/modalAction";
import {
  ADD_NEW_STUDENT_MODAL,
  ADD_NEW_STUDENT_MODAL_FORM,
  LOGIN_MODAL,
  //EMAIL_MODAL,
  FORGETPASSWORD_MODAL,
  PROVIDER_INFO_MODAL,
  REGISTER_MODAL,
  USER_INFO_MODAL,
  EXPLORE_MODAL,
  ADD_EXPERIANCE_MODAL,
  VIEW_PROFILE,
  REGISTER_PROGRAM,
  PAYMENT_DETAILS,
  PROMO_CODE,
  ANNOUNCEMENT,
  REVIEW_MODAL,
  CONTACT_INFO_PROVIDER_MODAL,
  ADD_RECOMMANDATION,
  MESSAGE_MODAL,
  CHANGE_PASSWORD_MODAL,
  APPLY_PROMO_MODAL,
  ADD_USER_MODAL,
  RESLOVE_MODAL,
  SUBSCRIPTION_MODAL,
  PAYMENT_SUBS,
  RESOURCES_MODAL,
  ADD_USER_ADMIN_MODAL,
} from "../types";

const initialState = {
  loginModal: false,
  emailModal: false,
  registerModal: false,
  forgetPasswordModal: false,
  resgisterStudentModal: false,
  accountSignupModal: false,
  userInfoModal: false,
  addNewStModal: false,
  addNewStModalForm: false,
  providerInfoModal: false,
  exploreModal: false,
  addExperienceModal: false,
  viewProfileModal: false,
  registerProgram: false,
  paymentDetails: false,
  promoCode: false,
  announcement: false,
  reviewModal: false,
  contactProviderModal: false,
  addRecommandation: false,
  messageModal: false,
  changePasswordModal: false,
  applyPromo: false,
  addUserModal: false,
  resloveModal: false,
  subscriptionModal: {
    open: false,
    isEdit: false,
  },
  paymentSubs: false,
  resourcesModal: false,
  addUserAdmin: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.payload,
      };
    // case EMAIL_MODAL:
    //   return {
    //     ...state,
    //     emailModal: action.payload,
    //   };
    case REGISTER_MODAL:
      return {
        ...state,
        registerModal: action.payload,
      };
    case FORGETPASSWORD_MODAL:
      return {
        ...state,
        forgetPasswordModal: action.payload,
      };
    case USER_INFO_MODAL:
      return {
        ...state,
        userInfoModal: action.payload,
      };
    case ADD_NEW_STUDENT_MODAL:
      return {
        ...state,
        addNewStModal: action.payload,
      };
    case ADD_NEW_STUDENT_MODAL_FORM:
      return {
        ...state,
        addNewStModalForm: action.payload,
      };
    case PROVIDER_INFO_MODAL:
      return {
        ...state,
        providerInfoModal: action.payload,
      };
    case EXPLORE_MODAL:
      return {
        ...state,
        exploreModal: action.payload,
      };
    case ADD_EXPERIANCE_MODAL:
      return {
        ...state,
        addExperienceModal: action.payload,
      };
    case VIEW_PROFILE:
      return {
        ...state,
        viewProfileModal: action.payload,
      };
    case REGISTER_PROGRAM:
      return {
        ...state,
        registerProgram: action.payload,
      };
    case PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.payload,
      };
    case REVIEW_MODAL:
      return {
        ...state,
        reviewModal: action.payload,
      };
    case CONTACT_INFO_PROVIDER_MODAL:
      return {
        ...state,
        contactProviderModal: action.payload,
      };
    case ADD_RECOMMANDATION:
      return {
        ...state,
        addRecommandation: action.payload,
      };
    case MESSAGE_MODAL:
      return {
        ...state,
        messageModal: action.payload,
      };
    case CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModal: action.payload,
      };
    case APPLY_PROMO_MODAL:
      return {
        ...state,
        applyPromo: action.payload,
      };
    case ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: action.payload,
      };
    case RESLOVE_MODAL:
      return {
        ...state,
        resloveModal: action.payload,
      };
    case SUBSCRIPTION_MODAL:
      return {
        ...state,
        subscriptionModal: action.payload,
      };
    case PAYMENT_SUBS:
      return {
        ...state,
        paymentSubs: action.payload,
      };
    case RESOURCES_MODAL:
      return {
        ...state,
        resourcesModal: action.payload,
      };
    case ADD_USER_ADMIN_MODAL:
      return {
        ...state,
        addUserAdmin: action.payload
      }
    default:
      return state;
  }
};

export default modalReducer;
