import React from 'react';
import { Field, reduxForm, } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Divider } from "@material-ui/core"
import { Row, Col } from 'reactstrap';
import { renderField } from "../../../shared/components/form/RenderField"
import AutoDropDown from "../../../shared/components/form/AutoDropDown"
import ActivityTag from "../../../shared/components/form/ActivityTag"
import { mediaTypes } from "../../../config/mediaTypes"
import ImageUpload from "../../../shared/components/form/ImageUpload"
import { tagTypes } from '../../../config/tagTypes';
import renderFileInputField from "../../../shared/components/form/FileInput"
import TextEditor from "shared/components/text-editor/TextEditor"
import { daysofWeek } from "shared/StaticData"
import {validate} from "config/formValidation"
const ExperienceForm = ({
    handleSubmit,
    submitting,
    valid,
    expSubmit,
    initialValues,
    experierenceMedia,
    file,
    closeModal
}) => {

    return (
        <form
            className="login-form"
            onSubmit={handleSubmit(expSubmit)}
        >
            <Row>
                <Col md={10} lg={10} sm={10}>
                    <div className="form__form-group">
                        <Field
                            name="name"
                            label="Name*"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'
                            
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="locationId"
                            label="City*"
                            component={ActivityTag}
                            type='text'
                            placeholder="City"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            tagType={[tagTypes.Location]}
                            

                        />
                    </div>
                    {/* <div className="form__form-group">
                        <Field
                            name="email"
                            label="Email"
                            component={renderField}
                            type="text"
                            placeholder="email"
                            startIcon='email'
                            disabled
                            
                        />
                    </div> */}
                    
                </Col>
                <Col md={2} lg={2} sm={2} className="pl-0">
                    <ImageUpload
                        accept="image/*"
                        image={experierenceMedia
                            ? experierenceMedia
                            :
                            initialValues.experiencePhoto}
                        mediatype={mediaTypes.Experience}
                    />
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="activity"
                            label="Activity*"
                            component={ActivityTag}
                            type='text'
                            placeholder="Activity"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            tagType={[tagTypes.Activity]}
                            
                        />
                    </div>
                </Col>
              
                <Col md={6} sm={6} xs={6} lg={6}>
                    <div className="form__form-group">
                        <Field
                            name="cost"
                            min="0"
                            label="Cost (CA$)"
                            component={renderField}
                            type='number'
                            step="0.0001"
                            placeholder="Cost"
                            className="input-without-border-radius"
                            startIcon='attach_money'
                        />
                    </div>
                </Col>
                <Col md={6} sm={6} xs={6} lg={6}>
                    <div className="form__form-group">
                        <Field
                            name="numberOfSessions"
                            min="0"
                            label=" For how many sessions"
                            component={renderField}
                            type='number'
                            className="input-without-border-radius"
                            startIcon='hourglass_empty_icon'
                        />
                    </div>
                </Col>
                <Col md={6} sm={6} xs={6} lg={6}>
                    <div className="form__form-group">
                        <Field
                            name="capacity"
                            min="0"
                            label="Capacity"
                            component={renderField}
                            type='number'
                            className="input-without-border-radius"
                            startIcon='confirmation_number_icon'
                        />
                    </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <div className="form__form-group">
                        <Field
                            name="city"
                            label="City*"
                            component={renderField}
                            type='text'
                            placeholder="City"
                            className="input-without-border-radius"
                            startIcon='home'
                            
                        />
                    </div>
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="ageGroup"
                            label="Age Group"
                            component={ActivityTag}
                            type="text"
                            placeholder="Age Group"
                            startIcon='people'
                            className="input-without-border-radius"
                            tagType={[tagTypes.AgeGroup]}
                        />
                    </div>
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="availability"
                            label="Days of a week"
                            component={AutoDropDown}
                            type='text'
                            placeholder="Availability"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            options={daysofWeek}
                        />

                    </div>
                </Col>

                <Col md={6} lg={6} sm={6}>
                    <div className="form__form-group">

                        <Field
                            name="externalLink"
                            label="External Link"
                            component={renderField}
                            type="url"
                            placeholder="Include http:// or https://"
                            className="input-without-border-radius"
                            startIcon='link'
                        />
                    </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Field
                        name="file"
                        component={renderFileInputField}
                        mediatype={mediaTypes.Experience}
                        myValue={file ? file : initialValues.file}
                    />
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">

                        <Field
                            name="description"
                            label="Description"
                            component={TextEditor}
                            type='text'
                            placeholder="Minimum 250 character required"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </Col>
            </Row>
            <Divider />
            <div className="my-10 flex flex-end flex-middle ">
                <div >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!valid || submitting}

                    >Submit
                   </Button>
                </div>
                <div className="px-10">
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={closeModal}
                    >Close
                   </Button>
                </div>


            </div>
        </form>
    );
};

export default connect(state => ({
    errorMsg: state.user.error,
    experierenceMedia: state.media.experierenceMedia,
    file: state.media.experierencePdf,
    initialValues: {
        name: state.experiance.providerExpById.name ? state.experiance.providerExpById.name : "",
        locationId: state.experiance.providerExpById.locationId ? state.experiance.providerExpById.locationId : [],
        location: state.experiance.providerExpById.location ? {name: state.experiance.providerExpById.location, id:state.experiance.providerExpById.locationId} : "",
        experiencePhoto: state.experiance.providerExpById.experiencePhoto ? state.experiance.providerExpById.experiencePhoto : "",
        activity: state.experiance.providerExpById.activity ? state.experiance.providerExpById.activity : [],
        ageGroup: state.experiance.providerExpById.ageGroup ? state.experiance.providerExpById.ageGroup : "",
        availability: state.experiance.providerExpById.availability ? state.experiance.providerExpById.availability.split(",") : [],
        capacity: state.experiance.providerExpById.capacity ? state.experiance.providerExpById.capacity : "",
        city: state.experiance.providerExpById.city ? state.experiance.providerExpById.city : "",
        cost: state.experiance.providerExpById.cost ? state.experiance.providerExpById.cost : "",
        description: state.experiance.providerExpById.description ? state.experiance.providerExpById.description : "",
        externalLink: state.experiance.providerExpById.externalLink ? state.experiance.providerExpById.externalLink : "",
        file: state.experiance.providerExpById.file ? state.experiance.providerExpById.file : "",
        id: state.experiance.providerExpById.id ? state.experiance.providerExpById.id : 0,
        numberOfSessions: state.experiance.providerExpById.numberOfSessions ? state.experiance.providerExpById.numberOfSessions : "",
        email: state.user.email ? state.user.email : "",
        contact:state.user.contact?state.user.contact:""
    }
}))(reduxForm({
    form: 'experianceForm',
    enableReinitialize: true,
    validate
})(ExperienceForm));
