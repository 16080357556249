import React from 'react';
import {
  Card, CardBody,
} from 'reactstrap';
import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const CardPremium = ({ planName, commisionPercentage,yearlyCost, cents, planDescription, monthlyCost, annuallyCost, handleSubscription,subscriptionAlreadyTaken  }) => {
console.log("subscriptionAlready=========>",subscriptionAlreadyTaken)
  return (
    <Card className="mt-20 p-0 " >
      <CardBody className="pricing-card pricing-card p-0 m-10 ">
        <div className="pricing_border">
          <div className="pricing-card__body p-10 ">
            <h3 className="pricing-card__plan">{planName}</h3>
            <hr className="hr" />
            <div>
              <span>({`${commisionPercentage}% + ${cents}`}&#162; per. reg)</span>
            </div>
            <div className="mt-10">
              <h5 ></h5>
              {yearlyCost ? <p className="pricing-card__price">{`${'CA$'+annuallyCost}`}<span>/yr</span> </p> :<p className="pricing-card__price">{`${'CA$'+monthlyCost}`}<span>/mo</span> </p> }
                 
            </div>

            {/* <p className="">${ monthlyCost}<span>/mo</span></p> */}
            {planDescription.map((des, i) => <div key={i}>
              {i < 3 &&
                (

                  <p className=" my-10 flex flex-middle ">
                    <CheckCircleOutlineIcon className="text-success" />
                    <span className="mx-4 text-muted"> {des}</span>

                  </p>)
              }

            </div>
            )}

          </div>
         { subscriptionAlreadyTaken ?
         "":<Button onClick={handleSubscription}
            className="my-10"
            variant="outlined"
            size="small" color="primary"
            disableElevation>Continue</Button>}
        </div>
      </CardBody>
    </Card>
  )
}

export default CardPremium;
