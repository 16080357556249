import React, { } from 'react';
import { Field, reduxForm, } from 'redux-form';
import { connect, } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Divider } from "@material-ui/core"
import { renderField } from "../form/RenderField"
import { validate } from "../../../config/formValidation"
import ReactTooltip from 'react-tooltip';
import AutoDropDown from '../form/AutoDropDown';

const UserInfoForm = ({
  handleUserInfo,
  errorMsg,
  submitting,
  valid,
  handleSubmit,
  handleSkip
}) => {

  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(handleUserInfo)}
    >
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            name="fName"
            label="First Name*"
            component={renderField}
            type="text"
            placeholder="First Name"
            startIcon="person"
          />
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            name="mName"
            label="Middle Name"
            component={renderField}
            type="text"
            placeholder="Middle Name"
            startIcon="person"
          />
        </div>

      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            label="Last Name*"
            component={renderField}
            type='text'
            placeholder="Last Name"
            className="input-without-border-radius"
            name="lName"
            startIcon="person"
          />
        </div>
      </div>

      <div className="form__form-group">
        <Field
          // disabled={isEdit ? true : false}
          name="address1"
          label="Address*"
          component={renderField}
          placeholder="Address*"
          startIcon="home"
        />
      </div>

      <div className="form__form-group">
        <Field
          // disabled={isEdit ? true : false}
          name="city"
          label="City*"
          component={renderField}
          placeholder="City*"
          startIcon="home"
        />
      </div>

      <div className="form__form-group">
        {/* <Field
              disabled={isEdit ? true : false}
              name="province"
              label="Province*"
              component={renderField}
              placeholder="Province"
              startIcon="home"
            /> */}
        <Field
          name="province"
          label="Province"
          required
          component={AutoDropDown}
          multiple={false}
          options={[
            "AB",
            "BC",
            "MB",
            "NB",
            "NL",
            "NS",
            "NT",
            "NU",
            "ON",
            "PE",
            "QC",
            "SK",
            "YT"
          ]}
          type="text"
          placeholder="Select Province"
          startIcon="home"
        />
      </div>

      <div className="form__form-group">
        <Field
          // disabled={isEdit ? true : false}
          name="postalCode"
          label="Postal Code*"
          component={renderField}
          placeholder="Postal Code*"
          startIcon="home"
        />
      </div>

      <p className="collect-info">
        Why do we need to collect this info
        <span class="lnr lnr-question-circle" data-tip="Why do we need to collect this info?
       We take the privacy and security of the platform very seriously and need to collect
        information to verify the identity and legitimacy of each user account. 
        Any fake or illegitimate users will be permanently removed and banned from using our platform.

      How will we store and use your information? 
      All information is encrypted and strictly accessed by the service/program provider delivering
      the service/program you have registered in to be used for planning purposes and offering the best experience.
        We may share your information with your school for partnership programs within your school district."></span></p><ReactTooltip />
      <Divider />
      <div className="flex flex-middle float-right my-10 ">
        <div>
          <Button
            className="account__btn"
            type="submit"
            color="primary"
            variant="contained"
            disabled={submitting || !valid}

          >Continue
          </Button>
        </div>
        <div>
          <Button style={{
            position: 'relative',
            right: '0',
            bottom: '0'
          }}
            className="account__btn"
            color="secondary"
            variant="text"
            onClick={handleSkip}
          >skip
          </Button>
        </div>
      </div>
    </form>
  );
};
UserInfoForm.propTypes = {
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,

};
UserInfoForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',

};

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm({
  form: 'parent-info-form',
  validate
})(UserInfoForm));
