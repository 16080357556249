// import React, { useState, useEffect } from "react";
// import TagCard from "./components/TagCard";
// import { tagTypes } from "../../config/tagTypes";
// import { Row, Container } from "reactstrap";
// import AddNewTag from "./components/AddNewTag";
// import api from ".././../utils/apiClient";
// import { reset } from "redux-form";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   promoCodeModal,
//   announcementModal,
//   resourcesModal,
// } from "../../redux/actions/modalAction";
// import {
//   showFailedNoti,
//   showSucessNoti,
// } from "../../redux/actions/notificationAction";
// import {
//   getPromoCodes,
//   addPromoCode,
//   getPromoCodeById,
//   setPromoCodeById,
// } from "../../redux/actions/promoActions";
// import {
//   getTagsByTagType,
//   getTagDetails,
//   setTagDetails,
// } from "../../redux/actions/tagAction";
// import { totalcount } from "../../redux/actions/profileActions";
// import {
//   getAnnouncement,
//   getAnnouncementDetails,
//   setAnnouncementDetails,
// } from "../../redux/actions/AnnouncementAction";
// import TotalPayout from "./components/TotalPayout";
// import Announcement from "./components/Announcement";
// import PromoCodes from "./components/PromoCodes";
// import AddPromoCode from "./components/AddPromoCode";
// import AddAnnouncement from "./components/AddAnnouncement";
// import DetailsTag from "./components/DetailsTagCard";
// import TagsCounter from "./components/TagsCounter";
// import RegistrationStats from "./components/RegistrationStats";
// import ConfirmationModal from "../../shared/components/ConfirmationModal";
// import AdminResources from "./components/AdminResources";
// import AddResources from "./components/AddResources";
// import { postResources,getResources,resourcesById,setResourcesDetails } from "../../redux/actions/resourcesAction"
// import {announcementType} from "config/announcementType"
// // import history from '../../history'

// const AdminHome = () => {
//   const dispatch = useDispatch();
//   const [tagModel, settagModel] = useState(false);
//   const [refreshPromo, setRefreshPromo] = useState(false);
//   const [refreshTag, setRefreshTag] = useState(false);
//   const [refreshAnnouncement, setRefreshAnnouncement] = useState(false);
//   const [refreshResources, setRefreshResources] = useState(false);
//   const [refreshRegistrationStats, setRefreshRegistrationStats] = useState(
//     false
//   );
//   const [refreshTagsCounter, setRefreshTagsCounter] = useState(false);
//   const [isEdit, setIsEdit] = useState(false);
//   const [deletePromoModal, setDeletePromoModal] = useState(false);
//   const [deleteTagModal, setDeleteTagModal] = useState(false);
//   const [deleteAnnouncementModal, setDeleteAnnouncementModal] = useState(false);
//   const [deleteResourcesModal, setDeleteResourcesModal] = useState(false);
//   const [currentId, setCurrentId] = React.useState({});
//   //Selector for promo code modal/popup.
//   const modal = useSelector((state) => state.modal);
//   // dispatch(getPromoCodes())
//   const { promocodeList } = useSelector((state) => state.promocode);
//   const { allTags } = useSelector((state) => state.tags);
//   const { announcementList } = useSelector((state) => state.announcement);
//   const { resource } = useSelector(state => state.resources)

//   const closePromoCodeModal = () => {
//     dispatch(setPromoCodeById({}));
//     dispatch(promoCodeModal(false));
//     setIsEdit(false);
//     dispatch(reset("promocodeForm"));
//   };
//   const openPromoCodeModal = () => {
//     dispatch(promoCodeModal(true));
//   };

//   //Selector for announcement modal/pop-up.
//   const openAnnouncementModal = () => {
//     dispatch(announcementModal(true));
//   };
//   const closeAnnouncementModal = () => {
//     dispatch(setAnnouncementDetails({}));
//     setIsEdit(false);
//     dispatch(announcementModal(false));
//     dispatch(reset("announcement-form"));
//   };
//   //Selector for Resources modal/pop-up
//   const openResourcesModal = () => {
//     dispatch(resourcesModal(true));
//   };
//   const closeResourcesModal = () => {
//     dispatch(setResourcesDetails({}))
//       dispatch(resourcesModal(false));
//       dispatch(reset("resourcesForm"));
//   };
//   //Selector for Tags modal/pop-up.
//   const openTagModal = () => {
//     settagModel(true);
//   };
//   const closeTagModal = () => {
//     dispatch(setTagDetails({}));
//     setIsEdit(false);
//     settagModel(false);
//     dispatch(reset("tagForm"));
//   };

//   const handleClose = () => {
//     setDeletePromoModal(false);
//     setDeleteTagModal(false);
//     setDeleteAnnouncementModal(false);
//     setDeleteResourcesModal(false);
//     ;
//   };

//   //API Implementaion to manage Promo Code.
//   const promoCodeSubmit = (values) => {
//     let promoCodeData = {
//       id: values.id,
//       codeName: values.codeName,
//       code: values.code,
//       discountPrice: parseInt(values.discountPrice),
//       validUpto: new Date(values.validUpto).toISOString(),
//     };
//     api.promoCode
//       .managePromoCode(promoCodeData, true)
//       .then((res) => {
//         setIsEdit(false);
//         closePromoCodeModal();
//         dispatch(reset("promocodeForm"));
//         dispatch(showSucessNoti(res.data.message));
//         handleOnRefreshPromo();
//       })
//       .catch((err) => {
//         setIsEdit(false);
//         dispatch(setPromoCodeById({}));
//         console.error(err.message)

//       });
//   };
//   // to get program id and student id
//   const getById = (values) => {
//     let id = []
//     values.map((p) => {
//       id.push(p.id)
//     })
//     return id
//   }
//   // Handle Announcement Submit
//   const handleAnnouncement = (values) => {
//     let submitData = {
//       id: values.id,
//       announcementName: values.announcementName,
//       announcementFor:values.announcementFor.id,
//       programId: getById(values.programId),
//       studentId: getById(values.studentId),
//       allStudents: values.announcementFor.id === announcementType.AllStudents?true :false,
//       description: values.description,
//       allProvider: values.announcementFor.id === announcementType.AllProvider? true : false
//     };
//     api.announcement
//       .addAnnouncement(submitData)
//       .then((res) => {
//         if (res.data.data) {
//           setIsEdit(false);
//           closeAnnouncementModal();
//           dispatch(setAnnouncementDetails({}))
//           dispatch(reset("announcementForm"));
//           dispatch(showSucessNoti(res.data.message));
//           handleOnRefreshAnnouncement();
//         } else {
//           setIsEdit(false);
//           dispatch(showFailedNoti(res.data.message));
//           dispatch(setAnnouncementDetails({}))
//         }
//       })
//       .catch((err) => {
//         setAnnouncementDetails({});
//         setIsEdit(false);
//         dispatch(showFailedNoti(err.message));
//         dispatch(reset("announcement-form"));
//       });
//   };
//   //API Implementaion to manage Tag
//   const handleAddNewTag = async (values) => {
//     let data = {
//       id: values.id,
//       name: values.name,
//       tagType: values.tagType.tagType?values.tagType.tagType:values.tagType,
//       types: values.tagType.types? values.tagType.types:values.types,
//     };
//     await api.tag
//       .manageTag(data, true)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(reset("tagForm"));
//           closeTagModal();
//           handleOnRefreshTag();
//           dispatch(showSucessNoti(res.data.message));
//         } else {
//           dispatch(reset("tagForm"));
//           dispatch(showFailedNoti(res.data.message))
//         }
//       })
//       .catch((err) => {
//         closeTagModal();
//         dispatch(setTagDetails({}));
//         dispatch(reset("tagForm"));
//         dispatch(showFailedNoti(err));
//       });
//   };
//   // Refresh Promo Card
//   const handleOnRefreshPromo = () => {
//     setRefreshPromo(true);
//     dispatch(getPromoCodes()).then(() =>
//       setTimeout(() => setRefreshPromo(false), 500)
//     );
//   };
//   // Edit Promo Code Details
//   const handleEditPromo = (id) => {
//     dispatch(getPromoCodeById(id)).then(() => {
//       setIsEdit(true);
//       dispatch(promoCodeModal(true));
//     });
//   };
//   // Delete Promo Code
//   const handleDeletePromo = () => {
//     setDeletePromoModal(false);
//     setRefreshPromo(true);
//     let id = currentId;
//     api.promoCode
//       .deletePromoCode(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           setRefreshPromo(false)
//           dispatch(getPromoCodes())
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshPromo(false)
//         }

//       })
//       .catch((err)=> console.error(err.message));
//   };
//   const confirmDeletePromo = (id) => {
//     setCurrentId(id);
//     setDeletePromoModal(true);
//   };
//   // Refresh Tags Card
//   const handleOnRefreshTag = () => {
//     setRefreshTag(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTag(false), 500)
//     );
//   };
//   // Edit Tag Details
//   const handleEditTag = (id) => {
//     dispatch(getTagDetails(id)).then(() => {
//       setIsEdit(true);
//       settagModel(true);
//     });
//   };
//   // Delete Tag
//   const handleDeleteTag = () => {
//     let {id} = currentId;
//     setDeleteTagModal(false);
//     setRefreshTag(true);
//     api.tag
//       .deleteTag(id)
//       .then((res) => {
//         dispatch(getTagsByTagType(null))
//         setRefreshTag(false)
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//         }

//       }).catch(err=>console.error(err.message))
//   };
//   const confirmDeleteTag = (id) => {
//     setCurrentId(id);
//     setDeleteTagModal(true);
//   };

//   // Refresh Registration Stats
//   const handleOnRefreshRegistrationStats = () => {
//     setRefreshRegistrationStats(true);
//     setTimeout(() => setRefreshRegistrationStats(false), 500);
//   };
//   // Refresh Tags Stats
//   const handleOnRefreshTagsCounter = () => {
//     setRefreshTagsCounter(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTagsCounter(false), 1000)
//     );
//   };
//   // Refresh Announcement Card
//   const handleOnRefreshAnnouncement = () => {
//     setRefreshAnnouncement(true);
//     dispatch(getAnnouncement()).then(() =>
//       setTimeout(() => setRefreshAnnouncement(false), 500)
//     );
//   };
//   // Handle Resources
//   const handleResources=async(val)=>{
//       let data ={
//         id: val.id,
//         subject: val.subject,
//         body: val.body,
//         link: val.externalLink
//       }
//       await api.resources
//       .post(data)
//       .then((res) => {
//         if (res.data) {
//           dispatch(reset("resourcesForm"));
//           dispatch(setResourcesDetails({}));
//           closeResourcesModal();
//           handleOnRefreshResources();
//           dispatch(showSucessNoti(res.data.message));
//         }
//         else {
//           setIsEdit(false);
//           dispatch(reset("resourcesForm"));
//           dispatch(showFailedNoti(res.data.message));
//         }
//       })
//       .catch((err) => {
//         dispatch(setResourcesDetails({}));
//         setIsEdit(false);
//         dispatch(showFailedNoti(err));
//         dispatch(reset("resourcesform"));
//       });
//   }
//   // Refresh Resources Card
//   const handleOnRefreshResources = () => {
//     setRefreshResources(true);
//     dispatch(setResourcesDetails({}))
//     dispatch(getResources()).then(() =>{
//       dispatch(reset("resourcesForm"));
//       setTimeout(() => setRefreshResources(false),500)
//     })

//   };
//   // Edit Resources Details
//   const handleEditResources = (id) => {
//       dispatch(resourcesById(id)).then(() => {
//         setIsEdit(true)
//         openResourcesModal(true)
//       })
//   };
//   // Delete Resources
//   const handleDeleteResources = () => {
//     setDeleteResourcesModal(false);
//     setRefreshResources(true);
//     let id = currentId;
//     api.resources
//       .delete(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           dispatch(getResources())
//           setRefreshResources(false)
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshResources(false)
//         }

//       })
//       .catch((err) => console.error(err.message));

//   };
//   const confirmhandleDeleteResources = (id) => {
//     setCurrentId(id);
//     setDeleteResourcesModal(true);
//   };
//   // Edit Announcement Details
//   const handleEditAnnouncement = (id) => {
//     dispatch(getAnnouncementDetails(id)).then(() => {
//       setIsEdit(true);
//       openAnnouncementModal();
//     });
//   };
//   // Delete Annouoncement
//   const handleDeleteAnnouncement = () => {
//     setDeleteAnnouncementModal(false);
//     let {id }= currentId;
//     setRefreshAnnouncement(true);
//     api.announcement
//       .deleteAnnouncement(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           dispatch(getAnnouncement())
//           setRefreshAnnouncement(false)
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshAnnouncement(false)
//         }

//       })
//       .catch((err) =>console.error(err.message) );
//   };
//   const confirmDeleteAnnouncement = (id) => {
//     setCurrentId(id);
//     setDeleteAnnouncementModal(true);
//   };

//   useEffect(() => {
//     setRefreshPromo(true);
//     dispatch(getPromoCodes()).then(() =>
//       setTimeout(() => setRefreshPromo(false), 1000)
//     );
//     setRefreshTag(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTag(false), 1000)
//     );
//     dispatch(totalcount());
//     dispatch(getAnnouncement()).then(() =>
//       setTimeout(() => setRefreshAnnouncement(false), 1000)
//     );
//     setRefreshResources(true)
//     dispatch(getResources()).then(() => {
//       setTimeout(() => setRefreshResources(false),1000)
//     })
//   }, []);

//   return (
//     <Container className="dashboard">
//       <Row>
//         <TotalPayout />
//         <RegistrationStats
//           onRefresh={handleOnRefreshRegistrationStats}
//           refreshRegistrationStats={refreshRegistrationStats}
//         />
//         <TagsCounter
//           onRefresh={handleOnRefreshTagsCounter}
//           refreshTagsCounter={refreshTagsCounter}
//         />
//       </Row>
//       <AddNewTag
//         isOpen={tagModel}
//         isEdit={isEdit}
//         closeModal={closeTagModal}
//         handleTag={handleAddNewTag}
//       />
//       <Row>
//         <Announcement
//           openAnnouncementModal={openAnnouncementModal}
//           closeAnnouncementModal={closeAnnouncementModal}
//           announcementList={announcementList}
//           onRefresh={handleOnRefreshAnnouncement}
//           refreshAnnouncement={refreshAnnouncement}
//           handleEditAnnouncement={(id) => handleEditAnnouncement(id)}
//           handleDeleteAnnouncement={(id) => confirmDeleteAnnouncement(id)}
//         />
//         <PromoCodes
//           openPromoCodeModal={openPromoCodeModal}
//           closePromoCodeModal={closePromoCodeModal}
//           promocodeList={promocodeList}
//           onRefresh={handleOnRefreshPromo}
//           refreshPromo={refreshPromo}
//           handleEditPromo={(id) => handleEditPromo(id)}
//           handleDeletePromo={(id) => confirmDeletePromo(id)}
//         />
//         {/* <CountOfRegistrations /> */}
//         <DetailsTag
//           openTagsModal={openTagModal}
//           tagList={allTags}
//           onRefresh={handleOnRefreshTag}
//           refreshTags={refreshTag}
//           handleEditTag={(id) => handleEditTag(id)}
//           handleDeletetag={(id) => confirmDeleteTag(id)}
//         />
//         <AdminResources
//           openResourcesModal={openResourcesModal}
//           closeResourcesModal={closeResourcesModal}
//           resource={resource}
//           onRefresh={handleOnRefreshResources}
//           refreshResources={refreshResources}
//           handleEditResources={(id) => handleEditResources(id)}
//           handleDeleteResources={(id) => confirmhandleDeleteResources(id)}
//         />
//       </Row>
//       <Row>
//         {/* form of adding promocode */}
//         {modal.promoCode && (
//           <AddPromoCode
//             promoCodeSubmit={promoCodeSubmit}
//             isPromoOpen={modal.promoCode}
//             closePromoModal={closePromoCodeModal}
//             isEdit={isEdit}
//           />
//         )}

//         {/* form of adding announcement */}
//         <AddAnnouncement
//           handleAnnouncement={handleAnnouncement}
//           isAnnouncementOpen={modal.announcement}
//           isEdit={isEdit}
//           closeAnnouncementModal={closeAnnouncementModal}
//         />
//         {/* Add Details of all api */}

//         {/* form of Adding Resources */}
//         <AddResources
//           isResourcesOpen={modal.resourcesModal}
//           isEdit={isEdit}
//           closeResourcesModal={closeResourcesModal}
//           handleResources={handleResources}
//         />
//       </Row>

//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Promo Code?"
//         colored=""
//         header={true}
//         isOpen={deletePromoModal}
//         closeModal={handleClose}
//         onYesClick={handleDeletePromo}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message={`Are you sure you want to delete ${currentId.name}?`}
//         colored=""
//         header={true}
//         isOpen={deleteTagModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteTag}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Announcement?"
//         colored=""
//         header={true}
//         isOpen={deleteAnnouncementModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteAnnouncement}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Resource?"
//         colored=""
//         header={true}
//         isOpen={deleteResourcesModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteResources}
//       />

//     </Container>
//   );
// };

// export default AdminHome;

import React, { useState, useEffect } from "react";
import TagCard from "./components/TagCard";
import { tagTypes } from "../../config/tagTypes";
import { Row, Container } from "reactstrap";
import AddNewTag from "./components/AddNewTag";
import api from ".././../utils/apiClient";
import { Button, Icon, TableRow, TableCell } from "@material-ui/core";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ButtonToolbar,
  Table,
} from "reactstrap";
import { reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import SearchableTable from "../../shared/components/SearchableTable";
import {
  promoCodeModal,
  announcementModal,
  resourcesModal,
} from "../../redux/actions/modalAction";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
} from "reactstrap";
import {
  showFailedNoti,
  showSucessNoti,
} from "../../redux/actions/notificationAction";
import {
  getPromoCodes,
  addPromoCode,
  getPromoCodeById,
  setPromoCodeById,
} from "../../redux/actions/promoActions";
import {
  getTagsByTagType,
  getTagDetails,
  setTagDetails,
} from "../../redux/actions/tagAction";
import { totalcount } from "../../redux/actions/profileActions";
import {
  getAnnouncement,
  getAnnouncementDetails,
  setAnnouncementDetails,
} from "../../redux/actions/AnnouncementAction";
import TotalPayout from "./components/TotalPayout";
import Announcement from "./components/Announcement";
import PromoCodes from "./components/PromoCodes";
import AddPromoCode from "./components/AddPromoCode";
import AddAnnouncement from "./components/AddAnnouncement";
import DetailsTag from "./components/DetailsTagCard";
import TagsCounter from "./components/TagsCounter";
import RegistrationStats from "./components/RegistrationStats";
import ConfirmationModal from "../../shared/components/ConfirmationModal";
import AdminResources from "./components/AdminResources";
import AddResources from "./components/AddResources";
import {
  postResources,
  getResources,
  resourcesById,
  setResourcesDetails,
} from "../../redux/actions/resourcesAction";
import { announcementType } from "config/announcementType";
import { salesReport } from "redux/actions/transactionActions";
import { uniqBy } from "lodash";
import { getProviderList } from "redux/actions/profileActions";
// import { exportToCsv2 } from "shared/helpers"
import { CSVLink, CSVDownload } from "react-csv";

//import  _ from "lodash"
// import history from '../../history'
import moment from "moment";

const rows = [
  {
    id: 1,
    label: "Date Time",
  },

  {
    id: 2,
    label: "Program Name",
  },
  {
    id: 3,
    label: "Student Name",
  },
  {
    id: 4,
    label: "Provider Email",
  },
  {
    id: 5,
    label: "Payment Method",
  },
  {
    id: 6,
    label: "Commission",
  },
  {
    id: 7,
    label: "Payout Total",
  },
];
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const AdminHome = () => {
  const dispatch = useDispatch();
  const [tagModel, settagModel] = useState(false);
  const [refreshPromo, setRefreshPromo] = useState(false);
  const [refreshTag, setRefreshTag] = useState(false);
  const [refreshAnnouncement, setRefreshAnnouncement] = useState(false);
  const [refreshResources, setRefreshResources] = useState(false);
  const [refreshRegistrationStats, setRefreshRegistrationStats] =
    useState(false);
  const [salesInfo, setsalesInfo] = useState();
  const [refreshTagsCounter, setRefreshTagsCounter] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [deleteAnnouncementModal, setDeleteAnnouncementModal] = useState(false);
  const [deleteResourcesModal, setDeleteResourcesModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [currentId, setCurrentId] = React.useState({});
  //Selector for promo code modal/popup.
  const modal = useSelector((state) => state.modal);
  const sss = useSelector((state) => state.salesReport);

  // dispatch(getPromoCodes())
  const { promocodeList } = useSelector((state) => state.promocode);
  const { allTags } = useSelector((state) => state.tags);
  const { announcementList } = useSelector((state) => state.announcement);
  const { resource } = useSelector((state) => state.resources);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setrowsPerPage1] = useState(50);
  const { providerList } = useSelector((state) => state.provider);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage1 = (event, page) => {
    setPage1(page);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setrowsPerPage1(parseInt(event.target.value));
  };

  const closePromoCodeModal = () => {
    dispatch(setPromoCodeById({}));
    dispatch(promoCodeModal(false));
    setIsEdit(false);
    dispatch(reset("promocodeForm"));
  };
  const openPromoCodeModal = () => {
    dispatch(promoCodeModal(true));
  };
  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  //Selector for announcement modal/pop-up.
  const openAnnouncementModal = () => {
    dispatch(announcementModal(true));
  };
  const closeAnnouncementModal = () => {
    dispatch(setAnnouncementDetails({}));
    setIsEdit(false);
    dispatch(announcementModal(false));
    dispatch(reset("announcement-form"));
  };
  //Selector for Resources modal/pop-up
  const openResourcesModal = () => {
    dispatch(resourcesModal(true));
  };
  const closeResourcesModal = () => {
    dispatch(setResourcesDetails({}));
    dispatch(resourcesModal(false));
    dispatch(reset("resourcesForm"));
  };
  //Selector for Tags modal/pop-up.
  const openTagModal = () => {
    settagModel(true);
  };
  const closeTagModal = () => {
    dispatch(setTagDetails({}));
    setIsEdit(false);
    settagModel(false);
    dispatch(reset("tagForm"));
  };

  const handleClose = () => {
    setDeletePromoModal(false);
    setDeleteTagModal(false);
    setDeleteAnnouncementModal(false);
    setDeleteResourcesModal(false);
  };

  const [saleReports, setSalesReports] = useState([]);
  const [individualProviderData, setIndividualProviderData] = useState([]);
  const [exportData, setExportData] = useState();
  const [exportVar, setExportVar] = useState([]);
  const [exportVar2, setExportVar2] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [clk, setClk] = useState(false);

  const getSalesReport = (id) => {
    api.transaction
      .salesReport(id)
      .then((res) => {
        if (res.data.data) {
          console.log("idd", id);
          if (id) {
            console.log("id of selecting prog.", id);
            setIndividualProviderData(res.data.data);
          } else {
            setIndividualProviderData(res.data.data);
            setSalesReports(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  //API Implementaion to manage Promo Code.
  const promoCodeSubmit = (values) => {
    let promoCodeData = {
      id: values.id,
      codeName: values.codeName,
      code: values.code,
      discountPrice: parseInt(values.discountPrice),
      validUpto: new Date(values.validUpto).toISOString(),
    };
    api.promoCode
      .managePromoCode(promoCodeData, true)
      .then((res) => {
        setIsEdit(false);
        closePromoCodeModal();
        dispatch(reset("promocodeForm"));
        dispatch(showSucessNoti(res.data.message));
        handleOnRefreshPromo();
      })
      .catch((err) => {
        setIsEdit(false);
        dispatch(setPromoCodeById({}));
        console.error(err.message);
      });
  };
  // to get program id and student id
  const getById = (values) => {
    let id = [];
    values.map((p) => {
      id.push(p.id);
    });
    return id;
  };
  // Handle Announcement Submit
  const handleAnnouncement = (values) => {
    let submitData = {
      id: values.id,
      announcementName: values.announcementName,
      announcementFor: values.announcementFor.id,
      programId: getById(values.programId),
      studentId: getById(values.studentId),
      allStudents:
        values.announcementFor.id === announcementType.AllStudents
          ? true
          : false,
      description: values.description,
      allProvider:
        values.announcementFor.id === announcementType.AllProvider
          ? true
          : false,
    };
    api.announcement
      .addAnnouncement(submitData)
      .then((res) => {
        if (res.data.data) {
          setIsEdit(false);
          closeAnnouncementModal();
          dispatch(setAnnouncementDetails({}));
          dispatch(reset("announcementForm"));
          dispatch(showSucessNoti(res.data.message));
          handleOnRefreshAnnouncement();
        } else {
          setIsEdit(false);
          dispatch(showFailedNoti(res.data.message));
          dispatch(setAnnouncementDetails({}));
        }
      })
      .catch((err) => {
        setAnnouncementDetails({});
        setIsEdit(false);
        dispatch(showFailedNoti(err.message));
        dispatch(reset("announcement-form"));
      });
  };
  //API Implementaion to manage Tag
  const handleAddNewTag = async (values) => {
    let data = {
      id: values.id,
      name: values.name,
      tagType: values.tagType.tagType ? values.tagType.tagType : values.tagType,
      types: values.tagType.types ? values.tagType.types : values.types,
    };
    await api.tag
      .manageTag(data, true)
      .then((res) => {
        if (res.data.data) {
          dispatch(reset("tagForm"));
          closeTagModal();
          handleOnRefreshTag();
          dispatch(showSucessNoti(res.data.message));
        } else {
          dispatch(reset("tagForm"));
          dispatch(showFailedNoti(res.data.message));
        }
      })
      .catch((err) => {
        closeTagModal();
        dispatch(setTagDetails({}));
        dispatch(reset("tagForm"));
        dispatch(showFailedNoti(err));
      });
  };
  // Refresh Promo Card
  const handleOnRefreshPromo = () => {
    setRefreshPromo(true);
    dispatch(getPromoCodes()).then(() =>
      setTimeout(() => setRefreshPromo(false), 500)
    );
  };
  // Edit Promo Code Details
  const handleEditPromo = (id) => {
    dispatch(getPromoCodeById(id)).then(() => {
      setIsEdit(true);
      dispatch(promoCodeModal(true));
    });
  };
  // Delete Promo Code
  const handleDeletePromo = () => {
    setDeletePromoModal(false);
    setRefreshPromo(true);
    let id = currentId;
    api.promoCode
      .deletePromoCode(id)
      .then((res) => {
        if (res.data.data) {
          dispatch(showSucessNoti(res.data.message));
          setRefreshPromo(false);
          dispatch(getPromoCodes());
        } else {
          dispatch(showFailedNoti(res.data.message));
          setRefreshPromo(false);
        }
      })
      .catch((err) => console.error(err.message));
  };
  const confirmDeletePromo = (id) => {
    setCurrentId(id);
    setDeletePromoModal(true);
  };
  // Refresh Tags Card
  const handleOnRefreshTag = () => {
    setRefreshTag(true);
    dispatch(getTagsByTagType(null)).then(() =>
      setTimeout(() => setRefreshTag(false), 500)
    );
  };
  // Edit Tag Details
  const handleEditTag = (id) => {
    dispatch(getTagDetails(id)).then(() => {
      setIsEdit(true);
      settagModel(true);
    });
  };
  // Delete Tag
  const handleDeleteTag = () => {
    let { id } = currentId;
    setDeleteTagModal(false);
    setRefreshTag(true);
    api.tag
      .deleteTag(id)
      .then((res) => {
        dispatch(getTagsByTagType(null));
        setRefreshTag(false);
        if (res.data.data) {
          dispatch(showSucessNoti(res.data.message));
        } else {
          dispatch(showFailedNoti(res.data.message));
        }
      })
      .catch((err) => console.error(err.message));
  };
  const confirmDeleteTag = (id) => {
    setCurrentId(id);
    setDeleteTagModal(true);
  };

  // Refresh Registration Stats
  const handleOnRefreshRegistrationStats = () => {
    setRefreshRegistrationStats(true);
    setTimeout(() => setRefreshRegistrationStats(false), 500);
  };
  // Refresh Tags Stats
  const handleOnRefreshTagsCounter = () => {
    setRefreshTagsCounter(true);
    dispatch(getTagsByTagType(null)).then(() =>
      setTimeout(() => setRefreshTagsCounter(false), 1000)
    );
  };
  // Refresh Announcement Card
  const handleOnRefreshAnnouncement = () => {
    setRefreshAnnouncement(true);
    dispatch(getAnnouncement()).then(() =>
      setTimeout(() => setRefreshAnnouncement(false), 500)
    );
  };
  // Handle Resources
  const handleResources = async (val) => {
    let data = {
      id: val.id,
      subject: val.subject,
      body: val.body,
      link: val.externalLink,
    };
    await api.resources
      .post(data)
      .then((res) => {
        if (res.data) {
          dispatch(reset("resourcesForm"));
          dispatch(setResourcesDetails({}));
          closeResourcesModal();
          handleOnRefreshResources();
          dispatch(showSucessNoti(res.data.message));
        } else {
          setIsEdit(false);
          dispatch(reset("resourcesForm"));
          dispatch(showFailedNoti(res.data.message));
        }
      })
      .catch((err) => {
        dispatch(setResourcesDetails({}));
        setIsEdit(false);
        dispatch(showFailedNoti(err));
        dispatch(reset("resourcesform"));
      });
  };
  // Refresh Resources Card
  const handleOnRefreshResources = () => {
    setRefreshResources(true);
    dispatch(setResourcesDetails({}));
    dispatch(getResources()).then(() => {
      dispatch(reset("resourcesForm"));
      setTimeout(() => setRefreshResources(false), 500);
    });
  };
  // Edit Resources Details
  const handleEditResources = (id) => {
    dispatch(resourcesById(id)).then(() => {
      setIsEdit(true);
      openResourcesModal(true);
    });
  };
  // Delete Resources
  const handleDeleteResources = () => {
    setDeleteResourcesModal(false);
    setRefreshResources(true);
    let id = currentId;
    api.resources
      .delete(id)
      .then((res) => {
        if (res.data.data) {
          dispatch(showSucessNoti(res.data.message));
          dispatch(getResources());
          setRefreshResources(false);
        } else {
          dispatch(showFailedNoti(res.data.message));
          setRefreshResources(false);
        }
      })
      .catch((err) => console.error(err.message));
  };
  const confirmhandleDeleteResources = (id) => {
    setCurrentId(id);
    setDeleteResourcesModal(true);
  };
  // Edit Announcement Details
  const handleEditAnnouncement = (id) => {
    dispatch(getAnnouncementDetails(id)).then(() => {
      setIsEdit(true);
      openAnnouncementModal();
    });
  };
  // Delete Annouoncement
  const handleDeleteAnnouncement = () => {
    setDeleteAnnouncementModal(false);
    let { id } = currentId;
    setRefreshAnnouncement(true);
    api.announcement
      .deleteAnnouncement(id)
      .then((res) => {
        if (res.data.data) {
          dispatch(showSucessNoti(res.data.message));
          dispatch(getAnnouncement());
          setRefreshAnnouncement(false);
        } else {
          dispatch(showFailedNoti(res.data.message));
          setRefreshAnnouncement(false);
        }
      })
      .catch((err) => console.error(err.message));
  };
  const confirmDeleteAnnouncement = (id) => {
    setCurrentId(id);
    setDeleteAnnouncementModal(true);
  };

  useEffect(() => {
    dispatch(getProviderList());

    getSalesReport();
    dispatch(salesReport());
    // handleSalesReport()
    setRefreshPromo(true);
    dispatch(getPromoCodes()).then(() =>
      setTimeout(() => setRefreshPromo(false), 1000)
    );
    setRefreshTag(true);
    dispatch(getTagsByTagType(null)).then(() =>
      setTimeout(() => setRefreshTag(false), 1000)
    );
    dispatch(totalcount());
    dispatch(getAnnouncement()).then(() =>
      setTimeout(() => setRefreshAnnouncement(false), 1000)
    );
    setRefreshResources(true);
    dispatch(getResources()).then(() => {
      setTimeout(() => setRefreshResources(false), 1000);
    });
  }, []);

  const handleSalesReport = (e) => {
    getSalesReport(Number(e.target.value));
  };

  function exportTable() {
    let arr = [{}];
    let splicedItems = individualProviderData.slice(
      page1 * rowsPerPage1,
      page1 * rowsPerPage1 + rowsPerPage1
    );
    splicedItems.map((item) => {
      return arr.push({
        transactionDate: item.transactionDate,
        programName: item.programName,
        studentName: item.studentName,
        providerEmail: item.providerEmail,
        strPaymentMethod: item.strPaymentMethod,
        fees: item.fees.toFixed(2),
        amount: item.amount,
      });
    });
    setExportData(splicedItems);
    if (arr.length > 0) {
      setExportVar(arr);
    }

    setClicked(!clicked);
    setClk(true);
  }
  console.log("splicedItems", exportVar);

  function exportTable2() {
    let arr = [{}];
    let splicedItems = saleReports.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    splicedItems.map((item) => {
      return arr.push({
        transactionDate: item.transactionDate,
        programName: item.programName,
        studentName: item.studentName,
        providerEmail: item.providerEmail,
        strPaymentMethod: item.strPaymentMethod,
        fees: item.fees.toFixed(2),
        amount: item.amount,
      });
    });
    setExportData(splicedItems);
    if (arr.length > 0) {
      setExportVar(arr);
    }
    console.log("arr", arr);

    setClicked(!clicked);
    setClk(true);
  }
  console.log("splicedItems", exportVar);

  const headers = [
    {
      label: "Date",
      key: "transactionDate",
    },
    {
      label: "Program Name ",
      key: "programName",
    },
    {
      label: " Student Name",
      key: "studentName",
    },
    {
      label: "Provider Email",
      key: "providerEmail",
    },
    {
      label: " Payment Method",
      key: "strPaymentMethod",
    },
    {
      label: "Commission",
      key: "fees",
    },
    {
      label: " Amount",
      key: "amount",
    },
  ];

  const csvLink = {
    filename: "file.csv",
    headers: headers,
    data: exportVar,
  };

  return (
    <>
      <Card className="h-auto">
        <CardBody className="p-0">
          <div className="profile__card tabs tabs--bordered-bottom">
            {/* <div className="tabs__wrap" > */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  All Providers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Individual Providers
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane
                tabId="2"
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <select onChange={(e) => handleSalesReport(e)}>
                  {/*             
         {uniqBy(saleReports,'programName')?.map((data)=>{
            // console.log("providerlist",providerList)
              return(
             
                <option value={data.providerId}>{data.providerId}</option>
              )
           
           
          })}  */}
                  {providerList.map((data) => {
                    return (
                      <option value={data.userId}>
                        {data.organisationName}
                      </option>
                    );
                  })}
                </select>
                <button
                  style={{ border: "none" }}
                  onClick={() => exportTable()}
                >
                  {clicked === true ? null : "Enable download"}
                </button>

                {/* <div > */}
                <CSVLink
                  {...csvLink}
                  onClick={() => {
                    exportTable();
                    setClk(!true);
                  }}
                >
                  {clk === true ? "Click to Download" : null}
                </CSVLink>
                {/* </div> */}

                <SearchableTable
                  rows={rows}
                  filteredData={individualProviderData}
                  isShow={false}
                  searchPlaceholder=""
                  rowsPerPage={rowsPerPage1}
                  page={page1}
                  handleChangePage={handleChangePage1}
                  handleChangeRowsPerPage={handleChangeRowsPerPage1}

                  // actionTablebtn={true}
                  // actionBtnIcon="publish"
                  // actionBtnText="Export"
                  // actionBtnClick={exportToCsv2}
                >
                  {console.log(
                    "jhsj",
                    individualProviderData.slice(
                      page1 * rowsPerPage1,
                      page1 * rowsPerPage1 + rowsPerPage1
                    )
                  )}
                  {individualProviderData
                    .slice(
                      page1 * rowsPerPage1,
                      page1 * rowsPerPage1 + rowsPerPage1
                    )
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          {index + 1 + page1 * rowsPerPage1}
                        </TableCell>
                        <TableCell className="material-table__cell text-nowrap">
                          {moment(item.transactionDate).format(
                            "DD-MM-YYYY HH:mm A"
                          )}
                        </TableCell>
                        <TableCell className={""}>{item.programName}</TableCell>
                        <TableCell className={""}>{item.studentName}</TableCell>
                        <TableCell className={""}>
                          {item.providerEmail}
                        </TableCell>
                        <TableCell className={""}>
                          {item.strPaymentMethod}
                        </TableCell>
                        <TableCell className={""}>
                          {item.fees.toFixed(2)}
                        </TableCell>
                        <TableCell className={""}>{item.amount}</TableCell>
                      </TableRow>
                    ))}
                </SearchableTable>
              </TabPane>

              <TabPane
                tabId="1"
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <button
                  style={{ border: "none" }}
                  onClick={() => exportTable2()}
                >
                  {clicked === true ? null : "Enable download"}
                </button>

                {/* <div > */}
                <CSVLink
                  {...csvLink}
                  onClick={() => {
                    exportTable2();
                    setClk(!true);
                  }}
                >
                  {clk === true ? "Click to Download" : null}
                </CSVLink>
                {/* </div> */}

                <SearchableTable
                  rows={rows}
                  searchPlaceholder=""
                  filteredData={saleReports}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}

                  //    actionTablebtn={true}
                  //   actionBtnIcon="publish"
                  //  actionBtnText="Export"
                  //    actionBtnClick={exportToCsv2}
                >
                  {saleReports
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell className="material-table__cell text-nowrap">
                          {moment(item.transactionDate).format(
                            "DD-MM-YYYY HH:mm A"
                          )}
                        </TableCell>
                        <TableCell className={""}>{item.programName}</TableCell>
                        <TableCell className={""}>{item.studentName}</TableCell>
                        <TableCell className={""}>
                          {item.providerEmail}
                        </TableCell>
                        <TableCell className={""}>
                          {item.strPaymentMethod}
                        </TableCell>
                        <TableCell className={""}>
                          {item.fees.toFixed(2)}
                        </TableCell>
                        <TableCell className={""}>{item.amount}</TableCell>
                      </TableRow>
                    ))}
                  {/* <>
              {saleReports
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow
                    key={index}
                >
                    <TableCell padding="checkbox">
                      
                        {(index + 1)+ (page * rowsPerPage)}
                    </TableCell>
                    <TableCell className="material-table__cell text-nowrap"  >
                    {moment(item.transactionDate).format("DD-MM-YYYY HH:mm A")}
                    </TableCell>
                    <TableCell className={""} >
                    {item.programName}
                    </TableCell>
                    <TableCell className={""} >
                    {item.studentName}
                    </TableCell>
                    <TableCell className={""} >
                    {item.paymentMethod}
                    </TableCell>
                    <TableCell className={""} >
                    {item.amount}
                    </TableCell>
                   
                </TableRow>

                  );
                })}
            </> */}
                </SearchableTable>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
        {/* </div> */}
      </Card>

      <salesReport isOpen={salesInfo} />

      <Container className="dashboard">
        <Row>
          <TotalPayout />
          <RegistrationStats
            onRefresh={handleOnRefreshRegistrationStats}
            refreshRegistrationStats={refreshRegistrationStats}
          />
          <TagsCounter
            onRefresh={handleOnRefreshTagsCounter}
            refreshTagsCounter={refreshTagsCounter}
          />
        </Row>
        <AddNewTag
          isOpen={tagModel}
          isEdit={isEdit}
          closeModal={closeTagModal}
          handleTag={handleAddNewTag}
        />
        <Row>
          <Announcement
            openAnnouncementModal={openAnnouncementModal}
            closeAnnouncementModal={closeAnnouncementModal}
            announcementList={announcementList}
            onRefresh={handleOnRefreshAnnouncement}
            refreshAnnouncement={refreshAnnouncement}
            handleEditAnnouncement={(id) => handleEditAnnouncement(id)}
            handleDeleteAnnouncement={(id) => confirmDeleteAnnouncement(id)}
          />
          <PromoCodes
            openPromoCodeModal={openPromoCodeModal}
            closePromoCodeModal={closePromoCodeModal}
            promocodeList={promocodeList}
            onRefresh={handleOnRefreshPromo}
            refreshPromo={refreshPromo}
            handleEditPromo={(id) => handleEditPromo(id)}
            handleDeletePromo={(id) => confirmDeletePromo(id)}
          />
          {/* <CountOfRegistrations /> */}
          <DetailsTag
            openTagsModal={openTagModal}
            tagList={allTags}
            onRefresh={handleOnRefreshTag}
            refreshTags={refreshTag}
            handleEditTag={(id) => handleEditTag(id)}
            handleDeletetag={(id) => confirmDeleteTag(id)}
          />
          <AdminResources
            openResourcesModal={openResourcesModal}
            closeResourcesModal={closeResourcesModal}
            resource={resource}
            onRefresh={handleOnRefreshResources}
            refreshResources={refreshResources}
            handleEditResources={(id) => handleEditResources(id)}
            handleDeleteResources={(id) => confirmhandleDeleteResources(id)}
          />
        </Row>
        <Row>
          {/* form of adding promocode */}
          {modal.promoCode && (
            <AddPromoCode
              promoCodeSubmit={promoCodeSubmit}
              isPromoOpen={modal.promoCode}
              closePromoModal={closePromoCodeModal}
              isEdit={isEdit}
            />
          )}

          {/* form of adding announcement */}
          <AddAnnouncement
            handleAnnouncement={handleAnnouncement}
            isAnnouncementOpen={modal.announcement}
            isEdit={isEdit}
            closeAnnouncementModal={closeAnnouncementModal}
          />
          {/* Add Details of all api */}

          {/* form of Adding Resources */}
          <AddResources
            isResourcesOpen={modal.resourcesModal}
            isEdit={isEdit}
            closeResourcesModal={closeResourcesModal}
            handleResources={handleResources}
          />
        </Row>

        <ConfirmationModal
          color="danger"
          title="Delete Confirmation"
          message="Are you sure you want to delete this Promo Code?"
          colored=""
          header={true}
          isOpen={deletePromoModal}
          closeModal={handleClose}
          onYesClick={handleDeletePromo}
        />
        <ConfirmationModal
          color="danger"
          title="Delete Confirmation"
          message={`Are you sure you want to delete ${currentId.name}?`}
          colored=""
          header={true}
          isOpen={deleteTagModal}
          closeModal={handleClose}
          onYesClick={handleDeleteTag}
        />
        <ConfirmationModal
          color="danger"
          title="Delete Confirmation"
          message="Are you sure you want to delete this Announcement?"
          colored=""
          header={true}
          isOpen={deleteAnnouncementModal}
          closeModal={handleClose}
          onYesClick={handleDeleteAnnouncement}
        />
        <ConfirmationModal
          color="danger"
          title="Delete Confirmation"
          message="Are you sure you want to delete this Resource?"
          colored=""
          header={true}
          isOpen={deleteResourcesModal}
          closeModal={handleClose}
          onYesClick={handleDeleteResources}
        />
      </Container>
    </>
  );
};

export default AdminHome;

// import React, { useState, useEffect } from "react";
// import TagCard from "./components/TagCard";
// import { tagTypes } from "../../config/tagTypes";
// import { Row, Container } from "reactstrap";
// import AddNewTag from "./components/AddNewTag";
// import api from ".././../utils/apiClient";
// import { Button, Icon, TableRow, TableCell } from "@material-ui/core"
// import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
// import {
//   DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, ButtonToolbar, Table
// } from 'reactstrap'
// import { reset } from "redux-form";
// import { useDispatch, useSelector } from "react-redux";
// import SearchableTable from "../../shared/components/SearchableTable"
// import {
//   promoCodeModal,
//   announcementModal,
//   resourcesModal,
// } from "../../redux/actions/modalAction";
// import classnames from "classnames";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   Card,
//   Col,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   CardBody
// } from 'reactstrap';
// import {
//   showFailedNoti,
//   showSucessNoti,
// } from "../../redux/actions/notificationAction";
// import {
//   getPromoCodes,
//   addPromoCode,
//   getPromoCodeById,
//   setPromoCodeById,
// } from "../../redux/actions/promoActions";
// import {
//   getTagsByTagType,
//   getTagDetails,
//   setTagDetails,
// } from "../../redux/actions/tagAction";
// import { totalcount } from "../../redux/actions/profileActions";
// import {
//   getAnnouncement,
//   getAnnouncementDetails,
//   setAnnouncementDetails,
// } from "../../redux/actions/AnnouncementAction";
// import TotalPayout from "./components/TotalPayout";
// import Announcement from "./components/Announcement";
// import PromoCodes from "./components/PromoCodes";
// import AddPromoCode from "./components/AddPromoCode";
// import AddAnnouncement from "./components/AddAnnouncement";
// import DetailsTag from "./components/DetailsTagCard";
// import TagsCounter from "./components/TagsCounter";
// import RegistrationStats from "./components/RegistrationStats";
// import ConfirmationModal from "../../shared/components/ConfirmationModal";
// import AdminResources from "./components/AdminResources";
// import AddResources from "./components/AddResources";
// import { postResources,getResources,resourcesById,setResourcesDetails } from "../../redux/actions/resourcesAction"
// import {announcementType} from "config/announcementType"
// import { salesReport } from "../../redux/actions/transactionActions";
// // import history from '../../history'
// import moment from 'moment';

// const rows = [
//   {
//     id: 1,
//     label: "Date Time",
//   },

//   {
//     id: 3,
//     label: "Program Name",
//   },
//   {
//     id: 4,
//     label: "Student Name",
//   },
//   {
//     id: 5,
//     label: "Payment Method",
//   },
//   {
//     id: 6,
//     label: "Payout Total",
//   },

// ];
// const useRowStyles = makeStyles({
//   root: {
//     "& > *": {
//       borderBottom: "unset",
//     },
//   },
// });

// const AdminHome = () => {
//   const dispatch = useDispatch();
//   const [tagModel, settagModel] = useState(false);
//   const [refreshPromo, setRefreshPromo] = useState(false);
//   const [refreshTag, setRefreshTag] = useState(false);
//   const [refreshAnnouncement, setRefreshAnnouncement] = useState(false);
//   const [refreshResources, setRefreshResources] = useState(false);
//   const [refreshRegistrationStats, setRefreshRegistrationStats] = useState(
//     false
//   );
//   const [salesInfo, setsalesInfo] = useState()
//   const [refreshTagsCounter, setRefreshTagsCounter] = useState(false);
//   const [isEdit, setIsEdit] = useState(false);
//   const [deletePromoModal, setDeletePromoModal] = useState(false);
//   const [deleteTagModal, setDeleteTagModal] = useState(false);
//   const [deleteAnnouncementModal, setDeleteAnnouncementModal] = useState(false);
//   const [deleteResourcesModal, setDeleteResourcesModal] = useState(false);
//   const [activeTab, setActiveTab] = useState('1');
//   const [currentId, setCurrentId] = React.useState({});
//   //Selector for promo code modal/popup.
//   const modal = useSelector((state) => state.modal);
//   const sss = useSelector((state) => state.salesReport);
//
//   // dispatch(getPromoCodes())
//   const { promocodeList } = useSelector((state) => state.promocode);
//   const { allTags } = useSelector((state) => state.tags);
//   const { announcementList } = useSelector((state) => state.announcement);
//   const { resource } = useSelector(state => state.resources)

//   const closePromoCodeModal = () => {
//     dispatch(setPromoCodeById({}));
//     dispatch(promoCodeModal(false));
//     setIsEdit(false);
//     dispatch(reset("promocodeForm"));
//   };
//   const openPromoCodeModal = () => {
//     dispatch(promoCodeModal(true));
//   };
//   function toggle(tab) {
//     if (activeTab !== tab) {
//       setActiveTab(tab);
//     }
//   }

//   //Selector for announcement modal/pop-up.
//   const openAnnouncementModal = () => {
//     dispatch(announcementModal(true));
//   };
//   const closeAnnouncementModal = () => {
//     dispatch(setAnnouncementDetails({}));
//     setIsEdit(false);
//     dispatch(announcementModal(false));
//     dispatch(reset("announcement-form"));
//   };
//   //Selector for Resources modal/pop-up
//   const openResourcesModal = () => {
//     dispatch(resourcesModal(true));
//   };
//   const closeResourcesModal = () => {
//     dispatch(setResourcesDetails({}))
//       dispatch(resourcesModal(false));
//       dispatch(reset("resourcesForm"));
//   };
//   //Selector for Tags modal/pop-up.
//   const openTagModal = () => {
//     settagModel(true);
//   };
//   const closeTagModal = () => {
//     dispatch(setTagDetails({}));
//     setIsEdit(false);
//     settagModel(false);
//     dispatch(reset("tagForm"));
//   };

//   const handleClose = () => {
//     setDeletePromoModal(false);
//     setDeleteTagModal(false);
//     setDeleteAnnouncementModal(false);
//     setDeleteResourcesModal(false);
//     ;
//   };

//   const[saleReports,setSalesReports]=useState([]);
//   const[individualProviderData,setIndividualProviderData]=useState([]);
//   const getSalesReport=(id)=>{

//    api.transaction.salesReport(id).then(res => {
//       {console.log("abhi abhi kiya h",res.data.data)}

//       if (res.data.data) {

//         if(id){
//           setIndividualProviderData(res.data.data);
//         }
//         else{
//           setSalesReports(res.data.data);
//         }

//       }
//   }).catch(err => {
//       console.error(err.message)
//   })
//   }

//   //API Implementaion to manage Promo Code.
//   const promoCodeSubmit = (values) => {
//     let promoCodeData = {
//       id: values.id,
//       codeName: values.codeName,
//       code: values.code,
//       discountPrice: parseInt(values.discountPrice),
//       validUpto: new Date(values.validUpto).toISOString(),
//     };
//     api.promoCode
//       .managePromoCode(promoCodeData, true)
//       .then((res) => {
//         setIsEdit(false);
//         closePromoCodeModal();
//         dispatch(reset("promocodeForm"));
//         dispatch(showSucessNoti(res.data.message));
//         handleOnRefreshPromo();
//       })
//       .catch((err) => {
//         setIsEdit(false);
//         dispatch(setPromoCodeById({}));
//         console.error(err.message)

//       });
//   };
//   // to get program id and student id
//   const getById = (values) => {
//     let id = []
//     values.map((p) => {
//       id.push(p.id)
//     })
//     return id
//   }
//   // Handle Announcement Submit
//   const handleAnnouncement = (values) => {
//     let submitData = {
//       id: values.id,
//       announcementName: values.announcementName,
//       announcementFor:values.announcementFor.id,
//       programId: getById(values.programId),
//       studentId: getById(values.studentId),
//       allStudents: values.announcementFor.id === announcementType.AllStudents?true :false,
//       description: values.description,
//       allProvider: values.announcementFor.id === announcementType.AllProvider? true : false
//     };
//     api.announcement
//       .addAnnouncement(submitData)
//       .then((res) => {
//         if (res.data.data) {
//           setIsEdit(false);
//           closeAnnouncementModal();
//           dispatch(setAnnouncementDetails({}))
//           dispatch(reset("announcementForm"));
//           dispatch(showSucessNoti(res.data.message));
//           handleOnRefreshAnnouncement();
//         } else {
//           setIsEdit(false);
//           dispatch(showFailedNoti(res.data.message));
//           dispatch(setAnnouncementDetails({}))
//         }
//       })
//       .catch((err) => {
//         setAnnouncementDetails({});
//         setIsEdit(false);
//         dispatch(showFailedNoti(err.message));
//         dispatch(reset("announcement-form"));
//       });
//   };
//   //API Implementaion to manage Tag
//   const handleAddNewTag = async (values) => {
//     let data = {
//       id: values.id,
//       name: values.name,
//       tagType: values.tagType.tagType?values.tagType.tagType:values.tagType,
//       types: values.tagType.types? values.tagType.types:values.types,
//     };
//     await api.tag
//       .manageTag(data, true)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(reset("tagForm"));
//           closeTagModal();
//           handleOnRefreshTag();
//           dispatch(showSucessNoti(res.data.message));
//         } else {
//           dispatch(reset("tagForm"));
//           dispatch(showFailedNoti(res.data.message))
//         }
//       })
//       .catch((err) => {
//         closeTagModal();
//         dispatch(setTagDetails({}));
//         dispatch(reset("tagForm"));
//         dispatch(showFailedNoti(err));
//       });
//   };
//   // Refresh Promo Card
//   const handleOnRefreshPromo = () => {
//     setRefreshPromo(true);
//     dispatch(getPromoCodes()).then(() =>
//       setTimeout(() => setRefreshPromo(false), 500)
//     );
//   };
//   // Edit Promo Code Details
//   const handleEditPromo = (id) => {
//     dispatch(getPromoCodeById(id)).then(() => {
//       setIsEdit(true);
//       dispatch(promoCodeModal(true));
//     });
//   };
//   // Delete Promo Code
//   const handleDeletePromo = () => {
//     setDeletePromoModal(false);
//     setRefreshPromo(true);
//     let id = currentId;
//     api.promoCode
//       .deletePromoCode(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           setRefreshPromo(false)
//           dispatch(getPromoCodes())
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshPromo(false)
//         }

//       })
//       .catch((err)=> console.error(err.message));
//   };
//   const confirmDeletePromo = (id) => {
//     setCurrentId(id);
//     setDeletePromoModal(true);
//   };
//   // Refresh Tags Card
//   const handleOnRefreshTag = () => {
//     setRefreshTag(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTag(false), 500)
//     );
//   };
//   // Edit Tag Details
//   const handleEditTag = (id) => {
//     dispatch(getTagDetails(id)).then(() => {
//       setIsEdit(true);
//       settagModel(true);
//     });
//   };
//   // Delete Tag
//   const handleDeleteTag = () => {
//     let {id} = currentId;
//     setDeleteTagModal(false);
//     setRefreshTag(true);
//     api.tag
//       .deleteTag(id)
//       .then((res) => {
//         dispatch(getTagsByTagType(null))
//         setRefreshTag(false)
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//         }

//       }).catch(err=>console.error(err.message))
//   };
//   const confirmDeleteTag = (id) => {
//     setCurrentId(id);
//     setDeleteTagModal(true);
//   };

//   // Refresh Registration Stats
//   const handleOnRefreshRegistrationStats = () => {
//     setRefreshRegistrationStats(true);
//     setTimeout(() => setRefreshRegistrationStats(false), 500);
//   };
//   // Refresh Tags Stats
//   const handleOnRefreshTagsCounter = () => {
//     setRefreshTagsCounter(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTagsCounter(false), 1000)
//     );
//   };
//   // Refresh Announcement Card
//   const handleOnRefreshAnnouncement = () => {
//     setRefreshAnnouncement(true);
//     dispatch(getAnnouncement()).then(() =>
//       setTimeout(() => setRefreshAnnouncement(false), 500)
//     );
//   };
//   // Handle Resources
//   const handleResources=async(val)=>{
//       let data ={
//         id: val.id,
//         subject: val.subject,
//         body: val.body,
//         link: val.externalLink
//       }
//       await api.resources
//       .post(data)
//       .then((res) => {
//         if (res.data) {
//           dispatch(reset("resourcesForm"));
//           dispatch(setResourcesDetails({}));
//           closeResourcesModal();
//           handleOnRefreshResources();
//           dispatch(showSucessNoti(res.data.message));
//         }
//         else {
//           setIsEdit(false);
//           dispatch(reset("resourcesForm"));
//           dispatch(showFailedNoti(res.data.message));
//         }
//       })
//       .catch((err) => {
//         dispatch(setResourcesDetails({}));
//         setIsEdit(false);
//         dispatch(showFailedNoti(err));
//         dispatch(reset("resourcesform"));
//       });
//   }
//   // Refresh Resources Card
//   const handleOnRefreshResources = () => {
//     setRefreshResources(true);
//     dispatch(setResourcesDetails({}))
//     dispatch(getResources()).then(() =>{
//       dispatch(reset("resourcesForm"));
//       setTimeout(() => setRefreshResources(false),500)
//     })

//   };
//   // Edit Resources Details
//   const handleEditResources = (id) => {
//       dispatch(resourcesById(id)).then(() => {
//         setIsEdit(true)
//         openResourcesModal(true)
//       })
//   };
//   // Delete Resources
//   const handleDeleteResources = () => {
//     setDeleteResourcesModal(false);
//     setRefreshResources(true);
//     let id = currentId;
//     api.resources
//       .delete(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           dispatch(getResources())
//           setRefreshResources(false)
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshResources(false)
//         }

//       })
//       .catch((err) => console.error(err.message));

//   };
//   const confirmhandleDeleteResources = (id) => {
//     setCurrentId(id);
//     setDeleteResourcesModal(true);
//   };
//   // Edit Announcement Details
//   const handleEditAnnouncement = (id) => {
//     dispatch(getAnnouncementDetails(id)).then(() => {
//       setIsEdit(true);
//       openAnnouncementModal();
//     });
//   };
//   // Delete Annouoncement
//   const handleDeleteAnnouncement = () => {
//     setDeleteAnnouncementModal(false);
//     let {id }= currentId;
//     setRefreshAnnouncement(true);
//     api.announcement
//       .deleteAnnouncement(id)
//       .then((res) => {
//         if (res.data.data) {
//           dispatch(showSucessNoti(res.data.message))
//           dispatch(getAnnouncement())
//           setRefreshAnnouncement(false)
//         } else {
//           dispatch(showFailedNoti(res.data.message))
//           setRefreshAnnouncement(false)
//         }

//       })
//       .catch((err) =>console.error(err.message) );
//   };
//   const confirmDeleteAnnouncement = (id) => {
//     setCurrentId(id);
//     setDeleteAnnouncementModal(true);
//   };

//   useEffect(() => {
//     getSalesReport("");
//     dispatch(salesReport());
//     setRefreshPromo(true);
//     dispatch(getPromoCodes()).then(() =>
//       setTimeout(() => setRefreshPromo(false), 1000)
//     );
//     setRefreshTag(true);
//     dispatch(getTagsByTagType(null)).then(() =>
//       setTimeout(() => setRefreshTag(false), 1000)
//     );
//     dispatch(totalcount());
//     dispatch(getAnnouncement()).then(() =>
//       setTimeout(() => setRefreshAnnouncement(false), 1000)
//     );
//     setRefreshResources(true)
//     dispatch(getResources()).then(() => {
//       setTimeout(() => setRefreshResources(false),1000)
//     })
//   }, []);

//   const handleSalesReport=(e)=>{
//     getSalesReport(Number(e.target.value))
//   }

//   return (
//     <>
//     <Card className="h-auto">
//     <CardBody className="p-0">
//       <div className="profile__card tabs tabs--bordered-bottom" >
//         {/* <div className="tabs__wrap" > */}
//         <Nav tabs >
//           <NavItem >
//             <NavLink
//               className={classnames({ active: activeTab === "1" })}
//               onClick={() => {
//                 toggle("1");
//               }}
//             >
//               All Providers
//                 </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink
//               className={classnames({ active: activeTab === "2" })}
//               onClick={() => {
//                 toggle("2");
//               }}
//             >
//               Individual Providers
//                 </NavLink>
//           </NavItem>

//         </Nav>

//         <TabContent activeTab={activeTab}>
//           <TabPane tabId="2">
//           <select onChange={(e)=>handleSalesReport(e)}>
//           {saleReports?.map((data)=>{
//             return(

//               <option value={data.providerId}>{data.programName}</option>
//             )
//           })}

//         </select>
//           <SearchableTable
//                     rows={rows}
//                     filteredData={individualProviderData}
//                     isShow={false}
//                     searchPlaceholder=""
//                   >
//                      {individualProviderData.map((item, index) => (
//                         <TableRow
//                         key={index}
//                     >
//                         <TableCell padding="checkbox">
//                             {index+1}
//                         </TableCell>
//                         <TableCell className="material-table__cell text-nowrap"  >
//                         {moment(item.transactionDate).format("DD-MM-YYYY HH:mm A")}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.programName}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.studentName}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.paymentMethod}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.amount}
//                         </TableCell>

//                     </TableRow>
//                     ))}

//                   </SearchableTable>

//           </TabPane>
//           <TabPane tabId="1">
//           <SearchableTable
//                     rows={rows}
//                     searchPlaceholder=""
//                     filteredData={saleReports}
//                   >
//                      {saleReports.map((item, index) => (
//                         <TableRow
//                         key={index}
//                     >
//                         <TableCell padding="checkbox">
//                             {index+1}
//                         </TableCell>
//                         <TableCell className="material-table__cell text-nowrap"  >
//                         {moment(item.transactionDate).format("DD-MM-YYYY HH:mm A")}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.programName}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.studentName}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.paymentMethod}
//                         </TableCell>
//                         <TableCell className={""} >
//                         {item.amount}
//                         </TableCell>

//                     </TableRow>
//                     ))}

//                   </SearchableTable>
//           </TabPane>
//         </TabContent>
//       </div>
//     </CardBody>
//     {/* </div> */}
//   </Card>

//   <salesReport
//         isOpen={salesInfo}

//       />

//     <Container className="dashboard">
//       <Row>
//         <TotalPayout />
//         <RegistrationStats
//           onRefresh={handleOnRefreshRegistrationStats}
//           refreshRegistrationStats={refreshRegistrationStats}
//         />
//         <TagsCounter
//           onRefresh={handleOnRefreshTagsCounter}
//           refreshTagsCounter={refreshTagsCounter}
//         />
//       </Row>
//       <AddNewTag
//         isOpen={tagModel}
//         isEdit={isEdit}
//         closeModal={closeTagModal}
//         handleTag={handleAddNewTag}
//       />
//       <Row>
//         <Announcement
//           openAnnouncementModal={openAnnouncementModal}
//           closeAnnouncementModal={closeAnnouncementModal}
//           announcementList={announcementList}
//           onRefresh={handleOnRefreshAnnouncement}
//           refreshAnnouncement={refreshAnnouncement}
//           handleEditAnnouncement={(id) => handleEditAnnouncement(id)}
//           handleDeleteAnnouncement={(id) => confirmDeleteAnnouncement(id)}
//         />
//         <PromoCodes
//           openPromoCodeModal={openPromoCodeModal}
//           closePromoCodeModal={closePromoCodeModal}
//           promocodeList={promocodeList}
//           onRefresh={handleOnRefreshPromo}
//           refreshPromo={refreshPromo}
//           handleEditPromo={(id) => handleEditPromo(id)}
//           handleDeletePromo={(id) => confirmDeletePromo(id)}
//         />
//         {/* <CountOfRegistrations /> */}
//         <DetailsTag
//           openTagsModal={openTagModal}
//           tagList={allTags}
//           onRefresh={handleOnRefreshTag}
//           refreshTags={refreshTag}
//           handleEditTag={(id) => handleEditTag(id)}
//           handleDeletetag={(id) => confirmDeleteTag(id)}
//         />
//         <AdminResources
//           openResourcesModal={openResourcesModal}
//           closeResourcesModal={closeResourcesModal}
//           resource={resource}
//           onRefresh={handleOnRefreshResources}
//           refreshResources={refreshResources}
//           handleEditResources={(id) => handleEditResources(id)}
//           handleDeleteResources={(id) => confirmhandleDeleteResources(id)}
//         />
//       </Row>
//       <Row>
//         {/* form of adding promocode */}
//         {modal.promoCode && (
//           <AddPromoCode
//             promoCodeSubmit={promoCodeSubmit}
//             isPromoOpen={modal.promoCode}
//             closePromoModal={closePromoCodeModal}
//             isEdit={isEdit}
//           />
//         )}

//         {/* form of adding announcement */}
//         <AddAnnouncement
//           handleAnnouncement={handleAnnouncement}
//           isAnnouncementOpen={modal.announcement}
//           isEdit={isEdit}
//           closeAnnouncementModal={closeAnnouncementModal}
//         />
//         {/* Add Details of all api */}

//         {/* form of Adding Resources */}
//         <AddResources
//           isResourcesOpen={modal.resourcesModal}
//           isEdit={isEdit}
//           closeResourcesModal={closeResourcesModal}
//           handleResources={handleResources}
//         />
//       </Row>

//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Promo Code?"
//         colored=""
//         header={true}
//         isOpen={deletePromoModal}
//         closeModal={handleClose}
//         onYesClick={handleDeletePromo}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message={`Are you sure you want to delete ${currentId.name}?`}
//         colored=""
//         header={true}
//         isOpen={deleteTagModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteTag}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Announcement?"
//         colored=""
//         header={true}
//         isOpen={deleteAnnouncementModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteAnnouncement}
//       />
//       <ConfirmationModal
//         color="danger"
//         title="Delete Confirmation"
//         message="Are you sure you want to delete this Resource?"
//         colored=""
//         header={true}
//         isOpen={deleteResourcesModal}
//         closeModal={handleClose}
//         onYesClick={handleDeleteResources}
//       />

//     </Container>
//     </>
//   );
// };

// export default AdminHome;
