import React, { useState, useEffect } from "react";
import Table from "./components/Table";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "redux-form"
import {
  getSubscription,
  addSubscription,
  getSubscriptionById,
  deleteSubscription,
  subscriptionTransactions,
} from "../../redux/actions/subscriptionAction";
import AddSubscription from "./components/AddSubscription";
import PurchaseHistoryTable from "./components/PurchaseHistoryTable";
import ConfirmationModal from "../../shared/components/ConfirmationModal";
import api from "../../utils/apiClient";
import {SubscriptionName} from "config/subscrition"
import HelmetComponent from "shared/components/HelmetComponent"
import {seoData} from "shared/StaticData"

const AdminSubscription = () => {
  const dispatch = useDispatch();
  
  const list = useSelector((state) => state.subscription.subscriptionList);
  const { subTransaction } = useSelector(state => state.subscription)
  const [subModal, setSubModal] = useState(false);
  const [currentId, setCurrentId] = React.useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [refreshSubscription, setRefreshSubscription] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const handleClose = () => {
    setDeactivateModal(false);
  };
  const handleOnRefreshSubscription = () => {
    setRefreshSubscription(true);
    dispatch(getSubscription()).then(() =>
      setTimeout(() => setRefreshSubscription(false), 500)
    );
  };

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(subscriptionTransactions());
  }, []);

  const closeSubModal = () => {
    setIsEdit(false);
    setSubModal(false);
    // dispatch(setTagDetails({}))
    dispatch(reset("SubscriptionForm"))
    
  };
  const openSubModal = () => {
    setSubModal(true);
  };
 
  const addSubs = async (val) => {
    let data = {
      id: val.id,
      subscriptionName: val.subscriptionName,
      descriptions: val.descriptions,
      costPerMonth: parseFloat(val.costPerMonth),
      annually: parseFloat(val.annually),
      commisionPercentage: parseFloat(val.commisionPercentage),
      cents: parseFloat(val.cents),
    };
    dispatch(addSubscription(data))
    .then(() => dispatch(reset("SubscriptionForm")))
    .then(() => dispatch(getSubscription()))
    setSubModal(false);
  };
  const handleSubEdit = (id) => {
    dispatch(getSubscriptionById(id)).then(() => {
      setIsEdit(true);
      setSubModal(true);
    });
  };
  const handleSubDelete = (id) => {
    setRefreshSubscription(true);
    dispatch(deleteSubscription(id))
      .then(() => dispatch(getSubscription()))
      .then(() => setTimeout(() => setRefreshSubscription(false), 1000));
  };
  const handleDeactivate = () => {
    setDeactivateModal(false);
    let id = currentId;
    api.subscription.delete(id)
    .then(() => dispatch(getSubscription()))
  };
  const confirmDeactvate = (id) => {
    setCurrentId(id);
    setDeactivateModal(true);
  };
  return (
    <>
    <HelmetComponent seoData={seoData.subscription}/>
      <Table
        list={list}
        onRefresh={handleOnRefreshSubscription}
        refreshSubscription={refreshSubscription}
        openSubModal={openSubModal}
        handleEdit={(id) => handleSubEdit(id)}
        handleDelete={(id) => handleSubDelete(id)}
        confirmDeactvate={(id) =>confirmDeactvate(id)}
      />
      <AddSubscription
        isOpen={subModal}
        isEdit={isEdit}
        addSubs={addSubs}
        closeModal={closeSubModal}
      />
      <PurchaseHistoryTable 
      list = {subTransaction}
      onRefresh={handleOnRefreshSubscription}
      refreshSubscription={refreshSubscription}
      />
       <ConfirmationModal
        color="danger"
        title="Delete Confirmation"
        message="Are you sure you want to delete this Subscription?"
        colored=""
        header=""
        isOpen={deactivateModal}
        closeModal={handleClose}
        onYesClick={handleDeactivate}
      />
    </>
  );
};

export default AdminSubscription;
