import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, ButtonToolbar } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Image from "../../StudentDetalsPage/Component/Image";
import { useSelector } from "react-redux";
import { Button, Typography, Icon, Chip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import {
  loginModal,
  programRegiterModal,
  reviewModal,
  contactProviderModal,
} from "../../../redux/actions/modalAction";
import { enrolledProgramStudent } from "../../../redux/actions/enrollmentAction";
import { dateFormat, ArrayToString, stringToTime } from "../../../shared/helpers";
import {
  getProviderProfileId,
  addFavorite,
} from "../../../redux/actions/profileActions";
import {
  getSearchItem
} from "../../../redux/actions/UniversalSearch";
import { showSucessNoti } from "../../../redux/actions/notificationAction";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { authRoles } from "../../../config/authroles";
import { getProgramById } from "../../../redux/actions/programActions";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import { getMyReview } from "../../../redux/actions/reviewAction";
// import RegisterStudents from "./RegisterStudents"
// import ListingCard from "./ListingCard"

export default function Common({ programDetails, detailsLoading, type }) {
  const auth = useSelector((state) => state.user);
  const [showList, setShowList] = React.useState(false)
  const history = useHistory();
  const dispatch = useDispatch();
  const handleRegiterStudent = (id) => {
    if (auth.isAuthenticated) {
      if (id == 'NA' && programDetails.externalLink !== "") {
        window.open(programDetails.externalLink)
      } else {
        dispatch(programRegiterModal(true));
        dispatch(enrolledProgramStudent(id));
      }
    } else {
      dispatch(loginModal(true));
    }
  };
  const handleReview = () => {
    dispatch(getMyReview(programDetails.id)).then(() => {
      dispatch(reviewModal(true));
    });
  };
  const viewContact = () => {
    dispatch(getProviderProfileId(programDetails.createdBy));
    dispatch(contactProviderModal(true));
  };
  const handleShare = () => {
    navigator.clipboard.writeText(
      window.location.href
    );
    dispatch(showSucessNoti("Link copied"));
  };

  const addfavoriteProvider = (id) => {
    dispatch(addFavorite(id)).then(() => {
      dispatch(getProgramById(programDetails.id));
    });
  };
  const renderLocations = () => {
    let location = []
    if (type === "program") {
      programDetails.locations.map((loc) => {
        location.push(loc.name)
      })
      return location.join(", ")
    } else {
      return programDetails.location
    }
  }



  const bull = <span className="bullet">•</span>;
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12} id="html-2-pdfwrapper">
        <Card>
          <CardBody>
            <div className="mb-10">
              <h4 className="text-black mb-10 capitalize">
                gtesttttt sadasd
              </h4>

            </div>
            <div className="product-card" id="toPdf">

              <div className="product-gallery">


                {/* (<Skeleton animation="wave" variant="rect" width="100%" height={300} />) */}

                <img src={`${process.env.PUBLIC_URL}/img/details.jpg`} alt="program-img" />

                <div className="flex flex-mddle flex-start my-20">
                  {/* <Avatar src={`${process.env.PUBLIC_URL}/img/avatar/user1.jpg`} /> */}
                  <div className="mx-10 flex flex-middle">
                    <div className="mx-4">
                      <span>
                        fhgdfgsd
                      </span>
                      <div>
                        {/* <span>
                                    <Rating name="read-only" value={4} readOnly />
                                </span> */}
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>
            <div className="product-card__info">
              {/* <Profile></Profile> */}

              <ButtonToolbar>

                {/* {auth.isAuthenticated &&
                    //!programDetails.isEnrolled &&
                    programDetails.registrationFlag &&
                    auth.role === authRoles.Parents && //(programDetails.capacity > programDetails.registredStudents) &&  */}
                {/* <Button
                      variant="outlined"
                      onClick={() => handleRegiterStudent(programDetails.id)}
                      color="primary"
                    >
                      Register
                    </Button> */}

                <Button
                  variant="outlined"
                  // onClick={() => handleRegiterStudent('NA')}
                  color="primary"
                >
                  External Link
                </Button>

                <Button
                  variant="outlined"
                  //   onClick={() => setShowList(!showList)}
                  color="primary"
                >
                  View Listing
                </Button>

                <Button
                  variant="outlined"
                  //   onClick={() => window.open(programDetails.website)}
                  color="primary"
                >
                  Website
                </Button>
                <Button
                  //   onClick={handleShare}
                  variant="outlined"
                  color="primary"
                >
                  Share
                </Button>


                <Button
                  variant="outlined"
                  //   onClick={viewContact}
                  color="primary"
                >
                  Contact
                </Button>



                <Button
                  variant="outlined"
                  //   onClick={handleReview}
                  color="primary"
                >
                  Review
                </Button>



                <IconButton
                  variant="contained"
                  color="primary"
                // onClick={() => addfavoriteProvider(programDetails.createdBy)}
                >

                  <FavoriteIcon />

                  <FavoriteBorderIcon />

                </IconButton>

              </ButtonToolbar>
              <div className="mb-10 ">
                <Typography variant="h3">
                  <Skeleton variant="text" width="20%" />
                  {/* <span className="ml-8 font-size-13">per {programDetails.sessionsForCost > 1 ? programDetails.sessionsForCost : ""} session</span> */}
                </Typography>
              </div>
              <div className="mb-10 flex flex-middle">

                <Skeleton variant="text" width="20%" />


                <Icon>language</Icon>
                <h5 className=" bold-text mx-4">

                  City:
                  <span className="text-muted mx-4">
                    fdgsdgsdfsd
                    {/* ArrayToString(programDetails.locations).join(", ")} */}
                  </span>)
                  <span className="text-muted mx-4">ghfhf</span>
                </h5>




              </div>

              <div className="mb-10 flex flex-middle">

                <Skeleton variant="text" width="20%" />

                <LocationOnIcon />
                <h5 className=" bold-text mx-4">Location: <span className="text-muted mx-4">gdfgfgf </span> </h5>


              </div>



              <div className="mb-10 flex flex-middle">
                <Skeleton variant="text" width="20%" />

                <EventSeatIcon />
                <h5 className=" bold-text mx-4">Capacity: <span className="text-muted mx-4">fgdf</span></h5>

                <HourglassEmptyIcon />
                <h5 className=" bold-text mx-4">Total Sessions: <span className="text-muted mx-4">

                </span> </h5>


              </div>
              <div className="mb-10 flex flex-middle">

                <Icon>group_icon</Icon>
                <h5 className=" bold-text mx-4">Age Group:
                  <span className="text-muted mx-4">

                  </span> </h5>

              </div>

              <div className="mb-10 flex flex-middle">

                <Icon>category_icon</Icon>
                <h5 className=" bold-text mx-4">Category Tags:
                  <span className="text-muted mx-4">

                  </span> </h5>

              </div>

              <div className="flex flex-middle mb-4">
                <Icon>event_icon</Icon>
                <h5 className="bold-text mx-4">
                  Regular Dates :
                </h5>
              </div>

              <Skeleton variant="text" width="20%" height={10} />
              <Skeleton variant="text" width="20%" height={10} />
              <Skeleton variant="text" width="20%" height={10} />

              {/* {programDetails.regulerDates.map((dates, i) => ( */}
              <Typography key={"sd"} variant="body2" className="mx-20">

                {/* {dateFormat(dates, true)} */}
              </Typography>

            </div>

            <div className="mb-10 flex flex-middle">

              <Skeleton variant="text" width="20%" />

              <ScheduleIcon />
              <h5 className=" bold-text mx-4">Regular end time: <span className="text-muted mx-4"> </span> </h5>


            </div>

            <div className="mb-10">
              <div className="flex flex-middle mb-4">
                <Icon>event_icon</Icon>
                <h5 className="bold-text mx-4">
                  Special Dates :
                </h5>
              </div>



              <Skeleton variant="text" width="20%" height={10} />
              <Skeleton variant="text" width="20%" height={10} />
              <Skeleton variant="text" width="20%" height={10} />


              {/* programDetails && programDetails.specialDates.map((dates, i) => ( */}
              <Typography key={''} variant="body2" className="mx-20">
                {/* {dateFormat(dates)} */}efre
              </Typography>


            </div>

            <div className="mb-10 flex flex-middle">

              <Skeleton variant="text" width="20%" />

              <ScheduleIcon />
              <h5 className=" bold-text mx-4">Special end time: <span className="text-muted mx-4">fdgfdgdfgd </span> </h5>

            </div>

            <div className="mb-10">
              <div className="flex flex-middle mb-4">
                <Icon>event_icon</Icon>
                <h5 className="bold-text mx-4">
                  No Sessions :
                </h5>
              </div>

              <Typography variant="body2" className="mx-20">

                <Skeleton variant="text" width="20%" height={10} />



              </Typography>
            </div>
            <div className="mb-10">

              <div className="flex flex-middle mb-4">
                <Icon>event_icon</Icon>
                <h5 className="bold-text mx-4">
                  Days of the Week :
                </h5>
              </div>

              <Typography variant="body2" className="mx-20">

                <Skeleton variant="text" width="20%" height={10} />

                <Typography variant="body2" className="mx-20">

                </Typography>

              </Typography>
            </div>
            <div className="mb-10">
              <div className="flex flex-middle mb-4">
                <Icon>notes</Icon>
                <h5 className="bold-text mx-4">

                </h5>
              </div>


              <React.Fragment>
                <Skeleton animation="wave" height={10} className="mb-10" />
                <Skeleton animation="wave" height={10} width="80%" />
              </React.Fragment>
              <h5 className="mx-20" dangerouslySetInnerHTML={{ __html: <p>dsdsfasd</p> }}></h5>

            </div>


          </CardBody>
        </Card>
      </Col>

    </Row>
  );
}