import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { connect } from "react-redux"
import {loginModal} from "../../../../redux/actions/modalAction"

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  handleLogin = () => {
    this.props.loginModal(true).then(() => {
       this.hideSidebar()
     })
   }
  render() {
    const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="About"
            icon="text-align-justify"
            route="/about"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Support"
            icon="text-align-justify"
            route="/support"
            onClick={this.hideSidebar}
          />
          <div
            onClick={this.handleLogin}
          >
            <li className="sidebar__link">
            <span className={`sidebar__link-icon lnr lnr-lock`} /> 
              <p className="sidebar__link-title">
                Login
              </p>
            </li>
          </div>
        </ul>
      </div>
    );
  }
}

export default connect(null,{loginModal})(SidebarContent);
