import React from 'react';
import { Link, withRouter, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { TextField, Icon, InputAdornment, CircularProgress, Chip } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from "utils/apiClient"
import {
  setUiniversalExperianceData, setUiniversalProviderData, setUiniversalProgramData, setSearchText
} from 'redux/actions/UniversalSearch';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const TopbarSearch = ({ className = "" }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [searchValue, setSearchValue] = React.useState("")

  const getSearchItem = (value) => {
    if (value.length < 3) return;
    dispatch(setSearchText(value));
    api.tag.search({
      "searchText": value,
      "tags": [],
      "activityTags": [],
      "locationTags": [],
      "ageGroup": "",
      "dayOfWeek": "",
      "pageNo": 0,
      "pageSize": 0
    })
      .then((res) => {
        console.log("tagsss", res)
        let op = []
        let { item1, item2, item3 } = res.data
        // if (item1.length > 0) item1.map((i) => op.push({ id: i.id, name: i.programName, type: "program" }))
        // if (item2.length > 0) item2.map((i) => op.push({ id: i.id, name: i.name, type: "experience" }))
        // if (item3.length > 0) item3.map((i) => op.push({ id: i.id, name: `${i.fName} ${i.mName ? i.mName : ""} ${i.lName}`, type: "provider" }))
        if (op) setOptions(op)
        setAllListing(res.data)
        console.log("program name", res.data.item1[0].programName)
      })
      .catch(err => console.log(err))
  }
  const setAllListing = (data) => {
    let { item1, item2, item3 } = data
    if (item1 && item1.length > 0) {
      let op = []
      item1.map((l) => op.push({ ...l, uniType: "program" }))
      dispatch(setUiniversalProgramData(op))
    } else dispatch(setUiniversalProgramData([]))
    if (item2 && item2.length > 0) {
      let op = []
      item2.map((l) => op.push({ ...l, uniType: "experiance" }))
      dispatch(setUiniversalExperianceData(op))
    }
    else dispatch(setUiniversalExperianceData([]))

    if (item3 && item3.length > 0) {
      let op = []
      item3.map((l) => op.push({ ...l, uniType: "provider" }))
      dispatch(setUiniversalProviderData(op))
    } else dispatch(setUiniversalProviderData([]))
  }
  const handleQurySearch = () => {
    const urlParams = new URLSearchParams(location.search).get('result')
    if (urlParams) {
      setSearchValue({ name: urlParams })
      getSearchItem(urlParams)
    }
    else history.push("/")
  }

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);

    }
  }, [open]);
  React.useEffect(() => {
    if (location.pathname === "/search") {
      return handleQurySearch()
    }
  }, [])
  const handleMove = (option) => {
    if (option) {
      if (typeof (option) === "string") {
        setSearchValue({ name: option })
        handleSearchReload(option)
      }
      else if (typeof (option) === "object" && option.type) {
        setSearchValue(option)
        handleHotSearch(option)
      }
      else {
        handleSearchReload(option.name)
        setSearchValue({ name: option.name })
      }
    }
  }
  const handleHotSearch = (option) => {


    let myPromise = new Promise(function (myResolve, myReject) {
      history.push({
        pathname: `/`,
      })
      myResolve('ok'); // when successful
    });
    myPromise.then(
      function (value) {
        if (value === 'ok') history.push({
          pathname: `/detail/${option.id}`,
          search: `?type=${option.type}`,
        })
      }
    );
  }
  const handleSearchReload = (option) => {
    let to = {
      pathname: `/search`,
      search: `?result=${option}`,
    }
    history.push(to)
  }
  return (
    <Autocomplete
      open={open}
      className={className}
      value={searchValue}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `${params.inputValue}`,
          });
        }

        return filtered;
      }}
      onChange={(e, newValue) => handleMove(newValue)}
      onInputChange={(event, newValue) => getSearchItem(newValue)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option && option.name}
      options={options}
      loading={loading}
      loadingText={"Enter more than three character to get search results."}
      freeSolo
      renderOption={(option) => (
        <React.Fragment>
          <span>{option && option.type && (<Chip label={option.type} className="mr-4" />)}
            <span className="uppercase text-black ">{option.name}</span></span>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          variant="outlined"
          placeholder="Search Keyword"
          InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default withRouter(TopbarSearch);