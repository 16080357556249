import React from "react";
import ProgramForm from "./ProgramFrom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ModalAddProgram({ title, isOpen, closeModal, programSubmit }) {
  return (
    <>
      <Modal
        toggle={closeModal}
        className="theme-light ltr-support login-modal-form"
        isOpen={isOpen}
        centered
        size="lg" style={{maxWidth: '1600px', width: '100%', margin: '10px auto'}} 
      >
        <ModalHeader toggle={closeModal}>
          <h5>
            <strong>{title}</strong>
          </h5>
        </ModalHeader>
        <ModalBody>
          <ProgramForm programSubmit={programSubmit} closeModal={closeModal} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalAddProgram;
