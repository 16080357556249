import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import { setUserData, authError } from '../../../redux/actions/authActions';
import { loginModal, registerModal, providerInfoModal, userInfoModal } from "../../../redux/actions/modalAction"
import { showFailedNoti, showSucessNoti } from "../../../redux/actions/notificationAction"
import googleAuthSetting from '../../../config/google';
import { userService } from "../../../Services/userService"
import ConfirmationModal from "../../../shared/components/ConfirmationModal"
import { authRoles } from '../../../config/authroles';
class AuthGoogleBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      googleConfirm: false,
      tokenId: "",
      userDataLocal: {}
    }
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      action: PropTypes.string.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
  };


  responseSuccess = (response) => {
    let { form } = this.props
    this.setState({ tokenId: response.tokenId })
    switch (form) {
      case "modal_login":
        return this.handleGoogleSign()
      case "modal_register":
        this.setState({ googleConfirm: true })
      default:
        return null
    }
  }
  handleGoogleSign = () => {
    const { dispatch, history, roleCode } = this.props;
    let data = {
      token: this.state.tokenId,
      roleCode: roleCode
    }
    userService.authUserExternal(data).then(user => {
      if (!user.data) {
        dispatch(authError(user.message))
      } else {
        let data = {
          name: user.data.name,
          avatar: user.data.image,
          role: user.data.role,
        }
        dispatch(setUserData(data)).then(() => {
          dispatch(loginModal(false))
          // dispatch(authError({}));
          history.push("/home")
          dispatch(showSucessNoti(user.message))

        })
      }

    }).catch(err => console.error(err))
  }
  getRoleCode = (role) => {
    let code = 3;
    if (role === 3) {
      return code = 12
    } if (role === 4) {
      return code = 13
    }
  }

  handleGoogleRegister = () => {
    const { dispatch, history, roleCode } = this.props;
    let data = {
      token: this.state.tokenId,
      roleCode: this.getRoleCode(roleCode)
    }
    userService.authUserExternal(data).then(user => {
      if (!user.data) {
        dispatch(authError(user.message))
      } else {
        let data = {
          name: user.data.name,
          avatar: user.data.image,
          role: user.data.role,
        }
        // dispatch(setUserData(data)).then(() => {
        //   dispatch(showSucessNoti(user.message))
        // })
        this.props.setuserDataLocal(data)
        dispatch(registerModal(false))
        dispatch(authError(null));
        dispatch(showSucessNoti(user.message))
        if (user.data.role === authRoles.Provider) {

          dispatch(providerInfoModal(true))
          this.googleConfirmClose()
        } else if (user.data.role === authRoles.Parents) {
          dispatch(userInfoModal(true))
          this.googleConfirmClose()
        }
      }

    }).catch(err => console.error(err))
  }

  responseError = (response) => {
    const { dispatch } = this.props;
    dispatch(authError(response.error));
  }

  googleConfirmClose = () => {
    this.setState({ googleConfirm: false })
  }
  getTitle = () => {
    let { roleCode } = this.props
    switch (roleCode) {
      case authRoles.Parents:
        return `Are you sure you want to register as "Parent"?`
      case authRoles.Provider:
        return `Are you sure you want to register as "Provider"?`
      default:
        return ""
    }
  }

  render() {
    return (
      <>
        <GoogleLogin
          clientId={googleAuthSetting.client_id}

          onSuccess={this.responseSuccess}
          onFailure={this.responseError}
          cookiePolicy="single_host_origin"
        />
        {this.state.googleConfirm && (<ConfirmationModal
          color=""
          title={this.getTitle()}
          message=""
          colored={false}
          header={false}
          isOpen={this.state.googleConfirm}
          closeModal={this.googleConfirmClose}
          onYesClick={this.handleGoogleRegister}
        />)}
      </>

    );
  }
}

export default withRouter(connect()(AuthGoogleBtn));
