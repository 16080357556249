import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import { Box, Chip } from '@material-ui/core';
import ActivityTag from "./ActivityTag"
import LocationTag from "./LocationTag"
import CategoryTag from "./CategoryTag"
import { ageGroup, daysofWeek } from "shared/StaticData"
import MinimumAgeTag from "./MinimumAgeTag"
import DaysTag from "./DaysTag"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
function TabPanel(props) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}



export default function TabsButton({ searchTagId, selectedDays, selectedAge, handleSelectedDays, handleSelectedAge }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // to-do---selection tag 
  const [selectedTag, setSeletedTag] = React.useState({})
  const handleTagSelect = (data) => {
    setSeletedTag(data)
    searchTagId(data.id)
  }
  return (
    <div >
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab style={{ minWidth: "100px" }} label="Location"  {...a11yProps(0)} />
          <Tab style={{ minWidth: "100px" }} label="Activity" {...a11yProps(1)} />
          {/* <Tab style={{ minWidth: "100px" }} label="Category"  {...a11yProps(2)} /> */}
          {/* <Tab style={{ minWidth: "100px" }} label="Age Group"  {...a11yProps(3)} /> */}
          {/* <Tab style={{ minWidth: "100px" }} label="Days"  {...a11yProps(3)} /> */}
        </Tabs>
      </>
      <TabPanel value={value} index={0}>
        <LocationTag handleTagSelect={(tag) => handleTagSelect(tag)} selectedTag={selectedTag} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ActivityTag handleTagSelect={(tag) => handleTagSelect(tag)} selectedTag={selectedTag} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <CategoryTag handleTagSelect={(tag) => handleTagSelect(tag)} selectedTag={selectedTag} />

      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <MinimumAgeTag handleTagSelect={(tag) => handleTagSelect(tag)} selectedTag={selectedTag} />
        </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <DaysTag />
      </TabPanel> */}
    </div>
  );
}
