import React from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import {useDispatch} from "react-redux"
import ForgetPasswordForm from '../../../../shared/components/ChangePassword/ForgotPasswordForm'
import {forgotPassword} from "../../../../redux/actions/authActions"
const ModalForgetPassword = ({
  title,
  isOpen,
  closeModal,
}) => {
  const dispatch = useDispatch()
  const handleForgot = async(values) => {
     await dispatch(forgotPassword(values.email))
  }
  return (<Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
    <ModalHeader toggle={closeModal}><strong>{title}</strong></ModalHeader>
    <ModalBody>
      <ForgetPasswordForm
        handleForgot={handleForgot}
      />
    </ModalBody>
  </Modal>)
}
export default ModalForgetPassword;