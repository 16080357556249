import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import { Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { connect } from "react-redux"
import { showSucessNoti } from "../../../redux/actions/notificationAction"
import { getCartDetails } from "../../../redux/actions/cartAction"
import {paymentDetailsModal } from "../../../redux/actions/modalAction"
import SimpleBackDrop from "../../../shared/components/SimpleBackDrop/BackDrop"
import { ADD_SUBSCRIPTION, DELETE_SUBSCRIPTION, GET_SUBSCRIPTION, GET_SUBSCRIPTION_BY_ID,SET_SUBS_TYPE,
  SUBSCRIPTION_TRANSACTION } from "../../../redux/types";
import {addTransactionDetails} from "../../../redux/actions/transactionActions"
import {getProviderProfile} from "../../../redux/actions/profileActions"
import api from "../../../utils/apiClient"

const CLIENT_ID ="AS3jpU2EXtxVa3fKA_CuPmbdNmbvX2sSJhape5karkEVIniX-2JJBEMz2jGdlioYzd1NejuDZwu4Qhae"



let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      paymentStart: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createSubscription = (data, actions, ) => {
    let { totalvalue,currencyType="CAD",payPalPlanId } = this.props
    return actions.subscription.create({
      'plan_id': payPalPlanId
    })
  };
  // need to change in future

 
  onApprove = async (data, actions) => {
    
    this.setState({ paymentStart: true })
    

    

        let {payPalPlanId, subscriptionId, subsType,details } = this.props
      

    let body = {
            subscriptionId: subscriptionId,
            invoiceNumber:parseInt(data.orderID),
            subscriptionType: parseInt(subsType),
            paymentSource: 1,
            paypalSubscription:data.subscriptionID,
            transactionDetails: JSON.stringify(details)
        }
    // let {payPalPlanId, subscriptionId } = this.props
    // data.subscriptionId = subscriptionId
    // data.paypalSubscription=data.subscriptionID
    this.props.saveTractionDetails(body)

    // actions.order.capture().then(details => {
    //   const paymentData = {
    //     payerID: data.payerID,
    //     orderID: data.orderID
    //   };
    //   this.props.saveTractionDetails(data, details)
    // });
  };


  render() {
    const { showButtons, loading, paid, paymentStart } = this.state;

    return (
      <div className="main">

        {showButtons ? (
          <div>

            <PayPalButton
              createSubscription={(data, actions) => this.createSubscription(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
              
            />
          </div>
        ) : (
            <>
              <Typography variant="h1">
                <Skeleton />
              </Typography>
              <Typography variant="h1">
                <Skeleton />
              </Typography>
            </>
          )}
        {paymentStart && (
          <SimpleBackDrop open={paymentStart} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartProduct: state.cart.cartProduct,
  totalCartCost: state.cart.totalCartCost,
  grandTotalCart: state.cart.grandTotalCart,
  payPalPlanId: state.subscription.subsDetails.payPalPlanId,
  subscriptionId: state.subscription.subsDetails.id,
  subsType: state.subscription.subsType,
  transactionDetails:state.subscription.transactionDetails
  
})

export default
  scriptLoader(`https://www.paypal.com/sdk/js?currency=CAD&client-id=${CLIENT_ID}&components=buttons&vault=true&intent=subscription`)
    (connect(mapStateToProps, { showSucessNoti, getCartDetails, paymentDetailsModal,addTransactionDetails })
      (PaypalButton));