import React from 'react'
import { Card, CardBody, Col } from 'reactstrap';
import Collapse from '../../../../shared/components/Collapse';

export default function TermsAndCondition() {
    return (

        <>
            <Collapse className="with-shadow" title="Public Accounts (Parents and Students):">
                <p>
                    Parents and students, who use our online platform service to find and register for children and
                    youth programs in their community, will receive lifetime free access to the DWUL platform. See
                    the privacy policies on how we collect and use your information to provide accurate registration
                    to program providers and schools associated with that program.

          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Program Disclaimer:">
                <p>
                    Programs and activities listed on the DWUL Online Platform are majority provided by local
                    program providers, independent businesses, non-profits, charities, grassroots, and community
                    groups who use the Online Platform for program promotions and registration purposes. Except
                    for school-partnership programs offered directly by the Do What U Luv Foundation, and will be
                    indicated in the Program Provider details

          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Collection of Payments:">
                <p>
                    The DWUL Platform administers all online payments made by credit cards, Interac debit, and
                    AMEX through qualified online payment vendors. We use both Stripe Payment and PayPal Pro,
                    APIs and secured integrations, which are both in compliance with the federal and provincial
                    laws regulating online payment collections. Stripe Payment and PayPal Pro will only collect and
                    store encrypted information to facilitate the payment, and are subject to Stripe and PayPal's
                    terms of services and privacy policies. DWUL does not store any payment information.


          </p>
            </Collapse>
            <Collapse className="with-shadow" title="Program Provider Accounts:">
                <p>
                    The DWUL online platform provides online registration and promotional services to educational
                    program providers, local businesses, non-profits, and charities, who use it to list their programs
                    and accept registrations from families and students. See our Program Provider Services
                    Package for detailed terms and services for businesses, non-profits, charities, monthly account
                    subscription fees, and registration transaction fees.


          </p>
            </Collapse>
            <Collapse className="with-shadow" title="School Partners Accounts:">
                <p>
                    We offer services to school partners free of cost, who use the DWUL platform as a central place
                    to host all the out-of-school programs and activities in their school for the convenience of the
                    families attending that school. See our School Partnership and Community Engagement
                    guidelines for what services Schools will receive.

          </p>
            </Collapse>
        </>
    )
}
