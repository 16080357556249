import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { getUserList } from "../../../../redux/actions/userAction"
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  InputBox: {
      minWidth: "fit-content"
  }
}));

export default function FilterSelect() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [roleCode, setRoleCode] = React.useState(0)
  const handleChange = (event) => {
    setRoleCode(event.target.value)
    dispatch(getUserList(event.target.value !== 0 ? event.target.value : ""))
  };
  return (<>
  <div className="flex flex-middle">
  <div className="pr-10">
      User Type:
  </div>

      <FormControl variant="standard" className={classes.InputBox}>
        <Select
          value={roleCode}
          onChange={handleChange}
          name="roleCode"
        >
          <MenuItem value={0}>All</MenuItem>
          {/* <MenuItem value={1}>Super Admin</MenuItem> */}
          <MenuItem value={2}>Admin</MenuItem>
          <MenuItem value={3}>Provider</MenuItem>
          <MenuItem value={4}>Parents</MenuItem>
        </Select>
      </FormControl>
      </div>
      </>
  );
}