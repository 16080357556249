import React from 'react'
import {useSelector} from 'react-redux'
import {Route, Redirect, withRouter} from 'react-router-dom'

const ProtectedRoute=({component:Component,role,...rest})=>{
    const auth = useSelector(state => state.user)
  return(
    <Route
         {...rest}
      render={props => {
        return auth.isAuthenticated
        ?
        <Component {...props} />
        : <Redirect to='/' />
      }}
    />
  )

}

export default withRouter(ProtectedRoute)