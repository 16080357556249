import api from '../../utils/apiClient';
import {showFailedNoti,showSucessNoti,showWarningNoti} from "./notificationAction"
import { GET_USER_LIST, GET_USER_COUNT,GET_ORAGANISATIONS,SET_USER_ROLE_CODE } from "../types"
import {authRoles} from "config/authroles"
export const getUserList = (data) => async(dispatch) => {
    await api.users.getUserList(data, true).then(res => {
        if (res.data) {
            let user = res.data.filter((user) => (user.dwulRoles !== authRoles.SuperAdmin && !user.isDelete))
            dispatch(setUserList(user))
        }
    }).catch(err => console.log(err))
}
export const setUserList = (list) => dispatch => {
    dispatch({
        type: GET_USER_LIST,
        payload: list
    })
}
export const getUserCount = () => async(dispatch) => {
    for (let i = 2; i <= 4; i++) {
        await api.users.getUserList(i, true).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_USER_COUNT,
                    payload: { name: i === 2 ? "admin" : i === 3 ? "provider" : i === 4 ? "parent" : "unknown", count: res.data.length }
                })
            }
        }).catch(err => console.log(err))
    }
}
export const getOrganisations = () => async(dispatch) => {
    await api.users.getOrganisations().then(res => {
        // debugger
        if (res.data) {
            dispatch({
                type: GET_ORAGANISATIONS,
                payload: res.data
            })
        }
    }).catch(err => console.log(err))
}


export const activateUser=(id)=>async dispatch=>{
    await api.profile.activate(id).then(res =>{
        if(res.data){
            dispatch(showSucessNoti(res.data.message))
        } else {
            dispatch(showWarningNoti(res.data.message))
        }
    })
    .catch(err => {
        dispatch(showFailedNoti(err.message))
    })
}
export const deactivateUser=(id)=>async dispatch=>{
    await api.profile.deactivate(id).then(res =>{
        if(res.data.data){
            dispatch(showSucessNoti(res.data.message))
        } else {
            dispatch(showWarningNoti(res.data.message))
        }
    })
    .catch(err => {
        dispatch(showFailedNoti(err.message))
    })
}

export const deleteSpecificUser = (id) => async dispatch => {
    await api.users.deleteUser(id).then((res) => {
        if (res.data.data) {
            dispatch(showSucessNoti(res.data.message))
        } else {
            dispatch(showWarningNoti(res.data.message))
        }
    }).catch(err=>dispatch(showFailedNoti(err.message)))
}
//USED FOR SIGNUP

export const setUserRoleCode = (role) => async dispatch => {

    dispatch({
        type: SET_USER_ROLE_CODE,
        payload:role
    })
}