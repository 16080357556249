import axios from "axios"
import { ALL_EXPERIANCE, ALL_EXPERIANCE_LOADING, SEND_EXPERIENCE, GET_PROVIDER_EXPERIENCE, GET_PROVIDER_EXP_BY_ID } from "../types"
import api from '../../utils/apiClient';

export const allExperiance = ({ searchText = "", ageGroup = "", tags = [], pageSize = 10, pageNo = 1 })  => async (dispatch) => {

    const config = {
        method: "post",
        url: window.apiUrl + "Experience/All-Experience",
        data: JSON.stringify({ searchText, ageGroup, tags,pageSize,pageNo }),
        headers: {
            'Content-Type': 'application/json',
        }
    }
    

    dispatch(setAllExperieceLoading(true));

    await axios(config).then(res => {
        if (res.data) {
            dispatch({
                type: ALL_EXPERIANCE,
                payload: res.data
            });
            dispatch(setAllExperieceLoading(false));
        }
    }).then(err => {
        dispatch(setAllExperieceLoading(false));
    }).catch(err => {
        dispatch(setAllExperieceLoading(false));
    })
}

export const sendExperience = (expData) => async (dispatch) => {
    await api.experience.post(expData, true)
        .then(res => {
            if (res.data) {
                dispatch({
                    type: SEND_EXPERIENCE,
                    payload: res.data
                })
            }
        })
        .catch(err => console.log(err))

}

export const providerExperience = () => async (dispatch) => {
    await api.experience.getProviderExperience(true).then(res => {
        if (res.data) {
            dispatch({
                type: GET_PROVIDER_EXPERIENCE,
                payload: res.data
            })
        }
    }).catch(res => {

    })
}
export const getproviderExpById = (id) => async (dispatch) => {
    await api.experience.getProviderById(id, true).then(res => {
        // debugger
        if (res.data) {
            dispatch(setExperienceById(res.data))
        }

    }).catch(err => {

    })
}

export const setExperienceById = (data) => async (dispatch) => {
    dispatch({
        type: GET_PROVIDER_EXP_BY_ID,
        payload: data
    })
}

const setAllExperieceLoading = (data) => async (dispatch) => {
    dispatch({
        type: ALL_EXPERIANCE_LOADING,
        payload: data
    })
}