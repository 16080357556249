import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../prop-types/ReducerProps';
const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const MatTableHead = ({
  onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, onRequestSort,deleteMode,rows
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
         #
      </TableCell>
      {rows.map((row,i) => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-right"
          key={i}
          align={rtl.direction === 'rtl' ? 'right' : 'left'}
          padding="default"
        >
            {row.label}
        </TableCell>
      ), this)}
    </TableRow>
  </TableHead>
);

// MatTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.string.isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
//   rtl: RTLProps.isRequired,
// };

export default connect(state => ({
  rtl: state.rtl,
}))(MatTableHead);
