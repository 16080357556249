import { APPLY_PROMO, DISCOUNT_AMOUNT, PROMO_CODE_LIST, PROMO_CODE_DETAILS } from "../types"
import api from "../../utils/apiClient"
import { showSucessNoti, showWarningNoti, } from "../../redux/actions/notificationAction"
export const getPromoCodes = () => async dispatch => {
    api.promoCode.getPromoCode()
        .then(res => {
            if (res) {
                dispatch({
                    type: PROMO_CODE_LIST,
                    payload: res.data
                })
            }
        }).catch(err => console.log(err))
}
export const getPromoCodeById = (id) => async dispatch => {
    api.promoCode.getPromoCodeById(id)
        .then(res => {
            if (res) {
                // debugger
                dispatch(setPromoCodeById(res.data))
            }
        }).catch(err => console.log(err))
}
export const setPromoCodeById = (data) => dispatch => {
    dispatch({
        type: PROMO_CODE_DETAILS,
        payload: data
    })
}
export const managePromoCode = (data) => async dispatch => {
    api.promoCode.managePromoCode(data)
        .then(res => {
            if (res) {
                dispatch(showSucessNoti(res.data.message))
            }
        }).catch(err => console.log(err))
}