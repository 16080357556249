import React,{useEffect} from "react";
import { Col,  } from "reactstrap";
import AddUser from "./components/AddUser/AddUser";
import AddUserModal from "./components/AddUser/AddUserModal";
import { addUserModal } from "../../redux/actions/modalAction";
import {useSelector,useDispatch} from "react-redux";
import { addUser } from "../../redux/actions/profileActions";
import { getSubscription } from "../../redux/actions/subscriptionAction";
import AddUserTable from "./components/AddUser/AddUserTable";


export default function SuperAdmin() {

  const [refreshSubscription, setRefreshSubscription] = React.useState(false)
  
    const modal = useSelector(state => state.modal)
    const dispatch = useDispatch();

    

    const handleOnRefreshSubscription = () => {
      setRefreshSubscription(true)
      dispatch(getSubscription())
      .then(() => setTimeout(() => setRefreshSubscription(false), 500))
    }

   

    const closeAddUser = () => {
        dispatch(addUserModal(false))
     }
     const addNewUser=async(val)=>{
        let data ={
          fName: val.fName,
          mName: val.mName,
          lName: val.lName,
          email: val.email,
          password: val.password,
          roleCode: parseInt(val.roleCode),
        }
        dispatch(addUser(data))
        dispatch(addUserModal(false))
     }

     const openSubModal = () => {
      dispatch(addUserModal(true))
    }
  return (
    <>
      <Col>
        <h3 className="page-title">Add User</h3>
      </Col>
      <AddUserTable
      openSubModal={openSubModal}
      />
      <AddUserModal
        title="Add User"
        isOpen={modal.addUserModal}
        closeModal={closeAddUser}
        addNewUser={addNewUser}
      />
    </>
  );
}
