import api from "../../utils/apiClient"
import { showFailedNoti, showSucessNoti, showWarningNoti } from "./notificationAction"
import { CART_ODER, DISCOUNT_AMOUNT, TOTAL_COST, GRAND_TOTAL, CART_LOADING } from "../types"


export const addOder = (programcart = {}, experiancercart = {}) => async (dispatch) => {
    let cart = {
        programOrders: programcart,
        experienceOrders: experiancercart
    }
    await api.cart.addToCart(cart).then((res) => {
        if (res.data.data) {
            dispatch(showSucessNoti(res.data.message))
            dispatch(getCartDetails())
        } else {
            dispatch(showWarningNoti(res.data.message))
        }
    }).catch((err) => {
        dispatch(showFailedNoti(err.message))
    })
}
export const getCartDetails = () => async (dispatch) => {
    dispatch(cartLoading(true))
    await api.cart.cartDetails().then(res => {
        if (res.data) {
            let cartAmount = res.data.cartAmount
            let discountAmount = res.data.discountAmount
            let grandTotal = res.data.totalAmount
            dispatch(addcartToCart(res.data))
            dispatch(totalDiscountAmount(discountAmount))
            dispatch(totalCartCost(cartAmount))
            dispatch(totalGrandAmount(grandTotal))
        }
    }).catch((err) => {
        console.error(err.message)
    })
}

export const addcartToCart = (product) => async (dispatch) => {
    dispatch({
        type: CART_ODER,
        payload: product,
    })
}

export const totalCartCost = (totalCost) => async (dispatch) => {
    dispatch({
        type: TOTAL_COST,
        payload: totalCost
    })
}

export const totalDiscountAmount = (discount) => async dispatch => {
    dispatch({
        type: DISCOUNT_AMOUNT,
        payload: discount
    })
}
export const totalGrandAmount = (grandTotal) => async dispatch => {
    dispatch({
        type: GRAND_TOTAL,
        payload: grandTotal
    })
}

export const cartLoading = (loading) => async dispatch => {
    dispatch({
        type: CART_LOADING,
        payload: loading
    })
}


export const applyPromoCodeCart = (code) => async dispatch => {
    api.cart.promoCodeCart(code).then(res => {
        if (res) {
            dispatch(getCartDetails())
            dispatch(showSucessNoti(res.data.message))
        }
    }).catch(err => console.log(err))
}

export const removeFromCart = (cartId, oderId) => async dispatch => {
    api.cart.removeCart(cartId, oderId).then((res) => {
        if (res.data) {
            dispatch(getCartDetails())
            dispatch(showSucessNoti(res.data.message))
        }
    }).catch((err) => {
        
    })
}