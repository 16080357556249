import React, { useEffect } from "react";
import {useSelector,useDispatch} from "react-redux"
import { Container, Col ,Row} from "reactstrap";
import ParentHistory from "./components/ParentHistory";
import Cancellation from "../Components/Cancellation"
import MessageList from "./components/MessageList"
import {refundListParent} from "redux/actions/transactionActions"
import {getMessageParent} from "redux/actions/messageActions"
const columns = [
  {
    id: 1, label: 'Order Id',
  },
  {
    id: 2, label: 'Program Name',
  },
  {
    id: 3, label: 'Payment Source',
  },
  {
    id: 5, label: 'Date of Request',
  },
  {
    id: 7, label: 'Student Name',
  },
  {
    id: 8, label: 'Payment Status',
  },
  {
    id: 9, label: 'Amount (CA$)',
  },
  {
    id: 10, label: 'Refunded',
  },
  
]
export default function ParentActivities() {
  const refund = useSelector((state) => state.transaction.refundList)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(refundListParent())
    dispatch(getMessageParent())
  },[])
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="card__title">
              <h3 className="bold-text">Activities</h3>
            </div>
          </Col>
        </Row>
        <Col>
          <ParentHistory />
          <Cancellation
              refund={refund}
              columns={columns}
            />
            <MessageList />
        </Col>
      </Container>
    </>
  );
}
