import { post, get } from './base/index';


export default {
    addAnnouncement: (data) => {
        return post(`${window.apiUrl}Announcement/add-announcement`, data, true)
    },
    getAnnouncement: () => {
        return get(`${window.apiUrl}Announcement/get-announcements`, {}, true)
    },
    getAnnouncementDetails: (id) => {
        return get(`${window.apiUrl}Announcement/get-announcement?id=${id}`, {}, true)
    },
    deleteAnnouncement: (id) => {
        return post(`${window.apiUrl}Announcement/delete-announcement?id=${id}`, {}, true)
    },
    getAnnouncementByUser: () => {
        return get(`${window.apiUrl}Announcement/users-announcements?Page_size=10000&Page_no=1`, {}, true)
    }
}