import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileMain from "./components/ProfileMain";
import ChangePassModal from "../../../../../shared/components/ChangePassword/ChangePassModal";
import { changePasswordModal } from "../../../../../redux/actions/modalAction";
import {
  changePassword,
  getAdmin,
} from "../../../../../redux/actions/profileActions";
import { showSucessNoti, showFailedNoti } from "redux/actions/notificationAction"
import { Col, Container, Row } from 'reactstrap';
import ProfileTabs from "./components/ProfileTabs";
import api from "../../../../../utils/apiClient"
import { userService } from "../../../../../Services/userService";
import { setUserData } from "../../../../../redux/actions/authActions";
import { authRoles } from "../../../../../config/authroles";
import { Messages } from "../../../../../config/notificationType";

const AdminProfile = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const [isEdit, setIsEdit] = useState(true);
  const { adminProfile } = useSelector((state) => state.profile);
  const [changePassLoading, setChangePassLoading] = useState(false);
  const { userMedia } = useSelector((state) => state.media);
  const closeChangePassModal = () => {
    dispatch(changePasswordModal(false));
  };
  const handleChangePassModal = () => {
    dispatch(changePasswordModal(true));
  };
  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };
  const confirmChangePass = async (val) => {
    setChangePassLoading(true);
    let data = {
      oldPassword: val.oldPassword,
      newPassword: val.newPassword,
    };
    dispatch(changePassword(data));
    dispatch(changePasswordModal(false));
    setChangePassLoading(false);
  };
  useEffect(() => {
    dispatch(getAdmin());
  }, []);
  const updateAdmin = async (val) => {

    let data = {
      avatar: userMedia ? userMedia : val.avatar,
      fName: val.fName,
      mName: val.mName,
      lName: val.lName,
      // email: val.email
    }
    let userData = {
      name: val.fName + val.mName + val.lName,
      avatar: userMedia ? userMedia : val.avatar,
      role: authRoles.Admin,

    };

    await api.profile.updateAdmin(data).then(res => {
      if (res.data.data) {
        setIsEdit(true)
        // console.log("updateAdm", )
        userService.setUserLocal(userData);
        dispatch(setUserData(userData));
        dispatch(showSucessNoti(res.data.message));
        dispatch(getAdmin())
      } else {
        dispatch(showFailedNoti(res.data.message));
      }
    }).catch((err) => {
      dispatch(showFailedNoti(Messages.GeneralError));
    })
  }
  return (
    <>
      <Container>

        <Row className="mt-0">
          <Col xl={4} lg={4} md={6} sm={12} xs={12}>
            <Row className="mt-0 mx-0">
              <ProfileMain
                handleChangePassModal={handleChangePassModal}
                admin={adminProfile}
              />
            </Row>
          </Col>
          <Col l={8} lg={8} md={6} sm={12} xs={12}>
            <ProfileTabs updateAdmin={updateAdmin} isEdit={isEdit} toggleEdit={toggleEdit} />
          </Col>
        </Row>
      </Container>
      <ChangePassModal
        title="Change Password"
        isOpen={modal.changePasswordModal}
        closeModal={closeChangePassModal}
        confirmChangePass={confirmChangePass}
        onSubmit={confirmChangePass}
        changePassLoading={changePassLoading}
      />
    </>
  );
};

export default AdminProfile;
