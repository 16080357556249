import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Picklist, PicklistOption, DatePicker } from 'react-rainbow-components';
// import { LocalizationProvider,DatePicker } from "@mui/lab";
import { parseISO } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { newOrderTableReducer } from 'redux/reducers';
// import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css'

export const renderField = ({
  input,
  name,
  label,
  type,
  startIcon,
  showPasswordToggle,
  value,
  rows,
  multiline,
  toggleBtn = false,
  inputProps,
  min = '',
  max = '',
  meta: { touched, error, warning },
  ...custom
}) => {
  //const [selectedDate, setSelectedDate]=useSate(null)
  // const [startDate, setStartDate] = useState(new Date());
  console.log(input.regularStartTime ? moment(input.value) : '');
  console.log('type -->>>>>', type);
  console.log('input props --->>>>', inputProps);
  const getUTCDate = (date = "") => {
    if (date == "") {
        return ""
    } else {
       
      return moment(date).tz('Canada/Central').format('YYYY-MM-DD');
      


    }

}
  // debugger
  const passwordInput = () => (
    <TextField
      fullWidth
      variant='outlined'
      size='small'
      helperText={touched && error}
      error={touched && error}
      label={label}
      autoComplete
      placeholder={label}
      type={type}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon>{startIcon}</Icon>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='start'>
            {type == 'text' ? (
              <Icon onClick={showPasswordToggle}>visibility</Icon>
            ) : (
              <Icon onClick={showPasswordToggle}>visibility_off</Icon>
            )}
          </InputAdornment>
        ),
      }}
      {...input}
      {...custom}
    />
  );
  const normalInput = () => (
    <TextField
      fullWidth
      variant='outlined'
      size='small'
      label={label}
      helperText={touched && error}
      error={touched && error}
      placeholder={label}
      type={type}
      multiline={multiline}
      rows={rows}
      inputProps={{
        min: 0,
        max: max,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon>{startIcon}</Icon>
          </InputAdornment>
        ),
      }}
      {...input}
      {...custom}
    />
  );

  const dateTimeInput = () => (
    // <InputMask
    //     className="form-control"
    //     mask="9999/99/99 99:99"

    //     {...input}
    //     onChange={input.onChange}
    //     {...custom}
    // >
    //     {(inputProps) =>
    // <DatePicker
    //  selected={input.value}
    //  onChange={input.onChange}
    //   />

    <DatePicker
      className='datessss'
      // value={input.value}
      type={type.dateInput}
      value={parseISO(input.value)}
      onChange={input.onChange}
      // onChange={(e, newValue) => {
      //     input.onChange(newValue)
      // }}
      // label="DatePicker Label"

      format='medium'
      // locale={state.locale.name}
      inputProps={{
        style: { width: 420 },
      }}
      // placeholder="yyyy/mm/dd"
    />

    //  <TextField
    //     fullWidth
    //     variant="outlined"
    //     size="small"
    //     label={`${label} - (yyyy/mm/dd hh:mm)`}
    //     placeholder={label}
    //     helperText={touched && error}
    //     error={touched && error}
    //     multiline={multiline}
    //     {...inputProps}

    // />
    //     }
    // </InputMask>
  );
  const dateInput = () => {
    console.log('date input value >>>>>>>>>>>>>>>>>>>>> ',getUTCDate( input.value));

    return (
      <input
        type='date'
        value={input.value ?getUTCDate( input.value) : null}
        onChange={input.onChange}
        style={{
          width: '100%',
          margin: '9px 9px',
          padding: '6px',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
        }}
      ></input>
      // <DatePicker
      //     className='datessss rainbow-p-vertical_large rainbow-p-horizontal_xx-large rainbow-m-horizontal_xx-large'
      //     selected={input.value}
      //     onChange={input.onChange}
      //     placeholder="dd/mm/yyyy"
      //     dateFormat='dd/MM/yyyy'
      //     // minDate={new Date()}
      //     isClearable
      //     showYearDropdown
      //     scrollableMonthYearDropdown
      // />

      // <InputMask
      //     className="form-control"
      //     mask="9999/99/99"
      //     onChange={input.onChange}
      //     onChange={input.onChange}
      //     {...input}>

      //     {(inputProps) =>

      // <div className="rainbow-p-vertical_large rainbow-p-horizontal_xx-large rainbow-m-horizontal_xx-large">
      //     <DatePicker
      //         value={input.value}

      //         // onChange={input.onChange}
      //         onChange={(e, newValue) => {
      //             input.onChange(newValue)
      //         }}
      //         // label="DatePicker Label"
      //         formatStyle="medium"
      //         // locale={state.locale.name}
      //         bottomHelpText={touched && error}
      //         error={touched && error}
      //         inputProps={{
      //             style: { width: 420 }
      //         }}
      //         placeholder="yyyy/mm/dd"

      //         {...inputProps}

      //         required={true}
      //     />
      // </div>

      // <TextField
      //--------------------------commented-------------------
      // <LocalizationProvider dateAdapter={AdapterDateFns}>
      // <DatePicker
      //   fullWidth
      //   //variant="outlined"
      //   size="medium"
      //   // label={`${label} - (yyyy/mm/dd)`}

      //     label={"mm/dd/yyyy"}
      //     // placeholder={"mm/dd/yyyy"}
      //   helperText={touched && error}
      //   // error={touched && error}
      //   // multiline={multiline}
      //   // type='date'
      //     // renderInput={(params) => <TextField {...params} className="input-without-border-radius"
      //     // />}

      //   type={type.dateInput}
      // value={input.value ? input.value : null}
      //   onChange={input.onChange}
      //   // {...inputProps}
      //   />

      // </LocalizationProvider>
      //---------------------commented----------------------
      //     }

      // <DatePicker
      //   className="input-without-border-radius"
      //   onChange={input.onChange}
      //   placeholderText={"mm/dd/yyyy"}

      //   value={input.value ? input.value : null}

      //   />

      // </InputMask>
    );
  };

  const timeInput = () => {
    return (
      <TimePicker
        label={label}
        showSecond={false}
        onChange={input.onChange}
        // onChange = {(value)=>{value &&
        //         moment(value).format('HH:mm')
        //     // value.format('HH:MM')
        // }}
        inputReadOnly
        placeholder='--:--'
        // value={new Date(input.value)}

        value={input.value ? input.value : null}
        // format='HH:mm'
        // defaultValue={moment()}
        use12Hours
        // inputProps={{
        //     min: min,
        //     max: max
        // }}
        inputIcon={startIcon}
        fullWidth
        variant='outlined'
        helperText={touched && error}
        error={touched && error}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon>{startIcon}</Icon>
            </InputAdornment>
          ),
        }}
      />
    );
  };
  const renderInputUi = () => {
    if (toggleBtn) {
      return passwordInput();
    } else if (type === 'datetime-local') {
      return dateTimeInput();
    } else if (type === 'date') {
      return dateInput();
    } else if (type === 'time') {
      return timeInput();
    } else return normalInput();
  };

  return renderInputUi();
};
