import React, { useState, useRef, useCallback } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Rating from "../../../../../../shared/components/Rating";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { Avatar } from "@material-ui/core";
import NoRecord from "../../../../../../shared/components/NoRecord";
const Ava = `${process.env.PUBLIC_URL}/img/avatar/avatar.png`;

const ProfileMyReviews = () => {
  const { reviewList, reviewLoading } = useSelector((state) => state.review);
  return (
    <>
      {reviewLoading ? (
        <div>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton variant="rect" width="100%" animation="wave" height={100} />
        </div>
      ) : reviewList.length === 0 ? (
        <NoRecord />
      ) : (
        <div >
          {reviewList.length > 0 &&
            reviewList.map((data, i) => {
              return (
                <Col className="px-0" md={12} xl={12} lg={12}>
                  <Card className="p-0 mb-10">
                    <CardBody 
                      className="dashboard__booking-card w-100 bg-light-grey"
                    >
                      <div>
                        <h4 className="bold-text mb-1">{data.programName}</h4>
                        <Row>
                          <Avatar
                            src={data.parentImage ? data.parentImage : Ava}
                            className="ml-3"
                          />
                          <h4 className="bold-text pl-2 pt-2 pb-3">
                            {data.parentName}
                          </h4>
                        </Row>
                        {/* <Rating value={data.rating} /> */}
                        <h4 className="bold-text pt-1">{data.subject}</h4>
                        <h5 className="text-muted">{data.fullMassage}</h5>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </div>
      )}
    </>
  );
};
export default ProfileMyReviews;
