import React, { useState } from 'react';
import { withRouter,useHistory } from "react-router-dom"
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux"
import CartCard from './Components/CartCard';
import ApplyPromo from "./Components/ApplyPromo"
import { applyPromoCodeModal,paymentDetailsModal } from "../../redux/actions/modalAction"
import { getCartDetails, applyPromoCodeCart, removeFromCart } from "../../redux/actions/cartAction"
import ConfirmationModal from "../../shared/components/ConfirmationModal"
import PaymentModal from "./Components/PaymentModal"
import { addTransactionDetails } from "../../redux/actions/transactionActions"

const Cart = () => {
    const history = useHistory()
    const { applyPromo,paymentDetails } = useSelector(state => state.modal)
    const [removeItemId, setRemoveItemId] = React.useState({
        oderId: 0,
        cartId: 0,
        itemName: ""
    })
    const [removeConfirm, setRemoveConfirm] = React.useState(false)
    const dispatch = useDispatch()
    const { 
        grandTotalCart = 0, } = useSelector(state => state.cart)

    const handlePromoCode = () => {
        dispatch(applyPromoCodeModal(true))
    }
    const handleDialogClose = () => {
        if (removeConfirm) setRemoveConfirm(false)
        if (applyPromo) dispatch(applyPromoCodeModal(false))
        if(paymentDetails) dispatch(paymentDetailsModal(false))
    }
    const submitPromoCode = (value) => {

        dispatch(applyPromoCodeCart(value.code)).then(() => {
            dispatch(getCartDetails()).then(() => {
                handleDialogClose()
            })

        })
    }

    React.useEffect(() => {
        dispatch(getCartDetails())
    }, [])

    const handleRemoveCart = (cartId, oderId, itemName) => {
        setRemoveItemId({
            cartId: cartId,
            oderId: oderId,
            itemName: itemName
        })
        setRemoveConfirm(true)
    }
    const handleDeleteConfirm = () => {
        dispatch(removeFromCart(removeItemId.cartId, removeItemId.oderId))
        handleDialogClose()
    }
    const handlePaymentModal = () => {
        if (grandTotalCart === 0) {
            saveTractionDetails()
        }else{
          dispatch(paymentDetailsModal(true))
        }
        
    }
    const saveTractionDetails = async () => {
        function guidGenerator() {
            var S4 = function() {
               return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
            };
            return ("DWULT-"+S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
        }
        let data = {
           id: 0,
          paymentSource: 5,
          transactionDetails:"",
          applicationFee: 0,
          currency: "CAD",
          transactionId: guidGenerator()
        }
        await dispatch(addTransactionDetails(data)).then(() => {
           
          dispatch(getCartDetails()).then(() => {
             history.push("/home")
            dispatch( paymentDetailsModal(false))
            })
        })
      }
    return (
        <Container>
            <Row>
                <CartCard
                    handlePromoCode={handlePromoCode}
                    handleRemoveCart={(cartId, oderId, itemName) => handleRemoveCart(cartId, oderId, itemName)}
                    handlePaymentModal={handlePaymentModal}
                />
                 
            </Row>

            {applyPromo && (
                <ApplyPromo
                    title="Apply Promo"
                    isOpen={applyPromo}
                    closeModal={handleDialogClose}
                    submitPromoCode={submitPromoCode}
                />
            )}

            {removeConfirm && (
                <ConfirmationModal
                    title={`Are you sure want to delete?`}
                    message={`${removeItemId.itemName} will be deleted from your cart.`}
                    header={true}
                    color="danger"
                    colored={false}
                    isOpen={removeConfirm}
                    closeModal={handleDialogClose}
                    onYesClick={handleDeleteConfirm}
                />
            )}

            { paymentDetails &&
                (<PaymentModal
                open={paymentDetails} 
                handleClose={handleDialogClose}
                title="Select payment method"
            />)
            }
        </Container>
    );
}

export default withRouter(Cart);
