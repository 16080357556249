import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../../shared/components/form/RenderField";
import { Col, Row } from "reactstrap";
import { Button } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import AutoDropDown from "../../../../shared/components/form/AutoDropDown"
import { authRoles } from '../../../../config/authroles';
import { validate } from "../../../../config/formValidation";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddUserForm = ({
  handleSubmit,
  closeModal,
  submitting,
  valid,
  addNewUser,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const [roleCode, setRoleCode] = React.useState(authRoles.Parents)

  const handleChange = (event) => {
    setRoleCode(event.target.value);
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit(addNewUser)}>
      <Col md={12} xs={12} lg={12}>
        <Field
          name="fName"
          type="text"
          label="First Name*"
          startIcon="people"
          component={renderField}
        />
      </Col>
      <br />
      <Col md={12} xs={12} lg={12}>
        <Field
          name="mName"
          type="text"
          label="Middle Name"
          startIcon="people"
          component={renderField}
        />
      </Col>
      <br />
      <Col md={12} xs={12} lg={12}>
        <Field
          name="lName"
          type="text"
          label="Last Name*"
          startIcon="people"
          component={renderField}
        />
      </Col>
      <br />
      <Col md={12} xs={12} lg={12}>
        <Field
          name="email"
          type="text"
          label="Email*"
          startIcon="email"
          component={renderField}
        />
      </Col>
      <br />

      <Col md={12} xs={12} lg={12}>
        <Field
          name="password"
          type="text"
          label="Password*"
          startIcon="lockIcon"
          component={renderField}
          lassName="input-without-border-radius"
          type={showPassword ? "text" : "password"}
          showPasswordToggle={showPasswordToggle}
        />
      </Col>
      <br />
      <Col md={12} xs={12} lg={12}>
       {/* <FormControl className={classes.formControl}  >
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roleCode}
                  onChange={handleChange}
                >
                     
                  <MenuItem value={authRoles.Admin}>Admin</MenuItem>
                  <MenuItem value={authRoles.Provider}>Provider</MenuItem>
                  <MenuItem value={authRoles.Parents}>Parents</MenuItem>
                </Select>
              </FormControl>
               
                         <Field
                            name="role"
                            label="Role"
                            component={AutoDropDown}
                            type="text"
                            // defaultValue={initialValues.organisationName}
                            placeholder="Role"
                            multiple={false}

                            startIcon='people'
                            options={authRoles.Admin,authRoles.Provider,authRoles.Parents}
                        />                                                           */}
                    
        <label>Role:</label>
        <br />
        <Field
          name="roleCode"
          type="text"
          label="Role Code"
          startIcon="accessibilityIcon"
          component="select"
        >
          <option></option>

          <option value={authRoles.Admin}>Admin</option>
          <option value={authRoles.Provider}>Provider</option>
          <option value={authRoles.Parents}>Parents</option>
        </Field>
      </Col>
    
      <Col>
        <Row className="row">
          <Col className="mt-3">
            <Button
              className="account__btn"
              color="secondary"
              disableElevation
              variant="outlined"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Col>
          <Col className="mt-3">
            <Button
              disabled={!valid || submitting}
              className="account__btn"
              type="submit"
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "AddUser",
  validate,
})(AddUserForm);
