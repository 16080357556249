import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StudentModel from "./StudentModal";
import { useSelector } from "react-redux";
import { TableRow, TableCell } from "@material-ui/core";
import SearchableTable from "../../../shared/components/SearchableTable";
import { Card, CardBody } from "reactstrap"

const columns = [
  {
    id: 1, label: "Name",
  },
  {
    id: 2, label: "Parent Name",
  },
  {
    id: 3, label: "Age (Years)",
  },
  {
    id: 4, label: "Gender",
  },
  {
    id: 5, label: "Email",
  },

  {
    id: 6, label: "Phone",
  },
  {
    id: 7, label: "Registered Program(s)",
  },
  {
    id: 8, label: " Action",
  }
];

const StudentTable = ({ StudentEnrolled, loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");

  const StudentList = useSelector(state => state.student.studentEnrolledList)
  const tableData = StudentList.map((item) => ({ ...item, fullName: `${item.fName} ${item.mName ? item.mName : ""} ${item.lName}` }))

  const clearSearch = () => {
    setSearchValue("");
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };

  const handleFavSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };


  let filteredData = tableData

  if (searchValue) {
    filteredData = tableData.filter((search) => search.fullName.toLowerCase().match((searchValue.toLowerCase())))
  }

  return (
    < div >
      <Card>
        <CardBody>
          <SearchableTable
            title=""
            rows={columns}
            searchValue={searchValue}
            filteredData={filteredData}
            clearSearch={clearSearch}
            loading={loading}
            actionTablebtn={false}
            searchLabel="Search Student"
            searchPlaceholder="By name"
            rowsPerPage={rowsPerPage}
            page={page}
            getSearchResult={handleFavSearch}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d, i) => {
                return (
                  <TableRow
                    className="material-table__row"
                    role="checkbox"
                    tabIndex={-1}
                    key={d.id}
                    id="divToPrint"
                  >
                    <TableCell className="material-table__cell" padding="checkbox">
                      {(i + 1) + (page * rowsPerPage)}
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right text-nowrap"
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      <span className="pl-8">{d.fullName}</span>
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.parentName}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.age}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.gender}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                      {d.email}
                    </TableCell>

                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.phone}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.programName.length > 0 && d.programName.map((data, index) =>
                        <div className="ml-3">
                          <li key={index} className="text-muted pb-3 ProgramName">
                            {data}
                          </li>
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="material-table__cell">
                      <StudentModel Student={{ ...d, studentName: d.fullName }} />
                    </TableCell>

                  </TableRow>
                );
              })}
          </SearchableTable>
        </CardBody>
      </Card>

    </ div>
  );
};

StudentTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(StudentTable);
