// // import React, { Component } from 'react';
// // import PropTypes from 'prop-types';
// // import { withRouter } from 'react-router';
// // import { connect } from 'react-redux';
// // import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// // import FacebookIcon from 'mdi-react/FacebookIcon';
// // import { auth, authError } from '../../../redux/actions/authActions';
// // import facebookAuthSettings from '../../../config/facebook';

// // class AuthFacebookeBtn extends Component {
// //   static propTypes = {
// //     dispatch: PropTypes.func.isRequired,
// //     history: PropTypes.shape({
// //       action: PropTypes.string.isRequired,
// //       push: PropTypes.func.isRequired,
// //     }).isRequired,
// //   };

// //   responseFacebook = (response) => {
// //     const { dispatch, history } = this.props;
// //     if (response.status) {
// //       dispatch(authError('Some error'));
// //     } else {
// //       dispatch(auth({ name: response.name, avatar: response.picture.data.url }));
// //       localStorage.setItem('easydev', response.accessToken);
// //       history.push('/dashboard_default');
// //     }
// //   }

// //   render() {
// //     return (

// //       <FacebookLogin
// //         appId="416517876614367"
// //         fields="name,email,picture"
// //         autoLoad={true}
// //         render={renderProps => (
// //           <button
// //             type="button"
// //             className="account__social-btn account__social-btn--facebook"
// //             onClick={renderProps.onClick}
// //           >
// //             <FacebookIcon />
// //           </button>
// //         )}

// //         callback={this.responseFacebook}
// //       />
// //     );
// //   }
// // }

// // export default withRouter(connect()(AuthFacebookeBtn));






// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import { auth, authError } from '../../../redux/actions/authActions';
// import PropTypes from 'prop-types';
// import { withRouter } from 'react-router';

// const AuthFacebookeBtn = () => {
//   const propTypes = {
//     dispatch: PropTypes.func.isRequired,
//     history: PropTypes.shape({
//       action: PropTypes.string.isRequired,
//       push: PropTypes.func.isRequired,
//     }).isRequired,
//   };

//   const getFBStatus = (response) => {
//     window.FB.getLoginStatus(function (fbResp) {
//       if (fbResp.status == 'connected') {
//         // hit api with fb Token
//         var token = fbResp.authResponse.accessToken
//         
//       }
//       if (fbResp.status == 'unknown') {
//         alert('failed');
//       }
//     });
//   }

//   return (
//     <>
//       {/* <button
//       type="button"
//       className="account__social-btn account__social-btn--facebook"
//       onClick={getFBStatus}
//     >
//     </button>
//      */}
//       <FacebookLogin
//         appId="416517876614367"
//         fields="name,email,picture"
//         autoLoad={true}
//         render={renderProps => (
//           <button
//             type="button"
//             className="account__social-btn account__social-btn--facebook"
//             onClick={renderProps.onClick}
//             callback={getFBStatus}
//           >
//             <FacebookIcon /> <span className="text-white">Login with Facebook</span>
//           </button>
//         )}

//       // callback={this.responseFacebook}
//       />
//     </>
//   );
// }

// export default withRouter(connect()(AuthFacebookeBtn));

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { withRouter } from 'react-router';
// import { connect } from 'react-redux';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import { auth, authError } from '../../../redux/actions/authActions';
// import facebookAuthSettings from '../../../config/facebook';

// class AuthFacebookeBtn extends Component {
//   static propTypes = {
//     dispatch: PropTypes.func.isRequired,
//     history: PropTypes.shape({
//       action: PropTypes.string.isRequired,
//       push: PropTypes.func.isRequired,
//     }).isRequired,
//   };

//   responseFacebook = (response) => {
//     const { dispatch, history } = this.props;
//     if (response.status) {
//       dispatch(authError('Some error'));
//     } else {
//       dispatch(auth({ name: response.name, avatar: response.picture.data.url }));
//       localStorage.setItem('easydev', response.accessToken);
//       history.push('/dashboard_default');
//     }
//   }

//   render() {
//     return (

//       <FacebookLogin
//         appId="416517876614367"
//         fields="name,email,picture"
//         autoLoad={true}
//         render={renderProps => (
//           <button
//             type="button"
//             className="account__social-btn account__social-btn--facebook"
//             onClick={renderProps.onClick}
//           >
//             <FacebookIcon />
//           </button>
//         )}

//         callback={this.responseFacebook}
//       />
//     );
//   }
// }

// export default withRouter(connect()(AuthFacebookeBtn));






import React, { Component, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from 'mdi-react/FacebookIcon';
import { auth, authError, setUserData } from '../../../redux/actions/authActions';
import { withRouter } from 'react-router';
import { showFailedNoti, showSucessNoti } from "../../../redux/actions/notificationAction"
import axios from 'axios'
import { userService } from "../../../Services/userService"
import { facebookModal, loginModal, providerInfoModal, registerModal, userInfoModal } from 'redux/actions/modalAction';
import { useCallback } from 'react';
import { authRoles } from 'config/authroles';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { getByTitle } from '@testing-library/react';
import { SelectionState } from 'draft-js';

const AuthFacebookeBtn = (props) => {
  const history = useHistory()
  let dispatch = useDispatch()
  const [token, setToken] = useState('');
  const [facebookConfirm, setFacebookConfirm] = useState('');

  const successFacebook = (response) => {
    let { form } = props
    setToken(response.accessToken)
    console.log(response)
    switch (form) {
      case "modal_login":
        return handleSign(response.accessToken)
      case "modal_register":
        setFacebookConfirm(true)
      default:
        return null
    }
  }

  function failFacebook(resp) {
    console.error(resp)
  }

  const handleSign = (token) => {
    console.log("tokenReponse", token)
    const { roleCode } = props;
    let data = {
      token: token,
      email: "",
      roleCode: roleCode
    }
    userService.registerfacebook(data).then(user => {
      if (!user.data) {
        dispatch(authError(user.message))
      } else {
        let data = {
          name: user.data.name,
          avatar: user.data.image,
          role: user.data.role,
        }
        dispatch(setUserData(data)).then(() => {
          dispatch(loginModal(false))
          // dispatch(authError({}));
          history.push("/home")
          dispatch(showSucessNoti(user.message))

        })
      }

    }).catch(err => console.error(err))
  }

  const registerHandler = () => {
    let { roleCode } = props
    var params = {
      token: token,
      email: "",
      roleCode: getRoleCode(roleCode)
    }
    userService.registerfacebook(params).then(user => {
      if (!user.data) {
        dispatch(authError(user.message))
      } else {
        let data = {
          name: user.data.name,
          avatar: user.data.image,
          role: user.data.role,
        }
        props.setuserDataLocal(data)
        dispatch(registerModal(false))
        dispatch(authError(null));
        dispatch(showSucessNoti(user.message))
        if (user.data.role === authRoles.Provider) {
          dispatch(providerInfoModal(true))
          setFacebookConfirm(false)
        } else if (user.data.role === authRoles.Parents) {
          dispatch(userInfoModal(true))
          setFacebookConfirm(false)
        }
      }
    })
  }

  const getRoleCode = (role) => {
    let code = 3;
    if (role === 3) {
      return code = 12
    } if (role === 4) {
      return code = 13
    }
  }

  const getTitle = () => {
    let { roleCode } = props
    switch (roleCode) {
      case authRoles.Parents:
        return `Are you sure you want to register as "Parent"?`
      case authRoles.Provider:
        return `Are you sure you want to register as "Provider"?`
      default:
        return ""
    }
  }

  return (
    <>
      <FacebookLogin
        appId="416517876614367"
        fields="name,email,access_token"
        autoLoad
        callback={successFacebook}
        onFailure={failFacebook}
        icon={<FacebookIcon />}
        cssClass="account__social-btn account__social-btn--facebook"
      />
      {facebookConfirm && (<ConfirmationModal
        color=""
        title={getTitle()}
        message=""
        colored={false}
        header={false}
        isOpen={facebookConfirm}
        closeModal={() => setFacebookConfirm(false)}
        onYesClick={registerHandler}
      />)}


    </>
  );
}

export default withRouter(connect()(AuthFacebookeBtn));


