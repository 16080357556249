
import {
  SET_FAV_PROV_ID,
  GET_PARENT_FAV,
  } from '../types';
  const initialState = {
    parentfavProvider: [],
    favPovLoading:true
  };
  
const favoriteReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case SET_FAV_PROV_ID:
        return {
          ...state,
          parentfavProviderById: action.payload,
        }
      case GET_PARENT_FAV:
        return {
          ...state,
          parentfavProvider: action.payload,
          favPovLoading:false
        }
      default:
        return state;
    }
  };
  
  export default favoriteReducer;
  