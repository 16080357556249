import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../../shared/components/form/RenderField";
import { Col, Row } from "reactstrap";
import { validate } from "../../../../config/formValidation";
import { Button, Divider } from "@material-ui/core";
const SendMessage = ({
  handleSubmit,
  closeModal,
  submitting,
  valid,
  sendMessages
}) => {
  return (
    <>
      <form onSubmit={handleSubmit(sendMessages)}>
        <>
          <Col md={12} xs={12} lg={12}>
            <div className="form__form-group">
              <Field
                name="fullMassage"
                type="text"
                label="Message"
                placeholder="Your Message"
                startIcon="description"
                component={renderField}
                multiline={true}
                rows={4}
              />
            </div>
          </Col>

        </>
        <Divider />
        <div className="my-10 flex flex-end flex-middle">
          <div className="mx-10">
            <Button
              className="account__btn"
              color="secondary"
              disableElevation
              variant="outlined"
              onClick={closeModal}
            >
              Cancel
              </Button>
          </div>
          <div>
            <Button
              className="account__btn"
              type="submit"
              color="primary"
              variant="contained"
              disabled={!valid || submitting}
            >
              Submit
              </Button>
          </div>

        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "SendMessage",
  validate,
})(SendMessage);
