import React, { useEffect, useState } from 'react'
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Button, Divider, TextField } from "@material-ui/core"
import { Row, Col } from 'reactstrap';
import renderFileInputField from "../../../shared/components/form/FileInput"
import { renderField } from "../../../shared/components/form/RenderField"
import { mediaTypes } from "../../../config/mediaTypes"
import ImageUpload from "../../../shared/components/form/ImageUpload"
import ActivityTag from "../../../shared/components/form/ActivityTag"
import renderFieldArray from "../../../shared/components/form/renderFieldArray"
import { useSelector } from "react-redux"
import { validate } from './ProgramFormValidation'
//import { validate } from "../../../config/formValidation"
// import { getProviderProgramById, providerProgram, setProviderProgramById } from "../../redux/actions/programActions"
import { getProviderProgramById, providerProgram, setProviderProgramById } from "../../../redux/actions/programActions"

import { tagTypes } from '../../../config/tagTypes';
import RenderToggle from "../../../shared/components/form/RenderToggle"
import TextEditor from "shared/components/text-editor/TextEditor";
// import moment from 'moment';
import moment from 'moment-timezone';


const ProgramForm = ({
    handleSubmit,
    submitting,
    valid,
    programImage,
    programSubmit,
    registrationFlag,
    programMedia,
    initialValues,
    flyer,
    regularCount,
    specialCount,
    closeModal,

}) => {
    const dispatch = useDispatch()



    return (
        <form className="login-form" onSubmit={handleSubmit(programSubmit)} >
            <Row>
                <Col>
                    <div className="form__form-group-field float-left ">
                        <Field
                            name="registrationFlag"
                            component={RenderToggle}
                            label="Enable Registration"
                        />
                        {console.log("enablebutton", registrationFlag)}
                    </div>
                </Col>
                <Col md={10} lg={10} sm={10}>

                    <div className="form__form-group">
                        <Field
                            name="programName"
                            label="Name*"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'
                        />
                    </div>




                    {/* <span class="lnr lnr-home"></span> */}




                    <div className="form__form-group">
                        {/* <p className="text-left">
                            <label>Location of the program</label></p> */}
                        <Field
                            name="placeName"

                            label="Location program of the program"
                            component={renderField}
                            type="text"
                            placeholder="Place Name"
                            startIcon='location_on'
                        />
                    </div>
                    <Row>
                        <Col md={7} lg={7} sm={8}>
                            <div className="form__form-group">
                                <Field
                                    name="streetAddress"
                                    // label="Location*"
                                    component={renderField}
                                    type="text"
                                    placeholder="Street Address"
                                    startIcon='location_on'
                                />
                            </div>
                        </Col>
                        <Col md={5} lg={5} sm={8}>
                            <div className="form__form-group">
                                <Field
                                    name="room"
                                    // label="Location*"
                                    component={renderField}
                                    type="text"
                                    placeholder="Room"
                                    startIcon='location_on'
                                />
                            </div>
                        </Col>
                    </Row>










                    <div className="form__form-group">
                        <Field

                            name="programLocation"
                            label="Notes about location*"
                            component={renderField}
                            type="text"
                            placeholder="Notes about location"
                            startIcon='location_on'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="locations"
                            label="City Tags"
                            component={ActivityTag}
                            type='text'
                            placeholder="City tags"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            defaultValue={{ id: initialValues.locationId, name: initialValues.locationName }}
                            tagType={[tagTypes.Location, tagTypes.School]}


                        />
                    </div>
                </Col>
                <Col md={2} lg={2} sm={2} className="pl-0">
                    <ImageUpload
                        name="programPhoto"
                        accept="image/*"
                        image={programMedia ? programMedia : initialValues.programPhoto}
                        mediatype={mediaTypes.Program}
                    />
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="activity"
                            label="Activity tags"
                            component={ActivityTag}
                            type='text'
                            placeholder="Activity tags"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            tagType={[tagTypes.Activity]}
                        />
                    </div>

                    <div className="form__form-group mb-0">
                        <Field
                            name="categoryTags"
                            label="Category tags"
                            component={ActivityTag}
                            type='text'
                            placeholder="Category tags"
                            className="input-without-border-radius"
                            startIcon='category'
                            tagType={[tagTypes.Category]}
                        />
                    </div>

                    <div className="form__form-group mb-0 regular-date-name">
                        <FieldArray
                            name="regulerDates"
                            label="Regular Dates"
                            component={renderFieldArray}
                            type="date"
                            className="input-without-border-radius"
                            startIcon="event_calendar"
                            placeholder="yyyy/mm/dd"
                            min={new Date().toISOString().substr(0, 16)}
                        />


                        <hr />
                        <Row>
                            <Col md={6} sm={12} xs={12} lg={6}>
                                <div className="form__form-group mb-0 label-end-time">
                                    <label>Regular start time*</label>
                                    <Field
                                        name="regularStartTime"
                                        label="Regular start time"
                                        component={renderField}
                                        type="time"
                                        placeholder="Regular start time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}

                                    />
                                </div>
                            </Col>
                            <Col md={6} sm={12} xs={12} lg={6}>
                                <div className="form__form-group mb-0 label-end-time">
                                    <label>Regular end time*</label>
                                    <Field
                                        name="regularEndTime"
                                        label="Regular end time"
                                        component={renderField}
                                        type="time"
                                        placeholder="Regular end time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <FieldArray
                            name="specialDates"
                            label="Special Dates"
                            component={renderFieldArray}
                            type="date"
                            className="input-without-border-radius"
                            startIcon="event_calender"
                            placeholder="yyyy/mm/dd"


                        />
                        <hr />
                        <Row>
                            <Col md={6} sm={12} xs={12} lg={6}>
                                <div className="form__form-group mb-0 label-end-time">
                                    <label>Special start time</label>
                                    <Field
                                        name="specialStartTime"
                                        label="Special start time"
                                        component={renderField}
                                        type="time"
                                        placeholder="Special start time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={6} sm={12} xs={12} lg={6}>
                                <div className="form__form-group mb-0 label-end-time">
                                    <label>Special end time</label>
                                    <Field
                                        name="specialEndTime"
                                        label="Special end time"
                                        component={renderField}
                                        type="time"
                                        placeholder="Special end time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form__form-group regular-date-name">
                        <FieldArray
                            name="noSession"
                            label="No session"
                            component={renderFieldArray}
                            type='date'
                            placeholder="yyyy/mm/dd"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                        />
                    </div>


                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <TextField
                        label="Number of sessions."
                        id="outlined-margin-dense"
                        className="input-without-border-radius mb-20"
                        value={regularCount.length + specialCount.length}


                        disabled
                        size="small"
                        helperText="(Regular dates + special dates)"
                        variant="outlined"
                        fullWidth
                    />
                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group">

                        <Field
                            name="capacity"
                            label="Capacity*"
                            component={renderField}
                            min="0"
                            type='number'
                            className="input-without-border-radius"
                            startIcon='group'
                        />
                    </div>

                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group">
                        <Field
                            name="cost"
                            label="Cost (CA$)*"
                            step="0.01"
                            component={renderField}
                            type='number'
                            placeholder="Cost"
                            className="input-without-border-radius"
                            step="0.01"
                            startIcon='attach_money'
                        />
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group">
                        <Field
                            name="sessionsForCost"
                            label="Per Registration*"
                            component={renderField}
                            type='number'
                            className="input-without-border-radius"
                            startIcon='hourglass_empty'
                            disabled={true}
                        />
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group">
                        {!registrationFlag && (<Field
                            name="externalLink"
                            label="External Link"
                            component={renderField}
                            type="url"
                            placeholder="Include http:// or https://"
                            className="input-without-border-radius"
                            startIcon='link'
                        />)}

                    </div>



                </Col>
                <Col md={!registrationFlag ? 6 : 12} sm={12} xs={12} lg={!registrationFlag ? 6 : 12}>
                    <div className="form__form-group">
                        <Field
                            name="flyer"
                            component={renderFileInputField}
                            mediatype={mediaTypes.Program}
                            myValue={flyer ? flyer : initialValues.flyer}
                        />
                    </div>

                </Col>

                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group mb-0 label-end-time label-two-end">
                        <Field
                            name="minimumAge"
                            label="Minimum age"
                            component={renderField}
                            type="number"
                            placeholder="Minimum Age"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12} lg={6}>
                    <div className="form__form-group mb-0 label-end-time label-two-end">
                        <Field
                            name="maximumAge"
                            label="Maximum age"
                            component={renderField}
                            type="number"
                            placeholder="Maximum Age"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                    </div>
                </Col>

                <Col md={12} lg={12} sm={12}>
                    {/* <div className="form__form-group">
                        <Field
                            name="ageGroup"
                            label="Age Group*"
                            component={ActivityTag}
                            type='text'
                            placeholder="Age Group"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            tagType={[tagTypes.AgeGroup]}
                        />
                    </div> */}

                    <div className="form__form-group">

                        <Field
                            name="description"
                            label="Description*"
                            component={TextEditor}
                            type='text'
                            placeholder="Description"
                            className="input-without-border-radius"
                            startIcon='note'
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </Col>
            </Row>
            <Divider />
            <div className="my-10 flex flex-end flex-middle ">
                <div >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!valid || submitting}

                    >Submit
                    </Button>
                </div>
                <div className="px-10">
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={closeModal}
                    >Close
                    </Button>
                </div>


            </div>

        </form>
    );
};
const selector = formValueSelector('programForm')

const getUTCDate = (date = []) => {
    if (date == "") {
        return ""
    } else {
        return date.map((d) => {
            return moment(d).tz('Canada/Central').format('YYYY-MM-DDTHH:mm:ss')
        })


    }

}

export default connect(state => {
    const hasregulerDates = selector(state, 'regulerDates')
    const hasspecialDates = selector(state, 'specialDates')
    const registrationFlag = selector(state, 'registrationFlag')
    const regularStartTime = selector(state, 'regularStartTime')
    const regularEndTime = selector(state, 'regularEndTime')
    const specialStartTime = selector(state, 'specialStartTime')
    const specialEndTime = selector(state, 'specialEndTime')

    console.log("previous date: ", [moment(state.program.programById.regulerDates).tz('Canada/Central').format('YYYY-MM-DDTHH:mm:ss')])
    console.log(state.program.programById.specialDates, 'state.program.programById.specialDates')
    // 
    return ({

        errorMsg: state.user.error,
        programMedia: state.media.programMedia,
        flyer: state.media.programPdf,
        regularCount: hasregulerDates ? hasregulerDates.filter(n => n) : [],
        specialCount: hasspecialDates ? hasspecialDates.filter(n => n) : [],
        registrationFlag: registrationFlag,
        initialValues: {
            programName: state.program.programById.programName ? state.program.programById.programName : "",
            programLocation: state.program.programById.programLocation ? state.program.programById.programLocation : "",
            categoryTags: state.program.programById.categoryTags ? state.program.programById.categoryTags : [],
            specialEndTime: state.program.programById.specialEndTime ? state.program.programById.specialEndTime : null,
            specialStartTime: state.program.programById.specialStartTime ? state.program.programById.specialStartTime : null,
            regularEndTime: state.program.programById.regularEndTime ? state.program.programById.regularEndTime : null,
            regularStartTime: state.program.programById.regularStartTime ? state.program.programById.regularStartTime : null,
            locations: state.program.programById.locations ? state.program.programById.locations : [],
            programPhoto: state.program.programById.programPhoto ? state.program.programById.programPhoto : "",
            locationName: state.program.programById.locationName ? state.program.programById.locationName : "",
            flyer: state.program.programById.flyer ? state.program.programById.flyer : "",
            activity: state.program.programById.activity ? state.program.programById.activity : [],
            ageGroup: state.program.programById.ageGroup ? state.program.programById.ageGroup : [],
            capacity: state.program.programById.capacity ? state.program.programById.capacity : 0,
            cost: state.program.programById.cost ? state.program.programById.cost : 0,
            description: state.program.programById.description ? state.program.programById.description : "",
            id: state.program.programById.id ? state.program.programById.id : 0,
            regulerDates: state.program.programById.regulerDates ? getUTCDate(state.program.programById.regulerDates) : [''],
            noSession: state.program.programById.noSession ? getUTCDate(state.program.programById.noSession) : [''],
            specialDates: state.program.programById.specialDates ? getUTCDate(state.program.programById.specialDates) : [''],
            sessionsForCost: state.program.programById.sessionsForCost ? state.program.programById.sessionsForCost : 1,
            registrationFlag: state.program.programById.registrationFlag ? state.program.programById.registrationFlag : false,
            externalLink: state.program.programById.externalLink ? state.program.programById.externalLink : "",

            placeName: state.program.programById.placeName ? state.program.programById.placeName : '',
            streetAddress: state.program.programById.streetAddress ? state.program.programById.streetAddress : '',
            room: state.program.programById.room ? state.program.programById.room : '',
            minimumAge: state.program.programById.minimumAge ? state.program.programById.minimumAge : '',
            maximumAge: state.program.programById.maximumAge ? state.program.programById.maximumAge : ''
        }
    })
}
)(reduxForm({
    form: 'programForm',
    enableReinitialize: true,
    validate
})(ProgramForm));