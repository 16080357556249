import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody, Col, } from "reactstrap";
import { Avatar, TableRow, TableCell, Icon, Button } from "@material-ui/core"
import LoadingIcon from "mdi-react/LoadingIcon";
import SearchableTable from "shared/components/SearchableTable";
import { getStudentListByProgramId } from "redux/actions/studentAction";
import { exportToCsv } from "shared/helpers"
import StudentModal from "../../Student/components/StudentModal"
import ConfirmationModal from "shared/components/ConfirmationModal"
import { showSucessNoti, showFailedNoti } from "redux/actions/notificationAction"
import api from "utils/apiClient"
const columns = [
  {
    id: 0,
    label: "",
  },
  {
    id: 1,
    label: "First Name",
  },
  {
    id: 2,
    label: "Last Name",
  },
  {
    id: 3,
    label: "Age (Years)",
  },
  {
    id: 4,
    label: "Grade",
  },
  {
    id: 5,
    label: "Gender",
  },
  {
    id: 6,
    label: "Email",
  },
  {
    id: 7,
    label: "Phone",
  },
  {
    id: 8,
    label: "PhotoPermission"
  },
  {
    id: 9,
    label: "LiabilityWaiver"
  },
  {
    id: 10,
    label: ""
  },
  {
    id: 11,
    label: "Action"
  },

]
const RegisterStudents = ({ id }) => {
  const { studentListByProgramId } = useSelector((state) => state.student);
  const { programDetails } = useSelector(state => state.program)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [currentId, setCurrentId] = useState({})
  const [cancelDialog, setCancelDialog] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true)
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
    // setLoading(true)
    // dispatch(getStudentListByProgramId({ pageNo: page + 1, pageSize: event.target.value, programId: id }))
    //   .then(() => setLoading(false))
  };
  const handleChangePage = (event, pg) => {
    setPage(pg);
    // setLoading(true)
    // dispatch(getStudentListByProgramId({ pageNo: pg + 1, pageSize: rowsPerPage, programId: id }))
    //   .then(() => setLoading(false))
  };
  const clearSearch = () => {
    setSearchValue("");
  };
  const handleHistorySearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  const convertToCsv = () => {
    let data = []
    let A = [
      "Sl. No.",
      "Student First Name",
      "Student Middle Name",
      "Student Last Name",
      "DOB",
      "Age",
      "Gender",
      "Email",
      // "Postal Code",
      "Phone",
      "Parent Name",
      "Grade",
      "Emergency Contact",
      "Learning Needs",
      "Medical Condition",
      "School Name",
      "Payment Status",
      "Address",
      "City",
      "Province",
      "Postal Code",
      "PhotoPermission",
      "LiabilityWaiver"
    ]
    data.push(A)

    console.log("filteredData for export", filteredData)
    filteredData.forEach((d, index) => {
      return data.push([
        index + 1,
        d.fName,
        '',
        d.lName,
        new Date(d.birthDate).toLocaleDateString(),
        d.age,
        d.gender,
        d.email,
        // d.address1,
        // d.city,
        // d.postalCode,
        d.phone,
        d.parentName,
        d.grade,
        d.emergencyContact,
        d.learningNeeds,
        d.medicalCondition,
        d.schoolName,
        d.paymentStatus,
        d.address1,
        d.city,
        d.province,
        d.postalCode,
        d.photoPermission,
        d.liabilityWaiver
      ])
    });
    exportToCsv(data, programDetails.programName)
  }
  const handleCancel = (data) => {
    setCurrentId(data)
    setCancelDialog(true)
  }
  const handleClose = () => {
    setCancelDialog(false)
  }
  const handleCancelStudent = () => {
    api.transaction.refundByProvider({ studentId: currentId.studentId, programId: programDetails.id })
      .then((res) => {
        if (res) {
          if (res.data.data) {
            setCancelDialog(false)
            dispatch(showSucessNoti(res.data.message))
          }
          else {
            setCancelDialog(false)
            dispatch(showFailedNoti(res.data.message))
          }
        }
      }).catch(err => {
        setCancelDialog(false)
      })
  }

  let filteredData = studentListByProgramId.item2
  if (searchValue) {
    filteredData = studentListByProgramId?.item2.filter((search) => {
      if (search.fName.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      }
      else if (search.lName.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      }
      else if (search.mName.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      }
      else if (search.email.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      }
    });
  }
  useEffect(() => {
    setLoading(true)
    dispatch(getStudentListByProgramId({ pageNo: page + 1, pageSize: 1000, programId: id }))
      .then(() => setLoading(false))
      .catch((err) => console.log(err.message))
  }, [])
  return (<Col xl={12} lg={12} md={12} sm={12} xs={12}>
    <Card>
      <CardBody>
        <>
          <Col md={12}>
            <Card>
              <CardBody>
                <h5 className="uppercase bold-text font-size-13 mb-24">Enrolled Students</h5>
                <SearchableTable
                  title=""
                  rows={columns}
                  filteredData={filteredData}
                  loading={loading}
                  actionTablebtn={true}
                  actionBtnIcon="upload"
                  actionBtnText="Export"
                  actionBtnClick={convertToCsv}
                  searchType="text"
                  searchLabel="Search"
                  searchValue={searchValue}
                  clearSearch={clearSearch}
                  searchPlaceholder="Student Name"
                  pagination={true}
                  getSearchResult={handleHistorySearch}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={studentListByProgramId.item1}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {loading ? (
                    <div className="panel__refresh">
                      <LoadingIcon />
                    </div>
                  ) : (
                    ""
                  )}

                  {!loading &&
                    filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell><Avatar className="mr-8" src={item.photo} /></TableCell>
                          <TableCell className="material-table__cell">
                            {item.fName}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.lName}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.age}
                          </TableCell>
                          <TableCell className="material-table__cell text-nowrap">
                            {item.grade}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.gender}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.email}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.phone}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.photoPermission === true ? "Selected" : "Not Selected"}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            {item.liabilityWaiver === true ? "Selected" : "Not Selected"}
                          </TableCell>
                          <TableCell className="material-table__cell">
                            <StudentModal Student={
                              {
                                age: item.age,
                                birthDate: item.birthDate,
                                currentAcademicDate: item.currentAcademicDate,
                                email: item.email,
                                emergencyContact: item.emergencyContact,
                                studentName: `${item.fName} ${item.mName ? item.mName : ""} ${item.lName}`,
                                gender: item.gender,
                                grade: item.grade,
                                id: item.id,
                                programName: [programDetails.programName],
                                image: item.image,
                                phone: item.phone,
                                photo: item.photo,
                                schoolName: item.schoolName
                              }
                            } />
                          </TableCell>
                          <TableCell>
                            <Button variant="outlined" size="small" color="secondary" onClick={() => handleCancel({
                              studentName: `${item.fName} ${item.mName ? item.mName : ""} ${item.lName}`,
                              studentId: item.id
                            })}>
                              <Icon color="secondary" className="mr-10">highlight_off</Icon>
                              Cancel
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </SearchableTable>
              </CardBody>
            </Card>
          </Col>
        </>
      </CardBody>
    </Card>
    {cancelDialog && (<ConfirmationModal
      color="danger"
      title="Cancel student registration"
      message={<span>Are you sure want to cancel <strong>{currentId.studentName}</strong> from program <strong>{programDetails.programName}</strong>?</span>}
      colored=""
      header={true}
      isOpen={cancelDialog}
      closeModal={handleClose}
      onYesClick={handleCancelStudent}
    />)}
  </Col>)
}


export default RegisterStudents