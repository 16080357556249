import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,  Divider, } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Skeleton } from "@material-ui/lab"
import {Col} from "reactstrap"


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 240,
    },
    
});

function SkeletonCard({ }) {
    const classes = useStyles();

    return (
        
            [0, 1, 2, 3].map((d, i) => <Col key={i}>
                <Card className={classes.root + " my-10 card-shadow"} >
                    <CardActionArea>
                        <Skeleton animation="wave" variant="rect" className={classes.media} />

                    </CardActionArea>

                    <CardContent>


                        <div className="my-10">
                            <Skeleton animation="wave" height={10} className="mb-10" />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </div>
                        <Divider />
                        <div className="my-10">
                            <Skeleton animation="wave" height={40} className="mb-10" />

                        </div>
                    </CardContent>
                    <CardActions className="bg-light-grey">
                        <Skeleton animation="wave" variant="circle" width={40} height={40} />

                        <Skeleton animation="wave" height={10} width="80%" />
                    </CardActions>
                </Card >

            </Col>)
        

    );
}

export default SkeletonCard