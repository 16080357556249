import { GET_ENROLLED_PROGRAM_STUDENT } from "../types";
import api from "../../utils/apiClient";
import { Toast } from "reactstrap";

export const enrolledProgramStudent = (id) => async (dispatch) => {
  await api.program
    .getProgramById(id, true)
    .then((res) => {
      if (res.data) {
        console.log("program dataaa.....", res.data)
        if (res.data.registredStudent <= res.data.capacity) {
          dispatch(setEnrollmentProgram(res.data));
        }
        else {
          console.log("program full to its capacity.")
        }

      }
    })
    .catch((err) => {
      // debugger;
    });
};

export const setEnrollmentProgram = (data) => async (dispatch) => {
  dispatch({
    type: GET_ENROLLED_PROGRAM_STUDENT,
    payload: data,
  });
};
