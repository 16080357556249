
import {
    PARENT_CALENDER_EVENTS,
    PROVIDER_CALENDER_EVENTS
   } from '../types';
   const initialState = {
       calenderEvents: [],
       loadCalender:false
   };
   
 const calendarReducer = (state = initialState, action) => {
     switch (action.type) {
         case PROVIDER_CALENDER_EVENTS:
             return {
                 ...state,
                 calenderEvents:action.payload
             }
       default:
         return state;
     }
   };
   
   export default calendarReducer;
   