import React, { useState } from 'react'
import Avatar from "@material-ui/core/Avatar";
import { Button, Icon } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Col } from "reactstrap"
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import BookIcon from '@material-ui/icons/Book';
import GradeIcon from '@material-ui/icons/Grade';
import { Mail } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  programList: {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px"
  },
  active: {
    color:"#2b2c48 !important",
    fontWeight:"800 !important"

  }
}));

function StudentInformations({ StudentInformations }) {
  const classes = useStyles();
  const [about, setAbout] = useState(true)
  const [program, setprogram] = useState(false)
  const [contact, setcontact] = useState(false)

  const aboutButton = () => {
    setAbout(true)
    setprogram(false)
    setcontact(false)
  }

  const programButton = () => {
    setAbout(false)
    setprogram(true)
    setcontact(false)
  }

  const contactButton = () => {
    setAbout(false)
    setprogram(false)
    setcontact(true)
  }
  return (
    <>
      {about &&
        <div className="StudentModal pb-10" >
          <Col md={12} >
            <Avatar variant="square" className={classes.large + " studentImage m-auto"} src={StudentInformations.image} />
            <div className="font-size-30 font-weight-500 text-center mb-10n">
              {`${StudentInformations.studentName}`}
            </div>
            <div className="font-size-13 mb-16 text-center text-muted">
              {StudentInformations.email},<span className="ml-4">{StudentInformations.gender}</span>
            </div>
            <div className="font-size-18 mb-8">
            <SchoolRoundedIcon className="mr-8"/>{StudentInformations.schoolName}
            </div>
            <div className="flex flex-middle flex-start mb-8">
            <div className="font-size-15 mr-4">
            <GradeIcon fontSize="default" className="mr-8"/><span className="text-muted">Grade:</span>
            </div>
            <div className="font-size-13 text-nowrap">
            {StudentInformations.grade}
            </div>
            </div>

            <div className="flex flex-middle flex-start mb-8">
            <div className="font-size-15 mr-4">
            <BookIcon fontSize="default" className="mr-8"/><span className="text-muted">Learning Need:</span>
            </div>
            <div className="font-size-13 text-nowrap">
            {StudentInformations.learningNeeds}
            </div>
            </div>

            <div className="flex flex-middle flex-start mb-8">
            <div className="font-size-15 mr-4">
            <LocalHospitalIcon fontSize="default" className="mr-8"/><span className="text-muted">Medical Condition:</span>
            </div>
            <div className="font-size-13 text-nowrap">
            {StudentInformations.medicalCondition}
            </div>
            </div>
            {/* <div className="flex flex-middle flex-start mb-8">
            <div className="font-size-15 mr-4">
            <CalendarTodayIcon fontSize="default" className="mr-8"/><span className="text-muted">AcademicDate:</span>
            </div>
            <div className="font-size-13 ">
            {moment(StudentInformations.currentAcademicDate).utc().format("MMM DD, YYYY ")}
            </div>
            </div> */}
            <div className="flex flex-middle flex-start mb-8">
            <div className="font-size-15 flex flex-middle mr-4">
            <Icon className="mr-8">cake</Icon><span className="text-muted">Birthday:</span>
            </div>
            <div className="font-size-13 ">
            {moment(StudentInformations.birthDate).utc().format("MMM DD, YYYY ")}
            </div>
            </div>
          </Col>
        </div>
      }
      {/* {program && <div  className="pb-10">
        <Col md={12} className="flex">
        <Avatar variant="circle" className={classes.small + " studentImage"} src={StudentInformations.image} />
            <div className=" mb-16 ">
              <span className="font-size-18 font-weight-500">{`${StudentInformations.fName}  ${StudentInformations.lName}`}</span><br />
              <span className="text-muted">{StudentInformations.email}</span>
            </div>
        </Col>
        <Col md={12}>
          {StudentInformations.programName.length > 0 && StudentInformations.programName.map((data, index) =>
            <div className="mt-10">
              <li key={index} className={classes.programList + " text-muted p-10 ProgramName"} >
                {data}
              </li>
            </div>
          )}</Col>
      </div>} */}
      {contact &&
        <div className="pb-10">
          <Col md={12} className="flex">
        <Avatar variant="circle" className={classes.small + " studentImage"} src={StudentInformations.image} />
            <div className=" mb-16 ">
              <span className="font-size-18 font-weight-500">{`${StudentInformations.studentName} `}</span><br />
              <span className="text-muted">{StudentInformations.email}</span>
            </div>
        </Col>
        <Col md={12}>
            <div className="mt-10">
              <li key={1} className={classes.programList + " text-muted p-10 mb-10 ProgramName"} >
              <Mail className="mr-8"/>{StudentInformations.email}
              </li>
              <li key={2} className={classes.programList + " text-muted p-10 ProgramName"} >
              <PhoneIcon className="mr-8"/>{StudentInformations.emergencyContact}
              </li>
            </div>
          </Col>
        </div>
      }
      <div class="card-buttons mb-0 pb-0 pt-8">
        <Button className={about ? classes.active: "" + " py-0 font-size-10 font-weight-500"} onClick={aboutButton} active>ABOUT</Button>
        {/* <Button className={program ? classes.active: "" + " py-0 font-size-10 font-weight-500"} onClick={programButton} active>Program</Button> */}
        <Button className={contact ? classes.active: "" + " py-0 font-size-10 font-weight-500"} onClick={contactButton} active>Contact</Button>
      </div>
    </>
  )
}

export default StudentInformations
