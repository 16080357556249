import React  from 'react';
import { Field, reduxForm,} from 'redux-form';
import { connect} from 'react-redux';
import {Button,Divider} from "@material-ui/core"
import { Row,Col } from 'reactstrap';
import {renderField} from "../form/RenderField"
import {validate} from "../../../config/formValidation"
const Google = `${process.env.PUBLIC_URL}/img/for_store/catalog/btn_google_signin_dark_pressed_web@2x.png`
const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  valid,
  handleForgot
}) => {
 
  return (
    <form
      onSubmit={handleSubmit(handleForgot)}
    >
     
     {/* <div className="mb-3">
          <p>Don’t worry! Please enter your registered email id to reset password.</p>
      </div> */}
  
      <Row>
        <Col>
        <div className="form__form-group">
          <Field
            name="email"
            label="Email*"
            component={renderField}
            type="email"
            placeholder="Email"
            startIcon='email'
            />
          </div>
         
        </Col>
      </Row>
     <Divider/>
      <div className="float-right my-10">
            <Button
              className="account__btn"
                type="submit"
                color="primary"
              disabled={!valid || submitting}
              variant="contained"
            >Submit
            </Button>
        </div>
    </form>
  );
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm({
  form: 'forgotForm',
  validate,
})(ForgotPasswordForm));
