import React from "react";
import { Card, CardBody, Col, Row, } from "reactstrap";
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form";
import { Button, Divider } from "@material-ui/core"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { renderField } from "../../../../shared/components/form/RenderField";

import { validate } from "../../../../config/formValidation"

const RecommendationForm = ({ handleSubmit, closeRecommandationModal, submitRecommendationNew }) => (
  <Card>
    <CardBody>
      <form onSubmit={handleSubmit(submitRecommendationNew)}>
        <Row>
          <Col md={12}>
           
            <div className="form__form-group">
              <Field
                name="programName"
                label="Program Name*"
                placeholder="Program Name"
                startIcon="people"
                component={renderField}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="location"
                label="Location*"
                placeholder="Location"
                startIcon="location_on"
                component={renderField}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="city"
                label="City*"
                placeholder="City Name"
                startIcon="home"
                component={renderField}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="category"
                label="Category*"
                placeholder="Category"
                startIcon="category"
                component={renderField}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="activity"
                label="Activity*"
                placeholder="Activity"
                startIcon="local_activity"
                component={renderField}
              />
            </div>
          </Col>


          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="website"
                Label="Website"
                placeholder="Include http:// or https://"
                startIcon="link"
                type="url"
                component={renderField}
              />
            </div>
          </Col>
          {/* <Col md={6}>
            <div className="form__form-group">
              <Field
                name="file"
                label="File"
                component={renderField}
                type="text"
                placeholder="File"
                className="input-without-border-radius"
                startIcon="file_copy"
              />
            </div>
          </Col> */}
          <Col md={12}>
            <div className="form__form-group">
              <Field
                name="reason"
                label="Reason*"
                startIcon="description"
                type="text"
                component={renderField}
                multiline={true}
                rows={4}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <div className="flex flex-end  mt-10 ">
          
          <Button color="primary" className="mr-10" variant="contained" type="submit">Submit</Button>
          <Button onClick={closeRecommandationModal} variant="outlined" color="secondary" >Close</Button>
        </div>

      </form>
    </CardBody>
  </Card>
);
const mapStateToProps = (state) => {
  return ({
    initialValues: {
      id: 0,
      programName: "",
      location: "",
      city: "",
      website: "",
      category: "",
      activity: "",
      reason: "",
      file: ""
    }
  })
}
export default connect(mapStateToProps, null)(reduxForm({
  form: "recommendationForm",
  validate
})(withTranslation("common")(RecommendationForm)));
