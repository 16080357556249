import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllProgram } from "../../redux/actions/programActions";
import DwulCard from "../../shared/components/CardComponent/DwulCard";
import NoRecord from "../../shared/components/NoRecord";
import SkeletonCard from "../../shared/components/CardComponent/SkeletonCard";
const AllProgram = ({ filtered = false }) => {
  const dispatch = useDispatch();
  let [pageNumber, setPageNumber] = useState(1);
  const { loading, allProgram } = useSelector((state) => state.program);
  let data = filtered
    ? allProgram.filter((item) => item.isEnrolled === false)
    : allProgram;
  useEffect(() => {
    (async () => {
      dispatch(getAllProgram({ pageNo: pageNumber, pageSize: 10 }));
    })();
  }, [pageNumber]);
  useEffect(() => {
    if (!allProgram) {
      return;
    }
  }, [loading]);

  // isEnrolled: false
  return (
    <>
      {/* only if loading is true  */}
      {!loading && allProgram.length === 0 && (
        <Col>
          <NoRecord />
        </Col>
      )}
      {loading && <SkeletonCard />}
      {data.map((item, index) => (
        <div key={index} className="flex flex-middle  ">
          <DwulCard
            name={item.programName}
            image={item.programPhoto}
            description={item.description}
            id={item.id}
            newTag={item.new}
            regulerDates={item.regulerDates}
            specialDates={item.specialDates}
            regularStartTime={item.regularStartTime}
            specialStartTime={item.specialStartTime}
            availability={item.availability}
            location={item.locationName}
            cost={item.cost}
            activities={item.activity}
            key={index}
            isManage={false}
            routePath={`/detail/${item.programName}/${item.id}`}
            item={item}
            providerImage={item.providerImage}
            organizationName={item.organisationName}
          />
        </div>
      ))}
      <div className="topbar_login">
        {pageNumber > 1 ? (
          <button
            className="topbar_login_btn"
            onClick={() => {
              pageNumber--;
              console.log("pageNumber ", pageNumber);

              setPageNumber(pageNumber);
            }}
          >
            Previous
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="topbar_login_btn"
          onClick={() => {
            pageNumber++;
            console.log("pageNumber ", pageNumber);

            setPageNumber(pageNumber);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default withRouter(AllProgram);
