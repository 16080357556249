import React from 'react';
import {
  Card, CardBody, Col, Container, Badge
} from 'reactstrap';
import { use, useDispatch } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { Button, Icon, Chip } from "@material-ui/core";
import { Avatar } from "@material-ui/core"
import { truncateString } from "../../../../../../shared/helpers"
import Divider from '@material-ui/core/Divider';
import SubscriptionCard from "./SubscriptionCard"
const Ava = `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`;

const ProfileMain = ({ providerProfile, handleChangePassModal, loading }) => {
  const dispatch = useDispatch()
  return (
    <Card className="pb-10">
      <CardBody className="profile__card p-16 pb-0">
        {
          loading ?
            <div className="my-10">


              <Skeleton className="profile__avatar m-auto" variant="circle" width={150} animation="wave" height={150} />

            </div>
            :
            <div className="my-10 text-center">

              <Avatar className="profile__avatar m-auto" src={providerProfile.logoUrl ? providerProfile.logoUrl : Ava} alt="avatar" />
            </div>
        }
        <div className="profile__stat p-10 my-10">
          {
            loading ? (
              <h5>
                <Skeleton className="m-auto" animation="wave" height={20} width="20" />
              </h5>
            )
              :
              <div className="flex flex-middle flex-center">
                <h3 className="profile__name  text-tranform-none">
                  <span>
                    {providerProfile.fName}  {providerProfile.lName}
                  </span>
                </h3>
              </div>
          }
          {
            loading ?
              <h4>
                <Skeleton className="m-auto" animation="wave" height={20} width="20" />
              </h4>

              :

              <div className=" mt-8 mb-10 flex flex-center flex-middle text-muted">
                <Icon className="mx-4">location_city</Icon>
                <span className="bold-text">   {providerProfile.organisationName}</span>
              </div>

          }
          {
            loading ?
              <>
                <Skeleton animation="wave" height={25} />
                <Skeleton animation="wave" height={25} width="80" />
              </>
              :
              <div className="text-left ">
                <h5 dangerouslySetInnerHTML={{ __html: truncateString(providerProfile.description, 500) }}></h5>
              </div>
          }
        </div>

        {/* <Divider /> */}

        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__name">Contact</p>
            {
              loading ?
                <Skeleton variant="rect" width="100%" animation="wave" height={10} />
                :
                <p className="profile__stat-title">{providerProfile.contactNo}</p>
            }
          </div>
          <div className="profile__stat">
            <p className="profile__name">Email</p>
            {
              loading ?
                <Skeleton variant="rect" width="100%" animation="wave" height={10} />
                :
                <p className="profile__stat-title">{(!providerProfile.email || providerProfile.email == "") ? "NA" : providerProfile.email}</p>
            }
          </div>
        </div>
        <Divider />


        <div className="mt-3 mb-3 text-center">
          <Button size="small" color="primary" onClick={handleChangePassModal} variant="outlined">Change Password</Button>
        </div>
        <div className="mt-2">
          <Divider />
        </div>

        <SubscriptionCard
          loading={loading}
          subscriptionName={providerProfile.subscriptionName}
          endDate={providerProfile.endDate}
        />
      </CardBody>
    </Card>
  );
}

export default ProfileMain;
