import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useSelector,useDispatch} from "react-redux";
import { rating } from "../../../../redux/actions/reviewAction"

export default function Review() {
  const giveRating = useSelector(state => state.review.rating);
  const readOnly = useSelector(state => state.review?.readOnlyReview);
  const dispatch = useDispatch()
  return (
    <>
      <Box component="fieldset"  borderColor="transparent">
  
        <Typography className="ml-1" component="legend">Rate Program</Typography>
        <Rating
          size="large"
          name="simple-controlled"
          value={giveRating}
          disabled={readOnly}
          onChange={(event, giveRating) => {
          
            dispatch(rating(giveRating))
      
          }}
        />
      </Box>
     
    </>
  );
}
