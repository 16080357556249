import React from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import { Chip, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import CityTag from "../../../shared/components/PopularTags/Components/CityTag";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.3),

    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Interest = ({ parentProfile }) => {

  const classes = useStyles();

  return (
    <>
      <Card className="pb-16">
        <CardBody className="profile__card">
          <h3 className="bold-text text-center mt-2">My Interests</h3>
          <Container>
            <Divider className="mt-3" />

            <div className={classes.root}>
              {parentProfile.interestNames && parentProfile.interestNames.map((d, i) => (
                <Chip
                  icon={<Icon>loyalty_sharp_icon</Icon>}
                  label={d.name}
                  color="primary"
                  variant="outlined"
                />
              ))}
              {parentProfile.interestNames && parentProfile.interestNames.length === 0 && (<span className="text-muted">Update your Interests in your profile setting and check for activities in this Featured Page.</span>)}
            </div>
          </Container>
        </CardBody>
      </Card>
    </>
  );
};

export default Interest;
