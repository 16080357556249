import React, { Fragment } from "react";
import {
  Modal, ModalHeader, Alert, Card, CardBody
} from 'reactstrap';
import {useHistory} from "react-router-dom"
import {useSelector,useDispatch} from "react-redux"
import PaypayButton from "./PaypalButton"
import Collapse from "../../../shared/components/Collapse";
import Etranfer from "./Etranfer"
import PayLater from "./PayLater"

import { addTransactionDetails } from "../../../redux/actions/transactionActions"
import { getCartDetails } from "../../../redux/actions/cartAction"
import { paymentDetailsModal } from "../../../redux/actions/modalAction"
import StripeButton from "./StripeButton"
function PaymentModal({ open, handleClose, title }) {
  // cartProduct: state.cart.cartProduct,
  // totalCartCost: state.cart.totalCartCost,
  //   grandTotalCart: state.cart.grandTotalCart
  
  
  const { grandTotalCart } = useSelector(state => state.cart)
  const history = useHistory()
  const dispatch = useDispatch()
 const  saveTractionDetails = async (tdata, details) => {
   console.log("saveTractionDetails")
    let data = {
       id: 0,
      paymentSource: 1,
      transactionDetails: JSON.stringify(details),
      applicationFee: 0,
      currency: "CAD",
      transactionId: tdata.orderID
    }
    await dispatch(addTransactionDetails(data)).then(() => {
       
      dispatch(getCartDetails()).then(() => {
         history.push("/home")
        dispatch( paymentDetailsModal(false))
        })
    })
  }
  return (
    <Fragment>
      <Modal scrollable toggle={handleClose} className="theme-light ltr-support login-modal-form mb-10" isOpen={open} centered>
        <ModalHeader toggle={handleClose}><h5><strong>{title}</strong></h5></ModalHeader>
        <Card style={{overflow: 'auto'}}>
          <CardBody>
            {/* <Collapse title="PayPal" className="with-shadow">
              <PaypayButton
                totalvalue={grandTotalCart}
                saveTractionDetails={(tdata, details) => saveTractionDetails(tdata, details)} />
            </Collapse> */}
            <Collapse
              title="Credit Card"
              className="with-shadow">
               <StripeButton/>
            </Collapse>
            <Collapse title="E-Tranfer" className="with-shadow">
               <Etranfer/>
            </Collapse>
            <Collapse title="Wait-list" className="with-shadow">
              <PayLater/>
            </Collapse>
          </CardBody>
        </Card>

      </Modal>
    </Fragment>
  );
}
export default PaymentModal
