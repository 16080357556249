import {
    GET_TRANSACTION_HISTORY_PROVIDER, GET_TRANSACTION_HISTORY_PARENT, GET_TRANSACTION_HISTORY_ADMIN, TOTAL_COUNT,
    CANCEL_ORDER_LIST, CANCEL_ORDER, REFUND_ORDER_LIST, APPROVE_REFUND, PAYMENT_SATRT, TOATAL_PAYOUTS
} from "../types"

const initialState = {
    TrHistoryProvider: [],
    TrHistoryParent: [],
    TrHistoryAdmin: [],
    totalCount: {},
    cancelOrderList: [],
    cancelOrder: 0,
    refundList: [],
    approveRefund: {},
    trloadingParent: true,
    paymentStart: false,
    totalPayouts: {}
}

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSACTION_HISTORY_PROVIDER:
            return {
                ...state,
                TrHistoryProvider: action.payload
            };
        case GET_TRANSACTION_HISTORY_PARENT:
            return {
                ...state,
                TrHistoryParent: action.payload,
                trloadingParent: false
            };
        case GET_TRANSACTION_HISTORY_ADMIN:
            return {
                ...state,
                TrHistoryAdmin: action.payload
            };
        case TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload
            };
        case CANCEL_ORDER_LIST:
            return {
                ...state,
                cancelOrderList: action.payload
            }
        case CANCEL_ORDER:
            return {
                ...state,
                cancelOrder: action.payload
            }
        case REFUND_ORDER_LIST:
            return {
                ...state,
                refundList: action.payload
            }
        case APPROVE_REFUND:
            return {
                ...state,
                approveRefund: action.payload
            }
        case PAYMENT_SATRT:
            return {
                ...state,
                paymentStart: action.payload
            }
        case TOATAL_PAYOUTS:
            return {
                ...state,
                totalPayouts: action.payload
            }
        default:
            return state;

    }
};

export default transactionReducer;