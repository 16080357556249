import React from 'react'
import { makeStyles, Icon } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: "4.25rem",
        opacity: "0.5"
    },
    smallIcon: {
        fontSize: "40px",
        opacity: "0.2",
        left: "4px",
        position: "absolute"
    },
    label: {
        fontSize: "24px"
    },
    smallCount: {
        color: "#7d7d7d !important"
    },
    "shadow-default": {
        textShadow: "1px 1px 4px #646777"
    },
    "shadow-color1": {
        textShadow: "1px 1px 4px #f9cb78"
    },
    "shadow-color2": {
        textShadow: "1px 1px 4px #8ae98d"
    },
    "shadow-color3": {
        textShadow: "1px 1px 4px #d2d47a"
    },
    "shadow-color4": {
        textShadow: "1px 1px 4px #9ea1f0"
    },
    "shadow-color5": {
        textShadow: "1px 1px 4px #68c1c5"
    },
    default: {
        color: "#646777 !important"
    },
    color1: {
        color: "#f6a81e !important"
    },
    color2: {
        color: "#3ddb42 !important"
    },
    color3: {
        color: "#b5b722 !important"
    },
    color4: {
        color: "#5e62e6 !important"
    },
    color5: {
        color: "#03989f !important"
    },
    cardbg: {
        borderRadius: "10px",
        padding: "15px 13px",
        position: "relative"
    },
    smallCardbg: {
        borderRadius: "10px",
        position: "relative"
    },
    "cardbg-default": {
        backgroundColor: "#64677733 !important"
    },
    "cardbg-color1": {
        backgroundColor: "#f6a81e33 !important"
    },
    "cardbg-color2": {
        backgroundColor: "#3ddb4233 !important"
    },
    "cardbg-color3": {
        backgroundColor: "#b5b72233 !important"
    },
    "cardbg-color4": {
        backgroundColor: "#5e62e633 !important"
    },
    "cardbg-color5": {
        backgroundColor: "#03989f33 !important"
    },

}))

export default function CounterCard({
    count,
    label,
    icon,
    color = "default",
    className = "",
    size = "default"
}) {
    const classes = useStyles();

    return size === "small" ?
        (
            <div className={`${classes.smallCardbg} ${classes[`cardbg-${color}`]}  w-100 ${className}`}>

                <div className="flex flex-middle p-8 flex-center">
                    <h1 className={`subhead px-8 bold-text ${classes.smallCount}`}>{count}</h1>
                    <p className={`font-size-10 ${classes[color]} mt-0 uppercase text-center`}><strong>{label}</strong>
                    </p>
                    <Icon


                        color="action"
                        className={` float-right ${classes.smallIcon} ${classes[color]} ${classes[`shadow-${color}`]}`}>{icon}</Icon>
                </div>

            </div>
        ) : (
            <div className={`${classes.cardbg} ${classes[`cardbg-${color}`]}`}>
                <div className="dashboard__carousel-slide">
                    <h1 className="subhead ml-10 bold-text">{count}</h1>
                    <p className={`${classes.label} ${classes[color]} uppercase`}><strong>{label}</strong>
                        <Icon

                            color="action"
                            className={`float-right mt-36n ${classes.icon} ${classes[color]} ${classes[`shadow-${color}`]}`}>{icon}</Icon>
                    </p>
                </div>
            </div>
        )
}