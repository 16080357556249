import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
// import TopBar from "../../shared/components/TopBar"
// import CardNew from "../../shared/components/CardComponent/CardNew"
// import SkeletonCard from "../../shared/components/CardComponent/SkeletonCard"
import ModalAddProgram from "./Components/ModalAddProgram"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router-dom"
import useCollapse from 'react-collapsed'

import api from "../utils/apiClient"
import { getProviderProgramById, providerProgram, setProviderProgramById } from "../redux/actions/programActions"
import { reset } from "redux-form"
import { useSelector, useDispatch } from "react-redux"
import { showSucessNoti, showFailedNoti, showWarningNoti } from "../redux/actions/notificationAction"
// import ConfirmationModal from "../../shared/components/ConfirmationModal"
// import NoRecord from "../../shared/components/NoRecord"
import { getLocationData } from "shared/helpers"
import moment from 'moment';


import { programListingByProvider, ListingOfLink, addLink } from '../redux/actions/linkManagementAction';
import CardNew from './Components/CardNew';






const ProfileInfoLink = () => {
    const dispatch = useDispatch()
    const [programModal, setProgramModal] = React.useState(false);
    const [programImage, setProgramImage] = React.useState("");
    const [deleteProgram, setdeleteProgram] = React.useState(false)
    const [programCurrent, setProgramCurrent] = React.useState({})
    const [isEdit, setIsEdit] = React.useState(false)
    const history = useHistory()
    const [progamDetails, setProgamDetails] = useState([]);
    const [providerProfile, setProviderProfile] = useState('');
    const [locations, setLocations] = useState('');
    const [programs, setPrograms] = useState([]);
    const [LocationsError, setLocationsError] = useState('');
    const [isProviderUser, setisProviderUser] = useState(false);
    const [description, setDescription] = useState('');
    const [logoUrl, setlogoUrl] = useState('')
    const [imageUpload, setImageUpload] = useState('');
    const [DescriptionError, setDescriptionError] = useState('')
    const [linkId, setLinkId] = useState();
    const [id, setId] = useState(0);
    const [locationName, setLocationName] = useState();
    const [ln, setln] = useState('');
    const [linkProgramId, setLinkProgramId] = useState('');
    const [champa, setChampa] = useState([])
    const [callApi, setCallApi] = useState(false)
    // const [organisationName, setOrganisationName] = useState('');
    // const [organisationNameError, setOrganisationNameError] =  useState('')
    const [linkName, setLinkName] = useState("")
    const [linkManagementAnalytics, setlinkManagementAnalytics] = useState([])
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    const { programMedia, programPdf } = useSelector(state => state.media)
    const { providerProgramData, providerLoading } = useSelector(state => state.program)
    const [isProgramValues, setisProgramValues] = useState(false);
    const [arrProgram, setArrProgram] = useState([]);

    const { logo } = useSelector(state => state.program)

    const linkManagementList = useSelector(
        (state) => state.linkManagement.linkManagementList
    );

    React.useEffect(() => {
        dispatch(providerProgram())
    }, [])
    const handleClose = () => {
        dispatch(reset("programForm"))
        dispatch(setProviderProgramById({}))

        setProgramModal(false)
        setdeleteProgram(false)
    }


    useEffect(() => {
        let pathName = window.location.pathname

        let arry = pathName.split('/');

        if (arry.length === 4) {

            let linkName = arry[3];
            setln(linkName)
            getLinkDetailsByLinkName(linkName);
        }
    }, [callApi])

    async function getLinkDetailsByLinkName(linkName) {


        await api.linkManagement.GetLinkByLinkName(linkName, true).then(res => {

            if (res.data) {
                if (res.data.data.isActive) {
                    // console.log("Active", res.data.data)
                    setisProgramValues(res.data.data)
                    setLinkId(res.data.data.linkId)
                    setlinkManagementAnalytics(res.data.data.linkManagementAnalytics)
                    setLocations(res.data.data.providerProfile.locations)
                    setDescription(linkName)
                    setlogoUrl(res.data.data.providerProfile.logoUrl)
                    setProgamDetails(res.data.data.progamDetails);
                    setProviderProfile(res.data.data.providerProfile);
                    setisProviderUser(res.data.data.isProviderUser)
                    let a = document.getElementById("description")
                    a.innerHTML = "<span class='material-icons MuiIcon-root' aria-hidden='true' >notes</span> About:" + res.data.data.providerProfile.description
                    res.data.data.programName = linkName
                    dispatch(setProviderProgramById(res.data.data))
                    // dispatch(showSucessNoti(res.data.message));

                }
                else {
                }
                // else{
                //     history.push("/home");
                // }
            }
        }).catch(err => {

            if (err.response) dispatch(showFailedNoti(err.response.data.title));
        })

    }
    const handleProgram = () => {
        getLinkDetailsByLinkName(description)
        setProgramModal(true);
        setIsEdit(false);
    }


    const getUTCDate = (date = []) => {
        if (date == "") {
            return ""
        } else {
            return date.map((d) => {
                let dt = new Date(d).toISOString();
                return dt.substring(0, 19)
            })
        }
    }

    // const getUTCDate = (date = []) => {
    //     if (date == "") {
    //         return ""
    //     } else {
    //         return date.map((d) => {
    //             return moment(d).format('YYYY-MM-DDTHH:mm:ss')
    //         })


    //     }

    // }

    const programSubmit = async (values) => {
        setln(values.programName)
        console.log(JSON.stringify(values))

        let programData = {

            "id": linkId,
            "programName": values.programName,
            "logoUrl": programMedia ? programMedia : values.programPhoto,
            "description": values.description,
            "locations": values.locations,
            "websiteLink": values.websiteLink,
            "youtubeLink": values.youtubeLink,
            "instagramLink": values.instagramLink,
            "facebookLink": values.facebookLink,
            "twitterLink": values.twitterLink,
            "linkName": values.programName,
            "isActive": true,
            "arrProgram": [0],

            // id: linkId,
            // linkName: ln,
            // "isActive": true,
            // "arrProgram": [
            //     0
            // ],
            // "ageGroup": [
            //     {
            //         "id": 0,
            //         "name": "string"
            //     }
            // ],
            // description: values.description,


            // logoUrl: programMedia ? programMedia : values.programPhoto,
            // // "logoUrl":values.logoUrl,
            // // programPhoto: programMedia ? programMedia : values.programPhoto,
            // "programName": "string",
            // "categoryTags": [
            //     {
            //         "id": 0,
            //         "name": "string"
            //     }
            // ],
            // locations: values.locations,

            // "organisationName": "organisationName"

        }

        console.log("programData", programData)

        await api.linkManagement.addProgramLinkManagement(programData, true).then(res => {
            setTimeout(function () {
                if (callApi == true) {
                    setCallApi(false);
                }
            }, 500);

            dispatch(reset("programForm"))
            if (res.data) {
                dispatch(showSucessNoti(res.data.message))
                dispatch(ListingOfLink())
                window.location.href = values.programName
                handleClose()
                getLinkDetailsByLinkName()
                setCallApi(true)
            } else {


                // closeModaleditlink();
                // closeModaladd();
                // dispatch(showWarningNoti(res.message))
            }
        }).catch(err => {
            // dispatch(showFailedNoti(err.message))
            // handleClose()
            // closeModaladd();
            dispatch(showFailedNoti(err.response.data.title));
        })

    }

    function checkhttp(url) {
        if (url) {
            if (url.includes('http')) {

                return url

            } else {
                return "https://" + url

            }

        }
    }


    return (
        <>
            <Row>
                <Col>


                    <div className="collapse-data-box">
                        {/* <button {...getToggleProps()}>
                            View Analytics
                        </button> */}
                        {isProviderUser ? <button style={{ marginRight: "30px", zIndex: 100 }} className="edit-profile-box new-edit" onClick={() => handleProgram()}>Edit Link </button> : ""}
                        {/* <section {...getCollapseProps()}>
                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Data</th> 
                                    <th>IP Address</th>
                                </tr>
                                {linkManagementAnalytics && linkManagementAnalytics.map(data => 
                                <tr>
                                    <td><p>{data.id}</p></td> 
                                    <td> <p>{data.ipAddress}</p></td>
                                </tr>
                           ) }
                            </table> 
                        </section> */}
                    </div>


                    <div className="row">
                        <div class="col-md-4">
                            <div className="Profile-info-content">
                                <div className="profile-info-img">
                                    <img src={providerProfile && providerProfile.logoUrl} alt="student"></img>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div className="Profile-info-content-student">
                                <div className="profile-text-box">
                                    {/* <a href="">View Listing</a>
                            <a href="">website</a> */}
                                </div>
                                <div className="profile-text-box">
                                    <h6><span class="material-icons MuiIcon-root" aria-hidden="true">language</span> {providerProfile && providerProfile.locations.map(a => (a.name)).join(', ')}</h6>
                                </div>
                                <div className="profile-text-box">
                                    <h6 id="description"></h6>
                                    {/* {/ <p>{providerProfile.description}</p > /} */}
                                    <div div className='collapse-data-box mt-5 social_row'>
                                        <a href={checkhttp(isProgramValues.websiteLink)} target="_blank" id="copylink"> <button type="button" className='mb-0' role="button"> Website</button></a>

                                        <div className='ml-3'>
                                            {isProgramValues.facebookLink && <a href={checkhttp(isProgramValues.facebookLink)} target="_blank" id="copylink">
                                                <span className='fb_icon'>
                                                    <i className='fa fa-facebook'></i>
                                                </span></a>}
                                            {isProgramValues.instagramLink && <a href={checkhttp(isProgramValues.instagramLink)} target="_blank" id="copylink">
                                                <span className='insta_icon'>
                                                    <i className='fa fa-instagram'></i>
                                                </span></a>}
                                            {isProgramValues.youtubeLink && <a href={checkhttp(isProgramValues.youtubeLink)} target="_blank" id="copylink">
                                                <span className='youtube_icon'>
                                                    <i className='fa fa-youtube-play'></i>
                                                </span></a>}
                                            {isProgramValues.twitterLink && <a a href={checkhttp(isProgramValues.twitterLink)} target="_blank" id="copylink">
                                                <span className='twitter_icon'>
                                                    <i className='fa fa-twitter'></i>
                                                </span></a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5"></hr>

                    <div className="row">
                        {progamDetails && progamDetails.map((item, index) => {
                            return (
                                <div className="col-md-3 listingrow" key={index}   >
                                    <>

                                        <CardNew
                                            name={item.programName}
                                            image={item.programPhoto}
                                            locations={item.locations}
                                            id={item.id}
                                            regulerDates={item.regulerDates}
                                            item={item}
                                            routePath={`/detail/${item.programName}/${item.id}`}
                                        />
                                    </>
                                </div>
                                // <div className="col-md-3">

                                //     <div className="experience-box-area">
                                //         <div className="experience-box-img">
                                //             {data.programPhoto === "" ? <img src={`${process.env.PUBLIC_URL}/img/details.jpg`} alt=""></img> : <img src={data.programPhoto} alt=""></img>}
                                //         </div>
                                //         <div className="experience-box-content">
                                //             <h4>{data.programName}</h4>
                                //             <p><span class="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">language</span> {data.locations.map(a => a.name)}</p>
                                //             <p><span class="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">group</span>{data.regulerDates[0]}</p>
                                //             {/* <p><span class="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">room</span> {data.ageGroup.map(c => c.name)}</p> */}
                                //         </div>
                                //     </div>
                                // </div>
                            );
                        })}
                    </div>

                    <div className="collapse-data-box">
                        <button {...getToggleProps()}>
                            View Analytics
                        </button>
                        <section {...getCollapseProps()}>
                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Data</th>
                                    {/* <th>Email</th> */}
                                    <th>IP Address</th>
                                </tr>
                                {linkManagementAnalytics && linkManagementAnalytics.map(data =>
                                    <tr>
                                        <td><p>{data.id}</p></td>
                                        <td><p>{data.linkManagementId}</p></td>
                                        {/* <td><p>{'' }</p></td>   */}
                                        {/* inkManagementAnalytics[0]?.userDetails.email */}
                                        <td> <p>{data.ipAddress}</p></td>
                                    </tr>
                                )}
                            </table>
                        </section>
                    </div>
                </Col>
            </Row>

            {
                programModal && (
                    <ModalAddProgram
                        title="Edit Link"
                        isOpen={programModal}
                        closeModal={handleClose}
                        programSubmit={programSubmit}
                        programImage={programImage}
                        isEdit={isEdit}
                        setArrProgram={setArrProgram}
                    />

                )
            }
            {/* <ConfirmationModal
                color="danger"
                title="Delete Program"
                message="Are you sure you want to delete"
                colored=""
                header=""
                isOpen={deleteProgram}
                closeModal={handleClose}
                onYesClick={handleProgramDeleteConfirm}
            /> */}
        </>
    )
}

export default withRouter(ProfileInfoLink)