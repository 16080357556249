import React, { useState } from 'react';
import axios from "axios"


export default function BannerUploadInput() {

 
 const handleChange = (event) => {
   
     
     if (event.target.files.length!==0) {
              
         let formData = new FormData();
         for (let i = 0; i < event.target.files.length; i++){
             formData.append('banner' + (i + 1).toString(), event.target.files[i]);
             console.log('banner' + (i + 1).toString(), event.target.files[i])
         }
        
 
              
            uploadBanners(formData)
          }
    
     }

    return (
        <div>
            <br></br>
            <h4>Upload Banners :</h4>
        <input
                type="file"
                multiple="multiple"
       
        name="file"
        icon='file text outline'
        iconPosition='left'
        label='Upload Banners'
        labelPosition='right'
        placeholder='Upload Banner...'
        onChange={handleChange}
    /></div>);
  
}

async function uploadBanners(formData) {
    
 let token = localStorage.getItem("authToken");

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        };
    // 
        await axios.post(window.apiUrl + `Common/Upload-banners`, formData, config)
            .then(res => {
                
                
            },
                error => {
                 console.log("upload error : "+error.message)
                }
            );
    
        
    
}