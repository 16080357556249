import React from 'react';
import ProgramListTable from './ProgramListTable';

const ProfileProgram = () => {
  return(
    <>
    <ProgramListTable 
    title="Program List & Details"
    />
    </>
  ) 
};

export default ProfileProgram;
