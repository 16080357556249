import api from '../../utils/apiClient';
import {showSucessNoti} from "../actions/notificationAction"
import {GET_REVIEW,POST_REVIEW,RATING,P_ID, GET_PROGRAM_REVIEW} from "../types"

export const postReview =(data)=> async dispatch =>{
    await api.review.postReview(data,true).then(res => {
        if(res && res.data){
            dispatch({
                type: POST_REVIEW,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message));
        }
    })
    .catch(err =>{
        
    })
}

export const getReview =()=> async dispatch =>{
    await api.review.getReview(true).then(res =>{
        if(res && res.data){
            dispatch({
                type:GET_REVIEW,
                payload: res.data
            })
        }
    })
}

export const getMyReview = (id) => async dispatch => {
    await api.review.getParentReview(id ?? 0).then(res => {
        if(res && res.data){
            dispatch({
                type:GET_PROGRAM_REVIEW,
                payload: res.data
                // payload: 
                // {
                //     subject: 'dummy data',
                //     ratings: 4,
                //     fullMassage: 'My Message'
                // }
            })
        }
    });    
}

export const rating =(data)=> async dispatch =>{
    dispatch({
        type: RATING,
        payload: data
    })
}
export const programId =(id)=> async dispatch =>{
    dispatch({
        type: P_ID,
        payload: id
    })
}