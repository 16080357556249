import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button, Divider } from "@material-ui/core"
import { Row, Col } from 'reactstrap';
import { renderField } from "../../../shared/components/form/RenderField"
import { getOrganisations } from "../../../redux/actions/userAction"
import AutoDropDown from "../../../shared/components/form/AutoDropDown"
import { validate } from "../../../config/formValidation"
import ActivityTag from "../../../shared/components/form/ActivityTag"
import { mediaTypes } from "../../../config/mediaTypes"
import ImageUpload from "../../../shared/components/form/ImageUpload"
import { tagTypes } from '../../../config/tagTypes';
import renderFileInputField from "../../../shared/components/form/FileInput"
import { daysofWeek, ageGroup } from "shared/StaticData"
import TextEditor from "shared/components/text-editor/TextEditor"
import { getTodayday } from "shared/helpers"
const ExperienceForm = ({
    handleSubmit,
    submitting,
    valid,
    expSubmit,
    initialValues,
    experierenceMedia,
    file,
    closeModal
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrganisations())
    }, [])

    const OrganisationName = useSelector(state => state.users.getOrganisations)

    return (
        <form
            className="login-form"
            onSubmit={handleSubmit(expSubmit)}
        >
            <Row>
                <Col md={10} lg={10} sm={10}>
                    {/* <div className="form__form-group">
                        <Field
                            name="name"
                            label="Name"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'
                        />
                    </div> */}
                    <div className="form__form-group">
                        <Field
                            name="title"
                            label="Experience Title"
                            component={renderField}
                            type="text"
                            placeholder="Title"
                            startIcon='people'
                        />
                    </div>
                     <div className="form__form-group">
                        <Field
                            name="name"
                            label="Provider Name"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'
                        />
                    </div>
                    <Col md={6} lg={6} sm={6}>
                    <div className="form__form-group">

                        <Field
                            name="externalLink"
                            label="External Link"
                            component={renderField}
                            type="url"
                            placeholder="Include http:// or https://"
                            className="input-without-border-radius"
                            startIcon='link'
                        />
                    </div>
                </Col>
                    <div className="form__form-group">
                        <Field
                            name="address"
                            label="Address Info"
                            component={renderField}
                            type='text'
                            placeholder="Address"
                            className="input-without-border-radius"
                            // startIcon='location_on'
                            // tagType={[tagTypes.Location]}


                        />
                    </div>
                  
                    <div className="form__form-group">
                        <Field
                            name="locationId"
                            label="Location Tag*"
                            component={ActivityTag}
                            type='text'
                            placeholder="City"
                            // className="input-without-border-radius"
                            startIcon='location_on'
                            tagType={[tagTypes.Location]}


                        />
                    </div>
                      <div className="form__form-group">
                        <Field
                            name="activityId"
                            label="Activity"
                            component={ActivityTag}
                            defaultValue={initialValues.activity}
                            type='text'
                            placeholder="Activity"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            tagType={[tagTypes.Activity]}
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="category"
                            label="Category Tag"
                            component={ActivityTag}
                            defaultValue={initialValues.category}
                            type='text'
                            placeholder="Category"
                            className="input-without-border-radius"
                            startIcon='category_rounded_icon'
                            tagType={[tagTypes.Category]}
                        />
                    </div>
                    
                </Col>
                <Col md={2} lg={2} sm={2} className="pl-0">
                    <ImageUpload
                        accept="image/*"
                        image={experierenceMedia
                            ? experierenceMedia
                            :
                            initialValues.experiencePhoto}
                        mediatype={mediaTypes.Experience}
                    />
                </Col>
                <Col md={12} lg={12} sm={12}>
                    {/* <div className="form__form-group"> */}
                        {/* <Field
                            name="organisationName"
                            label="OrganisationName"
                            component={AutoDropDown}
                            type="text"
                            // defaultValue={initialValues.organisationName}
                            placeholder="OrganisationName"
                            multiple={false}
                            startIcon='people'
                            options={OrganisationName}
                        /> */}
                    {/* </div> */}

                    {/* <div className="form__form-group">
                        <Field
                            name="activity"
                            label="Activity"
                            component={renderField}
                            // defaultValue={initialValues.activity}
                            type='text'
                            placeholder="Activity"
                            // className="input-without-border-radius"
                            startIcon='rowing_rounded_icon'
                            // tagType={[tagTypes.Activity]}
                        />
                    </div> */}
                     
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="time"
                            label="Time"
                            component={renderField}
                            type='text'
                            placeholder="Time"
                            className="input-without-border-radius"
                            startIcon='access_alarms_rounded_icon'
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="date"
                            label="Date"
                            component={renderField}
                            type='text'
                            placeholder="Date"
                            className="input-without-border-radius"
                            startIcon='calendar_month_rounded_icon'
                        />
                    </div>
<div className="form__form-group">
                        <Field
                            name="age"
                            label="Age"
                            component={renderField}
                            type='text'
                            placeholder="Age"
                            className="input-without-border-radius"
                            startIcon='cake_rounded'
                        />
                    </div>
                </Col>
                <Col md={4} sm={4} xs={4} lg={4}>
                    <div className="form__form-group">
                        <Field
                            name="cost"
                            label="Cost"
                            component={renderField}
                            type='number'
                            step="0.0001"
                            placeholder="Cost"
                            className="input-without-border-radius"
                            startIcon='attach_money'
                        />
                    </div>
                </Col>
                {/* <Col md={4} sm={4} xs={4} lg={4}>
                    <div className="form__form-group">
                        <Field
                            name="numberOfSessions"
                            label="Sessions"
                            component={renderField}
                            type='number'
                            className="input-without-border-radius"
                            startIcon='hourglass_empty_icon'
                        />
                    </div>
                </Col> */}
                {/* <Col md={4} sm={4} xs={4} lg={4}>
                    <div className="form__form-group">
                        <Field
                            name="capacity"
                            label="Capacity"
                            component={renderField}
                            type='number'
                            className="input-without-border-radius"
                            startIcon='confirmation_number_icon'
                        />
                    </div>
                </Col> */}
                {/* <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="ageGroup"
                            label="Age Group*"
                            component={renderField}
                            type="text"
                            placeholder="Age Group"
                            startIcon='people'
                            className="input-without-border-radius"
                            // tagType={[tagTypes.AgeGroup]}
                        />
                    </div>
                </Col> */}
                {/* <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">
                        <Field
                            name="availability"
                            label="Days Of Week"
                            component={AutoDropDown}
                            type='text'
                            placeholder="Days Of Week"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            options={daysofWeek}
                        />

                    </div>
                </Col> */}

                
                <Col md={6} lg={6} sm={6}>
                    <Field
                        name="file"
                        component={renderFileInputField}
                        mediatype={mediaTypes.Experience}
                        myValue={file ? file : initialValues.file}
                    />
                </Col>
                <Col md={12} lg={12} sm={12}>
                    <div className="form__form-group">

                        <Field
                            name="description"
                            label="Description"
                            component={TextEditor}
                            type='text'
                            placeholder="Minimum 250 character required"
                            className="input-without-border-radius"
                            startIcon='local_activity'
                            multiline={true}
                            rows={4}
                        />
                    </div>
                </Col>
            </Row>
            <Divider />
            <div className="my-10 flex flex-end flex-middle ">
                <div >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!valid || submitting}

                    >Submit
                    </Button>
                </div>
                <div className="px-10">
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={closeModal}
                    >Close
                    </Button>
                </div>


            </div>
        </form>
    );
};
const selector = formValueSelector('experianceForm')
export default connect(state => {
    const organisationName = selector(state, 'organisationName')
    return ({
        errorMsg: state.user.error,
        experierenceMedia: state.media.experierenceMedia,
        file: state.media.experierencePdf,
        OrganisationName: organisationName ? organisationName : "",
        initialValues: {
            name: state.experiance.providerExpById.name ? state.experiance.providerExpById.name : "",
            organisationName: state.experiance.providerExpById.organisationName ? state.experiance.providerExpById.organisationName : "",
            locationId: state.experiance.providerExpById.locationId ? state.experiance.providerExpById.locationId : [],
            location: state.experiance.providerExpById.location ? state.experiance.providerExpById.location : "",
            experiencePhoto: state.experiance.providerExpById.experiencePhoto ? state.experiance.providerExpById.experiencePhoto : "",
            activity: state.experiance.providerExpById.activity ? state.experiance.providerExpById.activity : "",
            ageGroup: state.experiance.providerExpById.ageGroup? state.experiance.providerExpById.ageGroup : "",
            availability: state.experiance.providerExpById.availability ? state.experiance.providerExpById.availability.split(",") : [],
            capacity: state.experiance.providerExpById.capacity ? state.experiance.providerExpById.capacity : "",
            city: state.experiance.providerExpById.city ? state.experiance.providerExpById.city : "",
            cost: state.experiance.providerExpById.cost ? state.experiance.providerExpById.cost : "",
            description: state.experiance.providerExpById.description ? state.experiance.providerExpById.description : "",
            externalLink: state.experiance.providerExpById.externalLink ? state.experiance.providerExpById.externalLink : "",
            file: state.experiance.providerExpById.file ? state.experiance.providerExpById.file : "",
            id: state.experiance.providerExpById.id ? state.experiance.providerExpById.id : 0,
            numberOfSessions: state.experiance.providerExpById.numberOfSessions ? state.experiance.providerExpById.numberOfSessions : "",
            title: state.experiance.providerExpById.title ? state.experiance.providerExpById.title : "",
            activityId: state.experiance.providerExpById.activityId ? state.experiance.providerExpById.activityId : "",
            address: state.experiance.providerExpById.address ? state.experiance.providerExpById.address : "",
            categoryId: state.experiance.providerExpById.categoryId ? state.experiance.providerExpById.categoryId : [],
            date: state.experiance.providerExpById.date ? state.experiance.providerExpById.date : "",
            time: state.experiance.providerExpById.time ? state.experiance.providerExpById.time : "",
            programDetails: state.experiance.providerExpById.programDetails ? state.experiance.providerExpById.programDetails : "",
            age: state.experiance.providerExpById.age ? state.experiance.providerExpById.age : "",
            // programDetails:state.experiance.providerExpById.prog?state.experiance.providerExpById.description:"",
        }
    })
    
})(reduxForm({
    form: 'experianceForm',
    enableReinitialize: true,
    validate
})(ExperienceForm));
