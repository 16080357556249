import axios, { defaultParams } from '../base/axios';

export default async (url, body, headers, params, ct) => {
  try {

    if (headers) {
      return await axios.post(url, body, {
        ...defaultParams(), ...params, cancelToken: ct && ct.cancelToken
      });
    } else {
      return await axios.post(url, body, {
        ...params, cancelToken: ct && ct.cancelToken
      });
    }

  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    // localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.post(url, body, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};
