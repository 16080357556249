import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { IconButton, Icon, TableRow, Tooltip, TableCell } from "@material-ui/core";
import { openMessages, closeMessages } from '../../../../redux/actions/messageActions'
import SearchableTable from "../../../../shared/components/SearchableTable";
import { Card, CardBody } from "reactstrap"



const MessagesTable = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(50);
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.message.loading);
    const messageList = useSelector((state) => state.message.getMessage);

    const handelClick = (data) => {
        dispatch(openMessages(data))
        dispatch(closeMessages(true))
    }

    const clearSearch = () => {
        setSearchValue("");
    };
    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value);
    };

    const handleFavSearch = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value);
    };
    const rows = [
        {
            id: 1, label: "Name",
        },
        {
            id: 2, label: "Email",
        },
        {
            id: 4, label: "Actions",
        },
    ];
    let filteredData = messageList;
    if (searchValue) {
        filteredData = messageList.filter((user) =>
            user.parentName.toLowerCase().match(searchValue.toLowerCase())
        );
    }
    return (
        <>
            <Card>
                <CardBody>
                    <SearchableTable
                        title="Messages List"
                        rows={rows}
                        searchValue={searchValue}
                        filteredData={filteredData}
                        clearSearch={clearSearch}
                        loading={loading}
                        actionTablebtn={false}
                        searchLabel="Search parent"
                        searchPlaceholder="By name"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        getSearchResult={handleFavSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    >
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((d, i) => {
                                return (
                                    <TableRow
                                        className="material-table__row"
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={d.id}
                                    >
                                        <TableCell className="material-table__cell" padding="checkbox">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            {d.parentName}
                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                                            {d.email}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >
                                            <Button variant="outlined" color="primary" size="small" onClick={() => handelClick(d.messageDetails)}>View Messages </Button>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                    </SearchableTable>
                </CardBody>
            </Card>

        </>
    )
}
export default withTranslation("common")(MessagesTable);