
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, Box, Typography } from "@material-ui/core"
import Chip from '@material-ui/core/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { getTagsByTagType } from '../../../redux/actions/tagAction';
import { exploreModal } from '../../../redux/actions/modalAction';
import { getAllProgram } from '../../../redux/actions/programActions';
import { allExperiance } from '../../../redux/actions/experianceAction';
import { setSelectedTag, setMainSelectedTag } from "../../../redux/actions/tagAction";
import { setSearchText } from "../../../redux/actions/UniversalSearch";
import Skeleton from "@material-ui/lab/Skeleton"
import ExploreModal from "./Components/ExploreModal";
import { getProviderList } from "../../../redux/actions/profileActions"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function PopularTags() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const modal = useSelector(state => state.modal)
  const tagList = useSelector(state => state.tags?.allTags)
  const myselectedTag = useSelector(state => state.tags?.selectedTag)
  const searchText = useSelector(state => state.universal?.uniSearchText);
  const [selectedTag, setSeletedtag] = useState("All")

  const closeModal = () => {
    dispatch(exploreModal(false))
  }
  const openModal = () => {
    dispatch(exploreModal(true))
  }
  useEffect(() => {
    let data = localStorage.getItem("my_select")
    let mySelect = data
    
    dispatch(getTagsByTagType(null))
    dispatch(getProviderList());

  }, [])

  // const tagList = useSelector(state => state.tags.allTags)
  const handleSearchTag = (list) => {
    let tagId = [list.id]
    setSeletedtag(list.id)
    dispatch(setMainSelectedTag('All'));
    if (list.tagType === "days") {
      dispatch(getAllProgram(searchText ? searchText : "", tagId, "", list.name));
      dispatch(allExperiance(searchText ? searchText : "", "", tagId, list.name));
    }
    else {
      console.log("tagId", tagId)
      dispatch(getAllProgram(searchText ? searchText : "", tagId, ""));
      dispatch(allExperiance(searchText ? searchText : "", "", tagId));
    }
  }
  const handleAllTag = (id) => {
    setSeletedtag(id);
    dispatch(setSelectedTag([]));
    dispatch(setMainSelectedTag(id));
    dispatch(setSearchText(""));
    if (id === "Programs") {
      dispatch(getAllProgram("", [], ""));
    }
    else if (id === "Experience") {
      dispatch(allExperiance("", "", []));
    }
    else if (id === "Provider") {
      dispatch(getProviderList());
    }
    else {
      dispatch(getAllProgram("", [], ""));
      dispatch(allExperiance("", "", []));
      dispatch(getProviderList());
    }
  }
  const renderChip = (chipData = []) => {
    let data = " "
    data = chipData && chipData.map((tag, i) => {
      if (i < 3) {
        return (
          <Chip
            className="mx-4"
            variant="outlined" key={i}
            label={tag.name}
            color={tag.id === selectedTag ? "primary" : "default"}
            variant={tag.id === selectedTag ? "default" : "outlined"}
            onClick={() => handleSearchTag(tag)}

          />
        )
      }
    })
    return data
  }
  const renderPopularTag = (tagData = []) => {
    if (tagData && tagData.length < 3 && tagData.length > 0) {
      return renderChip(tagData)
    } else if (tagData && tagData.length > 3) {
      return <>
        {renderChip(tagData)}
        <Chip
          className="mx-4"
          variant="outlined"
          label={`+ ${tagData.length - 3} more`} />
      </>
    } else {
      return renderChip(tagData)
    }
  }

  const renderSelectedChip = (chipData = []) => {
    let data = " "
    data = chipData && chipData.map((tag, i) => {
      if (i < 3) {
        return (
          <Chip
            className="mx-4"
            variant="outlined" key={i}
            label={tag.name}
            color={tag.color}
            variant={tag.variant}
            onClick={() => handleSearchTag(tag)}

          />
        )
      }
    })
    return data
  }
  const renderSelectedTag = (tagData = []) => {
    if (tagData && tagData.length < 3 && tagData.length > 0) {
      return renderSelectedChip(tagData)
    } else if (tagData && tagData.length > 3) {
      return <>
        {renderSelectedChip(tagData)}
        <Chip
          className="mx-4"
          variant="outlined"
          label={`+ ${tagData.length - 3} more`} />
      </>
    } else {
      return renderSelectedChip(tagData)
    }
  }

  return (
    <div className={classes.root}>
      <ExploreModal
        open={modal.exploreModal}
        handleClose={closeModal}
        title="Filter Tags"
        tagLists={tagList}
      />

      <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="Filter"
        color="primary"
        onClick={openModal}
      />
      <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="ALL"
        color={selectedTag === "All" ? "primary" : "default"}
        variant={selectedTag === "All" ? "default" : "outlined"}
        onClick={() => handleAllTag("All")}
      />
      <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="Programs"
        color={selectedTag === "Programs" ? "primary" : "default"}
        variant={selectedTag === "Programs" ? "default" : "outlined"}
        onClick={() => handleAllTag("Programs")}
      />
      {/* <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="Experience"
        color={selectedTag === 'Experience' ? "primary" : "default"}
        variant={selectedTag === "Experience" ? "default" : "outlined"}
        onClick={() => handleAllTag('Experience')}
      /> */}
      <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="Provider"
        color={selectedTag === 'Provider' ? "primary" : "default"}
        variant={selectedTag === "Provider" ? "default" : "outlined"}
        onClick={() => handleAllTag('Provider')}
      />
       <Chip
        icon={<Icon>loyalty_sharp_icon</Icon>}
        label="Experience"
        color={selectedTag === 'Experience' ? "primary" : "default"}
        variant={selectedTag === "Experience" ? "default" : "outlined"}
        onClick={() => handleAllTag('Experience')}
      />
      {/* {myselectedTag.length > 0 ?

        (
          renderSelectedTag(myselectedTag)
        )
        : (tagList ? renderPopularTag(tagList) : (
          <>
            <Box width="100%">
              <Skeleton width="100%">
                <Typography>.</Typography>
              </Skeleton>
            </Box>

          </>
        ))
      } */}

    </div>
  );
}
