import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import WrappedRoutes from './WrappedRoutes'
import ResetPassword from '../../Account/ResetPassword/index';
import Footer from "../../Footer";

const Router = () => (
  <>
    <MainWrapper>
      <main>
        <Switch>

          <Route path="/404" component={NotFound404} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/" component={WrappedRoutes} />
         
        </Switch>
       
      </main>
     
    </MainWrapper>
    <Footer/>
  </>

);

export default Router;
