import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, Provider } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import { getProviderProfile } from "../../../../../redux/actions/profileActions"
import { getReview } from "../../../../../redux/actions/reviewAction";
import api from '../../../../../utils/apiClient';
import { Skeleton } from "@material-ui/lab"
import ChangePassModal from "../../../../../shared/components/ChangePassword/ChangePassModal";
import { changePasswordModal } from "../../../../../redux/actions/modalAction"
import { changePassword } from "../../../../../redux/actions/profileActions"
import CounterCard from "../../../../../shared/components/CounterCard"
import SimpleBackDrop from "../../../../../shared/components/SimpleBackDrop/BackDrop"
import SubscriptionCard from "./components/SubscriptionCard"
import ConfirmationModal from "../../../../../shared/components/ConfirmationModal";
import { reset } from "redux-form";
import {
  getPromoCodes,
  addPromoCode,
  getPromoCodeById,
  setPromoCodeById,
} from "../../../../../redux/actions/promoActions";
import {
  promoCodeModal,
  announcementModal,
  resourcesModal,
} from "../../../../../redux/actions/modalAction";
import {
  getAnnouncement,
  getAnnouncementDetails,
  setAnnouncementDetails,
} from "../../../../../redux/actions/AnnouncementAction";
import AddAnnouncement from "./components/AddAnnouncement";
import AddPromoCode from "./components/AddPromoCode";
import {
  showFailedNoti,
  showSucessNoti,
} from "../../../../../redux/actions/notificationAction";
import {announcementType} from "config/announcementType"
const Profile = () => {

  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal)
  const [refreshAnnouncement, setRefreshAnnouncement] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { providerProfile, providerLoading } = useSelector(state => state.profile)
  const[changePassLoading,setChangePassLoading] = useState(false);
  const { announcementList } = useSelector((state) => state.announcement);
  const [currentId, setCurrentId] = React.useState({});
  const [deleteAnnouncementModal, setDeleteAnnouncementModal] = useState(false);
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const { promocodeList } = useSelector((state) => state.promocode);
  useEffect(() => {

    getproviderprofileDetails()
  }, []);
  const getproviderprofileDetails = () => {
    dispatch(getProviderProfile())
    dispatch(getAnnouncement())
    dispatch(getReview())
    dispatch(getPromoCodes())
  }
  const handleClose = () => {

      setDeleteAnnouncementModal(false);
      setDeletePromoModal(false)
  };  

  const closeChangePassModal = () => {
    dispatch(changePasswordModal(false))
  }
  const handleChangePassModal = () => {
    dispatch(changePasswordModal(true))
    
  }
  const confirmChangePass = async (val) => {
    setChangePassLoading(true);
    let data = {
      oldPassword: val.oldPassword,
      newPassword: val.newPassword
    }
    dispatch(changePassword(data))
    dispatch(changePasswordModal(false))
    setChangePassLoading(false);
  }
  const openAnnouncementModal = () => {
    dispatch(announcementModal(true));
  };
  const closeAnnouncementModal = () => {
    dispatch(setAnnouncementDetails({}));
    setIsEdit(false);
    dispatch(announcementModal(false));
    dispatch(reset("announcementForm"));
  };
   // Refresh Announcement Card
   const handleOnRefreshAnnouncement = () => {
    setRefreshAnnouncement(true);
    dispatch(getAnnouncement()).then(() =>
      setTimeout(() => setRefreshAnnouncement(false), 500)
    );
  };
  
    // to get program id and student id
    const getById = (values) => {
      let id = []
      values.map((p) => {
        id.push(p.id)
      })
      return id
    }
  // Handle Announcement
  const handleAnnouncement = (values) => {
    let submitData = {
      id: values.id,
      announcementName: values.announcementName,
      announcementFor:values.announcementFor.id,
      programId: getById(values.programId),
      studentId: getById(values.studentId),
      allStudents: values.announcementFor.id === announcementType.AllStudents?true :false,
      description: values.description,
    };

    api.announcement
      .addAnnouncement(submitData)
      .then((res) => {
        if (res.data) {
          setIsEdit(false);
          closeAnnouncementModal();
          dispatch(reset("announcement-form"));
          dispatch(showSucessNoti(res.data.message));
          handleOnRefreshAnnouncement();
        } else {
          setIsEdit(false);
          dispatch(showFailedNoti(res.data.message));
        }
      })
      .catch((err) => {
        setAnnouncementDetails({});
        setIsEdit(false);
        dispatch(showFailedNoti(err.message));
        dispatch(reset("announcement-form"));
      });
  };
   // Edit Announcement Details
   const handleEditAnnouncement = (id) => {
    dispatch(getAnnouncementDetails(id)).then(() => {
      setIsEdit(true);
      openAnnouncementModal();
    });
  };
   // Delete Annouoncement
   const handleDeleteAnnouncement = () => {
    setDeleteAnnouncementModal(false);
    let id = currentId;
   
    api.announcement
      .deleteAnnouncement(id).then(res =>{
        if(res.data){
          dispatch(showSucessNoti(res.data.message))
        }
      })
      .then(() => dispatch(getAnnouncement()))
      .then(() => setTimeout(() => setRefreshAnnouncement(false), 1000));
  };
  const confirmDeleteAnnouncement = (id) => {
    setCurrentId(id);
    setDeleteAnnouncementModal(true);
  };

    //API Implementaion to manage Promo Code.
    const promoCodeSubmit = (values) => {
      let promoCodeData = {
        id: values.id,
        codeName: values.codeName,
        code: values.code,
        discountPrice: parseInt(values.discountPrice),
        validUpto: new Date(values.validUpto).toISOString(),
      };
      api.promoCode
        .managePromoCode(promoCodeData, true)
        .then((res) => {
          setIsEdit(false);
          closePromoCodeModal();
          dispatch(reset("promocodeForm"));
          dispatch(showSucessNoti(res.data.message));
          dispatch(getPromoCodes())
        })
        .catch((err) => {
          setIsEdit(false);
          dispatch(setPromoCodeById({}));
          dispatch(showFailedNoti(err));
        });
    };
  const closePromoCodeModal = () => {
    dispatch(setPromoCodeById({}));
    dispatch(promoCodeModal(false));
    setIsEdit(false);
    dispatch(reset("promocodeForm"));
  };
  const openPromoCodeModal = () => {
    dispatch(promoCodeModal(true));
  };
    // Edit Promo Code Details
    const handleEditPromo = (id) => {
      dispatch(getPromoCodeById(id)).then(() => {
        setIsEdit(true);
        dispatch(promoCodeModal(true));
      });
    };
     // Delete Promo Code
  const handleDeletePromo = () => {
    setDeletePromoModal(false);

    let id = currentId;
    api.promoCode
      .deletePromoCode(id)
      .then(res =>{
        if(res.data){
          dispatch(showSucessNoti(res.data.message))
        }
      })
      .then(() => dispatch(getPromoCodes()))

  };
  const confirmDeletePromo = (id) => {
    setCurrentId(id);
    setDeletePromoModal(true);
  };
  return (
    <>
 
    {changePassLoading && (
          <SimpleBackDrop open={changePassLoading} />
        )}
      <Row className="mt-0">
        <Col xl={4} lg={4} md={12} sm={12} xs={12} >
          <Row className="mt-0 mx-0">
            <ProfileMain
              providerProfile={providerProfile}
              handleChangePassModal={handleChangePassModal}
              loading={providerLoading}
              
            />
          </Row>
          <Row className="mt-0 m-auto">
            <div className="w-100">
              {/* <div className="mb-10">
                <SubscriptionCard
                />
              </div> */}
              <div className="mb-10">
                <CounterCard
                  color="color1"
                  count={providerProfile.experience}
                  label="EXPERIENCE"
                  icon="mood"
                />
              </div>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.programs}
                  color="color2"
                  label="PROGRAMS"
                  icon="card_membership_icon "
                />
              </div>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.registrationCount}
                  color="color3"
                  label="REGISTRATIONS"
                  icon="create"
                />
              </div>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.totalStudents}
                  color="color4"
                  label="STUDENTS"
                  icon="emoji_people"
                />
              </div>
            </div>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={12} sm={12} xs={12} >
          
          <ProfileTabs
          //Announcement 
          onRefresh={handleOnRefreshAnnouncement}
          openAnnouncementModal={openAnnouncementModal}
          closeAnnouncementModal={closeAnnouncementModal}
          announcementList={announcementList}
          handleEditAnnouncement={(id) => handleEditAnnouncement(id)}
          handleDeleteAnnouncement={(id) => confirmDeleteAnnouncement(id)}
          //Promocode
          openPromoCodeModal={openPromoCodeModal}
          closePromoCodeModal={closePromoCodeModal}
          promoCodeSubmit={promoCodeSubmit}
          promocodeList={promocodeList}
          handleEditPromo={(id) => handleEditPromo(id)}
          handleDeletePromo={(id) => confirmDeletePromo(id)}
          />
          {modal.promoCode && (
          <AddPromoCode
            promoCodeSubmit={promoCodeSubmit}
            isPromoOpen={modal.promoCode}
            closePromoModal={closePromoCodeModal}
            isEdit={isEdit}
          />
        )}
        </Col>
        <AddAnnouncement 
          handleAnnouncement={handleAnnouncement}
          isAnnouncementOpen={modal.announcement}
          isEdit={isEdit}
          closeAnnouncementModal={closeAnnouncementModal}
        />
        <ChangePassModal
          title="Change Password"
          isOpen={modal.changePasswordModal}
          closeModal={closeChangePassModal}
          confirmChangePass={confirmChangePass}
          onSubmit={confirmChangePass}
          changePassLoading = {changePassLoading}
        />
        <ConfirmationModal
        color="danger"
        title="Delete Confirmation"
        message="Are you sure want to Delete this Announcement?"
        colored=""
        header=""
        isOpen={deleteAnnouncementModal}
        closeModal={handleClose}
        onYesClick={handleDeleteAnnouncement}
      />
        <ConfirmationModal
        color="danger"
        title="Delete Confirmation"
        message="Are you sure you want to Delete this Promo Code?"
        colored=""
        header=""
        isOpen={deletePromoModal}
        closeModal={handleClose}
        onYesClick={handleDeletePromo}
      />
      </Row>
    </>
  )

};

export default Profile;
