import React from 'react';
import { connect } from "react-redux"
import {
  Modal, ModalHeader, ModalBody, Alert
} from 'reactstrap';

import LogInForm from '../../../../shared/components/loginForm/LogInForm';
import AuthGoogleBtn from "containers/Account/AuthBtn/googleAuthBtn"
import AuthFacebookeBtn from "containers/Account/AuthBtn/fbAuthBtn"
import GoogleRecaptcha from "shared/components/auth/GoogleRecaptcha"
import MicrosoftLogin from "containers/Account/AuthBtn/MicrosoftLogin"

const ModalLoginForm = ({
  title,
  isOpen,
  handleLogin,
  error,
  closeModal,
  emailLogin,
  passwordLogin,
  errorMessage,
  errorMsg,
}) => (
  <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
    <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
    <ModalBody className="login-form">
      <Alert
        color="danger"
        isOpen={!!errorMessage || !!errorMsg}
      >
        {errorMessage}
        {errorMsg}
      </Alert>
      <div className="my-10 loginggle">
        <AuthGoogleBtn form="modal_login" roleCode={0} />
      </div>
      <div className="my-10 loginfb">
        <AuthFacebookeBtn form="modal_login" roleCode={0} />
      </div>
      <div className="my-10">
        <MicrosoftLogin form="modal_login" roleCode={0} />
      </div>
      <div className="account__or my-20">
        <p>Or</p>
      </div>
      <div className="mt-10">
        <LogInForm
          handleLogin={handleLogin}
          errorMessage={error}
          form="modal_login"
          fieldUser="E-mail"
          typeFieldUser="email"
          emailLogin={emailLogin}
          passwordLogin={passwordLogin}
        />
      </div>
      <GoogleRecaptcha />
    </ModalBody>

  </Modal>
);
export default connect(state => ({
  errorMessage: state.user.error
}))(ModalLoginForm);
