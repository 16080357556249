import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, ButtonToolbar, Alert } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Image from "./Image";
import { useSelector } from "react-redux";
import { Button, Typography, Icon, Chip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import axios from "axios";
import {
  loginModal,
  programRegiterModal,
  reviewModal,
  contactProviderModal,
} from "../../../redux/actions/modalAction";
import { enrolledProgramStudent } from "../../../redux/actions/enrollmentAction";
import {
  dateFormat,
  ArrayToString,
  stringToTime,
} from "../../../shared/helpers";
import {
  getProviderProfileId,
  addFavorite,
} from "../../../redux/actions/profileActions";
import { getSearchItem } from "../../../redux/actions/UniversalSearch";
import { showSucessNoti } from "../../../redux/actions/notificationAction";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { authRoles } from "../../../config/authroles";
import { getProgramById } from "../../../redux/actions/programActions";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import { getMyReview } from "../../../redux/actions/reviewAction";
import RegisterStudents from "./RegisterStudents";
import ListingCard from "./ListingCard";
import SubscribeBox from "containers/Layout/newsetter_box/subscribeBox";

import { Button as BootstrapButton } from "react-bootstrap";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Form } from "react-bootstrap";

export default function DetailsCard({ programDetails, detailsLoading, type }) {
  const auth = useSelector((state) => state.user);
  const [showList, setShowList] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [subscriberEmail, setSubscriberEmail] = useState("");

  function handleSubscriberOnChange(value) {
    console.log("subscriber change handler ", value);
    setSubscriberEmail(value);
  }

  const handleRegiterStudent = (id) => {
    if (auth.isAuthenticated) {
      if (id == "NA" && programDetails.externalLink !== "") {
        window.open(programDetails.externalLink);
      } else if (programDetails.registredStudents < programDetails.capacity) {
        console.log("programDetails", programDetails);
        dispatch(programRegiterModal(true));
        dispatch(enrolledProgramStudent(id));
      } else {
        dispatch(programRegiterModal(false));
      }
    } else {
      dispatch(loginModal(true));
    }
  };
  const handleReview = () => {
    dispatch(getMyReview(programDetails.id)).then(() => {
      dispatch(reviewModal(true));
    });
  };
  const handleSubscribeToNewsLetter = () => {
    toggleModal();
  };
  const viewContact = () => {
    dispatch(getProviderProfileId(programDetails.createdBy));
    dispatch(contactProviderModal(true));
  };
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(showSucessNoti("Link copied"));
  };

  const addfavoriteProvider = (id) => {
    dispatch(addFavorite(id)).then(() => {
      dispatch(getProgramById(programDetails.id));
    });
  };
  const renderLocations = () => {
    let location = [];
    if (type === "program") {
      programDetails.locations.map((loc) => {
        location.push(loc.name);
      });
      return location.join(", ");
    } else {
      return programDetails.location;
    }
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  function toggleModal() {
    setModalOpen(!isModalOpen);
  }

  async function subscribe(e) {
    e.preventDefault();
    let email = subscriberEmail;
    console.log("Email subscribe ", email);
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //     'Authorization': "Bearer " + token
    //   }
    // };
    //
    let body = { email: email };

    await axios.post(window.apiUrl + `Common/add-subscriber`, body).then(
      (res) => {
        console.log("subscribe response ", res);
        setIsSubscribed(true);
        handleClose();
        setSubscriberEmail("");
      },
      (error) => {
        console.log("upload error : " + error.message);
        setSubscriberEmail("");
        setIsSubscribed(false);
      }
    );
  }
  let path2 = window.location.href;
  const bull = <span className="bullet">•</span>;
  console.log(programDetails);
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12} id="html-2-pdfwrapper">
        <Card>
          <CardBody>
            <div className="mb-10">
              <h4 className="text-black mb-10 capitalize">
                {detailsLoading ? (
                  <Skeleton variant="text" />
                ) : type === "program" ? (
                  programDetails.programName
                ) : (
                  type === "experience" && programDetails.name
                )}
                {type === "provider" && programDetails.organisationName}
              </h4>
              {type === "experience" ? (
                <div>
                  {programDetails.activityId &&
                    programDetails.activityId.map((tag, i) => (
                      <Chip
                        label={tag.name}
                        size="small"
                        variant="outlined"
                        color="primary"
                        className="mx-4"
                        key={i}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  {programDetails.activity &&
                    programDetails.activity.map((tag, i) => (
                      <Chip
                        label={tag.name}
                        size="small"
                        variant="outlined"
                        color="primary"
                        className="mx-4"
                        key={i}
                      />
                    ))}
                </div>
              )}
              {/* <div>
                {programDetails.activityId && programDetails.activityId.map((tag, i) => (
                  <Chip
                    label={tag.name}
                    size="small"
                    variant="outlined"
                    color="primary"
                    className="mx-4"
                    key={i}
                  />
                ))}
              </div> */}
            </div>
            <div className="product-card" id="toPdf">
              <Image
                activities={programDetails.activity}
                programName={programDetails.programName}
                src={
                  type === "program"
                    ? programDetails.programPhoto
                    : type === "experience"
                    ? programDetails.experiencePhoto
                    : programDetails.logoUrl
                }
                detailsLoading={detailsLoading}
                organisationName={
                  type !== "provider" ? programDetails.organisationName : " "
                }
                rating={programDetails.rating}
                avatar={programDetails.providerImage}
                type={type}
              />
              <div className="product-card__info">
                {/* <Profile></Profile> */}

                <ButtonToolbar>
                  {auth.isAuthenticated &&
                  programDetails.registrationFlag &&
                  auth.role === authRoles.Parents ? (
                    // auth.isAuthenticated &&
                    //   //!programDetails.isEnrolled &&
                    //   programDetails.registrationFlag &&
                    //   auth.role === authRoles.Parents && //(programDetails.capacity > programDetails.registredStudents) &&
                    <Button
                      variant="outlined"
                      onClick={() => handleRegiterStudent(programDetails.id)}
                      color={
                        programDetails.registredStudents <
                        programDetails.capacity
                          ? "primary"
                          : "grey"
                      }
                    >
                      {programDetails.registredStudents <
                      programDetails.capacity
                        ? "Register"
                        : "Registrations Full!"}
                    </Button>
                  ) : !auth.isAuthenticated &&
                    programDetails.registrationFlag ? (
                    <Button
                      variant="outlined"
                      onClick={() => dispatch(loginModal(true))}
                      color={
                        programDetails.registredStudents <
                        programDetails.capacity
                          ? "primary"
                          : "grey"
                      }
                    >
                      {console.log("path", window.location.href)}
                      {programDetails.registredStudents <
                      programDetails.capacity
                        ? "Register"
                        : "Registrations Full!"}
                    </Button>
                  ) : null}

                  {!programDetails.registrationFlag &&
                    programDetails.externalLink && (
                      <Button
                        variant="outlined"
                        onClick={() => handleRegiterStudent("NA")}
                        color="primary"
                      >
                        External Link
                      </Button>
                    )}
                  {!detailsLoading && type === "provider" && (
                    <Button
                      variant="outlined"
                      onClick={() => setShowList(!showList)}
                      color="primary"
                    >
                      View Listing
                    </Button>
                  )}
                  {type === "provider" && programDetails.website && (
                    <Button
                      variant="outlined"
                      onClick={() => window.open(programDetails.website)}
                      color="primary"
                    >
                      Website
                    </Button>
                  )}
                  {type !== "provider" && (
                    <Button
                      onClick={handleShare}
                      variant="outlined"
                      color="primary"
                    >
                      Share
                    </Button>
                  )}

                  {auth.role === authRoles.Parents &&
                    (type === "program" || type === "experience") && (
                      <Button
                        variant="outlined"
                        onClick={viewContact}
                        color="primary"
                      >
                        Contact
                      </Button>
                    )}

                  {programDetails.isEnrolled &&
                    auth.role === authRoles.Parents && (
                      <Button
                        variant="outlined"
                        onClick={handleReview}
                        color="primary"
                      >
                        Review
                      </Button>
                    )}
                  <Button
                    variant="outlined"
                    onClick={handleShow}
                    color="primary"
                  >
                    I'm Interested
                  </Button>
                  {/* <SubscribeBox  isOpen={ isModalOpen} /> */}
                  {/* <Modal isOpen = {false}></Modal> */}
                  {/* <BootstrapButton variant="primary" onClick={handleShow}>
        Launch demo modal
      </BootstrapButton> */}
                  <Alert color="green" isOpen={isSubscribed}>
                    Thank you for your interests. DWUL will check if the
                    provider is accepting registrations.
                  </Alert>
                  <BootstrapModal show={show} onHide={handleClose}>
                    <Form>
                      <BootstrapModal.Header closeButton>
                        <BootstrapModal.Title>
                          Subscribe to Newsletter
                        </BootstrapModal.Title>
                      </BootstrapModal.Header>
                      <BootstrapModal.Body>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={subscriberEmail}
                            onChange={(e) => {
                              e.preventDefault();
                              handleSubscriberOnChange(e.target.value);
                            }}
                          />
                          {isSubscribed ? (
                            <Form.Text className="text-muted">
                              Thank You
                            </Form.Text>
                          ) : (
                            <Form.Text className="text-muted">
                              We'll never share your email with anyone else.
                            </Form.Text>
                          )}
                        </Form.Group>
                      </BootstrapModal.Body>
                      <BootstrapModal.Footer>
                        <BootstrapButton
                          variant="secondary"
                          onClick={handleClose}
                        >
                          Close
                        </BootstrapButton>
                        <BootstrapButton
                          variant="primary"
                          type="submit"
                          onClick={subscribe}
                        >
                          Subscribe
                        </BootstrapButton>
                      </BootstrapModal.Footer>
                    </Form>
                  </BootstrapModal>
                  {auth.isAuthenticated &&
                    auth.role === authRoles.Parents &&
                    programDetails.isEnrolled && (
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          addfavoriteProvider(programDetails.createdBy)
                        }
                      >
                        {programDetails.isFavorite &&
                        programDetails.isFavorite === true ? (
                          <FavoriteIcon />
                        ) : (
                          <FavoriteBorderIcon />
                        )}
                      </IconButton>
                    )}
                </ButtonToolbar>
                {programDetails.registrationFlag === false ? (
                  <div>
                    <p style={{ color: "red" }}>
                      Registration is closed, contact the program provider for
                      any inquiries
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
                {type !== "provider" && programDetails.cost > 0 && (
                  <div className="mb-10">
                    <Typography variant="h3">
                      {detailsLoading ? (
                        <Skeleton variant="text" width="20%" />
                      ) : (
                        <>
                          {programDetails.cost > 0
                            ? `CA$${programDetails.cost}`
                            : ""}
                        </>
                      )}
                      {/* <span className="ml-8 font-size-13">per {programDetails.sessionsForCost > 1 ? programDetails.sessionsForCost : ""} session</span> */}
                    </Typography>
                  </div>
                )}
                <div className="mb-10 flex flex-middle">
                  {detailsLoading ? (
                    <Skeleton variant="text" width="20%" />
                  ) : (
                    <>
                      {ArrayToString(programDetails.locations).length > 0 && (
                        <>
                          <Icon>language</Icon>
                          <h5 className=" bold-text mx-4">
                            City:
                            {type === "program" || type === "provider" ? (
                              <span className="text-muted mx-4">
                                {ArrayToString(programDetails.locations).join(
                                  ", "
                                )}
                              </span>
                            ) : (
                              <span className="text-muted mx-4">
                                {programDetails.location}
                              </span>
                            )}
                          </h5>
                        </>
                      )}
                    </>
                  )}
                </div>

                {type === "program" && programDetails.programLocation && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <LocationOnIcon />
                        <h5 className=" bold-text mx-4">
                          Location:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.programLocation}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {/* minimumAge  maximumAge */}

                {type === "program" && programDetails.placeName && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <LocationOnIcon />
                        <h5 className=" bold-text mx-4">
                          Place Name:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.placeName}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {type === "program" && programDetails.streetAddress && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <LocationOnIcon />
                        <h5 className=" bold-text mx-4">
                          Street Address:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.streetAddress}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {type === "program" && programDetails.room && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <LocationOnIcon />
                        <h5 className=" bold-text mx-4">
                          Room:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.room}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {/* {type !== "provider" && ((programDetails.regulerDates && programDetails.regulerDates.length)
                  || programDetails.numberOfSessions > 0) && (<div className="mb-10 flex flex-middle">
                    {detailsLoading ?
                      <Skeleton variant="text" width="20%" />
                      : (<>
                        <HourglassEmptyIcon />
                        <h5 className=" bold-text mx-4">Total Sessions: <span className="text-muted mx-4">
                          {type === "program" ?
                            programDetails.regulerDates.length + programDetails.specialDates.length :
                            programDetails.numberOfSessions}
                        </span> </h5>
                      </>)
                    }
                  </div>)} */}

                {type !== "provider" && programDetails.capacity > 0 && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <EventSeatIcon />
                        <h5 className=" bold-text mx-4">
                          Capacity:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.capacity}{" "}
                            {type === "program" && (
                              <>
                                (
                                {programDetails.capacity -
                                  programDetails.registredStudents}{" "}
                                seats remaining)
                              </>
                            )}
                          </span>{" "}
                          |{" "}
                        </h5>

                        <HourglassEmptyIcon />
                        <h5 className=" bold-text mx-4">
                          Total Sessions:{" "}
                          <span className="text-muted mx-4">
                            {type === "program"
                              ? programDetails.regulerDates.length +
                                programDetails.specialDates.length
                              : programDetails.numberOfSessions}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {type === "experience" ? (
                  <div className="mb-10 flex flex-middle">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.ageGroup.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Icon>group_icon</Icon>
                        <h5 className=" bold-text mx-4">
                          Age Group:
                          <span className="text-muted mx-4">
                            {programDetails.ageGroup &&
                              `${programDetails.ageGroup}`}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="mb-10 flex flex-middle">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.ageGroup.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Icon>group_icon</Icon>
                        <h5 className=" bold-text mx-4">
                          Age Group:
                          <span className="text-muted mx-4">
                            {programDetails.ageGroup &&
                              `${programDetails.ageGroup}`}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {type === "experience" ? (
                  <div className="mb-10 flex flex-middle">
                    {!detailsLoading &&
                    type === "program" &&
                    programDetails &&
                    programDetails.categoryId.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Icon>category_icon</Icon>
                        <h5 className=" bold-text mx-4">
                          Category Tags:
                          <span className="text-muted mx-4">
                            {programDetails.categoryId &&
                              `${ArrayToString(programDetails.categoryId).join(
                                ", "
                              )}`}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="mb-10 flex flex-middle">
                    {!detailsLoading &&
                    type === "program" &&
                    programDetails &&
                    programDetails.categoryTags.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Icon>category_icon</Icon>
                        <h5 className=" bold-text mx-4">
                          Category Tags:
                          <span className="text-muted mx-4">
                            {programDetails.categoryTags &&
                              `${ArrayToString(
                                programDetails.categoryTags
                              ).join(", ")}`}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {type === "provider" ? (
                  <div className="mb-10 flex flex-middle">
                    <h5 className="bold-text mx-4">
                      Why do you love what you do?
                    </h5>
                    <span className="text-muted mx-4">
                      {programDetails.whyDoYouLuvWhatUDo}
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                {type === "program" && (
                  <div className="mb-10 ">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.regulerDates === 0 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>event_icon</Icon>
                        <h5 className="bold-text mx-4">Regular Dates :</h5>
                      </div>
                    )}
                    {detailsLoading ? (
                      <>
                        <Skeleton variant="text" width="20%" height={10} />
                        <Skeleton variant="text" width="20%" height={10} />
                        <Skeleton variant="text" width="20%" height={10} />
                      </>
                    ) : (
                      programDetails.regulerDates.map((dates, i) => (
                        <Typography key={i} variant="body2" className="mx-20">
                          {dateFormat(dates, true)}
                        </Typography>
                      ))
                    )}
                  </div>
                )}

                {type === "program" && programDetails.regularStartTime && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <ScheduleIcon />
                        <h5 className=" bold-text mx-4">
                          Regular start time:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.regularStartTime
                              ? stringToTime(programDetails.regularStartTime)
                              : "NA"}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {type === "program" && programDetails.regularEndTime && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <ScheduleIcon />
                        <h5 className=" bold-text mx-4">
                          Regular end time:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.regularEndTime
                              ? stringToTime(programDetails.regularEndTime)
                              : "NA"}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {type === "program" && (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.specialDates.length === 0 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>event_icon</Icon>
                        <h5 className="bold-text mx-4">Special Dates :</h5>
                      </div>
                    )}
                    {detailsLoading ? (
                      <>
                        <Skeleton variant="text" width="20%" height={10} />
                        <Skeleton variant="text" width="20%" height={10} />
                        <Skeleton variant="text" width="20%" height={10} />
                      </>
                    ) : (
                      programDetails &&
                      programDetails.specialDates.map((dates, i) => (
                        <Typography key={i} variant="body2" className="mx-20">
                          {dateFormat(dates)}
                        </Typography>
                      ))
                    )}
                  </div>
                )}

                {type === "program" && programDetails.specialStartTime && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <ScheduleIcon />
                        <h5 className=" bold-text mx-4">
                          Special Start time:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.specialStartTime
                              ? stringToTime(programDetails.specialStartTime)
                              : "NA"}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {type === "program" && programDetails.specialEndTime && (
                  <div className="mb-10 flex flex-middle">
                    {detailsLoading ? (
                      <Skeleton variant="text" width="20%" />
                    ) : (
                      <>
                        <ScheduleIcon />
                        <h5 className=" bold-text mx-4">
                          Special End time:{" "}
                          <span className="text-muted mx-4">
                            {programDetails.specialEndTime
                              ? stringToTime(programDetails.specialEndTime)
                              : "NA"}{" "}
                          </span>{" "}
                        </h5>
                      </>
                    )}
                  </div>
                )}

                {/* {type === "program" && (<div className="mb-10">
                  {!detailsLoading
                    && programDetails
                    && programDetails.noSession && programDetails.noSession.length === 0 ?
                    "" : <div className="flex flex-middle mb-4">
                      <Icon>event_icon</Icon>
                      <h5 className="bold-text mx-4">
                        No Sessions :
                      </h5>
                    </div>
                  }
                  <Typography variant="body2" className="mx-20">
                    {detailsLoading
                      ?
                      <Skeleton variant="text" width="20%" height={10} />
                      : programDetails && programDetails.noSession && programDetails.noSession.map((dates, i) => (
                        <Typography key={i} variant="body2" className="mx-20">
                          {dateFormat(dates, false)}
                        </Typography>
                      ))
                    }
                  </Typography>
                </div>)} */}

                {type === "program" && (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.noSession &&
                    programDetails.noSession.length === 0 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>event_icon</Icon>
                        <h5 className="bold-text mx-4">No Sessions :</h5>
                        {detailsLoading ? (
                          <>
                            <Skeleton variant="text" width="20%" height={10} />
                            <Skeleton variant="text" width="20%" height={10} />
                            <Skeleton variant="text" width="20%" height={10} />
                          </>
                        ) : (
                          programDetails &&
                          programDetails.noSession &&
                          programDetails.noSession.map((dates, i) => (
                            <Typography
                              key={i}
                              variant="body2"
                              className="mx-20"
                            >
                              {dateFormat(dates)}
                            </Typography>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                )}

                {type === "program" &&
                  programDetails.minimumAge &&
                  programDetails.maximumAge && (
                    <div className="mb-10 flex flex-middle">
                      {detailsLoading ? (
                        <Skeleton variant="text" width="20%" />
                      ) : (
                        <>
                          <Icon>event_icon</Icon>
                          <h5 className=" bold-text mx-4">
                            Age Range :{" "}
                            <span className="text-muted mx-4">
                              {programDetails.minimumAge
                                ? programDetails.minimumAge
                                : 0}{" "}
                              -{" "}
                              {programDetails.maximumAge
                                ? programDetails.maximumAge
                                : 0}
                            </span>{" "}
                          </h5>
                        </>
                      )}
                    </div>
                  )}

                {type === "experience" && (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.availability ? (
                      <div className="flex flex-middle mb-4">
                        <Icon>event_icon</Icon>
                        <h5 className="bold-text mx-4">Days of the Week :</h5>
                      </div>
                    ) : (
                      ""
                    )}
                    <Typography variant="body2" className="mx-20">
                      {detailsLoading ? (
                        <Skeleton variant="text" width="20%" height={10} />
                      ) : (
                        programDetails &&
                        programDetails.availability && (
                          <Typography variant="body2" className="mx-20">
                            {programDetails.availability}
                          </Typography>
                        )
                      )}
                    </Typography>
                  </div>
                )}
                <div className="mb-10">
                  {!detailsLoading &&
                  programDetails &&
                  programDetails.description &&
                  programDetails.description.length < 1 ? (
                    ""
                  ) : (
                    <div className="flex flex-middle mb-4">
                      <Icon>notes</Icon>
                      <h5 className="bold-text mx-4">
                        {type !== "provider" ? "Program Details:" : "About:"}
                      </h5>
                    </div>
                  )}

                  {detailsLoading ? (
                    <React.Fragment>
                      <Skeleton
                        animation="wave"
                        height={10}
                        className="mb-10"
                      />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                  ) : (
                    // : (<h5 className="mx-20" dangerouslySetInnerHTML={{ __html: programDetails.description }}></h5>)
                    <h5
                      className="mx-20"
                      dangerouslySetInnerHTML={{
                        __html:
                          type === "experience"
                            ? programDetails.programDetails
                            : programDetails.description,
                      }}
                    ></h5>
                  )}
                </div>
                {/* here */}
                {type === "experience" ? (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.time &&
                    programDetails.time.length < 1 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>access_time</Icon>
                        <h5 className="bold-text mx-4">{"Time:"}</h5>
                      </div>
                    )}

                    {detailsLoading ? (
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={10}
                          className="mb-10"
                        />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    ) : (
                      // : (<h5 className="mx-20" dangerouslySetInnerHTML={{ __html: programDetails.description }}></h5>)
                      <h5
                        className="mx-20"
                        dangerouslySetInnerHTML={{
                          __html: programDetails.time,
                        }}
                      ></h5>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                {type === "experience" ? (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.date &&
                    programDetails.date.length < 1 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>event_icon</Icon>
                        <h5 className="bold-text mx-4">{"Date"}</h5>
                      </div>
                    )}

                    {detailsLoading ? (
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={10}
                          className="mb-10"
                        />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    ) : (
                      // : (<h5 className="mx-20" dangerouslySetInnerHTML={{ __html: programDetails.description }}></h5>)
                      <h5
                        className="mx-20"
                        dangerouslySetInnerHTML={{
                          __html: programDetails.date,
                        }}
                      ></h5>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                {type === "experience" ? (
                  <div className="mb-10">
                    {!detailsLoading &&
                    programDetails &&
                    programDetails.address &&
                    programDetails.address.length < 1 ? (
                      ""
                    ) : (
                      <div className="flex flex-middle mb-4">
                        <Icon>map_icon</Icon>
                        <h5 className="bold-text mx-4">{"Address:"}</h5>
                      </div>
                    )}

                    {detailsLoading ? (
                      <React.Fragment>
                        <Skeleton
                          animation="wave"
                          height={10}
                          className="mb-10"
                        />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    ) : (
                      // : (<h5 className="mx-20" dangerouslySetInnerHTML={{ __html: programDetails.description }}></h5>)
                      <h5
                        className="mx-20"
                        dangerouslySetInnerHTML={{
                          __html: programDetails.address,
                        }}
                      ></h5>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                {!detailsLoading &&
                  (type === "program" || type === "experience") &&
                  (programDetails.flyer || programDetails.file) && (
                    <div className="mt-10">
                      <Link
                        to={{
                          pathname:
                            type === "program"
                              ? programDetails.flyer
                              : programDetails.file,
                        }}
                        target="_blank"
                        download
                      >
                        <Button variant="outlined" color="primary">
                          Download flyer
                        </Button>
                      </Link>
                    </div>
                  )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {auth.isAuthenticated &&
        (auth.role === authRoles.Provider || auth.role === authRoles.Admin) &&
        type === "program" &&
        programDetails &&
        programDetails.id && (
          <RegisterStudents id={programDetails.id} key={programDetails.id} />
        )}
      {programDetails && programDetails.id && showList && (
        <ListingCard id={programDetails.id} />
      )}
    </Row>
  );
}

//  {"organisationName":"",
//   "email": "info@dowhatuluv.ca", "contactNo": "",
//     "id": 33,
//     "title": "test experience",
//     "name": "test provider",
//     "experiencePhoto": "",
//     "externalLink": "https://google.com",
//       "address": "test", "locationId": [{ "id": 1, "name": "BC" }], "locationString": null, "activityId": [{ "id": 16, "name": "Music" }], "activityString": null, "date": "2nd June 2022", "time": "11:00 AM", "ageGroup": "23", "cost": 10, "programDetails": "<p>test description</p>\n", "categoryId": [{ "id": 76, "name": "Online Virtual Programs" }], "categoryString": null
// }
