import { get } from './base/index';
import { post } from "./base/index"

// Available api requests here: "https://documenter.getpostman.com/view/8854915/SzS7R6uu?version=latest"
export default {
    updateProfile: (data, headers) => {
        return post(`${window.apiUrl}UserProfile/update-user-profile`, data, headers);
    },
    updateProviderProfile: (data, headers) => {
        return post(`${window.apiUrl}UserProfile/update-provider-profile`, data, headers)
    },
    // getParentProfile: () => {
    //     return get(`${window.apiUrl}UserProfile/get-parent-profile`, {}, true)
    // },
    getParentProfile: (id) => {
        return get(`${window.apiUrl}UserProfile/get-parent-profile?ProgramId=${id}`, {}, true)
    },
    updateParentProfile: (data, headers) => {
        const body = data;
        return post(`${window.apiUrl}UserProfile/update-parent-profile`, body, true)
    },
    getUserProfileTotalCount: () => {
        return get(`${window.apiUrl}UserProfile/total-count`, {}, false)
    },
    getProviderProfileById: (id, headers) => {
        return get(`${window.apiUrl}UserProfile/get-provider-profile?Id=${id}`, {}, headers);
    },
    getParentProfileById: (id) => {
        return get(`${window.apiUrl}UserProfile/parent-profile?Id=${id}`, {}, true);
    },
    favorite: (id, headers) => {

        return post(`${window.apiUrl}UserProfile/update-parent-fevorites?Id=${id}`, {}, headers)
    },
    changePassword: (data, headers) => {
        // debugger
        return post(`${window.apiUrl}UserProfile/change-password`, data, headers)
    },
    createUser: (data, headers) => {
        return post(`${window.apiUrl}UserProfile/create-user`, data, headers)
    },
    activate: (id) => {
        // debugger
        return post(`${window.apiUrl}UserProfile/activate-user?userId=${id}`, {}, true)
    },
    deactivate: (id) => {
        // debugger
        return post(`${window.apiUrl}UserProfile/deactivate-user?userId=${id}`, {}, true)
    },
    adminProfile: () => {
        return get(`${window.apiUrl}UserProfile/admin-profile`, {}, true)
    },
    updateAdmin: (data) => {
        return post(`${window.apiUrl}UserProfile/update-admin-profile`, data, true)
    }
}