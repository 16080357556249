import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import MessagesTable from './Message/MessagesTable'
import HistoryTable from "./../Components/HistoryTable";
import {
  getTrHistoryProvider,
  approveRefund,
} from "../../../redux/actions/transactionActions";
import { getProviderProfile } from "../../../redux/actions/profileActions";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import CounterCard from "../../../shared/components/CounterCard"
import { showSucessNoti } from "../../../redux/actions/notificationAction";
import { getMessage,resloveMessage } from "../../../redux/actions/messageActions";
import Messages from './Message/Messages'
const ProviderActivities = () => {
  const dispatch = useDispatch();
  const [refundModal, setRefundModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState({});

  const cancelList = useSelector((state) => state.transaction.cancelOrderList);

  const { providerProfile, providerLoading } = useSelector(state => state.profile)

  const providerHistory = useSelector(
    (state) => state.transaction.TrHistoryProvider
  );
  const loading = useSelector((state) => state.message);

  const open = useSelector((state) => state.message.open);

  useEffect(() => {
    (async () => {
      dispatch(getProviderProfile());
      dispatch(getTrHistoryProvider());
      dispatch(getMessage());
    })();
  }, []);

  const handleClose = () => {
    setRefundModal(false);
  };
  const handleApproveModal = (id) => {
    setRefundModal(true);
    setCurrentId(id);
  };
  const handleConfirmRefund = () => {
    let id = currentId;
    dispatch(approveRefund(id));
    dispatch(showSucessNoti("Your Request has been sent Successfully!"));
    setRefundModal(false);
  };

  return (
    <>
      <Container>
        {open
          ?
          (<Messages/>)
          :
          (<>
          <Row className="mt-0 m-auto">
            
            <div className="w-100">
            <Row>
            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
              <div className="mb-10">
                <CounterCard
                  color="color1"
                  count={providerProfile.experience}
                  label="EXPERIENCE"
                        icon="mood"
                        size="small"
                />
              </div>
              </Col>
              <Col xl={3} lg={3} md={6} sm={12} xs={12}>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.programs}
                  color="color2"
                  label="PROGRAMS"
                        icon="card_membership_icon "
                        size="small"
                />
              </div>
              </Col>
              <Col xl={3} lg={3} md={6} sm={12} xs={12}>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.registrationCount}
                  color="color3"
                  label="REGISTRATIONS"
                        icon="create"
                        size="small"
                />
              </div>
              </Col>
              <Col xl={3} lg={3} md={6} sm={12} xs={12}>
              <div className="mb-10">
                <CounterCard
                  count={providerProfile.totalStudents}
                  color="color4"
                  label="STUDENTS"
                        icon="emoji_people"
                        size="small"
                />
              </div>
              </Col>
              </Row>
            </div>
          </Row>
            <Row>
              <Col md={12}>
                <div className="card__title">
                  <h3 className="bold-text">Activities</h3>

                </div>
              </Col>
            </Row>
            <MessagesTable />
            <HistoryTable
              history={providerHistory}
              loading={loading.loading}
            />
          </>)

        }

        {  refundModal && (<ConfirmationModal
           color="primary"
           title="Approve Refund"
           message="Are you sureyou want to Approve this Refund?"
           colored={true}
           header={true}
           isOpen={refundModal}
           closeModal={handleClose}
           onYesClick={handleConfirmRefund}
         />)
        }

        {/* to approve message */}
        
        
      </Container>

    </>

  );
};

export default ProviderActivities;
