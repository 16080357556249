import React, { Fragment } from "react";
import {
  Modal, ModalHeader, ModalBody, Alert, Card, CardBody
} from 'reactstrap';
import {useSelector,useDispatch} from "react-redux"
import PaypayButton from "../../../Cart/Components/PaypalButton"
import Collapse from "../../../../shared/components/Collapse";
import { SubscriptionType } from "../../../../config/subscrition"
import { saveSubscriptionData, getSubscription } from "../../../../redux/actions/subscriptionAction"
import { modalSubscrition, paymentSubsModal } from "../../../../redux/actions/modalAction"
import { getProviderProfile } from "../../../../redux/actions/profileActions"
import StripeButton from "containers/Cart/Components/StripeButton"
function PaymentSubsModal({ open, handleClose, title,handlePaymentSucess }) {




  
    const { subscriptionList, subsDetails, subsType } = useSelector(state => state.subscription)
    console.log("new value", subscriptionList)
    console.log("new subsDetails", subsDetails.id)
    console.log("new subsType", subsType)
    const dispatch = useDispatch()
    const getTotalValue = () => {
        switch (parseInt(subsType)) {
            case SubscriptionType.Monthly:
                return subsDetails.costPerMonth
            case SubscriptionType.Yearly:
                return subsDetails.annually
            default:
                return 0
        }
    }
    const paypalSubscribe = (data, actions) => {
      return actions.subscription.create({
      'plan_id': "<P-P-0DJ78918J82127834MG6NJDQ>",
      });
      };


      const paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        console.log("Payapl ap",detail)
        console.log("ddaats",data)
        };


        const saveTractionDetails = (data) => {
          console.log("tdata=============>",data)
          // alert("tdet=============>")

        // let data = {
        //     subscriptionId: subsDetails.id,
        //     invoiceNumber:parseInt(tdata.orderID),
        //     subscriptionType: parseInt(subsType),
        //     paymentSource: 1,
        //     transactionDetails: JSON.stringify(details)
        // }
        dispatch(saveSubscriptionData(data)).then(() => {
          
          dispatch(paymentSubsModal(false)).then(() => {
            handlePaymentSucess()
          })
        })
    }
  return (
    <Fragment>
      <Modal scrollable toggle={handleClose} className="theme-light ltr-support login-modal-form mb-10" isOpen={open} centered>
        <ModalHeader toggle={handleClose}><h5><strong>{title}</strong></h5></ModalHeader>
        <Card style={{overflow: 'auto'}}>
          <CardBody>
            <Collapse title="PayPal" className="with-shadow">
              <PaypayButton
               totalvalue={getTotalValue()}
              //  createSubscription={paypalSubscribe}
              //  onApprove={paypalOnApprove}
                currencyType="CAD" 
                saveTractionDetails={(data)=>saveTractionDetails(data)} 
                
                />
            </Collapse>
            {/* <Collapse title="Stripe" className="with-shadow">
              <StripeButton />
            </Collapse> */}
          </CardBody>
        </Card>

      </Modal>
    </Fragment>
  );
}
export default PaymentSubsModal
