import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Review from "./Review"
import ReviewForm from "./ReviewForm";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function ReviewModal({ isOpen,title,closeModal,submitReview}) {

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={closeModal} aria-labelledby="form-dialog-title">
        <DialogTitle onClose={closeModal} id="form-dialog-title">{title}</DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>
            <Review />
          
            <ReviewForm closeModal={closeModal}
            submitReview={submitReview}
            />
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
    </>
  );
}
