import { post, get } from './base';

export default 
    {
        getSub: () =>{
            return get(`${window.apiUrl}Subscription/get-subscriptions`,{},true)
        },
        post: (data) => {
            return post(`${window.apiUrl}Subscription/add-subscription`,data,true)
        },
        getById: (id) =>{
            return get(`${window.apiUrl}Subscription/get-subscription-by-id?id=${id}`,{},true)
        },
        delete: (id) =>{
            return post(`${window.apiUrl}Subscription/delete-subscription?id=${id}`,{},true)
        },
        saveSubscription: (data) => {
            return post (`${window.apiUrl}SubscriptionTransaction/subscription-transaction`,data,true)
        },
        subscriptionTransactions: () => {
            return get(`${window.apiUrl}SubscriptionTransaction/subscription-transactions`,{},true)
        },
    }
