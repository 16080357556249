import React from 'react'
import { Container, Row,Col } from 'reactstrap';

export default function Banner() {
    return (<div class="Banner w-100 mt-30n">
            <div class="content text-center text-white font-size-25 px-10 flex flex-center  ">
            <div>
                <h1 style={{ "color": "white", "fontWeight": "bold" }}>It takes a Community to raise a Family.</h1><br />
                <h2 style={{"color":"white", "fontWeight":"bold"}}>This is your Community</h2><br />
            
            </div>
               
            </div>
        </div>)
}
