import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../../shared/components/form/RenderField";
import { Col, Row } from "reactstrap";
import { Button } from "@material-ui/core";
import { validate } from "../../../../config/formValidation";
import { connect } from 'react-redux';
const ReviewForm = ({
  handleSubmit,
  closeModal,
  submitReview,
  submitting,
  initialValues,
  valid,
}) => {
  return (
    <form onSubmit={handleSubmit(submitReview)}>
      <Col md={12} xs={12} lg={12}>
        <Field
          name="subject"
          type="text"
          label="Title"
          startIcon="titleIcon"
          component={renderField}
          disabled={initialValues.readOnlyReview}
        />
      </Col>
      <br />
      <Col md={12} xs={12} lg={12}>
        <Field
          name="fullMassage"
          type="text"
          label="Description"
          startIcon="description"
          component={renderField}
          multiline={true}
          disabled={initialValues.readOnlyReview}
          rows={4}
        />
      </Col>
      <Col>
      <Row className="row">
        <Col md={6} xs={6} sm={6} xs={6}>
          <Button
            className="account__btn"
            color="secondary"
            disableElevation
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Col>
        <Col md={6} xs={6} sm={6} xs={6}>
          <Button
            disabled={!valid || submitting || initialValues.readOnlyReview}
            className="account__btn"
            type="submit"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Col>
      </Row>
      </Col>
    </form>
  );
};

export default connect(state => ({
  initialValues: {
    subject: state.review?.reviewData?.subject ?? "",
    fullMassage:state.review?.reviewData?.fullMassage ?? "",
    readOnlyReview: state.review?.readOnlyReview ?? false
  }
}))(reduxForm({
  form: "Review",validate
})(ReviewForm));
