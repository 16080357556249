import { mediaTypes } from "config/mediaTypes";
// import MultiSelect from "react-multi-select-component";
import { tagTypes } from 'config/tagTypes';
import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import Modal from 'react-modal';
import { connect, useDispatch, useSelector } from "react-redux";
import { Col } from 'reactstrap';
import { Field, formValueSelector, reduxForm, reset, initialize } from 'redux-form';
import { setMediaProgram } from "redux/actions/mediaAction";
import PopularTags1 from 'shared/components/PopularTags/PopularTags1';
import { validate } from "../../../config/formValidation";
import { ListingOfLink, programListingByProvider } from '../../../redux/actions/linkManagementAction';
import { showFailedNoti, showSucessNoti } from "../../../redux/actions/notificationAction";
import { providerProgram, providerProgramForAdd } from '../../../redux/actions/programActions';
import { setLocationTag } from '../../../redux/actions/tagAction';
import ActivityTag from "../../../shared/components/form/ActivityTag";
import ImageUpload from "../../../shared/components/form/ImageUpload";
import { renderField } from "../../../shared/components/form/RenderField";
import { exportToCsv } from "../../../shared/helpers/index";
import api from "../../../utils/apiClient";
import CardNew from "../CardNew";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const customStyles1 = {
    content: {
        top: '3%',
        left: '50%',
        width: '50%',
        minWidth: '450px',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, 0%)',
    },
};


const LinkProgram = ({ handleSubmit, location, link, programMedia, initialValues, programSubmit, fieldData }) => {
    const type = new URLSearchParams(location.search).get("type")
    const { programDetails, detailsLoading } = useSelector(state => state.program)
    const { providerExpById } = useSelector(state => state.experiance)
    const { providerProfileId, providerProfileLoading } = useSelector(state => state.profile)
    const { data } = useSelector(state => state)
    const { values } = useSelector(state => state.form.LinkProgram)
    const dispatch = useDispatch()
    const [copied, setCopied] = useState(false);
    const [temp, settemp] = useState({})
    const copylink = useRef(null);

    const [text, setText] = useState('');
    const inputHandler = event => {
        setText(event.target.value);
    }



    const { mainSelectedTag } = useSelector(state => state.tags);

    const Tag = useSelector(state => state.tags.selectedTag)
    const Tags = useSelector(state => state.tags)

    const providerProgramData1 = useSelector(state => state.program.allProgram)


    useEffect(() => {
        settemp({ temp: 1 });
    }, [Tag])

    const programsList = useSelector(
        (state) => state.linkManagement.programListByProvider

    );

    const linkManagementList = useSelector(
        (state) => state.linkManagement.linkManagementList
    );

    const handleSelectedTag = (tag) => {

        dispatch(setLocationTag())
    }

    const { providerProgramData } = useSelector(state => state.program)
    const { programSearchQuery } = useSelector(state => state.program)


    React.useEffect(() => {
        dispatch(providerProgram())
        // dispatch(providerProgramForAdd())
        // dispatch(programListingByProvider())

        dispatch(ListingOfLink())
    }, [])

    function afterOpenModalDelete() {
        subtitle.style.color = '#f00';
    }
    const [modalIsOpendelete, setIsOpenedelete] = useState(false);
    const [linkName, setLinkName] = useState('');
    const [programs, setPrograms] = useState([]);
    const [programsError, setProgramsError] = useState('');
    const [linkNameError, setLinkNameError] = useState('');
    const [providerProfile, setProviderProfile] = useState('');
    const [description, setDescription] = useState('');


    const [linkProgramId, setLinkProgramId] = useState(0);
    const [check, setCheck] = useState(false);
    const [programName, setprogramName] = useState('')




    const convertToCsv = () => {
        let data = []
        // let csvContent = "";
        let A = [["Name", "Link", "Number of Visits"]]
        data.push(A)
        linkManagementList.forEach((programLink, index) => {
            return data.push([
                programLink.linkName,
                programLink.link,
                programLink.linkVisited

            ])

        });
        exportToCsv(data, "link_detail")
        // setAnchorEl(null)
    }

    let subtitle;
    const [modalIsOpenadd, setIsOpenadd] = React.useState(false);
    const [modalIsOpenadded, setIsOpenadded] = React.useState(false);

    function openModaladd(value) {

        setLinkProgramId(0);
        setIsOpenadded(true);
        console.log("openModaladd", value)
        if (value == "add") {
            clearDataOnNewLink()
            setCheck('Add')
        }
    }

    function clearDataOnNewLink() {
        setProviderId([]);
        dispatch(setMediaProgram(""))

        // dispatch(reset('LinkProgram'));
        // dispatch(reset('LinkProgram'));
        // fieldData.values.programName = undefined
        // fieldData.values.programPhoto = ""
        // fieldData.values.description = ""
        // fieldData.values.locations = ""
        // fieldData.values.websiteLink = ""
        // fieldData.values.youtubeLink = ""
        // fieldData.values.instagramLink = ""
        // fieldData.values.facebookLink = ""
        // fieldData.values.twitterLink = ""
    }

    function afterOpenModaladd() {
        subtitle.style.color = '#f00';
    }

    useEffect(() => {
        if (!modalIsOpenadded) {
            dispatch(reset('LinkProgram'));
            dispatch(initialize('LinkProgram'));
        }
    }, [modalIsOpenadded])

    function closeModaladd() {
        setIsOpenadd(false);
        setLinkNameError('');
        setLinkName('');
        setProgramsError('');
        setPrograms([]);
        setIsOpenadded(false);
        setLinkProgramId(0);
    }

    const [modalIsOpeneditlink, setIsOpeneditlink] = React.useState(false);

    function openModaleditlink(linkData) {

        setCheck('Edit')
        // dispatch(reset('LinkProgram'));

        getLinkDetailsByLinkName(linkData.linkName)
        setLinkProgramId(linkData.id);
        setLinkNameError('');
        setLinkName(linkData.linkName);
        setProgramsError('');
        setLinkProgramId(linkData.id)

        let tmpArry = [];
        // updated-----------------------------------------------
        linkData.arrProgram.map((programId) => {
            providerProgramData.item1.map((program) => {
                if (program.value == programId) {
                    tmpArry.push(program);
                }
            })
        })
        // updated-----------------------------------------------
        setProviderId(linkData.arrProgram);
        setPrograms(tmpArry);

        handleopenEditProgram()

    }


    function afterOpenModaleditlink() {
        subtitle.style.color = '#f00';
    }

    function closeModaleditlink() {
        setIsOpeneditlink(false);
        setLinkProgramId(0);
        setLinkNameError('');
        setLinkName('');
        setProgramsError('');
        setPrograms([]);
    }

    function openModaldelete(data, index) {
        setDeletedData(data);
        setIsOpenedelete(true);
    }
    const [deletedData, setDeletedData] = useState('');
    function closeModaldelete() {
        setIsOpenedelete(false);
    }

    useEffect(() => {

    }, [linkName])


    const onProgramSelect = (e) => {
        handleProgramChange(e)
    }

    const onProgramRemove = (e) => {
        handleProgramChange(e)
    }

    function handleProgramChange(e) {
        setProgramsError('');
        setPrograms(e);
    }


    async function handleopenEditProgram() {
        if (linkName.trim() === '') {
            setLinkNameError("Please enter link name")
        } else {
            setIsOpenadd(false);
            setIsOpenadded(true);
        }
    }

    async function onSubmit(value) {
        if (providerId.length === 0) {

            alert('Please select atleast one program.');
            setIsOpenadd(false);
            setIsOpenadded(true);

        } else {
            let params = {
                "id": linkProgramId,
                "programName": fieldData.values.programName,
                "logoUrl": programMedia,
                "description": fieldData.values.description,
                "locations": fieldData.values.locations,
                "websiteLink": fieldData.values.websiteLink,
                "youtubeLink": fieldData.values.youtubeLink,
                "instagramLink": fieldData.values.instagramLink,
                "facebookLink": fieldData.values.facebookLink,
                "twitterLink": fieldData.values.twitterLink,
                "linkName": fieldData.values.programName,
                "isActive": true,
                "arrProgram": providerId
            }
            setProviderId([]);
            await api.linkManagement.addProgramLinkManagement(params, true).then(res => {
                if (res.data) {

                    dispatch(showSucessNoti(res.data.message));
                    dispatch(ListingOfLink());
                    dispatch(providerProgram())
                    // setTimeout( 500);

                    closeModaleditlink();
                    closeModaladd();

                }
            }).catch(err => {
                closeModaladd();
                dispatch(showFailedNoti(err.response.data.title));
            })
        }
    }

    useEffect(() => {
        getLinkDetailsByLinkName(linkName);

    }, [])


    async function getLinkDetailsByLinkName(linkName) {
        await api.linkManagement.GetLinkByLinkName(linkName, true).then(res => {


            if (res.data) {
                if (res.data.data.isActive) {

                    setIsOpenadd(false);
                    setIsOpenadded(true);
                    dispatch(initialize('LinkProgram', {
                        programName: linkName,
                        programPhoto: res.data.data.providerProfile.logoUrl,
                        description: res.data.data.providerProfile.description,
                        websiteLink: res.data.data.websiteLink,
                        youtubeLink: res.data.data.youtubeLink,
                        instagramLink: res.data.data.instagramLink,
                        facebookLink: res.data.data.facebookLink,
                        twitterLink: res.data.data.twitterLink,
                        locations: res.data.data.providerProfile.locations
                    }));

                    dispatch(setMediaProgram(res.data.data.providerProfile.logoUrl))


                    // setChampa(res.data.data) 
                    setDescription(res.data.data.providerProfile.description)
                    // setlogoUrl(res.data.data.providerProfile.logoUrl)
                    // setOrganisationName(res.data.data.providerProfile.organisationName)

                    setProviderProfile(res.data.data.providerProfile);
                    let a = document.getElementById("description")
                    a.innerHTML = "<span class='material-icons MuiIcon-root' aria-hidden='true' >notes</span> About:" + res.data.data.providerProfile.description
                    dispatch(showSucessNoti(res.data.message));

                }
                else {
                }
            }
        }).catch(err => {

            if (err.response) dispatch(showFailedNoti(err.response.data.title));
        })

    }


    async function handleStatus(id, status) {
        let params = {
            linkId: id,
            active: !status
        };
        await api.linkManagement.activeInactiveLinkProgram(params, true).then(res => {
            if (res.data) {
                dispatch(showSucessNoti(res.data.message));
                dispatch(ListingOfLink());
            }
        }).catch(err => {
            closeModaladd();
            dispatch(showFailedNoti(err.response.data.title));
        })

    }

    async function deleteLinkProgram(id) {
        let params = {
            linkId: id,
        };
        await api.linkManagement.deleteLinkProgram(params, true).then(res => {
            if (res.data) {

                dispatch(showSucessNoti(res.data.message));
                dispatch(ListingOfLink());
                closeModaldelete()
            }
        }).catch(err => {
            closeModaladd();
            dispatch(showFailedNoti(err.response.data.title));
        })

    }

    const routePath = {
        pathname: window.location.origin,
        // search: `?type=${type}`,
        // hash: `${name.replace(/ /g, '-')}`,
        // state: { id: id }
    }

    const [providerId, setProviderId] = useState([]);

    const handleProviderId = (id) => {

        if (providerId.includes(id)) {
            setProviderId(arr => arr.filter(i => i !== id))
        } else {
            setProviderId(arr => arr.concat(id));
        }
    }

    function insert(str, index, value) {
        return str.substr(0, index) + value + str.substr(index);
    }

    return (<div>
        <Helmet>
            <title>Link - DWUL</title>
            <meta name="description" content="Todos!" />
            <meta name="theme-color" content="#008f68" />
        </Helmet>
        <section className="link-management-page">
            <div className="listlink-heading d-flex">
                <h3 className=" uppercase bold-text">List of Link</h3>
                <button className="MuiButtonBase-root MuiButton-root MuiButton-outlined ml-10 MuiButton-outlinedPrimary" onClick={() => { openModaladd('add') }} tabindex="0" type="button"><span className="MuiButton-label">Add Link</span><span className="MuiTouchRipple-root"></span></button>
                <button className="MuiButtonBase-root MuiButton-root MuiButton-outlined ml-10 MuiButton-outlinedPrimary" onClick={convertToCsv} tabindex="0" type="button"><span className="MuiButton-label">Export</span><span className="MuiTouchRipple-root"></span></button>

            </div>
            {/* <form
                    className="login-form"
                    // onSubmit={handleSubmit(expSubmit)}
                >
                    <Row>
                        <Col md={12} lg={12} sm={12}>
                            <div className="form__form-group">
                                <Field
                                    name="availability"
                                    label="Days of a week"
                                    component={AutoDropDown}
                                    type='text'

                                    placeholder="Availability"
                                    className="input-without-border-radius"
                                    startIcon='location_on'
                                    options={daysofWeek}
                                />

                            </div>
                        </Col>
                    </Row>
                </form> */}
            <div className="responsive-table listmanagement-table-main mt-3">
                <table width="100%">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Link</th>
                            <th>Number of Visits</th>
                            <th>Active</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {linkManagementList.map((programLink, index) => {


                            const pair = insert(programLink.link, 20, "link/")


                            return (

                                < tr >
                                    <td>{index + 1}</td>
                                    <td>{programLink.linkName}</td>

                                    <td> <a href={pair} target="_blank" id="copylink" onClick={localStorage.setItem('linkname', programLink.linkName)}> {programLink.link} </a> </td>
                                    {/* <td> <a href={routePath.pathname + programLink.userName  + programLink.linkName} target = "_blank" > { programLink.link} </a> </td> */}
                                    <td>{programLink.linkVisited}</td>
                                    <td>
                                        <div className="toggle-container">
                                            <label className="switch round">
                                                <input type="checkbox" checked={programLink.isActive} onClick={() => handleStatus(programLink.id, programLink.isActive)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td><span className="edit-listing mr-3" onClick={() => openModaleditlink(programLink)}>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </span>

                                        <span className="delete-listing mr-3">
                                            <i className="fa fa-trash-o" aria-hidden="true" onClick={() => openModaldelete(programLink, index)}></i>
                                        </span>
                                        <CopyToClipboard text={programLink.link}>

                                            <span className="delete-listing ">
                                                <i class="fa fa-files-o" aria-hidden="true"></i>

                                            </span>

                                        </CopyToClipboard>


                                    </td>

                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>
        </section >

        {/* ---------------commented-------------------------- */}

        {/* <Modal
            isOpen={modalIsOpenadd}
            onAfterOpen={afterOpenModaladd}
            onRequestClose={closeModaladd}
            style={customStyles}
            contentLabel="Modal">
            <div className="add-link-modal">
                <div className="add-link-head d-flex">
                    <h5 ref={(_subtitle) => (subtitle = _subtitle)}>Add Link</h5>
                    <p onClick={closeModaladd}><i class="fa fa-times" aria-hidden="true"></i></p>
                </div>
                <div class="form-group">
                    <label className="linkname" for="usr">Name:</label>
                    <input type="text" class="form-control" id="usr" value={linkName} onChange={(e) => { setLinkName(e.target.value); setLinkNameError(''); }} name="linkname" />
                </div>
                {linkNameError && <p className="url-used">{linkNameError}</p>}
                <div class="form-group">
                    <label className="linkname" for="usr">Url</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text link-permanent" id="basic-addon3" disabled>{window.location.origin + "/" + JSON.parse(localStorage.getItem('authUser')).name}</span>
                        </div>
                        <input type="text" class="form-control" id="basic-url" value={linkName} aria-describedby="basic-addon3" disabled />
                    </div>
                </div>
                <a class="btn link-submit" onClick={() => onSubmit("new")}>Next</a>



            </div>


        </Modal> */}
        {/* ---------------commented-------------------------- */}




        <Modal
            isOpen={modalIsOpenadded}
            // onAfterOpen={afterOpenModaladd}
            onRequestClose={closeModaladd}
            style={customStyles1}
            portalClassName="link-program-new-modal"
            contentLabel="Modal">
            <form className="add-link-modal" onSubmit={handleSubmit(onSubmit)}>
                <div className="add-link-head d-flex">
                    <h5 ref={(_subtitle) => (subtitle = _subtitle)}>{check == 'Add' ? 'Choose Program' : 'Edit Program'}</h5>
                    <p onClick={closeModaladd}><i class="fa fa-times" aria-hidden="true"></i></p>
                </div>

                <div>

                    <Col md={2} lg={2} sm={2} className="pl-0">
                        <ImageUpload
                            name="programPhoto"
                            accept="image/*"
                            image={programMedia ? programMedia : initialValues.programPhoto}
                            mediatype={mediaTypes.Program}
                        />
                    </Col>

                    <div className="form__form-group">
                        <Field
                            name="programName"
                            label="Name*"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'

                        />
                    </div>



                    <div className="form__form-group">
                        <Field
                            name="locations"
                            label="City Tags"
                            component={ActivityTag}
                            type='text'
                            placeholder="City tags"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            defaultValue={{ id: initialValues.locationId, name: initialValues.locationName }}
                            tagType={[tagTypes.Location, tagTypes.School]}
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="description"
                            label="Description"
                            component={renderField}
                            type="text"
                            placeholder="Description"
                            startIcon='people'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="websiteLink"
                            label="Website"
                            component={renderField}
                            type="text"
                            placeholder="Website"
                            startIcon='website'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="facebookLink"
                            label="Facebook Link"
                            component={renderField}
                            type="text"
                            placeholder="Facebook Link"
                            startIcon='Facebook'
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="twitterLink"
                            label="Twitter Link"
                            component={renderField}
                            type="text"
                            placeholder="Twitter Link"
                            startIcon='Twitter'
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="instagramLink"
                            label="Instagram Link"
                            component={renderField}
                            type="text"
                            placeholder="Instagram Link"
                            startIcon='Instagram'
                        />
                    </div>
                    <div className="form__form-group">
                        <Field
                            name="youtubeLink"
                            label="Youtube Link"
                            component={renderField}
                            type="text"
                            placeholder="Youtube Link"
                            startIcon='Youtube'
                        />
                    </div>




                </div>

                {/* {check == 'Add' || check === 'Edit' ? <PopularTags1 onClick={handleSelectedTag} /> : null}
                {check == 'Add' || check === 'Edit' ? */}
                <div className="listing-box">
                    {/* <Program /> */}

                    <>
                        <div className='row'>
                            {providerProgramData &&
                                providerProgramData.item2 > 0
                                && providerProgramData.item1.map((item, index) => {
                                    if (item.programName.toLowerCase().includes(programSearchQuery) || !programSearchQuery)
                                        if (Tag.length == 0) {
                                            return <div className="col-md-4 listingrow" key={index}   >
                                                <>


                                                    <CardNew
                                                        name={item.programName}
                                                        cost={item.cost}
                                                        newTag={item.new}
                                                        salesTag={item.discount}
                                                        activities={item.activity}
                                                        image={item.programPhoto}
                                                        description={item.description}
                                                        locations={item.locations}
                                                        id={item.id}
                                                        regulerDates={item.regulerDates}
                                                        specialDates={item.specialDates}
                                                        regulerStartTime={item.regularStartTime ? item.regularStartTime : null}
                                                        regulerEndTime={item.regularEndTime ? item.regularEndTime : null}
                                                        specialStartTime={item.specialStartTime ? item.specialStartTime : null}
                                                        specialEndTime={item.specialEndTime ? item.specialEndTime : null}
                                                        onClick={() => handleProviderId(item.id)}
                                                        isManage={true}
                                                        item={item}
                                                        selected={providerId.includes(item.id)}

                                                        // selected={true}
                                                        // sessionsCount={(item.specialDates?.length + item.regulerDates?.length)?(item.specialDates?.length + item.regulerDates?.length):0}
                                                        sessionsCount={((item.specialDates && item.specialDates.length) ? item.specialDates.length : 0) + ((item.regulerDates && item.regulerDates.length) ? item.regulerDates.length : 0)}
                                                        routePath={`/detail/${item.programName}/${item.id}`}
                                                        className='jhkhk'
                                                        minimumAge={item.minimumAge}
                                                        maximumAge={item.maximumAge}
                                                    />

                                                </>
                                            </div>

                                        } else if (item.locations.filter((i) => Tag.some(tag => tag.id == i.id)).length) {
                                            return <div className="flex flex-middle" key={index}   >
                                                {/* <input type='checkbox'  onChange={()=>handleProviderId(item.id)}/> */}
                                                <>

                                                    <CardNew
                                                        name={item.programName}
                                                        cost={item.cost}
                                                        newTag={item.new}
                                                        salesTag={item.discount}
                                                        activities={item.activity}
                                                        image={item.programPhoto}
                                                        description={item.description}
                                                        locations={item.locations}
                                                        id={item.id}
                                                        regulerDates={item.regulerDates}
                                                        specialDates={item.specialDates}
                                                        regulerStartTime={item.regularStartTime ? item.regularStartTime : null}
                                                        regulerEndTime={item.regularEndTime ? item.regularEndTime : null}
                                                        specialStartTime={item.specialStartTime ? item.specialStartTime : null}
                                                        specialEndTime={item.specialEndTime ? item.specialEndTime : null}
                                                        onClick={() => handleProviderId(item.id)}
                                                        isManage={true}
                                                        item={item}
                                                        selected={providerId.includes(item.id)}
                                                        // selected={true}
                                                        // selected={providerId.includes(item.id)}
                                                        // sessionsCount={(item.specialDates?.length + item.regulerDates?.length)?(item.specialDates?.length + item.regulerDates?.length):0}
                                                        sessionsCount={((item.specialDates && item.specialDates.length) ? item.specialDates.length : 0) + ((item.regulerDates && item.regulerDates.length) ? item.regulerDates.length : 0)}
                                                        routePath={`/detail/${item.programName}/${item.id}`}
                                                        className='jhkhk'
                                                        minimumAge={item.minimumAge}
                                                        maximumAge={item.maximumAge}
                                                    />
                                                </>
                                            </div>
                                        }
                                        else if (item.activity.filter((i) => Tag.some(tag => tag.id == i.id)).length) {
                                            return <div className="flex flex-middle" key={index}   >
                                                {/* <input type='checkbox'  onChange={()=>handleProviderId(item.id)}/> */}
                                                <>

                                                    <CardNew
                                                        name={item.programName}
                                                        cost={item.cost}
                                                        newTag={item.new}
                                                        salesTag={item.discount}
                                                        activities={item.activity}
                                                        image={item.programPhoto}
                                                        description={item.description}
                                                        locations={item.locations}
                                                        id={item.id}
                                                        regulerDates={item.regulerDates}
                                                        specialDates={item.specialDates}
                                                        regulerStartTime={item.regularStartTime ? item.regularStartTime : null}
                                                        regulerEndTime={item.regularEndTime ? item.regularEndTime : null}
                                                        specialStartTime={item.specialStartTime ? item.specialStartTime : null}
                                                        specialEndTime={item.specialEndTime ? item.specialEndTime : null}
                                                        onClick={() => handleProviderId(item.id)}
                                                        isManage={true}
                                                        item={item}
                                                        selected={providerId.includes(item.id)}
                                                        // selected={true}
                                                        // selected={providerId.includes(item.id)}
                                                        // sessionsCount={(item.specialDates?.length + item.regulerDates?.length)?(item.specialDates?.length + item.regulerDates?.length):0}
                                                        sessionsCount={((item.specialDates && item.specialDates.length) ? item.specialDates.length : 0) + ((item.regulerDates && item.regulerDates.length) ? item.regulerDates.length : 0)}
                                                        className='jhkhk'
                                                        routePath={`/detail/${item.programName}/${item.id}`}
                                                        minimumAge={item.minimumAge}
                                                        maximumAge={item.maximumAge}
                                                    />
                                                </>
                                            </div>
                                        }

                                })}
                        </div>

                    </>
                </div>
                {/* // : null} */}
                <button class="btn link-submit" type="submit">submit</button>

            </form>


        </Modal>

        {/* edit link */}

        <Modal
            isOpen={modalIsOpeneditlink}
            onAfterOpen={afterOpenModaleditlink}
            onRequestClose={closeModaleditlink}
            style={customStyles}
            contentLabel="Modal">
            <div className="add-link-modal">
                <div className="add-link-head d-flex">
                    <h5 ref={(_subtitle) => (subtitle = _subtitle)}>Edit Link</h5>
                    <p onClick={closeModaleditlink}><i class="fa fa-times" aria-hidden="true"></i></p>
                </div>
                <div class="form-group">
                    <label className="linkname" for="usr">Name:</label>
                    <input type="text" class="form-control" id="usr" value={linkName} onChange={(e) => { setLinkName(e.target.value); setLinkNameError(''); }} name="username" />
                </div>
                <div class="form-group">
                    <label className="linkname" for="usr">Url</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text link-permanent" id="basic-addon3" disabled>{window.location.origin + "/" + JSON.parse(localStorage.getItem('authUser')).name}</span>
                        </div>
                        <input type="text" class="form-control" id="basic-url" value={linkName} aria-describedby="basic-addon3" disabled />
                    </div>
                </div>
                {/* <p className="url-used">This url is already used</p> */}
                {/* <div className="listing-box">
                    <label className="linkname" for="usr">Listing</label>

                    <Multiselect showArrow options={providerProgramData} selectedValues={programs} displayValue="text" isObject={true} showCheckbox={true}
                    onSelect={onProgramSelect} // Function will trigger on select event
                    onRemove={onProgramRemove}
                    />
                    {programsError && <p className="url-used">{programsError}</p>}
                </div> */}

                <a class="btn link-submit" onClick={() => handleopenEditProgram()}>Update</a>
            </div>
        </Modal>


        <Modal
            isOpen={modalIsOpendelete}
            onAfterOpen={afterOpenModalDelete}
            onRequestClose={closeModaldelete}
            style={customStyles}
            contentLabel="Modal">
            <div className="add-link-modal">
                <div className="add-link-head d-flex">
                    <h5 ref={(_subtitle) => (subtitle = _subtitle)}>Delete Link</h5>
                    <p onClick={closeModaldelete}><i class="fa fa-times" aria-hidden="true"></i></p>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <p>Are you sure you want to delete "{deletedData.file_name}" ?</p>
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <div className=" text-center">
                        <button className="btn btn-primary mr-2" style={{ backgroundColor: '#ef3e37' }} onClick={() => deleteLinkProgram(deletedData.id)}>Yes</button>
                        <button className="btn btn-secondary " onClick={() => closeModaldelete()}>No</button>
                    </div>
                </div>

            </div>
        </Modal>

    </div >)
};
const selector = formValueSelector('LinkProgram')

export default connect(state => {
    const hasregulerDates = selector(state, 'regulerDates')
    const hasspecialDates = selector(state, 'specialDates')
    const registrationFlag = selector(state, 'registrationFlag')
    const regularStartTime = selector(state, 'regularStartTime')
    const regularEndTime = selector(state, 'regularEndTime')
    const specialStartTime = selector(state, 'specialStartTime')
    const specialEndTime = selector(state, 'specialEndTime')



    // 
    // debugger
    return ({
        fieldData: state?.form.LinkProgram,
        errorMsg: state.user.error,
        programMedia: state.media.programMedia,
        flyer: state.media.programPdf,
        regularCount: hasregulerDates ? hasregulerDates.filter(n => n) : [],
        specialCount: hasspecialDates ? hasspecialDates.filter(n => n) : [],
        registrationFlag: registrationFlag,
        initialValues: {
            facebookLink: state.program.programById.facebookLink ? state.program.programById.facebookLink : "",
            twitterLink: state.program.programById.twitterLink ? state.program.programById.twitterLink : "",
            instagramLink: state.program.programById.instagramLink ? state.program.programById.instagramLink : "",
            youtubeLink: state.program.programById.youtubeLink ? state.program.programById.youtubeLink : "",
            programName: state.program.programById.programName ? state.program.programById.programName : "",
            programLocation: state.program.programById.programLocation ? state.program.programById.programLocation : "",
            categoryTags: state.program.programById.categoryTags ? state.program.programById.categoryTags : [],
            specialEndTime: state.program.programById.specialEndTime ? state.program.programById.specialEndTime : null,
            specialStartTime: state.program.programById.specialStartTime ? state.program.programById.specialStartTime : null,
            regularEndTime: state.program.programById.regularEndTime ? state.program.programById.regularEndTime : null,
            regularStartTime: state.program.programById.regularStartTime ? state.program.programById.regularStartTime : null,
            locations: state.program.programById.locations ? state.program.programById.locations : [],
            programPhoto: state.program.programById.programPhoto ? state.program.programById.programPhoto : "",
            locationName: state.program.programById.locationName ? state.program.programById.locationName : "",
            flyer: state.program.programById.flyer ? state.program.programById.flyer : "",
            activity: state.program.programById.activity ? state.program.programById.activity : [],
            ageGroup: state.program.programById.ageGroup ? state.program.programById.ageGroup : [],
            capacity: state.program.programById.capacity ? state.program.programById.capacity : 0,
            cost: state.program.programById.cost ? state.program.programById.cost : 0,
            description: state.program.programById.description ? state.program.programById.description : "",
            getData: state.program.programById.getData ? state.program.programById.getData : "",
            websiteLink: state.program.programById.websiteLink ? state.program.programById.websiteLink : "",

            id: state.program.programById.id ? state.program.programById.id : 0,
            regulerDates: state.program.programById.regulerDates ? state.program.programById.regulerDates : [''],
            noSession: state.program.programById.noSession ? state.program.programById.noSession : [''],
            specialDates: state.program.programById.specialDates ? state.program.programById.specialDates : [''],
            sessionsForCost: state.program.programById.sessionsForCost ? state.program.programById.sessionsForCost : 1,
            registrationFlag: state.program.programById.registrationFlag ? state.program.programById.registrationFlag : false,
            externalLink: state.program.programById.externalLink ? state.program.programById.externalLink : "",

            placeName: state.program.programById.placeName ? state.program.programById.placeName : '',
            streetAddress: state.program.programById.streetAddress ? state.program.programById.streetAddress : '',
            room: state.program.programById.room ? state.program.programById.room : '',
            minimumAge: state.program.programById.minimumAge ? state.program.programById.minimumAge : '',
            maximumAge: state.program.programById.maximumAge ? state.program.programById.maximumAge : ''
        }
    })
}
)(reduxForm({
    form: 'LinkProgram',
    enableReinitialize: false,
    validate
})(LinkProgram));


// export default connect(state => ({
//     errorMsg: state.user.error,
//     experierenceMedia: state.media.experierenceMedia,
//     file: state.media.experierencePdf,
//     initialValues: {
//         name: state.linkManagement.programListByProvider.programs ? state.linkManagement.programListByProvider.programs : [],
//     }
// }))(reduxForm({
//     form: 'experianceForm',
//     enableReinitialize: true,
//     validate
// })(LinkProgram));


// export default withRouter(LinkProgram);











































