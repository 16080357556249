import {
    GET_PROVIDER_PROFILE,
    UPDATE_PROVIDER_PROFILE,
    PARENT_PROFILE,
    STUDENT_PROFILE,
    UPDATE_PARENT,
    GET_PROVIDER_PROFILE_ID,
    USER_PROFILE_TOTAL_COUNT,
    ADD_FAVORITE,
    GET_FAVORITE,
    CHANGE_PASSWORD,
    ADD_USER_SUPERADMIN,
    ADMIN_PROFILE,
    PROVIDER_LIST,
    REGISTERED_PROGRAM,
    PROVIDER_PROFILE_LOADING
} from "../types";
import api from '../../utils/apiClient';
import { showFailedNoti, showSucessNoti } from "./notificationAction";

export const getProviderProfile = () => async (dispatch) => {
    dispatch(setProviderProfileLoading(true));
    await api.provider.providerProfile().then(res => {
        if (res.data) {

            console.log("provider Profile ....")
            dispatch({
                type: GET_PROVIDER_PROFILE,
                payload: res.data
            })
            dispatch(setProviderProfileLoading(false));
        }
    })
        .catch(err => {
            dispatch(setProviderProfileLoading(false));
        })
}

export const setProviderProfileLoading = (val) => async (dispatch) => {
    dispatch({
        type: PROVIDER_PROFILE_LOADING,
        payload: val
    })
}

export const updateProviderProfile = (data) => async (dispatch) => {
    await api.profile.updateProviderProfile(data, true).then(res => {

        if (res.data) {
            dispatch({
                type: UPDATE_PROVIDER_PROFILE,
                payload: res.data
            })
        }
    })
        .catch(err => console.log(err))

}

export const getparentProfile = (id) => async (dispatch) => {
    await api.profile.getParentProfile(id).then(res => {
        console.log("msg hgsdg", res.data)
        if (res.data) {
            dispatch(setParentProfile(res.data.parentProfile))


            dispatch(setStudentsProfile(res.data.studentProfileList))
            dispatch(setRegisteredprogram(res.data.registeredPrograms))
        }
    }).catch(err => {
        dispatch(showFailedNoti(err.message))
    })
}
// setting parent profile --@includes student list in parent.
export const setParentProfile = (parentData) => async (dispatch) => {
    dispatch({
        type: PARENT_PROFILE,
        payload: parentData
    })
}
// setting student list on parent profile.
export const setStudentsProfile = (studentData) => async (dispatch) => {
    dispatch({
        type: STUDENT_PROFILE,
        payload: studentData
    })
}
// setting registered program list on parent profile.
export const setRegisteredprogram = (data) => async (dispatch) => {
    dispatch({
        type: REGISTERED_PROGRAM,
        payload: data
    })
}
// update parent profile
export const updateParent = (data) => async (dispatch) => {
    await api.profile.updateParentProfile(data, true).then(res => {
        if (res.data) {
            dispatch({
                type: UPDATE_PARENT,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message))
        }
    })
        .catch(err => {

        })
}
export const getProviderProfileId = (id) => async dispatch => {
    await api.profile.getProviderProfileById(id, true).then(res => {


        if (res.data) {
            dispatch({
                type: GET_PROVIDER_PROFILE_ID,
                payload: res.data
            })
        }
    })
        .catch(err => {
            console.log(err)
        })
}
export const totalcount = () => async (dispatch) => {
    await api.profile.getUserProfileTotalCount().then(res => {
        if (res.data) {
            dispatch({
                type: USER_PROFILE_TOTAL_COUNT,
                payload: res.data
            })
        }

    })
        .catch(err => {

        })
}
export const addFavorite = (id) => async dispatch => {
    await api.profile.favorite(id, true).then(res => {

        if (res.data) {
            dispatch(showSucessNoti(res.data.message))

        }
    })
        .catch(err => {

        })
}

export const changePassword = data => async dispatch => {
    await api.profile.changePassword(data, true).then(res => {
        if (res.data) {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message))
        }

    })
        .catch(err => {
            dispatch(showFailedNoti(err.message))

        })

}
export const addUser = (data) => async dispatch => {
    await api.profile.createUser(data, true).then(res => {
        if (res.data) {
            dispatch({
                type: ADD_USER_SUPERADMIN,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message))
        }
    })
        .catch(err => {
            dispatch(showFailedNoti(err.message))
        })
}
export const getAdmin = () => async dispatch => {
    await api.profile.adminProfile().then(res => {
        if (res.data) {
            dispatch({
                type: ADMIN_PROFILE,
                payload: res.data
            })
        }
    }).catch((err) => {
        console.error(err.message)
    })
}

export const getProviderList = (searchText = "", pageSize = 0, pageNo = 0) => async dispatch => {
    await api.provider.getProviderList({ searchText, pageNo, pageSize })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: PROVIDER_LIST,
                    payload: res.data
                })
            }
        }).catch((err) => {
            console.error(err.message)
        })
}
export const getParentProfileById = (id) => async dispatch => {
    await api.profile.getParentProfileById(id)
        .then(res => {
            if (res.data) {
                dispatch({
                    type: PARENT_PROFILE,
                    payload: res.data
                })
            }
        }).catch((err) => {
            console.error(err.message)
        })
}
