import {get } from './base/index';
import { post } from "./base/index"
import axios from '../api/base/axios';
const CancelToken = axios.CancelToken;
let cancel;

// Available api requests here: "https://documenter.getpostman.com/view/8854915/SzS7R6uu?version=latest"
export default {
    get: (data) => {
        const params = {...data };
        return get(`${window.apiUrl}Tags/tag-details`, { params });
    },
    getTagDetails: (id) => {
        return get(`${window.apiUrl}Tags/tag-details?id=${id}`, {}, true);
    },
    post: (data, headers) => {
        const body = { tagType: data };
        return post(`${window.apiUrl}Tags/tag-by-tagtype`, body, headers);
    },
    manageTag: (data, headers) => {
        const body = data
        return post(`${window.apiUrl}Tags/Manage-Tags`, body, headers)
    },
    deleteTag: (id, headers=true) => {
        return post(`${window.apiUrl}Tags/delete-tag-by-tagtype?id=${id}`,{}, headers)
    },
    search: (data) => {
        cancel && cancel();
        return post(`${window.apiUrl}Common/universal-search`, data,false,null,{
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c;
            })
          })
    }
};