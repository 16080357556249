
import {  post, get } from './base/index';


export default{
    providerProfile: ()=>{
        return get(`${window.apiUrl}UserProfile/provider-profile`,{},true)
    },
    updateProfile: (data,headers)=>{
        const body = data
        return post(`${window.apiUrl}update-provider-profile`,body,headers)
    },
    getProviderList: (data) => {
        return get(`${window.apiUrl}UserProfile/provider-profiles-list?${data.searchText !== "" ? `searchText=${data.searchText}` : ""}&pageSize=${data.pageSize}&pageNo=${data.pageNo}`, {}, false)
    }
}