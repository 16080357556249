import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Field, reduxForm } from "redux-form";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { renderField } from "../../../shared/components/form/RenderField";
import Button from "@material-ui/core/Button";
import { validate } from "../../../config/formValidation";
import { connect, useSelector } from "react-redux";
import AutoDropDown from "../../../shared/components/form/AutoDropDown";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

function AddUser({
  isAddUserModalOpen,
  closeAddUserModal,
  handleSubmit,
  isEdit,
  initialValues,
  valid,
  submitting,
}) {
  const [password, setPassword] = React.useState(false);
  const showToggle = () => {
    setPassword(!password);
  };
  return (
    <div>
      <Dialog onClose={closeAddUserModal} fullWidth open={isAddUserModalOpen}>
        <DialogTitle className="pb-0" onClose={closeAddUserModal}>
          {isEdit ? "Edit Resources" : "Add User"}
        </DialogTitle>
        <form className="login-form p-16 px-24" onSubmit={handleSubmit}>
          <DialogContent className="p-0 pt-4">
            <div>
              <Field name="id" component={renderField} hidden />
            </div>
            <div className="form__form-group">
              <Field
                name="programId"
                type="text"
                label="User Type*"
                placeholder="User Type"
                component={AutoDropDown}
                options={["Admin", "Parent", "Provider"]}
                multiple={false}
                startIcon="card_membership_icon"
              ></Field>
            </div>
            <div className="form__form-group">
              <Field
                name="subject"
                label="Name"
                type="text"
                component={renderField}
                placeholder="Name"
                startIcon="people"
                required
              />
            </div>

            <div className="form__form-group">
              <Field
                name="email"
                label="Email"
                type="text"
                component={renderField}
                required
                placeholder="Email"
                startIcon="email"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="phone"
                label="Phone"
                component={renderField}
                type="text"
                placeholder="Phone"
                className="input-without-border-radius"
                startIcon="contactsIcon"
              />
            </div>
            <div className="form__form-group">
              <Field
                name="password"
                label="Password"
                component={renderField}
                type="password"
                startIcon="vpn_key"
                type={password ? "text" : "password"}
                className="input-without-border-radius"
                showPasswordToggle={showToggle}
                toggleBtn={true}
              />
            </div>
          </DialogContent>
          <DialogActions className="float-right p-0">
            <Button
              className="mx-10"
              variant="contained"
              type="submnit"
              color="primary"
              disabled={!valid || submitting}
            >
              Save
            </Button>
            <Button
              className="m-0"
              variant="outlined"
              color="default"
              onClick={closeAddUserModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default connect((state) => ({
  initialValues: {
    id: state.announcement.announcementDetails.id
      ? state.announcement.announcementDetails.id
      : 0,
    announcementName: state.announcement.announcementDetails.announcementName
      ? state.announcement.announcementDetails.announcementName
      : "",
    description: state.announcement.announcementDetails.description
      ? state.announcement.announcementDetails.description
      : "",
    programname: state.announcement.announcementDetails.programname
      ? state.announcement.announcementDetails.programname
      : "",
    programId: state.announcement.announcementDetails.programId
      ? state.announcement.announcementDetails.programId
      : "",
  },
}))(
  reduxForm({
    form: "addUser",
    validate,
    enableReinitialize: true,
  })(AddUser)
);
