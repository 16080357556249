import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SearchableTable from "../../../shared/components/SearchableTable";
import { useSelector, useDispatch } from "react-redux";
import { TableRow, TableCell, Button, Icon, Tooltip } from "@material-ui/core";
import { addNewStModalForm } from "redux/actions/modalAction"
import api from "utils/apiClient"
import ConfirmationModal from "shared/components/ConfirmationModal"
import { getStudentProfile } from "redux/actions/studentAction"
import { showSucessNoti, showFailedNoti } from "redux/actions/notificationAction"
import { getparentProfile } from 'redux/actions/profileActions';

const column = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "grade",
    label: "Grade",
  },
  {
    id: "Gender",
    label: "Gender",
  },
  {
    id: "Email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "emergencyContact",
    label: "Emergency Contact",
  },
  {
    id: "school",
    label: "School",
  },
  {
    id: "action",
    label: "Action",
  }
];
const StudentListTable = ({ title, students }) => {

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const dispatch = useDispatch()
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [cnfrmDelete, setCnfrmDelete] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const { stLoading } = useSelector((state) => state.profile);
  const getTableData = () => {
    let tableData = [];
    if (students) {
      students.map((student) => {
        tableData.push({
          id: student.id,
          name: `${student.fName} ${student.mName !== null ? student.mName : ""} ${student.lName}`,
          grade: student.grade,
          gender: student.gender,
          phone: student.phone,
          emergencyContact: student.emergencyContact,
          email: student.email,
          schoolName: student.schoolName,
        })
      })
    }
    return tableData;
  };
  const clearSearch = () => {
    setSearchValue("");
  };
  const handleFavSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  const handleEdit = (item) => {
    dispatch(getStudentProfile(item.id)).then(() =>
      dispatch(addNewStModalForm(true))
    )

  }
  const handleDelete = (item) => {
    setCurrentId(item.id)
    setCnfrmDelete(true)
  }
  const handleCnfrmDelete = () => {
    api.student.deleteStudent(currentId)
      .then((res) => {
        if (res.data.data) {
          dispatch(showSucessNoti(res.data.message))
          setCnfrmDelete(false)
          console.log("idddd", res.data)
          dispatch(getparentProfile(0))
        }
        else {
          dispatch(showFailedNoti(res.data.message))
          setCnfrmDelete(false)
        }
      })
      .catch(err => dispatch(showFailedNoti(err.message)))
    setCnfrmDelete(false)
  }
  let filteredData = getTableData();
  if (searchValue) {
    filteredData = getTableData().filter((user) =>
      user.name.toLowerCase().startsWith(searchValue.toLowerCase())
    );
  }

  return (
    <>
      <SearchableTable
        title=""
        rows={column}
        searchValue={searchValue}
        filteredData={filteredData}
        clearSearch={clearSearch}
        loading={stLoading}
        actionTablebtn={true}
        actionBtnText="Add Persons"
        actionBtnClick={() => dispatch(addNewStModalForm(true))}
        searchLabel="Search student"
        searchPlaceholder="By name"
        rowsPerPage={rowsPerPage}
        page={page}
        getSearchResult={handleFavSearch}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        {filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((d, i) => {
            return (
              <TableRow
                className="material-table__row cursor-pointer"
                role="checkbox"
                tabIndex={-1}
                key={d.id}
              >
                <TableCell
                  className="material-table__cell"
                  padding="checkbox"
                >
                  {(i + 1) + (page * rowsPerPage)}
                </TableCell>
                <TableCell className="text-nowrap">
                  {d.name}
                </TableCell>
                <TableCell className="text-nowrap">{d.grade}</TableCell>
                <TableCell>{d.gender}</TableCell>
                <TableCell className="text-nowrap">{d.email}</TableCell>
                <TableCell>{d.phone}</TableCell>
                <TableCell>{d.emergencyContact}</TableCell>
                <TableCell>{d.schoolName}</TableCell>
                <TableCell>
                  <span className="flex">
                    <Tooltip title="Edit">
                      <Button variant="text" onClick={() => handleEdit(d)}>
                        <Icon>edit</Icon>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button variant="text" color="secondary" onClick={() => handleDelete(d)}>
                        <Icon>delete</Icon>
                      </Button>
                    </Tooltip>
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
      </SearchableTable>
      <ConfirmationModal
        color="danger"
        title="Delete Confirmation"
        message="Are you sure you want to delete this Student?"
        colored=""
        header={true}
        isOpen={cnfrmDelete}
        closeModal={() => setCnfrmDelete(false)}
        onYesClick={handleCnfrmDelete}
      />
    </>
  );
};

StudentListTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(StudentListTable);
