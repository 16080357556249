import {
  PARENT_PROFILE, STUDENT_PROFILE, UPDATE_PARENT, GET_PROVIDER_PROFILE,
  GET_PROVIDER_PROFILE_ID, USER_PROFILE_TOTAL_COUNT,ADD_FAVORITE,
  GET_FAVORITE,
  CHANGE_PASSWORD,ADMIN_PROFILE, REGISTERED_PROGRAM
} from '../types';

const initialState = {
  parentRegisteredPrograms:[],
  parentProfile: {},
  studentProfile: [],
  updateParent: {},
  providerProfile: {},
  totalCount: {},
  providerProfileId: {},
  providerProfileLoading:true,
  stLoading: true,
  parentLoading:true,
  changePassword: {},
  providerLoading: true,
  loading: true,
  getFavorite: [],
  adminProfile: {}
};

const profileReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case PARENT_PROFILE:
      return {
        ...state,
        parentProfile: action.payload,
        parentLoading:false
      };
      case REGISTERED_PROGRAM:
      return {
        ...state,
        parentRegisteredPrograms: action.payload,
        parentLoading:false
      };
    case STUDENT_PROFILE:
      return {
        ...state,
        studentProfile: action.payload,
        stLoading: false
      }
    case UPDATE_PARENT:
      return {
        ...state,
        updateParent: action.payload
      }
    case GET_PROVIDER_PROFILE:
      return {
        ...state,
        providerProfile: action.payload,
        providerLoading: false
      }
    case USER_PROFILE_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload
      }
    case GET_PROVIDER_PROFILE_ID:
      return {
        ...state,
        providerProfileId: action.payload,
        providerProfileLoading: false
      }
    case ADD_FAVORITE:
      return{
        ...state,
        
      }
    case GET_FAVORITE:
      return {
        ...state,
        getFavorite: action.payload
      }
    case CHANGE_PASSWORD:
      return{
        ...state,
        changePassword: action.payload
      }
    case ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: action.payload
      }
    default:
      return state;
  }
};

export default profileReducer;
