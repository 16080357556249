import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Panel from '../../../shared/components/Panel'
import Resources from "containers/Resources"
const columns = [
    {
        id: 0, label: 'Name',
    },
    {
        id: 1, label: 'Description',
    },
    {
        id: 2, label: 'Link',
    },
    {
        id: 3, label: '',
    }
];

const AdminResources = ({
    openResourcesModal,
    resource,
    onRefresh,
    refreshResources,
    handleEditResources,
    handleDeleteResources
}) => {
    const [searchValue, setSearchValue] = useState("")

    const clearSearch = () => {
        setSearchValue("")
    }
    const handleResourcesSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    let filteredData = resource
    if (searchValue) {
        filteredData = resource.filter((search) => {
            if ((search.subject.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }

        })
    }

    return (
        <Panel xl={6} lg={6} md={12} sm={12} xs={12}
            onRefresh={onRefresh}
            refresh={refreshResources}
            title="Resources" >
            <Resources
                openResourcesModal={openResourcesModal}
                resource={resource}
                refreshResources={refreshResources}
                handleEditResources={handleEditResources}
                handleDeleteResources={handleDeleteResources}
                resource={resource}
                columns={columns}
                editResources={true}
            />

        </Panel>
    );
}



export default AdminResources;
