import React from 'react'
import { Chip, Avatar,} from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';
import Rating from '@material-ui/lab/Rating';
export default function image({ src, programName, activities, detailsLoading, organisationName,
    rating,
    avatar,type }) {
    return (
        <>
       
            <div className="product-gallery">
                
                {detailsLoading
                    ?
                    (<Skeleton animation="wave" variant="rect" width="100%" height={300} />)
                    :
                    (<img src={src ? src : `${process.env.PUBLIC_URL}/img/details.jpg`} alt="program-img" />)
                }
                {type !== "provider" && ( <div className="flex flex-mddle flex-start my-20">
                    <Avatar src={avatar? avatar: `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`} />
                    <div className="mx-10 flex flex-middle">
                        <div className="mx-4">
                            <span>
                                {organisationName}
                            </span>
                            <div>
                                <span>
                                    {/* <Rating name="read-only" value={rating} readOnly /> */}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>)}
               
            </div>
        </>

    )
}
