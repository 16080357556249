
import { PROGRAM_LIST_FOR_LINK_MANAGEMENT, LINK_MANAGEMENT_LIST, IS_LINK_CREATED } from "../types";
    
    const initialState = {
      programListByProvider: [],
      linkManagementList: []
    }
const linkManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROGRAM_LIST_FOR_LINK_MANAGEMENT:
            return {
                ...state,
                programListByProvider: action.payload,
            };
        case LINK_MANAGEMENT_LIST:
            return {
                ...state,
            linkManagementList: action.payload,
            };
        default:
            return state;
    }
};

export default linkManagementReducer;
