import { GET_PROVIDER_PROFILE, UPDATE_PROVIDER_PROFILE, PROVIDER_LIST, PROVIDER_PROFILE_LOADING } from "../types";

const initialState = {
    profileLoading: true,
    providerProfile: {},
    updatedProfile: [],
    providerList: []
}

const providerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVIDER_PROFILE:
            return {
                ...state,
                providerProfile: action.payload
            }
        case UPDATE_PROVIDER_PROFILE:
            return {
                ...state,
                updatedProfile: action.payload
            }
        case PROVIDER_LIST:
            return {
                ...state,
                providerList: action.payload
            }
        case PROVIDER_PROFILE_LOADING:
            return {
                ...state,
                profileLoading: action.payload
            }
        default:
            return state;
    }
}
export default providerReducer;