/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import ProviderForm from "./ProviderForm";
import { Row, Col, ButtonToolbar } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../../../../utils/apiClient";
import { getProviderProfile } from "../../../../../../redux/actions/profileActions";
import { setUserData } from "../../../../../../redux/actions/authActions";
import {
  showFailedNoti,
  showSucessNoti,
} from "../../../../../../redux/actions/notificationAction";
import { Skeleton } from "@material-ui/lab";
import { userService } from "../../../../../../Services/userService";
import { authRoles } from "../../../../../../config/authroles";
import { Button, } from "@material-ui/core";
import { getLocationData } from "shared/helpers/index"
const ProfileUpdate = () => {
  const [isEdit, setIsEdit] = useState(true);
  const pMedia = useSelector((state) => state.media.providerMedia);
  const { providerLoading } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const providerSubmit = async (values) => {
    let data = {
      fName: values.fName,
      mName: values.mName,
      lName: values.lName,
      email: values.email,
      organisationName: values.organisationName,
      activity: values.activity,
      locations: values.locations,
      city: values.city,
      description: values.description,
      website: values.website,
      contactNo: values.contactNo,
      logoUrl: pMedia ? pMedia : values.logoUrl,
      ageGroup: values.ageGroup,
      WhyDoYouLuvWhatUDo: values.whyDoULuvWhatUDo,
    };
    let userData = {
      name: values.fName + values.mName + values.lName,
      avatar: pMedia ? pMedia : values.logoUrl,
      role: authRoles.Provider,
    };
    await api.profile.updateProviderProfile(data, true).then((res) => {
      if (res.data) {
        dispatch(getProviderProfile());
        dispatch(showSucessNoti(res.data.message));
        dispatch(setUserData(userData));
        userService.setUserLocal(userData);
        setIsEdit(true)
      } else {
        dispatch(showFailedNoti("Something went wrong!"));
      }
    });
  };
  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };
  return (
    <>
      <div className="mb-3 text-right">
        <Button
          variant={isEdit ? "contained" : "outlined"}
          onClick={toggleEdit}
          color="primary"
        >
          Edit
        </Button>
      </div>
      {providerLoading ? (
        <div>
          <Row>
            <Col md={8}>
              <Skeleton animation={false} height={30} />
              <Skeleton animation={false} height={30} />
            </Col>
            <Col md={4}>
              <Skeleton variant="rect" variant="circle" width={80} animation="wave" height={80} />
            </Col>
          </Row>
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
          <Skeleton animation={false} height={30} />
        </div>
      ) : (
        <ProviderForm providerSubmit={providerSubmit} isEdit={isEdit} />
      )}
    </>
  );
};

export default ProfileUpdate;
