import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from "@material-ui/core"
import { TableRow, TableCell, Tooltip, IconButton, Icon } from "@material-ui/core"
import { Card, CardBody } from "reactstrap"
import NoRecord from "shared/components/NoRecord"
import {getAnnouncementByUser} from "redux/actions/AnnouncementAction"
import SearchableTable from "shared/components/SearchableTable"
import ConfirmationModal from "shared/components/ConfirmationModal"
const rows = [
    {
        id: 1, label: 'Title',
    },
    {
        id: 6, label: '',
    }
];
const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        }
    },
    resolve:{
        color: "#299e02"
    },
    not_resolve: {
        color: "#a7a58e"
    }

})
const AnnouncementList = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setrowsPerPage] = React.useState(5)
    const [searchValue, setSearchValue] = React.useState("")
    
    const [dialog, setDialog] = React.useState(false)
    const [currentItem, setCurrentItem] = React.useState({})
    const annuncementList = useSelector(state => state.announcement.annuncementByUser)
    const handleChangePage = (event, page) => {
        setPage(page)
    };
    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value)
    };
    const clearSearch = () => {
        setSearchValue("")
    }

    const handleView =(val) => {
        setCurrentItem(val)
        setDialog(true)
    }
    const handleAnnouncementSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    useEffect(()=>{
        dispatch(getAnnouncementByUser())
    }, [])
    let filteredData = annuncementList
    if (searchValue) {
        filteredData = annuncementList.filter((item) => item.subject.toLowerCase().startsWith(searchValue.toLowerCase()))
    }
    return (
        <><Card>
            <CardBody>
            <SearchableTable
                        title="Announcement"
                        rows={rows}
                        searchValue={searchValue}
                        filteredData={filteredData}
                        clearSearch={clearSearch}
                        actionTablebtn={false}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        getSearchResult={handleAnnouncementSearch}
                    >
                        {filteredData.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                            .map((d, i) => {
                                return (
                                    <TableRow
                                        className="material-table__row cursor-pointer"
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={d.id}
                                    >
                                        <TableCell className="material-table__cell" padding="checkbox">
                                            {(i + 1) +(page*rowsPerPage)}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right "
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            {d.subject}
                                        </TableCell>
                                        <TableCell
                                    className="material-table__cell material-table__cell-right"
                                >
                                    <IconButton color="primary" onClick={()=> handleView(d)}>
                                        <Tooltip title="info">
                                            <Icon>info</Icon>
                                        </Tooltip>

                                    </IconButton>
                                </TableCell>
                                    </TableRow>
                                );
                            })}
                        </SearchableTable>
                {dialog && (<ConfirmationModal
            isOpen={dialog}
            color={"primary"}
            closeModal={() => setDialog(false)}
            title={currentItem.subject}
            message={currentItem.description}
            noToolbar={true}
        />)}
            </CardBody>
        </Card>
        </>
    )
}

export default AnnouncementList