
import {  post, get } from './base/index';


export default{
    getproviderEvents: () => {
      return get(`${window.apiUrl}Program/all-program-dates`,{},true) 
   },
   getparentsEvents: () => {
      return get(`${window.apiUrl}Program/parent-program-dates`,{},true)
   }
}