
import {
    CART_ODER,
    TOTAL_COST,
    DISCOUNT_AMOUNT,
    GRAND_TOTAL,
    CART_LOADING
} from '../types';
const initialState = {
    cartProduct: {
        id: 1,
        orders: []
},
    totalCartCost: 0,
    discountAmount: 0,
    grandTotalCart: 0,
    cartLoading: true
};

const cartReducer = (state = initialState, action) => {

    switch (action.type) {
        case CART_ODER:
            return {
                ...state,
                cartProduct: action.payload,
                cartLoading: false
            }
        case TOTAL_COST:
            return {
                ...state,
                totalCartCost: action.payload
            }
        case DISCOUNT_AMOUNT:
            return {
                ...state,
                discountAmount: action.payload
            }
        case GRAND_TOTAL:
            return {
                ...state,
                grandTotalCart: action.payload
            }
        case CART_LOADING:
            return {
                ...state,
                cartLoading: action.payload
            }
        default:
            return state;
    }
};

export default cartReducer;
