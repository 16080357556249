import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { authRoles } from "../../config/authroles"
import SuperAdminStudent from "./SuperAdminStudent/index"
import ProviderStudent from "../Student/index"
import HelmetComponent from "shared/components/HelmetComponent"
import {seoData} from "shared/StaticData"


const Listings = () => {
  const auth = useSelector(state => state.user)
  const renderUi = () => {
    if (auth.role === authRoles.Provider) {
        return <ProviderStudent/>
    } else if (auth.role === authRoles.SuperAdmin) {
        return <SuperAdminStudent/>
    } else {
    
        return null
    }
}

    return <>
        {/* <HelmetComponent seoData={ seoData.listings}/> */}
       {renderUi()}
    </>
}

export default Listings