import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IconButton, Icon, Tooltip, TableRow, TableCell } from "@material-ui/core"
import { getParentFavorite, getParentProviderFavById } from "../../../redux/actions/favoriteAction"
import SearchableTable from "../../../shared/components/SearchableTable"
import FavDetailModal from "./components/FavDetailModal"
import { Card, CardBody } from "reactstrap"
const Favourite = () => {
    const [favModal, setFavModal] = React.useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(50)
    const [searchValue, setSearchValue] = useState("")
    const dispatch = useDispatch()
    const { parentfavProvider, favPovLoading } = useSelector(state => state.fav)
    React.useEffect(() => {
        dispatch(getParentFavorite())
    }, [])
    const rows = [
        {
            id: "name", label: 'Provider Name',
        },
        {
            id: "email", label: 'Organisations',
        },
        {
            id: "contact", label: 'Email',
        },
        {
            id: "website", label: 'Contact',
        },
        {
            id: "city", label: 'City',
        },
        {
            id: "website", label: 'Website',
        },
        {
            id: "location", label: 'City',
        },
        {
            id: "action", label: '',
        },

    ];
    const getDetailsInfo = (id) => {
        dispatch(getParentProviderFavById(id)).then((() => {
            setFavModal(true)
        }))


    }
    const handleClose = () => {
        setFavModal(false)
    }
    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value)
    };

    const handleFavSearch = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value)
    }
    const clearSearch = () => {
        setSearchValue("")
    }
    let filteredData = parentfavProvider
    if (searchValue) {
        filteredData = parentfavProvider.filter((user) => user.name.toLowerCase().startsWith(searchValue.toLowerCase()))
    }
    return (
        <>
            <Card>
                <CardBody>

                    <SearchableTable
                        title="Favourite"
                        rows={rows}
                        searchValue={searchValue}
                        filteredData={filteredData}
                        clearSearch={clearSearch}
                        loading={favPovLoading}
                        actionTablebtn={false}
                        getDetailsInfo={getDetailsInfo}
                        searchLabel="Search Fav"
                        searchPlaceholder="By name"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        getSearchResult={handleFavSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    >

                        {filteredData
                            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                            .map((d, i) => {
                                let loc = []
                                d.location.map((l) => loc.push(l.name))
                                return (
                                    <TableRow
                                        className="material-table__row cursor-pointer"
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={d.id}
                                    // onClick={() => getDetailsInfo(d.id)}
                                    >
                                        <TableCell className="material-table__cell" padding="checkbox">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            {d.name}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >{d.organisationName}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >{d.email}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >{d.contactNo}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >{d.city}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >
                                            <a href={d.website} target="_blank">{d.website}</a>
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >{loc.join(", ")}
                                        </TableCell>
                                        {/* <TableCell
                                    className="material-table__cell material-table__cell-right"
                                >
                                    <IconButton color="primary" onClick={() => getDetailsInfo(d.id)}>
                                        <Tooltip title="info">
                                            <Icon>info</Icon>
                                        </Tooltip>

                                    </IconButton>
                                </TableCell> */}
                                    </TableRow>
                                );
                            })}
                    </SearchableTable>

                    <FavDetailModal
                        title="Details"
                        isOpen={favModal}
                        closeModal={handleClose}

                    />

                </CardBody>
            </Card>
        </>

    )
}

export default Favourite