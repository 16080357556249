import React from 'react'
import PopularTags from "../../shared/components/PopularTags/PopularTags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from 'reactstrap';
import SkeletonCard from "shared/components/CardComponent/SkeletonCard";
import HelmetComponent from "shared/components/HelmetComponent"
import { seoData } from "shared/StaticData"
import { getFeaturedList } from "redux/actions/FeaturedAction"
import DwulCard from "shared/components/CardComponent/DwulCard";
import NoRecord from "shared/components/NoRecord"
export default function Featured() {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(getFeaturedList())
    }, [])
    const { featuredList,featureLoading } = useSelector(state => state.featured)
    return (
        <>
            <HelmetComponent seoData={seoData.featured} />
            {/* <PopularTags /> */}
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="card__title">
                            <h3 className=" bold-text">Featured</h3>
                            <span className="text-muted text-tranform-none">Update your Interests in your profile setting and check for activities in this "Featured" page.</span>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <>
                        {!featureLoading && featuredList.length === 0 && (<Col ><NoRecord /></Col>)}
                        {featureLoading && (<SkeletonCard />)}
                        {featuredList && featuredList.length > 0 && featuredList.map((item, index) => {
                            return (
                                <div className="flex flex-middle ml-10 mb-15" key={index}>
                                    <DwulCard
                                        name={item.programName}
                                        image={item.programPhoto}
                                        description={item.description}
                                        id={item.id}
                                        newTag={item.new}
                                        discountTag={item.discount}
                                        specialDates={item.specialDates}
                                        availability={item.availability}
                                        location={item.locationName}
                                        cost={item.cost}
                                        activities={item.activities}
                                        key={index}
                                        isManage={false}
                                        item={item}
                                        capacity={item.capacity}
                                        sessionPerCost={item.sessionsForCost}
                                        regulerDates={item.regulerDates}
                                    />
                                </div>
                            )
                        })}
                    </>
                </Row>
            </Container>

        </>
    )
}
