/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from "react-redux"
import { Drawer, Icon, IconButton, Card, Button, Badge } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { getAllNotification, deleteNotification, notificationCount, clearNotiFromApi } from "../../../redux/actions/notificationAction"
import { getTimeDifference } from "shared/helpers/index"
import { notificationType } from "config/notificationType"
import NoRecord from "shared/components/NoRecord"
class TopbarNotification extends Component {
  state = {
    collapse: false,
  };
  notiRef = React.createRef()
  componentDidMount() {
    this.renderNotifications()
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
    // this.props.notificationCount(0)
    this.renderNotifications()
  };
  renderNotifications = () => {
    let { getAllNotification } = this.props
    getAllNotification()
  }
  //  get type of notification
  getNotificationType = (notiType) => {
    switch (notiType) {
      case notificationType.AccountRegistration:
        return {
          icon: "person",
          name: "Registration",
          color: "primary",
          path: "/users"
        }
      case notificationType.Payment:
        return {
          icon: "payment",
          name: "Payment",
          color: "primary",
          path: "/activities"
        }
      case notificationType.Review:
        return {
          icon: "rate_review",
          name: "Review",
          color: "primary",
          path: "/activities"
        }
      case notificationType.Message:
        return {
          icon: "message",
          name: "Message",
          color: "primary",
          path: "/activities"
        }
      case notificationType.NewExperience:
        return {
          icon: "local_offer",
          name: "Experience",
          color: "primary",
          path: "/activities"
        }
      case notificationType.NewProgram:
        return {
          icon: "local_offer",
          name: "Program",
          color: "primary",
          path: "/activities"
        }
      case notificationType.Message:
        return {
          icon: "message",
          name: "Message",
          color: "primary",
          path: "/activities"
        }
      case notificationType.Refund:
        return {
          icon: "money",
          name: "Refund",
          color: "primary",
          path: "/activities"
        }
      case notificationType.Cancellation:
        return {
          icon: "Block",
          name: "Cancellation",
          color: "error",
          path: "/activities"
        }
      default:
        return {
          icon: "",
          name: "",
          color: ""
        }
    }
  }

  render() {
    const { collapse } = this.state;
    let { notificationList = [], notiLoading, deleteNotification, notiCount } = this.props

    let filteredNoti = notificationList.filter((read) => !read.isRead)
    return (
      <>
        <Button onClick={this.toggle} className="topbar__btn topbar__btn--mail topbar__btn--new px-0">
          <Badge badgeContent={parseInt(notiCount)} color="secondary" max={9999}>
            <NotificationsIcon fontSize="large" className="text-grey " />
          </Badge>
        </Button>
        <Drawer
          width={"100px"}
          ref={this.notiRef}
          variant="temporary"
          anchor={"right"}
          open={collapse}
          onClose={this.toggle}
          ModalProps={{
            keepMounted: true
          }}
        >
          <div className="notification_drawer">
            <div className="notification__topbar flex flex-middle p-16 mb-24">
              <Icon color="primary">notifications</Icon>
              <h5 className="ml-8 my-0 font-weight-500">Notifications</h5>
            </div>
            {notificationList.length === 0 && !notiLoading
              &&
              (<NoRecord icon="notifications" message="No new notification." />)}
            {notiLoading
              &&
              (<div className="ml-8">
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
              </div>)}
            {!notiLoading && notificationList.map(notification => {

              return <div
                key={notification.id}
                className="notification__card position-relative"
              >
                <IconButton
                  size="small"
                  className={"delete-button bg-light-grey mr-24"}
                  onClick={() => deleteNotification(notification.id)}
                >
                  <Icon className="text-muted" fontSize="small">
                    clear
                  </Icon>
                </IconButton>
                <Link to={this.getNotificationType(notification.notificationType).path} onClick={this.toggle}>
                  <Card className="mx-16 mb-24" elevation={3}>
                    <div className={`card__topbar flex flex-middle flex-space-between p-8  ${notification.isRead ? "bg-light-grey" : "noti_read"} `}>
                      <div className="flex">
                        <div className="card__topbar__button">
                          <Icon
                            className="card__topbar__icon"
                            fontSize="small"
                            color={this.getNotificationType(notification.notificationType).color}
                          >
                            {this.getNotificationType(notification.notificationType).icon}
                          </Icon>
                        </div>
                        <span className="ml-4 font-weight-500 text-muted">
                          {this.getNotificationType(notification.notificationType).name}
                        </span>
                      </div>
                      <small className="card__topbar__time text-muted">
                        {getTimeDifference(new Date(notification.date))} ago
                      </small>
                    </div>
                    <div className="px-16 pt-8 pb-16">
                      <p className="m-0">
                        {notification.notificationMessage}
                      </p>
                    </div>
                  </Card>
                </Link>
              </div>

            }
            )}
            {notificationList.length > 0 && (<div className="text-center">
              <Button onClick={() => this.props.clearNotiFromApi()} >Clear Notifications</Button>
            </div>)}

          </div>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationList: state.notification.notificationList,
    notiLoading: state.notification.notiLoading,
    notiCount: state.notification.notiCount
  }
}
export default connect(mapStateToProps, { getAllNotification, deleteNotification, notificationCount, clearNotiFromApi })(TopbarNotification)