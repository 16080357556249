import { post, get } from './base/index';

export default {
    post: (data, headers) => {
        const body = data;
        return post(`${window.apiUrl}Program/ManageProgram`, body, headers)
    },
    get: () => {
        return get(`${window.apiUrl}Program/provider-programs`)
    },
    getAllProgram: (programData, headers) => {
        console.log("are tags yaha hain", programData)
        const body = {
            searchText: programData.searchText ? programData.searchText : "",
            tags: programData.tags ? programData.tags : [0],
            //-----------------------tags------------------------
            activityTags: programData.activityTags ? programData.activityTags : [],
            locationTags: programData.locationTags ? programData.locationTags : [],
            //-----------------------tags------------------------
            ageGroup: programData.ageGroup ? programData.ageGroup : "",
            dayOfWeek: programData.dayOfWeek ? programData.dayOfWeek : "",
            pageNo: programData.pageNo ? programData.pageNo : 0,
            minimumAge: programData.minimumAge ? programData.minimumAge : 0,
            maximumAge: programData.maximumAge ? programData.maximumAge : 0,
            pageSize: programData.pageSize ? programData.pageSize : 0
        }; 
        console.log("test ")
        
        
        
        return post(`${window.apiUrl}Program/all-programs`, body, headers)
    },
    getProgramById: (id, headers) => {
        return get(`${window.apiUrl}Program/GetProgramById?ProgramId=${id}`, {}, headers)
    },
    deleteProgram: (id, headers) => {

        return post(`${window.apiUrl}Program/delete-program?programId=${id}`, {}, headers)
    },
    getProviderProgram: (headers) => {
        return get(`${window.apiUrl}Program/provider-programs?Page_size=10000&Page_no=1`, {}, headers)
    },
    manageProgram: (data, headers) => {
        let body = data
        return post(`${window.apiUrl}Program/ManageProgram`, body, headers)
    },
    addProgramAdmin: (data, headers) => {
        let body = data
        return post(`${window.apiUrl}Program/add-program-admin`, body, headers)
    },
    enrolledPrograms: (headers) => {

        return get(`${window.apiUrl}Program/enrolled-programs?Page_size=10000&Page_no=1`, {}, headers)
    },
    allProgramDates: (headers) => {
        return get(`${window.apiUrl}Program/all-program-dates`, {}, headers)
    },
    programListing: (id, headers = true) => {
        return get(`${window.apiUrl}Program/programs-by-provider-id?providerId=${id}`, {}, headers)
    }
}