import { Button, Divider } from "@material-ui/core";
import CardNew from "containers/LinkManagement/CardNew";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { setLocationTag } from 'redux/actions/tagAction';
import PopularTags1 from 'shared/components/PopularTags/PopularTags1';
import { validate } from "../../config/formValidation";
import { mediaTypes } from "../../config/mediaTypes";
import { tagTypes } from '../../config/tagTypes';
import ActivityTag from "../../shared/components/form/ActivityTag";
import ImageUpload from "../../shared/components/form/ImageUpload";
import { renderField } from "../../shared/components/form/RenderField";


const ProfileInfoLink = ({
    handleSubmit,
    submitting,
    valid,
    programImage,
    programSubmit,
    registrationFlag,
    programMedia,
    initialValues,
    flyer,
    providerMedia,
    isEdit,
    regularCount,
    specialCount,
    closeModal,
    setArrProgram,

}) => {
    const dispatch = useDispatch()
    const Tag = useSelector(state => state.tags.selectedTag)
    const providerProgramData = useSelector(state => state.program.providerProgramData)
    const linkManagementList = useSelector(state => state.program)

    const [providerId, setProviderId] = useState([]);

    const handleSelectedTag = (tag) => {

        dispatch(setLocationTag())
    }

    const temp = useSelector(state => state.program.programById.progamDetails)
    console.log("===providerISDD===>", temp)

    useEffect(() => {
        if (temp)
            temp.forEach(item => {
                setProviderId(arr => arr.concat(item.id));
            });
    }, [])


    const handleProviderId = (id) => {
        if (providerId.includes(id)) {
            setProviderId(arr => arr.filter(i => i !== id))
        } else {
            setProviderId(arr => arr.concat(id));
        }
    }

    useEffect(() => {
        setArrProgram(providerId)
    }, [providerId])



    return (
        <form className="login-form" onSubmit={handleSubmit(programSubmit)}>
            <Row>
                <Col md={10} lg={10} sm={10}>

                    <Col md={2} lg={2} sm={2} className="pl-0">
                        <ImageUpload
                            name="programPhoto"
                            accept="image/*"
                            image={programMedia ? programMedia : initialValues.programPhoto}
                            mediatype={mediaTypes.Program}
                        />
                    </Col>

                    <div className="form__form-group">
                        <Field
                            name="programName"
                            label="Name*"
                            component={renderField}
                            type="text"
                            placeholder="Name"
                            startIcon='people'

                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="locations"
                            label="City Tags"
                            component={ActivityTag}
                            type='text'
                            placeholder="City tags"
                            className="input-without-border-radius"
                            startIcon='location_on'
                            defaultValue={{ id: initialValues.locationId, name: initialValues.locationName }}
                            tagType={[tagTypes.Location, tagTypes.School]}

                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="description"
                            label="Description"
                            component={renderField}
                            type="text"
                            placeholder="Description"
                            startIcon='people'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="websiteLink"
                            label="Website"
                            component={renderField}
                            type="text"
                            placeholder="Website"
                            startIcon='website'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="facebookLink"
                            label="Facebook Link"
                            component={renderField}
                            type="text"
                            placeholder="Facebook Link"
                            startIcon='Facebook'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="twitterLink"
                            label="Twitter Link"
                            component={renderField}
                            type="text"
                            placeholder="Twitter Link"
                            startIcon='Twitter'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="instagramLink"
                            label="Instagram Link"
                            component={renderField}
                            type="text"
                            placeholder="Instagram Link"
                            startIcon='Instagram'
                        />
                    </div>

                    <div className="form__form-group">
                        <Field
                            name="youtubeLink"
                            label="Youtube Link"
                            component={renderField}
                            type="text"
                            placeholder="Youtube Link"
                            startIcon='Youtube'
                        />
                    </div>

                </Col>

            </Row>
            <Divider />
            <div className="my-10 flex flex-end flex-middle ">
                <div >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!valid || submitting}

                    >Submit
                    </Button>
                </div>
                <div className="px-10">
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={closeModal}
                    >Close
                    </Button>
                </div>


            </div>

        </form>
    );
};
const selector = formValueSelector('ProfileInfoLink')

export default connect(state => {
    const hasregulerDates = selector(state, 'regulerDates')
    const hasspecialDates = selector(state, 'specialDates')
    const registrationFlag = selector(state, 'registrationFlag')
    const regularStartTime = selector(state, 'regularStartTime')
    const regularEndTime = selector(state, 'regularEndTime')
    // console.log(JSON.stringify(state.program))
    const specialStartTime = selector(state, 'specialStartTime')
    const specialEndTime = selector(state, 'specialEndTime')
    // 
    // 
    // debugger
    return ({
        errorMsg: state.user.error,
        programMedia: state.media.programMedia,
        flyer: state.media.programPdf,
        regularCount: hasregulerDates ? hasregulerDates.filter(n => n) : [],
        specialCount: hasspecialDates ? hasspecialDates.filter(n => n) : [],
        registrationFlag: registrationFlag,
        initialValues: {
            websiteLink: state.program.programById.websiteLink ? state.program.programById.websiteLink : "",
            facebookLink: state.program.programById.facebookLink ? state.program.programById.facebookLink : "",
            twitterLink: state.program.programById.twitterLink ? state.program.programById.twitterLink : "",
            instagramLink: state.program.programById.instagramLink ? state.program.programById.instagramLink : "",
            youtubeLink: state.program.programById.youtubeLink ? state.program.programById.youtubeLink : "",
            programName: state.program.programById.programName ? state.program.programById.programName : "",
            programLocation: state.program.programById.programLocation ? state.program.programById.programLocation : "",
            categoryTags: state.program.programById.categoryTags ? state.program.programById.categoryTags : "",
            specialEndTime: state.program.programById.specialEndTime ? state.program.programById.specialEndTime : null,
            specialStartTime: state.program.programById.specialStartTime ? state.program.programById.specialStartTime : null,
            regularEndTime: state.program.programById.regularEndTime ? state.program.programById.regularEndTime : null,
            regularStartTime: state.program.programById.regularStartTime ? state.program.programById.regularStartTime : null,
            locations: state.program.programById.providerProfile ? state.program.programById.providerProfile.locations : [],
            programPhoto: state.program.programById.providerProfile ? state.program.programById.providerProfile.logoUrl : "",
            locationName: state.program.programById.locationName ? state.program.programById.providerProfile.locationName : "",
            flyer: state.program.programById.flyer ? state.program.programById.flyer : "",
            activity: state.program.programById.activity ? state.program.programById.activity : [],
            ageGroup: state.program.programById.ageGroup ? state.program.programById.ageGroup : [],
            capacity: state.program.programById.capacity ? state.program.programById.capacity : 0,
            cost: state.program.programById.cost ? state.program.programById.cost : 0,
            description: state.program.programById.providerProfile ? state.program.programById.providerProfile.description : "",
            // getData: state.program.programById.getData ? state.program.programById.getData : "",


            id: state.program.programById.id ? state.program.programById.id : 0,
            regulerDates: state.program.programById.regulerDates ? state.program.programById.regulerDates : [''],
            noSession: state.program.programById.noSession ? state.program.programById.noSession : [''],
            specialDates: state.program.programById.specialDates ? state.program.programById.specialDates : [''],
            sessionsForCost: state.program.programById.sessionsForCost ? state.program.programById.sessionsForCost : 1,
            registrationFlag: state.program.programById.registrationFlag ? state.program.programById.registrationFlag : false,
            externalLink: state.program.programById.externalLink ? state.program.programById.externalLink : "",

            placeName: state.program.programById.placeName ? state.program.programById.placeName : '',
            streetAddress: state.program.programById.streetAddress ? state.program.programById.streetAddress : '',
            room: state.program.programById.room ? state.program.programById.room : '',
            minimumAge: state.program.programById.minimumAge ? state.program.programById.minimumAge : '',
            maximumAge: state.program.programById.maximumAge ? state.program.programById.maximumAge : '',
        }
    })
}
)(reduxForm({
    form: 'ProfileInfoLink',
    enableReinitialize: true,
    validate
})(ProfileInfoLink));