import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import StudentModel from "./StudentModal";
import { useSelector } from "react-redux";
import { IconButton, Icon, TableRow, Tooltip, TableCell } from "@material-ui/core";
import SearchableTable from "../../../../shared/components/SearchableTable";
import { Card, CardBody } from "reactstrap"
import moment from "moment";

const columns = [
  {
    id: 1, label: "Name",
  },

  {
    id: 3, label: "Gender",
  },
  {
    id: 4, label: "Email",
  },

  {
    id: 5, label: "Phone",
  },

  {
    id: 7, label: " Action",
  },

];

const StudentTable = ({ loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");

  const AllStudentList = useSelector(
    (state) => state.student.studentAllList
  );


  const clearSearch = () => {
    setSearchValue("");
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };

  const handleFavSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const tableData = () => {
    let tableData = [];
    if (AllStudentList) {
      AllStudentList.map((sub) => {
        tableData.push({
          id: sub.id,
          studentName: sub.fName + " " + sub.lName,
          gender: sub.gender,
          email: sub.email,
          emergencyContact: sub.emergencyContact,
          schoolName: sub.schoolName,
          currentAcademicDate: sub.currentAcademicDate,
          birthDate: sub.birthDate,
          image: sub.image,
          grade: sub.grade,
          medicalCondition: sub.medicalCondition,
          learningNeeds: sub.learningNeeds
        });
      });
    }
    return tableData;
  };
  let filteredData = tableData();
  if (searchValue) {
    filteredData = tableData().filter((search) =>
      search.studentName.toLowerCase().startsWith(searchValue.toLowerCase())
    );
  }



  return (
    < div >
      <Card>
        <CardBody>
          <SearchableTable
            title="Student List"
            rows={columns}
            searchValue={searchValue}
            filteredData={filteredData}
            clearSearch={clearSearch}
            loading={loading}
            actionTablebtn={false}
            searchLabel="Search Student"
            searchPlaceholder="By name"
            rowsPerPage={rowsPerPage}
            page={page}
            getSearchResult={handleFavSearch}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d, i) => {
                return (
                  <TableRow
                    className="material-table__row"
                    role="checkbox"
                    tabIndex={-1}
                    key={d.id}
                    id="divToPrint"
                  >
                    <TableCell className="material-table__cell" padding="checkbox">
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className="material-table__cell material-table__cell-right text-nowrap"
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      {d.studentName}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.gender}
                    </TableCell>
                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.email}
                    </TableCell>

                    <TableCell className="material-table__cell material-table__cell-right">
                      {d.emergencyContact}
                    </TableCell>

                    <TableCell className="material-table__cell">
                      <StudentModel Student={d} />
                    </TableCell>

                  </TableRow>
                );
              })}
          </SearchableTable>
        </CardBody>
      </Card>

    </ div>
  );
};

StudentTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(StudentTable);
