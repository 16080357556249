import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTagsByTagType, setLocationTag, setMinimumAgeTag } from "../../../../redux/actions/tagAction"
import { tagTypes } from "../../../../config/tagTypes"
import { Chip, Icon } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0),
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.9),
    },
  },
}));
const MinimumAgeTag = ({ handleTagSelect, selectedTag }) => {
  const classes = useStyles();

  const minimumAgeTag = useSelector(state => state.tags)
  console.log("checking==================>",minimumAgeTag)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch((getTagsByTagType([tagTypes.MinimumAge])))
}, [])
  /**
   * 
   * @param {*} color 
   * @return{string} change color of tag
   */
  const getColorTag = (color) => {
    switch (color) {
      case "primary":
        return "secondary"
      case "secondary":
        return "primary"
      default:
        return ""
    }
  }
  const getVariantTag = (variant) => {
    switch (variant) {
      case "default":
        return "outlined"
      case "outlined":
        return "default"
      default:
        return ""
    }

  }
  const handleSelectedTag = (tag) => {
    let filterData = []
    minimumAgeTag.filter((l) => {
      if (l.id === tag.id) {
        let newS = {
          searchText: "",
          // id: l.id,
          tags:null,
          minimumAge: 6,
          maximumAge: 11,
          pageSize: 0,
          ageGroup: "",
          dayOfWeek: "",
          pageNo: "",
        }
        filterData.push(newS)
      } else {
        filterData.push(l)
      }
    })
    dispatch(setMinimumAgeTag(filterData))
  }
  return <div className={classes.root}>
    {/* {minimumAgeTag &&
      minimumAgeTag.map((tag, i) => (

        <Chip
          key={i}
          icon={<Icon fontSize="small" >location_on</Icon>}
          color={tag.color}
          label={tag.minimumAge}
          variant={tag.variant}
          onClick={() => handleSelectedTag(tag)}
        />

      ))} */}



  </div>

}



export default MinimumAgeTag    