import React, { Component } from "react";
import { Card, CardBody, Col,  } from "reactstrap";
import { Field, reduxForm, } from "redux-form";
import { renderField } from "../../../../shared/components/form/RenderField";
import { withTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import LoadingIcon from 'mdi-react/LoadingIcon';
import { validate } from "../../../../config/formValidation";
import { sendQuery,contactUsLoad } from "../../../../redux/actions/contactUsAction";
function ContactForm({ handleSubmit, submitting, valid, reset }) {
  const dispatch = useDispatch();

  const contactUs = async (val) => {
    let data = {
      name: val.name,
      email: val.email,
      subject: val.subject,
      description: val.message,
    };
    dispatch(sendQuery(data));
    // dispatch(contactUsLoad(data))
  };

  const contactUsLoading = useSelector(state => state.contactUs.contactUsLoading)

  


  return (
    <Col md={6} xs={12} xl={6} lg={6} sm={12}>
      <Card>
        <CardBody>
        {contactUsLoading ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
          <form onSubmit={handleSubmit(contactUs)} >
            <Col md={12} xs={12} lg={12} >
              <Field
                name="name"
                label="Name*"
                component={renderField}
                type="text"
                startIcon="people"
                placeholder="Name"
              />
            </Col>
            <br />
            <Col md={12} xs={12} lg={12}>
              <Field
                name="email"
                label="Email*"
                component={renderField}
                type="email"
                startIcon="email"
                placeholder="example@mail.com"
              />
            </Col>
            <br />
            <Col md={12} xs={12} lg={12}>
              <Field
                name="subject"
                label="Subject*"
                component={renderField}
                startIcon="subject"
                type="text"
              />
            </Col>
            <br />
            <Col md={12} xs={12} lg={12}>
              <Field
                name="message"
                label="Message"
                startIcon="description"
                component={renderField}
                type="text"
                multiline={true}
                rows={4}
              />
            </Col>

            <br />
            <div className="my-10 flex flex-end flex-middle ">
            <Button
                color="primary"
                type="submit"
                variant="contained"
                // onClick={reset}
                disabled={!valid || submitting}
                
              >
                Submit
              </Button>
              <div className="px-10"></div>
              <Button color="primary" variant="outlined" onClick={reset}>
                reset
              </Button>{" "}
              <div/>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
}


export default reduxForm({
  form: "Contact_form",
  validate,
})(withTranslation("common")(ContactForm))
