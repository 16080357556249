export const notificationType = {
        AccountRegistration : 1,
        Payment : 2,
        Review :3,
        Message : 4,
        // NewProgram : 5,
        NewExperience :6,
        Refund : 7,
        Cancellation : 8
}

export const messageItemType = {
        Program : 1,
        Experience : 2
}

export const Messages = {
        GeneralError : "Something went wrong"
}