import React, { useState} from "react";


import axios from "axios";

import { Button as BootstrapButton } from "react-bootstrap";
import { Modal as BootstrapModal } from "react-bootstrap";


const SubscribeBox = (show,onHide) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
    // const [show, setShow] = useState(false);



  async function subscribe(email) {
     

    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //     'Authorization': "Bearer " + token
    //   }
    // };
    // 
    await axios.post(window.apiUrl + `Common/add-subscriber`, )
      .then(res => {
                
        setIsSubscribed(true);
      },
        error => {
          console.log("upload error : " + error.message)
          setIsSubscribed(false);
        }
      );
  }
  function toggleModal(){
    setModalOpen(!isModalOpen);
  }
    


  return (
 <BootstrapModal show={show} onHide={onHide}>
                    <BootstrapModal.Header closeButton>
                      <BootstrapModal.Title>Modal heading</BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                      Woohoo, you're reading this text in a modal!
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                      <BootstrapButton
                        variant="secondary"
                        onClick={onHide}
                      >
                        Close
                      </BootstrapButton>
                      <BootstrapButton variant="primary" onClick={onHide}>
                        Save Changes
                      </BootstrapButton>
                    </BootstrapModal.Footer>
                  </BootstrapModal>
   
  );
};

export default SubscribeBox;


 // <Modal
      
      
    //   isOpen={isOpen}
    //     onRequestClose={toggleModal}
    //     contentLabel="Subscribe">
   
      
      {/* <input
        type="email"
       
        label={"Enter your email"}
        placeholder={"Enter your email"}
        variant="outlined"
      />
          {isSubscribed?<div> <button
        type="submit"
        onClick={(e) => {
            e.preventDefault();
            
        }}
        // disabled={isProcessing || !isValid}
       
        color="white"
        style={{
          paddingTop: "16px",
          paddingBottom: "16px"
        }}
      >
        Subscribed
      </button></div>:<div> <button
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          subscribe();
            
        }}
        // disabled={isProcessing || !isValid}
      
        color="white"
        style={{
          paddingTop: "16px",
          paddingBottom: "16px"
        }}
      >
        Subscribe
      </button></div> }
           */}
         
     
      {/* </Modal> */}