import React from 'react';
import {
  Modal, ModalHeader, ModalBody, Button, Col, Row,
} from 'reactstrap';
import ProviderInfo from '../../../../shared/components/providerInfo/ProviderInfo';
const ModalProviderInfo = ({
    title,
    isOpen,
    closeModal,
 
    handleProviderInfo,
    role,
    handleSkip
}) => (
  <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} centered>
   <ModalHeader ><h5><strong>{title}</strong></h5></ModalHeader>
    <ModalBody>
      <ProviderInfo
        handleProviderInfo={handleProviderInfo}
         role={role}      
      />
    </ModalBody>
  </Modal>
);


export default ModalProviderInfo;
