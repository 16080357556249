import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    sidebarCollapse: PropTypes.bool,
  };

  static defaultProps = {
    sidebarCollapse: false,
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToLight, changeToDark, sidebarCollapse } = this.props;

    return (
      <div className="sidebar__content">
          <ul className="sidebar__block">
        {this.props.sidebarMenu && this.props.sidebarMenu.map((menu, i) => (
        
          <SidebarLink
            key={i}
          title={menu.title}
          icon={menu.icon}
          route={menu.route}
            onClick={this.hideSidebar}
          />
    
        ))}
        </ul>
    
        {/* <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            icon="home"
            route="/home"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Featured"
            icon="tag"
            route="/featured"
            onClick={this.hideSidebar}
          />
            <SidebarLink
            title="Interests"
            icon="bookmark"
            route="/interests"
            onClick={this.hideSidebar}
          />
        </ul>
        <ul className="sidebar__block">
          
          <SidebarLink
            title="Activites"
            icon="envelope"
            route="/activities"
            onClick={this.hideSidebar} />
          <SidebarLink 
          title="Community" 
          icon="bubble" 
          route="/community" 
          onClick={this.hideSidebar} />
          <SidebarLink 
          title="Calendar" 
          icon="calendar-full" 
          route="/calender" 
          onClick={this.hideSidebar} />
        </ul>
       
        <ul className="sidebar__block">
          <SidebarLink 
          title="About" 
          icon="info"
           route="/about" />
          <SidebarLink 
          title="Support" 
          icon="question-circle"
           route="/support" />
        </ul> */}
      </div>
    );
  }
}

export default SidebarContent;
