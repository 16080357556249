import React, { useEffect } from 'react'
import { Field, } from 'redux-form'
import { renderField } from "./RenderField"
import { Button, Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon } from "@material-ui/core"
const renderFieldArray = ({ fields, label, type, startIcon, min, max, input, meta: { error } }) => {
    let d = false;
    const handleAdd = () => {
        // alert(label)
        fields.push()
    }

    if (fields.length == 0) {
        handleAdd()

    }

    const handleRemove = (index) => {
        fields.remove(index)
        console.log("removed field", index)
    }
    // const required = value => (value ? undefined : "Required");
    //console.log("fieldsssssssRenderArray", fields)

    return (
        <>
            <List className="p-0">

                <ListItem>
                    {/* <ListItemIcon>
                    <Icon className="event-calender-icon">event_calendar</Icon>
                </ListItemIcon> */}

                    <ListItemText id="switch-list-label-wifi" className="label-new-box" primary={label} />
                    <ListItemSecondaryAction>
                        <Button type="button" onClick={handleAdd}
                            id="add-date-button"
                        >
                            <Icon>add</Icon>
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>

                {
                    fields.length ?
                        <>
                            {fields.map((date, index) => (
                                <li className="flex my-10 " key={index}>
                                    {console.log("dateeeeeeee->>>>>", date)}
                                    {console.log("label "+`${label} #${index + 1}`)}
                                    {/* <ListItemIcon className="list-icon-item">
                                        <Icon className="event">event_calendar</Icon>
                                    </ListItemIcon> */}

                                    <Field
                                        className="input-without-border-radius"
                                        name={date}
                                        type={type}
                                        component={renderField}
                                        label={`${label} #${index + 1}`}
                                        startIcon={startIcon}
                                        min={min}
                                        max={max}
                                    // validate={required}
                                    />
                                    {((index >= 0) && (label == "Special Dates" || label == "No session")) ?
                                        <IconButton
                                            type="button"
                                            title="Remove Hobby"
                                            color="primary"
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Icon>clear</Icon>
                                        </IconButton >
                                        : (index > 0) && (label == "Regular Dates") ? <IconButton
                                            type="button"
                                            title="Remove Hobby"
                                            color="primary"
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Icon>clear</Icon>
                                        </IconButton > : null}
                                </li>
                            ))}
                        </>
                        : "No dates"
                }


                {error && <li className="error">{error}</li>}
            </List>
        </>
    )
}

export default renderFieldArray