import React, { Component } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {withRouter} from "react-router-dom"
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import {setUserData,logoutUser} from "../../../redux/actions/authActions"
import {connect,useSelector,} from "react-redux"
import {authRoles} from "../../../config/authroles"
const Ava = `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`;

class TopbarProfile extends Component {
  static propTypes = {
    user: UserProps.isRequired,
    // auth0: AuthOProps.isRequired,
  }


  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = async() => {
    const { history,logoutUser } = this.props
    logoutUser().then(() => {
    history.push("/")
  })
    
  }

  render() {
    const {auth} = this.props
    const { user } = this.props;
    const { collapse } = this.state;

    return (
      <div className="">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img
            className="topbar__avatar-img"
            src={user.avatar || Ava}
            alt="avatar"
          />
          <p className="topbar__avatar-name">
            {/* { auth0.loading ? 'Loading...' : (auth0.user && auth0.user.name) || user.fullName} */}
          </p>
          <DownIcon className="topbar__icon ml-0" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
           
           { auth.role === authRoles.Parents ?  
            <TopbarMenuLink
            title="Account"
            icon="user"
            path="/profile"
            
            />:
            auth.role === authRoles.Admin ?
            <TopbarMenuLink
              title="Account"
              icon="user"
              path="/profile"
            />
            :
            auth.role === authRoles.SuperAdmin ?
            <TopbarMenuLink
              title="Account"
              icon="user"
              path="/profile"
            />
            :
            null
          }
      
           
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps =(state)=>{
  return {  auth: state.user }
}

export default connect(mapStateToProps,{setUserData,logoutUser})(withRouter(TopbarProfile));
