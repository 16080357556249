import React, { Component } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody
} from "reactstrap";
import classnames from "classnames";
import ProfileMyReviews from "./ProfileMyReviews";
import ProfileUpdate from "./ProfileUpdate";
import Announcement from "./Announcement";
import PromoCode from "./PromoCode";

export default class ProfileTabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
        <Card className="h-auto">
          <CardBody className="p-0">
          <div className="profile__card tabs tabs--bordered-bottom" >
            {/* <div className="tabs__wrap" > */}
              <Nav tabs >
                <NavItem >
                  <NavLink 
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    My Reviews
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink 
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                  >
                    Announcement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                  >
                    Promo Code
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" style={{
                backgroundColor: '#fff',
              }}>
                  <ProfileUpdate />
                </TabPane>
                <TabPane tabId="2" style={{
                backgroundColor: '#fff',
              }}>
                  <ProfileMyReviews />
                </TabPane>
                <TabPane tabId="3" style={{
                backgroundColor: '#fff',
              }}>
                  <Announcement 
                  onRefresh={this.props.handleOnRefreshAnnouncement}
                  openAnnouncementModal={this.props.openAnnouncementModal}
                  closeAnnouncementModal={this.props.closeAnnouncementModal}
                  handleEditAnnouncement={this.props.handleEditAnnouncement}
                  handleDeleteAnnouncement={this.props.handleDeleteAnnouncement}
                  announcementList={this.props.announcementList}
                  />
                </TabPane>
                <TabPane tabId="4" style={{
                backgroundColor: '#fff',
              }}>
                  <PromoCode
                     openPromoCodeModal={this.props.openPromoCodeModal}
                     closePromoCodeModal={this.props.closePromoCodeModal}
                     promocodeList={this.props.promocodeList}
                     handleEditPromo={this.props.handleEditPromo}
                     handleDeletePromo={this.props.handleDeletePromo}
                  />
                </TabPane>
              </TabContent>
            </div>
            </CardBody>
          {/* </div> */}
        </Card>
    
    );
  }
}
