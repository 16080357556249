import React from 'react'

export default function ProviderFAQs() {
    return (
        
      <>
       
      </>
    )
}
