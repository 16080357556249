import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  Modal, ModalHeader, ModalBody, Alert, ModalFooter, ButtonToolbar
} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector, useDispatch } from "react-redux"
import { getparentProfile } from "../../../../redux/actions/profileActions"
import { authRoles } from '../../../../config/authroles';
import NoRecord from "../../../../shared/components/NoRecord"


const ModalAddNewSt = ({
  title,
  isOpen,
  closeModal,
  handleNewStudent,
  handleSkip,
  handleComplete
}) => {
  const studentProfile = useSelector(state => state.profile.studentProfile)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    // calling to get student list
    if (user.isAuthenticated && user.role === authRoles.Parents) {
      // dispatch(getparentProfile(0))
    }

  }, [])
  return (
    <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} size="lg" centered>
      <ModalHeader toggle={closeModal}>
        <h5><strong>{title}</strong></h5>
        </ModalHeader>
      
      <ModalBody>
      <div className="">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNewStudent}>
            Add Student(s)
        </Button>
        </div>
        <List className="mt-10">
          {studentProfile && studentProfile.length > 0 &&
            studentProfile.map((list, key) => (
              <>
                <ListItem key={key}  dense >
                  <ListItemIcon>
                    {key + 1}
                  </ListItemIcon>
                  <ListItemText  primary={`${list.fName} ${list.lName}`} />
                </ListItem>
              </>

            ))
          }
        </List>

      </ModalBody>
      <ModalFooter>
        <ButtonToolbar>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleComplete}>
            Complete</Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleSkip}
            size="medium"
          >Skip</Button>
        </ButtonToolbar>

      </ModalFooter>
    </Modal>
  );
}



export default ModalAddNewSt;
