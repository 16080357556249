import React, { useState } from "react";
import {
  Badge,
} from "reactstrap"
import moment from "moment"
import { makeStyles } from "@material-ui/core/styles";
import { Icon, TableRow, TableCell, IconButton, Button, Divider } from "@material-ui/core";
import HistoryInfo from "./HistoryInfo"
import UpdateIcon from "@material-ui/icons/Update";
import SearchableTable from "../../../shared/components/SearchableTable";
import { Card, CardBody } from "reactstrap"
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import { exportToCsv } from "shared/helpers"
import { paymentStatus } from "config/paymentCode"
import logoImage from "shared/img/logo/LOGO.png"
const rows = [
  {
    id: 1,
    label: "Order Id",
  },
  {
    id: 2,
    label: "Parent Name",
  },
  {
    id: 3,
    label: "Payment Mode",
  },
  {
    id: 4,
    label: "Date of Payment",
  },
  {
    id: 5,
    label: "Amount(CA$)",
  },
  {
    id: 6,
    label: "Promo Code",
  },
  {
    id: 7,
    label: "Payment Status",
  },
  //   id: 7,
  //   label: "Refund Status",
  // }, // {

  {
    id: 8,
    label: "Action",
  },
];
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
const HistoryTable = ({ history, loading, updateStatus = false, handleUpdate }) => {
  const [page, setPage] = useState(0);
  const [historyDetail, sethistoryDetail] = useState({});
  const [historyInfo, sethistoryInfo] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  let filteredData = [] = history;

  if (searchValue) {
    filteredData = history.filter((search) => {
      return (
        moment(search.dateOfPayment).format() ||
        moment(search.dateOfProgram).format()
      ).startsWith(searchValue);
    });
  }

  const clearSearch = () => {
    setSearchValue("");
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
  };

  const handleFavSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setSearchValue(value);
  };

  const handlePdf = (d) => {
    sethistoryDetail(d)
    sethistoryInfo(true)
    setAnchorEl(null)
  }
  const handlePdfDownload = () => {
    const logo = document.createElement("div")
    logo.id = "logo"
    logo.style.width = "90%"
    logo.classList.add("flex", "flex-end")
    const logoImg = document.createElement("img")
    logoImg.src = logoImage
    logoImg.style.width = "80px"
    logo.appendChild(logoImg)

    const button = document.getElementById("buton")
    button.style.display = "none"
    const input = document.getElementById("divToPrint").innerHTML

    const div = document.createElement("div")
    div.id = "print"
    div.style.width = "90%"
    div.style.height = "100%"
    div.style.display = "block"
    div.innerHTML = input
    const mainDiv = document.createElement("div")
    const mainPrint = "mainPrint_" + new Date().toISOString()
    mainDiv.id = mainPrint
    mainDiv.appendChild(logo)
    mainDiv.appendChild(div)
    document.body.append(mainDiv)

    const print = document.getElementById(mainPrint)
    html2canvas(print, {
      useCORS: true,
      allowTaint: true,
      scrollY: -window.scrollY
    })
      .then((canvas) => {

        const image = canvas.toDataURL('image/jpeg');
        const doc = new jsPDF('p', 'pt', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;
        doc.addImage(image, 'JPEG', 20, 20, canvasWidth, canvasHeight)
        doc.save(`${historyDetail.parentName}.pdf`);
      })
    mainDiv.style.display = "none"
    handleClose()
  }
  const handleClose = () => {
    sethistoryInfo(false)
    setAnchorEl(null)
  }
  const handleInfo = (d) => {
    sethistoryDetail(d)
    console.log("historyDetails", historyDetail)
    sethistoryInfo(true)
  }
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const convertToCsv = () => {
    let data = []
    // let csvContent = "";
    let A = [["Oder Id", "Parent Name", "Programs", "Payment Mode", "Payment Status", "Date of Payment", "Promo Code", "Amount($)"]]
    data.push(A)


    filteredData.forEach(d => {
      console.log("filteredData", d)
      return data.push([
        d.id,
        d.parentName,
        d.programOrder.map((item) => item.programName).join(" - "),
        d.paymentMode,
        d.paymentStatusString,
        new Date(d.dateOfPayment).toLocaleDateString(),
        d.promoCode,
        d.amount
      ])

    });
    exportToCsv(data, "payment_history")
    setAnchorEl(null)
  }
  const getStatusColor = (statusType) => {
    switch (statusType) {
      case paymentStatus.Pending:
        return "warning"
      case paymentStatus.Success:
        return "success"
      case paymentStatus.Failed:
        return "danger"
      default:
        return "info"
    }
  }
  const handleRefundRequestUi = (value) => {
    if (value.cancelRequest && !value.refunded) {
      return <Badge color="warning">Pending</Badge>
    } else if (!value.cancelRequest && value.refunded) {
      return <Badge color="Sucess">Success</Badge>
    } else {
      return <Badge color="info">Not requested</Badge>
    }
  }
  return (
    <>
      <Card>
        <CardBody>
          <SearchableTable
            title="History"
            rows={rows}
            filteredData={filteredData}
            loading={loading}
            actionTablebtn={false}
            searchType="date"
            searchLabel="Search By Date"
            searchValue={searchValue}
            clearSearch={clearSearch}
            searchPlaceholder="By name"
            rowsPerPage={rowsPerPage}
            page={page}
            getSearchResult={handleFavSearch}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            actionTablebtn={true}
            actionBtnIcon="publish"
            actionBtnText="Export"
            actionBtnClick={convertToCsv}
          >
            <>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d, i) => {
                  { console.log("filtereddata", d) }
                  return (

                    <TableRow key={d.id}>
                      <TableCell className="material-table__cell">
                        {(i + 1) + (page * rowsPerPage)}
                      </TableCell>
                      <TableCell className="material-table__cell">
                        {d.orderId}
                      </TableCell>
                      <TableCell className="material-table__cell text-nowrap">
                        {d.parentName}
                      </TableCell>
                      <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                        {d.paymentMode}
                      </TableCell>
                      <TableCell className="material-table__cell material-table__cell-right text-nowrap ">
                        {moment(d.dateOfPayment).format('MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="material-table__ceActivitiesll material-table__cell-right text-nowrap">
                        {d.amount}
                      </TableCell>
                      <TableCell className="material-table__cell material-table__cell-center text-nowrap">{d.promoCode}</TableCell>
                      <TableCell className="material-table__cell material-table__cell-center text-nowrap">
                        <Badge color={getStatusColor(d.paymentStatusEnum)}>{d.paymentStatusString}</Badge>
                        Activities          </TableCell>
                      <TableCell className="material-table__cell material-table__cell-center" style={{ display: "flex" }}>
                        <Button color="primary" variant="outlined"
                          color="primary"
                          size="small"
                          className="mr-10 text-tranform-none "
                          startIcon={<Icon>info</Icon>}
                          onClick={() => handleInfo(d)}>
                          Details
                        </Button>
                        {updateStatus && (d.paymentStatusEnum === paymentStatus.Pending) && (

                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            className="mr-10 text-tranform-none"
                            onClick={() => handleUpdate(d)}
                            startIcon={<UpdateIcon />}
                          >
                            Update
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>

                  );
                })}
            </>

          </SearchableTable>
        </CardBody>
      </Card>

      <HistoryInfo
        isOpen={historyInfo}
        title="Payment History"
        closeModal={handleClose}
        historyDetail={historyDetail}
        handlePdfDownload={handlePdfDownload}
        getStatusColor={getStatusColor}
      />

    </>
  );
};

export default HistoryTable;
