import { get,post } from './base';
export default {
   getAllNotification: (pageSize=0,pageNo=0) =>{
        return get(`${window.apiUrl}Notification/user-notifications?Page_size=${pageSize}&Page_no=${pageNo}`,{}, true)
    },
    deleteNotifcation: (id) => {
        return post(`${window.apiUrl}Notification/read-notification?notificationId=${id}`,{}, true)
    },
    clearNotifications: () => {
        return post(`${window.apiUrl}Notification/clear-notifications`,{},true)
    }
}