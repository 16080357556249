import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardBody,Row,  Col, } from "reactstrap";
import LoadingIcon from "mdi-react/LoadingIcon";
import NoRecord from "shared/components/NoRecord"
import { programListingByProvider } from "redux/actions/programActions";
import CardNew from "../../../shared/components/CardComponent/CardNew"

const ListingCard = ({ id }) => {
  const { programListByProvider } = useSelector((state) => state.program);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    setLoading(true)
    dispatch(programListingByProvider(id))
      .then(() => setLoading(false))
      .catch((err) => console.log(err.message))
  }, [])
  return (<Col xl={12} lg={12} md={12} sm={12} xs={12}>
    <Card>
      <CardBody>
        <>
          <Col md={12}>
            <Card>
              <CardBody>
              
               {programListByProvider.programs.length>0 && ( <h4 className="uppercase bold-text mb-4">Programs</h4>)}
                <Row>
                    {loading && (<div className="panel__refresh">
                      <LoadingIcon />
                    </div> )}
                    {!loading && programListByProvider.programs.length == 0 &&  (<NoRecord /> )}
                {programListByProvider.programs.map((item, index) => {
                            return <div className="flex flex-middle" key={index}>
                                <CardNew
                                    name={item.programName}
                                    toolBar={false}
                                    cost={item.cost}
                                    newTag={item.new}
                                    salesTag={item.discount}
                                    activities={item.activity}
                                    image={item.programPhoto}
                                    description={item.description}
                                    id={item.id}
                                    regulerDates = {item.regulerDates}
                                    specialDates={item.specialDates}
                                    availability={item.availability}
                                    locations={item.locations}
                                    isManage={true}
                                    item={item}
                                    sessionsCount={item.specialDates?.length + item.regulerDates?.length}
                                />
                            </div>
                        })}
                        </Row>
                        {programListByProvider.experience.length > 0 && (<h4 className="uppercase bold-text mt-10 mb-4">Experience</h4>)}
                <Row>
                {programListByProvider.experience.map((item, index) => {
                            return <div className="flex flex-middle" key={index}>
                                <CardNew
                            type="experience"
                            toolBar={false}
                            name={item.name}
                            image={item.experiencePhoto}
                            description={item.description}
                            id={item.id}
                            specialDates={item.specialDates}
                            availability={item.availability}
                            locations={item.locationId}
                            cost={item.cost}
                            activities={item.activity}
                            sessionsCount={item.numberOfSessions}
                            isManage={true}
                            item={item}
                        />
                            </div>
                        })}
                        </Row>
               </CardBody>
            </Card>
          </Col>
        </>
      </CardBody>
    </Card>
  </Col>)
}


export default ListingCard