export const SubscriptionType =
{
    Monthly :1,
    Yearly :2
}
export const SubscriptionName =
{
    Free :1,
    Community :2,
    Advanced : 3,
    ProgramPartner : 4
}

