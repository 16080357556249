import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTagsByTagType, setLocationTag } from "../../../../redux/actions/tagAction"
import { tagTypes } from "../../../../config/tagTypes"
import { Chip, Icon } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0),
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.9),
    },
  },
}));
const LocationTag = ({ handleTagSelect, selectedTag }) => {
  const classes = useStyles();

  const locationTag = useSelector(state => state.tags.locationTag)
  const dispatch = useDispatch()
  /**
   * 
   * @param {*} color 
   * @return{string} change color of tag
   */
  const getColorTag = (color) => {
    switch (color) {
      case "primary":
        return "secondary"
      case "secondary":
        return "primary"
      default:
        return ""
    }
  }
  const getVariantTag = (variant) => {
    switch (variant) {
      case "default":
        return "outlined"
      case "outlined":
        return "default"
      default:
        return ""
    }

  }
  const handleSelectedTag = (tag) => {
    let filterData = []
    locationTag.filter((l) => {
      if (l.id === tag.id) {
        let newS = {
          color: getColorTag(l.color),
          id: l.id,
          name: l.name,
          tagType: l.tagType,
          types: l.types,
          variant: getVariantTag(l.variant),
        }
        filterData.push(newS)
      } else {
        filterData.push(l)
      }
    })
    dispatch(setLocationTag(filterData))
  }
  useEffect(() => {
    dispatch((getTagsByTagType([tagTypes.Location])))
  }, [])
  return <div className={classes.root}>
    {locationTag &&
      locationTag.map((tag, i) => (

        <Chip
          key={i}
          icon={<Icon fontSize="small" >location_on</Icon>}
          color={tag.color}
          label={tag.name}
          variant={tag.variant}
          onClick={() => handleSelectedTag(tag)}
        />

      ))}



  </div>

}



export default LocationTag