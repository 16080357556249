import React, { useState, useEffect } from "react"
import { CardElement, useStripe, useElements, Elements, PaymentRequestButtonElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useHistory, withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { loadStripe } from '@stripe/stripe-js';
import { Button, CircularProgress } from '@material-ui/core'
import { addTransactionDetails, startPayment } from "redux/actions/transactionActions"
import { getCartDetails } from "redux/actions/cartAction"
import { paymentDetailsModal } from "redux/actions/modalAction"
import { paymentCode, paymentStatus } from "config/paymentCode"
import api from "utils/apiClient"
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [succeeded, setSucceeded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {

  }, []);
  const handleIntentApi = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    await api.transaction.setStripePaymentIntent(props.cartProduct.id).then(res => {
      if (res.data) {
        setClientSecret(res.data.clientSecret);
        handleSubmit(res.data.clientSecret);
      } else {
        
      }

    }).catch(err => console.log(err))
  }

  const handleSubmit = async (secret) => {
    if (secret) {
      const payload = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
        setSucceeded(false)
        setDisabled(false)
        setProcessing(false);
      } else {
        handleStripe(payload)
        setError(null);
        setSucceeded(true);
      }
    }
    else {
      setError(`Payment failed`);
      setProcessing(false);
      setSucceeded(false)
      setDisabled(false)
      setProcessing(false);
    }
  };
  const handleChange = async (event) => {
    
    console.log(elements.getElement(CardElement),'---------------------------------------------------');
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleStripe = async (payload) => {

    let data = {
      id: 0,
      paymentSource: paymentCode.Stripe,
      transactionDetails: JSON.stringify(payload.paymentIntent),
      applicationFee: 0,
      currency: payload.paymentIntent.currency,
      transactionId: payload.paymentIntent.id
    }
    await dispatch(addTransactionDetails(data)).then(() => {
      dispatch(paymentDetailsModal(false))
      dispatch(getCartDetails()).then(() => {
        // dispatch(startPayment(false))
        setSucceeded(false)
        setDisabled(false)
        setProcessing(false);
        history.push("/home")
      })
    })
  }
  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: 'black',
        fontWeight: 500,
        fontFamily: 'lato',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#87bbfd' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
    hidePostalCode: true
    
  }

  return (
    <form onSubmit={handleIntentApi}>
      <div style={{ border: "0.1px solid #3366FF" }} className="p-8">
        <CardElement options={CARD_OPTIONS} onChange={handleChange} />
      </div>
      {error && (<div>
        <small>{error}</small>
      </div>)}
      <div className="my-10 float-right">
        <Button type="submit"
          size="medium"
          color="primary" variant="contained"
          disabled={processing
            || disabled
            || succeeded}>
          {processing ? <CircularProgress size={20} /> : "Pay"}

        </Button>
      </div>
    </form>
  );
};

const StripeButton = (props) => {
  return <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>

}
const mapStateToProps = (state) => ({
  cartProduct: state.cart.cartProduct,

})
export default connect(mapStateToProps, {})(withRouter(StripeButton))