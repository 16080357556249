import React from "react";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom"
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row, Card, CardBody } from "reactstrap";
import PeopleIcon from "@material-ui/icons/People";
import DomainIcon from "@material-ui/icons/Domain";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LinkIcon from "@material-ui/icons/Link";
import { Mail } from "@material-ui/icons";
const Ava = `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`;
const useStyles = makeStyles((theme) => ({

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  programList: {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px"
  },
}));
const renderLocations = (locations) => {
  let location = []
  if (locations) {
    locations.map((loc) => {
      location.push(loc.name)
    })
    
  }
  return location.join(", ")  
}
const ContactContent = ({ closeModal }) => {
  const classes = useStyles();
  const providerInfo = useSelector((state) => state.profile.providerProfileId);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className=" text-center">

            <Avatar
              className="profile__avatar m-auto"
              src={providerInfo.logoUrl ? providerInfo.logoUrl : Ava}
              alt="avatar"
            />
          </div>
        </Col>

        <Col md={6}>

          <div className="flex flex-middle mb-4">
            <PeopleIcon />
            <h4 className="bold-text mx-4">
              Name
                     </h4>
          </div>
          <h4 className="text-muted mx-20">
            {providerInfo.fName}  {providerInfo.lName}
          </h4>
        </Col>
        <Col md={6}>
          <div className="flex flex-middle mb-4">
            <DomainIcon />
            <h4 className="bold-text mx-4">
              Organisation
              </h4>
          </div>
          <h4 className="text-muted mx-20">{providerInfo.organisationName}</h4>
        </Col>
        <Col md={6}>
          <div className="flex flex-middle mb-4">
            <PhoneIcon />
            <h4 className="bold-text mx-4">
              Contact
              </h4>
          </div>
          <h4 className="text-muted mx-20">{providerInfo.contactNo}</h4>
        </Col>
        <Col md={6}>
          <div className="flex flex-middle mb-4">
            <Mail />
            <h4 className="bold-text mx-4">
              Email
              </h4>
          </div>
          <h4 className="text-muted mx-20">{providerInfo.email}</h4>
        </Col>
        <Col md={6}>
          <div className="flex flex-middle mb-4">
            <LocationOnIcon />
            <h4 className="bold-text mx-4">
              Location
              </h4>
          </div>
          <h4 className="text-muted mx-20">{renderLocations(providerInfo.locations)}</h4>
        </Col>
        {providerInfo.website && (<Col md={6}>
          <div className="flex flex-middle mb-4">
            <LinkIcon />
            <h4 className="bold-text mx-4">
              Website
              </h4>
          </div>
          <a href={providerInfo.website} target="_blank">
          <h4 className="mx-20">{providerInfo.website}</h4>
          </a>
        
        </Col>)}
        

      </Row>
    </>
  );
};

export default ContactContent;
