import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { authRoles } from "../../config/authroles"
import AdminListings from "./AdminListings"
import ProviderListings from "./ProviderListings"
import HelmetComponent from "shared/components/HelmetComponent"
import SupeAdminListings from './SuperAdminListings/index'
import {seoData} from "shared/StaticData"


const Listings = () => {
  const auth = useSelector(state => state.user)
  const renderUi = () => {
    if (auth.role === authRoles.Provider) {
        return <ProviderListings/>
    } else if (auth.role === authRoles.Admin) { 
        return <AdminListings/>
    }else if (auth.role === authRoles.SuperAdmin) { 
        return <SupeAdminListings/>
    }   else {
    
        return null
    }
}

    return <>
        <HelmetComponent seoData={ seoData.listings}/>
       {renderUi()}
    </>
}

export default Listings