import React, { useEffect, useState } from 'react';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Alert
} from 'reactstrap';
import { TextField, InputAdornment, Icon, IconButton, Checkbox } from "@material-ui/core"


import { connect, useSelector, useDispatch } from "react-redux"
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { withTranslation } from 'react-i18next';
import renderRadioButtonField from "../form/RadioButton"
import renderRadioButtonFieldArray from "../form/RadioButtonArray"
import RenderToggle from "../form/RenderToggle"
import { renderField } from "../form/RenderField"
import { Button, Divider } from "@material-ui/core"
// import AutoDropDown from "shared/components/form/AutoDropDown"
import AutoDropDown from '../form/AutoDropDown';
import { validate } from "../../../config/formValidation"
import { tagTypes } from "../../../config/tagTypes";
import AsyncTag from "../form/AsyncTag"
import ReactTooltip from 'react-tooltip';
import { getparentProfile } from "../../../redux/actions/profileActions"
import { useExistingAddress } from "../../../redux/actions/modalAction"

import profileApi from 'utils/api/profileApi';
import renderFieldArray from '../form/renderFieldArray';
import { setDaysTag } from 'redux/actions/tagAction';
import { USE_EXISTING_ADDRESS } from 'redux/types';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const AddNewStudent = ({ useExistingAddress, handleSubmit, submitFormAddNewSt, valid, reset, label, closeModal, value, initialValues, liabilityWaiver, photoPermission }) => {
  const [modalIsOpendelete, setIsOpenedelete] = useState(false);
  const [modalIsOpenPhotoPermission, setIsOpenPhotoPermission] = useState(false)


  // const qqqq = useSelector((state)=>state.student.studentProfile.useExistingAddress);
  const [isAdult, setisAdult] = useState(false);
  (console.log("====champa>", useExistingAddress))
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const parentProfileData = useSelector((state) => state.profile.parentProfile);
  // console.log("----->HELLO",JSON.stringify(parentProfileData.postalCode))

  useEffect(() => {
    dispatch(getparentProfile(0)).then((res) => {


    })
    dispatch(getparentProfile(0))
    // dispatch(parentProfile())
    
  }, [])

  useEffect(() => {
    if (liabilityWaiver) {
      openModaldelete();
    } else {
      closeModaldelete();
    }
  }, [liabilityWaiver])


  useEffect(() => {
    if (photoPermission) {
      openModalPhotoPermission();
    } else {
      closeModalPhotoPermission();
    }
  }, [photoPermission])
  let subtitle;
  function afterOpenModalDelete() {
    subtitle.style.color = '#f00';
  }
  function closeModaldelete() {
    setIsOpenedelete(false);
  }
  function openModaldelete() {
    setIsOpenedelete(true);
  }


  function afterOpenModalPhotoPermission() {
    subtitle.style.color = '#f00';
  }
  function closeModalPhotoPermission() {
    setIsOpenPhotoPermission(false);
  }
  function openModalPhotoPermission() {
    setIsOpenPhotoPermission(true);

  }

  return (

    <form onSubmit={handleSubmit(submitFormAddNewSt)}>
      <Row>

        <div className="form__form-group">
          <label className="float-left">Select Persons</label>
          <div className="form__form-group-field">
            {/* {isAdult? */}
            <Field
              name="isAdult"
              component={renderRadioButtonFieldArray}
              label="Adult"
              radioValue="Adult"
              //defaultChecked={isAdult ? true : null}
              onChange={(e) => setisAdult(true)}
            />
            {/* : */}
            <Field
              name="isAdult"
              component={renderRadioButtonFieldArray}
              label="Student"
              radioValue="Student"
              defaultChecked={isAdult ? null : true}
              onChange={(e) => setisAdult(false)}
            />
            {/* } */}

          </div>
        </div>

        <Col md={6}>

          <div className="form__form-group">
            <Field
              name="fName"
              component={renderField}
              type="text"
              placeholder="First Name"
              className="input-without-border-radius"
              label="First Name*"
              startIcon="person"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              name="mName"
              label="Middle Name"
              component={renderField}
              type="text"
              placeholder="Middle Name"
              startIcon="person"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Last Name*"
              component={renderField}
              type='text'
              placeholder="Last Name"
              className="input-without-border-radius"
              name="lName"
              startIcon="person"
            />
          </div>
        </Col>
        <Col md={6} className="textfiled_input textfiled_input_date">
          <label className="textfiled_input_label">Date-of-birth*</label>
          <div className="form__form-group" style={{ border: "0" }}>
            <Field
              // label=""
              component={renderField}
              type="date"
              label="Date-of-birth*"
              min={new Date().toISOString()}

              className="input-without-border-radius"
              name="birthDate"
              startIcon="date_range"
            //placeholder="dd-mm-yyyy"
            />
          </div>
        </Col>

        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Phone*"
              component={renderField}
              type='tel'
              placeholder="phone"
              className="input-without-border-radius"
              name="phone"
              startIcon="phone"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Emergency Contact*"
              component={renderField}
              type='tel'
              placeholder="Emergency Contact"
              className="input-without-border-radius"
              name="emergencyContact"
              startIcon="phone"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="School*"
              component={AsyncTag}
              type='text'
              placeholder="School"
              className="input-without-border-radius"
              name="schoolTags"
              startIcon="school"

              tagType={[tagTypes.School]}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            {isAdult ?

              <Field
                disabled={isAdult}
                name="adult"
                label="Adult"
                //value={isAdult === true ? "Adult" : ""}
                value={"Adult"}
                //defaultValue="Adult"
                component={renderField}
                startIcon="grade"
              />
              : <Field
                name="grade"
                label="Grade"
                required
                component={AutoDropDown}
                multiple={false}
                options={[
                  "Toddler",
                  "Age 1",
                  "Age 2",
                  "Age 3",
                  "Age 4",
                  "Kindergarden",
                  "Grade 1",
                  "Grade 2",
                  "Grade 3",
                  "Grade 4",
                  "Grade 5",
                  "Grade 6",
                  "Grade 7",
                  "Grade 8",
                  "Grade 9",
                  "Grade 10",
                  "Grade 11",
                  "Grade 12",
                  "Graduated",
                  "Adult"
                ]}
                type="text"
                placeholder="Select Grade"
                startIcon="grade"
              />}
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Learning Needs"
              component={renderField}
              type='text'
              placeholder="Learning Needs"
              className="input-without-border-radius"
              name="learningNeeds"
              startIcon="note"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Medical Condition"
              component={renderField}
              type='text'
              placeholder="Medical Condition"
              className="input-without-border-radius"
              name="medicalCondition"
              startIcon="local_hospital"
            />
          </div>
        </Col>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="useExistingAddress"
              component={RenderToggle}
              label="Use Existing Address"
            />
          </div>
        </div>

        {useExistingAddress ?
          <Col md={6} className="textfiled_input">
            <label className="textfiled_input_label">Address*</label>
            <div className="form__form-group">

              <TextField
                // label="Address*"
                value={parentProfileData.address1}
                // component={renderField}
                type='text'
                id="outlined-required"
                placeholder="Address*"
                className="input-without-border-radius"
                name="address1"
                startIcon="home"
              />
            </div>
          </Col>
          :
          <Col md={6}>
            <div className="form__form-group">
              <Field
                label="Address*"
                component={renderField}
                type='text'
                placeholder="Address*"
                className="input-without-border-radius"
                name="address1"
                startIcon="home"
              />
            </div>
          </Col>}

        {useExistingAddress ?
          <Col md={6} className="textfiled_input">
            <label className="textfiled_input_label">City*</label>
            <div className="form__form-group">
              {console.log("parentD", parentProfileData.province)}
              <TextField

                value={parentProfileData.city}

                // component={renderField}
                type='text'
                placeholder="City*"
                className="input-without-border-radius"
                name="city"
                startIcon="home"
              />
            </div>
          </Col>
          :

          <Col md={6}>
            <div className="form__form-group">
              <Field
                label="City*"
                component={renderField}
                type='text'
                placeholder="City*"
                className="input-without-border-radius"
                name="city"
                startIcon="home"
              />
            </div>
          </Col>}
        {useExistingAddress ?
          <Col md={6} className="textfiled_input">
            <label className="textfiled_input_label">City*</label>
            <div className="form__form-group">
              <TextField

                value={parentProfileData.province}

                // component={renderField}
                type='text'
                placeholder="Province*"
                className="input-without-border-radius"
                name="province"
                contentEditable={false}
                startIcon="home"
              />
            </div>
          </Col>
          :
          <Col md={6}>
            <div className="form__form-group">
              {/* <Field
              label="Province*"
              component={renderField}
              type='text'
              placeholder="Province*"
              className="input-without-border-radius"
              name="province"
              startIcon="home"
            /> */}
              <Field

                name="province"
                label="Province"
                required
                component={AutoDropDown}
                multiple={false}
                options={[
                  "AB",
                  "BC",
                  "MB",
                  "NB",
                  "NL",
                  "NS",
                  "NT",
                  "NU",
                  "ON",
                  "PE",
                  "QC",
                  "SK",
                  "YT"
                ]}
                type="text"
                placeholder="Select Province"
                startIcon="home"
              />
            </div>
          </Col>}

        {/* <Col md={6}>
          <div className="form__form-group">
            <Field
              label="Province*"
              component={renderField}
              type='text'
              placeholder="Province*"
              className="input-without-border-radius"
              name="province"
              startIcon="home"
            />
          </div>
        </Col> */}
        {useExistingAddress ?
          <Col md={6} className="textfiled_input">
            <label className="textfiled_input_label">Postal Code*</label>
            <div className="form__form-group">
              {console.log("jinga lala", parentProfileData.postalCode)}
              <TextField

                // label={label}
                // component="input"
                value={parentProfileData.postalCode}
                type='text'
                placeholder="Postal Code*"
                className="input-without-border-radius"
                name="postalCode"
                startIcon="home"
              />
            </div>
          </Col>
          :
          <Col md={6}>
            <div className="form__form-group">
              {console.log("qwerty===>", renderField)}
              <Field
                label="Postal Code*"
                component={renderField}
                type='text'
                placeholder="Postal Code*"
                className="input-without-border-radius"
                name="postalCode"
                startIcon="home"
              />
            </div>
          </Col>}

      </Row>
      <Row>
        <Col>
          <div className="form__form-group">
            <label className="float-left">Select Gender*</label>
            <div className="form__form-group-field">
              <Field
                name="gender"
                component={renderRadioButtonField}
                label="Male"
                radioValue="Male"
              />
              <Field
                name="gender"
                component={renderRadioButtonField}
                label="Female"
                radioValue="Female"
              />
              <Field
                name="gender"
                component={renderRadioButtonField}
                label="Prefer not to say"
                radioValue="Prefer not to say"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="photoPermission"
                component={RenderToggle}
                label="Photo Permission"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="liabilityWaiver"
                component={RenderToggle}
                label="Liability Waiver*"
              />
            </div>

          </div>
        </Col>
      </Row>




      <Modal
        isOpen={modalIsOpendelete}
        onAfterOpen={afterOpenModalDelete}
        onRequestClose={closeModaldelete}
        style={customStyles}
        contentLabel="Modal">
        <div className="add-link-modal">
          <div className="add-link-head d-flex">
            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>Liability Disclaimer</h5>
            <p onClick={closeModaldelete}><i class="fa fa-times" aria-hidden="true"></i></p>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <p>"liability waiver" - By checking this box,
                I understand that "Do What U Luv" is not liable for any activities of the services/programs and that we are strictly a platform that facilitates access to public information.
                I also understand and accept any potential risks associated with physical and mental learning activities.</p>

            </div>
          </div>
          <br />
          <div className="col-md-12">
            <div className=" text-center">
              {/* <button className="btn btn-primary mr-2" style={{ backgroundColor: '#ef3e37' }} onClick={() => deleteLinkProgram(deletedData.id)}>Yes</button> */}
              <button className="btn btn-secondary " onClick={() => closeModaldelete()}>Ok</button>
            </div>
          </div>

        </div>
      </Modal>


      <Modal
        isOpen={modalIsOpenPhotoPermission}
        onAfterOpen={afterOpenModalPhotoPermission}
        onRequestClose={closeModalPhotoPermission}
        style={customStyles}
        contentLabel="Modal">
        <div className="add-link-modal">
          <div className="add-link-head d-flex">
            <h5 ref={(_subtitle) => (subtitle = _subtitle)}>Photo Permission Disclaimer</h5>
            <p onClick={closeModalPhotoPermission}><i class="fa fa-times" aria-hidden="true"></i></p>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <p>"photo consent" - By checking this box,
                I give consent for my child to participate in group/class photos to showcase what they have learned and the program activities so that parents may see the program photos and respectfully shared,
                for online promotional purposes.</p>

            </div>
          </div>
          <br />
          <div className="col-md-12">
            <div className=" text-center">
              {/* <button className="btn btn-primary mr-2" style={{ backgroundColor: '#ef3e37' }} onClick={() => deleteLinkProgram(deletedData.id)}>Yes</button> */}
              <button className="btn btn-secondary " onClick={() => closeModalPhotoPermission()}>Ok</button>
            </div>
          </div>

        </div>
      </Modal>






      <p className="collect-info">
        Why do we need to collect this info
        <span class="lnr lnr-question-circle" data-tip="Why do we need to collect this info?
       We take the privacy and security of the platform very seriously and need to collect
        information to verify the identity and legitimacy of each user account. 
        Any fake or illegitimate users will be permanently removed and banned from using our platform.
 
       How will we store and use your information? 
        All information is encrypted and strictly accessed by the service/program provider delivering
       the service/program you have registered in to be used for planning purposes and offering the best experience.
       We may share your information with your school for partnership programs within your school district."></span></p><ReactTooltip />
      <Divider />
      <div className="flex  flex-end flex-middle my-10">
        <div className="pl-10 mr-10">
          <Button type="button" color="secondary" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </div>
        <div>
          <Button color="primary" disabled={!valid} variant="contained" type="submit">Submit</Button>
        </div>

      </div>
    </form >
  )
}
const selector = formValueSelector('Add_new_student')




export default connect(state => {
  const useExistingAddress = selector(state, 'useExistingAddress');
  const liabilityWaiver = selector(state, 'liabilityWaiver');
  const photoPermission = selector(state, 'photoPermission');
  
  //addres
  let address = ((state.profile.parentProfile.address1 ? state.profile.parentProfile.address1 : ""));
  let city = ((state.profile.parentProfile.city ? state.profile.parentProfile.city : ""));
  let postalCode = ((state.profile.parentProfile.postalCode ? state.profile.parentProfile.postalCode : ""));
  return ({
    //addres
    address1: (useExistingAddress ? (state.profile.parentProfile.address1 ? state.profile.parentProfile.address1 : "") : ''),
    city: (useExistingAddress ? (state.profile.parentProfile.city ? state.profile.parentProfile.city : "") : ''),
    postalCode: (useExistingAddress ? (state.profile.parentProfile.postalCode ? state.profile.parentProfile.postalCode : "") : ''),
    province: (useExistingAddress ? (state.profile.parentProfile.province ? state.profile.parentProfile.province : "") : ''),
    liabilityWaiver: liabilityWaiver,
    photoPermission: photoPermission,
    useExistingAddress: useExistingAddress,
    initialValues: {

      useExistingAddress: state.student.studentProfile.useExistingAddress ? state.student.studentProfile.useExistingAddress : false,

      address1: state.student.studentProfile.useExistingAddress ? (state.profile.parentProfile.address1 ? state.profile.parentProfile.address1 : "") : '',
      city: state.profile.parentProfile.city ? state.profile.parentProfile.city : '',
      postalCode: state.profile.parentProfile.postalCode ? state.profile.parentProfile.postalCode : '',




      birthDate: state.student.studentProfile.birthDate ? moment.utc(state.student.studentProfile.birthDate).format() : "",
      currentAcademicDate: state.student.studentProfile.currentAcademicDate ? state.student.studentProfile.currentAcademicDate : "",
      emergencyContact: state.student.studentProfile.emergencyContact ? state.student.studentProfile.emergencyContact : "",
      fName: state.student.studentProfile.fName ? state.student.studentProfile.fName : "",
      gender: state.student.studentProfile.gender ? state.student.studentProfile.gender : "",
      isAdult: state.student.studentProfile.isAdult ? state.student.studentProfile.isAdult : "",
      grade: state.student.studentProfile.grade ? state.student.studentProfile.grade : "Adult",
      id: state.student.studentProfile.id ? state.student.studentProfile.id : 0,
      lName: state.student.studentProfile.lName ? state.student.studentProfile.lName : "",
      address1: state.student.studentProfile.address1 ? state.student.studentProfile.address1 : "",
      city: state.student.studentProfile.city ? state.student.studentProfile.city : "",
      province: state.student.studentProfile.province ? state.student.studentProfile.province : "",
      postalCode: state.student.studentProfile.postalCode ? state.student.studentProfile.postalCode : "",
      learningNeeds: state.student.studentProfile.learningNeeds ? state.student.studentProfile.learningNeeds : "",
      liabilityWaiver: state.student.studentProfile.liabilityWaiver ? state.student.studentProfile.liabilityWaiver : false,
      mName: state.student.studentProfile.mName ? state.student.studentProfile.mName : "",
      medicalCondition: state.student.studentProfile.medicalCondition ? state.student.studentProfile.medicalCondition : "",
      phone: state.student.studentProfile.phone ? state.student.studentProfile.phone : "",
      photo: state.student.studentProfile.photo ? state.student.studentProfile.photo : "",
      photoPermission: state.student.studentProfile.photoPermission ? state.student.studentProfile.photoPermission : false,
      schoolTags: state.student.studentProfile.schoolTags ? { id: state.student.studentProfile.schoolTags, name: state.student.studentProfile.schoolName } : [],


    }
  })
})(reduxForm({
  form: 'Add_new_student', // a unique identifier for this form
  validate,
  enableReinitialize: true
})(withTranslation('common')(AddNewStudent)));