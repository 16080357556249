import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import api from "../../../../../../utils/apiClient";
import { setUserData } from "../../../../../../redux/actions/authActions";
import {
  showFailedNoti,
  showSucessNoti,
} from "../../../../../../redux/actions/notificationAction";
import ProfileUpdate from "./ProfileUpdate";

class ProfileTabs extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Update
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" style={{
                backgroundColor: '#fff',
              }}>
                <ProfileUpdate updateAdmin={this.props.updateAdmin} isEdit={this.props.isEdit } toggleEdit={ this.props.toggleEdit}/>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userMedia: state.media.userMedia,
  };
};
export default connect(mapStateToProps, {
  showFailedNoti,
  showSucessNoti,
  setUserData,
})(ProfileTabs);
