import React, { useState } from 'react';
import { Field, reduxForm, } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core"
import { Row, Col } from 'reactstrap';
import { loginModal, registerModal, forgetPasswordModal } from '../../../redux/actions/modalAction';
import { renderField } from "../form/RenderField"
import scriptLoader from "react-async-script-loader";
import { validate } from "../../../config/formValidation"

const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY
const LogInForm = ({
  handleLogin,
  handleSubmit,
  submitting,
  valid,
  recaptchaLoad
}) => {

  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector(state => state.user)
  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleForgetPasswordModal = () => {
    dispatch(forgetPasswordModal(true)).then(() => {
      dispatch(loginModal(false))
    })
  }

  return (
    <>
      <form

        onSubmit={handleSubmit(handleLogin)}
      >
        <Row>
          <Col>

            <div className="form__form-group">
              <Field
                name="email"
                label="Email*"
                component={renderField}
                type="email"
                placeholder="Email"
                startIcon='email'
              />
            </div>
            <div className="form__form-group">

              <Field
                name="password"
                label="Password*"
                component={renderField}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                className="input-without-border-radius"
                startIcon='vpn_key'
                showPasswordToggle={showPasswordToggle}
                toggleBtn={true}
              />
              <div className="account__forgot-password">
                {/* {form == 'modal_login' && (<NavLink to="/reset_password">Forgot a password?</NavLink>)} */}
                <p onClick={handleForgetPasswordModal}> <strong className="text-primary cursor-pointer">Forgot  password?</strong></p>

              </div>

            </div>

            {/* <button className="" disabled type="submit">
                      This site is protected by reCAPTCHA and the Google
                <Link
                        target="_blank"
                        to={{ pathname: "https://policies.google.com/privacy" }} > &nbsp; Privacy Policy
                </Link> &nbsp; and
                <Link
                target="_blank"
                to={{ pathname: "https://policies.google.com/terms" }} > &nbsp; Terms of Service </Link> &nbsp; apply.
              </button>  */}
            <div className="mt-2">

              {/* <input type="text" id="g-recaptcha-response" name="g-recaptcha-response"></input> */}
              <Button
                type="submit"
                color="primary"
                className=" account__btn text-tranform-none w-100"
                disabled={!valid || submitting || !recaptchaLoad}
                variant="contained"
              >Sign In
              </Button>
            </div>
          </Col>
        </Row>
      </form>
      <div className="flex flex-middle flex-center mt-10 ">
        <span >Don't have an account?</span>
        <div>
          <strong
            className="text-tranform-none text-secondary pl-10 cursor-pointer"
            onClick={() => {
              dispatch(registerModal(true)).then(() => {
                dispatch(loginModal(false))
              });

            }}
          >
            Register
          </strong>
        </div>

      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return ({
    recaptchaLoad: state.user.recaptchaLoad,
    initialValues: {
      tokenKey: state.user.recaptchaToken ? state.user.recaptchaToken : "",
      email: "",
      password: ""
    }
  })
}
export default connect(mapStateToProps, null)(reduxForm({
  form: 'loginForm',
  validate,
  enableReinitialize: true
})(LogInForm));
