import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTagsByTagType,setCatergoryTag } from "../../../../redux/actions/tagAction"
import { tagTypes } from "../../../../config/tagTypes"
import { Chip,makeStyles } from "@material-ui/core"
import FavoriteIcon from '@material-ui/icons/Favorite';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0),
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.9),
    },
  },
}));
const CategoryTag = ({ handleTagSelect, selectedTag }) => {
  const classes = useStyles();
    const catergoryTag = useSelector(state => state.tags.categoryTag)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch((getTagsByTagType([tagTypes.Category])))
    }, [])

     /**
   * 
   * @param {*} color 
   * @return{string} change color of tag
   */
  const getColorTag = (color) => {
    switch (color) {
      case "primary":
        return "secondary"
      case "secondary":
        return "primary"
      default:
        return ""
    }
  }
  const getVariantTag = (variant) => {
    switch (variant) {
      case "default":
        return "outlined"
      case "outlined":
        return "default"
      default:
        return ""
    }

  }
  const handleSelectedTag = (tag) => {
    let filterData = []
    catergoryTag.filter((l) => {
      if (l.id === tag.id) {
        let newS = {
          color: getColorTag(l.color),
          id: l.id,
          name: l.name,
          tagType: l.tagType,
          types: l.types,
          variant: getVariantTag(l.variant),
        }
        filterData.push(newS)
      } else {
        filterData.push(l)
      }
    })
    dispatch(setCatergoryTag(filterData))
  }

    return <div className={classes.root}>
        {catergoryTag && catergoryTag.map((tag, i) => (
            <Chip
                key={i}
                color={tag.color}
                icon={<FavoriteIcon fontSize="small" />}
                label={tag.name}
                variant={tag.variant}
                onClick={() => handleSelectedTag(tag)}
            />
        ))}

    </div>
}



export default CategoryTag