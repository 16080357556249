import { get,post } from './base/index';

export default {
    getUserList: (roleCode,headers) =>{
        return get(`${window.apiUrl}UserProfile/get-user-list?dwulRoles=${roleCode}`,{},headers)
    },
    getOrganisations: ()=>{
        return get(`${window.apiUrl}UserProfile/get-organisations`,{},true)
    },
    deleteUser: (id) => {
       return post(`${window.apiUrl}UserProfile/delete-user?userId=${id}`,{},true) 
    }
   
}


    