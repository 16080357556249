import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Panel from '../../../shared/components/Panel'
import {
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, ButtonToolbar, Table
} from 'reactstrap'
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Button, Icon, TableRow, TableCell } from "@material-ui/core"
import SearchableTable from "../../../shared/components/SearchableTable"
const color_list = [
    " dashboard__btc",
    " dashboard__eth",
    " dashboard__neo",
    " dashboard__ste",
    " dashboard__eos",
    " dashboard__lit"
]
const promoColor = () => color_list[Math.floor(Math.random() * color_list.length - 1)]
const columns = [
    {
        id: 0, label: 'Name',
    },
    {
        id: 1, label: 'Code',
    },
    {
        id: 2, label: 'Discount (%)',
    },
    {
        id: 3, label: 'Validity',
    },
    {
        id: 4, label: '',
    }
];
const PromoCodes = ({
    openPromoCodeModal,
    promocodeList,
    onRefresh,
    refreshPromo,
    handleEditPromo,
    handleDeletePromo
}) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(50)
    const [searchValue, setSearchValue] = useState("")

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value)
    };
    const handleChangePage = (event, page) => {
        setPage(page)
    };
    const clearSearch = () => {
        setSearchValue("")
    }
    const handlePromoSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    let filteredData = promocodeList
    if (searchValue) {
        filteredData = promocodeList.filter((search) => {
            if ((search.codeName.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }
            else if ((search.code.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }
        })
    }

    return (
        <Panel xl={6} lg={6} md={12} sm={12} xs={12} onRefresh={onRefresh} refresh={refreshPromo} title="Promo Codes"  >
            <SearchableTable
                title=""
                rows={columns}
                filteredData={filteredData}
                loading={refreshPromo}
                actionTablebtn={true}
                actionBtnIcon="add"
                actionBtnText=""
                actionBtnClick={openPromoCodeModal}
                searchType="text"
                searchLabel="Search"
                searchValue={searchValue}
                clearSearch={clearSearch}
                searchPlaceholder="Search Promo Code"
                pagination={false}
                getSearchResult={handlePromoSearch}
            >
                {filteredData.map((item, index) => (
                    <TableRow
                        key={index}

                    >
                        <TableCell padding="checkbox">
                            {index + 1}
                        </TableCell>
                        <TableCell className="material-table__cell text-nowrap" >
                            {item.codeName}
                        </TableCell>
                        <TableCell className={`bold-text ${promoColor() ? promoColor() : "dashboard__btc"}`} >
                            {item.code}
                        </TableCell>
                        <TableCell className="material-table__cell" >
                            {item.discountPrice}
                        </TableCell>
                        <TableCell className="material-table__cell" >
                            {new Date(item.validUpto).toLocaleDateString()}
                        </TableCell>
                        <TableCell className="material-table__cell" >
                            <UncontrolledDropdown className="dashboard__table-more">
                                <DropdownToggle>
                                    <p><DotsHorizontalIcon /></p>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown__menu">
                                    <DropdownItem onClick={() => handleEditPromo(item.id)}>Edit</DropdownItem>
                                    <DropdownItem onClick={() => handleDeletePromo(item.id)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </ UncontrolledDropdown>
                        </TableCell>
                    </TableRow>
                ))}
            </SearchableTable>
        </Panel>
    );
}

PromoCodes.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PromoCodes);

