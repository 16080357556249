import React from 'react';
import {useSelector} from "react-redux"
import StudentListTable from './StudentListTable';
const ProfileStudent = () => {
  const { studentProfile,stLoading } = useSelector(state => state.profile)
  return(
  <>
    <StudentListTable 
    students={studentProfile}
    loading={stLoading}
    />
  </>
);
  }

export default ProfileStudent;
