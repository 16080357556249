import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PopularTags from "../../../shared/components/PopularTags/PopularTags"
import { Col, Container, Row } from 'reactstrap'
import MyList from "./MyList"
import AllProgram from "../../AllProgram"
import { Button } from "@material-ui/core"

import ProfileCard from "shared/components/CardComponent/ProfileCard"
import { getProviderList } from "redux/actions/profileActions"
import Experience from "../../Experience"

const ParentHome = ({ t }) => {
  const [state, setState] = React.useState({
    myList: false,
    programList: true,
    experienceList: false,
    providerList: false,

  })
  const dispatch = useDispatch()
  const { providerList } = useSelector(state => state.provider)
  const { mainSelectedTag } = useSelector(state => state.tags);
  useEffect(() => {
    dispatch(getProviderList())
  }, [])
  let filteredProviderList = providerList.filter(item => item.city &&
    item.organisationName &&
    item.ageGroup.length > 0 &&
    item.locations.length > 0)
  return (
    <>
      <PopularTags />
      <div className="mx-30 mt-10 p-10">
        <h6 className="text-transform-none text-gray">Go to your Account setting (top-right icon) to 1) Add/ Manage Students, 2) Update Parent Info, 3) Update your Interests, 4) Change your password.</h6>
        <Row>
          <Col md={12}>
            <div className="mb-10 mt-20">
              <h3 className="bold-text uppercase">My List <span>
                <Button
                  size="small"
                  className="text-gray"
                  color="primary"
                  onClick={() => setState(state => (
                    { ...state, myList: !state.myList }
                  ))}>{state.myList ? "Hide" : "Show"}</Button>
              </span>
              </h3>

            </div>
          </Col>
        </Row>
        {state.myList && (<Row>
          <MyList />
        </Row>)}
        {(mainSelectedTag === "All" || mainSelectedTag === "Programs") && (
          <>
            <Row>
              <Col md={12}>
                <div className="mt-20 mb-10">
                  <h3 className="bold-text uppercase">Programs
                    <span>
                      <Button
                        size="small"
                        className="text-gray"
                        color="primary"
                        onClick={() => setState(state => (
                          { ...state, programList: !state.programList }
                        ))}>{state.programList ? "Hide" : "Show"}</Button>
                    </span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row >
              {state.programList && (<AllProgram filtered={true} />)}
            </Row>
          </>)}

        {(mainSelectedTag === "All" || mainSelectedTag === "Experience") && (
          <>
            <Row >
              <Col md={12}>
                <div className="mt-15 mb-10">
                  <h3 className="bold-text uppercase">Experiences
                    <span>
                      <Button
                        size="small"
                        className="text-gray"
                        color="primary"
                        onClick={() => setState(state => (
                          { ...state, experienceList: !state.experienceList }
                        ))}>{state.experienceList ? "Hide" : "Show"}</Button>
                    </span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row >
              {state.experienceList && (<Experience type="general" />)}
            </Row>
          </>
        )}
        {(mainSelectedTag === "All" || mainSelectedTag === "Provider") && (
          <>
            <Row >
              <Col md={12}>
                <div className="mt-15 mb-15">
                  <h3 className="bold-text uppercase">Providers
                    <span>
                      <Button
                        size="small"
                        className="text-gray"
                        color="primary"
                        onClick={() => setState(state => (
                          { ...state, providerList: !state.providerList }
                        ))}>{state.providerList ? "Hide" : "Show"}</Button>
                    </span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              {state.providerList && (filteredProviderList.length !== 0 && filteredProviderList.map((item, index) => (
                <div key={index + new Date()}>
                  <ProfileCard
                    item={item}
                  />
                </div>
              )))}
            </Row>
          </>
        )}
      </div>
    </>
  )
}
export default ParentHome