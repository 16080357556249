import React from "react"
import {useDispatch} from "react-redux"
import { Button,CircularProgress } from "@material-ui/core"
import scriptLoader from "react-async-script-loader";
import {showFailedNoti,showSucessNoti} from "redux/actions/notificationAction"
import moment from "moment"
let discoveryDocs = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
]
const GoogleSync = ({ events }) => {
    const dispatch = useDispatch()
    const [startSync,setStartSync] =  React.useState(false)
    React.useEffect(() => {
        // gapi initialise
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                apiKey: process.env.REACT_APP_CALENDAR_API_KEY,
                clientId: process.env.REACT_APP_CALENDAR_CLIENT_ID,
                discoveryDocs: discoveryDocs,
                scope: "https://www.googleapis.com/auth/calendar"
            }).then((res) => {
                let authInstance = window.gapi.auth2.getAuthInstance();
                // dispatch({type:GOOGLE_AUTH, payload:authInstance})
            }).catch(err => {
                // dispatch(showErrorSnackbar(err.error))
                // dispatch({type:GOOGLE_AUTH_STATE, payload:{authState:true,authmsg:""}})
            })
        })
    }, [])
    const handleSyncWithGoogle = () => {
        let isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get()
        setStartSync(true)
        if (isSignedIn) {
            syncWithGoogleCaledar()
        } else {
            window.gapi.auth2.getAuthInstance().signIn({
                prompt: "select_account"
            }).then(res => {
                syncWithGoogleCaledar()
            }).catch(err => {
                setStartSync(false)
                dispatch(showFailedNoti(err.message))
            })
        }
    }
    const getNewEvents = () => {
        let d = []
        if (events.events) {
            events.events.map((res) => {
                let myEvents = {
                    'summary': res.title,
                    'start': {
                        'date': moment(res.start).format("Y-MM-DD")
                    },
                    'end': {
                        'date': moment(res.end).format("Y-MM-DD"),
                    },
                };
                d.push(myEvents)
            })
        }
        return d
    }
    const syncWithGoogleCaledar = () => {
        let newEvents = getNewEvents()

        const batch = window.gapi.client.newBatch()
        if (newEvents.length>0) {
            newEvents.map((r, j) => {
                batch.add(window.gapi.client.calendar.events.insert({
                    'calendarId': 'primary',
                    'resource': newEvents[j]
                }))
            })
            batch.then((res) => {
                setStartSync(false)
                if (res.status === 200) {
                    dispatch(showSucessNoti("Events are successfully synced with your's google calendar."))
                } else {
                    dispatch(showFailedNoti("Error occured."))
                }
            }).catch((err)=> dispatch(showFailedNoti(err.message)))
        }
    }
    return <Button
        onClick={() => handleSyncWithGoogle()}
        className='my-10'
        color="secondary"
        size="medium"
        disabled={startSync}
        variant="outlined">
        {startSync ? <CircularProgress size={ 15}/>:" Sync with google"}
       
        </Button>
}

export default scriptLoader(`https://apis.google.com/js/api.js`)(GoogleSync)