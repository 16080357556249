import React from "react";
import { Col, Container, Row,Card,CardBody } from "reactstrap";
import { useDispatch,useSelector } from "react-redux";
import Favourite from "./Favourite"
import Recommandation from "./Recommendation/index"
import HelmetComponent from "shared/components/HelmetComponent"
import { seoData } from "shared/StaticData"
import Resources from "containers/Resources"
import {getResources, } from "redux/actions/resourcesAction"
import AnnouncementList from "./Component/AnnouncementList"
const columns = [
  {
    id: 0, label: 'Name',
  },
  {
    id: 1, label: 'Description',
  },
  {
    id: 2, label: 'Link',
  },
];
export default function Community() {
  const dispatch = useDispatch();
  React.useEffect(() => {
     dispatch(getResources())
  }, [])
  const { resource } = useSelector(state => state.resources)
  return (
    <>
      <HelmetComponent seoData={seoData.community} />
      <Container>
        <Row>
          <Col md={12}>
            <div className="card__title">
              <h3 className="bold-text">Community</h3>
              <h6 className="text-muted text-tranform-none mt-10">You can make recommendations on any programs and activities for families and youth, if it is not listed on our
                    platform currently.</h6>
            </div>
          </Col>
        </Row>
        <Favourite />
        <Recommandation />
        <Card>
          <CardBody>
            <Resources
              resource={resource}
              title="Resources"
              columns={columns}
              editResources={false}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AnnouncementList />
          </CardBody>
        </Card>

      </Container>
    </>
  );
}
