import React from 'react';
import { useDispatch } from "react-redux"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import CircularProgress from '@material-ui/core/CircularProgress';
import api from "../../../utils/apiClient";
import AddNewFormTag from "./AddNewFormTag"
import { showFailedNoti, showSucessNoti, showWarningNoti } from "redux/actions/notificationAction"
import { TextField, InputAdornment, Icon, IconButton } from '@material-ui/core';

// to filter lower case in autocomplete
const filterOptions = createFilterOptions({
  ignoreCase: true,
});
export default function AsyncTag(props) {

  let { input,
    label,
    type,
    startIcon,
    placeholder,
    defaultValue,
    tagType,
    id,
    meta: { touched, error, warning },
    ...custom
  } = props;

  const [open, setOpen] = React.useState(false);
  const [tagModal, setTagModal] = React.useState(false)
  const [options, setOptions] = React.useState([{ title: '', id: null }]);
  const [tagName, setTagName] = React.useState("")
  const loading = open && options.length === 0;
  const { onChange, ...rest } = input;
  const dispatch = useDispatch()
  React.useEffect(() => {
    let active = true;
    let tags = []

    if (!loading) {
      return undefined;
    }




    (async () => {
      return await api.tag.post(tagType, false).then((res) => {
        if (res.data.data && active) {

          console.log("tagValuesasa", res.data.data)
          res.data.data.map(value => tags.push({
            id: value.id,
            name: value.name

          }))

        }
      }).then(() => {
        setOptions((tags))

      }
      )
        .catch((e) => console.error(e))
    })();

    return () => {
      active = false;
    };

  }, [loading]);


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleTagInput = e => {
    setTagName(e.target.value)
  }

  const handleAddNewTag = async (values) => {
    let data = {
      id: 0,
      name: tagName,
      tagType: tagType[0],
      types: "",
    };
    await api.tag
      .manageTag(data, true)
      .then((res) => {
        if (res.data.data) {
          closeTagModal()
          dispatch(showSucessNoti(res.data.message))
        } else {
          closeTagModal()
          dispatch(showWarningNoti(res.data.message))
        }
      })
      .catch((err) => {
        dispatch(showFailedNoti(err.message))
      });
  }
  const closeTagModal = () => {
    setTagName("");
    setTagModal(false);
  }
  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option) => option && option.name}
        options={options}
        loading={loading}
        value={input.value || []}
        onChange={(e, newValue) => {
          input.onChange(newValue)
        }}
        defaultValue={defaultValue}
        getOptionSelected={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <TextField
            {...params}
            // {...input}
            placeholder={placeholder}
            type={type}
            helperText={touched && error}
            error={touched && error}
            variant="outlined"
            size="small"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>{startIcon}</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> :
                    <IconButton onClick={() => setTagModal(true)} size="small" color="primary" >
                      <Icon className="cursor-pointr" fontSize="small">add</Icon>
                    </IconButton>}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            {...custom}

          />
        )}
      />
      <AddNewFormTag
        isOpen={tagModal}
        startIcon={startIcon}
        label={label}
        closeModal={closeTagModal}
        handleTag={handleAddNewTag}
        handleTagInput={handleTagInput}
        tagName={tagName}
      />
    </>


  );
}
