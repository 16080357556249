import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
import { Container, Row } from 'reactstrap';
import AllProgram from './Containers/AllPrograms'
import { getAllProgram, } from '../../../redux/actions/programActions'
const SuperAdminListings = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
          dispatch(getAllProgram())
        })()
      },[])

    return (
          <Container>
             
             {/* <Row>
                <AllExperiance/>
            </Row>  */}
            
            <Row>
                <AllProgram/>
            </Row> 
            
            
          </Container>    
    );
}

export default SuperAdminListings;

