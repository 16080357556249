import React from "react";
import { withRouter } from "react-router";
import TopBar from "../../shared/components/TopBar";
import CardNew from "../../shared/components/CardComponent/CardNew";
import SkeletonCard from "../../shared/components/CardComponent/SkeletonCard";
import ModalAddProgram from "./Components/ModalAddProgram";
import { Row, Col } from "reactstrap";
import api from "../../utils/apiClient";
import {
  getProviderProgramById,
  providerProgram,
  setProviderProgramById,
} from "../../redux/actions/programActions";
import { reset } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import {
  showSucessNoti,
  showFailedNoti,
  showWarningNoti,
} from "../../redux/actions/notificationAction";
import {
  setProgramPdf,
  setMediaProgram,
} from "../../redux/actions/mediaAction";
import ConfirmationModal from "../../shared/components/ConfirmationModal";
import NoRecord from "../../shared/components/NoRecord";
import { getLocationData } from "shared/helpers";
import moment from "moment";

const Program = () => {
  const dispatch = useDispatch();
  const [programModal, setProgramModal] = React.useState(false);
  const [programImage, setProgramImage] = React.useState("");
  const [deleteProgram, setdeleteProgram] = React.useState(false);
  const [programCurrent, setProgramCurrent] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const { programMedia, programPdf } = useSelector((state) => state.media);
  const { providerProgramData, providerLoading } = useSelector(
    (state) => state.program
  );
  React.useEffect(() => {
    dispatch(providerProgram());
  }, []);
  const handleClose = () => {
    dispatch(reset("programForm"));
    dispatch(setProviderProgramById({}));
    dispatch(setProgramPdf(""));
    dispatch(setMediaProgram(""));
    setProgramModal(false);
    setdeleteProgram(false);
  };
  const handleProgram = () => {
    setProgramModal(true);
    setIsEdit(false);
  };
  const getUTCDate = (date = []) => {
    if (date == [""]) {
      return [""];
    } else {
      return date.map((d) => {
        return moment(d).tz("Canada/Central").format("YYYY-MM-DDTHH:mm:ss");
      });
    }
  };

  const programSubmit = async (values) => {
    // console.log("values ---->", values.specialDates)
    // values.specialDates.filter()
    let programData = {
      id: values.id,
      programName: values.programName,
      cost: parseFloat(values.cost),
      sessionsForCost: parseInt(values.sessionsForCost),
      locations: values.locations,
      locationName: "",
      regulerDates:
        values.regulerDates.filter((n) => n) == 0
          ? null
          : getUTCDate(values.regulerDates),
      noSession:
        values.noSession.filter((n) => n) == 0
          ? null
          : getUTCDate(values.noSession),
      specialDates:
        values.specialDates.filter((n) => n) == 0
          ? null
          : getUTCDate(values.specialDates),
      capacity: values.capacity ? parseInt(values.capacity) : 0,
      activity: values.activity,
      ageGroup: values.ageGroup,
      description: values.description,
      programPhoto: programMedia ? programMedia : values.programPhoto,
      flyer: programPdf ? programPdf : values.flyer,
      registrationFlag: values.registrationFlag,
      externalLink: values.externalLink,
      programLocation: values.programLocation,
      categoryTags: values.categoryTags ? values.categoryTags : [],
      regularEndTime: values.regularEndTime
        ? moment(values.regularEndTime).format("HH:mm")
        : null,
      specialEndTime: values.specialEndTime
        ? moment(values.specialEndTime).format("HH:mm")
        : null,
      regularStartTime: values.regularStartTime
        ? moment(values.regularStartTime).format("HH:mm")
        : null,
      specialStartTime: values.specialStartTime
        ? moment(values.specialStartTime).format("HH:mm")
        : null,
      placeName: values.placeName,
      streetAddress: values.streetAddress,
      room: values.room,
      minimumAge: Number(values.minimumAge),
      maximumAge: Number(values.maximumAge),
    };
    console.log("programdata index" + JSON.stringify(programData));

    // debugger
    await api.program
      .manageProgram(programData, true)
        .then((res) => {
            console.log("programdata res " + JSON.stringify(res));
        dispatch(reset("programForm"));
        if (res.data) {
          dispatch(showSucessNoti(res.data.message));
          dispatch(providerProgram());
          handleClose();
        } else {
          dispatch(showWarningNoti(res.message));
        }
      })
      .catch((err) => {
        dispatch(showFailedNoti(err.message));
        handleClose();
      });
  };
  const handleProgramDelete = (data) => {
    setProgramCurrent(data);
    setdeleteProgram(true);
  };
  const handleProgramEdit = (data) => {
    dispatch(getProviderProgramById(data)).then(() => {
      setIsEdit(true);
      setProgramModal(true);
    });
  };
  const handleProgramDeleteConfirm = async () => {
    let id = programCurrent;
    await api.program
      .deleteProgram(id, true)
      .then((res) => {
        setdeleteProgram(false);
        dispatch(providerProgram());
      })
      .catch((err) => {});
  };
  return (
    <>
      <Row>
        <Col>
          <TopBar
            title="Programs"
            buttonTitle="Add"
            buttonClick={handleProgram}
          ></TopBar>
        </Col>
      </Row>

      <Row>
        {!providerLoading && providerProgramData.item2 === 0 && (
          <Col>
            <NoRecord />
          </Col>
        )}
        {providerLoading && <SkeletonCard />}
        <>
          {providerProgramData &&
            providerProgramData.item2 > 0 &&
            providerProgramData.item1.map((item, index) => {
              return (
                <div className="flex flex-middle" key={index}>
                  <CardNew
                    name={item.programName}
                    cost={item.cost}
                    newTag={item.new}
                    salesTag={item.discount}
                    activities={item.activity}
                    image={item.programPhoto}
                    description={item.description}
                    id={item.id}
                    regulerDates={item.regulerDates}
                    specialDates={item.specialDates}
                    regulerStartTime={
                      item.regularStartTime ? item.regularStartTime : null
                    }
                    regulerEndTime={
                      item.regularEndTime ? item.regularEndTime : null
                    }
                    specialStartTime={
                      item.specialStartTime ? item.specialStartTime : null
                    }
                    specialEndTime={
                      item.specialEndTime ? item.specialEndTime : null
                    }
                    locations={item.locations}
                    handleEdit={(id) => handleProgramEdit(id)}
                    handleDelete={(id) => handleProgramDelete(id)}
                    isManage={true}
                    item={item}
                    // sessionsCount={(item.specialDates?.length + item.regulerDates?.length)?(item.specialDates?.length + item.regulerDates?.length):0}
                    sessionsCount={
                      (item.specialDates && item.specialDates.length
                        ? item.specialDates.length
                        : 0) +
                      (item.regulerDates && item.regulerDates.length
                        ? item.regulerDates.length
                        : 0)
                    }
                    routePath={`/detail/${item.programName}/${item.id}`}
                    className="jhkhk"
                    minimumAge={item.minimumAge}
                    maximumAge={item.maximumAge}
                  />
                </div>
              );
            })}
        </>
      </Row>
      {programModal && (
        <ModalAddProgram
          title="Create Program"
          isOpen={programModal}
          closeModal={handleClose}
          programSubmit={programSubmit}
          programImage={programImage}
          isEdit={isEdit}
        />
      )}
      <ConfirmationModal
        color="danger"
        title="Delete Program"
        message="Are you sure you want to delete"
        colored=""
        header=""
        isOpen={deleteProgram}
        closeModal={handleClose}
        onYesClick={handleProgramDeleteConfirm}
      />
    </>
  );
};

export default withRouter(Program);
