import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Panel from "../../../shared/components/Panel";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import {  TableRow, TableCell } from "@material-ui/core";
import SearchableTable from "../../../shared/components/SearchableTable";

const columns = [
  {
    id: 1,
    label: "Name",
  },
  {
    id: 1,
    label: "Announcement For",
  },
  {
    id: 2,
    label: "",
  },
];

const Announcement = ({
  openAnnouncementModal,
  announcementList,
  onRefresh,
  refreshAnnouncement,
  handleEditAnnouncement,
  handleDeleteAnnouncement,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const clearSearch = () => {
    setSearchValue("");
  };
  const handleAnnouncementSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  let filteredData = announcementList;
  if (searchValue) {
    filteredData = announcementList.filter((search) => {
      if (
        search.announcementName
          .toLowerCase()
          .startsWith(searchValue.toLowerCase())
      ) {
        return search;
      } else if (
        search.description.toLowerCase().startsWith(searchValue.toLowerCase())
      ) {
        return search;
      } else if (
        search.programname.toLowerCase().startsWith(searchValue.toLowerCase())
      ) {
        return search;
      }
    });
  }

  return (
    <Panel
      xl={6}
      lg={6}
      md={12}
      sm={12}
      xs={12}
      onRefresh={onRefresh}
      refresh={refreshAnnouncement}
      title="Announcement"
    >
      <SearchableTable
        title=""
        rows={columns}
        filteredData={filteredData}
        loading={refreshAnnouncement}
        actionTablebtn={true}
        actionBtnIcon="add"
        actionBtnText=""
        actionBtnClick={openAnnouncementModal}
        searchType="text"
        searchLabel="Search"
        searchValue={searchValue}
        clearSearch={clearSearch}
        searchPlaceholder="Search Announcemnet"
        pagination={false}
        getSearchResult={handleAnnouncementSearch}
      >
        {filteredData.map((item, index) => (
          <TableRow key={index}>
            <TableCell padding="checkbox">{index + 1}</TableCell>
            <TableCell className="material-table__cell">
              {item.announcementName}
            </TableCell>
            <TableCell className="material-table__cell">
              {item.announcementString}
            </TableCell>

            <TableCell className="material-table__cell">
              <UncontrolledDropdown className="dashboard__table-more">
                <DropdownToggle>
                  <p>
                    <DotsHorizontalIcon />
                  </p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem onClick={() => handleEditAnnouncement(item.id)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDeleteAnnouncement(item)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </TableCell>
          </TableRow>
        ))}
      </SearchableTable>
    </Panel>
  );
};

export default withTranslation("common")(Announcement);
