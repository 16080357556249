import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, ButtonGroup,
} from 'reactstrap';
import GoogleSync from "./GoogleSync"
import { Button } from "@material-ui/core"

const EventLabels = (events) => (
  <Col md={12} lg={12} xl={3}>
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">event labels</h5>
        </div>
        <p className="typography-message">
          <span className="calendar-label calendar-label--red" /> Special dates
        </p>
        <p className="typography-message">
          <span className="calendar-label calendar-label--green" /> Regular dates
        </p>
        {/* <p className="typography-message">
          <span className="calendar-label calendar-label--blue" /> Non-priority events
        </p> */}
        <div>
          <GoogleSync events={ events}/>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default EventLabels;
