import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux"
import { Button, Icon, makeStyles, DialogActions, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useDispatch } from "react-redux"
import { setSelectedTag } from "../../../../redux/actions/tagAction";
import { getAllProgram, providerProgram } from '../../../../redux/actions/programActions';
import { allExperiance } from '../../../../redux/actions/experianceAction';
import { tagTypes } from "config/tagTypes";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin'
import TabsButton from "./TabButton"
import MinimumAgeTag from "./MinimumAgeTag";
function ExploreModal({ open, handleClose, title }) {
  const dispatch = useDispatch()
  const [selectedDays, setSeleteddays] = useState([`${new Date().toDateString()}`])
  const locationTag = useSelector(state => state.tags.locationTag)
  const catergoryTag = useSelector(state => state.tags.categoryTag)

  const activityTag = useSelector(state => state.tags.activityTag)
  // const minimumAgeTag = useSelector(state => state.tags.minimumAgeTag)
  // console.log("minimumAgeTag", minimumAgeTag)
  const daysTag = useSelector(state => state.tags.daysTag)
  const handleGetAllProgram = () => {
    console.log("abhi abhi kiya hai")

    let id = [],
      days = [],
      selectedTag = [],
      locationTags = [],
      activityTags = []


    console.log("locationTag", locationTag)
    console.log("activityTag", activityTag)
    console.log("idddd======>", id)

    locationTag.map(t => {
      if (t.color === "primary" && t.variant === "default") {
        id.push(t.id)
        locationTags.push(t.id)
        console.log("idddd===L===>", locationTags)
        selectedTag.push({ ...t })

      }
    })


    // minimumAgeTag.map(t => {
    //   if(t.color === "primary" && t.variant === "default") {
    //     id.push(t.minimumAge)
    //     selectedTag.push({...t})
    //   }
    // })
    catergoryTag.map(t => {
      if (t.color === "primary" && t.variant === "default") {

        id.push(t.id)
        selectedTag.push({ ...t })
      }
    })
    activityTag.map(t => {
      if (t.color === "primary" && t.variant === "default") {
        id.push(t.id)
        activityTags.push(t.id)
        console.log("idddd===A===>", activityTags)
        selectedTag.push({ ...t })
      }
    })
    daysTag && daysTag.map(t => {
      console.log("qwerrt", daysTag)
      if (t.color === "primary" && t.variant === "default") {
        days.push(t.name)
        console.log("days", days)
        selectedTag.push({ ...t })
      }
    })


    let mySelect = JSON.stringify(selectedTag)
    console.log("selected tag", selectedTag)
    localStorage.setItem("my_select", mySelect)
    dispatch(setSelectedTag(selectedTag))
    dispatch(getAllProgram("", id, activityTags, "", days.join(","), locationTags));
    dispatch(allExperiance("", "", id, days.join(",")));
    dispatch(providerProgram())
    handleClose()
  }
  const getIconTag = (tagIcon) => {
    switch (tagIcon) {
      case tagTypes.Location:
        return <Icon fontSize="small" >location_on</Icon>
      case tagTypes.minimumAgeTag:
        return <PersonPinIcon fontSize="small" />
      case tagTypes.Category:
        return <PersonPinIcon fontSize="small" />
      case tagTypes.Activity:
        return <FavoriteIcon fontSize="small" />
      case tagTypes.School:
        return <Icon>school</Icon>
      case tagTypes.City:
        return <LocationCityIcon fontSize="small" />
      default:
        return null
    }
  }
  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
      >
        <DialogTitle><h5><strong>{title}</strong></h5></DialogTitle>
        <DialogContent>
          <TabsButton selectedDays={selectedDays} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close
          </Button>
          <Button onClick={handleClose} color="primary" className="mx-4" variant="contained" onClick={() => {
            handleGetAllProgram()
          }} >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default ExploreModal
