import React from 'react';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from "redux-form"
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { connect} from "react-redux";
import { renderField } from "../../../shared/components/form/RenderField"
import AutoSuggest from "../../../shared/components/form/AutoSuggest"
import { validate } from "../../../config/formValidation"
import { tagTypes } from "../../../config/tagTypes"
function AddNewTag({
    isOpen,
    closeModal,
    handleSubmit,
    handleTag,
    isEdit,
    submitting,
    valid, }) {
    const options = [
        { tagType: tagTypes.Category, types: "Category", icon: "category" },
        { tagType: tagTypes.Location, types: "Location", icon: "location_on" },
        { tagType: tagTypes.School, types: "School", icon: "school" },
        { tagType: tagTypes.Activity, types: "Activity", icon: "local_activity" },
        { tagType: tagTypes.AgeGroup, types: "AgeGroup", icon: "local_activity" },
    ];
    return (
        <div>
            <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
                <ModalHeader toggle={closeModal}><h5><strong>{isEdit ? "Edit Tag" : "Add Tag"}</strong></h5></ModalHeader>
                <form
                    className="login-form"
                    onSubmit={handleSubmit(handleTag)}
                >
                    <ModalBody>
                        <div className="form__form-group">
                            <Field
                                className="mb-10"
                                name="tagType"
                                label="Tag Type"
                                component={AutoSuggest}
                                type="text"
                                placeholder="Select Tag Type"
                                multiple={false}
                                options={options}
                            />
                        </div>
                        <div className="form__form-group">
                            <Field
                                name="name"
                                label={"Name"}
                                component={renderField}
                                type="text"
                                startIcon='label'
                                placeholder={"Name"}
                            />
                        </div>

                    </ModalBody>
                    <div className="flex flex-end flex-middle ">

                        <div>
                            <Button
                                className="m-0"
                                variant="outlined"
                                type="button"
                            
                                onClick={closeModal}
                                color="secondary">
                                Cancel
                        </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                className="mx-10"
                                autoFocus
                                type="submnit"
                                disabled={!valid || submitting}
                                color="primary">
                                Save
                        </Button>
                        </div>

                    </div>


                </form>

            </Modal>
        </div>
    );
}
export default connect(state => ({
    initialValues: {
        id: state.tags.tagDetails.id ? state.tags.tagDetails.id : 0,
        tagType: state.tags.tagDetails.tagType ? state.tags.tagDetails.tagType : 0,
        types: state.tags.tagDetails.types ? state.tags.tagDetails.types : "",
        name: state.tags.tagDetails.name ? state.tags.tagDetails.name : ""
    }
}))(reduxForm({
    form: 'tagForm',
    validate,
    enableReinitialize: true,

})(AddNewTag))