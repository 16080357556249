import { GET_REVIEW,POST_REVIEW,RATING,P_ID, GET_PROGRAM_REVIEW } from "../types";

const initialState ={
    reviewList:[],
    rating: 0,
    p_id: 0,
    reviewLoading: true,
    readOnlyReview: false,
    reviewData: {}
}

const reviewReducer =(state=initialState, action)=>{

    switch(action.type){
        case GET_REVIEW:
            return {
                ...state,
                reviewList: action.payload,
                reviewLoading: false
            }
        case RATING:
            return{
                ...state,
                rating: action.payload
            }
        case P_ID:
            return {
                ...state,
                p_id: action.payload
            }
        case GET_PROGRAM_REVIEW:
        return {
            ...state,
            rating: action.payload?.rating,
            reviewData: action.payload,
            readOnlyReview : action.payload && action.payload.ratings ? true : false
        }
        default:
            return state;
    }
}

export default reviewReducer;