import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderField } from "../../components/form/RenderField";
import { Col, Row } from "reactstrap";
import { Button, Divider } from "@material-ui/core";
import { validate } from "../../../config/formValidation";

const ChangePassForm = ({
  handleSubmit,
  closeModal,
  submitting,
  valid,
  onSubmit,
  form
}) => {
  const [oldPassword, setOldPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(false);
  const [confirmNewPassword, setconfirmNewPassword] = React.useState(false);

  const showOldToggle = () => {
    setOldPassword(!oldPassword)
  }
  const showNewToggle = () => {
    setNewPassword(!newPassword)
  }
  const showConfirmToggle = () => {
    setconfirmNewPassword(!confirmNewPassword)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12} xs={12} lg={12}>
          {form === "change_password" && (<div className="form__form-group">
            <Field
              name="oldPassword"
              type="text"
              label="Old Password"
              startIcon="vpn_key"
              component={renderField}
              type={oldPassword ? "text" : "password"}
              className="input-without-border-radius"
              showPasswordToggle={showOldToggle}
              toggleBtn={true}
            />
          </div>)}

        </Col>
        <Col md={12} xs={12} lg={12}>
          <div className="form__form-group">
            <Field
              name="newPassword"
              type="text"
              label="New Password"
              startIcon="vpn_key"
              component={renderField}
              className="input-without-border-radius"
              type={newPassword ? "text" : "password"}
              showPasswordToggle={showNewToggle}
              toggleBtn={true}
            />
          </div>

        </Col>
        <Col md={12} xs={12} lg={12}>
          <div className="form__form-group">
            <Field
              name="confirmNewPassword"
              type="text"
              label="Confirm New Password"
              startIcon="vpn_key"
              component={renderField}
              className="input-without-border-radius"
              type={confirmNewPassword ? "text" : "password"}
              showPasswordToggle={showConfirmToggle}
              toggleBtn={true}
            />
          </div>

        </Col>
      </Row>
      <Divider />
      <div className="flex flex-end my-10 flex-middle" >
        <div>
          <Button
            className="account__btn"
            type="submit"
            color="primary"
            variant="contained"
            disabled={!valid || submitting}
          >
            Submit
            </Button>

        </div>
        {form === "change_password" && (<div className="px-10">

          <Button
            className="account__btn"
            type="submit"
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={closeModal}
          >
            Cancel
  </Button>
        </div>)}

      </div>
    </form>
  );
};

export default reduxForm({
  form: "Change-Password",
  validate,
})(ChangePassForm);