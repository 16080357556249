import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import { Icon, TableRow, TableCell, Menu, MenuItem, IconButton } from "@material-ui/core"
import { Card, CardBody } from "reactstrap"
import { getTrHistoryParent } from "../../../../redux/actions/transactionActions";
import SearchableTable from "../../../../shared/components/SearchableTable"
import { showSucessNoti, showFailedNoti } from "../../../../redux/actions/notificationAction"
import MessageModal from "./message/MessageModal";
import { messageModal } from "../../../../redux/actions/modalAction"
import { sendMessage } from "../../../../redux/actions/messageActions"
import { refundListParent } from "redux/actions/transactionActions"
import api from "../../../../utils/apiClient";
import { messageItemType } from "../../../../config/notificationType";

import {
    Badge,
} from "reactstrap"
import moment from "moment"
import HistoryInfo from "../../Components/HistoryInfo";
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import { paymentStatus } from "config/paymentCode"
import CancelRefund from "./CancelRefund"
import logoImage from "shared/img/logo/LOGO.png"
import { Messages } from "config/notificationType";

const rows = [
    {
        id: 0, label: 'Order Id',
    },
    {
        id: 1, label: 'No. of Programs',
    },
    {
        id: 2, label: 'Payment Mode',
    },
    {
        id: 3, label: 'Date of Payment',
    },

    {
        id: 4, label: 'Amount (CA$)',
    },
    {
        id: 5, label: 'Promo Code',
    },
    {
        id: 6, label: 'Payment Status',
    },
    {
        id: 7, label: 'Action',
    }
];
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
})
const ParentHistory = () => {
    const [cancelProgram, setCancelProgram] = React.useState(false)
    const [page, setPage] = useState(0)
    const [historyDetail, sethistoryDetail] = useState({})
    const [rowsPerPage, setrowsPerPage] = useState(50)
    const [searchValue, setSearchValue] = useState("")
    const [programId, setProgramId] = React.useState()
    const [historyInfo, sethistoryInfo] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const { TrHistoryParent, trloadingParent } = useSelector(
        (state) => state.transaction
    );
    const modal = useSelector(state => state.modal)

    React.useEffect(() => {
        getPagedata()
    }, [])

    const getPagedata = async (pageSize = rowsPerPage, pageNo = page) => {
        if (page === 0) {
            await dispatch(getTrHistoryParent(pageSize, pageNo + 1));
        } else {
            await dispatch(getTrHistoryParent(pageSize, pageNo));
        }
    }
    const handlePdf = (d) => {
        sethistoryInfo(true)
        setAnchorEl(null)
    }
    const handleMenu = (event, data) => {
        setAnchorEl(event.currentTarget);
        sethistoryDetail(data)
    };
    const handleCancelModal = () => {
        setAnchorEl(null)
        setCancelProgram(true);
    }
    const handleProgramCancelConfirm = async (data) => {
        await api.transaction.cancelOrder(data, true).then(res => {
            if (res.data.data) {
                dispatch(showSucessNoti(res.data.message))
                dispatch(refundListParent())
                setCancelProgram(false);
            } else {
                dispatch(showFailedNoti(res.data.message))
                setCancelProgram(false);
            }
        }).catch(err => dispatch(showFailedNoti(Messages.GeneralError)))
    }
    const handleMessageModal = () => {
        setProgramId(historyDetail.programOrder && historyDetail.programOrder[0]?.programId);
        dispatch(messageModal(true));
    };
    const sendMessages = async (val) => {

        let data = {
            messageType: {
                id: programId,
                itemType: messageItemType.Program
            },
            subject: val.subject,
            fullMassage: val.fullMassage
        }
        dispatch(sendMessage(data))
        dispatch(messageModal(false));
    }
    const closeMessage = () => {
        dispatch(messageModal(false))
    }

    const handleClose = () => {
        setCancelProgram(false);
        sethistoryInfo(false)
        setAnchorEl(null)
    }
    const handleChangePage = (event, page) => {
        setPage(page)
        getPagedata(rowsPerPage, page)
    };
    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(parseInt(event.target.value))
        getPagedata(parseInt(event.target.value), page)
    };
    const handleFavSearch = (e) => {
        e.preventDefault();
        let value = e.target.value
        setSearchValue(value)
    }
    const clearSearch = () => {
        setSearchValue("")
    }
    const handleInfo = () => {
        sethistoryInfo(true)
        setAnchorEl(null)
    }
    let filteredData = TrHistoryParent
    if (searchValue) {
        filteredData = TrHistoryParent.filter((search) => {
            return (search.dateOfPayment.substring(0, 10) || search.dateOfProgram.substring(0, 10)).startsWith(searchValue)
        })
    }
    const handlePdfDownload = () => {
        const logo = document.createElement("div")
        logo.id = "logo"
        logo.style.width = "90%"
        logo.classList.add("flex", "flex-end")
        const logoImg = document.createElement("img")
        logoImg.src = logoImage
        logoImg.style.width = "80px"
        logo.appendChild(logoImg)

        const button = document.getElementById("buton")
        button.style.display = "none"
        const input = document.getElementById("divToPrint").innerHTML

        const div = document.createElement("div")
        div.id = "print"
        div.style.width = "90%"
        div.style.height = "100%"
        div.style.display = "block"
        div.innerHTML = input
        const mainDiv = document.createElement("div")
        const mainPrint = "mainPrint_" + new Date().toISOString()
        mainDiv.id = mainPrint
        mainDiv.appendChild(logo)
        mainDiv.appendChild(div)
        document.body.append(mainDiv)

        const print = document.getElementById(mainPrint)
        html2canvas(print, {
            useCORS: true,
            allowTaint: true,
            scrollY: -window.scrollY
        })
            .then((canvas) => {

                const image = canvas.toDataURL('image/jpeg');
                const doc = new jsPDF('p', 'pt', 'a4');
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;
                doc.addImage(image, 'JPEG', 20, 20, canvasWidth, canvasHeight)
                doc.save(`${historyDetail.parentName}.pdf`);
            })
        mainDiv.style.display = "none"
        handleClose()
    }

    const getStatusColor = (statusType) => {
        switch (statusType) {
            case paymentStatus.Pending:
                return "warning"
            case paymentStatus.Success:
                return "success"
            case paymentStatus.Failed:
                return "danger"
            default:
                return "info"
        }
    }

    return (
        <><Card>
            <CardBody>

                <SearchableTable
                    title="Payment History"
                    rows={rows}
                    filteredData={filteredData}
                    loading={trloadingParent}
                    actionTablebtn={false}
                    searchType="date"
                    searchLabel="Search By Date"
                    searchValue={searchValue}
                    clearSearch={clearSearch}
                    searchPlaceholder="By name"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    getSearchResult={handleFavSearch}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                >


                    {filteredData
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((d, i) => {
                            return (

                                <>
                                    <TableRow
                                        key={d.id}

                                    >
                                        <TableCell className="material-table__cell" >
                                            {(i + 1) + (page * rowsPerPage)}
                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right">
                                            {d.orderId}
                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right">
                                            {d.programOrder.length}
                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                                            {d.paymentMode}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >
                                            {moment(d.dateOfPayment).format('MMM DD, YYYY')}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right"
                                        >
                                            {d.amount}
                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right">
                                            {d.promoCode}</TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-center"
                                        >
                                            <Badge color={getStatusColor(d.paymentStatusEnum)}>{d.paymentStatusString}</Badge>

                                        </TableCell>
                                        <TableCell className="material-table__cell material-table__cell-right">
                                            <IconButton aria-controls="simple-menu" aria-haspopup="true" color="primary" onClick={(e) => handleMenu(e, d)}>
                                                <Icon>more_horiz</Icon>
                                            </IconButton>

                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={() => handleInfo()}>
                                                    <Icon fontSize="small" className="mr-4">info</Icon>
                                                    More Info
                                                </MenuItem>
                                                <MenuItem onClick={() => handleMessageModal()}>
                                                    <Icon fontSize="small" className="mr-4">contact_support</Icon>
                                                    Message Provider
                                                </MenuItem>
                                                <MenuItem onClick={() => handleCancelModal()}>
                                                    <Icon fontSize="small" className="mr-4">cancel</Icon>
                                                    Cancel</MenuItem>
                                                <MenuItem onClick={() => handlePdf()}>
                                                    <Icon fontSize="small" className="mr-4">picture_as_pdf</Icon>
                                                    Receipt </MenuItem>

                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                </SearchableTable>
                <MessageModal
                    title="Message Provider"
                    isOpen={modal.messageModal}
                    closeModal={closeMessage}
                    sendMessages={sendMessages}
                />

                <HistoryInfo
                    isOpen={historyInfo}
                    title="Payment History"
                    closeModal={handleClose}
                    historyDetail={historyDetail}
                    handlePdfDownload={handlePdfDownload}
                    getStatusColor={getStatusColor}
                />
                {cancelProgram && (<CancelRefund
                    isOpen={cancelProgram}
                    title="Cancel Program"
                    closeModal={handleClose}
                    historyDetail={historyDetail}
                    handleProgramCancelConfirm={(data) => handleProgramCancelConfirm(data)}
                    getStatusColor={getStatusColor}
                />)}

            </CardBody>
        </Card>
        </>

    )
}

export default ParentHistory