import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Button, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { paymentStatusOption, refundStatusOption } from "config/paymentCode";
import { RefundStatus } from 'config/paymentCode';
const UpdateStatus = ({
    title,
    isOpen,
    closeModal,
    statusValue,
    handleNewStatusValue,
    handleInputChange,
    refundAmount,
    updateStatus,
    paidAmount,
    updateTransaction,
    refundUpdate = false
}) => (
    
    <Modal className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <ModalBody className="login-form">
            <div className="form__form-group">
                <Autocomplete
                    size="small"
                    onChange={(e, newValue) => {
                        handleNewStatusValue(newValue)
                    }}
                    options={refundUpdate ? refundStatusOption : paymentStatusOption}
                    value={statusValue}
                    getOptionLabel={(option) => option && option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    renderInput={(params) => <TextField {...params} label="Status" required placeholder="Select status" variant="outlined" />}
                />
            </div>
            {refundUpdate && statusValue && statusValue?.id === RefundStatus.Partial && 
            <div className="form__form-group">
                <lable className="float-left">Refund Amount</lable>
                <input
                    type="number"
                    className="form__form-group-field"
                    max={paidAmount}
                    min={0}
                    onChange={(e) => {
                        handleInputChange(e.target.value)
                    }}
                />
            </div>}
        </ModalBody>

        <ModalFooter >
            <Button
                type="submit"
                color="primary"
                className=" text-tranform-none w-100 "
                variant="contained"
                size="medium"
                onClick={updateTransaction}
            >Update
            </Button>
        </ModalFooter>
    </Modal>
);

export default UpdateStatus
