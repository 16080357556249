import React, { } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Button, Divider } from "@material-ui/core"
import { renderField } from "../form/RenderField"
import { validate } from "../../../config/formValidation"
import { tagTypes } from "../../../config/tagTypes"
import ActivityTag from 'shared/components/form/ActivityTag';
const ProviderInfoForm = ({
    handleProviderInfo,
    handleSubmit,
    handleSkip,
    submitting,
    valid,
    reset
}) => {
    return (
        <form
            className="login-form"
            onSubmit={handleSubmit(handleProviderInfo)}
        >
            <Row>

                <Col md={6}>
                    <div className="form__form-group">
                        <Field
                            name="fName"
                            label="First Name*"
                            component={renderField}
                            type="text"
                            placeholder="First Name"
                            startIcon="person"
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form__form-group">
                        <Field
                            name="mName"
                            label="Middle Name"
                            component={renderField}
                            type="text"
                            placeholder="Middle Name"
                            startIcon="person"
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form__form-group">
                        <Field
                            label="Last Name*"
                            component={renderField}
                            type='text'
                            placeholder="Last Name"
                            className="input-without-border-radius"
                            name="lName"
                            startIcon="person"
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form__form-group">
                        <Field
                            label="Contact No*"
                            component={renderField}
                            type='number'
                            placeholder="Contact No"
                            className="input-without-border-radius"
                            name="contactNo"
                            startIcon="phone"
                        />
                    </div>


                </Col>

                <Col md={6}>
                    <div className="form__form-group">

                        <Field
                            label="Organisation*"
                            component={renderField}
                            type='text'
                            placeholder="Organisation Name"
                            className="input-without-border-radius"
                            name="organisationName"
                            startIcon="business"
                        />
                    </div>
                </Col>
                <Col md={6}>

                    <div className="form__form-group">

                        <Field
                            label="Website"
                            component={renderField}
                            type='text'
                            placeholder="Website"
                            className="input-without-border-radius"
                            name="website"
                            startIcon="language"

                        />

                    </div>

                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="form__form-group">

                        <Field
                            label="City Tags*"
                            component={ActivityTag}
                            type='text'
                            placeholder="City Tags"
                            className="input-without-border-radius"
                            name="locations"
                            startIcon="language"
                            tagType={[tagTypes.Location, tagTypes.School]}

                        />

                    </div>
                </Col>
            </Row>
            <Divider />
            <div className="flex flex-end  my-10">
                <div className="float-right">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={submitting || !valid}

                    >Continue
                    </Button>
                </div>
                <div className="float-right reset-bt-box">
                    <Button
                        color="secondary"
                        className="text-transform-none"
                        variant="text"
                        disabled={submitting}
                        onClick={reset}

                    >Reset
                    </Button>
                </div>
            </div>
        </form >
    );
};


export default connect(state => ({
    errorMsg: state.user.error,
}), { reset })(reduxForm({
    form: 'providerInfo',
    validate
})(ProviderInfoForm));
