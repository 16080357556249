import axios from "axios"
import { mediaTypes } from "../../config/mediaTypes"
import {
    EXPERIANCE_COMPLETED,
    PROVIDER_COMPLETED,
    USER_COMPLETED,
    PROGRAM_COMPLETED,
    EXPERIANCE_MEDIA,
    PROVIDER_MEDIA,
    USER_MEDIA,
    PROGRAM_MEDIA,
    PARENT_COMPLETED,
    PARENT_MEDIA,
    EXPERIANCE_COMPLETED_PDF,
    EXPERIANCE_MEDIA_PDF,
    PROGRAM_COMPLETED_PDF,
    PROGRAM_MEDIA_PDF
} from "../types"
export const uploadMedia = (data, mediaFor, uploadType = "image") => {
    return async dispatch => {
        let token = localStorage.getItem("authToken");
        const formData = new FormData();
        formData.append('body', data);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            },
            onUploadProgress: progressEvent => {
                let pCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                dispatch(setProgressUpload(mediaFor, pCompleted, uploadType))

            }
        };
        await axios.post(window.apiUrl + `Common/upload-file/${mediaFor}`, formData, config)
            .then(res => {
                if (res && res.data) {
                    dispatch(setUploadedFile(mediaFor, res.data, uploadType))
                }
            },
                error => {
                 console.log(error.message)
                }
            );
    };
}


//for dispatching progress
const setProgressUpload = (mediaFor, pCompleted, uploadType) => async dispatch => {
    switch (mediaFor) {

        case mediaTypes.Experience:
            if (uploadType === "image") {
                dispatch({
                    type: EXPERIANCE_COMPLETED,
                    payload: pCompleted
                })
            }
            if (uploadType === "pdf") {
                dispatch({
                    type: EXPERIANCE_COMPLETED_PDF,
                    payload: pCompleted
                })
            }


        case mediaTypes.ProviderLogo:
            dispatch({
                type: PROVIDER_COMPLETED,
                payload: pCompleted
            })
        case mediaTypes.UserAvatar:
            dispatch({
                type: USER_COMPLETED,
                payload: pCompleted
            })
        case mediaTypes.Program:
            if (uploadType === "image") {
                dispatch({
                    type: PROGRAM_COMPLETED,
                    payload: pCompleted
                })
            } 
            if (uploadType === "pdf") {
                dispatch({
                    type: PROGRAM_COMPLETED_PDF,
                    payload: pCompleted
                })
            }
         
        case mediaTypes.ParentLogo:
            dispatch({
                type: PARENT_COMPLETED,
                payload: pCompleted
            })
        default:
            return null
    }
}

// for setting value in reducer state 
const setUploadedFile = (mediaFor, file, uploadType) => async dispatch => {
    switch (mediaFor) {
        case mediaTypes.Experience:
            if (uploadType === "image") {
                dispatch(setExperienceMedia(file))
            } 
            if (uploadType == "pdf") {
     
                dispatch(setExperiancePdf(file))
            }
        case mediaTypes.ProviderLogo:
            dispatch(setProviderMedia(file))
        case mediaTypes.UserAvatar:
            dispatch(setUserMedia(file))
        case mediaTypes.Program:
            if (uploadType === "image") {
                dispatch(setMediaProgram(file)) 
            }
            if (uploadType === "pdf") {
                dispatch(setProgramPdf(file))
            }
        case mediaTypes.ParentLogo:
            dispatch(setParentMedia(file))
        default :
            return null
    }
}
export const setExperiancePdf = (file) => async dispatch => {
    dispatch({
        type: EXPERIANCE_MEDIA_PDF,
        payload:file
    })
}
export const setProgramPdf = (file) => async dispatch => {
    dispatch({
        type: PROGRAM_MEDIA_PDF,
        payload:file
    })
}
export const setExperienceMedia = (data) => async (dispatch) => {
    dispatch({ type: EXPERIANCE_MEDIA, payload: data })
}

export const setMediaProgram = (data) => async (dispatch) => {
    dispatch({ type: PROGRAM_MEDIA, payload: data })
}
export const setUserMedia = (data) => async (dispatch) => {
    dispatch({ type: USER_MEDIA, payload: data })
}
export const setProviderMedia = (data) => async (dispatch) => {
    dispatch({ type: PROVIDER_MEDIA, payload: data })
}
export const setParentMedia = (data) => async (dispatch) => {
    dispatch({ type: PARENT_MEDIA, payload: data })
}