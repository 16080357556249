import api from "../../utils/apiClient"
import {
    ACTIVITY_TAG, CATEGORY_TAG, GET_TAGS_BY_TAGTYPE, LOCATION_TAG, SCHOOL_TAG, CITY_TAG, MINIMUMAGE_TAG,
    TAG_DETAILS, DAYS_TAG, SELECTED_TAG, MAIN_SELECTED_TAG
} from "../types"
import { tagTypes } from "../../config/tagTypes"

export const getTagsByTagType = (data) => async (dispatch) => {
    
    api.tag.post(data, false)
        .then(res => {
            if (res.data) {
                let newData = []
                res.data.data.map((i) => {
                    newData.push({
                        id: i.id,
                        name: i.name,
                        tagType: i.tagType,
                        types: i.types,
                        color: "secondary",
                        variant: "outlined"
                    })
                })
                if (data === null) {
                    dispatch(setAllTag(newData))
                } else if (data === tagTypes.Location) {
                    dispatch(setLocationTag(newData))
                } else if (data === tagTypes.Category) {
                    dispatch(setCatergoryTag(newData))
                } else if (data === tagTypes.Activity) {
                    dispatch(setActivityTag(newData))
                } else if (data === tagTypes.City) {
                    dispatch(setCityTag(newData))
                } else if (data === tagTypes.School) {
                    dispatch(setSchoolTag(newData))
                } else if (data === tagTypes.MinimumAge) {
                    dispatch(setMinimumAgeTag(newData))
                }
            }

        })
        .catch(err => console.log(err))
}
export const getTagDetails = (id) => async (dispatch) => {

    await api.tag.getTagDetails(id)
        .then(res => {
            if (res.data) {

                dispatch(setTagDetails(res.data.result))
            }

        })
        .catch(err => console.log(err))
}

export const setTagDetails = (data) => dispatch => {
    dispatch({
        type: TAG_DETAILS,
        payload: data
    })
}

export const setMainSelectedTag = (data) => dispatch => {
    dispatch({
        type: MAIN_SELECTED_TAG,
        payload: data
    })
}

export const setAllTag = (tag) => async (dispatch) => {
    dispatch({
        type: GET_TAGS_BY_TAGTYPE,
        payload: tag
    })
    dispatch(setCatergoryTag(tag.filter((item) => item.tagType === 1)))
    dispatch(setLocationTag(tag.filter((item) => item.tagType === 2)))
    dispatch(setSchoolTag(tag.filter((item) => item.tagType === 3)))
    dispatch(setActivityTag(tag.filter((item) => item.tagType === 4)))
    dispatch(setCityTag(tag.filter((item) => item.tagType === 5)))
    // dispatch(setMinumumAgeTag(tag.filter((item) => item.tagType ===7)))
}
export const setLocationTag = (tag) => async (dispatch) => {
    dispatch({
        type: LOCATION_TAG,
        payload: tag
    })
}
export const setActivityTag = (tag) => async (dispatch) => {
    dispatch({
        type: ACTIVITY_TAG,
        payload: tag
    })
}
export const setCatergoryTag = (tag) => async (dispatch) => {
    dispatch({
        type: CATEGORY_TAG,
        payload: tag
    })
}
export const setSchoolTag = (tag) => async (dispatch) => {
    dispatch({
        type: SCHOOL_TAG,
        payload: tag
    })
}
export const setCityTag = (tag) => async (dispatch) => {
    dispatch({
        type: CITY_TAG,
        payload: tag
    })
}

export const setMinimumAgeTag = (tag) => async (dispatch) => {
    console.log("===================>")
    console.log("=========tag==========>", tag)
    dispatch({
        type: MINIMUMAGE_TAG,
        payload: tag
    })
}
export const setDaysTag = (tag) => async (dispatch) => {
    dispatch({
        type: DAYS_TAG,
        payload: tag
    })
}
export const setSelectedTag = (tag) => async (dispatch) => {
    dispatch({
        type: SELECTED_TAG,
        payload: tag
    })
}
/**
 * for getting all kind of tags
 */

export const getAllTags = (tagTypes) => async disaptch => {
    // debugger
    await api.tag.post(tagTypes).then(res => {
        // debugger
    }).catch((err) => {
        console.log(err.message)
    })
}