import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { studentList } from "../../../redux/actions/studentAction";
import StudentTable from "./components/StudentTable";
import HelmetComponent from "shared/components/HelmetComponent"
import { seoData } from "shared/StaticData"
export default function Student() {
  const StudentList = useSelector(
    (state) => state.student.studentAllList
  );
  const loading = useSelector((state) => state.student.studentAllListLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(studentList());
  }, []);



  
    

  return (
    <>
      <HelmetComponent seoData={seoData.student} />
      <Container>
        <StudentTable StudentList={StudentList} loading={loading} />
      </Container>



    </>
  );
}
