
import React from "react"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
      primary: {
        main: "#ef3e37",
      },
      secondary: {
        main: "#ef3e37", // for toogle switch
      },
    }
  });
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export const DWULTheme = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);