import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button } from "@material-ui/core"
import { Card, CardBody } from "reactstrap"
import NoRecord from "shared/components/NoRecord"

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        }
    },
    resolve:{
        color: "#299e02"
    },
    not_resolve: {
        color: "#a7a58e"
    }

})
const MessageList = () => {
    const classes = useStyles();
    const messageList = useSelector(state => state.message.parentMessages)
    return (
        <><Card>
            <CardBody>
                <h5 className="uppercase bold-text font-size-13 mb-16">Message History</h5>
                {
                    messageList && messageList.length > 0 && messageList.map((item, index) => <>
                        <Card className="p-0 mb-10" key={index}>
                            <CardBody
                                className="dashboard__booking-card w-100 p-0 pb-10"
                            >
                                <div className='flex flex-middle pb-10'>
                                    <Avatar
                                        src={item.providerName}
                                        className="ml-3"
                                        alt={item.providerName}
                                    />
                                    <div className="ml-8">
                                    <h4 className="bold-text ">
                                        {item.providerName}
                                    </h4>
                                    <h6 className="text-muted ">
                                        {item.email}
                                    </h6>
                                    </div>
                                </div>
                                {item.messageDetails &&
                                item.messageDetails.length > 0 &&
                                item.messageDetails.map((message, index) => (<Card className="p-0 mb-10">
                                <CardBody className="dashboard__booking-card w-100 bg-light-grey">
                                    <div className="flex flex-space-between"><h4 className="bold-text pb-10">{message.typeName}</h4><h6>{new Date(message.date).toLocaleString()} </h6></div>
                                    <h5 className="bold-text text-muted pb-8">{message.subject}</h5>
                                    <h6 className="text-muted">{message.fullMessage}</h6>
                                    <Button variant="text" className={message.isResolved ? classes.resolve + " pl-0 pr-0" : classes.not_resolve + " pl-0 pr-0" }>
                                        {message.isResolved ? "Resolved" : "Pending"}
                                    </Button>
                                </CardBody>
                            </Card>))}
                            </CardBody>
                        </Card>
                    </>)
                }
                {
                   messageList && messageList.length === 0 && (
                       <NoRecord />
                   ) 
                }
            </CardBody>
        </Card>
        </>
    )
}

export default MessageList