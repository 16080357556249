
import React from 'react';
import ProfileInfoLink from './ProfileInfoLink'
import {
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function ModalAddProgram(
  {
    title,
    isOpen,
    closeModal,
    programSubmit,
    setIsEdit,
    isEdit,
    setArrProgram
  }) {
  return (
    <>
      <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <ModalBody>
          <ProfileInfoLink
            programSubmit={programSubmit}
            closeModal={closeModal}
            isEdit={isEdit}
            setArrProgram={setArrProgram}
          />

        </ModalBody>
      </Modal>
    </>
  );
}


export default ModalAddProgram;
