import React from 'react';
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux"
import { IconButton, Icon, CircularProgress, Typography ,Box} from "@material-ui/core"
import { uploadMedia } from "../../../redux/actions/mediaAction"
import {mediaTypes} from "../../../config/mediaTypes"
// to show upload status
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={40} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FileInputField = ({ onChange, name, value,mediatype,myValue }) => {
  const dispatch = useDispatch()
  const { experierenceCompletedPdf,programCompletedPdf } = useSelector(state => state.media)
  const handleChange = async (e) => {

    e.preventDefault();
    const reader = new FileReader();
    const files = e.target.files[0];
    let url = reader.readAsDataURL(files);
    if (files) {
      await dispatch(uploadMedia(files, mediatype, "pdf"))
    }
  };
  const completedUpload = () => {
    let completedMedia = ""
    if (mediatype === mediaTypes.Program) {
      completedMedia = programCompletedPdf
    } else if (mediatype === mediaTypes.Experience) {
      completedMedia =  experierenceCompletedPdf
    }
    return completedMedia
  }

  return (
    <div className="flex flex-middle ">
      <div className="form__form-group-file">
        <label className="text-black" htmlFor={name}>Upload Flyer</label>
        <input
          type="file"
          name={name}
          id={name}
          onChange={handleChange}
        />


      </div>
      {completedUpload() && (<CircularProgressWithLabel value={completedUpload()} />)}
      {myValue && (
        <IconButton color="primary" onClick={() => window.location = myValue}>
          <Icon>download</Icon>
        </IconButton>
      )}
    </div>

  );
};

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

FileInputField.defaultProps = {
  value: null,
};

const renderFileInputField = ({ mediatype, input, meta,myValue }) => (
  <div className="form__form-group-input-wrap">
    <FileInputField mediatype={mediatype} {...input} myValue={myValue} />
    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
