import {SEND_MESSAGE,GET_MESSAGE,Resolve_MESSAGE,OPEN_MESSAGE,CLOSE_MESSAGE,GET_MESSAGE_ADMIN, PARENT_MESSAGE,
    OPEN_MESSAGE_ADMIN,CLOSE_MESSAGE_ADMIN
} from "../types";
import api from "../../utils/apiClient";
import { showFailedNoti, showSucessNoti } from "./notificationAction";

export const sendMessage=(data)=>async dispatch=>{
    await api.message.post(data,true).then(res =>{
        if(res.data){
           dispatch(showSucessNoti(res.data.message))
        }
        else{
            dispatch(showFailedNoti(res.data.message))
        }
    })
    .catch(err =>{
        // debugger
    })
}
export const getMessage=()=> async dispatch =>{
    await api.message.get(true).then(res =>{
        if(res.data){
            dispatch({
                type: GET_MESSAGE,
                payload: res.data
            })
        }
    })
    .catch(err =>{
        console.error(err)
    })
}
export const resloveMessage=(data)=> async dispatch =>{
    await api.message.resloved(data,true).then(res =>{
        // debugger
        if(res.data){
            dispatch(
                {
                    type:Resolve_MESSAGE,
                    payload:res.data
                }
            )
        }
    })
    .catch(err =>{
        // debugger
    })
}

export const openMessages = (data) => async (dispatch) => {
    dispatch({type: OPEN_MESSAGE,payload: data,})
}

export const closeMessages = (data) => async (dispatch) => {
    dispatch({type: CLOSE_MESSAGE,payload: data,})
}
export const openMessagesAdmin = (data) => async (dispatch) => {
    dispatch({type: OPEN_MESSAGE_ADMIN,payload: data,})
}

export const closeMessagesAdmin = (data) => async (dispatch) => {
    dispatch({type: CLOSE_MESSAGE_ADMIN,payload: data,})
}
export const getMessageAdmin =()=> async dispatch =>{
    await api.message.getMessageAdmin().then(res =>{
        if(res.data){
            dispatch({
                type: GET_MESSAGE_ADMIN,
                payload: res.data
            })
        }
    })
}

export const getMessageParent =()=> async dispatch =>{
    await api.message.getMessageParent().then(res =>{
        if(res.data){
            dispatch({
                type: PARENT_MESSAGE,
                payload: res.data
            })
        }
        else  dispatch(showFailedNoti(res.data.message))
    })
    .catch((err) => console.log(err.message))
}