import {  post, get } from './base/index';

export default {
    get: () => {
        return get(`${window.apiUrl}Resource/get-resources`,{},true)
    },
    post: (data) => {
        return post(`${window.apiUrl}Resource/Manage-Resource`,data,true)
    },
    delete: (id) => {
        return post(`${window.apiUrl}Resource/delete-resource?id=${id}`,{},true)
    },
    resourcesById: (id) => {
        return get(`${window.apiUrl}Resource/get-Resource?id=${id}`,{},true)
    }
}