import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment';
import {
    Col, Card, CardBody, Badge
} from 'reactstrap'
import { Button, Avatar, Icon, TableRow, TableCell } from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchableTable from 'shared/components/SearchableTable';

import { paymentStatus, RefundStatus } from "config/paymentCode"

const Cancellation = ({ refund = [], approve = false, columns = [], handleApproveModal, loading = false }) => {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(50)
    const [searchValue, setSearchValue] = useState("")

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value)
    };
    const handleChangePage = (event, page) => {
        setPage(page)
    };
    const clearSearch = () => {
        setSearchValue("")
    }
    const handleCancellationSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }

    let filteredData = refund
    if (searchValue) {
        filteredData = refund.filter((search) => {
            if ((search.parentName.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
                console.log("filterData", filteredData)
            }
        })
    }
    const getStatusColor = (statusType) => {
        switch (statusType) {
            case paymentStatus.Pending:
                return "warning"
            case paymentStatus.Success:
                return "success"
            case paymentStatus.Failed:
                return "danger"
            default:
                return "info"
        }
    }
    const getRefundStatusColor = (statusType) => {
        switch (statusType) {
            case RefundStatus.Pending:
                return "warning"
            case RefundStatus.Full:
                return "success"
            case RefundStatus.No:
                return "danger"
            case RefundStatus.Partial:
                return "info"
            default:
                return "info"
        }
    }
    return (
        <>
            <Card>
                <CardBody>
                    <h5 className="uppercase bold-text font-size-13 mb-24">Cancellation</h5>
                    <SearchableTable
                        title=""
                        rows={columns}
                        filteredData={filteredData}
                        loading={loading}
                        actionTablebtn={false}
                        searchType="text"
                        searchLabel="Search"
                        searchValue={searchValue}
                        clearSearch={clearSearch}
                        searchPlaceholder="Search Cancellation"
                        pagination={true}
                        getSearchResult={handleCancellationSearch}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    >
                        {filteredData
                            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                            .map((item, index) => (
                                <TableRow
                                    key={index}

                                >
                                    <TableCell>
                                        {(index + 1) + (page * rowsPerPage)}
                                    </TableCell>
                                    <TableCell className="material-table__cell" >
                                        {item.orderId}
                                    </TableCell>

                                    <TableCell className="material-table__cell" >
                                        {item.programName}
                                    </TableCell>
                                    <TableCell className="material-table__cell text-nowrap" >
                                        {item.paymentSource}
                                    </TableCell>

                                    <TableCell className="material-table__cell text-nowrap" >
                                        {moment(item.dateOfRequest).format('MMM DD, YYYY')}
                                    </TableCell>
                                    <TableCell className="material-table__cell text-nowrap" >
                                        {item.studentName}
                                    </TableCell>
                                    <TableCell className="material-table__cell text-nowrap" >
                                        {item.email}
                                    </TableCell>

                                    <TableCell className="material-table__cell" >
                                        <Badge color={getStatusColor(item.paymentStatusEnum)}>{item.paymentStatus}</Badge>

                                    </TableCell>
                                    <TableCell className="material-table__cell" >
                                        {item.amount}
                                    </TableCell>

                                    <TableCell className="material-table__cell" >
                                        <Badge color={getRefundStatusColor(item.refundStatusEnum)}>{item.refundStatus}</Badge>

                                    </TableCell>
                                    {approve && (<TableCell className="material-table__cell" >
                                        <Button
                                            disabled={(item.refundStatusEnum === RefundStatus.Pending) ? false : true}
                                            variant="outlined" color="primary" size="small"
                                            startIcon={<ArrowUpwardIcon />}
                                            onClick={() => handleApproveModal(item)}
                                        >
                                            Approve</Button>
                                    </TableCell>)}

                                </TableRow>
                            ))}
                    </SearchableTable>
                </CardBody>
            </Card>
        </>
    );
};


export default Cancellation;
