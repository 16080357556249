import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from "reactstrap"

import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CommentIcon from '@material-ui/icons/Comment';
import { useSelector, useDispatch } from "react-redux"
import { addNewStModalForm, paymentDetailsModal } from "../../../redux/actions/modalAction"
import { getparentProfile } from "../../../redux/actions/profileActions"

import NoRecord from "../../../shared/components/NoRecord"
import { useParams } from 'react-router';
import { getProgramById } from 'redux/actions/programActions';



export default function ModalRegisterProgram({ isOpen, title, closeModal, handleCart, handleMoreProgram }) {
  const {
    studentProfile,
    stLoading
  } = useSelector(state => state.profile)
  const { programDetails, detailsLoading } = useSelector(state => state.program)



  const [checked, setChecked] = React.useState([]);
  //handling check student value
  const handleToggle = (value) => () => {

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];




    // 

    if (currentIndex === -1) {

      if (checked.length < programDetails.capacity - programDetails.registredStudents) {
        newChecked.push(value);
        console.log("value checked", newChecked)
        console.log("the value", value)
      }

    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const dispatch = useDispatch()

  let { id } = useParams()

  useEffect(() => {
    dispatch(getparentProfile(id))
    dispatch(getProgramById(id))
    console.log("capacity", programDetails)

  }, [])

  return (
    <div>
      <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form"
        isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <div >
          <Button
            variant="outlined"
            className="float-right my-10"
            color="primary"
            onClick={() => dispatch(addNewStModalForm(true))}
          >
            Add Student
          </Button>
        </div>
        <ModalBody>
          <List>
            {stLoading ? (
              <div>
                <Skeleton variant="text" animation="wave" width="100%" />
              </div>
            ) : (!stLoading && studentProfile.length === 0 && (
              <Col>
                <NoRecord />
              </Col>
            ))}

            {studentProfile.map((list, i) => (

              <ListItem key={i} dense button onClick={handleToggle(list)}>

                <ListItemIcon >
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    checked={checked.indexOf(list) !== -1 && !list.isRegisteredForProgram}
                    //checked={checked.indexOf(list) !== -1}
                    disableRipple

                  />
                </ListItemIcon>
                <ListItemText primary={`${list.fName} ${list.lName}`} />
                <ListItemSecondaryAction>
                  <span className="capitalize">{list.gender}</span>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </ModalBody>

        <ModalFooter>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleMoreProgram(checked)}
            >Add More Program
            </Button>
          </div>
          <div className="pl-10">
            <Button autoFocus variant="outlined" className="pl-10" onClick={() => handleCart(checked)} color="primary">
              Pay Now
            </Button>
          </div>


        </ModalFooter>
      </Modal>
    </div>
  );


}
