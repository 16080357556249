
import { SUCCESS_NOTI, WARNING_NOTI, FAILED_NOTI, CLEAR_NOTI,ALL_NOTIFICATION, NOTI_LOADING,NOTI_COUNT } from "../types"
const initialState = {
  notificationList: [],
  notiLoading: true,
  notiCount:0
}
const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUCCESS_NOTI:
        return {
          ...state,
          successOpen: true,
          successMessage: action.payload
        };
        case FAILED_NOTI:
            return {
              ...state,
              errorOpen: true,
              errorMessage:action.payload
            };
      case  CLEAR_NOTI:
        return {
          ...state,
          successOpen: false,
          errorOpen: false,
          warningOpen: false,
          notificationList: []
        };
        case  WARNING_NOTI:
        return {
          ...state,
          warningOpen: true,
          warningMessage: action.payload
        };
        case  ALL_NOTIFICATION:
        return {
          ...state,
          notificationList: action.payload,
          notiLoading: false,
          notiCount:action.count
        };
      case NOTI_LOADING:
        return {
          ...state,
          notiLoading:action.payload
        }
      case NOTI_COUNT:
        return {
          ...state,
          notiCount:action.payload
        }
      default:
        return state;
    }
};
  export default notificationReducer
  