import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";
import CardNew from "../../shared/components/CardComponent/CardNew";
import SkeletonCard from "../../shared/components/CardComponent/SkeletonCard";
import { Row, Col } from "reactstrap";
import TopBar from "../../shared/components/TopBar";
import ModalAddExperiaces from "./Components/ModalAddExperience";
import {
  providerExperience,
  getproviderExpById,
  setExperienceById,
} from "../../redux/actions/experianceAction";
import api from "../../utils/apiClient";
import { useSelector, useDispatch } from "react-redux";
import { addExperienceModal } from "../../redux/actions/modalAction";
import ConfirmationModal from "../../shared/components/ConfirmationModal";
import {
  setExperienceMedia,
  setExperiancePdf,
} from "../../redux/actions/mediaAction";
import NoRecord from "../../shared/components/NoRecord";
import {
  showSucessNoti,
  showFailedNoti,
} from "../../redux/actions/notificationAction";
import { allExperiance } from "redux/actions/experianceAction";
const Experience = (props) => {
  const [deleteExp, setdeleteExp] = React.useState(false);
  const [expCurrent, setExpCurrent] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const expMedia = useSelector((state) => state.media.experierenceMedia);
  const expPdf = useSelector((state) => state.media.experierencePdf);
  const modal = useSelector((state) => state.modal);
  let [pageNumber, setPageNumber] = useState(1);
  const { providerExperienceData, loading, getAllExperiance } = useSelector(
    (state) => state.experiance
  );
  React.useEffect(() => {
    if (props.type !== "general") dispatch(providerExperience());
    else dispatch(allExperiance({ pageNo: pageNumber, pageSize: 10 }));
  }, [pageNumber]);
  const handleClose = () => {
    dispatch(reset("experianceForm"));
    dispatch(setExperiancePdf(""));
    dispatch(setExperienceById({}));
    dispatch(setExperienceMedia(""));
    dispatch(addExperienceModal(false));
    setdeleteExp(false);
  };
  // this is open add experience form in model
  const handleExp = () => {
    dispatch(setExperienceById({}));
    dispatch(addExperienceModal(true));
  };
  const expProviderSubmit = async (values) => {
    let expData = {
      id: values.id,
      adminCreated: false,
      name: values.name,
      experiencePhoto: expMedia ? expMedia : values.experiencePhoto,
      locationId: values.locationId,
      activity: values.activity,
      city: values.city,
      cost: values.cost ? parseFloat(values.cost) : 0,
      numberOfSessions: values.numberOfSessions
        ? parseInt(values.numberOfSessions)
        : 0,
      capacity: values.capacity ? parseInt(values.capacity) : 0,
      ageGroup: values.ageGroup,
      availability: values.availability?.join(","),
      description: values.description,
      file: expPdf ? expPdf : values.file,
      externalLink: values.externalLink,
    };
    await api.experience
      .manageExperience(expData, true)
      .then((res) => {
        if (res.data) {
          dispatch(showSucessNoti(res.data.message));
        }
        dispatch(providerExperience()).then(() => {
          if (isEdit) {
            setIsEdit(false);
          }
          handleClose();
        });
      })
      .catch((err) => {
        dispatch(showFailedNoti(err.message));
      });
  };
  const handleExpDelete = (data) => {
    setExpCurrent(data);
    setdeleteExp(true);
  };
  const handleExpEdit = (data) => {
    dispatch(getproviderExpById(data)).then(() => {
      setIsEdit(true);
      dispatch(addExperienceModal(true));
    });
  };

  const handleExpDeleteConfirm = async () => {
    let id = expCurrent;
    await api.experience
      .deleteProviderExp(id, true)
      .then((res) => {
        if (res.data) {
          dispatch(showSucessNoti(res.data.message));
        }
        setdeleteExp(false);
        dispatch(providerExperience());
      })
      .catch((err) => {
        dispatch(showFailedNoti(err.message));
      });
  };
  let listData =
    props.type !== "general" ? providerExperienceData.item1 : getAllExperiance;
  return (
    <>
      {props.type !== "general" && (
        <TopBar title="Experiences" buttonTitle="Add" buttonClick={handleExp} />
      )}
      <Row>
        {!loading && listData && listData.length === 0 && <NoRecord />}
        {loading && <SkeletonCard />}
        {!loading &&
          listData &&
          listData.length > 0 &&
          listData.map((item, index) => {
            return (
              <div className="flex flex-middle" key={index}>
                <CardNew
                  type="experience"
                  toolBar={props.type !== "general" ? true : false}
                  name={item.name}
                  image={item.experiencePhoto}
                  description={item.description}
                  id={item.id}
                  specialDates={item.specialDates}
                  availability={item.availability}
                  locations={item.locationId}
                  cost={item.cost}
                  activities={item.activity}
                  sessionsCount={item.numberOfSessions}
                  handleEdit={(id) => handleExpEdit(id)}
                  handleDelete={(id) => handleExpDelete(id)}
                  isManage={true}
                  item={item}
                />
              </div>
            );
          })}
      </Row>

      {modal.addExperienceModal && (
        <ModalAddExperiaces
          title="Create Experience"
          isOpen={modal.addExperienceModal}
          closeModal={handleClose}
          expSubmit={expProviderSubmit}
        />
      )}
      <ConfirmationModal
        color="danger"
        title="Delete Experiance"
        message="Are you sure you want to delete"
        colored=""
        header=""
        isOpen={deleteExp}
        closeModal={handleClose}
        onYesClick={handleExpDeleteConfirm}
          />
          <div className="topbar_login">
        {pageNumber > 1 ? (
          <button
            className="topbar_login_btn"
            onClick={() => {
              pageNumber--;
              console.log("pageNumber ", pageNumber);

              setPageNumber(pageNumber);
            }}
          >
            Previous
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="topbar_login_btn"
          onClick={() => {
            pageNumber++;
            console.log("pageNumber ", pageNumber);

            setPageNumber(pageNumber);
          }}
        >
          Next
        </button>
      </div>

      
    </>
  );
};

export default withRouter(Experience);
