import {ALL_RECOMMENDATION} from "../types"
import api from "../../utils/apiClient"
import {showSucessNoti} from "./notificationAction"
export const addRecommendation = (data) => async dispatch => {
    // debugger
    await api.recommend.addRecommendation(data,true).then(res =>{
        if(res.data){
            // debugger
            dispatch(showSucessNoti(res.data.message))
        }
    })
    .catch(err =>{
        console.error(err.message)
    })
}

export const getAllRecommendation = (pageSize=10000, pageNo=0) => async dispatch=> {
     await api.recommend.getRecommendation().then(res => {
        if (res) {

            dispatch({
                type: ALL_RECOMMENDATION,
                payload:res.data
            })
        }
    }).catch(err=> console.error(err.message))

}