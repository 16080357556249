import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux"
import SpeedDial from '@material-ui/lab/SpeedDial';
import Badge from '@material-ui/core/Badge';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useHistory } from "react-router-dom"
import { paymentDetailsModal } from "../../redux/actions/modalAction"
import { getCartDetails } from "../../redux/actions/cartAction"
import { authRoles } from "../../config/authroles"
const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));


export default function SpeedDials() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const [direction, setDirection] = React.useState('up');
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const { cartProduct } = useSelector(state => state.cart)
  const user = useSelector(state => state.user)
  React.useEffect(() => {
    if (user.role === authRoles.Parents && user.isAuthenticated) {
      dispatch(getCartDetails())
    }
  }, [user])

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  if ((cartProduct.orders.length === 0) || (user.role !== authRoles.Parents && !user.isAuthenticated)) {
    return null;
  }
  else {
    return (
      <div onClick={() => history.push("/cart")}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<Badge badgeContent={cartProduct.orders.length} color="secondary">
            <ShoppingBasketIcon onClick={() => history.push("/cart")} />
          </Badge>}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={direction}
        >
        </SpeedDial>
      </div>

    );
  }

}
