
export const subscriptionData = ["Free", "Community", "Advance", ]
//  meta and title for specific pages
const landindData = {
    title: "DWUL",
    char: "utf-8",
    meta: [
        {
            name: "",
            content:""
        }
    ]
}

const homeData = {
        title: "Home",
        char: "utf-8",
        meta: [
            {
                name: "Home",
                content:""
            }
        ]
}
const featuredData = {
    title: "Featured - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Featured",
            content:""
        }
    ]
}
const StudentData = {
    title: "Student - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Student",
            content:""
        }
    ]
}
const communityData = {
    title: "Community - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Community",
            content:""
        }
    ]
}

const SubscriptionData = {
    title: "Subscription - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Subscription",
            content:""
        }
    ]
}

const activityData = {
    title: "Activities - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Activities",
            content:""
        }
    ]
}

const listingsData = {
    title: "Listings - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Listings",
            content:""
        }
    ]
}

const calendarData = {
    title: "Calendar - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Calendar",
            content:""
        }
    ]
}

const profileData = {
    title: "Profile - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "Profile",
            content:""
        }
    ]
}

const aboutUsData = {
    title: "AboutUs - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "",
            content:""
        }
    ]
}

const faqData = {
    title: "Support - DWUL",
    char: "utf-8",
    meta: [
        {
            name: "",
            content:""
        }
    ]
}

//  title and content for meta --[name, descriprion]

export const seoData = {
    home: homeData,
    landing: landindData,
    featured: featuredData,
    activities: activityData,
    aboutUs:aboutUsData,
    faq:faqData,
    listings:listingsData,
    community:communityData,
    student:StudentData,
    profile:profileData,
    subscription:SubscriptionData,
    calendar:calendarData,

}

// days of group

export const daysofWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

//  age group

export const ageGroup=["pre-school",
"mid-school",
"senior-secondary"]
