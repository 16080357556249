import { post, get } from './base/index';

export default {
    post: (data, headers) => {
        const body = data;
        return post(`${window.apiUrl}Program/ManageProgram`, body, headers)
    },
    get: () => {
        return get(`${window.apiUrl}Program/provider-programs`)
    },
    getAllProgram: (programData, headers) => {

        const body = {
            searchText: programData.searchText ? programData.searchText : "",
            tags: programData.tags ? programData.tags : [0],

            ageGroup: programData.ageGroup ? programData.ageGroup : "",
            dayOfWeek: programData.dayOfWeek ? programData.dayOfWeek : "",
            pageNo: programData.ageGroup ? programData.ageGroup : 0,
            pageSize: programData.pageSize ? programData.pageSize : 0
        };

        return post(`${window.apiUrl}Program/all-programs`, body, headers)
    },

    programListing: (headers = true) => {
        return get(`${window.apiUrl}Program/GetProgramByProvider`, {}, headers)
    },
    LinkManagementList: (headers = true) => {
        return get(`${window.apiUrl}LinkManagement/GetLinkByProvider`, {}, headers)
    },
    addProgramLinkManagement: (data, headers = true) => {
        return post(`${window.apiUrl}LinkManagement/AddEdit-Link`, data, headers)
    },
    activeInactiveLinkProgram: (data, headers = true) => {
        return post(`${window.apiUrl}LinkManagement/ActiveDeactiveLink`, data, headers)
    },
    deleteLinkProgram: (data, headers = true) => {
        return post(`${window.apiUrl}LinkManagement/DeleteLink`, data, headers)
    },
    GetLinkByLinkName: (data, headers = true) => {
        return get(`${window.apiUrl}LinkManagement/GetLinkDetailsByLinkName?LinkName=${data}`, {}, headers)
    }
}
