import React from 'react';
import { TextField, Icon, InputAdornment, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Row, Col } from "reactstrap"

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(1),
      width: '15rem',
      
    },
  },
}));

export default function TableSearch(props) {
  const classes = useStyles();
  let {
    searchLabel,
    searchPlaceholder,
    getSearchResult,
    searchType = "search",
    searchValue = "",
    clearSearch
  } = props

  return (
    <Row>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <TextField
          size="small"
          className="mb-10"
          id="filled-search"
          label={searchLabel}
          placeholder={searchPlaceholder}
          type={searchType}
          variant="outlined"
          onChange={getSearchResult}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  fontSize="small"
                  color="primary"
                  className="cursor-pointer"
                  onClick={clearSearch}>close</Icon>
              </InputAdornment>
            )
          }}
        />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className="mb-10 float-right">

        {props.children}
        </div>
      </Col>
    </Row>
  );
}
