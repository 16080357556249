
import { SET_UNIV_LIST_PROGRAM, SET_UNIV_LIST_PROVIDER, SET_UNIV_LIST_EXPERIANCE, SET_UNI_LOADING, SET_GLOBAL_SEARCH_TEXT } from "../types/index"
import api from '../../utils/apiClient';
export const getSearchItem = (value) => async (dispatch) => {
  dispatch(allUniLoading(true));
  await api.tag.search({
    "searchText": value,
    "tags": [],

    "ageGroup": "",
    "dayOfWeek": "",
    "pageNo": 0,
    "pageSize": 0
  })
    .then((res) => {
      dispatch(setAllListing(res.data));
      dispatch(allUniLoading(false));
    })
    .catch(err => {
      dispatch(setAllListing({}));
      dispatch(allUniLoading(false));
      console.log(err)
    })
}

const setAllListing = (data) => async (dispatch) => {
  let { item1, item2, item3 } = data
  if (item1 && item1.length > 0) {
    let op = []
    item1.map((l) => op.push({ ...l, uniType: "program" }))
    dispatch(setUiniversalProgramData(op))
  } else dispatch(setUiniversalProgramData([]))
  if (item2 && item2.length > 0) {
    let op = []
    item2.map((l) => op.push({ ...l, uniType: "experiance" }))
    dispatch(setUiniversalExperianceData(op))
  }
  else dispatch(setUiniversalExperianceData([]))

  if (item3 && item3.length > 0) {
    let op = []
    item3.map((l) => op.push({ ...l, uniType: "provider" }))
    dispatch(setUiniversalProviderData(op))
  } else dispatch(setUiniversalProviderData([]))
}


const allUniLoading = (status) => async (dispatch) => {
  dispatch({
    type: SET_UNI_LOADING,
    payload: status
  })
}

export const setSearchText = (text) => async (dispatch) => {
  dispatch({
    type: SET_GLOBAL_SEARCH_TEXT,
    payload: text
  });
}






export const setUiniversalProgramData = (data) => async disaptch => {
  disaptch({
    type: SET_UNIV_LIST_PROGRAM,
    payload: data
  })
}
export const setUiniversalExperianceData = (data) => async disaptch => {
  disaptch({
    type: SET_UNIV_LIST_EXPERIANCE,
    payload: data
  })
}
export const setUiniversalProviderData = (data) => async disaptch => {
  disaptch({
    type: SET_UNIV_LIST_PROVIDER,
    payload: data
  })
}