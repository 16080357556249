import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, ButtonToolbar, Table
} from 'reactstrap'
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Button, Icon, TableRow, TableCell } from "@material-ui/core"
import Panel from '../../../shared/components/Panel'
import SearchableTable from "../../../shared/components/SearchableTable"

const columns = [
    {
        id: 0, label: 'Name',
    },
    {
        id: 1, label: 'Type',
    },
    {
        id: 2, label: '',
    }
];

const DetailsTagCard = ({
    openTagsModal,
    tagList,
    onRefresh,
    refreshTags,
    handleEditTag,
    handleDeletetag
}) => {

    const [searchValue, setSearchValue] = useState("")
    const clearSearch = () => {
        setSearchValue("")
    }
    const handleTagsSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    let filteredData = tagList
    if (searchValue) {
        filteredData = tagList.filter((search) => {
            if ((search.types.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }
            else if ((search.name.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }
        })
    }

    return (
        <Panel xl={6} lg={6} md={12} sm={12} xs={12} onRefresh={onRefresh} refresh={refreshTags} title="Tags"  >
            <SearchableTable
                title=""
                rows={columns}
                filteredData={filteredData}
                loading={refreshTags}
                actionTablebtn={true}
                actionBtnIcon="add"
                actionBtnText=""
                actionBtnClick={openTagsModal}
                searchType="text"
                searchLabel="Search"
                searchValue={searchValue}
                clearSearch={clearSearch}
                searchPlaceholder="Search Tags"
                pagination={false}
                getSearchResult={handleTagsSearch}
            >
                {filteredData.map((item, index) => (
                    <TableRow
                        key={index}
                    >
                        <TableCell padding="checkbox">
                            {index + 1}
                        </TableCell>
                        <TableCell className="material-table__cell text-nowrap" >
                            {item.name}
                        </TableCell>
                        <TableCell className={""} >
                            {item.types}
                        </TableCell>
                        <TableCell className="material-table__cell" >
                            <UncontrolledDropdown className="dashboard__table-more">
                                <DropdownToggle>
                                    <p><DotsHorizontalIcon /></p>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown__menu">
                                    <DropdownItem onClick={() => handleEditTag(item.id)}>Edit</DropdownItem>
                                    <DropdownItem onClick={() => handleDeletetag(item)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </ UncontrolledDropdown>
                        </TableCell>
                    </TableRow>
                ))}
            </SearchableTable>
        </Panel>
    )
};

export default withTranslation('common')(DetailsTagCard)

// const promoColor = () => {
//     const color_list = [
//         "bold-text dashboard__btc",
//         "bold-text dashboard__eth",
//         "bold-text dashboard__neo",
//         "bold-text dashboard__ste",
//         "bold-text dashboard__eos",
//         "bold-text dashboard__lit"
//     ]
//     return color_list[Math.floor(Math.random() * color_list.length - 1)]
// }

