import api from '../../utils/apiClient';
import { showSucessNoti, showFailedNoti } from "./notificationAction"
import {
    GET_TRANSACTION_HISTORY_PROVIDER,
    GET_TRANSACTION_HISTORY_PARENT,
    GET_TRANSACTION_HISTORY_ADMIN,
    TOTAL_COUNT,
    CANCEL_ORDER_LIST,
    CANCEL_ORDER,
    REFUND_ORDER_LIST,
    SALES_REPORT,
    APPROVE_REFUND,
    PAYMENT_SATRT,
    TOATAL_PAYOUTS
} from "../types"
import moment from 'moment';
export const addTransactionDetails = (data) => async dispatch => {
    // dispatch(startPayment(true))
    await api.transaction.addTractions(data).then(res => {
        if (res.data) {
            dispatch(showSucessNoti(res.data.message))
        }
    }).catch(err => {
        dispatch(showFailedNoti(err.message))
    })
}
export const getTrHistoryProvider = (pageSize = 1000, pageNo = 1) => async (dispatch) => {
    await api.transaction.getProviderTrHistory(true, pageSize = 1000, pageNo = 1).then(res => {
        if (res.data) {
            dispatch({
                type: GET_TRANSACTION_HISTORY_PROVIDER,
                payload: res.data
            })
        }
    }).catch(err => {
        console.error(err.message)
    })
}
export const getTrHistoryParent = (pageSize = 1000, pageNo = 1) => async (dispatch) => {
    await api.transaction.getParentTrHistory(true, pageSize, pageNo).then(res => {
        if (res.data) {
            dispatch({
                type: GET_TRANSACTION_HISTORY_PARENT,
                payload: res.data
            })
        }
    }).catch(err => { console.error(err.message) })
}
export const getTrHistoryAdmin = (pageSize = 1000, pageNo = 1) => async (dispatch) => {
    await api.transaction.getAdminTrHistory(true, pageSize, pageNo).then(res => {
        console.log("check",res.data)
        if (res.data) {
            dispatch({
                type: GET_TRANSACTION_HISTORY_ADMIN,
                payload: res.data
            })
        }
    }).catch(err => {
        console.error(err.message)
    })
}
export const getTotalCount = (data) => async (dispatch) => {
    await api.transaction.getTotalCount(true).then(res => {
        if (res.data) {
            dispatch({
                type: TOTAL_COUNT,
                payload: res.data
            })
        }
    }).catch(err => {
        console.error(err.message)
    })
}
export const cancelOrderList = () => async dispatch => {
    await api.transaction.cancelOrderList(true).then(res => {
        if (res.data) {
            dispatch({
                type: CANCEL_ORDER_LIST,
                payload: res.data
            })
        }
    })
}
export const cancelOrder = (id) => async dispatch => {
    await api.transaction.cancelOrder(id, true).then(res => {
        if (res.data) {
            dispatch({
                type: CANCEL_ORDER,
                payload: res.data
            })
        }
    })
}
export const refundList = (pageSize=10000000, pageNo=1) => async dispatch => {
    await api.transaction.refundOrderList(pageSize, pageNo, true).then(res => {
        if (res.data) {
            dispatch({
                type: REFUND_ORDER_LIST,
                payload: res.data
            })
        }
    })
        .catch(err => {
               console.error(err.message)
        })
}
export const refundListParent = (pageSize=1000, pageNo=1) => async dispatch => {
    await api.transaction.refundOrderListUser(pageSize, pageNo,true).then(res => {
      
        if (res.data) {
            dispatch({
                type: REFUND_ORDER_LIST,
                payload: res.data
            })
        }
    })
        .catch(err => {
               console.error(err.message)
        })
}
export const approveRefund = (id,status, amount = 0) => async dispatch => {
    await api.transaction.approveRefund(id,status,amount, true).then(res => {

        if (res.data.data) {
            dispatch(showSucessNoti(res.data.message))
        } else {
            dispatch(showFailedNoti(res.data.message))
        }
        
    }).catch((err)=>console.error(err.message))
}

export const startPayment = (payload) => async dispatch => {
    dispatch({
        type: PAYMENT_SATRT,
        payload: payload
    })
}

export const salesReport = () => async dispatch => {
    
    await api.transaction.salesReport(true).then(res => {
        {console.log("response",res.data.data)}
        if (res.data.data) {
            dispatch({
                type: SALES_REPORT,
                payload: res.data.data
            })
        }
    }).catch(err => {
        console.error(err.message)
    })
}


export const getTotalPayout = (
    startDate = new Date(),
    endDate = new Date(),
    pageNo = 1,
    pageSize = 10000
    ) => async dispatch => {
    const data = {
        pageNo: pageNo,
    pageSize: pageSize,
    startDate: startDate !== null ? moment(new Date(startDate)).format("YYYY-MM-DD") : "" ,
    endDate: startDate !== null ? moment(new Date(endDate)).format("YYYY-MM-DD") : ""
    }
    await api.transaction.getTotalPayout(data ,true)
        .then(res => {
            if (res.data) {
                dispatch({
                    type: TOATAL_PAYOUTS,
                    payload: res.data
                })
            }
        })
}