import React from 'react';
import {
  Modal, ModalHeader, ModalBody, Button, Col, Row,
} from 'reactstrap';
import UserInfoForm from '../../../../shared/components/userInfo/UserInfoForm';
const ModalUserForm = ({
    title,
    isOpen,
    closeModal,
    handleFirstName,
    handleLastName,
    handleMiddleName,
    handleUserInfo,
  role,
  handleSkip
}) => (
  <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} centered>
    <ModalHeader ><h5><strong>{title}</strong></h5></ModalHeader>
    <ModalBody>
      <UserInfoForm
        handleFirstName={handleFirstName}
        handleLastName={handleLastName}
        handleMiddleName={handleMiddleName}
        handleUserInfo={handleUserInfo}
        role={role}
        handleSkip={handleSkip}
      />
    </ModalBody>
  </Modal>
);


export default ModalUserForm;
