import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BigCalendar from './components/BigCalendar';
import EventLabels from './components/EventLabels';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { getCalender} from "../../../redux/actions/calenderAction"
import HelmetComponent from "shared/components/HelmetComponent"
import { seoData } from "shared/StaticData"

function Calendar({ rtl, user }) {
  const dispatch = useDispatch()
  const { calenderEvents } = useSelector(state => state.calendar)

  useEffect(() => {
   dispatch(getCalender())
  }, [])
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="mb-15">
            <h3 className=" uppercase bold-text">Calendar</h3>

          </div>
        </Col>
      </Row>
      <Row>
        <HelmetComponent seoData={seoData.calendar} />
        <BigCalendar
          dir={rtl.direction}
          events={calenderEvents} />

        <EventLabels   events={calenderEvents} />


      </Row>
    </Container>
  )

}

Calendar.propTypes = {
  rtl: RTLProps.isRequired,
};

export default connect(state => ({
  rtl: state.rtl,
  user: state.user
}))(Calendar);
