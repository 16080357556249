import React, { useState } from "react";
import { Card, CardBody, Col, Badge, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SearchableTable from "../../../shared/components/SearchableTable";
import moment from "moment";
import { TableRow, TableCell } from "@material-ui/core";
const ProgramListTable = ({ title }) => {
  const rows = [
    {
      id: "programName",
      label: "Program Name",
    },
    {
      id: "dateOfRegistration",
      label: "Date of Registration",
    },
    {
      id: "dateOfProgram",
      label: "Date of Program",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const programDetails = useSelector((state) => state.profile.parentRegisteredPrograms);
  const parentLoading  = useSelector((state) => state.profile.parentLoading);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const clearSearch = () => {
    setSearchValue("");
  };

  const handleFavSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  let filteredData = programDetails
  if (searchValue) {
    filteredData = programDetails.filter((item) =>
      item.programName.toLowerCase().startsWith(searchValue.toLowerCase())
    );
  }
  return (
    <>
        <SearchableTable
          title=""
          rows={rows}
          searchValue={searchValue}
          filteredData={filteredData}
          clearSearch={clearSearch}
          loading={parentLoading}
          actionTablebtn={false}
          searchLabel="Search program"
          searchPlaceholder="By name"
          rowsPerPage={rowsPerPage}
          page={page}
          getSearchResult={handleFavSearch}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <>
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((d, i) => {
              return (
                <TableRow
                  className="material-table__row cursor-pointer"
                  role="checkbox"
                  tabIndex={-1}
                  key={d.id}
                >
                  <TableCell
                    className="material-table__cell"
                    padding="checkbox"
                  >
                    {(i + 1)+(page * rowsPerPage)}
                  </TableCell>
                  <TableCell className="material-table__cell material-table__cell-center">
                    {d.programName}
                  </TableCell>
                  <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                    {moment(d.dateOfRegistration).local().format("MMM DD, YYYY ")}
                  </TableCell>
                  <TableCell className="material-table__cell material-table__cell-right text-nowrap">
                    {moment(d.dateOfProgram).local().format("MMM DD, YYYY ")}
                  </TableCell>
                </TableRow>
              );
            })}
            </>
        </SearchableTable>
    </>
  );
};

ProgramListTable.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation("common")(ProgramListTable);
