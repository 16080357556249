export const paymentCode = {
    payPal: 1,
    eTransfer: 2,
    Stripe: 3,
    PayLater: 4,
    NoPayment : 5
}
export const paymentStatus = {
    Pending : 1,
    Success : 2,
    Failed : 3
}
export const RefundStatus ={
   Full : 1,
   Partial : 2,
   No : 3,
   Pending : 4
}

export const paymentStatusOption = [{
    id: paymentStatus.Pending,
    name:"Pending"
},
{
    id: paymentStatus.Success,
    name:"Success"
},
{
    id: paymentStatus.Failed,
    name:"Failed"
},

]

export const refundStatusOption =[
{
    id: RefundStatus.Full,
    name:"Full"
},
{
    id: RefundStatus.Partial,
    name:"Partial"
},
{
    id:RefundStatus.No,
    name:"No Refund"
},
{
    id:RefundStatus.Pending,
    name:"Pending"
}
]