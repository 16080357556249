import React, { Component, Fragment, useReducer } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { DWULTheme } from "../../DWULTheme/DWULTheme"
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import Loading from '../../shared/components/Loading';
import history from "../../history"
import AuthGuard from "./AuthGuard"
import Notifications from "./Notification";
import { axiosInterceptor } from "../../utils/api/base/axios";
import LinkProgram from 'containers/LinkManagement/Components/LinkProgram';
import { BrowserRouter as Route, Switch } from 'react-router-dom';

import ReactGa from 'react-ga';

i18n.init(i18nextConfig);


export const AuthContext = React.createContext();

axiosInterceptor(history);
class App extends Component {


  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    ReactGa.pageview(window.location.pathname);
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
      var a = localStorage.getItem('username');
      if (a == null) {
        this.setState({ name: 'DWULRegistration' })
        localStorage.setItem('username', 'DWULRegistration');
      }
    });
  }

  onRedirectCallbackAuth0 = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  }

  render() {
    const { loaded, loading } = this.state;
    return (

      <Provider store={store}>
        <AuthGuard>
          <BrowserRouter history={history}>

            <I18nextProvider i18n={i18n}>

              <ScrollToTop>
                <Fragment>

                  {!loaded
                    && (
                      <Loading loading={loading} />
                    )
                  }

                  <DWULTheme>
                    <Notifications />
                    <div>

                      <Router />
                    </div>
                  </DWULTheme>

                </Fragment>
              </ScrollToTop>
            </I18nextProvider>

          </BrowserRouter>
        </AuthGuard>
        <Route path="/*/*" component={LinkProgram} />
      </Provider>
    );
  }
}

export default App;
