import React from 'react';
import { connect } from "react-redux"
import {
    Modal, ModalHeader, ModalBody, Alert
} from 'reactstrap';

const SchoolCommunityModal = ({
    title,
    isOpen,
    closeModal,
}) => (
    <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <ModalBody className="login-form">
            <h6 className="text-muted">
                For school inquiries, please contact
                 <strong>
                    <a href="mailto:info@dowhatuluv.ca"> info@dowhatuluv.ca</a>
                 </strong>
                &nbsp;
                and
                one of our community outreach members will get in touch with you
         </h6>
        </ModalBody>

    </Modal>
);
export default connect(state => ({
    errorMessage: state.user.error
}))(SchoolCommunityModal);
