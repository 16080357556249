
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import NotificationSystem from 'rc-notification';
import { FullWideNotification } from '../../shared/components/Notification';
import { setUserData } from '../../redux/actions/authActions';
import { axiosInterceptor } from "../../utils/api/base/axios"
import { userService } from "Services/userService"
import  localStorageService from "Services/localStorageService"
let notification = null;
NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
class AuthGuard extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.checkJwtAuth();

  }
  componentDidMount() {
    if (!window.navigator.onLine) {
      this.offlineNoti()
    }
    axiosInterceptor()
  }


  componentWillUnmount() {
    notification.destroy();
  }

  checkJwtAuth = () => {
    let authUser = localStorageService.getItem("authUser")
    this.props.setUserData(authUser)
  userService.loginWithToken().then(user => {
      //  this.props.setUserData(user)
    })
  };
  offlineNoti = () => {

    const notificationInitialProps = {
      content: <FullWideNotification
        color="danger"
        message="You are in offline mode."
      />,
      closable: false,
      duration: 10,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: `right-up ${this.props.rtl.direction}-support`,
    };
    notification.notice(notificationInitialProps);
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  theme: state.theme,
  rtl: state.rtl,
});


export default connect(mapStateToProps, {
  setUserData,
})(AuthGuard);

