import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardBody
} from 'reactstrap';
import classnames from 'classnames';
import ProfilePrograms from './ProfilePrograms';
import ProfileStudent from './ProfileStudent';
import ProfileUpdate from './ProfileUpdate';
import api from '../../../utils/apiClient';
import { getparentProfile } from "../../../redux/actions/profileActions"
import { setUserData, } from "../../../redux/actions/authActions"
import { showFailedNoti, showSucessNoti } from "../../../redux/actions/notificationAction"
import { authRoles } from "../../../config/authroles"
import { userService } from "../../../Services/userService"
import { Messages } from "config/notificationType";

class ProfileTabs extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.state = {
      activeTab: '1',
      isEdit: true
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleEdit() {
    const { isEdit } = this.state
    this.setState({
      isEdit: !isEdit
    })
  }
  // to get program id and student id
  getById = (values) => {
    let id = []
    values.map((p) => {
      id.push(p.id)
    })
    return id
  }
  updateParent = async (values) => {
    let data = {
      id: 0,
      avatar: this.props.userMedia ? this.props.userMedia : values.avatar,
      fName: values.fName,
      mName: values.mName,
      lName: values.lName,
      //email: values.email,
      city: values.city,
      address1: values.address1,
      province: values.province,
      postalCode: values.postalCode,
      interests: this.getById(values.interests),
      interestNames: []
    }
    let userData = {
      name: values.fName + values.mName + values.lName,
      email: values.email,
      avatar: this.props.userMedia ? this.props.userMedia : values.avatar,
      role: authRoles.Parents,
    }
    await api.profile.updateParentProfile(data, true).then(res => {
      if (res.data) {
        this.props.getparentProfile(0)
        this.props.showSucessNoti(res.data.message)
        this.props.setUserData(userData)
        userService.setUserLocal(userData)
        this.setState({ isEdit: true })
        console.log("update parent profile", res.data)
      }
      // else {
      //   this.props.showFailedNoti(res.data.message)
      // }
    }).catch(err => {
      this.props.showFailedNoti(Messages.GeneralError)
    })

  }

  render() {
    const initialValues = {
      username: 'Larry Boom',
      email: 'boom@mail.com',
    };

    const { activeTab } = this.state;
    return (
      <Card>
        <CardBody className="p-0">
          <div className="profile__card tabs tabs--bordered-bottom">
            <Nav tabs className="pl-0 pt-4">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }} >
                  My Parent info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }} >
                  Programs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }} >
                  Persons
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="px-24" activeTab={activeTab}>
              <TabPane tabId="3" style={{
                backgroundColor: '#fff',
              }}>
                <ProfileStudent />
              </TabPane>
              <TabPane tabId="2" style={{
                backgroundColor: '#fff',
              }}>
                <ProfilePrograms />
              </TabPane>
              <TabPane tabId="1" style={{
                backgroundColor: '#fff',
              }}>
                <ProfileUpdate onSubmit={this.updateParent} toggleEdit={this.toggleEdit} isEdit={this.state.isEdit} initialValues={initialValues} />
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userMedia: state.media.userMedia
  }
}
export default connect(mapStateToProps, { getparentProfile, showFailedNoti, showSucessNoti, setUserData })(ProfileTabs)