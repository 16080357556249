import React from 'react';
import {
  Card, CardBody
} from 'reactstrap';
import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const CardPro = ({handleProgramPartners}) => (
  <Card className="mt-20 p-0 " >
    <CardBody className="pricing-card pricing-card p-0 m-10 ">
      <div className="pricing_border">
        <div className="pricing-card__body p-10 ">
          <h3 className="pricing-card__plan">Program Partner</h3>
          <hr className="hr" />
          <div>
            <span>({`10% + 30`}&#162; per. reg)</span>
          </div>
          <div className="mt-20">
            {/* <h5 ></h5> */}
            <p className="pricing-card__price">{``}<span></span> </p>
            <p className="pricing-card__price">{``}<span></span> </p>
          </div>
          <div className="mt-10">
            <p className=" my-10 flex flex-middle ">
              <CheckCircleOutlineIcon className="text-success" />
              <span className="mx-4 text-muted">Limited Spots Available</span>

            </p>
            <p className=" my-10 flex flex-middle ">
              <CheckCircleOutlineIcon className="text-success" />
              <span className="mx-4 text-muted">Partnership support for DWUL Foundation</span>

            </p>
            <p className=" my-10 flex flex-middle ">
              <CheckCircleOutlineIcon className="text-success" />
              <span className="mx-4 text-muted">Promote to Schools</span>

            </p>

          </div>



        </div>
        <Button
          onClick={()=>handleProgramPartners()}
          className="my-10"
          variant="outlined"
          size="small"
          color="primary"
          disableElevation>
          Request info
          </Button>
      </div>
    </CardBody>
  </Card>
);

export default CardPro;
