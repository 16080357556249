import React, { useEffect } from 'react';
import { TextField, InputAdornment, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
export default function AutoSuggest(props) {
  let { input,
    label,
    type,
    startIcon,
    multiple = false,
    options,
    defaultValue,
    meta: { touched, error, warning },
    ...custom
  } = props;
  const [tagIcon, setTagIcon] = React.useState("label")
  const [valueLocal, setValueLocal] = React.useState({})
  useEffect(() => {
    if (input.value ) {
      // debugger
      let valueTag = options.filter((tag) => {
        if (tag.tagType === input.value) {
          return tag
        }
      })
      setValueLocal(valueTag[0])
      setTagIcon(valueTag[0] ? valueTag[0].icon : "label")
    } else {
      setValueLocal({})
      setTagIcon(" ")
    }
  },[])
  return (
    <div>
      <Autocomplete
        id="size-small-outlined-multi"
        size="small"
        options={options}
        value={valueLocal}
        getOptionLabel={(option) => {
          if (multiple) {
            return option
          } else {
            return option.types
          }

        }}
        getOptionSelected={(option,value)=>option.types === value.types}
        onChange={(e, newValue) => {
          // debugger
          input.onChange(newValue)
          setTagIcon(newValue !== null ? newValue.icon : "label")
          setValueLocal(newValue)
        }}
        renderOption={(option) => {
          if (multiple) {
            return option
          } else {
            return(
              <React.Fragment>
                <span className="mr-12"><Icon>{option.icon}</Icon></span>
                {option.types}
              </React.Fragment>
            )
          }
         }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>{tagIcon ? tagIcon: startIcon}</Icon>
                </InputAdornment>
              ),
            }}
            {...custom}
          />
        )}
      />
      {touched &&
        ((error &&
          <span className="text-danger">{error}</span>)
          ||
          (warning && <span>{warning}</span>))}
    </div>
  );
}
