import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonToolbar, Modal } from 'reactstrap';
import {Button} from "@material-ui/core"
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';

const ConfirmationModal = ({
  color, btn, title, message, colored, header, rtl,isOpen,closeModal,onYesClick, noToolbar = false, noHeader = false
}) => {
  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        centered
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="resgister-modal-area-box">
          {!noHeader && (<div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeModal} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>)}
          <div className="modal__body">
            {message}
          </div>
          {!noToolbar && (<ButtonToolbar className="modal__footer">
            <Button variant="outlined" color="secondary" className="modal_cancel"  onClick={closeModal}>Cancel</Button>{' '}
            <Button
              className="modal_ok"
              variant="contained"
              color="primary"
              onClick={onYesClick}>Ok</Button>
          </ButtonToolbar>)}
          </div>
      </Modal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  rtl: RTLProps.isRequired,
};

ConfirmationModal.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: true,
};

export default connect(state => ({
  rtl: state.rtl,
}))(ConfirmationModal);
