import React, { useState } from "react";
import { withRouter } from "react-router";
import { connect, useSelector } from "react-redux";
import { reset } from "redux-form";
import { authRoles } from "../../../config/authroles";
import PropTypes from "prop-types";
import ModalLoginForm from "./Components/ModalLoginForm";
import ModalForgetPasswordForm from "./Components/ModalForgetPassword";
import { authError, setUserData } from "../../../redux/actions/authActions";
import { getparentProfile } from "../../../redux/actions/profileActions";
import { Modal, ModalHeader, ModalBody, Alert, Button } from "reactstrap";
import {
  addNewStModal,
  loginModal,
  registerModal,
  userInfoModal,
  addNewStModalForm,
  providerInfoModal,
  forgetPasswordModal,
  modalSubscrition,
  paymentSubsModal,
  useExistingAddress,
} from "../../../redux/actions/modalAction";
import { setUserRoleCode } from "../../../redux/actions/userAction";
import {
  showFailedNoti,
  showSucessNoti,
} from "../../../redux/actions/notificationAction";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import { userService } from "../../../Services/userService";
import ModalRegisterForm from "./Components/ModalRegisterForm";
import ModalUserForm from "./Components/ModalUserForm";
import api from "../../../utils/apiClient";
import ModalAddNewSt from "./Components/ModalAddNewSt";
import ModalAddNewStForm from "./Components/ModalAddNewStForm";
import ModalProviderInfo from "./Components/ModalProviderInfo";
import ModalSubscription from "./Components/ModalSubscription";
import PaymentSubsModal from "./Components/PaymentSubsModal";
import { setStudentProfileById } from "redux/actions/studentAction";
import { Messages } from "../../../config/notificationType";
import moment from "moment";

function AuthForm(WrappedComponent) {
  const HocAuth = (props) => {
    const { history, dispatch } = props;
    const [roleCode, setRoleCode] = useState();
    const [skipModal, setSkipModal] = useState(false);
    const [userDataLocal, setuserDataLocal] = useState({}); // used for keeping value of user in component state so we can use it after user completed registration flow
    const modal = useSelector((state) => state.modal);
    const user = useSelector((state) => state.user);
    const { userRoleCode } = useSelector((state) => state.users);
    const [registerValues, setRegisterValues] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isRegisterConfirmed, setIsRegisterConfirmed] = useState(false);
    const closeAddNewStForm = () => {
      dispatch(addNewStModalForm(false));
      dispatch(reset("Add_new_student"));
      dispatch(setStudentProfileById({}));
    };
    React.useEffect(() => {
      selectRole();
    }, []);
    const selectRole = (role = authRoles.Parents) => {
      dispatch(setUserRoleCode(role));
    };
    const selectRole2 = (role) => {
      dispatch(setUserRoleCode(role));
    };

    // For logong user
    const handleLogin = async (userData) => {
      await userService
        .login(userData)
        .then((user) => {
          if (!user.data) {
            dispatch(authError(user.message));
          } else {
            let data = {
              name: user.data.name,
              avatar: user.data.image,
              role: user.data.role,
              //email: user.data.email,
              contact: user.data.contact,
            };
            dispatch(setUserData(data)).then(() => {
              if (window.location.pathname == "/") history.push("/home");

              // window.history.back()

              dispatch(loginModal(false));
              dispatch(showSucessNoti(user.message));
            });
          }
        })
        .catch((err) => console.error(err));
    };
    const getRoleCode = (role) => {
      let code = 3;
      if (role === 3) {
        return (code = 12);
      }
      if (role === 4) {
        return (code = 13);
      }
    };

    const handleRegister = async (values) => {
      setShowConfirmationModal(true);
      setRegisterValues(values);
      // if (isRegisterConfirmed) {
      //   console.log("in isRegister confirmed");
      //   let userData = {
      //     tokenKey: values.tokenKey,
      //     roleCode: getRoleCode(userRoleCode),
      //     email: values.email,
      //     password: values.password,
      //   };

      //   await userService
      //     .register(userData)
      //     .then((user) => {
      //       if (!user.data) {
      //         dispatch(authError(user.message));
      //       } else {
      //         let data = {
      //           name: user.data.name,
      //           avatar: user.data.image,
      //           role: user.data.role,
      //           email: user.data.email,
      //           contact: user.data.contact,
      //         };
      //         dispatch(registerModal(false));
      //         // dispatch(authError({}))
      //         dispatch(showSucessNoti(user.message));
      //         setuserDataLocal(data); // used for keeping value of user in component state so we can use it after user completed registration flow
      //         if (user.data.role === authRoles.Provider) {
      //           //dispatch(providerInfoModal(true))
      //           dispatch(
      //             showSucessNoti(
      //               "Please verify your email address to activate your account."
      //             )
      //           );
      //         } else if (user.data.role === authRoles.Parents) {
      //           //dispatch(userInfoModal(true))
      //           dispatch(
      //             showSucessNoti(
      //               "Please verify your email address to activate your account."
      //             )
      //           );
      //         }
      //       }
      //     })
      //     .catch((err) => dispatch(showFailedNoti(Messages.GeneralError)));
      // }
      // console.log("out isRegister confirmed");
    };

    const handleRegisterConfirmed = async (values) => {
      console.log("in isRegister confirmed");
      let userData = {
        tokenKey: values.tokenKey,
        roleCode: getRoleCode(userRoleCode),
        email: values.email,
        password: values.password,
      };

      await userService
        .register(userData)
        .then((user) => {
          if (!user.data) {
            dispatch(authError(user.message));
          } else {
            let data = {
              name: user.data.name,
              avatar: user.data.image,
              role: user.data.role,
              email: user.data.email,
              contact: user.data.contact,
            };
            dispatch(registerModal(false));
            // dispatch(authError({}))
            dispatch(showSucessNoti(user.message));
            setuserDataLocal(data); // used for keeping value of user in component state so we can use it after user completed registration flow
            if (user.data.role === authRoles.Provider) {
              //dispatch(providerInfoModal(true))
              dispatch(
                showSucessNoti(
                  "Please verify your email address to activate your account."
                )
              );
            } else if (user.data.role === authRoles.Parents) {
              //dispatch(userInfoModal(true))
              dispatch(
                showSucessNoti(
                  "Please verify your email address to activate your account."
                )
              );
            }
          }
        })
        .catch((err) => dispatch(showFailedNoti(Messages.GeneralError)));
    };
    const handleUserInfo = async (values) => {
      let data = {
        id: 0,
        fName: values.fName,
        mName: values.mName,
        lName: values.lName,
        //email: values.email,
        address1: values.address1,
        province: values.province,
        interests: [],
        city: values.city,
        postalCode: values.postalCode,
        favorites: 0,
      };
      await api.profile
        .updateParentProfile(data, true)
        .then((res) => {
          if (res.data.data) {
            dispatch(showSucessNoti(res.data.message));
            dispatch(userInfoModal(false));
            dispatch(addNewStModal(true));
            window.location.href = "/home";
          }
        })
        .catch((err) => {
          dispatch(showFailedNoti(Messages.GeneralError));
        });
    };
    const handleNewStudent = () => {
      dispatch(addNewStModalForm(true));
    };
    const submitAddNewSt = async (value) => {
      let data = {
        id: value.id,
        fName: value.fName,
        mName: value.mName,
        lName: value.lName,
        birthDate: moment(value.birthDate).format("YYYY-MM-DD"),
        //birthDate: moment(value.birthDate).format("yyyy-mm-dd"),
        schoolTags: value.schoolTags.id,
        grade: value.isAdult === true ? "Adult" : value.grade,
        // grade: value.grade,
        currentAcademicDate: new Date().toISOString(),
        emergencyContact: value.emergencyContact,
        medicalCondition: value.medicalCondition,
        learningNeeds: value.learningNeeds,
        gender: value.gender,
        isAdult: value.isAdult ? true : false,
        // email: "teacher@yopmail.com",

        useExistingAddress: value.useExistingAddress ? true : false,

        phone: value.phone,
        photo: "",
        photoPermission: value.photoPermission ? true : false,
        liabilityWaiver: value.liabilityWaiver ? true : false,
        address1: value.address1,
        city: value.city,
        postalCode: value.postalCode,
        province: value.province,
      };
      await api.student
        .addStudent(data, true)
        .then((res) => {
          if (res.data.data) {
            dispatch(showSucessNoti(res.data.message));
            dispatch(getparentProfile(0)).then(() => {
              dispatch(addNewStModalForm(false));
              dispatch(reset("Add_new_student"));
              dispatch(setStudentProfileById({}));
            });
          } else {
            dispatch(showFailedNoti(res.data.message));
            dispatch(reset("Add_new_student"));
            dispatch(setStudentProfileById({}));
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    };
    const handleProviderInfo = async (value) => {
      let data = {
        id: 0,
        fName: value.fName,
        mName: value.mName,
        lName: value.lName,
        email: value.email,
        organisationName: value.organisationName,
        activity: [],
        locations: value.locations,
        locationName: "",
        city: "",
        description: "",
        website: value.website,
        contactNo: value.contactNo,
        logoUrl: "",
        ageGroup: [],
      };

      await api.profile
        .updateProviderProfile(data, true)
        .then(async (res) => {
          if (res.data.data) {
            dispatch(providerInfoModal(false)).then(() => {
              dispatch(modalSubscrition(true, false));
              dispatch(showSucessNoti(res.data.message));
              if (window.location.pathname == "/")
                window.location.href = "/home";
            });

            // await setCurrentUser()
          } else {
            dispatch(showFailedNoti(res.data.message));
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    };
    const handleSkip = () => {
      setSkipModal(true);
      window.location.href = "/home";
    };
    const handleSkipModalClose = () => {
      setSkipModal(false);
      window.location.href = "/home";
    };
    const handleSkipConfirm = async () => {
      //setCurrentUser()
      closeModal();
      window.location.href = "/home";
    };
    const setCurrentUser = async () => {
      dispatch(setUserData(userDataLocal)).then(() => {
        history.push("/home");
        closeModal();
      });
    };
    const closeModal = () => {
      dispatch(authError(""));
      if (modal.userInfoModal) {
        dispatch(userInfoModal(false));
      }
      if (modal.addNewStModal) {
        dispatch(addNewStModal(false));
      }
      if (modal.providerInfoModal) {
        dispatch(providerInfoModal(false));
      }
      if (modal.loginModal) {
        dispatch(loginModal(false));
      }
      if (modal.forgetPasswordModal) {
        dispatch(forgetPasswordModal(false));
      }
      if (modal.registerModal) {
        dispatch(registerModal(false));
      }
      if (skipModal) {
        handleSkipModalClose();
        history.push("/home");
      }
    };
    const handleComplete = async () => {
      //await setCurrentUser()
      closeModal();
      history.push("/home");
    };
    const handlePaymentModalClose = () => {
      dispatch(paymentSubsModal(false));
    };
    const closeModalSubscription = () => {
      if (modal.subscriptionModal.isEdit) {
        dispatch(modalSubscrition(false));
      } else {
        setCurrentUser().then(() => {
          dispatch(modalSubscrition(false));
        });
      }
    };

    return (
      <>
        <Modal
          toggle={() => {
            setShowConfirmationModal(false);
          }}
          className="theme-light ltr-support login-modal-form"
          size="md"
          isOpen={showConfirmationModal}
          centered
        >
          <ModalHeader>
            <h5>
              <strong>
                Do you want to signup as a{" "}
                {userRoleCode === authRoles.Parents ? "Parent" : "Provider"}?
              </strong>
            </h5>
          </ModalHeader>
          <ModalBody>
            <button
              type="button"
              onClick={() => {
                setIsRegisterConfirmed(false);
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                setIsRegisterConfirmed(true);
                handleRegisterConfirmed(registerValues);
                setShowConfirmationModal(false);
              }}
            >
              Confirm
            </button>
          </ModalBody>
        </Modal>

        {modal.loginModal && (
          <ModalLoginForm
            title="Login to your account"
            isOpen={modal.loginModal}
            handleLogin={handleLogin}
            closeModal={closeModal}
          />
        )}
        {modal.forgetPasswordModal && (
          <ModalForgetPasswordForm
            title="Forgot password"
            isOpen={modal.forgetPasswordModal}
            closeModal={closeModal}
          />
        )}
        <ModalRegisterForm
          title="Register an account"
          isOpen={modal.registerModal}
          handleRegister={handleRegister}
          closeModal={closeModal}
          roleCode={userRoleCode}
          selectRole={selectRole2}
          setuserDataLocal={(user) => setuserDataLocal(user)}
        />

        <ModalUserForm
          title="Update Info"
          isOpen={modal.userInfoModal}
          closeModal={closeModal}
          handleUserInfo={handleUserInfo}
          roleCode={userRoleCode}
          handleSkip={handleSkip}
        />
        <ModalAddNewSt
          title="Registration Profile"
          isOpen={modal.addNewStModal}
          closeModal={closeModal}
          handleNewStudent={handleNewStudent}
          handleSkip={handleSkip}
          handleComplete={handleComplete}
        />
        {modal.addNewStModalForm && (
          <ModalAddNewStForm
            title=""
            isOpen={modal.addNewStModalForm}
            closeModal={closeAddNewStForm}
            submitFormAddNewSt={submitAddNewSt}
          />
        )}
        <ModalSubscription
          title="Choose Plan"
          isOpen={modal.subscriptionModal.open}
          closeModal={closeModalSubscription}
          setCurrentUser={setCurrentUser}
        />
        <ModalProviderInfo
          title="Account Info"
          isOpen={modal.providerInfoModal}
          closeModal={closeModal}
          handleProviderInfo={handleProviderInfo}
          role={userRoleCode}
          handleSkip={handleSkip}
        />
        <ConfirmationModal
          color="warning"
          title="Do you want to skip?"
          message=""
          colored={false}
          header={false}
          isOpen={skipModal}
          closeModal={handleSkipModalClose}
          onYesClick={handleSkipConfirm}
        />
        {modal.paymentSubs && (
          <PaymentSubsModal
            open={modal.paymentSubs}
            handleClose={handlePaymentModalClose}
            title="Choose Payment"
            handlePaymentSucess={closeModalSubscription}
          />
        )}

        <WrappedComponent selectRole={selectRole} {...props} />
      </>
    );
  };

  HocAuth.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };
  return connect()(withRouter(HocAuth));
}

export default AuthForm;
