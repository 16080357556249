import { GET_ENROLLED_PROGRAM_STUDENT, } from "../types";

const initialState = {
    program: {},
    sendEnrolledStudent: {}
}

const enrollmentReducer =(state = initialState, action)=>{
    switch(action.type){

    case GET_ENROLLED_PROGRAM_STUDENT:
        return {
            ...state,
            program: action.payload
        }
    default:
        return state;
    }
}
export default enrollmentReducer;