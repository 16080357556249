import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Modal, ModalHeader, ModalBody, Alert
} from 'reactstrap';
import Form from './Form';
import { Divider } from '@material-ui/core/';
export default function MessageModal({ isOpen, title, closeModal, sendMessages }) {

  return (
    <>
      <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <Divider />
        <ModalBody>

          <Form
            closeModal={closeModal}
            sendMessages={sendMessages}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
