import { SUCCESS_NOTI, WARNING_NOTI, FAILED_NOTI, CLEAR_NOTI, ALL_NOTIFICATION, NOTI_LOADING, NOTI_COUNT } from "../types"
import api from "../../utils/apiClient"



export const showSucessNoti = (message) => (dispatch) => {
    dispatch({
        type: SUCCESS_NOTI,
        payload: message
    })
}
export const showWarningNoti = (message) => (dispatch) => {
    dispatch({
        type: WARNING_NOTI,
        payload: message
    })
}
export const showFailedNoti = (message) => (dispatch) => {
    dispatch({
        type: FAILED_NOTI,
        payload: message
    })
}
// / actions/snackbarActions.js
export const clearNoti = () => async(dispatch) => dispatch({ type: CLEAR_NOTI });

//  get all notification

export const getAllNotification = (pageSize = 0, pageNo = 0) => {
    return async dispatch => {
        await api.noti.getAllNotification(pageSize, pageNo).then((res) => {
            if (res) {
                if (res.data) {

                    let filterData = res.data.filter((read) => !read.isRead)
                    dispatch({
                        type: ALL_NOTIFICATION,
                        payload: res.data,
                        count: filterData.length
                    })
                }
            }

        }).catch((err) => {
            console.error(err.message)
        })
    }

}

export const deleteNotification = (id) => async dispatch => {
    return await api.noti.deleteNotifcation(id).then(res => {
        if (res) {
            dispatch(showSucessNoti(res.data.message))
            dispatch(getAllNotification())
        }
    }).catch(err => {
        console.error(err.message)
    })
}

export const notiLoading = (loading = true) => async (dispatch) => {
    dispatch({
        type: NOTI_LOADING,
        payload: loading
    })
}

export const notificationCount = (count = 0) => async dispatch => {
    dispatch({
        type: NOTI_COUNT,
        payload: count
    })
}

/**
 * clear notifications from api
 */


export const clearNotiFromApi = () => async dispatch => {
    await api.noti.clearNotifications().then(res => {
        if (res) {
            dispatch(getAllNotification())
        }
    }).catch(err => {
         console.log(err.message)
     })
 }
