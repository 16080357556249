import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TableRow, TableCell } from "@material-ui/core"
import { getAllRecommendation, addRecommendation } from "../../../redux/actions/recommendationActions"
import SearchableTable from "shared/components/SearchableTable"
import { Card, CardBody } from "reactstrap"
import RecommendationModal from "./components/RecommendationModal"




const Recommendation = () => {
    const [recomModal, setRecomModal] = React.useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(50)
    const [searchValue, setSearchValue] = useState("")
    const dispatch = useDispatch()
    const { recommendations, recomLoading } = useSelector(state => state.recommend)
    React.useEffect(() => {
        dispatch(getAllRecommendation())
    }, [])
    const rows = [
        {
            id: 1, label: 'Program Name',
        },
        {
            id: 2, label: 'Location',
        },
        {
            id: 3, label: 'City',
        },
        {
            id: 4, label: 'Website',
        },
        {
            id: 5, label: 'Category',
        },
        {
            id: 6, label: 'Activity',
        },
        // {
        //     id: "", label: '',
        // },

    ];
    const getDetailsInfo = (id) => {
        // dispatch(getParentProviderFavById(id)).then((() => {
        //     setFavModal(true)
        // }))


    }
    const handleClose = () => {
        setRecomModal(false)
    }
    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(event.target.value)
    };

    const handleFavSearch = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value)
    }
    const clearSearch = () => {
        setSearchValue("")
    }
    const handleRecommendModal = () => {
        setRecomModal(true)
    }
    const submitRecommendation = (values) => {
        let data = {
            id: values.id,
            programName: values.programName,
            location: values.location,
            city: values.city,
            website: values.website,
            category: values.category,
            activity: values.activity,
            reason: values.reason,
            file: ""
        }
        dispatch(addRecommendation(data)).then(() => {
            handleClose()
            dispatch(getAllRecommendation())
        })
    }
    let filteredData = recommendations
    if (searchValue) {
        filteredData = recommendations.filter((user) => user.name.toLowerCase().startsWith(searchValue.toLowerCase()))
    }
    return (
        <>
            <Card>
                <CardBody>
                    <SearchableTable
                        title="Recommendation"
                        rows={rows}
                        searchValue={searchValue}
                        filteredData={filteredData}
                        clearSearch={clearSearch}
                        loading={recomLoading}
                        actionTablebtn={false}
                        getDetailsInfo={getDetailsInfo}
                        searchLabel="Search"
                        actionTablebtn={true}
                        actionBtnIcon="add"
                        actionBtnText=""
                        actionBtnClick={handleRecommendModal}
                        searchPlaceholder="By name"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        getSearchResult={handleFavSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    >

                        {filteredData
                            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                            .map((d, i) => {
                                return (
                                    <TableRow
                                        className="material-table__row cursor-pointer"
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={d.id}
                                    // onClick={() => getDetailsInfo(d.id)}
                                    >
                                        <TableCell className="material-table__cell" padding="checkbox">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right "
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            {d.programName}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >{d.location}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >{d.city}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >{d.website}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >{d.category}
                                        </TableCell>
                                        <TableCell
                                            className="material-table__cell material-table__cell-right text-nowrap"
                                        >{d.activity}
                                        </TableCell>
                                        {/* <TableCell
                                    className="material-table__cell material-table__cell-right"
                                >
                                    <IconButton color="primary" onClick={() => getDetailsInfo(d.id)}>
                                        <Tooltip title="info">
                                            <Icon>info</Icon>
                                        </Tooltip>

                                    </IconButton>
                                </TableCell> */}
                                    </TableRow>
                                );
                            })}
                    </SearchableTable>

                    <RecommendationModal
                        title="Details"
                        isOpen={recomModal}
                        closeModal={handleClose}
                        submitRecommendation={submitRecommendation}

                    />

                </CardBody>
            </Card>
        </>

    )
}

export default Recommendation