import React, { useState } from 'react';
import axios from "axios"


export default function CsvInput() {

    const [csvFile, setCsvFile] = useState("");
 
 const handleChange = (event) => {
   
     setCsvFile(event.target.files[0]);
     
     if (csvFile !== undefined) {
              console.log(event.target.files[0])
              let formData = new FormData();
         formData.append('file', event.target.files[0]);
 
              
            uploadCsv(formData)
          }
    
     }

    return (
        <div>
              <br></br>
            <h4>Upload CSV :</h4>
        <input
        type="file"
       
        name="file"
        icon='file text outline'
        iconPosition='left'
        label='Upload CSV'
        labelPosition='right'
        placeholder='UploadCSV...'
        onChange={handleChange}
    /></div>);
  
}

async function uploadCsv(formData) {
    
 let token = localStorage.getItem("authToken");

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        };
    // 
        await axios.post(window.apiUrl + `Experience/Upload-Experiences`, formData, config)
            .then(res => {
                
                
            },
                error => {
                 console.log("upload error : "+error.message)
                }
            );
    
        
    
}