import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import{CircularProgress,Typography} from "@material-ui/core"
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import {uploadMedia} from "../../../redux/actions/mediaAction"
import { useSelector,useDispatch } from "react-redux"
import Box from '@material-ui/core/Box'
import { mediaTypes } from '../../../config/mediaTypes';
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    },
    inputDis: {
        display: 'none',
    },
    large: {
        width: theme.spacing(8),
        height:theme.spacing(8)
    },
      wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
      buttonSuccess: {
        backgroundColor: "#ffffff",
        '&:hover': {
          backgroundColor: "#ffffff",
        },
      },
      fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
}));
function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
const UploadFile = ({accept, onImageUpload,pCompleted,disabled}) => {
    const classes = useStyles();

    const buttonClassname = clsx({
      [classes.buttonSuccess]: pCompleted,
    });

    return (<>
      <input
        accept={accept}
        onChange={onImageUpload}
        className={classes.inputDis}
        id="icon-button-file"
        type="file"
        disabled={disabled}
      />
      <label htmlFor="icon-button-file">
              <div className={classes.wrapper}>
        <IconButton
                    aria-label="save"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    className={buttonClassname}
                    size="small"
           >
                    {pCompleted=== "" ?
                        <PhotoCamera fontSize="small" />
                        :
                        < CircularProgressWithLabel value={pCompleted} />} 
        </IconButton>
               
      </div>
      </label>
       
    </>)
}
export default function ImageUpload({ image, accept, mediatype, input,disabled=false }) {
    const classes = useStyles();
    const dispatch = useDispatch()
  const [error, seterror] = React.useState("");
  const media = useSelector (state=>state.media)
  const onFileChange = (e) => {
      const files = e.target.files[0];
    if (files !== undefined) {
      if (files.size > 5000000 && accept==='image/*') {
        seterror("File size is too large select below 5mb");
      } else {
        seterror("");
        onUpload(e);
      }
    }
    };
  const onUpload = (e) => {
        const reader = new FileReader();
        const files = e.target.files[0];
        let url = reader.readAsDataURL(files);
        if (files) {
          dispatch(uploadMedia(files,mediatype))
    }
  }
  const completedUpload = () => {
    let completedMedia = ""
    if (mediatype === mediaTypes.Program) {
      completedMedia = media.programCompleted
    } else if (mediatype === mediaTypes.Experience) {
      completedMedia = media.experierenceCompleted
    }
    else if(mediatype === mediaTypes.ProviderLogo)
    completedMedia = media.providerCompleted
    else if(mediatype === mediaTypes.UserAvatar){
      completedMedia = media.userCompleted 
    }
    return completedMedia
  }
  return (
    <div className={classes.root}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
              badgeContent={<UploadFile
                  onImageUpload={onFileChange}
                accept={accept}
                disabled={disabled}
                pCompleted={completedUpload()}
              />}
      >
         <Avatar className={classes.large} alt="u" src={image}/>
              
          </Badge>
          <p>{error}</p>
          <small className="text-nowrap">Upload Photo</small>
    </div>
  );
}
