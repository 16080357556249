import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Panel from "../../../shared/components/Panel";
import { Field, reduxForm } from "redux-form";
import { CardBody, Col, Row, DropdownMenu, DropdownItem, UncontrolledDropdown,Card } from "reactstrap";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from "react-redux"
import { getTotalPayout } from "redux/actions/transactionActions"
import {getThisWeekDate, getThisMonthDate, getThisYear } from "shared/helpers"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    textAlign: "end"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const routePath = {
  pathname: `/activities`,
};

const TotalPayout = ({ handleSubmit }) => {

  const classes = useStyles();
  const dispatch = useDispatch()
  const {totalPayouts} = useSelector(state=> state.transaction)
  const [day, setday] = React.useState(1);

  const handleChange = (event) => {
    setday(event.target.value);
    switch(event.target.value) {
      case 1: dispatch(getTotalPayout())
      break;
      case 2: const {startDate, endDate} = getThisWeekDate()
      dispatch(getTotalPayout(startDate, endDate))
      break;
      case 3: const {startDay, endDay} = getThisMonthDate()
      dispatch(getTotalPayout(startDay, endDay))
      break;
      case 4: const {start, end} = getThisYear()
      dispatch(getTotalPayout(start, end))
      break;
      case 5: dispatch(getTotalPayout(null))
      break;
    }
  };
  useEffect(()=> {
  dispatch(getTotalPayout())
  },[])
  return (
    <Col  xl={4} lg={6} md={12} sm={12} xs={12}>
      <Card>
      <CardBody className="dashboard__card-widget py-28 px-24" >
        <Row className="">
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
        <h5 className="uppercase bold-text font-size-13 mb-16">Sales   Report</h5>
        </Col>
        <Col md={6} xl={6} lg={6} sm={6} xs={6}>
            <form onSubmit={handleSubmit} className={classes.formControl}>
              <FormControl variant="" size="small" >
                <Select
                  value={day}
                  onChange={handleChange}
                > 
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>This Week</MenuItem>
                  <MenuItem value={3}>This Month</MenuItem>
                  <MenuItem value={4}>This Year</MenuItem>
                  <MenuItem value={5}>Max</MenuItem>
                </Select>
              </FormControl>
            </form>
          </Col>
        </Row>
        <Row className="py-24">
          <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p className="dashboard__sales-report-now" > Collection</p>
          <p >${parseFloat(totalPayouts.totalAmount).toFixed(2)}</p>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p className="dashboard__sales-report-now"> Fees</p>
          <p >${parseFloat(totalPayouts.fees).toFixed(2) }</p>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p className="dashboard__sales-report-now"> Payout</p>
          <p >${parseFloat(totalPayouts.payoutAmount).toFixed(2)}</p>
          </Col>
        </Row>
      </CardBody>
      </Card>
    </Col>
  )
};

TotalPayout.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "TotalSales",
})(TotalPayout);
