import { PROMO_CODE_LIST, PROMO_CODE_DETAILS } from "../types"

const initialState = {
    promocodeList: [],
    promoDetails: {}
}

const promoReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROMO_CODE_LIST:
            return {
                ...state,
                promocodeList: action.payload
            };
        case PROMO_CODE_DETAILS:
            return {
                ...state,
                promoDetails: action.payload
            };
        default:
            return state;
    }
};
export default promoReducer