
import { GET_PROGRAM,PROGRAM_LIST_BY_PROVIDER, GET_PROVIDER_PROGRAM, GET_PROVIDER_PROGRAM_BY_ID, SEND_PROGRAM, SET_PROGRAM_BY_ID,GET_ENROLLED_PROGRAM,ALL_PROGRAM_LOADING,
PARENT_PROGRAM_DATES, 
PROGRAM_DETAILS_LOADING,
SEARCH_PROVIDER_PROGRAM} from "../types";

const initialState = {
  allProgram: [],
  providerProgramData: [],
  programById: {},
  programDetails:{},
  enrolledProgram: [],
  loading: true,
  providerLoading: true,
  myListLoading:true,
  programDates: [],
  detailsLoading:true,
  programListByProvider:{
    programs: [],
    experience: []
  }
}
const programReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRAM:
      return {
        ...state,
        allProgram: action.payload,
        loading:false

      };
    case SEND_PROGRAM:
      return {
        ...state,
        payload: action.payload

      }
    case GET_PROVIDER_PROGRAM:
      return {
        ...state,
        providerProgramData: action.payload,
        providerLoading:false
      }
      case SEARCH_PROVIDER_PROGRAM:
        return {
          ...state,
          programSearchQuery: action.payload,
        }
    case GET_PROVIDER_PROGRAM_BY_ID:
      return {
        ...state,
        programById: action.payload
      }
    case SET_PROGRAM_BY_ID:
      return {
        ...state,
        programDetails: action.payload,
        detailsLoading:false
        
      }
    case GET_ENROLLED_PROGRAM:
      return {
        ...state,
        enrolledProgram: action.payload,
        myListLoading:false,
        loading: false
      }
    case PARENT_PROGRAM_DATES:
      return{
        ...state,
        programDates: action.payload
      }
    case PROGRAM_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading:action.payload
      }
    case ALL_PROGRAM_LOADING:
      return {
        ...state,
        loading:action.payload
      }
      case PROGRAM_LIST_BY_PROVIDER:
      return {
        ...state,
        programListByProvider:action.payload
      }
    default:
      return state;
  }
};

export default programReducer;
