import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import ReactGa from 'react-ga';
import * as serviceWorker from './serviceWorker';
window.apiUrl = process.env.REACT_APP_BASE_URL
ReactGa.initialize('G-910WCKZ88G', []);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
