import React, { useState } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import { Button, Checkbox } from "@material-ui/core"
import { useDispatch, connect } from 'react-redux';

import {
  Col, Row
} from 'reactstrap';
import { loginModal, registerModal } from "../../../redux/actions/modalAction"
import { renderField } from "../form/RenderField"
import { validate } from "../../../config/formValidation"
const RegisterForm = ({
  handleRegister,
  handleSubmit,
  fieldUser,
  submitting,
  valid
}) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleLoginModal = () => {
    dispatch(registerModal(false)).then(() => {
      dispatch(loginModal(true))
    })
  }

  const handleChange = (event) => {
    setIsAccepted(event.target.checked)
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleRegister)}
      >
        <Row>
          <Col>
            <div className="form__form-group">
              <Field
                name="email"
                label="Email*"
                component={renderField}
                type="email"
                placeholder={fieldUser}
                startIcon="email"
              />
            </div>
            <div className="form__form-group">
              <Field
                label="Password*"
                component={renderField}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                className="input-without-border-radius"
                name="password"
                showPasswordToggle={showPasswordToggle}
                startIcon="vpn_key"
                toggleBtn={true}
              />
            </div>

            <div className="form__form-group">

              <Checkbox
                name="termscondition"
                color="primary"
                onChange={handleChange}
              />
              <label>I have read and accept the <a href="/support" target="_blank" id="copylink"><u>account policies</u></a></label>
            </div>

            <div >
              <Button
                className=" account__btn text-tranform-none w-100"
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || !valid || !isAccepted}

              >Start
              </Button>
            </div>
          </Col>
        </Row>


      </form>
      <div className="mt-10">
        <p>Already have an account? <strong onClick={handleLoginModal} className="text-secondary cursor-pointer pl-10 text-tranform-none">Login Now</strong></p>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log("stateee", state)
  return ({
    recaptchaLoad: state.user.recaptchaLoad,
    initialValues: {
      tokenKey: state.user.recaptchaToken ? state.user.recaptchaToken : "",
      email: "",
      password: "",
      termscondition: null
    }
  })
}
export default connect(mapStateToProps, null)(reduxForm({
  form: 'registrationForm',
  validate,
  enableReinitialize: true
})(RegisterForm));
