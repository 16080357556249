import api from "../../utils/apiClient"
import {GET_FAVORITE,SET_FAV_PROV_ID,GET_PARENT_FAV} from "../types"

export const getParentFavorite=()=> async dispatch =>{
    api.favorite.parentFavorite().then(res => {
        if (res.data) {
            dispatch({
                type: GET_PARENT_FAV,
                payload:res.data
           })
        }
    }).catch(res => {
        
    })
}

export const getParentProviderFavById = (id) => async dispatch => {
    api.favorite.parentproviderProfile(id).then((res) => {
        if (res.data) {
            // debugger
            dispatch({
                type: SET_FAV_PROV_ID,
                payload:res.data
         })
     }
    }).catch(err => {
        // debugger
    })
    
}