import React from 'react';
import {useDispatch}  from "react-redux"
import { NavLink } from 'react-router-dom';
import ChangePassForm from "../../../shared/components/ChangePassword/ChangePassForm"
import {resetPassword}  from "../../../redux/actions/authActions"
const logo = require("../../../shared/img/logo/LOGO.png")

const ResetPassword = (props) => {
  let token = props.location.search
  const dispatch = useDispatch()
  const onSubmit = async(item) => {
    await dispatch(resetPassword(item.newPassword, token.substring(4))).then(() => {
      props.history.push("/")
    })
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="">
            <img src={logo} style={{width:'100px', height:'100px', marginLeft:'33%'}} className="d-flex justify-content-center"></img>
            <br/>
            <h4 className="account__subhead subhead">Password reset</h4>
          </div>
          <ChangePassForm
            {...props}
            onSubmit={onSubmit}
            form="reset_password_form"
          />
          <div className="account__have-account">
            <p>Remember your password?  <NavLink to="/">home</NavLink></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
