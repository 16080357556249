import api from "../../utils/apiClient"
import { PARENT_CALENDER_EVENTS, PROVIDER_CALENDER_EVENTS } from "../types"
const getmidNightDate = (date) => {
    let midNight = new Date(date).setUTCHours(0,0,0,0)
    return new Date(midNight)
}
export const getCalender = () => async (dispatch) => {
    let calendarEvents = []
    await api.calender.getproviderEvents().then(res => {
        if (res.data) {
            res.data.map((events, idx) => {
                events.regulerDates.map((d, i) => {
                    // debugger
                    calendarEvents.push({
                        title: events.title,
                        allDay: true,
                        start: new Date(d),
                        end: getmidNightDate(d),
                        priority: 'family',
                    })
                    
                })
                events.specialDates.map((d, i) => {
                    // debugger
                    calendarEvents.push({
                        title: events.title,
                        allDay: true,
                        start: new Date(d),
                        end: getmidNightDate(d),
                        priority: 'high',
                    })
                    
                })
            })
            dispatch(setProviderEvents(calendarEvents))
        }
    }).catch(err => {
        console.error(err.message)
    })
}
export const setProviderEvents = (events) => (dispatch) => {
    dispatch({
        type: PROVIDER_CALENDER_EVENTS,
        payload: events
    })
}


export const getParentsCalender = () => async (dispatch) => {
    let calendarEvents = []
    await api.calender.getparentsEvents().then(res => {
        if (res) {
             res.data.map((e, i) => {
                calendarEvents.push({
                    title: e.title,
                    allDay: e.allDay,
                    start: new Date(e.start),
                    end: new Date(e.end),
                    priority: e.data ? "high" : "non",
                })
            })
            dispatch(setParentsEvents(calendarEvents))
        }
    }).catch(err => {
        console.error(err)
    })
}


export const setParentsEvents = (events) => async (dispatch) => {
    dispatch({
        type: PARENT_CALENDER_EVENTS,
        payload: events
    })
}