export const announcementType = {
    SpecificStudents : 1,
    ProgramStudents :2,
    AllStudents : 3,
    AllProvider : 4
}

export const announcementData = [{
    id: announcementType.SpecificStudents,
    name: "Specific  Student"
},
{
    id: announcementType.ProgramStudents,
    name: "Programs"
},
{
    id: announcementType.AllStudents,
    name: "All Students"
}
]

export const adminannouncementData = [{
    id: announcementType.SpecificStudents,
    name: "Specific  Student"
},
{
    id: announcementType.ProgramStudents,
    name: "Programs"
},
{
    id: announcementType.AllStudents,
    name: "All Students"
},
{
    id: announcementType.AllProvider,
    name: "All Providers"
}
]