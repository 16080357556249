// import React from 'react';
// import Table from '@material-ui/core/Table';
// import { Button, Icon } from "@material-ui/core"
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import MatTableHead from './components/MatTableHead';
// import TableContainer from '@material-ui/core/TableContainer';
// import { Skeleton } from "@material-ui/lab"
// import TableSearch from "./components/TableSearch"
// import MatTableToolbar from './components/MatTableToolbar';
// import NoRecord from "../NoRecord"
// import FilterSelect from "./components/FilterSelect"

// const SearchableTable = (props) => {
//   const handleActionBtnClick = () => {
//     alert("Button Clicked!")
//   }
//   let { title = "Searchable Table",
//     rows,
//     filteredData = [],
//     loading,
//     searchLabel = "Search",
//     searchPlaceholder = "Search..",
//     getSearchResult,
//     rowsPerPage = 50,
//     page = 0,
//     handleChangePage,
//     handleChangeRowsPerPage,
//     searchType,
//     searchValue,
//     clearSearch,
//     actionTablebtn = false,
//     actionBtnIcon = "add",
//     actionBtnText = "",
//     actionBtnClick = handleActionBtnClick,
//     filtermode = false,
//     pagination = true,
//     height = "370px",
//     filterAction = false,
//     count
//   } = props
//   // for search result
//   const checkHeight = () => {
//     if (pagination) {
//       if(filteredData.length !== 0) return ({ height: "auto" })
//       else return ({ height: height })
//     }
//     else  return ({ height: height })
//   }
//   const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - (page * rowsPerPage));
//   // FilterMode  :false--default , set true if want sorting
//   // deleteMode :false-- default , set true enable checkbox and delete all row in table
//   return (
//     <>
//       {title !== "" && (<div className="card__title">
//         <h5 className="bold-text">{title}</h5>
//       </div>)}
//       <TableSearch
//         searchLabel={searchLabel}
//         searchPlaceholder={searchPlaceholder}
//         getSearchResult={getSearchResult}
//         searchType={searchType}
//         searchValue={searchValue}
//         clearSearch={clearSearch}
//       >
//         <div className="flex">
//         {actionTablebtn &&
//           (<Button onClick={actionBtnClick} color="primary" size="medium" variant="outlined" className="rounded mx-10" >
//             <Icon fontSize="default" >{actionBtnIcon}</Icon>{actionBtnText}
//           </Button>)}
//           {filterAction &&
//           (<FilterSelect />)}
//         </div>
       
//         {filtermode && (<MatTableToolbar />)}
//       </TableSearch>
//       <div className="material-table__wrap">
//         <TableContainer style={checkHeight()}>
//           <Table stickyHeader className="material-table">
//             <MatTableHead
//               rowCount={filteredData.length}
//               rows={rows}
//             />
//             <TableBody>
//               {loading ? (<TableRow
//                 className="material-table__row"
//                 role="checkbox"
//               >
//                 {rows.map((Cell, i) => {
//                   return <TableCell className="material-table__cell material-table__cell-right"
//                     component="th"
//                     scope="row"
//                     key={i}
//                     padding="default">
//                     <Skeleton width="100%" />
//                   </TableCell>
//                 })}
//               </TableRow>)
//                 :
//                 filteredData.length === 0
//                 &&
//                 (<NoRecord />)}
//               {props.children}
//             </TableBody>
//           </Table>
//         </TableContainer>

//       </div>
//       {pagination && (<TablePagination
//         component="div"
//         className="material-table__pagination"
//         count={count ? count : filteredData.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         backIconButtonProps={{ 'aria-label': 'Previous Page' }}
//         nextIconButtonProps={{ 'aria-label': 'Next Page' }}
//         onChangePage={handleChangePage}
//         onChangeRowsPerPage={handleChangeRowsPerPage}
//         rowsPerPageOptions={[50,100,150,200,250,300]}
//         dir="ltr"
//         SelectProps={{
//           inputProps: { 'aria-label': 'rows per page' },
//           native: true,
//         }}
//       />)}
//     </>
//   );
// }
// export default SearchableTable




import React from 'react';
import Table from '@material-ui/core/Table';
import { Button, Icon } from "@material-ui/core"
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MatTableHead from './components/MatTableHead';
import TableContainer from '@material-ui/core/TableContainer';
import { Skeleton } from "@material-ui/lab"
import TableSearch from "./components/TableSearch"
import MatTableToolbar from './components/MatTableToolbar';
import NoRecord from "../NoRecord"
import FilterSelect from "./components/FilterSelect"

const SearchableTable = (props) => {
  const handleActionBtnClick = () => {
    alert("Button Clicked!")
  }
  let { title = "Searchable Table",
    rows,
    filteredData = [],
    loading,
    searchLabel = "Search",
    searchPlaceholder = "Search..",
    getSearchResult,
    rowsPerPage = 50,
    page = 0,
    handleChangePage,
    handleChangeRowsPerPage,
    searchType,
    searchValue,
    clearSearch,
    actionTablebtn = false,
    actionBtnIcon = "add",
    actionBtnText = "",
    actionBtnClick = handleActionBtnClick,
    filtermode = false,
    pagination = true,
    height = "370px",
    filterAction = false,
    count
  } = props
  // for search result
  const checkHeight = () => {
    if (pagination) {
      if(filteredData.length !== 0) return ({ height: "auto" })
      else return ({ height: height })
    }
    else  return ({ height: height })
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - (page * rowsPerPage));
  // FilterMode  :false--default , set true if want sorting
  // deleteMode :false-- default , set true enable checkbox and delete all row in table
  return (
    <>
    
      {searchPlaceholder&&title !== "" && (<div className="card__title">
        <h5 className="bold-text">{title}</h5>
      </div>)}
      { searchPlaceholder&&<TableSearch
        searchLabel={searchLabel}
        searchPlaceholder={searchPlaceholder}
        getSearchResult={getSearchResult}
        searchType={searchType}
        searchValue={searchValue}
        clearSearch={clearSearch}
      >
        <div className="flex">
        {actionTablebtn &&
          (<Button onClick={actionBtnClick} color="primary" size="medium" variant="outlined" className="rounded mx-10" >
            <Icon fontSize="default" >{actionBtnIcon}</Icon>{actionBtnText}
          </Button>)}
          {filterAction &&
          (<FilterSelect />)}
        </div>
       
        {filtermode && (<MatTableToolbar />)}
      </TableSearch>}
      <div className="material-table__wrap">
        <TableContainer style={checkHeight()}>
          <Table stickyHeader className="material-table">
            <MatTableHead
              rowCount={filteredData.length}
              rows={rows}
            />
            <TableBody>
              {loading ? (<TableRow
                className="material-table__row"
                role="checkbox"
              >
                {rows.map((Cell, i) => {
                  return <TableCell className="material-table__cell material-table__cell-right"
                    component="th"
                    scope="row"
                    key={i}
                    padding="default">
                    <Skeleton width="100%" />
                  </TableCell>
                })}
              </TableRow>)
                :
                filteredData.length === 0
                &&
                (<NoRecord />)}
              {props.children}
            </TableBody>
          </Table>
        </TableContainer>

      </div>
      {pagination && (<TablePagination
        component="div"
        className="material-table__pagination"
        count={count ? count : filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[50,100,150,200,250,300]}
        dir="ltr"
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
      />)}
    </>
  );
}
export default SearchableTable

