import React, { memo, useState } from 'react';
import { EditorState, convertToRaw,convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';

const ToolbarOptions = {
  options: ['inline', 'blockType', 'textAlign', 'link', 'image', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const TextEditorTwo = memo(({ input, readOnly=false }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (items) => {
    setEditorState(items);
    if (input.onChange) { input.onChange(draftToHtml(convertToRaw(items.getCurrentContent()))); }
  };

  React.useEffect(() => {
    if (input.value) {
      const blockFromHtml = convertFromHTML(input.value)
      const state = ContentState.createFromBlockArray(blockFromHtml.contentBlocks, blockFromHtml.entityMap)
      setEditorState(EditorState.createWithContent(state))
    }
  },[])

  return (
    <div className="text-editor">
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
        spellCheck={true}
        readOnly={readOnly}
      />
    </div>
  );
});

TextEditorTwo.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextEditorTwo;
