import {IS_LINK_CREATED, PROGRAM_LIST_FOR_LINK_MANAGEMENT, LINK_MANAGEMENT_LIST} from "../types"
import api from '../../utils/apiClient';
import moment from "moment";

// export const getAllProgram = (searchText = "",
//     tags = [],
//     ageGroup = "", dayOfWeek = "", pageNo = 0, pageSize = 0) => async (dispatch) => {
//         dispatch({
//             type: GET_PROGRAM,
//             payload: []
//         })
//         dispatch(allProgramLoading(true))
//         let data = {
//             searchText: searchText,
//             tags: tags,
//             ageGroup: ageGroup,
//             dayOfWeek: dayOfWeek,
//             pageNo: pageNo,
//             pageSize: pageSize,
//         }
//         api.program.getAllProgram(data, false)
//             .then(res => {
//                 if (res.data) {
//                     let pData = res.data
//                     dispatch(setAllProgram(pData))
//                 }
//             })
//             .catch(err => {
//                 console.log(err.message)
//                 dispatch(allProgramLoading(false))
//             })
//     }
// export const setAllProgram = (pData) => async dispatch => {
//     dispatch({
//         type: GET_PROGRAM,
//         payload: pData
//     })
// }

// export const sendProgram = (formData) => async (dispatch) => {
//     await api.program.post(formData, true)
//         .then(res => {
//             if (res.data) {
//                 dispatch({
//                     type: SEND_PROGRAM,
//                     payload: res.data
//                 })
//             }
//         })
//         .catch(err => console.log(err))
// }

// export const providerProgram = () => async (dispatch) => {
//     await api.program.getProviderProgram(true).then(res => {

//         if (res.data) {
//             dispatch({
//                 type: GET_PROVIDER_PROGRAM,
//                 payload: res.data
//             })
//         }
//     }).catch(res => {

//     })
// }

// export const getProviderProgramById = (id) => async (dispatch) => {
//     await api.program.getProgramById(id, true).then(res => {
//         if (res.data) {
//             let regulerDates = res.data?.regulerDates.map(c=>moment(c).format());
//             let specialDates = res.data.specialDates.map(c=>moment(c).format());
//             let noSession = res.data.noSession.map(c=>moment(c).format());
//             let obj = {...res.data,regulerDates,specialDates,noSession};
//             dispatch(setProviderProgramById(obj))
//         }
//     }).catch(err => {
//         console.error(err.message)
//     })
// }

// export const setProviderProgramById = (data) => async (dispatch) => {
//     dispatch({
//         type: GET_PROVIDER_PROGRAM_BY_ID,
//         payload: data
//     })
// }


//  for getting program details by id on parent 

// export const getProgramById = (id) => async (dispatch) => {
//     dispatch(detailsLoading(true))
//     await api.program
//         .getProgramById(id, true)
//         .then((res) => {
//             if (res.data) {
//                 dispatch(setProgramById(res.data));
//             }
//         })
//         .catch((err) => {
//             console.error(err.message)
//         });
// };

// export const setProgramById = (data) => async (dispatch) => {
//     dispatch({
//         type: SET_PROGRAM_BY_ID,
//         payload: data,
//     });
// };

// export const getEnrolledProgram = () => async dispatch => {
//     await api.program.enrolledPrograms(true).then(res => {
//         if (res.data) {
//             dispatch({
//                 type: GET_ENROLLED_PROGRAM,
//                 payload: res.data
//             })
//         }
//     })
//         .catch(err => {

//         })
// }
// export const parentProgramDates = () => async dispatch => {
//     await api.program.allProgramDates(true).then(res => {
//         // debugger
//         if (res.data) {
//             dispatch({
//                 type: PARENT_PROGRAM_DATES,
//                 payload: res.data
//             })
//         }
//     })
// }



// export const detailsLoading = (status) => async dispatch => {
//     dispatch({
//         type: PROGRAM_DETAILS_LOADING,
//         payload: status
//     })
// }
// export const allProgramLoading = (status) => async dispatch => {
//     dispatch({
//         type: ALL_PROGRAM_LOADING,
//         payload: status
//     })
// }

// export const programListingByProvider2 = (id) => async dispatch => {
//     let list = {
//         programs: [],
//         experience: []
//     }
//     dispatch({
//         type: PROGRAM_LIST_BY_PROVIDER,
//         payload: list
//     })
//     await api.program.programListing(id, true).then(res => {
//         if (res.data) {
//             list.programs = res.data.item1
//         }
//     }).catch((err) => {
//         console.error(err.message)
//     });
//     await api.experience.experienceListing(id, true).then(res => {
//         if (res.data) {
//             list.experience = res.data.item1
//         }
//     }).catch((err) => {
//         console.error(err.message)
//     });
//     dispatch({
//         type: PROGRAM_LIST_BY_PROVIDER,
//         payload: list
//     })
// }

/////////////////////////////////////////////////////


export const programListingByProvider = () => async dispatch => {
  let programs = []
    await api.linkManagement.programListing(true).then(res => {
        if (res.data) {
            programs = res.data.data
        }
    }).catch((err) => {
        console.error(err.message)
    });
    dispatch({
        type: PROGRAM_LIST_FOR_LINK_MANAGEMENT,
        payload: programs
    })
}

export const ListingOfLink = () => async dispatch => {
    let programLink = []
      await api.linkManagement.LinkManagementList(true).then(res => {
          if (res.data) {
            programLink = res.data.data
          }
      }).catch((err) => {
          console.error(err.message)
      });
      dispatch({
          type: LINK_MANAGEMENT_LIST,
          payload: programLink
      })
  }