import React from 'react';
import ExperienceForm from "./ExperianceForm"
import {
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


function ModalAddExperience(
  {
    isOpen,
    closeModal,
    expSubmit, title
  }) {
  return (
    <>
     
      <Modal toggle={closeModal}  className="theme-light ltr-support login-modal-form"  isOpen={isOpen} centered>
      <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <ModalBody>
        <ExperienceForm
            expSubmit={expSubmit}
            closeModal={ closeModal}
                />
         
        </ModalBody>
      </Modal>
    </>
  );
}


export default ModalAddExperience





{/*  */}


  //               ,
  