import React from "react"
import { Row, Col, Container } from "reactstrap"
import { Button, Typography } from "@material-ui/core"



const TopBar = ({ title, buttonClick, buttonTitle }) => {

    return <Container className=" ml-0" style={{marginTop:"15px"}}>
        <Row>
            <Col>
                <div className="flex">
                    <div className=" mb-15">

                        <h3 className=" uppercase bold-text">{title}</h3>

                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            className="ml-10"
                            onClick={buttonClick}
                        >
                            {buttonTitle}
                        </Button>
                    </div>

                </div>
            </Col>

        </Row>
    </Container>

}

export default TopBar