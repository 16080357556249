import { GET_FEATURED_LIST } from "../types/index"
import api from "utils/apiClient"
import {showFailedNoti} from "./notificationAction"

export const getFeaturedList = () => async dispatch => {
    await api.feature.getFeaturedList().then(res => {
        if (res.data) {
            // debugger
            dispatch({
                type: GET_FEATURED_LIST,
                payload: res.data
            })
        }
    }).catch((err) => {
        dispatch(showFailedNoti(err.message))
    })
}