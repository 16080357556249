import { get } from './base/index';
import { post } from "./base/index"

export default {
    getProviderTrHistory: (headers = true, pageSize = 1000000, pageNo = 1) => {
        return get(`${window.apiUrl}Transaction/transaction-history-provider?Page_size=${pageSize}&Page_no=${pageNo}`, {}, headers)
    },
    getParentTrHistory: (headers = true, pageSize = 10000000, pageNo = 1) => {
        return get(`${window.apiUrl}Transaction/transaction-history-parent?Page_size=${pageSize}&Page_no=${pageNo}`, {}, headers)
    },
    getAdminTrHistory: (headers = true, pageSize = 10000000, pageNo = 1) => {
        return get(`${window.apiUrl}Transaction/transaction-history-admin?Page_size=${pageSize}&Page_no=${pageNo}`, {}, headers)
    },
    updateTransaction: (id,status, headers = true) => {
        return post(`${window.apiUrl}Transaction/update-pending-transaction?transactionId=${id}&paymentStatus=${status}`, {}, headers)
    },
    addTractions: (data) => {
        return post(`${window.apiUrl}Transaction/add-transaction`, data, true)
    },
    cancelOrderList: (headers) => {
        return get(`${window.apiUrl}Transaction/cancel-order-list`, {}, headers)
    },
    // only for parents
    cancelOrder: (data, headers) => {
        return post(`${window.apiUrl}Transaction/cancel-order`, data, headers)
    },
    refundOrderList: (pageSize=10000000, pageNo=1,headers) => {
        return get(`${window.apiUrl}Transaction/refund-order-list?Page_size=${pageSize}&Page_no=${pageNo}`, {}, headers)
    },
    refundOrderListUser: (pageSize=1000, pageNo=1,headers) => {
        return get(`${window.apiUrl}Transaction/refund-list-user?Page_size=${pageSize}&Page_no=${pageNo}`, {}, headers)
    },
    approveRefund: (id,status,amount, headers) => {
        return post(`${window.apiUrl}Transaction/refund?Id=${id}&refundStatus=${status}&amount=${amount}`, {}, headers)
    },
    getTotalPayout: (data, headers) => {
        return get(`${window.apiUrl}Transaction/total-payout?Page_size=${data.pageSize}&Page_no=${data.pageNo}${data.startDate !== "" ? `&startDate=${data.startDate}&endDate=${data.endDate}`: ""}`, {}, headers)
    },
    refundByProvider: (data) => {
        return post(`${window.apiUrl}Transaction/refund-by-provider?programId=${data.programId}&studentId=${data.studentId}`, {}, true) 
    },
    setStripePaymentIntent: (cartId) => {
        return post(`${window.apiUrl}Transaction/payment-intent?cartId=${cartId}`,{},true)
    },
    salesReport: (id, headers=true) => {
        return get(`${window.apiUrl}Transaction/sales-report${id?`?providerId=${id}`:""}`, {}, headers)
    }

}