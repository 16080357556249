import { ANNOUNCEMENT_LIST, ANNOUNCEMENT_DETAILS, ANNOUNCEMENT_BY_USER } from "../types"

const initialState = {
    announcementList: [],
    announcementDetails: {},
    annuncementByUser: []
}

const announcementReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANNOUNCEMENT_LIST:
            return {
                ...state,
                announcementList: action.payload
            };
        case ANNOUNCEMENT_DETAILS:
            return {
                ...state,
                announcementDetails: action.payload
            };
            case ANNOUNCEMENT_BY_USER:
            return {
                ...state,
                annuncementByUser: action.payload
            };
        default:
            return state;
    }
};
export default announcementReducer