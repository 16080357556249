import { ADD_SUBSCRIPTION, GET_SUBSCRIPTION, GET_SUBSCRIPTION_BY_ID, SET_SUBS_TYPE,
SUBSCRIPTION_TRANSACTION } from "../types"

const initialState = {
    addSubscription: {},
    subscriptionList: [],
    getSubscriptionById: {},
    subsDetails: {},
    subsType: 1,
    subTransaction: []

}

const subscriptionReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_SUBSCRIPTION:
            return {
                ...state,
                addSubscription: action.payload
            }
        case GET_SUBSCRIPTION:
            return {
                ...state,
                subscriptionList: action.payload
            }
        case GET_SUBSCRIPTION_BY_ID:
            return {
                ...state,
                getSubscriptionById: action.payload
            }
        case SET_SUBS_TYPE:
            return {
                ...state,
                subsDetails: action.subsDetails,
                subsType: action.subsType
            }
        case SUBSCRIPTION_TRANSACTION:
            return{
                ...state,
                subTransaction: action.payload
            }    

        default:
            return state;
    }
}

export default subscriptionReducer;