import { post, get, deleteRequest } from './base/index';


export default {
  getProviderExperience: (headers) => {
    return get(`${window.apiUrl}Experience/providers-Experience?Page_size=10000&Page_no=1`, {},headers);
  },
  getAllExp: (data) =>{
    
    const body = data;
    return post(`${window.apiUrl}Experience/All-Experience`, body);
  },
    post: (data, headers) => {
      
    const body =  data ;
    return post(`${window.apiUrl}Experience/Manage-Experience`, body, headers);
  },
  deletePost: (id,headers)=>{
    
    return post(`${window.apiUrl}Experience/delete-experience?experienceId=${id}`,{},headers)
  },
  updateExperience:(data,headers)=>{
    const body = {...data}
    return post(`${window.apiUrl}Experience/Manage-Experience`, body, headers);
  },
  manageExperience: (data, headers) => {
    const body = data
    return post(`${window.apiUrl}Experience/manage-Experience`, body, headers);
  },
  manageExperienceAdmin: (data, headers) => {
    const body = data
    return post(`${window.apiUrl}Experience/Manage-Experience-admin`, body, headers);
  },
  
  deleteProviderExp: (data, headers) => {
    return post(`${window.apiUrl}Experience/delete-experience?experienceId=${data}`, {}, headers);
  },
  getProviderById: (data, headers) => {
    const params ={experienceId: parseInt(data)}
    return get(`${window.apiUrl}Experience/experience-details?experienceId=${data}`, {}, headers);
  },
  experienceListing: (id,headers = true) => {
    return get(`${window.apiUrl}Experience/experience-by-providerid?providerId=${id}`, {}, headers)
}
};
