import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR_AUTH,
  REMOVE_USER_DATA,
  SET_RECAPTCHA_KEY
} from '../types';
var empty = require('is-empty')
const initialState = {
  isAuthenticated: false,
  recaptchaToken: "",
  recaptchaLoad:false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        ...action.user,
        isAuthenticated:!empty(action.user)
          
      };
    case AUTHENTICATE_ERROR_AUTH:
      return {
        ...state,
        error: action.error
      };
    case REMOVE_USER_DATA:
      return {
        isAuthenticated:false
      }
    case SET_RECAPTCHA_KEY:
      return {
        recaptchaToken: action.payload,
        recaptchaLoad:true
      }
      
    default:
      return state;
  }
};

export default authReducer;
