// import axios from axios
export const getOidcStorageKey = () => {
  const authToken = localStorage.getItem("authToken");

  if (authToken || authToken !== undefined) {
    return authToken;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {

    return key;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc;
  }
  return null;
};

// grt refresh token 

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken && refreshToken !== undefined) {
    return refreshToken
  }
  return null
}

//  export to csv





