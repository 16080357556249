import { authRoles } from "config/authroles";
import React, { useState } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { connect, useDispatch } from 'react-redux';
// import MicrosoftLogin from "../../dist";
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { loginModal, microsoftModal, providerInfoModal, registerModal, userInfoModal } from "redux/actions/modalAction";
import { showSucessNoti } from "redux/actions/notificationAction";
import ConfirmationModal from "shared/components/ConfirmationModal";
import { authError, setUserData } from '../../../redux/actions/authActions';
import { userService } from "../../../Services/userService";
import {Image} from '../../../shared/img/microsoft.svg'
const MicrosoftLoginBtn = (props) => {
    const history = useHistory()
    const [msalInstance, onMsalInstanceChange] = useState();
    const [microConfirm, setMicroConfirm] = useState(false);
    const [token, setToken] = useState('');

    let dispatch = useDispatch()

    const responseSuccess = (err, data, msal) => {
        if(data){
        let { form } = props
        console.log("console.log", data.accessToken)
        setToken(data.accessToken)

        if (!err && data) {
            onMsalInstanceChange(msal);
        }
        switch (form) {
            case "modal_login":
                return handleSign(data.accessToken)
            case "modal_register":
                setMicroConfirm(true)
            default:
                return null
        }
    }
    }

    const handleSign = (token) => {
        const { roleCode } = props;
        let data = {
            token: token,
            roleCode: roleCode
        }
        userService.microsoftregister(data).then(user => {
            sessionStorage.removeItem("msal.idtoken")
            if (!user.data) {
                dispatch(authError(user.message))
            } else {
                let data = {
                    name: user.data.name,
                    avatar: user.data.image,
                    role: user.data.role,
                }
                dispatch(setUserData(data)).then(() => {
                    dispatch(loginModal(false))
                    // dispatch(authError({}));
                    history.push("/home")
                    dispatch(showSucessNoti(user.message))

                })
            }

        }).catch(err => console.error(err))
    }

    const registerHandler = () => {
        let { roleCode } = props
        var params = {
            token: token,
            roleCode: getRoleCode(roleCode)
        }
        
        userService.microsoftregister(params).then(user => {
            sessionStorage.removeItem("msal.idtoken")
            if (!user.data) {
                dispatch(authError(user.message))
            } else {
                let data = {
                    name: user.data.name,
                    avatar: user.data.image,
                    role: user.data.role,
                }
                props.setuserDataLocal(data)
                dispatch(registerModal(false))
                dispatch(authError(null));
                dispatch(showSucessNoti(user.message))
                if (user.data.role === authRoles.Provider) {
                    dispatch(providerInfoModal(true))
                    setMicroConfirm(false)
                } else if (user.data.role === authRoles.Parents) {
                    dispatch(userInfoModal(true))
                    setMicroConfirm(false)
                }
            }
        })
    }


    const getRoleCode = (role) => {
        let code = 3;
        if (role === 3) {
            return code = 12
        } if (role === 4) {
            return code = 13
        }
    }

    const logoutHandler = () => {
        msalInstance.logout();
    };

    const getTitle = () => {
        let { roleCode } = props
        switch (roleCode) {
            case authRoles.Parents:
                return `Are you sure you want to register as "Parent"?`
            case authRoles.Provider:
                return `Are you sure you want to register as "Provider"?`
            default:
                return ""
        }
    }

    return <>
        {msalInstance ? (
            <button className="loginbtn" onClick={logoutHandler}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="24px" height="24px"><path fill="#ff5722" d="M6 6H22V22H6z" transform="rotate(-180 14 14)"/><path fill="#4caf50" d="M26 6H42V22H26z" transform="rotate(-180 34 14)"/><path fill="#ffc107" d="M26 26H42V42H26z" transform="rotate(-180 34 34)"/><path fill="#03a9f4" d="M6 26H22V42H6z" transform="rotate(-180 14 34)"/></svg>
                Sign in with Microsoft</button>
        ) : <MicrosoftLogin clientId="3c4af231-3222-45dd-a7a2-b264a7e77471" redirectUri={window.location.href+"login"} authCallback={responseSuccess} />
        }
        {microConfirm && (<ConfirmationModal
            color=""
            title={getTitle()}
            message=""
            colored={false}
            header={false}
            isOpen={microConfirm}
            closeModal={() => setMicroConfirm(false)}
            onYesClick={registerHandler}
        />)}
    </>
}
export default withRouter(connect()(MicrosoftLoginBtn));