import axios from "axios"
import { AUTHENTICATE, AUTHENTICATE_ERROR_AUTH, REMOVE_USER_DATA, SET_RECAPTCHA_KEY } from "../types"
import { registerModal } from "./modalAction"
import { userService } from "../../Services/userService"
import { showSucessNoti, showFailedNoti } from "./notificationAction"
export const setUserData = (userData) => async (dispatch) => {
  // debugger
  dispatch({
    type: AUTHENTICATE,
    user: userData
  })
}

export const auth = (userData) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE,
    user: userData
  })
}

export function authError(error = {}) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}

export const registerUser = (userData) => async (dispatch) => {
  const config = {
    method: "post",
    url: window.apiUrl + "Account/register",
    data: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
    }
  }
  await axios(config).then(res => {
    if (res.data) {
      dispatch(registerModal(false))
    } else { }
  }).catch(err => {

  })
}

export function logoutUser() {

  return async (dispatch) => {
    dispatch({
      type: REMOVE_USER_DATA,
    });
    userService.logout().then(() => {

      dispatch(showSucessNoti('Logout Sucessfull'))
    });
  };
}

// Forgot-Password 

export const forgotPassword = (email) => async (dispatch) => {
  let forgotData = {
    email: email,
    redirectUrl: `${window.location.origin}/reset_password?id=`
  }
  const config = {
    method: "post",
    url: window.apiUrl + "Account/forgot-password",
    data: JSON.stringify(forgotData),
    headers: {
      'Content-Type': 'application/json',
    }
  }
  await axios(config).then((res) => {
    // debugger
    if (res.data.data) {
      dispatch(showSucessNoti(res.data.message))
    } else {
      dispatch(showFailedNoti(res.data.message))
    }
  })

}

export const resetPassword = (newPassword = "", token = "") => async (dispatch) => {
  // debugger
  let resetData = {
    newPassword: newPassword,
    token: token
  }
  const config = {
    method: "post",
    url: window.apiUrl + "Account/reset-password",
    data: JSON.stringify(resetData),
    headers: {
      'Content-Type': 'application/json',
    }
  }
  await axios(config).then((res) => {
    if (res.data.data) {
      dispatch(showSucessNoti(res.data.message))
    } else {
      dispatch(showFailedNoti(res.data.message))
    }
  })

}

// handle google recaptcha 
export const handleRecaptchaToken = (token) => async dispatch => {
  // debugger
  dispatch({
    type: SET_RECAPTCHA_KEY,
    payload: token
  })
}



//registration for external user-- used for both registration and login