/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { renderField } from "../../../../../../shared/components/form/RenderField";
import { Button } from "@material-ui/core";
import { validate } from "../../../../../../config/formValidation";
import { connect } from "react-redux";
import ImageUpload from "../../../../../../shared/components/form/ImageUpload";
import { mediaTypes } from "../../../../../../config/mediaTypes";
import { Row, Col } from "reactstrap";

const ProfileUpdate = ({
  handleSubmit,
  initialValues,
  userMedia,
  updateAdmin,
  isEdit,
  toggleEdit
}) => {
  return (
    <form onSubmit={handleSubmit(updateAdmin)}>
      <div className="mb-3">
        <Button
          variant={isEdit ? "contained" : "outlined"}
          onClick={toggleEdit}
          color="primary"
        >
          Edit
        </Button>
      </div>
      <Row>
        <Col>
          <div className="mb-10 ">
            <ImageUpload
              accept="image/*"
              image={userMedia ? userMedia : initialValues.avatar}
              mediatype={mediaTypes.UserAvatar}
              disabled={isEdit ? true : false}
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="fName"
              label="First Name*"
              type="text"
              component={renderField}
              placeholder="First Name"
              startIcon="people"
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="mName"
              label="Middle Name"
              component={renderField}
              placeholder="Middle Name"
              startIcon="people"
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="lName"
              label="Last Name*"
              component={renderField}
              placeholder="Last Name"
              startIcon="people"
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={true}
              name="email"
              label="Email"
              type="email"
              component={renderField}
              placeholder="Email"
              startIcon="email"
            />
          </div>
        </Col>
      </Row>
      <div className="flex flex-end flex-middle my-16">
        <div>
          <Button
            disabled={isEdit ? true : false}
            color="primary"
            variant="contained"
            type="submit"
          >
            Update profile
          </Button>
        </div>
      </div>
    </form>
  );
};

ProfileUpdate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default connect((state) => ({
  errorMsg: state.user.error,
  userMedia: state.media.userMedia,
  initialValues: {
    id: state.profile.adminProfile.avatar ? state.profile.adminProfile.id : 0,
    avatar: state.profile.adminProfile.avatar ? state.profile.adminProfile.avatar : "",
    fName: state.profile.adminProfile.fName
      ? state.profile.adminProfile.fName
      : "",
    mName: state.profile.adminProfile.mName
      ? state.profile.adminProfile.mName
      : "",
    lName: state.profile.adminProfile.lName
      ? state.profile.adminProfile.lName
      : "",
    email: state.profile.adminProfile.email
      ? state.profile.adminProfile.email
      : "",
  },
}))(
  reduxForm({
    form: "updateAdmin",
    validate,
    enableReinitialize: true
  })(ProfileUpdate)
);
