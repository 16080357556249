import React from 'react';
import { connect } from "react-redux"
import {
    Modal, ModalHeader, ModalBody, Table, Badge
} from 'reactstrap';
import moment from "moment"
import { ListItem, ListItemText, Button, Icon, } from "@material-ui/core"
const HistoryInfo = ({
    title,
    isOpen,
    closeModal,
    historyDetail = {},
    handlePdfDownload,
    getStatusColor
}) => (
    <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="lg" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}></ModalHeader>
        <div id="divToPrint">
            <ModalBody className="login-form "   >
                <div className="text-left"  >
                    <h4><strong>{title}</strong></h4>
                    <h5 className="my-10" >
                        Oder Id: <strong>{historyDetail.orderId}</strong>
                    </h5>
                    <h5 className="my-10">
                        Parent Name: <strong>{historyDetail.parentName}</strong>
                    </h5>
                    <h5 className="my-10">
                        Email: <strong>{historyDetail.email}</strong>
                    </h5>
                    <h5 className="my-10">
                        Date of Payment: <strong>{moment(historyDetail.dateOfPayment).format('MMM DD, YYYY')}</strong>
                    </h5>
                    <h5 className="my-10">
                        Method: <strong>{historyDetail.paymentMode}</strong>
                    </h5>
                    <Table responsive hover>
                        <thead className="text-nowrap">
                            <tr>
                                <th className="text-nowrap">Program Name</th>
                                <th className="text-nowrap">Program Date(s)</th>
                                <th>Students</th>
                                <th>Amount(CA$)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyDetail.programOrder && historyDetail.programOrder.map((p, i) => {
                                return (<tr>
                                    <td>{p.programName}</td>
                                    <td> <span>
                                    {p.programDates != null && p.programDates.map((item, index) => {
                                            return (<ListItem dense >
                                                <ListItemText
                                                    primary={`${new Date(item).toLocaleDateString()} `} />
                                            </ListItem>)
                                        })}
                                    </span></td>
                                    <td> <span>
                                        {p.students.map((s, index) => {
                                            return (<ListItem dense className="text-nowrap">
                                                <ListItemText
                                                    primary={`${index + 1}. ${s.strudentname} `} />
                                            </ListItem>)
                                        })}
                                    </span></td>
                                    <td>{`CA$${p.programAmount} x ${p.students.length}`}</td>
                                    <td>
                                        <Badge color={getStatusColor(historyDetail.paymentStatusEnum)}>
                                            {historyDetail.paymentStatusString}
                                        </Badge>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </div>
                <hr />
                <div className="flex flex-space-between">
                    <div>
                        {historyDetail.promoCode && (<h6 className="text-left">Promo Applied :<strong className="uppercase">{historyDetail.promoCode}</strong></h6>)}
                        <h6 className="text-left">Sub-Total : <strong>CA$ {parseFloat(historyDetail.subTotal).toFixed(2)}</strong> </h6>
                        <h6 className="text-left">Discount : <strong className="uppercase">CA$ {parseFloat(historyDetail.amount - historyDetail.subTotal).toFixed(2)}</strong></h6>
                        <h4 className="text-left">Total : <strong>CA$ {parseFloat(historyDetail.amount).toFixed(2)}</strong> </h4>
                    </div>
                    <div className="mt-auto">
                        <Button variant="contained" id="buton" size="small" color="primary" onClick={() => handlePdfDownload()} ><Icon className="mr-10">download</Icon>Download</Button>
                    </div>
                </div>
            </ModalBody>
        </div>
    </Modal>
);
export default connect(state => ({
    errorMessage: state.user.error
}))(HistoryInfo);
