import React from 'react';

import {
  Modal, ModalHeader, ModalBody, Alert
} from 'reactstrap';
import AddNewStudent from "../../../../shared/components/AddNewStudent/AddNewStudent"

const ModalAddNewStForm = ({
  title,
  isOpen, 
  closeModal,
  submitFormAddNewSt
}) => {
  
return (
  <Modal className="theme-light ltr-support login-modal-form"  toggle={closeModal}  isOpen={isOpen} centered>
     <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
    <ModalBody className="login-form">
        <AddNewStudent
              closeModal={closeModal}
              submitFormAddNewSt={submitFormAddNewSt}
          />
    </ModalBody>
  </Modal>
); 

} 



export default ModalAddNewStForm;
