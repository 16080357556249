import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import TopBar from "../../../shared/components/TopBar";
import { Col } from "reactstrap";
import api from "../../../utils/apiClient";
import { reset } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllProgram,
  getProviderProgramById,
  setProviderProgramById,
  providerProgram,
} from "../../../redux/actions/programActions";
import CardNew from "../../../shared/components/CardComponent/CardNew";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import NoRecord from "../../../shared/components/NoRecord";
import SkeletonCard from "../../../shared/components/CardComponent/SkeletonCard";
import {
  showSucessNoti,
  showFailedNoti,
} from "../../../redux/actions/notificationAction";
import {
  setProgramPdf,
  setMediaProgram,
} from "../../../redux/actions/mediaAction";
import ModalAddProgram from "./ModalAddProgram";
import { Container } from "@material-ui/core";
import moment from "moment";

const AllProgram = () => {
  const [programModal, setProgramModal] = React.useState(false);

  const dispatch = useDispatch();
  let [pageNumber, setPageNumber] = useState(1);
  

  const [deleteProgram, setdeleteProgram] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [programCurrent, setProgramCurrent] = React.useState({});

  const { programMedia, programPdf } = useSelector((state) => state.media);

  const handleProgram = () => {
    setProgramModal(true);
  };
  const { loading, allProgram } = useSelector((state) => state.program);
  useEffect(() => {
    (async () => {
      dispatch(getAllProgram({ pageNo: pageNumber, pageSize: 10 }));
    })();
  }, [pageNumber]);
  useEffect(() => {
    if (!allProgram) {
      return;
    }
  }, [loading]);

  const handleClose = () => {
    dispatch(reset("AdminProgramForm"));
    dispatch(setProviderProgramById({}));
    dispatch(setProgramPdf(""));
    dispatch(setMediaProgram(""));
    setProgramModal(false);
    setdeleteProgram(false);
  };

  // const getUTCDate = (date = []) => {
  //   if (date == "") {
  //       return ""
  //   } else {
  //       return date.map((d) => {
  //           return new Date(d).toISOString()
  //       })
  //   }
  // }

  // const getUTCDate = (date = []) => {
  //   if (date == "") {
  //     return "";
  //   } else {
  //     return date.map((d) => {
  //       let dt = new Date(d).toISOString();
  //       return dt.substring(0, 19);
  //     });
  //   }
  // };




  const getUTCDate = (date = []) => {
    if (date == "") {
      return ""
    } else {
      return date.map((d) => {
        return moment(d).tz('Canada/Central').format('YYYY-MM-DDTHH:mm:ss')
      })


    }

  }

  const programSubmit = async (values) => {
    let programData = {
      id: values.id,
      // organisationId: values.organisationId.organisationId,
      organisationId: values.organisationId,
      organisationName: values.organisationName.organisationName,
      programName: values.programName,
      cost: parseInt(values.cost),
      sessionsForCost: parseInt(values.sessionsForCost),
      locations: values.locations,
      regulerDates:
        values.regulerDates.filter((n) => n) == 0
          ? null
          : getUTCDate(values.regulerDates),
      noSession:
        values.noSession.filter((n) => n) == 0
          ? null
          : getUTCDate(values.noSession),
      specialDates:
        values.specialDates.filter((n) => n) == 0
          ? null
          : getUTCDate(values.specialDates),
      capacity: parseInt(values.capacity),
      activity: values.activity,
      ageGroup: values.ageGroup,
      description: values.description,
      programPhoto: programMedia ? programMedia : values.programPhoto,
      flyer: programPdf ? programPdf : values.flyer,
      registrationFlag: values.registrationFlag,
      externalLink: values.externalLink,
      programLocation: values.programLocation,
      categoryTags: values.categoryTags ? values.categoryTags : [],
      regularEndTime: values.regularEndTime
        ? moment(values.regularEndTime).format("HH:mm")
        : null,
      specialEndTime: values.specialEndTime
        ? moment(values.specialEndTime).format("HH:mm")
        : null,
      regularStartTime: values.regularStartTime
        ? moment(values.regularStartTime).format("HH:mm")
        : null,
      specialStartTime: values.specialStartTime
        ? moment(values.specialStartTime).format("HH:mm")
        : null,
      placeName: values.placeName,
      streetAddress: values.streetAddress,
      room: values.room,
      minimumAge: Number(values.minimumAge),
      maximumAge: Number(values.maximumAge),
      providerId: values.organisationName?.id,
    };
    console.log("programdata "+ programData);
    await api.program
      .addProgramAdmin(programData, true)
      .then((res) => {
        dispatch(reset("AdminProgramForm"));
        if (res.data) {
          dispatch(showSucessNoti(res.data.message));
          dispatch(getAllProgram());
          handleClose();
        }
      })
      .catch((err) => {
        dispatch(showFailedNoti(err.message));
        handleClose();
      });
  };

  const handleProgramDelete = (data) => {
    setProgramCurrent(data);
    setdeleteProgram(true);
  };

  const handleProgramDeleteConfirm = async () => {
    let id = programCurrent;
    await api.program
      .deleteProgram(id, true)
      .then((res) => {
        setdeleteProgram(false);
        dispatch(providerProgram());
        dispatch(getAllProgram());
      })
      .catch((err) => { });
  };

  const handleProgramEdit = (data) => {
    dispatch(getProviderProgramById(data)).then(() => {
      setIsEdit(true);
      setProgramModal(true);
    });
  };

  return (
    <>
      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
        <TopBar
          title="Programs"
          buttonTitle="Add"
          buttonClick={handleProgram}
        ></TopBar>
      </Col>
      {/* only if loading is true  */}

      {!loading && allProgram.length === 0 && (
        <Col>
          <NoRecord />
        </Col>
      )}
      {loading && <SkeletonCard />}
      {allProgram.map((item, index) => {
        
        return (
          <div className="flex flex-middle flex-start" key={index}>
            <CardNew
              name={item.programName}
              image={item.programPhoto}
              description={item.description}
              id={item.id}
              newTag={item.new}
              regulerDates={item.regulerDates}
              specialDates={item.specialDates}
              availability={item.availability}
              locations={item.locations}
              cost={item.cost}
              activities={item.activity}
              key={index}
              isManage={false}
              routePath={`/detail/${item.programName}/${item.id}`}
              item={item}
              providerImage={item.providerImage}
              organizationName={item.organizationName}
              handleDelete={(id) => handleProgramDelete(id)}
              handleEdit={(id) => handleProgramEdit(id)}
              sessionsCount={
                item?.specialDates?.length == undefined
                  ? item?.regulerDates?.length
                  : item?.specialDates?.length + item?.regulerDates?.length
              }

            // sessionsCount={item?.specialDates?.length + item?.regulerDates?.length}
            />
          </div>
        );
      })}
      {programModal && (
        <ModalAddProgram
          title="Create Program"
          isOpen={programModal}
          closeModal={handleClose}
          programSubmit={programSubmit}
        // programImage={programImage}
        />
      )}
      <ConfirmationModal
        color="danger"
        title="Delete Program"
        message="Are you sure you want to delete"
        colored=""
        header=""
        isOpen={deleteProgram}
        closeModal={handleClose}
        onYesClick={handleProgramDeleteConfirm}
      />
      <div className="topbar_login">
        {pageNumber > 1 ? (
          <button
            className="topbar_login_btn"
            onClick={() => {
              pageNumber--;
              console.log("pageNumber ", pageNumber);

              setPageNumber(pageNumber);
            }}
          >
            Previous
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="topbar_login_btn"
          onClick={() => {
            pageNumber++;
            console.log("pageNumber ", pageNumber);

            setPageNumber(pageNumber);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default withRouter(AllProgram);
