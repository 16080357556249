/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { Container  } from "reactstrap";
import Profile from "../../Account/Profile/components/ProviderProfile";

const ProviderHome = () => {
  return (
    <Container>
      <Profile />
    </Container>
  );
};

export default ProviderHome;
