import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import HistoryTable from "../Components/HistoryTable";
import {
  getTrHistoryAdmin,
  refundList,
  approveRefund,
} from "../../../redux/actions/transactionActions";
import { getMessageAdmin } from "../../../redux/actions/messageActions";
import MessagesTable from "./components/messages/MessagesTable";
import Messages from "./components/messages/Messages";
import Cancellation from "../Components/Cancellation";
import ConfirmationModal from "../../../shared/components/ConfirmationModal";
import {
  showSucessNoti,
  showFailedNoti,
} from "../../../redux/actions/notificationAction";
import api from "../../../utils/apiClient";
import UpdateStatus from "../Components/UpdateStatus"
import { paymentStatusOption } from "config/paymentCode"
const columns = [
  {
    id: 1, label: 'Order Id',
  },

  {
    id: 3, label: 'Program Name',
  },
  {
    id: 4, label: 'Payment Source',
  },
  {
    id: 5, label: 'Date of Request',
  },
  {
    id: 6, label: 'Student Name',
  },
  {
    id: 2, label: ' Parent Email',
  },
  {
    id: 7, label: 'Payment Status',
  },
  {
    id: 8, label: 'Amount (CA$)',
  },
  {
    id: 9, label: 'Refund Status',
  },
  {
    id: 10, label: 'Action',
  }
]
export default function AdminActivities() {
  const [refundModal, setRefundModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateType, setUpdateType] = useState("Payment_Status")
  const [currentId, setCurrentId] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [statusValue, setStatusValue] = React.useState(paymentStatusOption[0]);
  const [refundAmount, setrefundAmount] = React.useState(0);
  const [paidAmount, setpaidAmount] = React.useState(0);
  const adminHistory = useSelector((state) => state.transaction.TrHistoryAdmin);
  const refund = useSelector((state) => state.transaction.refundList);
  const { adminMessage } = useSelector((state) => state.message);

  const { openAdmin } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refundList());
    dispatch(getTrHistoryAdmin());

    dispatch(getMessageAdmin());
  }, []);

  const handleClose = () => {
    setRefundModal(false);
    setUpdateModal(false);
  };
  const handleApproveModal = (transaction) => {
    setUpdateType("Refund_Status")
    let data = {
      id: transaction.paymentStatusEnum,
      name: transaction.paymentStatusString
    }
    setRefundModal(true)
    setUpdateModal(true);
    setCurrentId(transaction.id);
    setpaidAmount(transaction.amount);
  };

  const updateStatusOfTransaction = async () => {

    setUpdateModal(false);
    if (updateType === "Payment_Status") {
      await api.transaction
        .updateTransaction(currentId, statusValue.id)

        .then((res) => {
          dispatch(showSucessNoti(res.data.message));
          dispatch(getTrHistoryAdmin()).then(() => setLoading(false));
        })
        .catch((err) => dispatch(showFailedNoti(err)));

    } else if (updateType === "Refund_Status") {
      dispatch(approveRefund(currentId, statusValue.id, refundAmount)).then(() => {
        setUpdateModal(false);
        dispatch(refundList()).then(() => setLoading(false));
      });

    }

  };
  const handleUpdate = (transaction) => {
    let data = {
      id: transaction.paymentStatusEnum,
      name: transaction.paymentStatusString
    }
    setRefundModal(false)
    setUpdateModal(true);
    setCurrentId(transaction.id);
    setStatusValue(data);

  };
  const handleNewStatusValue = (value) => {
    setStatusValue(value)
  }

  const handleInputChange = (value) => {
    if (value)
      setrefundAmount(parseInt(value));
  }

  return (
    <Container>
      {openAdmin ? (
        <Messages />
      ) : (
        <>

          <MessagesTable adminMessage={adminMessage} />
          <Cancellation
            refund={refund}
            approve={true}
            columns={columns}
            loading={loading}
            handleApproveModal={(value) => handleApproveModal(value)}
          />
          <HistoryTable
            loading={loading}
            history={adminHistory}
            updateStatus={true}
            handleUpdate={(value) => handleUpdate(value)}
          />

        </>
      )}

      <UpdateStatus
        title="Update Status"
        isOpen={updateModal}
        refundUpdate={refundModal}
        closeModal={handleClose}
        statusValue={statusValue}
        handleInputChange={handleInputChange}
        refundAmount={refundAmount}
        paidAmount={paidAmount}
        handleNewStatusValue={(value) => handleNewStatusValue(value)}
        updateTransaction={updateStatusOfTransaction}
      />
    </Container>
  );
}
