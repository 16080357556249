// AUTHENTICATION TYPES
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';
export const REMOVE_USER_DATA = "REMOVE_USER_DATA"
export const SET_RECAPTCHA_KEY = "SET_RECAPTCHA_KEY"
// LAYOUT TYPES
export const CHANGE_BORDER_RADIUS = 'CHANGE_BORDER_RADIUS';
export const TOGGLE_BOX_SHADOW = 'TOGGLE_BOX_SHADOW';
export const TOGGLE_TOP_NAVIGATION = 'TOGGLE_TOP_NAVIGATION';

// MODAL TYPES
export const LOGIN_MODAL = "LOGIN_MODAL"
// export const EMAIL_MODAL = "EMAIL_MODAL"
export const FACEBOOOK_MODAL = "FACEBOOK_MODAL"
export const MICROSOFT_MODAL = "MICROSOFT_MODAL"
export const REGISTER_MODAL = "REGISTER_MODAL"
export const FORGETPASSWORD_MODAL = "FORGETPASSWORD_MODAL"
export const USER_INFO_MODAL = "USER_INFO_MODAL"
export const PROVIDER_INFO_MODAL = "PROVIDER_INFO_MODAL"
export const REGISTER_STUDENT_MODAL = "REGISTER_STUDENT_MODAL"
export const ADD_NEW_STUDENT_MODAL = "ADD_NEW_STUDENT_MODAL"
export const ADD_NEW_STUDENT_MODAL_FORM = "ADD_NEW_STUDENT_MODAL_FORM"
export const EXPLORE_MODAL = "EXPLORE_MODAL"
export const ADD_EXPERIANCE_MODAL = "ADD_EXPERIANCE_MODAL"
export const VIEW_PROFILE = "VIEW_PROFILE"
export const REGISTER_PROGRAM = "REGISTER_PROGRAM"
export const PAYMENT_DETAILS = "PAYMENT_DETAILS"
export const PROMO_CODE = "PROMO_CODE"
export const ANNOUNCEMENT = "ANNOUNCEMENT"
export const REVIEW_MODAL = "REVIEW_MODAL"
export const CONTACT_INFO_PROVIDER_MODAL = "CONTACT_INFO_PROVIDER_MODAL"
export const ADD_RECOMMANDATION = "ADD_RECOMMANDATION"
export const MESSAGE_MODAL = "MESSAGE_MODAL"
export const CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL"
export const APPLY_PROMO_MODAL = "APPLY_PROMO_CODE_MODAL"
export const ADD_USER_MODAL = "ADD_USER_MODAL"
export const RESLOVE_MODAL = "RESLOVE_MODAL"
export const SUBSCRIPTION_MODAL = "SUBSCRIPTION_MODAL"
export const PAYMENT_SUBS = "PAYMENT_SUBS"
export const RESOURCES_MODAL = "RESOURCES_MODAL"
export const ADD_USER_ADMIN_MODAL = "ADD_USER_ADMIN_MODAL"

// PROGRAM TYPES
export const GET_PROGRAM = "GET_PROGRAM"
export const GET_PROGRAM_BY_PROVIDER = "GET_PROGRAM_BY_PROVIDER"
export const ALL_EXPERIANCE = "ALL_EXPERIANCE"
export const SEND_EXPERIENCE = "SEND_EXPERIENCE"
export const GET_PROVIDER_PROGRAM = "GET_PROVIDER_PROGRAM"
export const SEARCH_PROVIDER_PROGRAM = "SEARCH_PROVIDER_PROGRAM"
export const GET_PROVIDER_PROGRAM_BY_ID = "GET_PROVIDER_PROGRAM_BY_ID"
export const SET_PROGRAM_BY_ID = "SET_PROGRAM_BY_ID"
export const GET_ENROLLED_PROGRAM = "GET_ENROLLED_PROGRAM"
export const PARENT_PROGRAM_DATES = "PARENT_PROGRAM_DATES"
export const PROGRAM_DETAILS_LOADING = "PROGRAM_DETAILS_LOADING"
export const ALL_PROGRAM_LOADING = "ALL_PROGRAM_LOADING"
export const REGISTERED_PROGRAM = "REGISTERED_PROGRAM"
export const PROGRAM_LIST_BY_PROVIDER = "PROGRAM_LIST_BY_PROVIDER"
// ALL STUDENTS
export const GET_ALL_STUDENT = "GET_ALL_STUDENT"
export const SEND_PROGRAM = "SEND_PROGRAM"
export const DELETE_MODAL = "DELETE_MODAL"
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE"
export const SET_SNACKBAR = "SET_SNACKBAR"
export const SELECT_STUDENT = "SELECT_STUDENT"
export const GET_STUDENT_ENROLLED_LIST = "GET_STUDENT_ENROLLED_LIST"
export const GET_STUDENT_LIST = "GET_STUDENT_LIST"
export const STUDENT_LIST_BY_PROGRAM_ID = "STUDENT_LIST_BY_PROGRAM_ID"
export const STUDENT_PROFILE_BY_ID = "STUDENT_PROFILE_BY_ID"
// EXPERIANCE
export const GET_PROVIDER_EXPERIENCE = "GET_PROVIDER_EXPERIENCE"
export const GET_PROVIDER_EXP_BY_ID = "GET_PROVIDER_EXP_BY_ID"
export const ALL_EXPERIANCE_LOADING = "ALL_EXPERIANCE_LOADING"
//Tags
export const GET_TAGS_BY_TAGTYPE = "GET_TAGS_BY_TAGTYPE"
export const LOCATION_TAG = "LOCATION_TAG"
export const ACTIVITY_TAG = "ACTIVITY_TAG"
export const CATEGORY_TAG = "CATEGORY_TAG"
export const MINIMUMAGE_TAG = "MINIMUMAGE_TAG"
export const CITY_TAG = "CITY_TAG"
export const TAG_DETAILS = "TAG_DETAILS"
export const SCHOOL_TAG = "SCHOOL_TAG"
export const DAYS_TAG = "DAYS_TAG"
export const COST_TAG = "COST_TAG"
export const SELECTED_TAG = "SELECTED_TAG"
export const MAIN_SELECTED_TAG = "MAIN_SELECTED_TAG" //All, Program, experience or Provider

//Get User List for Admin
export const GET_USER_LIST = "GET_USER_LIST"
export const GET_USER_COUNT = "GET_USER_COUNT"
export const GET_ORAGANISATIONS = "GET_ORAGANISATIONS"

// MEDIA TYPES
export const EXPERIANCE_COMPLETED = "EXPERIANCE_COMPLETED"
export const PROVIDER_COMPLETED = "PROVIDER_COMPLETED"
export const USER_COMPLETED = "USER_COMPLETED"
export const PROGRAM_COMPLETED = "PROGRAM_COMPLETED"
export const EXPERIANCE_MEDIA = "EXPERIANCE_MEDIA"
export const PROVIDER_MEDIA = "PROVIDER_MEDIA"
export const USER_MEDIA = "USER_MEDIA"
export const PROGRAM_MEDIA = "PROGRAM_MEDIA"
export const PARENT_COMPLETED = "PARENT_COMPLETED"
export const PARENT_MEDIA = "PARENT_MEDIA"
export const EXPERIANCE_COMPLETED_PDF = "EXPERIANCE_COMPLETED_PDF"
export const EXPERIANCE_MEDIA_PDF = "EXPERIANCE_MEDIA_PDF"
export const PROGRAM_COMPLETED_PDF = "PROGRAM_COMPLETED_PDF"
export const PROGRAM_MEDIA_PDF = "PROGRAM_MEDIA_PDF"

//  Profile
export const GET_PROVIDER_PROFILE = "GET_PROVIDER_PROFILE"
export const PROVIDER_PROFILE_LOADING = "PROVIDER_PROFILE_LOADING"
export const GET_PROVIDER_PROFILE_ID = "GET_PROVIDER_PROFILE_ID"
export const UPDATE_PROVIDER_PROFILE = "UPDATE_PROVIDER_PROFILE"
export const PARENT_PROFILE = "PARENT_PROFILE"
export const STUDENT_PROFILE = "STUDENT_PROFILE"
export const UPDATE_PARENT = "UPDATE_PARENT"
export const USER_PROFILE_TOTAL_COUNT = " USER_PROFILE_TOTAL_COUNT"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const GET_FAVORITE = "GET_FAVORITE"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const ADD_USER_SUPERADMIN = "ADD_USER_SUPERADMIN"
export const ADMIN_PROFILE = "ADMIN_PROFILE"
export const ACTIVATE_USER = "ACTIVATE_USER"
export const PROVIDER_LIST = "PROVIDER_LIST"
//Transactions
export const GET_TRANSACTION_HISTORY_PROVIDER = "GET_TRANSACTION_HISTORY_PROVIDER"
export const GET_TRANSACTION_HISTORY_PARENT = "GET_TRANSACTION_HISTORY_PARENT"
export const GET_TRANSACTION_HISTORY_ADMIN = "GET_TRANSACTION_HISTORY_ADMIN"
export const TOTAL_COUNT = "TOTAL_COUNT"
export const CANCEL_ORDER_LIST = "CANCEL_ORDER_LIST"
export const CANCEL_ORDER = "CANCEL_ORDER"
export const REFUND_ORDER_LIST = "REFUND_ORDER_LIST"
export const APPROVE_REFUND = "APPROVE_REFUND"
export const PAYMENT_SATRT = "PAYMENT_SATRT"
export const TOATAL_PAYOUTS = "TOATAL_PAYOUTS"
export const SALES_REPORT = "SALES_REPORT"
export const SALES_REPORT_PROVIDER = "SALES_REPORT_PROVIDER"
//Enrollment 
export const GET_ENROLLED_PROGRAM_STUDENT = "GET_ENROLLED_PROGRAM_STUDENT"

//Promo Code
export const MANAGE_PROMOCODE = "MANAGE_PROMOCODE"

//Reviw
export const POST_REVIEW = "POST_REVIEW"
export const GET_REVIEW = "GET_REVIEW"
export const RATING = "RATING"
export const P_ID = "P_ID"
export const GET_PROGRAM_REVIEW = "GET_PROGRAM_REVIEW"

//NOTIFICATIONS
export const SUCCESS_NOTI = "SUCCESS_NOTIFICATION"
export const FAILED_NOTI = "FAILED_NOTIFICATION"
export const WARNING_NOTI = "WARNING_NOTIFICATION"
export const CLEAR_NOTI = "CLEAR_NOTIFICATION"
export const NOTI_COUNT = "NOTI_COUNT"

export const ALL_NOTIFICATION = "ALL_NOTIFICATION"
export const NOTI_LOADING = "NOTI_LOADING"


// CALENDER                                                   
export const PROVIDER_CALENDER_EVENTS = "PROVIDER_CALENDER"
export const PARENT_CALENDER_EVENTS = "PARENT_CALENDER"

// CART 
export const CART_ODER = "CART_ODER"
export const TOTAL_COST = "TOTAL_CART_COST"
export const DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT"
export const GRAND_TOTAL = "GRAND_TOTAL"
export const CART_LOADING = "CART_LOADING"

//Recommendation
export const ALL_RECOMMENDATION = "All_RECOMMENDATION"
//Message
export const SEND_MESSAGE = "SEND_MESSAGE"
export const GET_MESSAGE = "GET_MESSAGE"
export const Resolve_MESSAGE = "Resolve_MESSAGE"
export const OPEN_MESSAGE = "OPEN_MESSAGE"
export const CLOSE_MESSAGE = "CLOSE_MESSAGE"
export const GET_MESSAGE_ADMIN = "GET_MESSAGE_ADMIN"
export const OPEN_MESSAGE_ADMIN = "OPEN_MESSAGE_ADMIN"
export const CLOSE_MESSAGE_ADMIN = "CLOSE_MESSAGE_ADMIN"
export const PARENT_MESSAGE = "PARENT_MESSAGE"
// PROMO TYPES

export const APPLY_PROMO = "APPLY_PROMO_CODE"
export const PROMO_CODE_LIST = "PROMO_CODE_LIST"
export const PROMO_CODE_DETAILS = "PROMO_CODE_DETAILS"
// FAV TYPES
export const SET_FAV_PROV_ID = "SET_FAV_PROVIDER_BY_ID"
export const GET_PARENT_FAV = "GET_PARENT_FAV"
// ANNOUNCEMENT TYPES
export const ANNOUNCEMENT_LIST = "ANNOUNCEMENT_LIST"
export const ANNOUNCEMENT_DETAILS = "ANNOUNCEMENT_DETAILS"
export const ANNOUNCEMENT_BY_USER = "ANNOUNCEMENT_BY_USER"
//CONTACT US
export const CONTACT_US = "CONTACT_US"
export const CONTACT_US_LOADING = "CONTACT_US_LOADING"
//SUBSCRIPTION
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const SUBSCRIPTION_GETPAYPALPLANS = "SUBSCRIPTION_GETPAYPALPLANS"
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const GET_SUBSCRIPTION_BY_ID = "GET_SUBSCRIPTION_BY_ID"
export const SUBSCRIPTION_TRANSACTION = "SUBSCRIPTION_TRANSACTION"

export const SET_SUBS_TYPE = "SET_SUBS_TYPE"

// Resources
export const GET_RESOURCES = "GET_RESOURCES"
export const POST_RESOURCES = "POST_RESOURCES"
export const DELETE_RESOURCES = "DELETE_RESOURCES"
export const RESOURCES_BY_ID = "RESOURCES_BY_ID"
export const RESOURCES_DETAILS = "RESOURCES_DETAILS"

//  faeture 
export const GET_FEATURED_LIST = "GET_FEATURED_LIST"

// universal search 

export const SET_UNIV_LIST_PROGRAM = "SET_UNIVERSAL_LIST_PROGRAM"
export const SET_UNIV_LIST_EXPERIANCE = "SET_UNIVERSAL_LIST_EXPERIANCE"
export const SET_UNIV_LIST_PROVIDER = "SET_UNIVERSAL_LIST_PROVIDER"

export const SET_UNI_LOADING = "SET_UNI_LOADING"
export const SET_USER_ROLE_CODE = "SET_USER_ROLE_CODE"
export const SET_GLOBAL_SEARCH_TEXT = "SET_GLOBAL_SEARCH_TEXT "

// link management
export const PROGRAM_LIST_FOR_LINK_MANAGEMENT = "PROGRAM_LIST_FOR_LINK_MANAGEMENT "
export const LINK_MANAGEMENT_LIST = "LINK_MANAGEMENT_LIST"
export const USE_EXISTING_ADDRESS = "USE_EXISTING_ADDRESS"

