import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux"
import {addNewStModalForm, loginModal,addNewStModal,providerInfoModal} from "../../../redux/actions/modalAction"
const TopbarLogin = ({ changeIsOpenModalLogin }) => {
  const dispatch = useDispatch()
  
    return (
        <div className="topbar_login">
            <button className="topbar_login_btn" onClick={()=>dispatch(loginModal(true))}>
                Login    
            </button> 
      </div>
    );
}
TopbarLogin.prototype = {
  changeIsOpenModalLogin:PropTypes.func.isRequired,
}
export default TopbarLogin;
