import React from "react";
import { makeStyles, Typography, Icon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mainBox: {
    position: "absolute",
    width: "100%",
    marginLeft: "-30px",
    opacity: "0.7",

  },
  container: {
    minHeight: "270px"
  }

}));

const NoRecord = ({icon="receipt", message=" No record(s) found."}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={"mt-48 " + classes.mainBox}>
        <Typography variant="body2" align="center" className="text-muted p-4" component="h2">
          <Icon className="text-muted" style={{ fontSize: "5.25rem", opacity: "0.5" }}>{ icon}</Icon>
        </Typography>
        <Typography gutterBottom variant="h6" align="center" className="text-muted p-4" component="h2">
          {message}
         </Typography>
      </div>
    </div>
  );
};

export default NoRecord;
