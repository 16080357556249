import React from "react"
import { Helmet } from "react-helmet";
import {useSelector} from "react-redux"
const HelmetComponet = ({ seoData = {}, pageName="" }) => {
    const { notiCount } = useSelector(state => state.notification)
    return <Helmet>
        <meta charSet={seoData.char} />
        {pageName && pageName !== undefined?
            <title>{pageName && pageName !== undefined? pageName : ''} - DWUL</title>
            :
            (<title>{`${seoData.title} ${notiCount > 0 ? `(${JSON.stringify(notiCount)})` : ""}`} - DWUL</title>)}
        {seoData.meta && seoData.meta.map((m, key) => <meta key={key} name={m.name} content={m.content} />)
        }
  </Helmet>
}

export default HelmetComponet