import React from 'react';
import { Link, Router } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Card, Icon, Divider, Chip, Avatar } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Skeleton } from "@material-ui/lab"
import { truncateString, ArrayToString, dateFormat } from "../../../shared/helpers"
const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
const useStyles = makeStyles({
    root: {
        width: '21rem',
        maxWidth: "21rem",
        height: '26rem'
    },
    content: {
        maxHeight: '12rem',
        height: '14rem',
    },
    media: {
        height: 240,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
});

function ProfileCard({
    type = "provider",
    item
}) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const renderChip = () => {
        let data = " "
        data = item.activity && item.activity.map((tag, i) => {
            if (i < 3) {
                return (<Chip className="mx-4" variant="outlined" key={i} size="small" label={tag.name} />)
            }
        })
        return data
    }
    const renderActvities = () => {
        if (item.activity && item.activity.length < 3 && item.activity.length > 0) {
            return renderChip()
        } else if (item.activity && item.activity.length > 3) {
            return <>
                {renderChip()}
                <Chip className="mx-4" variant="outlined" size="small" label={`+ ${item.activity.length - 3} more`} />
            </>
        } else {
            return renderChip()
        }
    }

    const routePath = {

        pathname: `page/detail/${item.userId}`,
        search: `?type=${type}`,
        hash: `${item.organisationName}`,
    }

    return (

        <Link to={routePath}>
            <Card className={classes.root + " mx-10 my-20 card-shadow "} >
                <CardActionArea className="main-program">
                    <CardMedia
                        className={classes.media}
                        image={item.logoUrl ? item.logoUrl : `${process.env.PUBLIC_URL}/img/details.jpg`}
                        title={item.organisationName}
                    />
                </CardActionArea>
                <CardContent className={classes.content + " pt-0 pb-0"}>
                    <h4 className="flex text-black my-10 flex flex-space-between">
                        <span className=" bold-text">{item.organisationName}</span>
                    </h4>
                    <div className="flex flex-middle flex-space-between text-muted my-4">
                        <div>
                            {item.locations && item.locations.length > 0 && (<div className="flex ">
                                <Icon fontSize="small">language</Icon>
                                <span className="mx-4">
                                    {
                                        ArrayToString(item.locations).length > 2 ?
                                            `${ArrayToString(item.locations)[0]}, ${ArrayToString(item.locations)[1]}, + ${ArrayToString(item.locations).length - 2}`
                                            : ArrayToString(item.locations).join(", ")
                                    }
                                </span>
                            </div>)}
                            {item.ageGroup && item.ageGroup.length > 0 && (<div className="flex flex-middle  ">
                                <Icon fontSize="small">group</Icon>
                                <span className="mx-4">
                                    {item.ageGroup && (ArrayToString(item.ageGroup).length > 2
                                        ? `${ArrayToString(item.ageGroup)[0]}, ${ArrayToString(item.ageGroup)[1]}, + ${ArrayToString(item.ageGroup).length - 2}` :
                                        `${ArrayToString(item.ageGroup).join(", ")}`)
                                    }
                                </span>
                            </div>)}
                            {item.city && item.city != "" && (<div className="flex flex-middle ">
                                <Icon fontSize="small">room</Icon>
                                <span className="mx-4">
                                    {item.city}
                                </span>
                            </div>)}
                        </div>
                        {/* <div>
              <span className="font-size-30 bold-text text-muted float-right">${cost}</span>
              {oldPrice ? <span className="card-old-price mx-4 text-muted ">${ oldPrice}</span> : ""}
            </div> */}
                    </div>
                    <div className="mb-10" >
                        {renderActvities()}
                    </div>
                </CardContent>
            </Card ></Link>);
}

export default ProfileCard