import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import scriptLoader from "react-async-script-loader";
import { Link } from "react-router-dom"
import { handleRecaptchaToken } from "redux/actions/authActions"
import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  recaptcha: {
    background: "transparent",
    border: "transparent",
    color: "gray",
    fontSize: "8px"
  }
}))

const recaptcha = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const GoogleRecaptcha = ({ setRecaptchaToken, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  useEffect(() => {
    const { isScriptLoaded, isScriptLoadSucceed } = props;

    const scriptJustLoaded =
      props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        handleRecaptcha()
      }
    }
  }, [props.isScriptLoaded, props.isScriptLoadSucceed])
  const handleRecaptcha = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(`${recaptcha}`, { action: "login" })
        .then((token) => {
          dispatch(handleRecaptchaToken(token))
        });
    });
  };

  return <>
    <button className={classes.recaptcha}
      data-sitekey={`${recaptcha}`}
      data-callback='onSubmit'
      data-action='submit'
      type="submit">
      This site is protected by reCAPTCHA and the Google
                <Link
        target="_blank"
        to={{ pathname: "https://policies.google.com/privacy" }} >  Privacy Policy
                </Link>  and
                <Link
        target="_blank"
        to={{ pathname: "https://policies.google.com/terms" }} > Terms of Service </Link>  apply.
                      </button>
  </>

}

export default scriptLoader(`https://www.google.com/recaptcha/api.js?render=${recaptcha}`)(GoogleRecaptcha)