import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Progress } from "reactstrap";
import { Button, Icon } from "@material-ui/core";
import { modalSubscrition } from "../../../../../../redux/actions/modalAction";
import { monthFormat } from "../../../../../../shared/helpers";
import { Skeleton } from "@material-ui/lab";

const SubscriptionCard = ({ subscriptionName, endDate, loading }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="text-center flex-middle">
        <div className="dashboard__booking-total-container">
          <h4 className="text-secondary pt-8">
            {subscriptionName}
          </h4>
          {subscriptionName === "Free" ? null : (
            <h6 className="progress-wrap text-bold mb-2">
              {loading ? (
                <>
                  <Skeleton
                    className="m-auto"
                    animation="wave"
                    height={25}
                    width="10"
                  />
                </>
              ) : (
                <span>Expires on - {monthFormat(endDate)}</span>
              )}
            </h6>
          )}
        </div>
        {/* <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label">87%</p>
          <Progress value={87} />
        </div> */}
        <div className="pt-2">
          <Button
            variant="outlined"
            onClick={() => dispatch(modalSubscrition(true, true))}
            size="small"
            color="primary"
          >
            Change subscription
          </Button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionCard;
