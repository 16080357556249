import React from "react";
import Dialog from '@material-ui/core/Dialog';
import moment from "moment"
import { Field, reduxForm, getFormInitialValues } from "redux-form"
import { connect, useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { renderField } from "../../../shared/components/form/RenderField"
import { validate } from "../../../config/formValidation"
import {formatInputDate} from "shared/helpers"
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle  disableTypography className={`pb-0 ${classes.root}`} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center"
    },
}))(MuiDialogActions);
function AddPromoCode({ handleSubmit,promoCodeSubmit,isPromoOpen, closePromoModal, isEdit, initialValues,valid,submitting }) {
    return (
        <div>
            <Dialog onClose={closePromoModal} aria-labelledby="Add Promo code" fullWidth open={isPromoOpen}>
                <DialogTitle onClose={closePromoModal}>
                    {isEdit ? "Edit Promo Code" : "Create Promo Code"}
                </DialogTitle>
                <form
                    className="login-form p-16"
                    onSubmit={handleSubmit(promoCodeSubmit)}
                >
                    
                    <DialogContent className="p-0 pt-10">
                    <div>
                            <Field
                                name="id"
                                component={renderField}
                                hidden
                            />
                        </div>
                        <div className="form__form-group">
                            <Field
                                name="codeName"
                                label="Code Name"
                                type="text"
                                startIcon="adjust"
                                component={renderField}
                                placeholder="Enter Code Name"
                            />
                        </div>
                        <div className="form__form-group">
                            <Field
                                name="code"
                                label="Code"
                                type="text"
                                startIcon='code'
                                component={renderField}
                                placeholder="Enter the Promo Code"
                            />
                        </div>
                        <div className="form__form-group">
                            <Field
                                name="discountPrice"
                                label="Discount Percentage"
                                type="number"
                                startIcon='local_offer'
                                component={renderField}
                                placeholder="Discount Percentage (%)"
                            />
                        </div>
                        <div className="form__form-group">
                            <Field
                                name="validUpto"
                                startIcon="schedule"
                                label="Valid Upto"
                                type="date"
                                inputProps={{min: formatInputDate(new Date().toISOString())}}
                                component={renderField}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions className="float-right p-0">
                    <Button
                        className="m-0"
                            variant="outlined"
                            color="secondary"
                            onClick={closePromoModal}
                            >
                            Cancel
                        </Button>
                        <Button
                        className="mx-10"
                            variant="contained"
                            type="submnit"
                            disabled={!valid || submitting}
                            color="primary">
                            Save
                        </Button>
                        
                    </DialogActions>
                    
                </form>
            </Dialog>
        </div>
    );
}

export default connect(state => ({
    initialValues: {
        id: state.promocode.promoDetails.id ? state.promocode.promoDetails.id : 0,
        codeName: state.promocode.promoDetails.codeName ? state.promocode.promoDetails.codeName : "",
        code: state.promocode.promoDetails.code ? state.promocode.promoDetails.code : "",
        discountPrice: state.promocode.promoDetails.discountPrice ? state.promocode.promoDetails.discountPrice : "",
        validUpto: state.promocode.promoDetails.validUpto ? moment(state.promocode.promoDetails.validUpto).format() : ""
    }
}))(reduxForm({
    form: 'promocodeForm',
    enableReinitialize: true,
    validate 
})(AddPromoCode))



