import moment from "moment"
import api from "../../utils/apiClient"
import {GET_ALL_STUDENT, SELECT_STUDENT,GET_STUDENT_ENROLLED_LIST,GET_STUDENT_LIST, STUDENT_LIST_BY_PROGRAM_ID, STUDENT_PROFILE_BY_ID} from "../types"
import {showFailedNoti,showSucessNoti} from "./notificationAction";

export const getAllStudent = (params,headers) => async (dispatch) => {
    await api.student.getStudent(params, headers).then(res => {
        if (res.data) {
            dispatch({
                type: GET_ALL_STUDENT,
                payload:res.data.result
             })
         }
    }).catch(err => {
        console.log(err)
    })
}

export const selectStudent = data => async dispatch =>{
    
    dispatch({
        type: SELECT_STUDENT,
        payload: data
    })
}
export const studentEnrolledList =()=> async dispatch=>{
    await api.student.getStudentEnrolledList().then(res =>{
       
        if(res.data){
          dispatch({
              type: GET_STUDENT_ENROLLED_LIST,
              payload: res.data.data
          })
        }
    })
    .catch(err =>{
        console.log(err)
    })
}

export const studentList =()=>async dispatch=>{
    await api.student.getStudentList().then(res =>{
            if(res.data){
                dispatch({
                    type:GET_STUDENT_LIST,
                    payload:res.data
                })
            }
    })
    .catch(err =>{
        console.log(err)
    })
}

export const getStudentListByProgramId =(data)=>async dispatch=>{
    await api.student.getStudentsByProgram(data).then(res =>{
            if(res.data){
                dispatch({
                    type:STUDENT_LIST_BY_PROGRAM_ID,
                    payload:res.data.result
                })
            }
    })
    .catch(err =>{
        console.log(err)
    })
}
export const getStudentProfile = (id) =>async dispatch=>{
    await api.student.getStudentById(id).then(res =>{
            if(res.data){
                var birthDate = moment(res.data.result.item2.birthDate).format();
                var obj = {...res.data.result.item2,birthDate};
                dispatch(setStudentProfileById(obj))
            }
            else {
                dispatch(showFailedNoti(res.data.message))
            }
    })
    .catch(err =>{
        dispatch(showFailedNoti(err.message))
    })
}
export const setStudentProfileById = (data) =>async dispatch=>{
            if(data){
                dispatch({
                    type:STUDENT_PROFILE_BY_ID,
                    payload:data
                })
    }
}
