import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom"
import { Col, } from 'reactstrap';

import { useSelector, useDispatch } from "react-redux"
import DwulCard from "../../../../shared/components/CardComponent/DwulCard"

import NoRecord from "../../../../shared/components/NoRecord"
import SkeletonCard from "../../../../shared/components/CardComponent/SkeletonCard"

const AllPrograms = () => {
  const dispatch = useDispatch()
  const {
    loading,
    allProgram,
    
  } = useSelector(state => state.program)
  // useEffect(() => {
  //   (async () => {
  //     dispatch(getAllProgram())
  //   })()
  // }, [])
  useEffect(() => {
    alert("program javascriptcdfe")
    if (!allProgram) {
      return;
    }
  
  }, [loading])
  return (
    <>
    <Col md={12} lg={12} sm={12} xs={12} xl={12}> 
            
            <h3 className="bold-text mb-2 ml-2">All Program</h3>
            </Col>
      {/* only if loading is true  */}
      {!loading && allProgram.length === 0 && (<Col><NoRecord /></Col>)}
      {loading && (<SkeletonCard/>) }
      { allProgram.map((item, index) => (
        <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}  className="mb-10">
          <DwulCard
            name={item.programName}
            image={item.programPhoto}
            description={item.description}
            id={item.id}
            newTag={item.new}
            regulerDates={item.regulerDates}
            specialDates={item.regulerDates[0]}
            availability={item.availability}
            location={item.locationName}
            cost={item.cost}
            activities={item.activity}
            key={index}
            isManage={false}
            routePath={`/detail/${item.programName}/${item.id}`}
            item={item}
            providerImage={item.providerImage}
            organizationName ={item.organisationName}
          />
        </Col>
      ))}
    </>
  );
}

export default withRouter(AllPrograms);
