import axios from 'axios';
import { getToken,getRefreshToken } from '../../helpers';
import { userService } from "../../../Services/userService";
import  { Redirect } from 'react-router-dom'

export const defaultParams = () => ({
  headers: { Authorization: `Bearer ${getToken()}` },
});

export default axios;


// axios response inceptor
export const axiosInterceptor = (history) => {
  axios.interceptors.response.use(response => {
    return response
  },
    error => {

      // Reject promise if usual error
      if (error.response && error.response.status === 401 && getToken() !== null && getRefreshToken() !== null) {
        return axios
        .post(window.apiUrl + 'Account/refresh-accesstoken', {
          refreshToken: getRefreshToken(),
          authToken: getToken()
        }).then(response => {
          userService.setToken(response.data.data.authToken);
          userService.setRefreshToken(response.data.data.refreshToken);
          error.response.config.headers['Authorization'] = 'Bearer ' + response.data.data.authToken;
          return axios(error.response.config);
        }).catch(error => {
          localStorage.removeItem('authToken');
          localStorage.removeItem('authUser');
          localStorage.removeItem('refreshToken')
          history.push('/');
        })
      
      } else {
          axios.interceptors.response.eject(axiosInterceptor);
          return Promise.reject(error);
      }

      /* 
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
     

    
    }
  );
}   
