import { get } from './base/index';
import {post} from "./base/index"
export default {
    addStudent: (body, headers) => {
        const data = body
        return post(`${window.apiUrl}Student/Manage-Student`,data, headers)
    },
    getStudent: (data, headers) => {
        const params = {...data}
        return get(`${window.apiUrl}Student/student-of-parent`,{params},headers)
    },
    getStudentEnrolledList: ()=>{
        return get(`${window.apiUrl}Student/enrolled-student-list`,{}, true)
    },
    getStudentList: ()=>{
        return get(`${window.apiUrl}Student/student-list`,{}, true)
    },
    getStudentsByProgram: ({pageNo, pageSize, programId}) => {
        return get(`${window.apiUrl}Student/students-in-program?pageNo=${pageNo}&PageSize=${pageSize}&programId=${programId}`, {}, true)
    },
    deleteStudent: (id) => {
        return post(`${window.apiUrl}Student/delete-student?userId=${id}`,{}, true)
    },
    getStudentById: (id) => {
        return get(`${window.apiUrl}Student/student-details?userId=${id}`, {}, true)
    }
};