 import {ALL_RECOMMENDATION} from "../types"

 const initialState = {
    recommendations: [],
     recomLoading:true
 }

const recommendationReducer = (state = initialState, action) => {
    switch(action.type){
        case ALL_RECOMMENDATION:
            return{
                ...state,
                recommendations: action.payload,
                recomLoading:false
            }
        default:
            return state;
    }
 }

 export default recommendationReducer