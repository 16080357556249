import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ContactContent from "./ContactContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from "@material-ui/core";
import SendMessage from "./SendMessage";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const ContactModal = ({ isOpen, title, closeModal, sendMessages }) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle onClose={closeModal} id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="my-10" >
            <ContactContent closeModal={closeModal} />
          </div>
          <div >
            <SendMessage
              sendMessages={sendMessages}
              closeModal={closeModal}
            />
          </div>


        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactModal;
