import React from 'react';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Card, Icon, Divider, Chip, Avatar } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Skeleton } from "@material-ui/lab"
import { truncateString, ArrayToString, dateFormat } from "../../../shared/helpers"
const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
const useStyles = makeStyles({
  root: {
    maxWidth: "21rem",
    minWidth: "21rem"
  },
  content: {
    maxHeight: '13rem',
    height: '13rem',
  },
  media: {
    height: 240,
    width: '21rem'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chipFixes: {
    overflowY: 'auto',
    height: '2rem'
  }
});

function DwulCard({
  handleEdit,
  handleDelete,
  name,
  description,
  id,
  regulerDates,
  specialDates,
  regularStartTime,
  specialStartTime,
  availability,
  location,
  cost,
  activities,
  image,
  isManage,
  paymentStatus,
  newTag,
  salesTag,
  oldPrice,
  type = "program",
  item
}) {

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const renderChip = () => {
    let data = " "
    data = activities && activities.map((tag, i) => {
      if (i < 3) {
        return (
          <Chip className="mx-4" variant="outlined" key={i} size="small" label={tag.name} />
        )
      }
    })
    return data
  }
  const renderActvities = () => {
    if (activities && activities.length > 3) {
      return <>
        {renderChip()}
        <Chip className="mx-4" variant="outlined" size="small" label={`+ ${activities.length - 3} more`} />
      </>
    } else {
      return renderChip()
    }
  }
  const routePath = {
    pathname: `/page/detail/${id}`,
    search: `?type=${type}`,
    hash: `${name.replace(" ", '-')}`,
    state: { id: id }
  }
  // ----------------------
  const renderLocations = () => {
    let loc = []
    item.locations.map((l) => loc.push(l.name))
    return loc
  }
  return (
    <a href={routePath.pathname + routePath.search + '#' + encodeURI(routePath.hash)}>
      <Card className={classes.root + " mx-10 my-20 card-shadow "} >
        <CardActionArea className="main-program">
          <CardMedia
            className={classes.media}
            image={image ? image : `${process.env.PUBLIC_URL}/img/details.jpg`}
            title="Contemplative Reptile"
          />
          {/* {item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ""} */}
          {item.discount ? <img className="catalog-item__label" src={Sale} alt="On Sale" /> : ""}
          {type === 'program' && <span className="font-size-30 bold-text catalog-item_price">{cost > 0 ? `CA$${cost}` : "Free"}</span>}
          {type === 'experience' && <span className="font-size-30 bold-text catalog-item_price">{cost > 0 ? `CA$${cost}` : ""}</span>}

        </CardActionArea>


        <CardContent className={classes.content + " pt-0 pb-0"}>
          <h4 className="flex text-black my-10 flex flex-space-between">
            <span className=" bold-text capitalize">{truncateString(name, 25)}</span>
          </h4>
          <div className="flex flex-middle flex-space-between text-muted my-4">
            <div>
              <div className="flex ">
                <Icon fontSize="small">language</Icon>
                <span className="mx-4">

                  {ArrayToString(item.locations).length > 2 ?
                    `${ArrayToString(item.locations)[0]}, ${ArrayToString(item.locations)[1]}, + ${ArrayToString(item.locations).length - 2}`
                    : ArrayToString(item.locations).join(", ")}
                </span>
              </div>
              {(regulerDates && regulerDates.length) > 0 && (<><div className="flex flex-middle ">
                <Icon fontSize="small">event_calendar</Icon>
                <span className="mx-4">{dateFormat(regulerDates[0])}</span>
              </div>
              </>)}



              {/* {(regularStartTime && regularStartTime.length) > 0 && (<><div className="flex flex-middle ">
                <Icon fontSize="small">alarm</Icon>
                <span className="mx-4">{regularStartTime}</span>
              </div>
              </>)} */}



              <div className="flex flex-middle  ">
                {/* <Icon fontSize="small">group</Icon> */}
                <span className="mx-4">

                  {item.ageGroup && (ArrayToString(item.ageGroup).length > 2
                    ? `${ArrayToString(item.ageGroup)[0]}, ${ArrayToString(item.ageGroup)[1]}, + ${ArrayToString(item.ageGroup).length - 2}` :
                    `${ArrayToString(item.ageGroup).join(", ")}`)
                  }
                </span>

              </div>
            </div>
            {/* <div>
              <span className="font-size-30 bold-text text-muted float-right">${cost}</span>
              {oldPrice ? <span className="card-old-price mx-4 text-muted ">${ oldPrice}</span> : ""}
            </div> */}
          </div>


          <div className={classes.chipFixes + " mb-10"} >
            {renderActvities()}
          </div>


          <div className="flex flex-middle" >
            <span className="mr-4 font-size-18">
              {item.paymentStatus}
              {/* {console.log("hellocheck",item)} */}
            </span>

          </div>
          {/* <Divider /> */}
          <div className="flex  flex-middle text-muted flex-space-evenly my-7">
            <div className="flex flex-middle  ">
              <Icon >alarm</Icon>
              <span className="mr-4 font-size-20">
                {(regulerDates === null ? 0 : regulerDates.length) + (specialDates === null ? 0 : specialDates.length)}
              </span>
              <span className="mr-8">
                session(s)
              </span>
            </div>
            {item.registredStudents < item.capacity ? <div className="flex flex-middle" >
              <Icon className="mr-4" >event_seat</Icon>
              <span className="mr-4 font-size-18">
                {item.capacity - item.registredStudents}
                {/* {console.log("hello"+item)} */}
              </span>
              <span >

                Seats Remaining
              </span>
            </div> :
              <div className="flex flex-middle" >
                <Icon className="mr-4" >event_seat</Icon>
                <span className="mr-4 font-size-18">
                  0
                  {/* {console.log("hello"+item)} */}
                </span>
                <span >

                  Seats Remaining,
                </span>
              </div>
            }
          </div>
        </CardContent>
        <CardActions className="bg-light-grey flex-middle flex-space-between">
          <div className="flex flex-mddle flex-start">
            <Avatar src={item.providerImage ? item.providerImage : `${process.env.PUBLIC_URL}/img/avatar/user1.jpg`} />
            <div className="mx-10 flex flex-middle">
              <div className="mx-4">
                <span>
                  {item.organisationName}
                </span>
                {/* <div>
                  <span>
                    <Rating name="read-only" value={item.rating} readOnly />
                  </span>
                </div> */}
              </div>

            </div>
          </div>
          {/* {item.isFavorite && (<Icon color="secondary" fontSize="large">favorite</Icon>)} */}

        </CardActions>
      </Card >
    </a>

  );
}

export default DwulCard