import { GET_RESOURCES, POST_RESOURCES,DELETE_RESOURCES,RESOURCES_BY_ID,RESOURCES_DETAILS } from "../types";

const initialState = {
    resource: [],
    postResources:{},
    resourceById: {},
    resourcesDetails: {}
}

const resourcesReducer=(state = initialState,action)=>{
    switch(action.type){
        case GET_RESOURCES:
            return {
                ...state,
                resource: action.payload
            }
        case POST_RESOURCES:
            return {
                ...state,
                postResources: action.payload
            }
        case RESOURCES_BY_ID:
            return{
                ...state,
                resourceById: action.payload
            }
        case RESOURCES_DETAILS:
            return{
                ...state,
                resourcesDetails: action.payload
            }
        default: 
        return state;
    }
}
export default resourcesReducer;