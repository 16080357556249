import React from 'react'
import Collapse from '../../../../shared/components/Collapse';

export default function ParentFAQs() {
    return (
        <>
        <Collapse className="with-shadow" title="How do I register for programs as a parent or guardian? ">
          <p>
          Click the login button to sign-up for a new account, or sign-in to an existing
parent account. See our online registration guide and tutorial here.
          </p>
        </Collapse>
        <Collapse className="with-shadow" title="How do I contact my program provider or instructor?">
          <p>
          All the program provider contact information is found on each program
registration page, located below the program description text in the Program
provider details section.
          </p>
        </Collapse>
        <Collapse className="with-shadow" title="How and where are the programs delivered?">
          <p>
          How and where the programs are delivered are different for each program and
program provider. Such as online virtual programs, in-person afterschool
programs at local schools, out-of-school and holiday camps, and programs
hosted directly at a local gym, studio, libraries, or community centers
          </p>
          <p>
          See the program description for all the details on how and where the program is
delivered, and contact the program provider for any questions.
          </p>
        </Collapse>
        <Collapse className="with-shadow" title="How do I request for a refund and cancellation?">
          <p>
          You can request for a refund and cancellation anytime, up to 24-hours before the
program start date. Simply contact DWUL at:<a className="cursor-pointer" href="mailto:registration@dowhatuluv.ca">registration@dowhatuluv.ca</a>, or
your Program Provider. Requests received within the 24-hours prior to the start
date will be charged for the first session.
          </p>
        </Collapse>
        <Collapse className="with-shadow" title="Can I request for a refund after a program has started?">
          <p>
          Full refunds are not available after a program has started and may be eligible for
a partial-refund based on how many sessions are in the program. All the
partial-refund requests are reviewed and approved by the Program Providers
individually, before DWUL can issue a partial-refund.
          </p>
          <p>
          Any requests received by the DWUL Platform at: <a className="cursor-pointer" href="mailto:registration@dowhatuluv.ca">registration@dowhatuluv.ca</a>, will
be forwarded to the Program Provider for approval.
          </p>
          <p>
          In the event that a Program Provider does not respond or acknowledge your
request, DWUL reserves the right to intervene and follow up with the Program
Provider directly to resolve the request.
          </p>
        </Collapse>
      </>
        
    )
}
