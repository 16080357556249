import React from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { authRoles } from "../../../../config/authroles";
import ParentProfile from "../../../Profile";
import AdminProfile from "./AdminProfile/index";
import SpeedDials from "../../../SpeedDials"
import HelmetComponent from "shared/components/HelmetComponent"
import {seoData} from "shared/StaticData"
import SuperAdmin from "containers/SuperAdmin";

const Profile = () => {
  const auth = useSelector((state) => state.user);
  const history = useHistory();
  const renderUi = () => {
    switch (auth.role) {
      case authRoles.Parents:
        return (
          <>
            <ParentProfile />
            <SpeedDials />
          </>
        );
      case authRoles.Admin:
        return <AdminProfile />;
      
      case authRoles.SuperAdmin:
        return <AdminProfile/>

      default:
        return null;
    }
  };
  return <><HelmetComponent seoData={ seoData.profile}/>
  {renderUi()}</>;
};
export default withRouter(Profile);
