import React from 'react';
import { connect } from "react-redux"
import {
  Modal,ModalHeader

} from 'reactstrap';
import SubscriptionPlan from "../../../../shared/components/SubscriptionPlan"
const ModalSubscription = ({
  title,
  isOpen,
  closeModal,
  setCurrentUser
}) => {
  return <Modal
    toggle={closeModal}
    size="lg"
    className="p-0 subscription_modal"
    isOpen={isOpen} centered>
    <ModalHeader toggle={closeModal}>{ title}</ModalHeader>

    <SubscriptionPlan setCurrentUser={setCurrentUser}/>
  </Modal>
}


export default connect()(ModalSubscription);
