import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Container,
  Row,
  Badge
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Icon,
  TableRow,
  TableCell,
  Chip
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { getUserList, getUserCount, deleteSpecificUser, activateUser, deactivateUser, } from "../../redux/actions/userAction";
import SearchableTable from "../../shared/components/SearchableTable";
import { addUserAdminModal } from "../../redux/actions/modalAction";
import CounterCard from "../../shared/components/CounterCard";
import AddUser from "./components/AddUser";
import ConfirmationModal from "../../shared/components/ConfirmationModal"
import { getParentProfileById } from "redux/actions/profileActions"
import { ArrayToString } from "shared/helpers"
const columns = [
  {
    id: 1,
    label: "Logo",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "User Type",
  },
  {
    id: 4,
    label: "Email",
  },

  {
    id: 5,
    label: "Status",
  },
  {
    id: 6,
    label: "Actions",
  },
];
const useStyles = makeStyles((theme) => ({

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  programList: {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px"
  },
  active: {
    color: "#ef3e37 !important",
    fontWeight: "800 !important"

  }
}));
const UsersList = () => {
  const classes = useStyles();
  const history = useHistory()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [userStatusCofirmModal, setuserStatusCofirmModal] = useState(false);
  const [deleteUserModal, setdeleteUserModal] = useState(false);
  const [profileDialog, setProfileDialog] = useState(false)
  const [currentId, setCurrentId] = React.useState({});
  const dispatch = useDispatch();
  const { userList, usersCount } = useSelector((state) => state.users);
  const { parentProfile } = useSelector((state) => state.profile);
  //Selector for modal/popup.
  const modal = useSelector((state) => state.modal);
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const clearSearch = () => {
    setSearchValue("");
  };
  const handleUserSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  //MODAL FOR ADDING USER
  const openAddUserModal = () => {
    dispatch(addUserAdminModal(true));
  };
  // CLOSING MODAL
  const closeAddUserModal = () => {
    dispatch(addUserAdminModal(false));
  };
  useEffect(() => {
    getPageData()
  }, []);
  const getPageData = () => {
    dispatch(getUserList(""));
    dispatch(getUserCount());
  }
  let filteredData = userList.filter(item => item.id !== 2);
  if (searchValue) {
    filteredData = userList.filter((search) => {
      if (search.name.toLowerCase().startsWith(searchValue.toLowerCase())) {
        return search;
      } else if (
        search.email.toLowerCase().startsWith(searchValue.toLowerCase())
      ) {
        return search;
      }
    });
  }
  const handleClose = () => {
    setuserStatusCofirmModal(false);
    setdeleteUserModal(false)
    setProfileDialog(false)
    setCurrentId({})
  };
  const handleDeactivateUser = () => {

    let { id } = currentId;
    dispatch(deactivateUser(id)).then(() => {
      setuserStatusCofirmModal(false);
      dispatch(getUserList(""))
    })
  };
  const confirmHandleDelete = (id) => {
    setCurrentId(id);
    setuserStatusCofirmModal(true);
  };
  const confirmHandleActivate = (id) => {
    setCurrentId(id);
    setuserStatusCofirmModal(true);
  };
  const handleActivateUser = async () => {
    let { id } = currentId;
    dispatch(activateUser(id)).then(() => {
      dispatch(getUserList(""))
    })
    setuserStatusCofirmModal(false);

  };
  const handleViewProfile = (item) => {
    if (item.dwulRoles === 4) {
      dispatch(getParentProfileById(item.id)).then(() => {
        setProfileDialog(true)
      })
    }
    else if (item.dwulRoles === 3) {
      history.push({
        pathname: `/page/detail/${item.id}`,
        search: `?type=provider`,
        hash: `${item.name}`
      })
    }
  }
  const renderStatus = (item) => {
    if (item.isActive) {
      return <Badge color="success">Active</Badge>
    } else {
      return <Badge color="danger">Deactivated</Badge>
    }
  }
  const confirmDeleteUser = (id) => {
    setCurrentId(id)
    setdeleteUserModal(true)
  }
  const handleDeleteUser = () => {
    const { id } = currentId
    dispatch(deleteSpecificUser(id)).then(() => {
      setdeleteUserModal(false)
      getPageData()
    })
  }

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h5 className="uppercase bold-text font-size-13 mb-24">
                Accounts
              </h5>
              <Row>
                <Col md={4} sm={6} xs={12} className="mb-10">
                  <CounterCard
                    count={usersCount.admin}
                    icon="supervisor_account_icon"
                    label="Admins"
                    size="small"
                    className="mr-16"
                  />
                </Col>
                <Col md={4} sm={6} xs={12} className="mb-10">
                  <CounterCard
                    count={usersCount.provider}
                    icon="supervisor_account_icon "
                    label="Providers"
                    size="small"
                    className="mr-16"
                  />
                </Col>
                <Col md={4} sm={6} xs={12} className="mb-10">
                  <CounterCard
                    count={usersCount.parent}
                    icon="supervisor_account_icon "
                    label="Parents"
                    size="small"
                    className="mr-16"
                  />
                </Col>
              </Row>
              <SearchableTable
                title=""
                rows={columns}
                filteredData={filteredData}
                loading={false}
                actionTablebtn={false}
                filterAction={true}
                searchType="text"
                searchLabel="Search"
                actionBtnText="Add User"
                actionBtnClick={openAddUserModal}
                searchValue={searchValue}
                clearSearch={clearSearch}
                searchPlaceholder="Search User"
                pagination={true}
                getSearchResult={handleUserSearch}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{(index + 1) + (page * rowsPerPage)}</TableCell>
                      <TableCell className="material-table__cell">
                        <Avatar src={item.avatar} alt={item.name} />
                      </TableCell>
                      <TableCell className="material-table__cell text-nowrap text-nowrap">
                        {item.name}
                      </TableCell>
                      <TableCell className="material-table__cell">
                        {item.userRole}
                      </TableCell>
                      <TableCell className="material-table__cell">
                        {item.email}
                      </TableCell>

                      <TableCell className="material-table__cell">
                        {renderStatus(item)}
                      </TableCell>

                      <TableCell className="material-table__cell">
                        <UncontrolledDropdown className="dashboard__table-more">
                          <DropdownToggle>
                            <p>
                              <DotsHorizontalIcon />
                            </p>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                            <DropdownItem onClick={() => handleViewProfile(item)}>
                              <span className="flex flex-middle"><Icon color="primary" className="mr-8">visibility</Icon>View</span>
                            </DropdownItem>
                            {item.isActive ?
                              (<DropdownItem onClick={() => confirmHandleDelete(item)} >
                                <span className="flex flex-middle"><Icon color="error" className="mr-8">block</Icon>Deactivate</span>
                              </DropdownItem>)
                              : (<DropdownItem onClick={() => confirmHandleActivate(item)} >
                                <span className="flex flex-middle"><Icon className="mr-8 text-success">check_circle_outline_icon</Icon>Activate</span>
                              </DropdownItem>)}
                            <DropdownItem onClick={() => confirmDeleteUser(item)}>
                              <span className="flex flex-middle"><Icon color="error" className="mr-8">delete</Icon>Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </TableCell>
                    </TableRow>
                  ))}
              </SearchableTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AddUser
        closeAddUserModal={closeAddUserModal}
        isAddUserModalOpen={modal.addUserAdmin}
      />
      <ConfirmationModal
        color="warning"
        title={`${currentId.isActive ? "Deactivate" : "Activate"} Confirmation`}
        message={`Are you sure want to ${currentId.isActive ? "Deactivate" : "Activate"} ${currentId.name}?`}
        colored=""
        header={true}
        isOpen={userStatusCofirmModal}
        closeModal={handleClose}
        onYesClick={currentId.isActive ? handleDeactivateUser : handleActivateUser}
      />
      <ConfirmationModal
        color="danger"
        title="Delete Confirmation"
        message={`Are you sure you want to Delete ${currentId.name} ?`}
        colored=""
        header={true}
        isOpen={deleteUserModal}
        closeModal={handleClose}
        onYesClick={handleDeleteUser}
      />
      {profileDialog && (<ConfirmationModal
        color="primary"
        title=""
        message={
          <>
            <Row>
              <Col md={12}>
                <Avatar variant="circle" className={classes.large + " studentImage m-auto"} src={parentProfile.parentProfile.avatar} />
                <div className="font-size-30 font-weight-500 text-center capitalize ">
                  {`${parentProfile.parentProfile.fName} ${parentProfile.parentProfile.mName ? parentProfile.parentProfile.mName : ""} ${parentProfile.parentProfile.lName}`}
                </div>
                <div className="font-size-13 mb-4 text-center text-muted">
                  {parentProfile.parentProfile.email}
                </div>
                {parentProfile.parentProfile.interestNames.length > 0 && (
                  <div className="mb-16 text-center">
                    {ArrayToString(parentProfile.parentProfile.interestNames).map((item) => (
                      <Chip
                        variant="outlined"
                        className="mx-4"
                        size="small"
                        color="secondary"
                        label={item}
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="font-size-13 font-weight-500 capitalize">
                  Registered Program:
                </div>
              </Col>
              <Col md={12}>
                {parentProfile.registeredPrograms.length > 0 && parentProfile.registeredPrograms.map((data, index) =>
                  <div className="mt-4 mb-8">
                    <li key={index} className={classes.programList + " text-muted p-10 ProgramName"} >
                      <div>
                        <span className="font-size-20 capitalize font-weigth-600 text-black">{data.programName}</span>
                        <h5 className=" flex flex-middle mb-4"><Icon className="mr-10 text-black" >group</Icon>Registered Students:</h5>
                        {data.studentNames && data.studentNames.length > 0 && (data.studentNames.map(item => <h6 className="ml-8 capitalize flex flex-middle"><Icon className="mr-8 text-black font-size-10">fiber_manual_record_icon</Icon>{item}</h6>))}
                        <h6 className="text-right">Registered on: {new Date(data.dateOfRegistration).toLocaleDateString()}</h6>
                      </div>
                    </li>
                  </div>
                )}
                {parentProfile.registeredPrograms.length === 0 && (<h6 className="text-muted">No Registration found.</h6>)}
              </Col>
              <Col md={12}>
                <div className="font-size-13 font-weight-500 capitalize">
                  Registered Students:
                </div>
              </Col>
              <Col md={12}>
                {parentProfile.studentProfileList.length > 0 && parentProfile.studentProfileList.map((data, index) =>
                  <div className="mt-4 mb-8">
                    <li key={index} className={classes.programList + " text-muted p-10 ProgramName"} >
                      <div>
                        <div className="font-size-20 capitalize text-black flex flex-middle mb-8">
                          <Avatar variant="circle" className="mr-10" src={data.photo} />
                          <div>
                            {`${data.fName} ${data.mNmae ? data.mNmae : ""} ${data.lName}`}
                            <h5>{`${data.grade}, ${data.gender}`}</h5>
                          </div>
                        </div>
                        <h5 className="text-black"><Icon className="mr-8">cake</Icon>{data.birthDate}</h5>
                        <h5 className="text-black"><Icon className="mr-8">school</Icon> School: {data.schoolName}</h5>
                        <h5 className="text-black"><Icon className="mr-8">phone</Icon>{data.phone}</h5>
                        <h5 className="text-black"><Icon className="mr-8">phone</Icon>{data.emergencyContact}</h5>
                      </div>
                    </li>
                  </div>
                )}
                {parentProfile.studentProfileList.length === 0 && (<h6 className="text-muted">No Registration found.</h6>)}
              </Col>
            </Row>
          </>
        }
        colored=""
        header={true}
        isOpen={profileDialog}
        closeModal={handleClose}
        noToolbar={true}
        noHeader={true}
      />)}
    </Container>
  );
};

export default UsersList;
