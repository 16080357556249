import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col } from 'reactstrap';
import Skeleton from "@material-ui/lab/Skeleton"
import { Box } from "@material-ui/core"
import DwulCard from "../../../shared/components/CardComponent/DwulCard";
import SkeletonCard from "../../../shared/components/CardComponent/SkeletonCard";
import { getEnrolledProgram } from "../../../redux/actions/programActions";
import Carousel from '../../../shared/components/carousel/CarouselMultiply';
import NoRecord from "../../../shared/components/NoRecord"
const MyList = () => {
  const { enrolledProgram, myListLoading } = useSelector(state => state.program)
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      dispatch(getEnrolledProgram());
      console.log("----")
    }
    )()
  }, [])
  return (
    <>
      {!myListLoading && enrolledProgram.length === 0 && (<Col ><NoRecord /></Col>)}
      {myListLoading && (<SkeletonCard />)}
      {enrolledProgram && enrolledProgram.length > 0 && enrolledProgram.map((item, index) => {
        return (
          <div className="flex flex-middle ml-10" key={index}>
            <DwulCard
              name={item.programName}
              image={item.programPhoto}
              description={item.description}
              id={item.id}
              newTag={item.new}
              discountTag={item.discount}
              specialDates={item.specialDates}
              availability={item.availability}
              location={item.locationName}
              cost={item.cost}
              activities={item.activities}
              key={index}
              isManage={false}
              item={item}
              capacity={item.capacity}
              sessionPerCost={item.sessionsForCost}
              regulerDates={item.regulerDates}
            />
          </div>
        )
      })}
    </>
  );
};

export default MyList;
