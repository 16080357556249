import React, { useState } from "react"
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { TableRow, TableCell, Tooltip, Typography } from "@material-ui/core"
import SearchableTable from "shared/components/SearchableTable"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { truncateString } from "shared/helpers"
import ConfirmationModal from "shared/components/ConfirmationModal"
const Resources = ({
    openResourcesModal,
    resource,
    refreshResources,
    handleEditResources,
    handleDeleteResources,
    columns,
    editResources = true,
    title = ""
}) => {
    const [searchValue, setSearchValue] = useState("")
    const [dialog, setDialog] = useState(false)
    const [currentId, setCurrentId] = useState({})

    const handlePopup = (data) => {
        setCurrentId(data)
        setDialog(true)
    }
    const clearSearch = () => {
        setSearchValue("")
    }
    const handleResourcesSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    let filteredData = resource
    if (searchValue) {
        filteredData = resource.filter((search) => {
            if ((search.subject.toLowerCase()).startsWith(searchValue.toLowerCase())) {
                return search
            }

        })
    }
    return <>
        <SearchableTable
            title={title}
            rows={columns}
            filteredData={filteredData}
            loading={refreshResources}
            actionTablebtn={editResources}
            actionBtnIcon="add"
            actionBtnText=""
            actionBtnClick={openResourcesModal}
            searchType="text"
            searchLabel="Search"
            searchValue={searchValue}
            clearSearch={clearSearch}
            searchPlaceholder="Search Resources"
            pagination={false}
            getSearchResult={handleResourcesSearch}
        >
            {filteredData.map((item, index) => (
                <TableRow
                    key={index}
                >
                    <TableCell padding="checkbox">
                        {index + 1}
                    </TableCell>
                    <TableCell className="material-table__cell" >
                        {item.subject}
                    </TableCell>
                    <TableCell className="material-table__cell" >
                        <Tooltip title="Click to read more.">
                            <Typography onClick={() => handlePopup(item)} noWrap>{truncateString(item.body, 50)}
                                <span className="text-muted cursor-pointer font-size-10 ml-4" onClick={() => handlePopup(item)} >{"Read more >"}</span></Typography>
                        </Tooltip>
                    </TableCell>
                    <TableCell className="material-table__cell" >
                        <a href={item.link} target="_blank">
                            {item.link}
                        </a>
                    </TableCell>
                    {editResources && (<TableCell className="material-table__cell" >
                        <UncontrolledDropdown className="dashboard__table-more">
                            <DropdownToggle>
                                <p><DotsHorizontalIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                <DropdownItem onClick={() => handleEditResources(item.id)}>Edit</DropdownItem>
                                <DropdownItem onClick={() => handleDeleteResources(item.id)}>Delete</DropdownItem>
                            </DropdownMenu>
                        </ UncontrolledDropdown>
                    </TableCell>)}
                </TableRow>
            ))}
        </SearchableTable>
        {dialog && (<ConfirmationModal
            isOpen={dialog}
            color={"primary"}
            closeModal={() => setDialog(false)}
            title={currentId.subject}
            message={currentId.body}
            noToolbar={true}
        />)}
    </>

}

export default Resources