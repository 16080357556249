import React, { useState } from "react";
import Panel from "../../../shared/components/Panel";
import SearchableTable from "../../../shared/components/SearchableTable";
import { Button, Icon, TableRow, TableCell } from "@material-ui/core";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

const rows = [
  {
    id: 0,
    label: "Plan Name",
  },
  {
    id: 1,
    label: "Cost (Monthly)",
  },
  {
    id: 2,
    label: "Cost (Annually)",
  },
  {
    id: 3,
    label: "Actions",
  },
  {
    id: 4,
    label: "",
  },
];
const Table = ({
  list,
  refreshSubscription,
  onRefresh,
  openSubModal,
  handleEdit,
  handleDelete,
  confirmDeactvate,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(50);

  const clearSearch = () => {
    setSearchValue("");
  };
  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(event.target.value);
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleTablesSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };
  const tableData = () => {
    let tableData = [];
    if (list) {
      list.map((sub) => {
        tableData.push({
          id: sub.id,
          subscriptionName: sub.subscriptionName,
          descriptions: sub.descriptions,
          costPerMonth: sub.costPerMonth,
          annually: sub.annually,
        });
      });
    }
    return tableData;
  };
  let filteredData = tableData();
  if (searchValue) {
    filteredData = tableData().filter((search) =>
      search.subscriptionName
        .toLowerCase()
        .startsWith(searchValue.toLowerCase())
    );
  }
  return (
    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget py-28 px-24">
          <h5 className="uppercase bold-text font-size-13 mb-24">
            Subscription Plans
          </h5>
          <SearchableTable
            title=""
            rows={rows}
            filteredData={filteredData}
            loading={refreshSubscription}
            actionTablebtn={true}//{process.env.NODE_ENV === "development"?true:false }
            actionBtnIcon="add"
            actionBtnText=""
            actionBtnClick={openSubModal}
            searchType="text"
            searchLabel="Search"
            searchValue={searchValue}
            clearSearch={clearSearch}
            searchPlaceholder="Search Subscription"
            pagination={true}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getSearchResult={handleTablesSearch}
          >
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">{(index + 1) + (page * rowsPerPage)}</TableCell>
                  <TableCell className="material-table__cell text-nowrap">
                    {item.subscriptionName}
                  </TableCell>
                  <TableCell className="material-table__cell">
                    CA$ {item.costPerMonth}
                  </TableCell>
                  <TableCell className="material-table__cell">
                    CA${item.annually}
                  </TableCell>

                  <TableCell className="material-table__cell">
                    <UncontrolledDropdown className="dashboard__table-more">
                      <DropdownToggle className="m-auto">
                        <p>
                          <DotsHorizontalIcon />
                        </p>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__menu ">
                        <DropdownItem onClick={() => handleEdit(item.id)}>
                          Edit
                        </DropdownItem>
                        <DropdownItem onClick={() => confirmDeactvate(item.id)}>Delete</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </TableCell>
                </TableRow>
              ))}
          </SearchableTable>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Table;
