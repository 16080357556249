import {get,post} from "./base";

export default {
    post: (data,headers)=>{
        return post(`${window.apiUrl}Message/send-message`,data,headers)
    },
    get: (headers)=>{
        return get(`${window.apiUrl}Message/get-messages`,{},headers)
    },
    resloved : (data,headers)=>{
        // debugger
        return post(`${window.apiUrl}Message/resolve-message?id=${data}`,{},headers)
    },
    getMessageAdmin: ()=>{
        return get(`${window.apiUrl}Message/get-messages-admin`,{},true)
    },
    getMessageParent: ()=>{
        return get(`${window.apiUrl}Message/get-messages-user`,{},true)
    }
}