import Registration from "containers/AboutUs/Component/Registration"

const isPositiveNumber = (value) => value < 0 ? false : true

const getMaxDateGrThanToday = (date) => {

    let toDate = new Date()
    if (new Date(date).getTime() < toDate.getTime()) {
        return false
    }
    return true
}
const validateSessionForCost = (cost, sessionCount) => {
    if (parseInt(cost) > parseInt(sessionCount)) {
        return false
    }
    return true
}
//push
const validate = (values) => {
    const errors = {};
    const requiredFields = [
        "fName",
        "lName",
        // "cost",
        "capacity",
        "programName",
        "locations",
        "reason",
        "email",
        "password",
        "gender",
        "birthDate",
        "liabilityWaiver",
        "phone",
        "programLocation",
        "schoolTags",
        "grade",
        "emergencyContact",
        "favoriteColor",
        "notes",
        "oldPassword",
        "newPassword",
        "confirmNewPassword",
        "subject",
        "fullMassage",
        //"description",
        "City Tags",
        "name",
        "types",
        "costPerMonth",
        "annually",
        "location",
        "locations",
        "organisationName",
        "activity",
        "sessionsForCost",
        "availability",
        "subject",
        "contactNo",
        "ageGroup",
        //Fields of promo code form
        "codeName",
        "code",
        "discountPrice",
        "validUpto",
        "address",
        "address1",
        "city",
        "postalCode",
        "province",
        "termscondition",
        "regulerDates",
        "regularStartTime",
        // "specialEndTime",
        // "specialStartTime",
        "regularEndTime",
        "websiteLink",
        "title",
        "externalLink",
        "locationId",
        "category",
        "time",
        "date",
        "age",
        "cost",
        "activityId",

        // "facebookLink",
        // "twitterLink",
        // "instagramLink",
        // "youtubeLink",


    ];
    const positiveFlield = ["cost", "discountPrice", "annually", "sessionsForCost", "capacity", "costPerMonth", "commisionPercentage", "cents", "numberOfSessions"]
    // requiredFields.forEach((field) => {

    //     if (!values[field]) {
    //         errors[field] = "Required";
    //     }



    // });

    requiredFields.map((field) => {
        if (!values[field]) {
            errors[field] = "Required"
        }
    })


    positiveFlield.forEach((field) => {
        if (!isPositiveNumber(values[field])) {
            errors[field] = "Must be positive number.";
        }
    });

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }
    if (values.newPassword != values.confirmNewPassword) {
        errors.confirmNewPassword =
            "New Password and confirmed Password must be same";
    }

    if (!getMaxDateGrThanToday(values.validUpto)) {
        errors.validUpto = "Validity date should be greater than todays date."
    }
    // using in program to validated session count

    if (values.regulerDates && values.specialDates) {
        var regulerDatescount = 0;
        for (let a = 0; a <= values.regulerDates.length; a++) {
            
            if ((values.regulerDates[a] !== "") && (values.regulerDates[a] !== undefined)) {
                errors[a] = "Required";
                regulerDatescount = regulerDatescount + 1;
            }
        }

        var specialDatescount = 0;
        for (let b = 0; b <= values.specialDates.length; b++) {
            
            if ((values.specialDates[b] !== "") && (values.specialDates[b] !== undefined)) {
                errors[b] = "Required";
                specialDatescount = specialDatescount + 1;
            }
        }


        let sessionCount = regulerDatescount + specialDatescount
        // let sessionCount = values.regulerDates.length + values.specialDates.length
        if (!validateSessionForCost(values.sessionsForCost, sessionCount)) {
            errors.sessionsForCost = `"Per Registration" should not be greater than "No. of sessions".`
        }
    }

    // var linkValidation = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

    // const linkValidation = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

    var linkValidation = /^((https?|ftp|smtp):\/\/)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

    const webLink = ["websiteLink", "facebookLink", "twitterLink", "instagramLink", "youtubeLink"]

    webLink.forEach((field) => {
        if (values[field] && values[field].length > 0 && !linkValidation.test(values[field])) {
            errors[field] = "Invalid link";
        }
    });

    return errors;
};

export { validate };