import React from 'react';
import { Link,useHistory,withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'
import TopbarLogin from "./TopbarLogin"
import TopbarSearch from './TopbarSearch';
import { Col, Row} from 'reactstrap'

const useStyles = makeStyles((theme) => ({
  
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const TopbarWithNavigation = ({ changeMobileSidebarVisibility,logo }) => {
  const classes = useStyles();
  return (
  <Row className="topbar  flex-middle flex-space-between m-0">
      <Col xl={1} lg={1} md={1} sm={2} xs={2} className="">
      <Link className="ml-8 mr-8" to="/" >
          <img className={"my-10 " + classes.large}    src={logo} />
      </Link>
       
      </Col> 
      <Col xl={8} lg={8} md={7} sm={9} xs={9} >
      <TopbarSearch />
      </Col>
      <Col xl={3} lg={3} md={4} sm={12} xs={12}  className="flex flex-space-evenly flex-middle pb-8">
         <TopbarLogin/>
      </Col>
  </Row>
)};

TopbarWithNavigation.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default withRouter(TopbarWithNavigation)
