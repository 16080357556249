
import {
    GET_FEATURED_LIST,
    } from '../types';
    const initialState = {
        featuredList: [],
        featureLoading:true
    };
    
  const featuredReducer = (state = initialState, action) => {
    
      switch (action.type) {
        case GET_FEATURED_LIST:
          return {
            ...state,
              featuredList: action.payload,
              featureLoading:false
          }
        default:
          return state;
      }
    };
    
    export default featuredReducer;
    