import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, reduxForm } from "redux-form"
import {renderField} from "../../../shared/components/form/RenderField"
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent:"center"
    },
}))(MuiDialogActions);

function ApplyPromo({ title, isOpen, closeModal,handleSubmit, submitPromoCode,label,tagIcon, submitting }) {


    return (
        <div>
            <Dialog  onClose={closeModal} aria-labelledby="customized-dialog-title" open={isOpen}>
                <DialogTitle id="customized-dialog-title" onClose={closeModal}>
                    {title}
                </DialogTitle>
                <form
                   
                    onSubmit={handleSubmit(submitPromoCode)}
                >   

                    <DialogContent dividers>
                        <Field
                            name="code"
                            label="Code"
                            component={renderField}
                            type="text"
                            placeholder="Code"
                            startIcon="money_off"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting}
                            variant="outlined"
                            autoFocus
                            type="submnit"
                            color="primary">
                            Save 
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </div>
    );
}
export default reduxForm({
    form: 'apply-promo',
  } )(ApplyPromo)