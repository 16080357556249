export const parentNavigation = [{
    title: "Home",
    icon: "home",
    route: "/home",
},
{
    title: "Featured",
    icon: "tag",
    route: "/featured",
},
// {
//     title: "Interests",
//     icon: "bookmark",
//     route: "/interests"
// },
{
    title: "Activities",
    icon: "envelope",
    route: "/activities",
},
{
    title: "Community",
    icon: "bubble",
    route: "/community"
},
{
    title: "Calendar",
    icon: "calendar-full",
    route: "/calender",
},
]

export const provderNavigation = [{
    title: "Home",
    icon: "home",
    route: "/home",
},
{
    title: "Listings",
    icon: "tag",
    route: "/listings",
},
{
    title: "Students",
    icon: "bookmark",
    route: "/student"
},
{
    title: "Activities",
    icon: "envelope",
    route: "/activities",
},
{
    title: "Calendar",
    icon: "calendar-full",
    route: "/calender",
},
{
    title: "Link Management",
    icon: "calendar-full",
    route: "/link-program",
},
]
export const adminNavigation = [{
    title: "Home",
    icon: "home",
    route: "/home",
},
{
    title: "Listings",
    icon: "tag",
    route: "/listings",
},
{
    title: "Subscription",
    icon: "apartment",
    route: "/subscription",
},
{
    title: "Activities",
    icon: "envelope",
    route: "/activities",
},
{
    title: "Accounts",
    icon: "bubble",
    route: "/users"
},
{
    title: "Calendar",
    icon: "calendar-full",
    route: "/calender",
},]
export const superAdminNavigation = [{
    title: "Home",
    icon: "home",
    route: "/home",
},
{
    title: "Listings",
    icon: "tag",
    route: "/listings",
},
{
    title: "Accounts",
    icon: "bubble",
    route: "/user",
},
{
    title: "Subscription",
    icon: "apartment",
    route: "/subscription"
},
{
    title: "Activities",
    icon: "envelope",
    route: "/activities",
},
{
    title: "Students",
    icon: "bookmark",
    route: "/student"
},
]


export const homeNavigations = [{
    title: "Home",
    icon: "home",
    route: "/",
},
{
    title: "About",
    icon: "question-circle",
    route: "/about",
},
{
    title: "Support",
    icon: "phone-handset",
    route: "/support"
}]