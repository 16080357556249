import { post, get } from './base/index';

export default {
    managePromoCode: (data, headers) => {
        const body = data
        return post(`${window.apiUrl}PromoCode/Manage-Promocode`, body, headers)
    },
    getPromoCode: () => {
        return get(`${window.apiUrl}PromoCode/get-Promocodes`, {}, true)
    },
    getPromoCodeById: (id) => {
        return get(`${window.apiUrl}PromoCode/get-Promocode?id=${id}`, {}, true)
    },
    deletePromoCode: (id) => {
        return post(`${window.apiUrl}PromoCode/delete-Promocode?id=${id}`, true)
    }
}