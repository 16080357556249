import React from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import ChangePassForm from "./ChangePassForm"


export default function ChangePassModal({ isOpen, title, closeModal, confirmChangePass, onSubmit }) {
  return (
    <>
      <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen} centered>
        <ModalHeader toggle={closeModal}><h5><strong>{title}</strong></h5></ModalHeader>
        <ModalBody>
          <ChangePassForm
            closeModal={closeModal}
            onSubmit={onSubmit}
            form="change_password"
          />
        </ModalBody>
      </Modal>
    </>
  );
}
