import {  post, get } from './base/index';

export default {
    postReview: (data,headers) =>{
        // debugger
        return post(`${window.apiUrl}Review/add-review`,data,headers)
    },
    getReview: (headers) =>{
        return get(`${window.apiUrl}Review/get-review`,{},headers)
    },
    getParentReview: (id) =>{
        return get(`${window.apiUrl}Review/get-review-by-programid?programId=${id}`,{},true);
    }

}