import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTagsByTagType,setActivityTag } from "../../../../redux/actions/tagAction"
import { tagTypes } from "../../../../config/tagTypes"
import { Chip,} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import PersonPinIcon from '@material-ui/icons/PersonPin'

const ActivityTag = ({ }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(1),
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    const activityTag = useSelector(state => state.tags.activityTag)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch((getTagsByTagType([tagTypes.Activity])))
    }, [])


   /**
   * 
   * @param {*} color 
   * @return{string} change color of tag
   */
  const getColorTag = (color) => {
    switch (color) {
      case "primary":
        return "secondary"
      case "secondary":
        return "primary"
      default:
        return ""
    }
    }
    
  const getVariantTag = (variant) => {
    switch (variant) {
      case "default":
        return "outlined"
      case "outlined":
        return "default"
      default:
        return ""
    }

  }
  const handleSelectedTag = (tag) => {
    let filterData = []
    activityTag.filter((l) => {
      if (l.id === tag.id) {
        let newS = {
          color: getColorTag(l.color),
          id: l.id,
          name: l.name,
          tagType: l.tagType,
          types: l.types,
          variant: getVariantTag(l.variant),
        }
        filterData.push(newS)
      } else {
        filterData.push(l)
      }
    })
    dispatch(setActivityTag(filterData))
  }

    return <div className={classes.root}>
        {activityTag && activityTag.map((tag, i) => (
            <Chip
                key={i}
                color={tag.color}
                icon={<PersonPinIcon fontSize="small" />}
                label={tag.name}
                variant={tag.variant}
                onClick={() => handleSelectedTag(tag)}
            />
        ))}

    </div>
}



export default ActivityTag