import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Card, Icon, Divider, Chip, Button } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Skeleton } from "@material-ui/lab"
import { dateFormat, stringToTime } from "../../shared/helpers"
import { truncateString, ArrayToString } from "../../shared/helpers"
// import { dateFormat, ArrayToString,  } from "../../../shared/helpers";
import moment from 'moment';
const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
const useStyles = makeStyles({
    root: {
        maxWidth: '20rem',
        minWidth: "20rem"
    },
    content: {
        maxHeight: '13rem',
        height: '13rem',
    },
    media: {
        height: 250,
        width: '20rem'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    chipFixes: {
        overflowY: 'auto',
        height: '3rem'
    }
});

function CardNew({
    handleEdit,
    handleDelete,
    name,
    toolBar = true,
    description,
    id,
    regulerDates,
    specialDates,
    availability,
    locations,
    cost,
    activities,
    image,
    isManage,
    newTag,
    salesTag,
    onClick,
    oldPrice,
    capacity,
    selected,
    sessionPerCost,
    discountTag,
    item,
    favTag,
    sessionsCount,
    regulerStartTime,
    regulerEndTime,
    className,
    specialStartTime,
    specialEndTime,
    maximumAge,
    minimumAge,
    type = "program",
    type1
}) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const renderChip = () => {
        let data = " "
        data = activities && activities.map((tag, i) => {
            if (i < 3) {
                return (
                    <Chip className="mx-4" variant="outlined" key={i} size="small" label={tag.name} />
                )
            }
        })
        return data
    }
    const renderActvities = () => {
        if (activities && activities.length > 3) {
            return <>
                {renderChip()}
                <Chip className="mx-4" variant="outlined" size="small" label={`+ ${activities.length - 3} more`} />
            </>
        } else {
            return renderChip()
        }
    }
    const routePath = {
        pathname: `/page/detail/${id}`,
        search: `?type=${type}`,
        hash: `${name.replace(/ /g, '-')}`,
        state: { id: id }
    }
    // ---

    return (
        <Card className={classes.root + " my-20 card-shadow mx-10 " + `${selected ? "" : ""}`} >

            <a href={routePath.pathname + routePath.search + '#' + encodeURI(routePath.hash)} target="_blank" id="copylink">
                <CardActionArea className="main-program">
                    <CardMedia
                        className={classes.media}
                        image={image ? image : `${process.env.PUBLIC_URL}/img/details.jpg`}
                        title="Contemplative Reptile"
                    />
                    {newTag ? <img className="catalog-item__label" src={New} alt="new" /> : ""}
                    {discountTag ? <img className="catalog-item__label" src={Sale} alt="new" /> : ""}
                    {type === 'program' && <span className="font-size-30 bold-text catalog-item_price">{cost > 0 ? `CA$${cost}` : "Free"}</span>}
                    {type === 'experience' && <span className="font-size-30 bold-text catalog-item_price">{cost > 0 ? `CA$${cost}` : ""}</span>}
                    {/* {favTag ? <Icon className="card-item__fav-icon">favorite_border</Icon> : <Icon className="card-item__fav-icon">favorite</Icon>} */}

                </CardActionArea>

                <CardContent className={classes.content + " pt-0 pb-0"}>
                    <h4 className="flex-middle flex-space-between text-muted  my-8">
                        {/* {console.log("iddd djj", id)} */}
                        <input type="checkbox" onClick={onClick} defaultChecked={selected ? true : false} />
                        <span className=" bold-text"> {truncateString(name, 30)}</span>
                    </h4>
                    <div className="flex  flex-middle flex-space-between text-muted  my-4">
                        <div>
                            <div className="flex flex-start ">
                                <Icon fontSize="small">language</Icon>
                                <span className="mx-4">
                                    {ArrayToString(locations)?.length > 2 ?
                                        `${ArrayToString(locations)[0]}, ${ArrayToString(locations)[1]}, + ${ArrayToString(locations).length - 2}`
                                        : ArrayToString(locations)?.join(", ")}
                                </span>
                            </div>
                            {(regulerDates && regulerDates.length > 0) && (<div className="flex flex-start">
                                <Icon fontSize="small">event_calendar</Icon>
                                <span className="mx-4">{dateFormat(regulerDates[0])}{stringToTime(regulerStartTime)} </span>
                            </div>)}
                            <div className="flex flex-middle  ">
                                <Icon fontSize="small">group</Icon>
                                <span className="mx-4">
                                    {(maximumAge && minimumAge) ? <span className="mx-4">Age{minimumAge}- {maximumAge} </span> : ''}


                                </span>
                            </div>
                        </div>
                    </div>


                    <div className={classes.chipFixes + " mb-10"}>
                        {
                            renderActvities()
                        }
                    </div>
                    {type === "program" && (<> <Divider />
                        <div className="flex  flex-middle text-muted flex-space-evenly my-7">
                            <div className="flex flex-middle  ">
                                <Icon >alarm</Icon>
                                <span className="mr-4 font-size-20">
                                    {(regulerDates === null ? 0 : regulerDates.length) + (specialDates === null ? 0 : specialDates.length)}
                                </span>
                                <span className="mr-8">
                                    session(s)
                                </span>
                            </div>
                            {item.registredStudents < item.capacity ? <div className="flex flex-middle" >
                                <Icon className="mr-4" >event_seat</Icon>

                                <span className="mr-4 font-size-18">
                                    {item.capacity - item.registredStudents}
                                </span>

                                <span >

                                    Seats Remaining
                                </span>
                            </div> :
                                <div className="flex flex-middle" >
                                    <Icon className="mr-4" >event_seat</Icon>

                                    <span className="mr-4 font-size-18">
                                        0
                                    </span>

                                    <span >

                                        Seats Remaining
                                    </span>
                                </div>}
                        </div>
                    </>
                    )}
                </CardContent>
            </a>
            {toolBar ? (<CardActions style={{ marginTop: "40px" }} className="flex-end">

                <Button size="small" color="primary" onClick={() => handleEdit(id)}>
                    {/* <Button size="small" color="primary" > */}


                </Button>
                <Button size="small" color="secondary" onClick={() => handleDelete(id)}>

                </Button>
            </CardActions>) : (<>
                <CardActions className="flex-end" />
            </>)}

        </Card >


    );
}

export default CardNew