import React from 'react';
import { useSelector, useDispatch } from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { Switch, Grid } from '@material-ui/core';
import { SubscriptionType } from "config/subscrition"
import CardPremium from "./components/CardPremium"
import { getSubscription, setSubsType, saveSubscriptionData } from "../../../redux/actions/subscriptionAction"
import CarouselMultiply from "../carousel/CarouselMultiply"
import { paymentSubsModal, modalSubscrition } from "../../../redux/actions/modalAction"
import { getProviderProfile } from "../../../redux/actions/profileActions"
import NoRecord from "shared/components/NoRecord"
import CardPro from "./components/CardPro"
import ProgramPartner from "./components/ProgramPartner"
import { useState } from 'react';
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#52d869",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch)
export default function SubscriptionPlan({ setCurrentUser }) {

  const [subscriptionId, setsubscriptionId] = useState(null)
  console.log("subscriptionID==============>", subscriptionId)
  const { subscriptionList } = useSelector(state => state.subscription)
  console.log("subscriptionlist==============>", subscriptionList)
  const { subscriptionModal } = useSelector(state => state.modal)
  const [state, setState] = React.useState({
    yearlyCost: false,
    programPartners: false
  });
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getSubscription())
  }, [])
  // handling subscription on th basis of cost
  const handleSubscription = (subs) => {
    console.log("subs=======>", subs)
    let subsTypeLocal = state.yearlyCost ? SubscriptionType.Yearly : SubscriptionType.Monthly
    if (subs.costPerMonth > 0 && subs.annually > 0) {
      dispatch(setSubsType(subs, subsTypeLocal)).then(() => {
        dispatch(paymentSubsModal(true))
      })
    } else if (!subscriptionModal.isEdit) {
      saveTractionDetails(subs).then(() => setCurrentUser())
    } else {
      saveTractionDetails(subs)
    }
  }
  const saveTractionDetails = async (subs) => {
    console.log("saveTractionDetails ccccccc", subs)
    setsubscriptionId(subs.id)
    let subsTypeLocal = state.yearlyCost ? SubscriptionType.Yearly : SubscriptionType.Monthly
    let data = {
      subscriptionId: subs.id,
      invoiceNumber: 0,
      subscriptionType: subsTypeLocal,
      paymentSource: 1,
      transactionDetails: "",
      payPalPlanId: subs.payPalPlanId
    }
    await dispatch(saveSubscriptionData(data)).then(() => {
      dispatch(getProviderProfile())
      dispatch(modalSubscrition(false, false))
    })
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleProgramPartners = () => {
    setState(state => (
      {
        ...state,
        programPartners: true
      }))
  }
  const handleClose = () => {
    setState(state => (
      {
        ...state,
        programPartners: false
      }))
    if (!subscriptionModal.isEdit) {
      setCurrentUser()
    } else {
      dispatch(getProviderProfile())
      dispatch(modalSubscrition(false, false))
    }
  }
  return (

    <>
      {subscriptionList.length === 0 && (<NoRecord />)}
      <Grid component="label" container alignItems="center" justify="center" spacing={1}>
        <Grid item>
          <h4 className="cursor-pointer"> Monthly</h4>
        </Grid>
        <Grid item>
          <IOSSwitch checked={state.yearlyCost} onChange={handleChange} name="yearlyCost" />
        </Grid>
        <Grid item>
          <h4 class="cursor-pointer"> Yearly</h4>
        </Grid>
      </Grid>

      <CarouselMultiply>

        {
          subscriptionList.map((subs, i) => 
            <>
              <CardPremium
                yearlyCost={state.yearlyCost}
                planName={subs.subscriptionName}
                planDescription={subs.descriptions}
                subscriptionAlreadyTaken={subs.subscriptionAlreadyTaken}
                payPalPlanId={subs.payPalPlanId}
                monthlyCost={subs.costPerMonth}
                handleSubscription={() => handleSubscription(subs)}
                commisionPercentage={subs.commisionPercentage}
                cents={subs.cents}
                annuallyCost={subs.annually}
                key={i} />
            </>
          )
        }
        <CardPro handleProgramPartners={handleProgramPartners} />

      </CarouselMultiply>
      {state.programPartners && (

        <ProgramPartner
          closeModal={handleClose}
          isOpen={state.programPartners}

        />)}
    </>


  )
}
