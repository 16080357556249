import { ACTIVITY_TAG, CATEGORY_TAG, GET_TAGS_BY_TAGTYPE, LOCATION_TAG, CITY_TAG, SCHOOL_TAG, 
    TAG_DETAILS,DAYS_TAG,SELECTED_TAG, MAIN_SELECTED_TAG } from '../types';

const initialState = {
    allTags: [],
    locationTag: [],
    categoryTag: [],
    activityTag: [],
    cityTag: [],
    schoolTag: [],
    tagDetails: {},
    daysTag:[
        {
            id:0,
            name: "Monday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:1,
            name: "Tuesday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:2,
            name: "Wednesday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:3,
            name: "Thursday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:4,
            name: "Friday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:5,
            name: "Saturday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        },
        {
            id:6,
            name: "Sunday",
            color: "secondary",
            variant: "outlined",
            tagType: "days",
            types: '',
        }
    ],
    selectedTag:[],
    mainSelectedTag: "All"
}
const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TAGS_BY_TAGTYPE:
            return {
                ...state,
                allTags: action.payload
            }
        case LOCATION_TAG:
            
            return {
                ...state,
                locationTag: action.payload
            }
        case ACTIVITY_TAG:
            return {
                ...state,
                activityTag: action.payload
            }
        case CATEGORY_TAG:
            return {
                ...state,
                categoryTag: action.payload
            }
        case CITY_TAG:
            return {
                ...state,
                cityTag: action.payload
            }
        case TAG_DETAILS:
            return {
                ...state,
                tagDetails: action.payload
            }
        case SCHOOL_TAG:
            return {
                ...state,
                schoolTag: action.payload
            }
        case DAYS_TAG:
            return {
                ...state,
                daysTag: action.payload
            }
        case SELECTED_TAG:
            return {
                ...state,
                selectedTag: action.payload
            }
        case MAIN_SELECTED_TAG: {
            return {
                ...state,
                mainSelectedTag: action.payload
            }
        }
        default:
            return state;
    }
}
export default tagReducer