import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { getToken } from "../../../../utils/helpers"
import { verifyEmail } from "Services/userService"
import { showSucessNoti, showFailedNoti } from "redux/actions/notificationAction"
import { authRoles } from "../../../../config/authroles"
import { userInfoModal } from "../../../../redux/actions/modalAction"
import localStorageService from 'Services/localStorageService'
import { providerInfoModal } from '../../../../redux/actions/modalAction'

const LandingRoute = ({ component: Component, role, ...rest }) => {
  const auth = useSelector(state => state.user)
  const dispatch = useDispatch()


  React.useEffect(async () => {
    let token = new URLSearchParams(rest.location.search).get("code")

    console.log("tokennn", token)
    if (token) {
      await verifyEmail(token).then(res => {
        if (res.data.data) {



          dispatch(showSucessNoti(res.data.message))

          if (res.data.data.role === 4) {
            dispatch(userInfoModal(true))
          }
          else {
            dispatch(providerInfoModal(true))

          }





        } else {
          dispatch(showFailedNoti(res.data.message))
        }
      })
    }
  }, [])
  return (
    <Route
      {...rest}
      render={props => {
        return (auth.isAuthenticated)
          ?
          role = authRoles.Guest ?
            <Component {...props} />
            :
            <>
              <Redirect to='/home' />
            </>

          : <Component {...props} />
      }
      }
    />
  )
}

export default withRouter(LandingRoute)