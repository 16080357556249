import React from 'react';
import { TextField, InputAdornment, Icon} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
export default function AutoDropDownForAdmin(props) {
  let { input,
    label,
    type,
    startIcon,
    multiple = true,
    options,
    defaultValue,
    value,
    objectArr = false,
    disabled=false,
    meta: { touched, error, warning },
    ...custom
  } = props;
  const getOptionLabel = (option) => {
    if (objectArr) {
      return option.name
    } else {
      return option
    }
  }
  return (
    <div>
   
      <Autocomplete
        multiple={multiple}
        limitTags={3}
        id="size-small-outlined-multi"
        size="small"
        autoSelect={true}
        options={options}
        value={input.value ||[] }
        getOptionLabel={(option) => option.organisationName==undefined?option:getOptionLabel(option.organisationName)}
        
        onChange={(e, newValue) => {
          // console.log("hello"+JSON.stringify(newValue))
          input.onChange(newValue)
        }}
        
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            
            // InputProps={{
            //   ...params.InputProps,
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <Icon>{startIcon}</Icon>
            //     </InputAdornment>
            //   ),
            // }}
            {...custom}
          />
        )}
      />
      {touched &&
        ((error &&
          <span className="text-danger">{error}</span>)
          ||
          (warning && <span>{warning}</span>))}
    </div>
  );
}
