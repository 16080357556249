import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../../../shared/components/form/RenderField";
import { Col, Row } from "reactstrap";
import { Button, Divider } from "@material-ui/core";
import { validate } from "../../../../../config/formValidation";
const Form = ({
  handleSubmit,
  closeModal,
  sendMessages,
  submitting,
  valid,
}) => {
  return (
    <form onSubmit={handleSubmit(sendMessages)}>
      <Row>
        <Col md={12} xs={12} lg={12}>
          <div className="form__form-group">
            <Field
              name="subject"
              type="text"
              label="Title"
              startIcon="titleIcon"
              component={renderField}
            />
          </div>

        </Col>
        <Col md={12} xs={12} lg={12}>
          <div className="form__form-group">
            <Field
              name="fullMassage"
              type="text"
              label="Message"
              placeholder="Your Message"
              startIcon="description"
              component={renderField}
              multiline={true}
              rows={4}
            />
          </div>

        </Col>
      </Row>

      <Divider />
      <div className="my-10 flex flex-end flex-middle">
        <div>
          <Button
            className="account__btn"
            type="submit"
            color="primary"
            variant="contained"
            disabled={!valid || submitting}
          >
            Submit
            </Button>
        </div>
        <div className="px-10">

          <Button
            className="account__btn"
            color="secondary"
            disableElevation
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
            </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "Message-form",
  validate,
})(Form);
