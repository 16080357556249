import React, { useEffect,useState } from 'react';
import { reset } from "redux-form"
import TopBar from "../../../shared/components/TopBar"
import { withRouter } from "react-router-dom"
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux"
import { allExperiance, getproviderExpById, setExperienceById } from '../../../redux/actions/experianceAction'
import ModalAddExperiaces from "./ModalAddExperiaces"
import api from "../../../utils/apiClient"
import { addExperienceModal } from "../../../redux/actions/modalAction"
import ConfirmationModal from "../../../shared/components/ConfirmationModal"
import { setExperienceMedia, setExperiancePdf } from "../../../redux/actions/mediaAction"
import { showSucessNoti, showFailedNoti } from "../../../redux/actions/notificationAction"
import CardNew from "../../../shared/components/CardComponent/CardNew"
import NoRecord from "../../../shared/components/NoRecord"
import SkeletonCard from "../../../shared/components/CardComponent/SkeletonCard"
import CsvInput from 'shared/components/form/csvUpload';
import BannerUploadInput from 'containers/Layout/upload_banner/uploadBanners';

const AllExperiance = () => {
    const [deleteExp, setdeleteExp] = React.useState(false)
    const [expCurrent, setExpCurrent] = React.useState({})
    const [isEdit, setIsEdit] = React.useState(false)

    const dispatch = useDispatch()

    const expMedia = useSelector(state => state.media.experierenceMedia)
    const expPdf = useSelector(state => state.media.experierencePdf)
    const modal = useSelector(state => state.modal)
let [pageNumber, setPageNumber] = useState(1);
    const {
        loading,
        getAllExperiance
    } = useSelector(state => state.experiance)
    useEffect(() => {
        dispatch(allExperiance({pageNo:pageNumber,pageSize:10}))
    }, [pageNumber])
    useEffect(() => {
        if (!getAllExperiance) {
            return;
        }
    }, [loading])

    const handleClose = () => {
        dispatch(reset("experianceForm"))
        dispatch(setExperiancePdf(""))
        dispatch(setExperienceById({}))
        dispatch(setExperienceMedia(""))
        dispatch(addExperienceModal(false))
        setdeleteExp(false)
    }
    // this is open add experience form in model
    const handleExp = () => {

        dispatch(addExperienceModal(true))
    }

    const expProviderSubmit = async (values) => {

        let expData = {
            id: values.id,
            name: values.name,
            //organisationName: "test",//values.organisationName,
            title:values.title,
            experiencePhoto: expMedia ? expMedia : values.experiencePhoto,
            locationId: values.locationId,
            // location: values.location.name,
            activityId: values.activityId,
            address:values.address,
            
            // city: values.city,
            cost: values.cost ? parseInt(values.cost) : 0,
            // numberOfSessions: values.numberOfSessions ? parseInt(values.numberOfSessions) : 0,
            // capacity: values.capacity ? parseInt(values.capacity) : 0,
            // ageGroup: values.ageGroup,
            categoryId:values.category,
            ageGroup:values.age,
            // availability: values.availability?.join(","),
            date: values.date,
            time: values.time,
            programDetails: values.description,
            file: expPdf ? expPdf : values.file,
            externalLink: values.externalLink
        }
        await api.experience.manageExperienceAdmin(expData, true).then(res => {
            dispatch(reset("experianceForm"))
            dispatch(showSucessNoti(res.data.message))
            dispatch(allExperiance()).then(() => {
                if (isEdit) {
                    dispatch(reset("experianceForm"))
                    setIsEdit(false)
                }
                handleClose()

            })

        }).catch(err => {
            dispatch(showFailedNoti(err.message))

        })
    }

    const handleExpEdit = (data) => {
        dispatch(getproviderExpById(data)).then(() => {
            setIsEdit(true)
            dispatch(addExperienceModal(true))
        })
    }

    const handleExpDeleteConfirm = async () => {
        let id = expCurrent
        await api.experience.deleteProviderExp(id, true).then(res => {
            setdeleteExp(false)
            dispatch(allExperiance())
        }).catch(err => {

        })

    }

    const handleExpDelete = (data) => {
        setExpCurrent(data)
        setdeleteExp(true)

    }
    return (
        <>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                
                <TopBar

                    title="Experience"
                    buttonTitle="Add"
                    buttonClick={handleExp}
                ></TopBar>
                
               
            </Col>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                
                <CsvInput />
                <BannerUploadInput/>
                
               
            </Col>
            
            {/* only if loading is true  */}
            {!loading && getAllExperiance.length === 0 && (<Col><NoRecord /></Col>)}
            {loading && (<SkeletonCard />)}
            {getAllExperiance.map((item, index) => {
                
                return (
                    <div className="flex flex-middle flex-start" key={index}>
                        <CardNew
                            name={item.name}
                            type="experience"
                            image={item.experiencePhoto}
                            description={item.programDetails}
                            id={item.id}
                            title={item.title}
                            // availability={item.availability}
                            locations={item.locationId}
                            cost={item.cost}
                            activities={item.activityId}
                            // sessionsCount={item.numberOfSessions}
                            handleEdit={(id) => handleExpEdit(id)}
                            handleDelete={(id) => handleExpDelete(id)}
                            item={item}
                        />
                    </div>
                )
            })}
            {modal.addExperienceModal && (
                <ModalAddExperiaces
                    title="Create Experience"
                    isOpen={modal.addExperienceModal}
                    closeModal={handleClose}
                    expSubmit={expProviderSubmit}
                />
            )}

            <ConfirmationModal
                color="danger"
                title="Delete Experiance"
                message="Are you sure you want to delete"
                colored=""
                header=""
                isOpen={deleteExp}
                closeModal={handleClose}
                onYesClick={handleExpDeleteConfirm}
            />
             <div className="topbar_login">
                {pageNumber > 1 ? <button className="topbar_login_btn" onClick={() => {
                    pageNumber--;
                    console.log("pageNumber ", pageNumber)
          
                    setPageNumber(pageNumber)
                }}>
                    Previous
                </button>: <div></div>}
        <button className="topbar_login_btn" onClick={() => {
          pageNumber++;
          console.log("pageNumber ", pageNumber)
          
          setPageNumber(pageNumber)
            }}>
               Next
            </button> 
      </div>
        </>
    );

}

export default withRouter(AllExperiance);