import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { loginModal, registerModal } from '../../../redux/actions/modalAction';
// import { loginModal, registerModal, forgetPasswordModal } from '../../../redux/actions/modalAction';

const TopbarLogin = ({}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div>
        <Link className="topbar__nav-link " to="/support">Support</Link>
      </div>
      <div><Link className="topbar__nav-link " to="/about">About</Link></div>
      <div><button className="topbar_login_btn  " onClick={() => dispatch(loginModal(true))}>Login</button></div>
      <div><button
        className="topbar_login_btn-register  "
        onClick={() => {
          dispatch(registerModal(true)).then(() => {
            dispatch(loginModal(false));
          });
        }}
      >Register
           </button>
      </div>
    </>
  );
};
TopbarLogin.prototype = {
  changeIsOpenModalLogin: PropTypes.func.isRequired,
};
export default TopbarLogin;
