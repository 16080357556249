const tagTypes = {
    Category: 1,
    Location: 2,
    School: 3,
    Activity: 4,
    AgeGroup: 5,
    City: 6,
    MinimumAge: 7,
    
}

export { tagTypes }