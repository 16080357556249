import React from 'react';
import {
  Modal, ModalHeader

} from 'reactstrap';

const ProgramPartner = ({
  title,
  isOpen,
  closeModal,
  setCurrentUser
}) => {
  return <Modal
    toggle={closeModal}
    size="sm"
    className="p-0"
    isOpen={isOpen} centered>
    <ModalHeader toggle={closeModal}>Program Partners</ModalHeader>
    <p className="text-left">
      Thank you for your interest in becoming "Program Partner".
      Please contact us at &nbsp;
      <a href={`mailto: info@dowhatuluv.ca`}>
      info@dowhatuluv.ca
      </a>
      &nbsp;
      and one
      of our community outreach members will get in touch with you.
    </p>

  </Modal>
}


export default ProgramPartner;
