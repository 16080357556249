import {
    CONTACT_US,CONTACT_US_LOADING
} from "../types";

const initialState = {
    contactUs: {},
    contactUsLoading:false,
}
const contactUsReducer =(state=initialState,action)=>{
    switch(action){
        case CONTACT_US:
            return{
                ...state,
                contactUs: action.payload,
            }
            // case CONTACT_US_LOADING:
            // return{
            //     ...state,
            //     contactUsLoading: action.payload
            // }
        default:
            return state;
    }
}
export default contactUsReducer;