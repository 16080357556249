
import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import CircularProgress from '@material-ui/core/CircularProgress';
import api from "../../../utils/apiClient";
import { TextField, InputAdornment, Icon } from '@material-ui/core';

// to filter lower case in autocomplete
const filterOptions = createFilterOptions({
  ignoreCase: true,
});
export default function ProviderAnnounSelect(props) {

  let { input,
    label,
    type,
    startIcon,
    placeholder,
    defaultValue,
    tagType,
    id,
    meta: { touched, error, warning },
    ...custom
  } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([{ title: '', id: null }]);
  const loading = open && options.length === 0;
  const [value, setvalue] = React.useState({})
  let active = true;
  let programs = []
  React.useEffect(() => {

    if (!loading) {
      return undefined;
    }
    handleOptions()

    return () => {
      active = false;
    };

  }, [loading]);


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleOptions = async () => {
    switch (input.name) {
      case "programId":
        return handleProgramList()
      case "studentId":
        return handleStudentList()
      default:
        return null
    }
  }
  const handleStudentList = async() => {
      return await api.student.getStudentEnrolledList().then((res) => {
    if (res.data && active) {
        
        res.data.data.map(value => programs.push({
          id: value.id,
          name: `${value.fName}  ${value.lName}`
        }))

      }
    }).then(() => setOptions(programs))
      .catch((e) => console.log(e))
  }
    const handleProgramList = async () => {
    
    return await api.program.getProviderProgram({
      pageNo: 1,
      pageSize: 10000000
    }, true).then((res) => {
      if (res.data && active) {
        res.data.item1.map(value => programs.push({
          id: value.id,
          name: value.programName
        }))

      }
    }).then(() => setOptions(programs))
      .catch((e) => console.log(e))
  }

  return (
    <>
      <Autocomplete
        multiple
        size="small"
        limitTags={3}
        id="tags-standard"
        value={input.value || []}
        onChange={(e, newValue) => {
          input.onChange(newValue)
        }}
        options={options}
        getOptionLabel={(option) => option && option.name}
        defaultValue={input.value}
        getOptionSelected={(option, value) => option.id === value.id}

        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // onInputChange={(e, newValue) => {
        //   input.value = newValue
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            type={type}
            size="small"
            color="primary"

            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            {...custom}
          />
        )}
      />
    </>


  );
}
