import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));
export default function SimpleRating({value}) {

  const classes = useStyles();
  return (
    <>
   
      <Box borderColor="transparent">      
      
        <div className={classes.root}>
        {/* <Rating name="read-only" value={value} size="medium" readOnly /> */}
        </div>
      </Box> 
      
    </>
  );
}
