import React from 'react'
import {useSelector} from 'react-redux'
import {Route, Redirect, withRouter} from 'react-router-dom'

const PrivateRoute=({component:Component,role,...rest})=>{
  const auth = useSelector(state => state.user)
  return(
    <Route
         {...rest}
      render={props => auth.isAuthenticated && (auth.role === role)
        ?
        <Component {...props} />
        : <Redirect to='/' />}
    />
  )

}

export default withRouter(PrivateRoute)