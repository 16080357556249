import {
    EXPERIANCE_COMPLETED,
    PROVIDER_COMPLETED,
    USER_COMPLETED,
    PROGRAM_COMPLETED,
    EXPERIANCE_MEDIA,
    PROVIDER_MEDIA,
    USER_MEDIA,
    PROGRAM_MEDIA,
    PARENT_MEDIA,
    PARENT_COMPLETED,
    EXPERIANCE_COMPLETED_PDF,
    EXPERIANCE_MEDIA_PDF,
    PROGRAM_MEDIA_PDF,
    PROGRAM_COMPLETED_PDF
} from "../types"

const initialState = {
    programMedia: "",
    userMedia: "",
    experierenceMedia: "",
    providerMedia: "",
    programCompleted: "",
    userCompleted: "",
    experierenceCompleted: "",
    providerCompleted: "",
    parentCompleted: "",
    parentMedia: "",
    experierenceCompletedPdf: "",
    experierencePdf: "",
    programCompletedPdf: "",
    programPdf: "",
};
const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPERIANCE_COMPLETED:
            return {
                ...state,
                experierenceCompleted: action.payload,

            };
        case EXPERIANCE_MEDIA:
            return {
                ...state,
                experierenceMedia: action.payload,
                experierenceCompleted: ""

            };
        case PROVIDER_COMPLETED:
            return {
                ...state,
                providerCompleted: action.payload

            }
        case PROVIDER_MEDIA:
            return {
                ...state,
                providerMedia: action.payload,
                providerCompleted: ""


            }
        case USER_COMPLETED:
            return {
                ...state,
                userCompleted: action.payload


            }
        case USER_MEDIA:
            return {
                ...state,
                userMedia: action.payload,
                userCompleted: ""
            }
        case PROGRAM_COMPLETED:
            return {
                ...state,
                programCompleted: action.payload,
            }
        case PROGRAM_MEDIA:
            return {
                ...state,
                programMedia: action.payload,
                programCompleted: ""
            }
        case PARENT_COMPLETED:
            return {
                ...state,
                parentCompleted: action.payload
            }
        case PARENT_MEDIA:
            return {
                ...state,
                parentMedia: action.payload,
                parentCompleted: ""
            }
        case EXPERIANCE_COMPLETED_PDF:
            return {
                ...state,
                experierenceCompletedPdf: action.payload

            }
        case EXPERIANCE_MEDIA_PDF:
            return {
                ...state,
                experierencePdf: action.payload,
                experierenceCompletedPdf: ""
            }
        case PROGRAM_COMPLETED_PDF:
            return {
                ...state,
                programCompletedPdf: action.payload
            }
        case PROGRAM_MEDIA_PDF:
            return {
                ...state,
                programPdf: action.payload,
                programCompletedPdf: ""
            }
        default:
            return state;
    }
};

export default mediaReducer;
