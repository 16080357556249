
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { authRoles } from "../../config/authroles"
import AdminActivities from "./AdminActivities"
import ParentActivities from "./ParentActivities"
import ProviderActivities from "./ProviderActivities"
import HelmetComponent from "shared/components/HelmetComponent"
import {seoData} from "shared/StaticData"
const Activities = () => {
    const auth = useSelector(state => state.user)
    const renderUi = () => {
        if (auth.role === authRoles.Provider) {
            return <ProviderActivities/>
        } else if (auth.role === authRoles.Parents) {
            return <ParentActivities/>
        } else if (auth.role === authRoles.Admin){
            return <AdminActivities/>
        } else if (auth.role === authRoles.SuperAdmin){
            return <AdminActivities/>
        } else {
            return null
        } 
    }
    return <>
        <HelmetComponent seoData={ seoData.activities}/>
       {renderUi()}
    </>
    
}

export default Activities