import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';
import TopbarSearch from '../topbar_with_navigation/TopbarSearch';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import TopbarLogin from "./TopbarLogin"
import { makeStyles } from '@material-ui/core/styles'
import { Col, Row} from 'reactstrap'
const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility, user,logo
}) => {
  const classes = useStyles();
  return (
    <>
     <Row className="topbar flex-middle flex-space-between m-0">
      <Col xl={10} lg={9} md={9} sm={9} xs={11}  className="pl-0 flex flex-middle flex-space-between">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Link className="mx-10 mr-10" to={user.isAuthenticated ? "/home" : "/"} >
            <img className={"my-10 " + classes.large} src={logo} />
      </Link>
      <TopbarSearch className="w-100" />
      </Col>
      <Col xl={2} lg={3} md={3} sm={3} xs={12}  className="flex flex-space-around flex-middle pb-8">
       
      {user.isAuthenticated ?
          (<>
            <TopbarNotification />
            <TopbarProfile user={user} />
          </>) :
          (<TopbarLogin />)}
      </Col>
  </Row>
  </>
  )};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  user: UserProps.isRequired,
};

export default Topbar;
