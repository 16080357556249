/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { renderField } from "../../../shared/components/form/RenderField";
//import AutoDropDown from '../form/AutoDropDown';
import AutoDropDown from "shared/components/form/AutoDropDown";
import { Button } from "@material-ui/core";
import { validate } from "../../../config/formValidation";
import { connect } from "react-redux";
import ImageUpload from "../../../shared/components/form/ImageUpload";
import { mediaTypes } from "../../../config/mediaTypes";
import { Row, Col } from "reactstrap";
import { tagTypes } from "../../../config/tagTypes";
import ActivityTag from "../../../shared/components/form/ActivityTag";

const ProfileUpdate = ({
  handleSubmit,
  reset,
  onSubmit,
  submitting,
  valid,
  initialValues,
  userMedia,
  isEdit,
  toggleEdit,
}) => {
  // const [isEdit, setIsEdit] = useState(true);
  // const toggleEdit = () => {
  //   setIsEdit(!isEdit);
  // };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <Button
          variant={isEdit ? "contained" : "outlined"}
          onClick={toggleEdit}
          color="primary"
        >
          Edit
        </Button>
      </div>
      <Row>
        <Col>
          <div className="mb-10 ">
            <ImageUpload
              accept="image/*"
              image={userMedia ? userMedia : initialValues.avatar}
              mediatype={mediaTypes.UserAvatar}
              disabled={isEdit ? true : false}
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="fName"
              label="First Name*"
              type="text"
              component={renderField}
              placeholder="First Name"
              startIcon="people"
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="mName"
              label="Middle Name"
              component={renderField}
              placeholder="Middle Name"
              startIcon="people"
            />
          </div>
          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="lName"
              label="Last Name*"
              component={renderField}
              placeholder="Last Name"
              startIcon="people"
            />
          </div>
          {/* <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="interests"
              label="Interest"
              component={ActivityTag}
              type="text"
              placeholder="Interest"
              className="input-without-border-radius "
              startIcon="local_activity"
              tagType={[tagTypes.Activity]}
            />
          </div> */}
          <div className="form__form-group">
            <Field
              // disabled={isEdit ? true : false}
              disabled={true}
              name="email"
              label="Email*"
              component={renderField}
              type="text"
              placeholder="Email"
              // className="input-without-border-radius"
              startIcon="email"
            />
          </div>
          <div className="form__form-group">
            {/* <Field
              disabled={isEdit ? true : false}
              name="province"
              label="Province*"
              component={renderField}
              placeholder="Province"
              startIcon="home"
            /> */}
            <Field
              disabled={isEdit ? true : false}
              name="province"
              label="Province"
              required
              component={AutoDropDown}
              multiple={false}
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ]}
              type="text"
              placeholder="Select Province"
              startIcon="home"
            />
          </div>

          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="address1"
              label="Address*"
              component={renderField}
              placeholder="Address*"
              startIcon="home"
            />
          </div>

          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="city"
              label="City*"
              component={renderField}
              placeholder="City*"
              startIcon="home"
            />
          </div>

          <div className="form__form-group">
            <Field
              disabled={isEdit ? true : false}
              name="postalCode"
              label="Postal Code*"
              component={renderField}
              placeholder="Postal Code*"
              startIcon="home"
            />
          </div>
        </Col>
      </Row>
      <div className="flex flex-end flex-middle my-16">
        <div>
          <Button
            disabled={isEdit ? true : false}
            color="primary"
            variant="contained"
            type="submit"
          >
            Update profile
          </Button>
        </div>
      </div>
    </form>
  );
};
ProfileUpdate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default connect((state) => ({
  errorMsg: state.user.error,
  userMedia: state.media.userMedia,
  initialValues: {
    avatar: state.profile.parentProfile.avatar,
    id: state.profile.parentProfile.id ? state.profile.parentProfile.id : 0,
    fName: state.profile.parentProfile.fName
      ? state.profile.parentProfile.fName.trim()
      : "",
    interests: state.profile.parentProfile.interestNames
      ? state.profile.parentProfile.interestNames
      : [],
    mName: state.profile.parentProfile.mName
      ? state.profile.parentProfile.mName.trim()
      : "",
    lName: state.profile.parentProfile.lName
      ? state.profile.parentProfile.lName.trim()
      : "",
    email: state.profile.parentProfile.email
      ? state.profile.parentProfile.email.trim()
      : "",
    province: state.profile.parentProfile.province
      ? state.profile.parentProfile.province.trim()
      : "",
    address1: state.profile.parentProfile.address1
      ? state.profile.parentProfile.address1.trim()
      : "",
    city: state.profile.parentProfile.city
      ? state.profile.parentProfile.city.trim()
      : "",
    country: state.profile.parentProfile.country
      ? state.profile.parentProfile.country.trim()
      : "",
    postalCode: state.profile.parentProfile.postalCode
      ? state.profile.parentProfile.postalCode.trim()
      : "",
  },
}))(
  reduxForm({
    form: "UpdateParent",
    enableReinitialize: true,
    validate,
  })(ProfileUpdate)
);
