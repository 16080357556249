import api from "../../utils/apiClient"
import { ADD_SUBSCRIPTION, DELETE_SUBSCRIPTION, GET_SUBSCRIPTION, GET_SUBSCRIPTION_BY_ID,SET_SUBS_TYPE,
SUBSCRIPTION_TRANSACTION } from "../types";
import { showFailedNoti, showSucessNoti, showWarningNoti } from "./notificationAction";
import {getProviderProfile} from "./profileActions"
export const getSubscription=()=>async dispatch=>{  
    await api.subscription.getSub().then(res =>{

        if(res.data){
            dispatch({
                type: GET_SUBSCRIPTION,
                payload: res.data
            })
        }
    }).catch(err =>{
        showFailedNoti(err)
    })
}
export const addSubscription=(data)=> async dispatch=>{
    await api.subscription.post(data).then(res=>{
        // debugger
        if(res.data){
            dispatch({
                type:ADD_SUBSCRIPTION,
                payload: res.data
            })
            dispatch(showSucessNoti(res.data.message))
        }
    })
    .catch(err =>{
        // debugger
    })
}
export const getSubscriptionById=(id)=>async dispatch=>{
    await api.subscription.getById(id).then(res=>{
        // debugger
        if(res.data){
            dispatch({
                type: GET_SUBSCRIPTION_BY_ID,
                payload: res.data
            })
        }
    }).catch(err =>{
        // debugger
    })
}
export const deleteSubscription=(id)=>async dispatch=>{
    await api.subscription.delete(id).then(res=>{
        // debugger
        if(res.data){
            dispatch({
                type: DELETE_SUBSCRIPTION,
                payload:res.data
            })
        }
    })
}


export const setSubsType = (subsDetails, subsType) => async dispatch => {
    // debugger
    dispatch({
        type: SET_SUBS_TYPE,
        subsDetails: subsDetails,
        subsType:subsType
        
    })
}

export const saveSubscriptionData = (data) => async (dispatch) => {
    console.log("saveSubscriptionData",data)
    await api.subscription.saveSubscription(data).then((res) => {
        console.log("saveSubscription",data)

        if (res.data.data) {
            console.log("res",res.data.data)
            dispatch(getProviderProfile())
            dispatch(showSucessNoti(res.data.message))
             
        } else {
            dispatch(showFailedNoti(res.data.message))
         }
    }).catch((err => {
        console.log(err.message)
    }))
}
export const subscriptionTransactions = () => async dispatch =>{
    await api.subscription.subscriptionTransactions().then(res =>{
        if(res.data){
            dispatch({
                type: SUBSCRIPTION_TRANSACTION,
                payload: res.data
            })
        }
    }).catch(err=>console.log(err.message))
}