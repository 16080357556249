import React from 'react';
import { Row, Col, Container } from "reactstrap"
import { useSelector } from "react-redux"
import DwulCard from "shared/components/CardComponent/DwulCard"
import CardNew from "shared/components/CardComponent/CardNew"
import ProfileCard from "shared/components/CardComponent/ProfileCard"
import NoRecord from "shared/components/NoRecord"
import SkeletonCard from "shared/components/CardComponent/SkeletonCard"

function UniversalSearch({ }) {

    const {
        uniProgram,
        uniExperiance,
        uniProvider,
        uniLoading
    } = useSelector(state => state.universal)

    return (
        <div className="mx-16">
            {uniExperiance.length === 0 && uniProgram.length === 0 && uniProvider.length === 0 && !uniLoading && (<NoRecord />)}
            {uniLoading && (<SkeletonCard />)}

            {uniProgram.length > 0 && (<>
                <Row>
                    <Col md={12}>
                        <div >
                            <h3 className="bold-text">Programs</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {uniProgram.map((item, index) => (
                        <div key={index} className="flex flex-middle ml-10 mb-10 mt-10  ">
                            <DwulCard
                                name={item.programName}
                                image={item.programPhoto}
                                description={item.description}
                                id={item.id}
                                newTag={item.new}
                                regulerDates={item.regulerDates}
                                specialDates={item.specialDates}
                                availability={item.availability}
                                location={item.locationName}
                                cost={item.cost}
                                activities={item.activity}
                                key={index}
                                isManage={false}
                                routePath={`/detail/${item.programName}/${item.id}`}
                                item={item}
                                providerImage={item.providerImage}
                                organizationName={item.organisationName}
                            />
                        </div>
                    ))}
                </Row>
            </>)}

            {uniExperiance.length > 0 && (<>
                <Row>
                    <Col md={12}>
                        <div className="mt-10 mb-10">
                            <h3 className="bold-text">Experience</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {uniExperiance.map((item, index) => (<div key={index} className="flex flex-middle ml-10  ">
                        <CardNew
                            type="experience"
                            toolBar={false}
                            name={item.name}
                            image={item.experiencePhoto}
                            description={item.description}
                            id={item.id}
                            specialDates={item.specialDates}
                            availability={item.availability}
                            locations={[item.location]}
                            cost={item.cost}
                            activities={item.activity}
                            sessionsCount={item.numberOfSessions}
                            isManage={false}
                            item={item}
                        />
                    </div>))}
                </Row>
            </>)}
            {uniProvider.length > 0 && (<>
                <Row>
                    <Col md={12}>
                        <div className="mt-10">
                            <h3 className="bold-text">Provider</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {uniProvider.map((item, index) => (<div key={index} className="flex flex-middle ml-10 ">
                        <ProfileCard
                            type="provider"
                            item={item}
                        />
                    </div>
                    ))}
                </Row>
            </>)}
        </div>
    );
}

export default UniversalSearch