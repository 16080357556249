
import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Col, Table, ButtonToolbar, Row
} from 'reactstrap';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import { useSelector } from "react-redux"
import { ListItem, ListItemText, Divider, Button, Typography, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Skeleton } from "@material-ui/lab"
import NoRecord from "../../../shared/components/NoRecord"
import { uniqBy } from "lodash"
const CartCard = ({ handlePromoCode, handleRemoveCart, handlePaymentModal }) => {
  const { cartProduct,
    totalCartCost = 0,
    discountAmount = 0,
    grandTotalCart = 0,
    cartLoading = true } = useSelector(state => state.cart)
  const { parentProfile } = useSelector(state => state.profile)



  return (
    <Col md={12} lg={12}>
      <Card className="cart">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Cart</h5>
            <h6 className="text-tranform-none mt-16">Contact Details:<br />
              <span>{parentProfile.email}</span>
            </h6>
            <Button disabled={cartProduct.orders.length === 0 ? true : false} color="primary" className="float-right mb-10" onClick={handlePromoCode}>Apply promo</Button>
          </div>
          <Table className="table--bordered cart__table" responsive>
            <thead>
              <tr>
                <th>Sl.No.</th>
                <th>Name</th>
                <th>Student</th>
                <th>Price</th>
                <th>Total</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cartLoading ? (
                <tr>
                  <td>
                    <Skeleton>
                      <Typography>.</Typography>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="100%">
                      <span>
                        <Typography>.</Typography>
                      </span>

                    </Skeleton>
                  </td>
                  <td >
                    <Skeleton width="100%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="100%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="100%">
                      <Typography>.</Typography>
                    </Skeleton>
                  </td>
                </tr>


              ) : (

                cartProduct.orders.map((ct, i) => {
                  let uniqueStudents = uniqBy(ct.studentDetails, 'studentId')
                  console.log("uniqueStudents", uniqueStudents)
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <span className="cart__preview-img">
                          <img src={ct.image ? ct.image : `${process.env.PUBLIC_URL}/img/details.jpg`} alt="product_preview" />
                        </span>
                        <span>
                          {ct.name}
                          {console.log("ct.name", ct.name)}
                          {console.log("ct", ct)}
                          {



                            //--------------------------------duplicate previous method-------------------------------------------
                            uniqueStudents.map((s, index) => {

                              return (
                                <ListItem dense >
                                  {console.log("ssss", s)}
                                  <ListItemText primary={`${index + 1}.${s.studentName} `} />
                                </ListItem>
                              )
                            })
                            //--------------------------------duplicate previous method-------------------------------------------
                          }
                          {console.log("ct.studentDetails", ct.studentDetails)}

                        </span>
                      </td>
                      <td>{uniqueStudents.length}</td>
                      <td className="text-nowrap">CA${ct.amount.toFixed(2)} x {uniqueStudents.length}</td>
                      <td>${ct.amount.toFixed(2) * uniqueStudents.length}</td>
                      <td>
                        <Tooltip title={`Remove ${ct.name}`} arrow>
                          <IconButton color="secondary" onClick={() => handleRemoveCart(cartProduct.id, ct.id, ct.name)}>

                            <DeleteForeverIcon />

                          </IconButton>
                        </Tooltip>


                      </td>
                    </tr>
                  )
                }))}

              {!cartLoading && cartProduct.orders.length === 0
                && (<>
                  <NoRecord />
                </>)
              }
            </tbody>
          </Table>
          <Divider />
          <div className="invoice__total">
            <p>Sub - Total amount: CA${totalCartCost}</p>
            <p>Discount:CA${discountAmount} </p>
            <p className="invoice__grand-total">Grand Total: CA${grandTotalCart}</p>
            <ButtonToolbar className="invoice__toolbar">
              <Button disabled={cartProduct.orders.length === 0 ? true : false} color="primary" variant="contained" size="large" disableElevation onClick={handlePaymentModal}>Proceed</Button>
            </ButtonToolbar>
          </div>

        </CardBody>
      </Card>
    </Col>
  );
}



export default CartCard;
